import React, {
  useCallback,
  useEffect,
  useState,
  memo,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Comment,
  Avatar,
  Input,
  Button,
  Row,
  Col,
  message,
  Tooltip,
  Tag,
} from "antd";
import {
  getComments,
  postComment,
  updateComment,
  postReply,
  updateReply,
} from "actions/customer";
import moment from "moment";
import { useDispatch } from "react-redux";
import { orderFlaggedStatus } from "utils/constants";
const { TextArea } = Input;

function Comments({ customerId }, ref) {
  const [comment, setComment] = useState("");
  const [editedComment, setEditedComment] = useState("");
  const [editedReply, setEditedReply] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      updateComments: async () => {
        await getCustomerComments();
      },
    }),
    []
  );

  useEffect(async () => {
    await getCustomerComments();
  }, []);

  const getCustomerComments = async () => {
    try {
      let result = await dispatch(getComments(customerId));
      console.log("result=====", result);
      setCommentList([...result]);
    } catch (error) {
      console.log("failed to load comments", error);
    }
  };

  const handleComment = useCallback(async () => {
    setLoading(true);
    const result = await dispatch(
      postComment({
        customerId,
        comment: comment,
      })
    );
    if (result) {
      setLoading(false);
      setComment("");
      await getCustomerComments();
    } else {
      message.error("Failed to update comment");
    }
  }, [comment]);

  const editComment = useCallback(
    (i) => {
      commentList[i]["edit"] = !commentList[i]?.edit;
      setEditedComment(commentList[i].comment);
      setCommentList([...commentList]);
    },
    [commentList]
  );

  const handleEditedComment = useCallback(
    async (i) => {
      try {
        const comment = commentList[i];
        let result = await dispatch(
          updateComment(customerId, comment._id, editedComment)
        );
        if (result) {
          await getCustomerComments();
        } else {
          message.error("Failed to update comment");
        }
      } catch (error) {
        message.error("failed to load comments");
      }
    },
    [editedComment, commentList]
  );

  const handleCancelEditedComment = useCallback(
    (i) => {
      const list = commentList.map((x) => ({ ...x, edit: false }));
      setCommentList([...list]);
      setEditedComment("");
    },
    [editedComment, commentList]
  );

  const showReplyInput = useCallback(
    (i) => {
      commentList[i]["addReply"] = !commentList[i]?.addReply;
      setCommentList([...commentList]);
      setReplyComment("");
    },
    [commentList]
  );

  const handleCancelReplyInput = useCallback(
    (i) => {
      commentList[i]["addReply"] = !commentList[i]?.addReply;
      setCommentList([...commentList]);
      setReplyComment("");
    },
    [commentList]
  );

  const handleRepliedComment = useCallback(
    async (i) => {
      try {
        const comment = commentList[i];
        console.log(replyComment);
        let result = await dispatch(
          postReply(customerId, {
            commentId: comment._id,
            comment: replyComment,
          })
        );
        if (result) {
          await getCustomerComments();
        } else {
          message.error("Failed to reply comment");
        }
      } catch (error) {
        message.error("failed to reply comments");
      }
    },
    [replyComment, commentList]
  );

  const editReplyComment = useCallback(
    (i, j) => {
      commentList[i].replies[j]["edit"] = !commentList[i].replies[j].edit;
      setEditedReply(commentList[i].replies[j].comment);
      setCommentList([...commentList]);
    },
    [commentList]
  );

  const handleCancelEditedReply = useCallback(() => {
    const list = commentList.map((x) => ({
      ...x,
      edit: false,
      replies: x.replies.map((y) => ({ ...y, edit: false })),
    }));
    setCommentList([...list]);
    setEditedReply("");
  }, [editedReply, commentList]);

  const handleEditedReply = useCallback(
    async (i, j) => {
      try {
        const comment = commentList[i];
        let reply = comment.replies[j];
        let result = await dispatch(
          updateReply(customerId, comment._id, reply._id, editedReply)
        );
        if (result) {
          setEditedReply("");
          await getCustomerComments();
        } else {
          message.error("Failed to update comment");
        }
      } catch (error) {
        message.error("failed to update reply");
      }
    },
    [editedReply, commentList]
  );

  return (
    <>
      <Row className="comment-input" gutter={16}>
        <Col span={22}>
          <TextArea
            placeholder="Enter comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Col>
        <Col span={2}>
          <Button
            type="primary"
            onClick={handleComment}
            loading={loading}
            disabled={!comment.length}
          >
            Add
          </Button>
        </Col>
      </Row>
      {commentList &&
        commentList.map((comment, i) => (
          <Fragment key={comment?._id}>
            <Comment
              className="primary-comment"
              actions={[
                <span
                  key="comment-nested-reply-to"
                  onClick={() => showReplyInput(i)}
                >
                  Reply
                </span>,
                comment?.flaggedStatus === 1 || comment?.flaggedStatus === 2
                  ? ""
                  : comment.userId && (
                      <span
                        key="comment-nested-edit-to"
                        onClick={() => editComment(i)}
                      >
                        Edit
                      </span>
                    ),
                comment.updatedAt !== comment.createdAt ? (
                  <span key="comment-nested-edited-to">(Edited)</span>
                ) : (
                  ""
                ),
              ]}
              author={
                <>
                  <a className="user-name">{comment?.name}</a>
                </>
              }
              datetime={
                <Tooltip
                  title={moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD HH:mm:ss")}
                >
                  <span>{moment().subtract(1, "days").fromNow()}</span>
                </Tooltip>
              }
              avatar={
                <Avatar
                  src="https://yours-dev.s3.ap-southeast-1.amazonaws.com/video/noun-user-circle-1743561.svg"
                  alt={comment?.name}
                />
              }
              content={
                <>
                  {comment.edit ? (
                    <Row gutter={16}>
                      <Col span={17}>
                        <TextArea
                          value={editedComment}
                          onChange={(e) => setEditedComment(e.target.value)}
                          placeholder="Enter comment"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Col>
                      <Col span={7}>
                        <Button
                          className="mr-10"
                          type="primary"
                          onClick={() => handleEditedComment(i)}
                          disabled={!editedComment.length}
                        >
                          Update
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => handleCancelEditedComment(i)}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <p className="mb-10">
                        {comment.flaggedStatus &&
                        comment.flaggedStatus !== 0 ? (
                          <Tag
                            key={i}
                            closable={false}
                            color={`${
                              comment.flaggedStatus === 1 ? "red" : "green"
                            }`}
                          >
                            {comment.flaggedStatus === 1
                              ? orderFlaggedStatus[0].label
                              : orderFlaggedStatus[1].label}
                          </Tag>
                        ) : (
                          ""
                        )}
                        {comment?.comment}
                      </p>
                    </>
                  )}
                </>
              }
            >
              {comment.replies.map((reply, j) => (
                <Fragment key={reply._id}>
                  <Comment
                    className="secondary-comment"
                    actions={[
                      <span
                        key="comment-nested-reply-to"
                        onClick={() => editReplyComment(i, j)}
                      >
                        Edit
                      </span>,
                    ]}
                    author={<a>{reply?.name}</a>}
                    avatar={
                      <Avatar
                        src="https://yours-dev.s3.ap-southeast-1.amazonaws.com/video/noun-user-circle-1743561.svg"
                        alt={reply?.name}
                      />
                    }
                    content={
                      <>
                        {reply.edit ? (
                          <Row gutter={16}>
                            <Col span={17}>
                              <TextArea
                                value={editedReply}
                                onChange={(e) => setEditedReply(e.target.value)}
                                placeholder="Enter comment"
                                autoSize={{ minRows: 2, maxRows: 6 }}
                              />
                            </Col>
                            <Col span={7}>
                              <Button
                                className="mr-10"
                                type="primary"
                                onClick={() => handleEditedReply(i, j)}
                                disabled={!editedReply.length}
                              >
                                Update
                              </Button>
                              <Button
                                type="primary"
                                onClick={() => handleCancelEditedReply()}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <p className="mb-10">{reply?.comment}</p>
                        )}
                      </>
                    }
                  />
                </Fragment>
              ))}
              {comment?.addReply && (
                <Comment
                  author={<a>{window?.localStorage?.name}</a>}
                  avatar={
                    <Avatar
                      src="https://yours-dev.s3.ap-southeast-1.amazonaws.com/video/noun-user-circle-1743561.svg"
                      alt={window?.localStorage?.name}
                    />
                  }
                  content={
                    <Row gutter={16}>
                      <Col span={17}>
                        <TextArea
                          value={replyComment}
                          onChange={(e) => setReplyComment(e.target.value)}
                          placeholder="Enter comment"
                          autoFocus
                          autoSize={{ minRows: 2, maxRows: 6 }}
                        />
                      </Col>
                      <Col span={7}>
                        <Button
                          className="mr-10"
                          type="primary"
                          onClick={() => handleRepliedComment(i)}
                          disabled={!replyComment.length}
                        >
                          Add
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => handleCancelReplyInput(i)}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  }
                />
              )}
            </Comment>
          </Fragment>
        ))}
    </>
  );
}

export default memo(forwardRef(Comments));
