import AssessmentApi from "services/assessment";

function handleError(err) {
  console.error(err);
}

export function listGroup(result) {
  return {
    type: "LIST_GROUP",
    payload: result,
  };
}

export function listAssessment(result) {
  return {
    type: "LIST_ASSESSMENT",
    payload: result,
  };
}

export function listAssessmentAttempt(result) {
  return {
    type: "LIST_ASSESSMENT_ATTEMPT",
    payload: result,
  };
}

export function listSelfies(result) {
  return {
    type: "LIST_SELFIES",
    payload: result,
  };
}

export function listQuestion(result) {
  return {
    type: "LIST_QUESTION",
    payload: result,
  };
}

export function listParameter(result) {
  return {
    type: "LIST_PARAMETER",
    payload: result,
  };
}

export function listParameterQuestionMapping(result) {
  return {
    type: "LIST_PARAMETER_QUESTION_MAPPING",
    payload: result,
  };
}

export function setActiveQuestion(result) {
  return {
    type: "SET_ACTIVE_QUESTION",
    payload: result,
  };
}

export function setActiveAssessment(result) {
 
  return {
    type: "SET_ACTIVE_ASSESSMENT",
    payload: result,
  };
}

export function setLatestResult(result) {
  return {
    type: "SET_LATEST_ASSESSMENT_RESULT",
    payload: result,
  };
}

export function setCompletedAssessment(result) {
  return {
    type: "SET_COMPLETED_ASSESSMENT",
    payload: result,
  };
}

export function resetActiveQuestion() {
  return {
    type: "RESET_ACTIVE_QUESTION",
  };
}

export function setPageDetails(payload) {
  return {
    type: "SET_PAGE_DETAILS",
    payload,
  };
}

export const getGroupList = () => async (dispatch) => {
  try {
    let res = await AssessmentApi.getGroupList();
    dispatch(listGroup(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSearchedAssessment = (
  isPublished,
  startDate,
  endDate
) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getAssessmentList(
      isPublished,
      startDate,
      endDate
    );
    dispatch(listAssessment(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const getAssessmentAttemptList = (query, pageNo, pageSize,activeAssessmentActiveFilter=null,activeAssessmentStatusFilter=null) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getAssessmentAttemptList(query, pageNo,pageSize);
    dispatch(listAssessmentAttempt(res.result ? res.result.result : []));
    dispatch(
      setPageDetails({
        currentPage: pageNo,
        ...res.result,
        activeAssessmentActiveFilter,
        activeAssessmentStatusFilter
      })
    );
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSelfieList = (userId) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getSelfieListById(userId);
    dispatch(listSelfies(res.result ? res.result : []));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSearchedQuestion = (assessmentId) => async (dispatch) => {
  try {
    let res = await AssessmentApi.searchQuestionList(assessmentId);
    dispatch(listQuestion(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const searchParameter = () => async (dispatch) => {
  try {
    let res = await AssessmentApi.searchParameter();
    dispatch(listParameter(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const createQuestion = (payload) => async (dispatch) => {
  try {
    let res = await AssessmentApi.createQuestion(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getAssessmentById = (id) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getAssessmentById(id);
    if (res.result) {
      dispatch(setActiveAssessment(res.result));
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const getCompletedAssessmentById = (id) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getCompletedAssessmentById(id);
    if (res.result) {
      dispatch(setCompletedAssessment(res.result));
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const getQuestionById = (questionId) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getQuestionById(questionId);
    if (res) {
      dispatch(setActiveQuestion(res));
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const updateQuestionById = (questionId, payload) => async (dispatch) => {
  try {
    let res = await AssessmentApi.updateQuestionById(questionId, payload);
    if (res) {
      dispatch(setActiveQuestion(res));
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const createOption = (payload) => async (dispatch) => {
  try {
    let res = await AssessmentApi.createOption(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateOptionById = (optionId, payload) => async (dispatch) => {
  try {
    let res = await AssessmentApi.updateOptionById(optionId, payload);
    if (res) {
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const createParameterQuestionMapping = (payload) => async (dispatch) => {
  try {
    let res = await AssessmentApi.createParameterQuestionMapping(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateParameterQuestionMapping = (id, payload) => async (
  dispatch
) => {
  try {
    let res = await AssessmentApi.updateParameterQuestionMappingById(
      id,
      payload
    );
    if (res) {
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const getParameterQuestionMappingList = (questionId,assessmentId) => async (
  dispatch
) => {
  try {
    let res = await AssessmentApi.getParameterQuestionMappingList(questionId,assessmentId);
    dispatch(listParameterQuestionMapping(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const resetActiveQuestionAction = () => async (dispatch) => {
  try {
    dispatch(resetActiveQuestion());
  } catch (err) {
    handleError(err);
  }
};

export const getLatestAssessmentByUserId = (id) => async (dispatch) => {
  try {
    let res = await AssessmentApi.getLatestAssessmentByUserId(id);
    if (res.result) {
      dispatch(setActiveAssessment(res.result));
      return res;
    } else {
      throw new Error();
    }
  } catch (err) {
    handleError(err);
  }
};

export const getLatestResultForUser= ( id ) => async (dispatch) =>{
  try {
    let res = await AssessmentApi.getLatestResultForUser(id);
    if (res.result) {
      dispatch(setLatestResult(res.result));
      return res;
    } else {
      throw new Error();
    }
  }catch( err){
    handleError(err);
  }
}