import SelfiesApi from "services/selfies";
import actionTypes from "actions/actionTypes";

export const listReviews = (result) => ({
  type: actionTypes.LIST_SELFIES,
  payload: result,
});

export const getSelfiesList = () => async (dispatch) => {
  try {
    const res = await SelfiesApi.getSelfiesList();
    dispatch(listReviews(res.result));
    return res.result;
  } catch (err) {
    console.log(err);
  }
};
