import React, { Component } from "react";
import "./addCategory.scss";
import { Icon, Input, Button, Upload, Form, InputNumber, message, Select } from "antd";
import { Link } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  getBase64,
  beforeUpload,
  UploadImage,
  filterObj,
  beforeUploadVideoMP4,
  beforeUploadVideoWebM,
  isEmpty,
} from "utils/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/category";
import * as currencyActions from "actions/currency";
import * as benefitActions from "actions/benefits";
import { getSafely } from "utils/helpers";
import { ReactSortable } from "react-sortablejs";
import ProductRestriction from "components/products/ProductRestriction";
const { Option } = Select;

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [
        {
          fieldName: "Category Name",
          key: "name",
        },
      ],
      additionalSettings: [
        {
          fieldName: "Slug",
          key: "slug",
        },
        {
          fieldName: "Yotpo ID",
          key: "yotpoId",
        },
        {
          fieldName: "External ID",
          key: "externalId",
        }
      ],
      linkPreOrder: [{
        fieldName: "Link Pre Order",
        key: "linkPreOrder",
      }],
      descEditorState: EditorState.createEmpty(),
      validateStatusDesc: "",
      errorMsgDescEditor: null,

      hoverImageLaoding: false,
      imageLoading: false,
      productImageLoading: false,
      productHoverImageLoading: false,
      mode: "create",
      activeCategoryId: null,
      hoverImgUrl: "",
      gallery: [{ mediaUrl: "", order: 1, mediaType: "" }],
      imageLoadingGallery: [],

      videoWebm: [{ mediaUrl: "", order: 1, mediaType: "" }],
      videoLoadingGallery: [],

      videoMp4: [{ mediaUrl: "", order: 1, mediaType: "" }],
      videoMp4LoadingGallery: [],

      sortedPrices: [],
      restrictedCountry: [],
      allowedCountry: [],
      benefitList: [],
      isProducts: false,
      isAvailable: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;
    this.props.fetchAllCurrency();
    if (params.id !== undefined) {
      this.setState({
        mode: "edit",
        activeCategoryId: params.id,
      });
      this.props.getActiveCategory(params.id).then((res) => {
        if (!res) return;
        if (res.description !== undefined)
          this.setEditorInitialState("descEditorState", res.description);
        if (res.image) {
          this.setState({
            imageUrl: res.image.mediaUrl,
            hoverImg: res.image.hoverImgUrl,
          });
        }
        if (res.gallery) {
          let arr = [...res.gallery];
          if (res.gallery.length < 5) {
            arr.push({ mediaUrl: "", order: 1, mediaType: "" });
          }
          this.setState({ gallery: arr });
        }
        if (res.prices && res.prices.length > 0) {
          let result = [];

          this.props.currencyList &&
            this.props.currencyList.length > 0 &&
            this.props.currencyList.forEach((currency, i) => {
              let found = res.prices.find(
                (price) => price.currency.code === currency.code
              );
              if (found) {
                result.push(found);
              } else {
                result.push({});
              }
            });

          this.setState({
            sortedPrices: result,
          });
        }
        if (res.videoWebm) {
          let arr = [...res.videoWebm];
          if (res.videoWebm.length < 3) {
            arr.push({ mediaUrl: "", order: 1, mediaType: "" });
          }
          this.setState({ videoWebm: arr });
        }
        if (res.videoMp4) {
          let arr = [...res.videoMp4];
          if (res.videoMp4.length < 3) {
            arr.push({ mediaUrl: "", order: 1, mediaType: "" });
          }
          this.setState({ videoMp4: arr });
        }


        if (res.productPageImage) {
          this.setState({
            productPageImageUrl: res.productPageImage.mediaUrl,
            productPageHoverImg: res.productPageImage.hoverImgUrl,
          });
        }

        if (res.restrictedCountry) {
          this.setState({
            restrictedCountry: res.restrictedCountry,
          });
        }

        if (res.allowedCountry) {
          this.setState({
            allowedCountry: res.allowedCountry,
          });
        }
        if (res.isAvailable) {
          this.setState({
            isAvailable: res.isAvailable,
          });
        }
        if (res.isProducts) {
          this.setState({
            isProducts: res.isProducts,
          });
        }
      });
    } else {
      this.props.setActiveCategory(null);
    }
    this.props.fetchListBenefits().then((i) => {
      this.setState({
        benefitList: i,
      });
    });
  }
  setEditorInitialState = (key, value) => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          [key]: editorState,
        });
      }
    } else {
      this.setState({
        [key]: EditorState.createEmpty(),
      });
    }
  };
  onDescEditorStateChange = (editorState) => {
    this.setState({
      descEditorState: editorState,
    });
  };
  isEditMode() {
    return this.state.mode === "edit";
  }
  handleImageChange = (info, type, index) => {
    if (info.file.status === "uploading") {
      if (type === "product-page") {
        this.setState({ productImageLoading: true });
      } else if (type === "gallery") {
        let arr = this.state.imageLoadingGallery;
        arr[index] = true;
        this.setState({ imageLoadingGallery: arr });
      } else if (type === "video") {
        let arr = this.state.videoLoadingGallery;
        arr[index] = true;
        this.setState({ videoLoadingGallery: arr });
      }else if (type === "videoMp4") {
        let arr = this.state.videoMp4LoadingGallery;
        arr[index] = true;
        this.setState({ videoMp4LoadingGallery: arr });
      }else {
        this.setState({ imageLoading: true });
      }
      return;
    }

    if (info.file.status === "done") {
      if (info.file.type) {
        if (type === "product-page") {
          this.props.form.setFieldsValue({
            "productPageImage.mediaType": info.file.type,
          });
        } else if (type === "video") {
        } else if (type === "videoMp4") {
        }else {
          this.props.form.setFieldsValue({
            "image.mediaType": info.file.type,
          });
        }
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        if (type === "product-page") {
          this.setState({
            productPageImageUrl: imageUrl,
            productImageLoading: false,
          });
          this.props.form.setFieldsValue({
            "productPageImage.mediaType": info.file.type,
          });
        } else if (type === "gallery") {
          let arr = this.state.imageLoadingGallery;
          let images = this.state.gallery;
          if (images[index].mediaUrl === "" && images.length < 5) {
            arr[index] = false;
            images[index].mediaUrl = imageUrl;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = imageUrl;
          }
          this.setState({
            gallery: images,
            imageLoadingBanner: arr,
          });
          let formArray = this.props.form.getFieldValue("gallery");
          formArray[index] = {
            mediaUrl: formArray[index].mediaUrl,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            gallery: formArray,
          });
        } else if (type === "video") {
          let arr = this.state.videoLoadingGallery;
          let videos = this.state.videoWebm;
          if (videos[index].mediaUrl === "" && videos.length < 3) {
            arr[index] = false;
            videos[index].mediaUrl = imageUrl;
            videos.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            videos[index].mediaUrl = imageUrl;
          }
          this.setState({
            videoWebm: videos,
            videoLoadingBanner: arr,
          });
          let formArray = this.props.form.getFieldValue("videoWebm");
          formArray[index] = {
            mediaUrl: formArray[index].mediaUrl,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            videoWebm: formArray,
          });
        } else if (type === "videoMp4") {
          let arr = this.state.videoMp4LoadingGallery;
          let videos = this.state.videoMp4;
          if (videos[index].mediaUrl === "" && videos.length < 3) {
            arr[index] = false;
            videos[index].mediaUrl = imageUrl;
            videos.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            videos[index].mediaUrl = imageUrl;
          }
          this.setState({
            videoMp4: videos,
            videoMp4LoadingBanner: arr,
          });
          let formArray = this.props.form.getFieldValue("videoMp4");
          formArray[index] = {
            mediaUrl: formArray[index].mediaUrl,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            videoMp4: formArray,
          });
        } else {
          this.setState({
            imageUrl,
            imageLoading: false,
          });
          this.props.form.setFieldsValue({
            "image.mediaType": info.file.type,
          });
        }
      });
    }
  };
  handleHoverImageChange = (info, type) => {
    if (info.file.status === "uploading") {
      if (type === "product-page") {
        this.setState({ productHoverImageLoading: true });
      } else {
        this.setState({ hoverImageLaoding: true });
      }
      return;
    }
    if (info.file.status === "done") {
      if (info.file.type) {
        if (type === "product-page") {
          this.props.form.setFieldsValue({
            "productPageImage.mediaType": info.file.type,
          });
        } else {
          this.props.form.setFieldsValue({
            "image.mediaType": info.file.type,
          });
        }
      }
      getBase64(info.file.originFileObj, (hoverImg) => {
        if (type === "product-page") {
          this.setState({
            productPageHoverImg: hoverImg,
            productHoverImageLoading: false,
          });
          this.props.form.setFieldsValue({
            "productPageImage.mediaType": info.file.type,
          });
        } else {
          this.setState({
            hoverImg,
            hoverImageLaoding: false,
          });
          this.props.form.setFieldsValue({
            "image.mediaType": info.file.type,
          });
        }
      });
    }
  };

  renderInputFields = (arr) => {
    const { getFieldDecorator } = this.props.form;
    return arr.map((obj, index) => {
      return (
        <div className="mb-8" key={index}>
          <p className="text-black regular text-14 mb-4">{obj.fieldName}</p>
          <Form.Item>
            {getFieldDecorator(`${obj.key}`, {
              initialValue: this.props.activeCategory
                ? this.props.activeCategory[obj.key]
                : undefined,
              rules: [{ required: true, message: "Required" }],
            })(<Input />)}
          </Form.Item>
        </div>
      );
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // validate editors
    const descEditorContent = this.state.descEditorState
      .getCurrentContent()
      .hasText();
    if (!descEditorContent) {
      this.setState({
        validateStatusDesc: "error",
        errorMsgDescEditor: "Required",
      });
    } else {
      this.setState({
        validateStatusDesc: "success",
        errorMsgDescEditor: "",
      });
    }
    this.props.form.validateFields((err, values) => {
      if (!err && descEditorContent) {
        values["description"] = draftToHtml(
          convertToRaw(this.state.descEditorState.getCurrentContent())
        );
        values.prices.forEach((price, index) => {
          values.prices[index] = {
            ...values.prices[index],
            currency: filterObj(
              ["id", "code", "symbol"],
              this.props.currencyList[index]
            ),
          };
        });
        values["prices"] = values.prices.filter(
          (price) => price.price !== undefined
        );
        values["gallery"] = values.gallery.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["videoWebm"] = values.videoWebm.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["videoMp4"] = values.videoMp4.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["restrictedCountry"] = this.state.restrictedCountry;
        values["allowedCountry"] = this.state.allowedCountry;
        values["isProducts"] = this.state.isProducts;
        values["isAvailable"] = this.state.isAvailable
        if (this.isEditMode()) {
          this.props
            .updateCategory(this.state.activeCategoryId, values)
            .then((res) => {
              this.setState({ loading: false });
              if (res) {
                message.success(`Updated Successfully`);
              } else {
                message.error("Oops, Error");
              }
            });
        } else {
          this.props.createCategory(values).then((res) => {
            this.setState({ loading: false });
            if (res) {
              message.success(`Created Successfully`);
              this.props.history.push("/Category");
            } else {
              message.error("Oops, Error");
            }
          });
        }
      }
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.file && e.file.response) {
      return e.file.response;
    }
    return e.file;
  };

  uploadButton = (state) => {
    return (
      <div>
        <Icon type={state ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  onChangeRestrictedCountry = (countries = []) => {
    this.setState({
      restrictedCountry: countries,
    });
  };

  onChangeAllowedCountry = (countries = []) => {
    this.setState({
      allowedCountry: countries,
    });
  };

  onToggleisProducts = () => {
    this.setState({
      isProducts: !this.state.isProducts
    });
  };

  onToggleisAvailable = () => {
    this.setState({
      isAvailable: !this.state.isAvailable,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { imageUrl } = this.state;
    const { hoverImg } = this.state;
    const { productPageImageUrl } = this.state;
    const { productPageHoverImg } = this.state;
    const { sortedPrices } = this.state;
    const uploadButton = (loading) => (
      <div>
        <Icon type={loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { activeCategory } = this.props;

    return (
      <div className="category-page" style={{ marginLeft: "200px" }}>
        <div className="flex justify-between w-full items-center">
          <Link to="/Category">
            <div className="flex justify-start items-center cursor-pointer">
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="text-14 pb-2">Back</div>
            </div>
          </Link>
          <Button type="primary" onClick={this.handleSubmit}>
            Save
          </Button>
        </div>

        <div className="theme-color semibold text-30 mb-12">
          {this.isEditMode() && activeCategory ? activeCategory.name : ""}
        </div>

        <Form onSubmit={this.handleSubmit}>
          <div className="flex">
            <div className="contentLeft">
              <div className="desc">
                <p className="semibold text-18 text-black">Text</p>
                {this.renderInputFields(this.state.inputFields)}
                {!this.state.isAvailable && this.renderInputFields(this.state.linkPreOrder)}
                {this.renderInputFields([{ fieldName: "Short Description", key: "shortDescription" }])}

                <div className="mb-14">
                  <p className="text-black regular text-14 mb-4">Description</p>
                  <Form.Item
                    label=""
                    validateStatus={this.state.validateStatusDesc}
                    help={this.state.errorMsgDescEditor || ""}
                  >
                    <Editor
                      editorState={this.state.descEditorState}
                      editorToolbar="editorToolbar"
                      editorWrapper="editorWrapper"
                      editorClassName="editor"
                      editor="editor"
                      onEditorStateChange={this.onDescEditorStateChange}
                      wrapperStyle={{
                        border: "1px solid #F1F1F1",
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="mb-20">
                  <div className="text-black regular text-14 mb-4">
                    Why use?
                  </div>
                  <Form.Item
                  >
                    {getFieldDecorator('whyUse', {
                      initialValue: activeCategory
                        ? activeCategory.whyUse
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea
                      rows={5}
                    />)}
                  </Form.Item>
                </div>
                <div className="mb-20">
                  <div className="text-black regular text-14 mb-4">
                    Unique Description
                  </div>
                  <Form.Item
                  >
                    {getFieldDecorator('uniqueDescription', {
                      initialValue: activeCategory
                        ? activeCategory.uniqueDescription
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea
                      rows={5}
                    />)}
                  </Form.Item>
                </div>
                <div className="">
                  <div className="text-black regular text-14 mb-4">
                    Instruction
                  </div>
                  <Form.Item
                  >
                    {getFieldDecorator('instruction', {
                      initialValue: activeCategory
                        ? activeCategory.instruction
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea
                      rows={5}
                    />)}
                  </Form.Item>
                </div>
              </div>
              <div className="images mb-30">
                <div className="flex justify-between mb-12">
                  <p className="semibold text-18 text-black">Images</p>
                  {/* <p className="regular text-14 text-link">
                    Add image from URL
                  </p> */}
                </div>
                <div>
                  <div>
                    <Form.Item extra="Main Image">
                      {getFieldDecorator("image.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue:
                          activeCategory && activeCategory.image
                            ? activeCategory.image.mediaUrl
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Please upload a main image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={this.handleImageChange}
                          customRequest={UploadImage}
                        >
                          {!this.state.imageLoading && imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton(this.state.imageLoading)
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("image.mediaType", {
                        initialValue:
                          activeCategory && activeCategory.image
                            ? activeCategory.image.mediaType
                            : undefined,
                      })(<span></span>)}
                    </Form.Item>
                    {/* added key for hover Image start */}
                    <Form.Item extra="Hover Image">
                      {getFieldDecorator("image.hoverImgUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue:
                          activeCategory && activeCategory.image
                            ? activeCategory.image.hoverImgUrl
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Please upload a main image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={this.handleHoverImageChange}
                          customRequest={UploadImage}
                        >
                          {!this.state.hoverImageLaoding && hoverImg ? (
                            <img
                              src={hoverImg}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton(this.state.hoverImageLaoding)
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    {/* added key for hover Image ends */}
                    <Form.Item extra="Product Page Image">
                      {getFieldDecorator("productPageImage.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue:
                          activeCategory && activeCategory.productPageImage
                            ? activeCategory.productPageImage.mediaUrl
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Please upload a product image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={(info) =>
                            this.handleImageChange(info, "product-page")
                          }
                          customRequest={UploadImage}
                        >
                          {!this.state.productImageLoading &&
                            productPageImageUrl ? (
                            <img
                              src={productPageImageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton(this.state.productImageLoading)
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("productPageImage.mediaType", {
                        initialValue:
                          activeCategory && activeCategory.productPageImage
                            ? activeCategory.productPageImage.mediaType
                            : undefined,
                      })(<span></span>)}
                    </Form.Item>
                    {/* added key for hover Image start */}
                    <Form.Item extra="Product Page Hover Image">
                      {getFieldDecorator("productPageImage.hoverImgUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue:
                          activeCategory && activeCategory.productPageImage
                            ? activeCategory.productPageImage.hoverImgUrl
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Please upload a hover image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={(info) =>
                            this.handleHoverImageChange(info, "product-page")
                          }
                          customRequest={UploadImage}
                        >
                          {!this.state.productHoverImageLoading &&
                            productPageHoverImg ? (
                            <img
                              src={productPageHoverImg}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton(this.state.productHoverImageLoading)
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <Form.Item
                      label="Gallery Images"
                      className="mb-0"
                    ></Form.Item>
                    <ReactSortable
                      list={this.state.gallery}
                      setList={(val) => this.setState({ gallery: val })}
                      className="flex flex-wrap"
                    >
                      {this.state.gallery.map((image, index) => {
                        return (
                          <div key={index}>
                            <Form.Item className="mb-0">
                              {getFieldDecorator(`gallery[${index}].mediaUrl`, {
                                getValueFromEvent: this.normFile,
                                initialValue: getSafely(
                                  () => image.mediaUrl,
                                  undefined
                                ),
                                rules: [
                                  {
                                    required: false,
                                    message: "Required",
                                  },
                                ],
                              })(
                                <Upload
                                  name="avatar"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  ReactSortable
                                  showUploadList={false}
                                  beforeUpload={beforeUpload}
                                  onPreview={this.handlePreview}
                                  onChange={(e) =>
                                    this.handleImageChange(e, "gallery", index)
                                  }
                                  customRequest={UploadImage}
                                >
                                  {this.state.gallery &&
                                    this.state.gallery[index] &&
                                    this.state.gallery[index].mediaUrl !== "" ? (
                                    <img
                                      src={this.state.gallery[index].mediaUrl}
                                      alt="avatar"
                                      style={{
                                        maxHeight: "88px",
                                        maxWidth: "88px",
                                        margin: "auto",
                                      }}
                                    />
                                  ) : (
                                    this.uploadButton(
                                      this.state.imageLoadingGallery[index]
                                    )
                                  )}
                                </Upload>
                              )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `gallery[${index}].mediaType`,
                                {
                                  initialValue: getSafely(
                                    () => image.mediaType,
                                    undefined
                                  ),
                                }
                              )(<span></span>)}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(`gallery[${index}].order`, {
                                initialValue: getSafely(
                                  () => index + 1,
                                  undefined
                                ),
                              })(<span></span>)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                    <div style={{color:"red"}}>Make sure to add both webm and mp4 (if uploading videos)</div>
                    <div>
                      <Form.Item
                        label="Product Video webm format (size < 8 MB)"
                        className="mb-0"
                      ></Form.Item>
                      <ReactSortable
                        list={this.state.videoWebm}
                        setList={(val) => this.setState({ videoWebm: val })}
                        className="flex flex-wrap"
                      >
                        {this.state.videoWebm.map((video, index) => {
                          return (
                            <div key={index}>
                              <Form.Item className="mb-0">
                                {getFieldDecorator(`videoWebm[${index}]mediaUrl`, {
                                  getValueFromEvent: this.normFile,
                                  initialValue: getSafely(
                                    () => video.mediaUrl,
                                    undefined
                                  ),
                                  rules: [
                                    {
                                      required: false,
                                      message: "Required",
                                    },
                                  ],
                                })(
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadVideoWebM}
                                    onChange={(e) =>
                                      this.handleImageChange(e, "video", index)
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {this.state.videoWebm &&
                                      this.state.videoWebm[index] &&
                                      this.state.videoWebm[index].mediaUrl !== "" ? (
                                      <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        style={{ width: '100px' }}
                                      >
                                        <source
                                          src={this.state.videoWebm[index].mediaUrl}
                                          type="video/webm"
                                        />

                                      </video>
                                    ) : (
                                      this.uploadButton(
                                        this.state.videoLoadingGallery[index]
                                      )
                                    )}
                                  </Upload>
                                )}
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0 }}>
                                {getFieldDecorator(
                                  `videoWebm[${index}].mediaType`,
                                  {
                                    initialValue: getSafely(
                                      () => video.mediaType,
                                      undefined
                                    ),
                                  }
                                )(<span></span>)}
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0 }}>
                                {getFieldDecorator(`videoWebm[${index}].order`, {
                                  initialValue: getSafely(
                                    () => index + 1,
                                    undefined
                                  ),
                                })(<span></span>)}
                              </Form.Item>
                            </div>
                          );
                        })}
                      </ReactSortable>
                    </div>
                    <div>
                      <Form.Item
                        label="Product Video mp4 format (size < 8 MB)"
                        className="mb-0"
                      ></Form.Item>
                      <ReactSortable
                        list={this.state.videoMp4}
                        setList={(val) => this.setState({ videoMp4: val })}
                        className="flex flex-wrap"
                      >
                        {this.state.videoMp4.map((video, index) => {
                          return (
                            <div key={index}>
                              <Form.Item className="mb-0">
                                {getFieldDecorator(`videoMp4[${index}]mediaUrl`, {
                                  getValueFromEvent: this.normFile,
                                  initialValue: getSafely(
                                    () => video.mediaUrl,
                                    undefined
                                  ),
                                  rules: [
                                    {
                                      required: false,
                                      message: "Required",
                                    },
                                  ],
                                })(
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadVideoMP4}
                                    onChange={(e) =>
                                      this.handleImageChange(e, "videoMp4", index)
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {this.state.videoMp4 &&
                                      this.state.videoMp4[index] &&
                                      this.state.videoMp4[index].mediaUrl !== "" ? (
                                      <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        style={{ width: '100px' }}
                                      >
                                        <source
                                          src={this.state.videoMp4[index].mediaUrl}
                                          type="video/mp4"
                                        />

                                      </video>
                                    ) : (
                                      this.uploadButton(
                                        this.state.videoMp4LoadingGallery[index]
                                      )
                                    )}
                                  </Upload>
                                )}
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0 }}>
                                {getFieldDecorator(
                                  `videoMp4[${index}].mediaType`,
                                  {
                                    initialValue: getSafely(
                                      () => video.mediaType,
                                      undefined
                                    ),
                                  }
                                )(<span></span>)}
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0 }}>
                                {getFieldDecorator(`videoMp4[${index}].order`, {
                                  initialValue: getSafely(
                                    () => index + 1,
                                    undefined
                                  ),
                                })(<span></span>)}
                              </Form.Item>
                            </div>
                          );
                        })}
                      </ReactSortable>
                    </div>
                    {/* added key for hover Image ends */}
                  </div>
                </div>
              </div>
              <div className="shipping-products-container mb-30">
                <div className="shipping-products">
                  <div
                    style={{
                      borderBottom: "1px solid #f4f4f7",
                      paddingBottom: "15px",
                    }}
                    className="content-container-i"
                  >
                    {console.log(activeCategory)}
                    <div className="text-base text-black bold">Shipping</div>
                    <div className="secon-text regular text-14">WEIGHT</div>
                    <div className="desc-shipping regular text-14">
                      Used to Calculate shipping rates at checkout and label
                      prices during fulfillment.
                    </div>
                    <div className="secon-text desc-shipping regular text-14">
                      Weight (ml)
                    </div>
                    <Form.Item>
                      {getFieldDecorator(`shippingMetadata.weight`, {
                        initialValue:
                          activeCategory && activeCategory.shippingMetadata?.weight
                            ? activeCategory.shippingMetadata.weight
                            : undefined,
                        rules: [{ required: true, message: "Required" }],
                      })
                        (
                          <Input
                            style={{ minWidth: "275px", marginTop: "10px" }}
                            min={0.1}
                          />
                        )
                      }
                    </Form.Item>
                    {/* Weight in ounces */}
                    <div className="secon-text desc-shipping regular text-14">
                      Weight (ounce / oz) for US
                    </div>
                    <Form.Item>
                      {getFieldDecorator('shippingMetadata.weightOz', {
                        initialValue:
                          activeCategory && activeCategory.shippingMetadata?.weightOz
                            ? activeCategory.shippingMetadata.weightOz
                            : undefined,
                        rules: [{ required: true, message: "Required" }],
                      })
                        (
                          <Input
                            style={{ minWidth: "275px", marginTop: "10px" }}
                            min={0.1}
                          />
                        )
                      }
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="contentRight flex flex-col">
              <div className="pricing-container">
                <div className="">
                  <div className="text-base text-black bold">Pricing</div>
                </div>
                <div className="pricing-products flex flex-col">
                  {this.props.currencyList &&
                    this.props.currencyList.length > 0 &&
                    this.props.currencyList.map((currency, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className="tab-pricing flex"
                            style={{ padding: "5px" }}
                          >
                            <Form.Item
                              style={{ marginRight: "6px" }}
                              extra={`Price in ${currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                                }`}
                            >
                              {getFieldDecorator(`prices[${index}].price`, {
                                initialValue:
                                  sortedPrices.length > 0 && sortedPrices[index]
                                    ? sortedPrices[index].price
                                    : undefined,
                                rules: [
                                  { required: false, message: "Required" },
                                ],
                              })(
                                <InputNumber
                                  formatter={(value) =>
                                    ` ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                            </Form.Item>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="tags mb-28">
                <div className="flex justify-between">
                  <p className="semibold text-18 text-black mb-24">Benefits</p>
                </div>

                <Form.Item label="">
                  {getFieldDecorator("benefits", {
                    initialValue:
                      activeCategory &&
                        activeCategory.benefits &&
                        activeCategory.benefits.length
                        ? activeCategory.benefits.map((ingre) => ingre._id)
                        : undefined,
                    rules: [
                      {
                        required: false,
                        message: "Please select atleast one tag",
                        type: "array",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select a benefit"
                      style={{ width: "100%" }}
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.benefitList &&
                        this.state.benefitList.map((tag) => (
                          <Option value={tag._id} key={tag._id}>
                            {tag.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>

              <div className="pricing-container">
                <div className="">
                  <div className="text-base text-black bold">
                    Additional Settings
                  </div>
                </div>
                {this.renderInputFields(this.state.additionalSettings)}
                {activeCategory && Object.keys(activeCategory).length > 0 && (
                  <ProductRestriction
                    restrictedCountry={this.state.restrictedCountry}
                    category
                    onChangeRestrictedCountry={this.onChangeRestrictedCountry}
                    allowedCountry={this.state.allowedCountry}
                    onChangeAllowedCountry={this.onChangeAllowedCountry}
                    onToggleisProducts={this.onToggleisProducts}
                    onToggleisAvailable={this.onToggleisAvailable}
                    isAvailable={this.state.isAvailable}
                    isProducts={this.state.isProducts}
                  />
                )}
              </div>
            </div>
            {/* products */}
          </div>
        </Form>
        {/* <hr />

        <div className="flex justify-between pb-40">
          {this.isEditMode() ? (
            <Button type="danger">Delete</Button>
          ) : (
            <div></div>
          )}
        </div> */}
      </div>
    );
  }
}

const addCategoryForm = Form.create({ name: "add_category_form" })(AddCategory);

function mapStateToProps(state) {
  const { Category, Products, Tags, Currency } = state;
  return {
    categorysList: Category.Category,
    allProducts: Products.products,
    tags: Tags.tags,
    activeCategory: Category.activeCategory,
    currencyList: Currency.currency,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListCategory: bindActionCreators(actions.getCategoryList, dispatch),
    fetchListBenefits: bindActionCreators(
      benefitActions.getSearchedBenefits,
      dispatch
    ),
    createCategory: bindActionCreators(actions.createCategory, dispatch),
    updateCategory: bindActionCreators(actions.updateCategory, dispatch),
    getActiveCategory: bindActionCreators(actions.getCategory, dispatch),
    setActiveCategory: bindActionCreators(actions.setActiveCategory, dispatch),
    fetchAllCurrency: bindActionCreators(
      currencyActions.getSearchedCurrency,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(addCategoryForm);
