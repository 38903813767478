
const initialState = {
  category: [],
  activeCategory: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_CATEGORY": 
      return setActiveCategory(state, action);
    case "LIST_CATEGORY":
      return listCategory(state, action);
    case "ADD_CATEGORY":
      return addCategory(state, action);
    default:
      return state
  }
}

function setActiveCategory(state, action) {
  return {
    ...state,
    activeCategory: { ...action.payload }
  }
}

function listCategory(state, action) {
  return {
    ...state, 
    category: [ ...action.payload ]
  };
}

function addCategory(state, action) {
  return {
    ...state,
    activeCategory: { ...action.payload }
  };
}