import React, { useState, useEffect } from "react";
import { Timeline } from "antd";
import moment from "moment";
import './historyBloc.scss'

const HistoryBlock = ({ results }) => {
  let logData = [];
  logData =
    results.length > 0 &&
    results.map((element) => {
      let userData = `${element.user.firstName} (${
        element.user.email
      }) ${moment(element.createdAt).format("LLLL")} \n`;
      return { userData: userData, log: element.changes };
    });
  return (
    <div
      style={{ marginTop: "20px" }}
      className="card-layout-clearfix items-center"
    >
      <h4
        style={{ marginTop: "1px", color: "#262626", marginBottom: "20px" }}
        className="text-base"
      >
        History:
      </h4>
      <Timeline className="scrollBar" style={{ height: "200px", overflow: "scroll" }}>
        {logData.length > 0
          ? logData.map((data) => {
              return (
                <Timeline.Item style={{ fontWeight: "bold" }}>
                  {data.userData}

                  {data.log.map((changes) => {
                    let logToPrint = `\n ${changes.key}: ${
                      changes.value.type
                    } `
                    let valueElemnts = Object.keys(changes.value )

                    logToPrint += valueElemnts.includes("from") ? " " + String(changes.value.from) :" " 
                    logToPrint += valueElemnts.includes("to") ? " to " + String(changes.value.to) : " ";
                    return <p>{logToPrint}</p>;
                  })}
                </Timeline.Item>
              );
            })
          : ""}
      </Timeline>
    </div>
  );
};

export default HistoryBlock;
