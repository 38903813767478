import loginApi from "services/login";
import Config from 'services/config/apiUrl'
// export function loginRequest(result) {
//   return {
//     type: "LOGIN_DETAILS",
//     payload: result
//   };
// };

export const loginRequest = (payload) => async (dispatch) => {
  try {
    let res = await loginApi.loginRequest(payload);
    if (res) {
      //localStorage.setItem('userDetails', JSON.stringify(res))
      localStorage.setItem("authToken", res.authToken);
      let name = "";
      if (res.firstName) {
        name += res.firstName;
      }
      if (res.lastName) {
        name += res.lastName;
      }
      localStorage.setItem("name", name);
      // dispatch(loginRequest(res));
      if (res.role) {
        localStorage.setItem("userType", res.role);
      }
      if (res.permissions) {
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
      }
      if (res.allowedCountry) {
        //delete res.allowedCountry.AU;
        localStorage.setItem("allowedCountry", JSON.stringify(res.allowedCountry));
      }
      if (res.allowedOrderType) {
        localStorage.setItem(
          "allowedOrderType",
          JSON.stringify(res.allowedOrderType)
        );
      }
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};

export const verifyGoogleLoginRequest = (payload) => async (dispatch) => {
  try {
    let res = await loginApi.verifyGoogleLoginRequest(payload);
    if (res) {
      //localStorage.setItem('userDetails', JSON.stringify(res))
      localStorage.setItem("authToken", res.authToken);
      let name = "";
      if (res.firstName) {
        name += res.firstName;
      }
      if (res.lastName) {
        name += res.lastName;
      }
      localStorage.setItem("name", name);
      // dispatch(loginRequest(res));
      if (res.role) {
        localStorage.setItem("userType", res.role);
      }
      if (res.permissions) {
        localStorage.setItem("permissions", JSON.stringify(res.permissions));
      }
      if (res.allowedCountry) {
        //delete res.allowedCountry.AU;
        localStorage.setItem("allowedCountry", JSON.stringify(res.allowedCountry));
      }
      if( res.allowedOrderType){
         localStorage.setItem(
           "allowedOrderType",
           JSON.stringify(res.allowedOrderType)
         );
      }
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};

export const googleLoginRequest = () => {
  window.open(`${Config.API_URL}${loginApi.getGoogleLoginPage()}`, '_self');
}
