import React, { Component } from "react";
import "./addDiscount.scss";
import {
  Icon,
  Select,
  Input,
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  notification,
  message,
  Checkbox,
  DatePicker,
  Upload,
  Radio,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/discount";
import * as productActions from "actions/products";
import * as customerActions from "actions/customer";
import * as categoryActions from "actions/category";
import * as currencyActions from "actions/currency";
import { filterObj, getBase64, beforeUpload, UploadImage } from "utils/helpers";
import moment from "moment";
import { ORDER_TYPES_MAPPER, PromoCategory } from "utils/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HistoryBlock from "components/historyBloc/historyBloc";
import {getDiscountHistory} from "services/discount/index"
import API from "services/config/apiUrl";
import TextArea from "antd/lib/input/TextArea";
import ShortUniqueId from "short-unique-id";

const { Option } = Select;
const { confirm } = Modal;
class AddDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "create",
      loading: false,
      selectedDiscountType: "",
      appliesToEntireOrder: true,
      appliesToAllUsers: true,
      selectedPromotionCategory: "",
      freeShipping: false,
      oncePerUser: false,
      newUserOnly: false,
      imageLoading: false,
      redemptionLimitPerPromo: 1,
      samplePromoCode: this.getRandomPromoCode(6),
      bannerImgUrl: "",
      selectedOrderType: "",
      setEndDate: false,
      randomPromoCode: undefined,
      promoLength: 6,
      restrictedProductCount: 1,
      showEndDate: "",
      orderType: {
        oneTime: false,
        firstSubscription: false,
        allSubscription: false,
      },
      selectedPromotionType: "",
      historyLogs: [],
      promoLink:"",
    };
  }

  isEditMode() {
    return this.state.mode === "edit";
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (values.minimumAmount){
        values["minimumAmount"] = values.minimumAmount.map((amount, i) => {
          const currencyObj = filterObj(
            ["id", "code"],
            this.props.currencyList[i]
          );
          return {
            ...amount,
            currency: currencyObj,
          };
        });
      }
        
      if (values.fixedValue) {
        values["fixedValue"] = values.fixedValue.map((amount, i) => {
          const currencyObj = filterObj(
            ["id", "code"],
            this.props.currencyList[i]
          );
          return {
            ...amount,
            currency: currencyObj,
          };
        });
      }
      if(values?.discountType === 'percentage_with_diff_prices') {
        if(!values?.orderType?.oneTime) {
          message.error("One time is required in Order Type");
          return;
        } else if(!values?.orderType?.allSubscription && !values?.orderType?.firstSubscription) {
          message.error("Please select at least one subscription option in Order Type");
          return;
        }
      } else {
        if(!values?.orderType?.oneTime && !values?.orderType?.allSubscription && !values?.orderType?.firstSubscription) {
          message.error("Please select at least one option in Order Type");
          return;
        }
      }
      
      // if (!this.state.showEndDate) {
      //   values.endDate = "";
      // }

      if(!values.orderType.allSubscription && !values.orderType.firstSubscription && !values.orderType.oneTime) {
        message.error("Please select order type");
        return;
      }

      this.setState({
        loading: true,
      });
      if (this.isEditMode()) {
        this.props
          .updateDiscount(this.state.activeDiscountId, values)
          .then((res) => {
            this.setState({
              loading: false,
            });
            if (!res) {
              message.error("Something went wrong!");
              return;
            }
            message.success("Discount Updated");
          });
      } else {
        this.props.createDiscount(values).then((res) => {
          this.setState({
            loading: false,
          });
          if (!res) {
            message.error("Something went wrong!");
            return;
          }
          message.success("Discount successfully created");
          this.props.history.push("/discounts");
        });
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;
    this.setState({ userType: localStorage.getItem("userType") });
    if (params.id !== undefined) {
      this.props.getDiscount(params.id, params.type).then((res) => {
        if (res) {
          this.setState({
            mode: "edit",
            activeDiscountId: res.id,
            appliesToAllUsers: res.appliesTo.allUsers,
            appliesToEntireOrder: res.appliesTo.entireOrder,
            selectedDiscountType: res.discountType,
            selectedPromotionType: res.promotionType,
            freeShipping: res.freeShipping,
            bannerImgUrl: res?.promoBannerImg?.mediaUrl,
            selectedPromotionCategory: res?.category,
            restrictedProductCount:
              res?.appliesTo?.applyOnlyForProducts?.products.length,
            promoLink: `${API.WEB_URL}?p=${res?.code}`,
          });
          //this.props.form.setFieldsValue({ category : res?.category});
        }
      });
      getDiscountHistory(params.id).then((res) => {
        if(res) {
          this.setState({historyLogs:res.result})
        }
      });
    } else {
      this.props.setActiveDiscount(null);
    }

    this.props.fetchAllProducts();
    this.props.fetchCustomerList();
    this.props.fetchAllCurrency();
    this.props.fetchCategoryList();
  }
  // method to show end date
  onEndDateChange = (e) => {
    if (!e.target.checked) {
      this.props.form.setFieldsValue({ endDate: "" });
    }

    this.setState((prevState) => {
      return {
        showEndDate: !prevState.showEndDate,
      };
    });
  };

  getRandomPromoCode =(  promoLength ) =>{
    const promoLengthForm = this.props.form.getFieldValue(
      "bulkPromo.promoLength"
    );
   
    let length = promoLengthForm || promoLength;
    if(length < 5){
      return this.state.samplePromoCode;
    }
   const uid = new ShortUniqueId({
     dictionary: "alphanum_upper",
     length: length,
   });
    return uid();
  }

  getTotalRedemption = ( ) =>{
    const randomPromoCode = this.props.form.getFieldValue(
      "bulkPromo.randomPromoCode"
    );
    
    let ManualPromoCount = 0;
    const bulkPromoPromoCodes = this.props.form.getFieldValue("bulkPromo.promoCodes");
    if (bulkPromoPromoCodes && bulkPromoPromoCodes.trim()) {
       ManualPromoCount = bulkPromoPromoCodes.trim().split("\n").length;
    }

    const RandomPromoCount = this.props.form.getFieldValue("bulkPromo.promoCount");
    let promoCount =0
    if(this.isEditMode()){
      promoCount =
        ManualPromoCount + this.props.activeDiscount.bulkPromo.promoCount;
    }else{
       promoCount = ManualPromoCount || RandomPromoCount;
    }
   
    let maxRedemptionPerPromo = this.props.form.getFieldValue(
       "redemptionLimit.perPromo"
     );
    //let maxRedemptionPerPromo = this.state.redemptionLimitPerPromo;

    let totalRedemption = promoCount * maxRedemptionPerPromo;
    return totalRedemption;

  }

  // method to show end date
  handleActivateCustomer = (discountId) => {
    let payload = {
      isActive: true,
    };
    confirm({
      title: `This discount would be activated again?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.updateDiscount(discountId, payload).then((res) => {
          this.props.history.push("/discounts");
          if (res) {
            message.success("Discount activated successfully");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleImageChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ imageLoading: true });
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (hoverImg) => {
        if (info.file.type) {
        this.props.form.setFieldsValue({
          "promoBannerImg.mediaType": info.file.type,
        });
      }
        this.setState({
          bannerImgUrl: hoverImg,
          imageLoading: false,
        });
        // this.props.form.setFieldsValue({
        //   promoBannerImg.mediaType: hoverImg,
        // });
      });
    }
  };

  getComplimentaryProductList = (allProducts ) =>{
    let productList=[];
    let products = this.props.activeDiscount?.appliesTo?.applyOnlyForProducts?.products;
    if(products){
      for( let i=0;  i < products.length;i++ ){
        let prod = products[i];
        let items = allProducts.find(
          (e) => String(e.id) == String(prod.product)
        );
        if(items){
          let prodSSU = items.ssu;
          let prodQuantity = prod.quantity;
          let prodName = ` ${prodSSU} X ${prodQuantity} `;
          productList.push(prodName);
        }
        
      }
    }
   return productList.join(',');
  }

  setDefaultMaxPromoRedemption= ( ) =>{
    if( this.state.selectedDiscountType == "free" &&
      this.props.match.params.type == 'bulk'  && this.state.randomPromoCode){
        return true
      }else{
        return false
      }
  }

  minimumAllowedValue = () => {
    let minAmount = 0;
    let percentageValue = this.props.form.getFieldValue("percentageValue");
    if (percentageValue == 100) {
      minAmount = 0;
    }
    return minAmount;
  };

  handleDeactivateCustomer = (discountId) => {
    let payload = {
      isActive: false,
      code: this.props.activeDiscount.code,
    };
    confirm({
      title: `Are you sure you want to deactivate this discount?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.updateDiscount(discountId, payload).then((res) => {
          this.props.history.push("/discounts");
          if (res) {
            message.success("Discount deactivated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.file && e.file.response) {
      return e.file.response;
    }
    return e.file;
  };
  render() {
    const RadioGroup = Radio.Group;
    
    const isBulkPromo = this.props.match.params.type  == 'bulk'? true: false ;
    const uploadButton = (loading) => (
      <div>
        <Icon type={loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };
    const {
      getFieldDecorator,
      getFieldsValue,
      setFieldsValue,
    } = this.props.form;
    const {
      allProducts,
      customerList,
      activeDiscount,
      categoryList,
    } = this.props;
    return (
      <div
        className="discount-page"
        style={{ marginLeft: "200px", paddingBottom: "30px" }}
      >
        <Form>
          <div className="flex items-center justify-between w-full">
            <Link to="/discounts">
              <div className="flex items-center justify-start cursor-pointer">
                <div>
                  <Icon className="mr-4" type="left" />
                </div>
                <div className="pb-2 text-14">Back</div>
              </div>
            </Link>
            {/* save btn main */}
            <Button
              type="primary"
              loading={this.state.loading}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
          <h4
            style={{ color: "#373873", fontSize: "30px", marginBottom: "17px" }}
            className=""
          >
            {this.isEditMode()
              ? `${
                  activeDiscount
                    ? activeDiscount.code || activeDiscount.bulkPromo.promoTitle
                    : ""
                }`
              : "Create Discount"}
          </h4>
          <div
            style={{ marginBottom: "15px" }}
            className="flex items-center feature-wrap"
          >
            {this.isEditMode() && (
              <>
                {activeDiscount && activeDiscount.isActive ? (
                  <div
                    onClick={() =>
                      this.handleDeactivateCustomer(activeDiscount.id)
                    }
                    className="flex items-center mr-20 cursor-pointer"
                  >
                    <Icon type="stop" className="mr-4" />
                    <div>Deactivate</div>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      this.handleActivateCustomer(activeDiscount.id)
                    }
                    className="flex items-center mr-20 cursor-pointer"
                  >
                    <Icon type="redo" className="mr-4" />
                    <div>Reactivate this discount</div>
                  </div>
                )}
              </>
            )}

            {/* <div className="flex items-center">
                <Icon type="retweet" className="mr-4" />
                <div>Reset password</div>
              </div> */}
          </div>
          {/* discoun type */}
          <div className="flex w-full">
            <div style={{ maxWidth: "571px", flex: 1 }}>
              <div className="discount-title-card">
                <Form.Item className="d-none">
                  {getFieldDecorator(`isBulkPromo`, {
                    initialValue: isBulkPromo,
                  })(<Input />)}
                </Form.Item>
                <div
                  style={{ paddingBottom: "16px" }}
                  className="flex items-center justify-between"
                >
                  <h4 className="text-base" style={{ color: "#262626" }}>
                    Promotion Type
                  </h4>
                </div>
                <Form.Item>
                  {getFieldDecorator(`promotionType`, {
                    initialValue: activeDiscount
                      ? activeDiscount.promotionType
                      : undefined,
                    rules: [{ required: true, message: "Required" }],
                  })(
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select promotion type"
                      disabled={this.isEditMode() ? true : false}
                      onChange={(type) => {
                        this.setState({
                          selectedPromotionType: type,
                        });
                      }}
                    >
                      {this.state.userType == "admin" && !isBulkPromo ? (
                        <Option value={"automatic"}>Automatic</Option>
                      ) : (
                        ""
                      )}
                      <Option value={"promoCode"}>Promo Code</Option>
                      <Option value={"buy_x_get_y"}>Buy X Get Y</Option>
                      <Option value={"spend_x_get_y"}>Spend X Get Y</Option>
                    </Select>
                  )}
                </Form.Item>
                {this.state.selectedPromotionType && (
                  <>
                    <div
                      style={{ paddingBottom: "16px" }}
                      className="flex items-center justify-between"
                    >
                      <h4 className="text-base" style={{ color: "#262626" }}>
                        Discount Type
                      </h4>
                    </div>

                    {this.state.selectedPromotionType != "buy_x_get_y" &&
                      this.state.selectedPromotionType != "spend_x_get_y" && (
                        <Form.Item>
                          {getFieldDecorator(`discountType`, {
                            initialValue: activeDiscount
                              ? activeDiscount.discountType
                              : undefined,
                            rules: [{ required: true, message: "Required" }],
                          })(
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select discount type"
                              onChange={(type) => {
                                this.setState({
                                  selectedDiscountType: type,
                                });
                              }}
                            >
                              <Option value={"percentage"}>Percentage</Option>
                              <Option value={"percentage_with_diff_prices"}>
                                Different Percentage discount for Subscription
                                and One-time
                              </Option>
                              <Option value={"fixed"}>Fixed</Option>
                              <Option value={"free_shipping"}>
                                Free Shipping
                              </Option>
                              <Option value={"free"}>Complimentary</Option>
                            </Select>
                          )}
                        </Form.Item>
                      )}
                    {this.state.selectedPromotionType == "buy_x_get_y" && (
                      <Form.Item>
                        {getFieldDecorator(`discountType`, {
                          initialValue: activeDiscount
                            ? activeDiscount.discountType
                            : undefined,
                          rules: [{ required: true, message: "Required" }],
                        })(
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select discount type"
                            onChange={(type) => {
                              this.setState({
                                selectedDiscountType: type,
                              });
                            }}
                          >
                            <Option value={"free"}>Complimentary</Option>
                            <Option value={"percentage"}>Percentage</Option>
                          </Select>
                        )}
                      </Form.Item>
                    )}
                    {this.state.selectedPromotionType == "spend_x_get_y" && (
                      <Form.Item>
                        {getFieldDecorator(`discountType`, {
                          initialValue: activeDiscount
                            ? activeDiscount.discountType
                            : undefined,
                          rules: [{ required: true, message: "Required" }],
                        })(
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select discount type"
                            onChange={(type) => {
                              this.setState({
                                selectedDiscountType: type,
                              });
                            }}
                          >
                            <Option value={"free_product"}>Free Product</Option>
                            <Option value={"percentage"}>
                              {" "}
                              Percentage off{" "}
                            </Option>
                            <Option value={"fixed"}> Flat off</Option>
                            <Option value={"free_shipping"}>
                              Free Shipping
                            </Option>
                          </Select>
                        )}
                      </Form.Item>
                    )}
                    {this.state.selectedDiscountType && (
                      <>
                        <div
                          style={{ paddingBottom: "16px" }}
                          className="flex items-center justify-between"
                        >
                          <h4
                            className="text-base"
                            style={{ color: "#262626" }}
                          >
                            Promotion Category
                          </h4>
                        </div>
                        <Form.Item>
                          {getFieldDecorator(`category`, {
                            initialValue: activeDiscount
                              ? String(activeDiscount.category)
                              : undefined,
                            rules: [{ required: true, message: "Required" }],
                          })(
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select promotion Category"
                              disabled={this.isEditMode() ? true : false}
                              value={this.state.selectedPromotionCategory}
                              onChange={(type) => {
                                this.setState({
                                  selectedPromotionCategory: type,
                                });
                              }}
                            >
                              {PromoCategory.map((cat) => (
                                <Option key={cat.key} value={cat.key}>
                                  {cat.label}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </>
                    )}
                    {this.state.selectedDiscountType == "free" && (
                      <>
                        <div
                          style={{ paddingBottom: "16px" }}
                          className="flex items-center justify-between"
                        >
                          <h4
                            className="text-base"
                            style={{ color: "#262626" }}
                          >
                            Type
                          </h4>
                        </div>
                        <Form.Item>
                          {getFieldDecorator(`type`, {
                            initialValue: activeDiscount
                              ? activeDiscount.type
                              : undefined,
                            rules: [{ required: true, message: "Required" }],
                          })(
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select order type"
                              onChange={(type) => {
                                this.setState({
                                  selectedOrderType: type,
                                });
                              }}
                            >
                              {Object.keys(ORDER_TYPES_MAPPER).map(
                                (type, index) => (
                                  <Option
                                    className="capitalize"
                                    value={type}
                                    key={index}
                                  >
                                    {ORDER_TYPES_MAPPER[type]}
                                  </Option>
                                )
                              )}
                            </Select>
                          )}
                        </Form.Item>
                      </>
                    )}
                  </>
                )}
              </div>
              {isBulkPromo && (
                <div className="discount-title-card">
                  <h4 className="text-base" style={{ color: "#262626" }}>
                    Bulk Discount set
                  </h4>
                  <h5
                    className="text-base"
                    style={{
                      color: "#262626",
                      paddingTop: "16px",
                      fontSize: "12px",
                      lineHeight: "16px",
                    }}
                  >
                    Title
                  </h5>
                  <div
                    style={{ paddingBottom: "0px" }}
                    className="flex items-center justify-between"
                  ></div>
                  <Form.Item>
                    {getFieldDecorator(`bulkPromo.promoTitle`, {
                      initialValue:
                        activeDiscount && activeDiscount.bulkPromo
                          ? activeDiscount.bulkPromo.promoTitle
                          : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input placeholder="" />)}
                    <h6
                      className="text-base"
                      style={{
                        color: "#262626",
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                    >
                      The name of the discount codes will be grouped under
                    </h6>{" "}
                  </Form.Item>

                  {
                    <Form.Item>
                      {getFieldDecorator(`bulkPromo.randomPromoCode`, {
                        initialValue:
                          activeDiscount && activeDiscount.bulkPromo
                            ? activeDiscount.bulkPromo.randomPromoCode
                            : undefined,
                        rules: [{ required: true, message: "Required" }],
                      })(
                        <RadioGroup
                          name={"rb1"}
                          onChange={(e) => {
                            this.setState({ randomPromoCode: e.target.value });
                          }}
                          disabled={this.isEditMode() ? true : false}
                        >
                          <Radio
                            defaultChecked={false}
                            style={radioStyle}
                            value={true}
                            disabled={this.isEditMode() ? true : false}
                            onChange={() => {
                              this.props.form.setFieldsValue({
                                "redemptionLimit.isAllowed": true,
                              });
                              this.props.form.setFieldsValue({
                                "redemptionLimit.perPromo": 1,
                              });
                            }}
                          >
                            Generate Random Code
                          </Radio>
                          <Radio
                            style={radioStyle}
                            value={false}
                            onChange={() => {
                              this.props.form.setFieldsValue({
                                "redemptionLimit.isAllowed": false,
                              });
                              this.props.form.setFieldsValue({
                                "redemptionLimit.perPromo": 1,
                              });
                            }}
                            disabled={this.isEditMode() ? true : false}
                          >
                            Provide Specific codes
                          </Radio>
                        </RadioGroup>
                      )}
                    </Form.Item>
                  }

                  {!this.isEditMode() &&
                    this.state.randomPromoCode === true && (
                      <>
                        <div className="flex items-center justify-between">
                          <Form.Item>
                            <h6
                              className="text-base"
                              style={{
                                color: "#262626",
                                fontSize: "12px",
                                lineHeight: "16px",
                              }}
                            >
                              Number of codes to generate
                            </h6>
                            {getFieldDecorator(`bulkPromo.promoCount`, {
                              initialValue:
                                activeDiscount && activeDiscount.bulkPromo
                                  ? activeDiscount.bulkPromo.promoCount
                                  : 1,
                              rules: [{ required: true, message: "Required" }],
                            })(<InputNumber min={1} max={500} />)}
                          </Form.Item>

                          <Form.Item>
                            <h6
                              className="text-base"
                              style={{
                                color: "#262626",
                                fontSize: "12px",
                                lineHeight: "16px",
                              }}
                            >
                              Code Length
                            </h6>
                            {getFieldDecorator(`bulkPromo.promoLength`, {
                              initialValue:
                                activeDiscount && activeDiscount.bulkPromo
                                  ? activeDiscount.bulkPromo.promoLength
                                  : this.state.promoLength,
                              rules: [{ required: true, message: "Required" }],
                            })(
                              <InputNumber
                                min={5}
                                max={10}
                                onChange={(n) => {
                                  //let samplecode = this.getRandomPromoCode();
                                  this.setState({
                                    promoLength: n,
                                    samplePromoCode: this.getRandomPromoCode(n),
                                  });
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>
                        <div className="">
                          <h6
                            className="text-base"
                            style={{
                              color: "#262626",
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Sample Promo code:
                          </h6>
                          {this.state?.samplePromoCode}
                        </div>
                      </>
                    )}
                  {this.isEditMode() &&
                    activeDiscount?.bulkPromo?.randomPromoCode == false &&
                    this.state.randomPromoCode == undefined && (
                      <div style={{ marginBottom: "10px" }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.setState({
                              randomPromoCode:
                                activeDiscount?.bulkPromo?.randomPromoCode,
                            });
                          }}
                        >
                          Add More codes
                        </Button>
                      </div>
                    )}
                  {this.isEditMode() &&
                    this.state.randomPromoCode === false && (
                      <>
                        <Button
                          type="primary"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            this.setState({
                              randomPromoCode: undefined,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          style={{ marginBottom: "10px", marginLeft: "10px" }}
                          onClick={() => {
                            this.props
                              .addMoreDiscount(this.props.match.params.id, {
                                promoCodes: this.props.form.getFieldValue(
                                  "bulkPromo.promoCodes"
                                ),
                              })
                              .then((result) => {
                                if (result?.promoSuccess) {
                                  let promoSuccess = result?.promoSuccess;
                                  notification.success({
                                    message: "promo codes Added successfully",
                                    description: ` ${promoSuccess.length} code Saved successfully`,
                                  });
                                }

                                if (result?.promoFailed) {
                                  let promoFailed = result?.promoFailed;
                                  notification.error({
                                    message: "Promo codes failed",
                                    description: ` ${
                                      promoFailed.length
                                    } code Failed: ${promoFailed.join(",")}`,
                                    duration: 600,
                                  });
                                }
                              });
                            this.setState({
                              randomPromoCode: undefined,
                            });
                          }}
                        >
                          Save
                        </Button>
                      </>
                    )}
                  {this.state.randomPromoCode === false && (
                    <>
                      <h6
                        className="text-base"
                        style={{
                          color: "#262626",
                          fontSize: "12px",
                          lineHeight: "16px",
                        }}
                      >
                        Codes to import (One code per line)
                      </h6>
                      <Form.Item>
                        {getFieldDecorator(`bulkPromo.promoCodes`, {
                          initialValue:
                            activeDiscount && activeDiscount.bulkPromo
                              ? activeDiscount.bulkPromo.promoCodes
                              : undefined,
                          rules: [{ required: true, message: "Required" }],
                        })(<TextArea rows={8} />)}
                      </Form.Item>
                    </>
                  )}
                  <div>
                    <Form.Item>
                      {getFieldDecorator("redemptionLimit.isAllowed", {
                        initialValue:
                          activeDiscount &&
                          activeDiscount.redemptionLimit &&
                          activeDiscount.redemptionLimit.isAllowed
                            ? activeDiscount.redemptionLimit.isAllowed
                            : this.setDefaultMaxPromoRedemption(),
                        valuePropName: "checked",
                      })(
                        <Checkbox
                          disabled={this.setDefaultMaxPromoRedemption()}
                          onChange={() => {
                            // this.props.form.setFieldsValue({
                            //   "redemptionLimit.isAllowed":
                            //     this.getTotalRedemption(),
                            // });
                          }}
                        >
                          {" "}
                          Add Max Promo Redemptions
                        </Checkbox>
                      )}
                    </Form.Item>
                    {this.props.form.getFieldValue(
                      "redemptionLimit.isAllowed"
                    ) == true && (
                      <>
                        <Form.Item>
                          <h6
                            className="text-base"
                            style={{
                              color: "#262626",
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Max Redemptions Per Promo Code
                          </h6>
                          {getFieldDecorator(`redemptionLimit.perPromo`, {
                            initialValue:
                              activeDiscount &&
                              activeDiscount.redemptionLimit &&
                              activeDiscount.redemptionLimit.perPromo
                                ? activeDiscount.redemptionLimit.perPromo
                                : 1,
                            rules: [{ required: true, message: "Required" }],
                          })(
                            <InputNumber
                              min={1}
                              max={100000}
                              onChange={(n) => {
                                //this.setState({ redemptionLimitPerPromo: n });
                              }}
                              disabled={this.setDefaultMaxPromoRedemption()}
                            />
                          )}
                        </Form.Item>
                        <Form.Item>
                          <h6
                            className="text-base"
                            style={{
                              color: "#262626",
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Total Redemptions :
                          </h6>
                          {getFieldDecorator(
                            `redemptionLimit.totalRedemption`,
                            {
                              initialValue: this.getTotalRedemption() || 1,

                              rules: [{ required: true, message: "Required" }],
                            }
                          )(
                            <InputNumber
                              min={1}
                              max={100000000}
                              disabled={true}
                            />
                          )}
                        </Form.Item>
                        {/* <p className="">
                          <h6
                            className="text-base"
                            style={{
                              color: "#262626",
                              fontSize: "12px",
                              lineHeight: "16px",
                            }}
                          >
                            Total Redemptions :
                          </h6>
                          {this.getTotalRedemption()}
                        </p> */}
                      </>
                    )}
                  </div>
                </div>
              )}
              {/* Code */}
              {!isBulkPromo && (
                <div className="discount-title-card">
                  <div
                    style={{ paddingBottom: "16px" }}
                    className="flex items-center justify-between"
                  >
                    <h4 className="text-base" style={{ color: "#262626" }}>
                      Code
                    </h4>
                    {/* <h4 style={{ color: "#363874", fontSize: "14px" }}>
              Generate Code
            </h4> */}
                  </div>
                  <Form.Item>
                    {getFieldDecorator(`code`, {
                      initialValue: activeDiscount
                        ? activeDiscount.code
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input placeholder="e.g. LOVEFROMYOURS" />)}
                  </Form.Item>
                  <h4
                    style={{
                      paddingTop: "16px",
                      fontSize: "14px",
                      color: "#656565",
                    }}
                    className=""
                  >
                    Customers will enter this code at checkout
                  </h4>
                  <Form.Item>
                    {getFieldDecorator("redemptionLimit.isAllowed", {
                      initialValue:
                        activeDiscount &&
                        activeDiscount.redemptionLimit &&
                        activeDiscount.redemptionLimit.isAllowed
                          ? activeDiscount.redemptionLimit.isAllowed
                          : false,
                      valuePropName: "checked",
                    })(<Checkbox> Add Max Promo Redemptions</Checkbox>)}
                  </Form.Item>

                  {this.props.form.getFieldValue("redemptionLimit.isAllowed") ==
                    true && (
                    <>
                      <Form.Item>
                        <h6
                          className="text-base"
                          style={{
                            color: "#262626",
                            fontSize: "12px",
                            lineHeight: "16px",
                          }}
                        >
                          Total Redemptions :
                        </h6>
                        {getFieldDecorator(`redemptionLimit.totalRedemption`, {
                          initialValue: 1,

                          rules: [{ required: true, message: "Required" }],
                        })(<InputNumber min={1} max={10000000} />)}
                      </Form.Item>
                    </>
                  )}
                </div>
              )}
              {/* description */}
              <div className="discount-title-card">
                <div
                  style={{ paddingBottom: "16px" }}
                  className="flex items-center justify-between"
                >
                  <h4 className="text-base" style={{ color: "#262626" }}>
                    Description
                  </h4>
                </div>
                <Form.Item>
                  {getFieldDecorator(`description`, {
                    initialValue: activeDiscount
                      ? activeDiscount.description
                      : undefined,
                    rules: [{ required: true, message: "Required" }],
                  })(<Input placeholder="e.g. Get 50% of on your order." />)}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator("promoBannerImg.mediaType", {
                    initialValue:
                      activeDiscount && activeDiscount.promoBannerImg
                        ? activeDiscount.promoBannerImg.mediaType
                        : undefined,
                  })(<span></span>)}
                </Form.Item>
                {!isBulkPromo && (
                  <>
                    <Form.Item extra="Upload Banner Image">
                      {getFieldDecorator("promoBannerImg.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue:
                          activeDiscount && activeDiscount.promoBannerImg
                            ? activeDiscount.promoBannerImg.mediaUrl
                            : undefined,
                        rules: [
                          {
                            required: false,
                            message: "Please upload a main image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={this.handleImageChange}
                          customRequest={UploadImage}
                        >
                          {!this.state.imageLoading &&
                          this.state.bannerImgUrl ? (
                            <img
                              src={this.state.bannerImgUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton(this.state.imageLoading)
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <h4 className="text-base" style={{ color: "#262626" }}>
                      Add Banner tittle
                    </h4>
                    <Form.Item>
                      {getFieldDecorator(`bannerTitle`, {
                        initialValue: activeDiscount
                          ? activeDiscount.bannerTitle
                          : undefined,
                        rules: [{ required: false, message: "" }],
                      })(<Input placeholder="Banner tittle" />)}
                    </Form.Item>
                    <h4 className="text-base" style={{ color: "#262626" }}>
                      Add Banner Sub text
                    </h4>
                    <Form.Item>
                      {getFieldDecorator(`bannerSubtitle`, {
                        initialValue: activeDiscount
                          ? activeDiscount.bannerSubtitle
                          : undefined,
                        rules: [{ required: false, message: "" }],
                      })(<Input placeholder="Banner Sub text" />)}
                    </Form.Item>
                  </>
                )}
              </div>
            </div>
            {this.isEditMode() && (
              <div className="flex flex-col justify-between flex-1 ml-20">
                <div className="summary discount-title-card">
                  <div className="flex items-center justify-between">
                    <h4 className="text-base" style={{ color: "#262626" }}>
                      Summary
                    </h4>
                  </div>

                  <div className="flex items-center justify-between">
                    <p className="discount-name">{`${
                      (activeDiscount && activeDiscount.code) ||
                      activeDiscount?.bulkPromo?.promoTitle
                    }`}</p>
                    <p
                      className={`discount-status ${
                        activeDiscount && activeDiscount.isActive
                          ? "active"
                          : "inactive"
                      }`}
                    >
                      {`${
                        activeDiscount && activeDiscount.isActive
                          ? "Active"
                          : "Inactive"
                      }`}
                    </p>
                  </div>
                  <ul className="bordered padding">
                    <li>
                      <p>{`${activeDiscount && activeDiscount.description}`}</p>
                    </li>

                    {activeDiscount && activeDiscount.appliesTo.allUsers && (
                      <li>
                        <p>For Everyone</p>
                      </li>
                    )}
                    {activeDiscount &&
                      activeDiscount?.usageLimits?.oncePerUser && (
                        <li>
                          <p>One use per customer</p>
                        </li>
                      )}

                    {activeDiscount &&
                      activeDiscount?.usageLimits?.newUserOnly && (
                        <li>
                          <p>Only for new customer</p>
                        </li>
                      )}
                    <li>
                      <p>
                        Active from{" "}
                        {`${
                          activeDiscount &&
                          moment(activeDiscount.startDate).format("DD MMM")
                        }`}
                      </p>
                    </li>
                    {this.isEditMode() && (
                      <li>
                        <p>
                          {" "}
                          created By:{" "}
                          {activeDiscount?.createdBy?.firstName +
                            " " +
                            activeDiscount?.createdBy?.lastName}{" "}
                        </p>
                        <p>
                          {" "}
                          created By Email: {activeDiscount?.createdBy?.email}
                        </p>
                      </li>
                    )}
                    {this.isEditMode() && isBulkPromo && (
                      <li>
                        <a
                          href={`/bulk-discounts/codes/${activeDiscount.id}/${activeDiscount?.bulkPromo.promoTitle}`}
                          target="_blank"
                        >
                          {" "}
                          <Button className="dropdown-btn" color="primary">
                            View all code :{" "}
                            {activeDiscount?.bulkPromo?.promoCount}{" "}
                          </Button>
                        </a>
                      </li>
                    )}

                    {this.isEditMode() &&
                      activeDiscount?.appliesTo?.applyOnlyForProducts?.products
                        ?.length > 0 && (
                        <li>
                          <p>
                            {" "}
                            Applicable :
                            {this.getComplimentaryProductList(allProducts)}
                          </p>
                        </li>
                      )}
                  </ul>
                  {this.isEditMode() && !isBulkPromo && (
                    <>
                      <div className="flex items-center justify-between">
                        <h4
                          className="text-base"
                          style={{ color: "#262626", marginTop: "15px" }}
                        >
                          Performance
                        </h4>
                      </div>
                      <ul>
                        <li>
                          <p>
                            {`${
                              activeDiscount && activeDiscount.timesUsed > 0
                                ? activeDiscount.timesUsed
                                : 0
                            } used`}
                          </p>
                        </li>
                      </ul>
                      <div
                        className="flex items-center justify-between"
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                      >
                        <h4
                          className="text-base m0"
                          style={{ color: "#262626" }}
                        >
                          Get a shareable link
                        </h4>
                      </div>
                      <div className="bordered"></div>
                      {this.state.selectedDiscountType == "free" && (
                        <Form.Item>
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  promoLink: `${API.WEB_URL}/cart?p=${activeDiscount?.code}&u=influencer`,
                                });
                              } else if (e.target.checked == false) {
                                this.setState({
                                  promoLink: `${API.WEB_URL}?p=${activeDiscount?.code}`,
                                });
                              }
                            }}
                          >
                            Cart page link
                          </Checkbox>
                        </Form.Item>
                      )}
                      <div
                        className="flex items-center justify-between"
                        style={{ marginTop: "24px", marginBottom: "15px" }}
                      >
                        <Form.Item
                          className="flex-1"
                          style={{ marginBottom: "0px" }}
                        >
                          <Input value={`${this.state.promoLink}`} readOnly />
                        </Form.Item>
                        <CopyToClipboard text={`${this.state.promoLink}`}>
                          <Button
                            type="primary"
                            className="copy-to-clipboard-button"
                          >
                            Copy link
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* discount type */}

          {/* order type */}
          <div className="discount-title-card">
            <div className="flex items-center justify-between">
              <h4 className="text-base" style={{ color: "#262626" }}>
                Order Type{" "}
                {this.state.selectedDiscountType !==
                  "percentage_with_diff_prices" && (
                  <span
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    (Select at least one-option)
                  </span>
                )}
              </h4>
            </div>
            <div style={{ marginTop: "-10px", marginBottom: "-16px" }}>
              <Form.Item>
                {getFieldDecorator("orderType.oneTime", {
                  initialValue: activeDiscount?.orderType?.oneTime
                    ? activeDiscount?.orderType?.oneTime
                    : this.state.selectedDiscountType == "free"
                    ? true
                    : false,
                  valuePropName: "checked",
                })(<Checkbox>One Time</Checkbox>)}
              </Form.Item>
            </div>

            {this.state.selectedPromotionType != "buy_x_get_y" && (
              <>
                <div>Subscription</div>
                <div style={{ marginTop: "-6px" }}>
                  <Form.Item>
                    {getFieldDecorator("orderType.firstSubscription", {
                      initialValue:
                        activeDiscount &&
                        activeDiscount.orderType &&
                        activeDiscount.orderType.firstSubscription
                          ? activeDiscount.orderType.firstSubscription
                          : this.state.selectedDiscountType == "free"
                          ? false
                          : false,
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        onChange={(e) => {
                          this.props.form.setFieldsValue({
                            ["orderType.allSubscription"]: false,
                          });
                          this.props.form.setFieldsValue({
                            ["orderType.firstSubscription"]: true,
                          });
                        }}
                        disabled={
                          this.state.selectedDiscountType == "free"
                            ? true
                            : false
                        }
                      >
                        First Subscription order
                      </Checkbox>
                    )}
                  </Form.Item>
                </div>
                <div style={{ marginTop: "-36px" }}>
                  <Form.Item>
                    {getFieldDecorator("orderType.allSubscription", {
                      initialValue:
                        activeDiscount &&
                        activeDiscount.orderType &&
                        activeDiscount.orderType.allSubscription
                          ? activeDiscount.orderType.allSubscription
                          : this.state.selectedDiscountType == "free"
                          ? false
                          : false,
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        onClick={(e) => {
                          let allSubscription = this.props.form.getFieldValue(
                            "orderType.allSubscription"
                          );
                          if (allSubscription == false) {
                            var modal = document.getElementById("myModal");
                            if (modal) {
                              modal.style.display = "block";
                            }
                            e.target.checked = false;
                          } else {
                            this.props.form.setFieldsValue({
                              ["orderType.allSubscription"]: false,
                            });
                          }
                        }}
                        disabled={
                          this.state.selectedDiscountType == "free"
                            ? true
                            : false
                        }
                      >
                        All subscription orders (if you're not sure,don't select
                        this)
                      </Checkbox>
                    )}
                  </Form.Item>
                </div>
              </>
            )}
          </div>

          {/****************** Values *******************/}
          <div style={{ marginTop: "20px" }} className="card-layout-clearfix">
            <div
              style={{ paddingBottom: "0px" }}
              className="flex items-center justify-between"
            >
              <h4 className="text-base" style={{ color: "#262626" }}>
                Discount Value
              </h4>
            </div>

            {this.state.selectedDiscountType === "free_product" && (
              <>
                <div style={{ margin: "14px 0" }}>
                  <h4 style={{ color: "#656565" }} className="text-14">
                    Select Free Product quantity
                  </h4>
                  <Form.Item style={{ marginRight: "6px" }} extra="">
                    {getFieldDecorator(`freeProduct[0].quantity`, {
                      initialValue: activeDiscount
                        ? activeDiscount.freeProduct[0].quantity
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<InputNumber min={1} max={10} placeholder="0" />)}
                  </Form.Item>
                  <h4 style={{ color: "#656565" }} className="text-14">
                    Select Free Product
                  </h4>
                  <Form.Item>
                    {getFieldDecorator(`freeProduct[0].product`, {
                      initialValue: activeDiscount
                        ? activeDiscount.freeProduct[0].product
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <Select
                        placeholder="Select free  products "
                        style={{ width: "100%" }}
                      >
                        {allProducts &&
                          allProducts
                            .filter(
                              (x) =>
                                (x.productType === "product" ||  x.productType == "merch") &&
                                x?.isPromoApplicable
                            )
                            .map((product) => (
                              <Option value={product.id} key={product.id}>
                                {product.name}
                              </Option>
                            ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </>
            )}
            {(this.state.selectedDiscountType === "percentage" ||
              this.state.selectedDiscountType ===
                "percentage_with_diff_prices") && (
              <div style={{ margin: "14px 0" }}>
                <Form.Item
                  style={{ marginRight: "6px" }}
                  extra=""
                  label={
                    this.state.selectedDiscountType ===
                    "percentage_with_diff_prices"
                      ? `Discount Value (subscription)`
                      : "Discount Value"
                  }
                >
                  {getFieldDecorator(`percentageValue`, {
                    initialValue: activeDiscount
                      ? activeDiscount.percentageValue
                      : undefined,
                    rules: [{ required: true, message: "Required" }],
                  })(<InputNumber min={1} max={100} placeholder="10%" />)}
                </Form.Item>

                {this.state.selectedDiscountType ===
                  "percentage_with_diff_prices" && (
                  <Form.Item
                    style={{ marginRight: "6px" }}
                    extra=""
                    label="Discount Value (one time)"
                  >
                    {getFieldDecorator(`oneTimePercentageValue`, {
                      initialValue: activeDiscount
                        ? activeDiscount.oneTimePercentageValue
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(<InputNumber min={1} max={100} placeholder="10%" />)}
                  </Form.Item>
                )}

                <Form.Item>
                  {getFieldDecorator("freeShipping", {
                    initialValue: activeDiscount
                      ? activeDiscount.freeShipping
                      : false,
                    valuePropName: "checked",
                  })(
                    <Checkbox
                      onChange={(e) => {
                        this.setState({
                          freeShipping: e.target.checked,
                        });
                      }}
                    >
                      Free Shipping
                    </Checkbox>
                  )}
                </Form.Item>
              </div>
            )}

            {this.state.selectedDiscountType === "fixed" && (
              <div style={{ margin: "14px 0" }}>
                <h4 style={{ color: "#656565" }} className="text-14">
                  Discount Value
                </h4>
                {this.props.currencyList &&
                  this.props.currencyList.length > 0 &&
                  this.props.currencyList.map((currency, index) => {
                    return (
                      <>
                        <div
                          className="flex tab-pricing"
                          style={{ padding: "5px" }}
                        >
                          <Form.Item
                            style={{ marginRight: "6px" }}
                            extra={currency.code.toUpperCase()}
                          >
                            {getFieldDecorator(`fixedValue[${index}].price`, {
                              initialValue:
                                activeDiscount &&
                                activeDiscount.fixedValue &&
                                activeDiscount.fixedValue[index]
                                  ? activeDiscount.fixedValue[index].price
                                  : undefined,
                              rules: [{ required: true, message: "Required" }],
                            })(
                              <InputNumber
                                formatter={(value) =>
                                  `${currency.symbol} ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                min={0.1}
                                max={10000000}
                                className="input-value"
                              />
                            )}
                          </Form.Item>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
            <h4
              style={{ marginTop: "13px", color: "#262626" }}
              className="text-14"
            >
              Subscription Discount
            </h4>
            <Form.Item>
              {getFieldDecorator("allowPromoSubDiscount", {
                initialValue: activeDiscount
                  ? activeDiscount.allowPromoSubDiscount
                  : false,
                valuePropName: "checked",
              })(
                <Checkbox
                  disabled={
                    this.state.selectedDiscountType == "free" ? true : false
                  }
                >
                  {" "}
                  Allow Subscription discount with Promo discount{" "}
                </Checkbox>
              )}
            </Form.Item>

            <h4
              style={{ marginTop: "13px", color: "#262626" }}
              className="text-14"
            >
              Applies To
            </h4>
            {this.state.selectedPromotionType != "buy_x_get_y" &&
              this.state.selectedPromotionType != "spend_x_get_y" &&
              this.state.selectedDiscountType !== "free" && (
                <>
                  <Form.Item>
                    {getFieldDecorator("appliesTo.entireOrder", {
                      initialValue: activeDiscount
                        ? activeDiscount.appliesTo.entireOrder
                        : true,
                      valuePropName: "checked",
                    })(
                      <Checkbox
                        onChange={(e) => {
                          this.setState({
                            appliesToEntireOrder: e.target.checked,
                          });
                        }}
                      >
                        Entire Order
                      </Checkbox>
                    )}
                  </Form.Item>
                </>
              )}
            {this.state.selectedPromotionType != "buy_x_get_y" &&
              this.state.selectedPromotionType != "spend_x_get_y" &&
              !this.state.appliesToEntireOrder &&
              this.state.selectedDiscountType !== "free" && (
                <>
                  <Form.Item>
                    {getFieldDecorator(`appliesTo.products`, {
                      initialValue: activeDiscount
                        ? activeDiscount.appliesTo.products
                        : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <Select
                        placeholder="Select products on which this discount will be applied"
                        style={{ width: "100%" }}
                        mode="multiple"
                      >
                        {allProducts &&
                          allProducts
                            .filter(
                              (x) =>
                                (x.productType === "product") &&
                                x?.isPromoApplicable
                            )
                            .map((product) => (
                              <Option value={product.id} key={product.id}>
                                {product.name}
                              </Option>
                            ))}
                      </Select>
                    )}
                  </Form.Item>
                </>
              )}

            {this.state.selectedDiscountType == "free" && (
              <>
                <Form.Item>
                  {getFieldDecorator("appliesTo.applyOnlyForProducts.allowed", {
                    initialValue:
                      activeDiscount &&
                      activeDiscount.appliesTo &&
                      activeDiscount.appliesTo.applyOnlyForProducts.allowed
                        ? activeDiscount.appliesTo.applyOnlyForProducts.allowed
                        : false,
                    valuePropName: "checked",
                  })(
                    <Checkbox
                      onChange={(e) => {
                        // this.setState({
                        //   appliesToEntireOrder: e.target.checked,
                        // });
                      }}
                      disabled={this.isEditMode()}
                    >
                      Apply only to select products
                    </Checkbox>
                  )}
                </Form.Item>

                {this.props.form.getFieldValue(
                  "appliesTo.applyOnlyForProducts.allowed"
                ) == true && (
                  <>
                    <Form.Item>
                      {getFieldDecorator(
                        "appliesTo.applyOnlyForProducts.addPersonalized",
                        {
                          initialValue:
                            activeDiscount &&
                            activeDiscount.appliesTo &&
                            activeDiscount.appliesTo.applyOnlyForProducts
                              .addPersonalized
                              ? activeDiscount.appliesTo.applyOnlyForProducts
                                  .addPersonalized
                              : false,
                          valuePropName: "checked",
                        }
                      )(
                        <Checkbox
                          onChange={(e) => {
                            // this.setState({
                            //   appliesToEntireOrder: e.target.checked,
                            // });
                          }}
                          disabled={this.isEditMode()}
                        >
                          Include Personalised Products
                        </Checkbox>
                      )}
                    </Form.Item>
                    <h6
                      className="text-base"
                      style={{
                        color: "#262626",
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                    >
                      No. of products
                    </h6>

                    <InputNumber
                      min={1}
                      max={10}
                      value={this.state.restrictedProductCount}
                      placeholder="No of products to add"
                      style={{ marginBottom: "20px" }}
                      disabled={this.isEditMode()}
                      onChange={(n) =>
                        this.setState((prevState) => {
                          return {
                            restrictedProductCount:
                              n < 1 || n > 10
                                ? prevState.restrictedProductCount
                                : n,
                          };
                        })
                      }
                    />
                    <>
                      {new Array(this.state.restrictedProductCount)
                        .fill(1)
                        .map((n, index) => (
                          <Row type="flex" justify="" key={index}>
                            <Col span={12}>
                              <Form.Item>
                                <h6
                                  className="text-base"
                                  style={{
                                    color: "#262626",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                  }}
                                >
                                  Select Product
                                </h6>

                                {getFieldDecorator(
                                  `appliesTo.applyOnlyForProducts.products[${index}].product`,
                                  {
                                    initialValue: activeDiscount?.appliesTo
                                      ?.applyOnlyForProducts?.products[index]
                                      ?.product
                                      ? activeDiscount?.appliesTo
                                          ?.applyOnlyForProducts?.products[
                                          index
                                        ]?.product
                                      : undefined,
                                    rules: [
                                      {
                                        required: !this.isEditMode(),
                                        message: "Required",
                                      },
                                    ],
                                  }
                                )(
                                  <Select
                                    placeholder="Select product to add"
                                    // value={
                                    //   this.state.activeProductList[index]?.product
                                    // }
                                    disabled={this.isEditMode()}
                                    onChange={(val) => {
                                      // this.setState({
                                      //   productType: productType,
                                      //   activeProductList: activeProductList,
                                      // });
                                    }}
                                  >
                                    {allProducts &&
                                      allProducts
                                        .filter((prod) => {
                                          if (
                                            ["Essentials", "Merch"].includes(
                                              prod.category.name
                                            )
                                          ) {
                                            if (
                                              prod.name ==
                                                "Deprecated - Clean Slate Exfoliating Pads (5 Mini)" ||
                                              prod.name ==
                                                "Clean Slate Exfoliating Pads"
                                            ) {
                                              return false;
                                            }
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        })
                                        .map((product) => (
                                          <Option
                                            value={product.id}
                                            key={product.id}
                                          >
                                            {product.name}
                                          </Option>
                                        ))}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item style={{ marginLeft: "6px" }} extra="">
                                <h6
                                  className="text-base"
                                  style={{
                                    color: "#262626",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                  }}
                                >
                                  Select Quantity
                                </h6>

                                {getFieldDecorator(
                                  `appliesTo.applyOnlyForProducts.products[${index}].quantity`,
                                  {
                                    initialValue: activeDiscount?.appliesTo
                                      ?.applyOnlyForProducts?.products[index]
                                      ?.quantity
                                      ? activeDiscount?.appliesTo
                                          ?.applyOnlyForProducts?.products[
                                          index
                                        ]?.quantity
                                      : 1,
                                    rules: [
                                      { required: true, message: "Required" },
                                    ],
                                  }
                                )(
                                  <InputNumber
                                    disabled={this.isEditMode()}
                                    min={1}
                                    max={10}
                                    placeholder="0"
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                    </>
                  </>
                )}
              </>
            )}

            <Form.Item>
              {getFieldDecorator("appliesTo.allUsers", {
                initialValue: activeDiscount
                  ? activeDiscount.appliesTo.allUsers
                  : true,
                valuePropName: "checked",
              })(
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      appliesToAllUsers: e.target.checked,
                    });
                  }}
                >
                  All users
                </Checkbox>
              )}
            </Form.Item>
            {!this.state.appliesToAllUsers && (
              <>
                <Form.Item>
                  {getFieldDecorator(`appliesTo.users`, {
                    initialValue: activeDiscount
                      ? activeDiscount.appliesTo.users
                      : undefined,
                    rules: [{ required: true, message: "Required" }],
                  })(
                    <Select
                      placeholder="Select users for whom this discount will be applied"
                      style={{ width: "100%" }}
                      mode="multiple"
                    >
                      {customerList &&
                        customerList.map((customer) => (
                          <Option value={customer.id} key={customer.id}>
                            {customer.firstName || ""} {customer.lastName || ""}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </>
            )}
          </div>
          <div style={{ marginTop: "15px" }} className="card-layout-clearfix">
            <h4
              style={{ marginTop: "13px", color: "#262626" }}
              className="text-14"
            >
              Discount Usage Applicable to:
            </h4>
            <Form.Item>
              {getFieldDecorator("usageLimits.oncePerUser", {
                initialValue: activeDiscount
                  ? activeDiscount.usageLimits
                    ? activeDiscount.usageLimits.oncePerUser
                    : false
                  : false,
                valuePropName: "checked",
              })(
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      oncePerUser: e.target.checked,
                    });
                  }}
                >
                  Once Per user
                </Checkbox>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("usageLimits.newUserOnly", {
                initialValue: activeDiscount
                  ? activeDiscount.usageLimits
                    ? activeDiscount.usageLimits.newUserOnly
                    : false
                  : false,
                valuePropName: "checked",
              })(
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      newUserOnly: e.target.checked,
                    });
                  }}
                >
                  New User Only
                </Checkbox>
              )}
            </Form.Item>
          </div>

          {/* ******************* Customer Buys and Get ******************** */}
          {this.state.selectedPromotionType == "buy_x_get_y" && (
            <div style={{ marginTop: "15px" }} className="card-layout-clearfix">
              <div className="flex items-center justify-between">
                <h4 className="text-base" style={{ color: "#262626" }}>
                  Customer Buys
                </h4>
              </div>

              <h4
                style={{ marginTop: "13px", color: "#262626" }}
                className="text-14"
              >
                Quantity:
              </h4>
              <Form.Item>
                {getFieldDecorator(`customerBuysQuantity`, {
                  initialValue: activeDiscount
                    ? activeDiscount.customerBuysQuantity
                    : undefined,
                  rules: [{ required: true, message: "Required" }],
                })(<Input placeholder="Customer buys quantity" />)}
              </Form.Item>

              <h4
                style={{ marginTop: "13px", color: "#262626" }}
                className="text-14"
              >
                Category:
              </h4>

              <>
                <Form.Item>
                  {getFieldDecorator(`customerBuys`, {
                    initialValue:
                      activeDiscount && activeDiscount.customerBuys
                        ? activeDiscount.customerBuys
                        : undefined,
                    rules: [{ required: true, message: "Required" }],
                  })(
                    <Select
                      placeholder="Select products on which this discount will be applied"
                      style={{ width: "100%" }}
                      //mode="multiple"
                    >
                      {categoryList &&
                        categoryList.map((product) => (
                          <Option value={product.id} key={product.id}>
                            {product.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </>
              <div className="flex items-center justify-between">
                <h4 className="text-base" style={{ color: "#262626" }}>
                  Customer Gets
                </h4>
              </div>
              <h4
                style={{ marginTop: "13px", color: "#262626" }}
                className="text-14"
              >
                Quantity:
              </h4>
              <Form.Item>
                {getFieldDecorator(`customerGetsQuantity`, {
                  initialValue: activeDiscount
                    ? activeDiscount.customerGetsQuantity
                    : undefined,
                  rules: [{ required: true, message: "Required" }],
                })(<Input placeholder="Customer gets quantity" />)}
              </Form.Item>
              <h4
                style={{ marginTop: "13px", color: "#262626" }}
                className="text-14"
              >
                Category:
              </h4>

              <>
                <Form.Item>
                  {getFieldDecorator(`customerGets`, {
                    initialValue:
                      activeDiscount && activeDiscount.customerGets
                        ? activeDiscount.customerGets
                        : undefined,
                    rules: [{ required: true, message: "Required" }],
                  })(
                    <Select
                      placeholder="Select products that will be free"
                      style={{ width: "100%" }}
                      //mode="multiple"
                    >
                      {categoryList &&
                        categoryList.map((product) => (
                          <Option value={product.id} key={product.id}>
                            {product.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </>
            </div>
          )}

          {/* ******************* Minimum requirements ******************** */}

          <div
            style={{ marginTop: "20px" }}
            className={`card-layout-clearfix ${
              this.state.selectedDiscountType == "free" ? "d-none" : ""
            }`}
          >
            <div
              style={{ paddingBottom: "0px" }}
              className="flex items-center justify-between"
            >
              <h4 className="text-base" style={{ color: "#262626" }}>
                Minimum requirements
                <span>
                  {this.state.selectedPromotionType == "spend_x_get_y" && (
                    //applyPostDiscount

                    <Form.Item>
                      {getFieldDecorator(
                        "applyPromotionOnMinValuePostDiscount",
                        {
                          initialValue: activeDiscount
                            ? activeDiscount.applyPromotionOnMinValuePostDiscount
                              ? activeDiscount.applyPromotionOnMinValuePostDiscount
                              : false
                            : false,
                          valuePropName: "checked",
                        }
                      )(
                        <Checkbox
                        // onChange={(e) => {
                        //   this.setState({
                        //     newUserOnly: e.target.checked,
                        //   });
                        // }}
                        >
                          Post Subscription Discount
                        </Checkbox>
                      )}
                    </Form.Item>
                  )}{" "}
                </span>
              </h4>
            </div>

            <div style={{ margin: "14px 0" }}>
              <h4 style={{ color: "#656565" }} className="text-14">
                Amount
              </h4>
              {this.props.currencyList &&
                this.props.currencyList.length > 0 &&
                this.props.currencyList.map((currency, index) => {
                  return (
                    <>
                      <div
                        className={`tab-pricing flex ${
                          this.state.selectedDiscountType == "free"
                            ? "d-none"
                            : ""
                        }`}
                        style={{ padding: "5px" }}
                      >
                        <Form.Item
                          style={{ marginRight: "6px" }}
                          extra={currency.code.toUpperCase()}
                        >
                          {getFieldDecorator(`minimumAmount[${index}].price`, {
                            initialValue:
                              activeDiscount &&
                              activeDiscount.minimumAmount &&
                              activeDiscount.minimumAmount[index]
                                ? activeDiscount.minimumAmount[index].price
                                : this.state.selectedDiscountType == "free"
                                ? 0
                                : undefined,
                            rules: [{ required: true, message: "Required" }],
                          })(
                            <InputNumber
                              formatter={(value) =>
                                `${currency.symbol} ${value}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              min={this.minimumAllowedValue()}
                              max={10000000}
                              className="input-value"
                            />
                          )}
                        </Form.Item>
                      </div>
                    </>
                  );
                })}
              {/* <h4
              style={{ marginTop: "13px", color: "#bfbfbf" }}
              className="text-14"
            >
              Applies to entire order
            </h4>
            <Checkbox onChange={this.checkboxChange}>
              Minimun Quantity Of Items
            </Checkbox> */}
            </div>
          </div>

          {/************* active dates **************/}
          <div style={{ marginTop: "20px" }} className="card-layout-clearfix">
            <div
              style={{ paddingBottom: "0px" }}
              className="flex items-center justify-between"
            >
              <h4 className="text-base" style={{ color: "#262626" }}>
                Active Dates
              </h4>
            </div>
            <Form.Item style={{ marginRight: "6px" }} label="Start date">
              {getFieldDecorator(`startDate`, {
                initialValue: activeDiscount
                  ? moment(activeDiscount.startDate)
                  : undefined,
                rules: [{ required: true, message: "Required" }],
              })(
                <DatePicker
                  placeholder="Select start date"
                  showTime
                  onChange={this.onDateChange}
                  onOk={this.onOk}
                />
              )}
            </Form.Item>
            {/* <div style={{ marginBottom: "24px" }}>
              <Checkbox onChange={this.onEndDateChange}>
                Select End Date
              </Checkbox>
            </div> */}
            {/* {this.state.showEndDate && ( */}
            <Form.Item style={{ marginRight: "6px" }} label="End Date">
              {getFieldDecorator(`endDate`, {
                initialValue: activeDiscount
                  ? moment(activeDiscount.endDate)
                  : moment().add("months", 12),
                rules: [{ required: true, message: "Required" }],
              })(
                // this.state.showEndDate && (
                <DatePicker
                  placeholder="Select end date"
                  showTime
                  onChange={this.onDateChange}
                  onOk={this.onOk}
                />
                // )
              )}
            </Form.Item>
            {/*// )}*/}
          </div>
        </Form>
        <div
          id="myModal"
          className="modal"
          onClick={(e) => {
            var className = e.target.className;
            if (className === "modal") {
              var elem = document.getElementsByClassName(className)[0];
              if (elem) {
                elem.style.display = "none";
              }
            } else {
              return;
            }
          }}
        >
          <div class="modal-content">
            <span
              onClick={() => {
                var modal = document.getElementById("myModal");
                var span = document.getElementsByClassName("close")[0];
                if (span) {
                  modal.style.display = "none";
                }
              }}
              class="close"
            >
              &times;
            </span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  fontWeight: "bold",
                  fontSize: 22,
                }}
              >
                Are your sure....
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "15%",
                }}
              >
                <div
                  onClick={() => {
                    var modal = document.getElementById("myModal");
                    var span = document.getElementsByClassName("close")[0];
                    if (span) {
                      modal.style.display = "none";
                    }
                  }}
                  style={{
                    borderRadius: 50,
                    height: 45,
                    width: 100,
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "red",
                  }}
                >
                  Cancel
                </div>
                <div
                  style={{
                    borderRadius: 50,
                    height: 45,
                    width: 100,
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "green",
                  }}
                  onClick={() => {
                    this.props.form.setFieldsValue({
                      ["orderType.allSubscription"]: true,
                    });
                    this.props.form.setFieldsValue({
                      ["orderType.firstSubscription"]: false,
                    });

                    var modal = document.getElementById("myModal");
                    var span = document.getElementsByClassName("close")[0];
                    if (span) {
                      modal.style.display = "none";
                    }
                    let { orderType } = this.state;
                    orderType.allSubscription = true;
                    orderType.firstSubscription = false;
                    this.setState({ orderType });
                  }}
                >
                  Yes
                </div>
              </div>
            </div>
          </div>
        </div>
        <HistoryBlock results={this.state.historyLogs} />
      </div>
    );
  }
}

const AddDiscountForm = Form.create({ name: "add_ingredients_form" })(
  AddDiscount
);

function mapStateToProps(state) {
  const { Products, Customer, Currency, Discount, Category } = state;
  return {
    allProducts: Products.products,
    customerList: Customer.customerList,
    currencyList: Currency.currency,
    activeDiscount: Discount.activeDiscount,
    categoryList: Category.category,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    fetchCustomerList: bindActionCreators(
      customerActions.getCustomerList,
      dispatch
    ),
    fetchCategoryList: bindActionCreators(
      categoryActions.getCategoryList,
      dispatch
    ),
    fetchAllCurrency: bindActionCreators(
      currencyActions.getSearchedCurrency,
      dispatch
    ),
    createDiscount: bindActionCreators(actions.createDiscount, dispatch),
    updateDiscount: bindActionCreators(actions.updateDiscount, dispatch),
    addMoreDiscount: bindActionCreators(actions.addMoreDiscount, dispatch),
    getDiscount: bindActionCreators(actions.getDiscount, dispatch),
    setActiveDiscount: bindActionCreators(actions.setActiveDiscount, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDiscountForm);
