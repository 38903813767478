import React, { Component } from "react";
import "./addBenefit.scss";
import {
  Icon,
  Tag,
  Select,
  Input,
  Button,
  Upload,
  Form,
  Switch,
  message,
  InputNumber,
  Col,
  Row,
} from "antd";
import { Link } from "react-router-dom";
import { getBase64, beforeUpload, UploadImage } from "utils/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/benefits";
import * as productActions from "actions/products";
import * as tagsActions from "actions/tags";
const { Option } = Select;
const { TextArea } = Input;

class AddBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [
        {
          fieldName: "Benefit",
          key: "name",
        },
      ],
      imageUrl: "",
      imageLoading: false,
      mode: "create",
      activeBenefitId: null,

      numberOfProducts: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setActiveBenefit(null);
    this.props.fetchAllProducts();
    this.props.fetchAllTags();
  }

  isEditMode() {
    return this.state.mode === "edit";
  }
  handleImageChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ imageLoading: true });
      return;
    }
    if (info.file.status === "done") {
      if (info.file.type) {
        this.props.form.setFieldsValue({
          "mainImage.mediaType": info.file.type,
        });
      }
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          imageLoading: false,
        })
      );
    }
  };

  renderTextareaFields = (arr) => {
    const { getFieldDecorator } = this.props.form;

    return arr.map((obj, index) => {
      return (
        <div className="mb-8" key={index}>
          <p className="text-black regular text-14 mb-4">{obj.fieldName}</p>
          <Form.Item>
            {getFieldDecorator(`${obj.key}`, {
              initialValue: this.props.activeBenefit
                ? this.props.activeBenefit[obj.key]
                : undefined,
              rules: [{ required: false, message: "Required" }],
            })(
              <TextArea
                rows={4}
                autoSize={true}
                style={{ resize: "none", height: "auto" }}
              />
            )}
          </Form.Item>
        </div>
      );
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.type = values.type ? "active" : "inactive";
        this.setState({ loading: true });
        this.props.createBenefit(values).then((res) => {
          this.setState({ loading: false });
          if (res) {
            message.success(`Created Successfully`);
            this.props.history.push("/benefits");
          } else {
            message.error("Oops, Error");
          }
        });
      }
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.file && e.file.response) {
      return e.file.response;
    }
    return e.file;
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    const { imageUrl } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.imageLoading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="benefits-page" style={{ marginLeft: "200px" }}>
        <div className="flex justify-between w-full items-center">
          <Link to="/benefits">
            <div
              className="flex justify-start items-center cursor-pointer"
              onClick={this.props.toggleBenefitsState}
            >
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="text-14 pb-2">Back</div>
            </div>
          </Link>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Save
          </Button>
        </div>

        <div className="theme-color semibold text-30 mb-12">
          {this.isEditMode() && this.props.activeBenefit
            ? this.props.activeBenefit.name
            : ""}
        </div>

        <Form onSubmit={this.handleSubmit}>
          <div className="flex">
            <div className="contentLeft">
              <div className="desc">
                <p className="semibold text-18 text-black">Text</p>
                {this.renderTextareaFields(this.state.inputFields)}
              </div>
              <div className="images">
                <div className="flex justify-between mb-12">
                  <p className="semibold text-18 text-black">Icon (Recommended size: 28x28px)</p>
                  {/* <p className="regular text-14 text-link">
                    
                  </p> */}
                </div>
                <div>
                  <div>
                    <Form.Item extra="Main">
                      {getFieldDecorator("mainImage.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue:
                          this.props.activeBenefit &&
                          this.props.activeBenefit.mainImage
                            ? this.props.activeBenefit.mainImage.mediaUrl
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Please upload a main image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={this.handleImageChange}
                          customRequest={UploadImage}
                        >
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("mainImage.mediaType", {
                        initialValue:
                          this.props.activeBenefit &&
                          this.props.activeBenefit.mainImage
                            ? this.props.activeBenefit.mainImage.mediaType
                            : undefined,
                      })(<span></span>)}
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="contentRight flex flex-col">
              {/* tags */}
              <div className="tags mb-28">
                <div className="flex justify-between">
                  <p className="semibold text-18 text-black mb-24">Tags</p>
                  <Link to="/tags">
                    <p className="regular text-14 text-link cursor-pointer">
                      Edit
                    </p>
                  </Link>
                </div>
                {this.props.activeBenefit &&
                  this.props.activeBenefit.tags &&
                  this.props.activeBenefit.tags.map((tag) => (
                    <Tag
                      className="semibold text-14"
                      style={{ marginBottom: "10px" }}
                    >
                      {tag === Object(tag) ? tag.name : ""}
                    </Tag>
                  ))}
                <Form.Item label="">
                  {getFieldDecorator("tags", {
                    rules: [
                      {
                        required: false,
                        message: "Please select atleast one tag",
                        type: "array",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select a tag"
                      style={{ width: "100%" }}
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.props.tags &&
                        this.props.tags.map((tag) => (
                          <Option value={tag._id} key={tag._id}>
                            {tag.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="select-products-container mb-28">
                <p className="line-22 text-base text-black">Active</p>
                <Form.Item>
                  {getFieldDecorator("type", {
                    initialValue: this.props.activeBenefit
                      ? this.props.activeBenefit.type === "active"
                      : true,
                    valuePropName: "checked",
                  })(<Switch />)}
                </Form.Item>
              </div>

              {/* <div className="tags mb-28">
                <div className="flex justify-between">
                  <p className="semibold text-18 text-black mb-24">Products</p>
                  <Link to="/products">
                    <p className="regular text-14 text-link cursor-pointer">
                      Edit
                    </p>
                  </Link>
                </div>

                <Form.Item label="">
                  {getFieldDecorator("products", {
                    rules: [
                      {
                        required: false,
                        message: "Please select atleast one tag",
                        type: "array",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select a product"
                      style={{ width: "100%" }}
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.props.allProducts &&
                        this.props.allProducts.map((tag) => (
                          <Option value={tag._id} key={tag._id}>
                            {tag.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div> */}
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const AddBenefitsForm = Form.create({ name: "add_benefits_form" })(AddBenefits);

function mapStateToProps(state) {
  const { Benefits, Products, Tags } = state;
  return {
    ingredientsList: Benefits.benefits,
    allProducts: Products.products,
    tags: Tags.tags,
    activeBenefit: Benefits.activeBenefit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListBenefits: bindActionCreators(actions.getBenefitsList, dispatch),
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
    createBenefit: bindActionCreators(actions.createBenefit, dispatch),
    updateBenefit: bindActionCreators(actions.updateBenefit, dispatch),
    deleteBenefit: bindActionCreators(actions.deleteBenefit, dispatch),
    getActiveBenefit: bindActionCreators(actions.getBenefit, dispatch),
    setActiveBenefit: bindActionCreators(actions.setActiveBenefit, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBenefitsForm);
