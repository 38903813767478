import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./review.scss";

import {
  Icon,
  Select,
  Input,
  Button,
  Upload,
  Form,
  Switch,
  message,
  InputNumber,
} from "antd";
import { 
  getBase64, 
  beforeUpload, 
  UploadImage,
  normFile,
} from "utils/helpers";
import * as actions from "actions/reviews";


const { Option } = Select;

const AddReview = (props) => {
  const inputFields = [
    {
      fieldName: "Author Name",
      key: "nameAuthor",
    },
    {
      fieldName: "Date",
      key: "date",
    },
  ]
    
  // TODO: this lists can be to write to database
  const verifiedFrom = [
    'twitter',
    'facebook',
    'yotpo',
    'instagram',
  ]
  const [ mode, setMode ] = useState('Created')
  const [ imageLoading, setImageLoading ] = useState(false)
  const [ loading, setLoaging ] = useState(false)
  const [ reviewID, setReviewID ] = useState()

  const [ activeReview, setActiveReview ] = useState()
  const [ authorPhoto, setAuthorPhoto ] = useState()
  const [ image, setImage ] = useState()

  const dispatch = useDispatch()
  const { getFieldDecorator, setFieldsValue } = props.form;

  useEffect(() => {
    const id = new URLSearchParams(window.location.search)
      .get('id')
    if(id) {
      setReviewID(id)
    }
    window.scrollTo(0, 0);
    if (reviewID) {
      setMode('Updated')
      dispatch(actions.getReview(reviewID))
      .then((review) => {
        setActiveReview(review)
      })
    }
  }, [reviewID])
   
  const isEditMode = () => {
    return mode === "Updated";
  }

  const handleImageChange = (info, imageType) => {
    if (info.file.status === "uploading") {
      if (imageType === "authorPhoto") {
        setActiveReview({
          ...activeReview,
          authorPhoto: {
            mediaUrl: '',
            mediaType: '',
          },
        })
      }
      if (imageType === "image") {
        setImage({
          ...image,
          image: {
            mediaUrl: '',
            mediaType: '',
          },
        })
      }
      setLoaging(true)
      setImageLoading(true);
      return;
    }
    if (info.file.status === "done") {
      if (imageType === "authorPhoto") {
        setFieldsValue({
          "authorPhoto.mediaType": info.file.type,
        });
      }
      if (imageType === "image") {
        setFieldsValue({
          "image.mediaType": info.file.type,
        });
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        if (imageType === "authorPhoto") {
          setAuthorPhoto(imageUrl)
          setFieldsValue({
            "authorPhoto.mediaUrl": info.file.response,
          });
        }
        if (imageType === "image") {
          setImage(imageUrl)
          setFieldsValue({
            "image.mediaUrl": info.file.response,
          });
        }
        setLoaging(false)
        setImageLoading(false);
      });
    }
  };

  const renderInputFields = (arr) => {
    return arr.map((obj) => (
      <div className="mb-8" key={obj.key}>
        <p className="text-black regular text-14 mb-4">{obj.fieldName}</p>
        <Form.Item>
          {getFieldDecorator(obj.key, {
            initialValue: activeReview 
              ? activeReview[obj.key]
              : undefined,
            rules: [{ 
              required: true, 
              message: "Required" 
            }],
          })(<Input />)}
        </Form.Item>
      </div>
    ));
  };

  const clickDeleteButton = (e) => {
    e.preventDefault()
    const deleteQuestion = window.confirm('Do you want to delete this review?')
    if(deleteQuestion) {
      handleDelete()
    }
  }

  const handleDelete = () => {
    dispatch(actions.deleteReview(reviewID))
      .then((res) => {
        if (res) {
          message.success(`Removed Successfully`, 0.5, () => {
            window.location.href = '/reviews'
          });
        } else {
          message.error("Oops, Error");
        }
      })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        setLoaging(true)
        dispatch(
          isEditMode()
          ? actions.updateReview(reviewID, values)
          : actions.createReview(values)
        ).then((res) => {
          setLoaging(false)
          if (res) {
            // const textMessage = mode === 
            message.success(`${mode} Successfully`);
            setMode('Updated')
            setReviewID(res._id)
          } else {
            message.error("Oops, Error");
          }
        })
      }
    })
  }

  const placeholderImage = (srcLoad, srcNew, loading) => (
    (srcNew || srcLoad) && (
      <img
        src={srcNew || srcLoad}
        alt="image"
        style={{ width: "100%" }}
      />
    ) || (
      uploadButton(loading)
    )
  )

  const uploadButton = (state) =>(
    <div>
      <Icon type={state ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div className="ingredients-page" style={{ marginLeft: "200px" }}>
      <div className="flex justify-between w-full items-center">
        <Link to="/reviews">
          <div className="flex justify-start items-center cursor-pointer">
            <div>
              <Icon className="mr-4" type="left" />
            </div>
            <div className="text-14 pb-2">Back</div>
          </div>
        </Link>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Save
        </Button>
      </div>

      <div className="theme-color semibold text-30 mb-12">
        {isEditMode() && activeReview?.nameAuthor}
      </div>

      <Form onSubmit={handleSubmit}>
        <div className="flex">
          <div className="contentLeft">
            <div className="desc">
              <p className="semibold text-18 text-black">Text</p>
              <div style={{marginBottom: '20px'}}>** You can set two varint words, <br/>
                for example write *{String.fromCharCode('123')}word_GB, word_US{String.fromCharCode('125')}* in a text, <br/>
                also you can highlight {String.fromCharCode('60')}span{String.fromCharCode('62')}
                  <span style={{ backgroundColor: 'rgba(254, 197, 4, 0.4)' }}>some text</span> 
                {String.fromCharCode('60')}/span{String.fromCharCode('62')}
              </div>
              {renderInputFields(inputFields)}

              <div>
                <Form.Item label="Author Photo">
                  {getFieldDecorator("authorPhoto.mediaUrl", {
                    getValueFromEvent: normFile,
                    initialValue: 
                      activeReview?.authorPhoto?.mediaUrl,
                      rules: [{ required: true, message: "Please upload an Author Photo" }],
                  })(
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={(e) => 
                        handleImageChange(e, "authorPhoto")
                      }
                      customRequest={UploadImage}
                    >
                      {placeholderImage(activeReview?.authorPhoto?.mediaUrl, authorPhoto, imageLoading)}
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("authorPhoto.mediaType", {
                    initialValue:
                      activeReview?.authorPhoto?.mediaType,
                  })(<span/>)}
                </Form.Item>
              </div>

              <div className="text-black regular text-14 mb-4">
                Rating (integer 1...5)
              </div>
              <Form.Item>
                {getFieldDecorator('rating', {
                    initialValue: activeReview?.rating || 5,
                    rules: [{ required: true, message: "Required Rating" }],
                  })
                  (<InputNumber
                    min={1}
                    max={5}
                    step={1}
                  />)}
              </Form.Item>

              <div className="text-black regular text-14 mb-4">
                Verified from
              </div>
              <Form.Item>
                {getFieldDecorator('verifiedFrom', {
                  initialValue: activeReview?.verifiedFrom,
                  rules: [{ required: true, message: "Required Verified from" }],
                })(
                  <Select>
                    {verifiedFrom.map((item) => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </ Select>   
                )}
              </Form.Item>

              <div className="mb-14">
                <p className="text-black regular text-14 mb-4">
                  Text **
                </p>
                <Form.Item>
                  {getFieldDecorator('text', {
                    initialValue: activeReview?.text,
                    rules: [{ required: true, message: "Required text" }],
                  })(<Input.TextArea rows={8} />)}
                </Form.Item>
              </div>
            </div>

            <div className="images">
              <div className="flex justify-between mb-12">
                <p className="semibold text-18 text-black">Image</p>
              </div>
              <div>
                <Form.Item label="Image">
                  {getFieldDecorator("image.mediaUrl", {
                    getValueFromEvent: normFile,
                    initialValue: activeReview?.image?.mediaUrl,
                    rules: [{ required: true, message: "Please upload a main image"}],
                  })(
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={(e) => 
                        handleImageChange(e, "image")
                      }
                      customRequest={UploadImage}
                    >
                      {placeholderImage(activeReview?.image?.mediaUrl, image, imageLoading)}
                    </Upload>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("image.mediaType", {
                    initialValue:
                      activeReview?.image?.mediaType,
                  })(<span/>)}
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="contentRight flex flex-col">
            <div className="select-products-container mb-28">
              <Form.Item 
                label="Show on Homepage" 
                className="mb-8">
                {getFieldDecorator("isShowOnHomepage", {
                  initialValue: activeReview?.isShowOnHomepage || false,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </div>
          </div>
        </div>
        
        <Button
          type="danger"
          onClick={clickDeleteButton}
          loading={loading}
        >
          Delete
        </Button>
      
      </Form>
    </div>
  );
}

const AddReviewForm = Form.create({ name: "add_reviews_form" })(
  AddReview
);

export default AddReviewForm;
