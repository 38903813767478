import React from "react";
import "./recommendationForm.scss";
import { Modal, Button, Icon, Form, Switch, Select } from "antd";
const { Option } = Select;

const RecommendationsModal = props => {
  const {
    title,
    visible,
    formInputFields,
    confirmLoading,
    handleOk,
    handleCancel,
    handleCreateRecommendation,
    activeTag,
    productList
  } = props;
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <Button
        type="primary"
        onClick={handleCreateRecommendation}
      >
        <Icon type="plus"></Icon>
        Add Recommendation
      </Button>
      <Modal
        title={title}
        visible={visible}
        okText="Submit"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {formInputFields &&
          formInputFields.map((obj, index) => {
            return (
              <div className="mb-8" key={index}>
                {/* render input fields */}
                <div className="text-black regular text-14 mb-4">
                  {obj.fieldName}
                </div>
                <Form.Item>
                  {getFieldDecorator(`${obj.key}`, {
                    initialValue: activeTag ? activeTag.product : undefined,
                    rules: [{ required: true, message: "Required" }]
                  })(<Select placeholder="Please Select Product">
                     {productList.map((product) => {
                      return (
                        <Option value={product.id} key={product.id}>
                          {product.name}
                        </Option>
                      );
                })}
                  </Select>)}
                </Form.Item>

                {/* active switch */}
                <div className="text-black regular text-14 mb-4">Show on results page </div>
                <Form.Item>
                  {getFieldDecorator("showInResultsPage", {
                    initialValue: activeTag ? activeTag.showInResultsPage : true,
                    valuePropName: "checked"
                  })(
                    <Switch />
                  )}
                </Form.Item>
              </div>
            );
          })}
      </Modal>
    </div>
  );
};

const RecommendationsForm = Form.create({ name: "recommendations_form" })(RecommendationsModal);
export default RecommendationsForm;
