const COUNTRIES_WITH_STATES = [
  {
    name: "Afghanistan",
    code: "AF",
    states: null,
  },
  {
    name: "Albania",
    code: "AL",
    states: null,
  },
  {
    name: "Algeria",
    code: "DZ",
    states: null,
  },
  {
    name: "Andorra",
    code: "AD",
    states: null,
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    states: null,
  },
  {
    name: "Anguilla",
    code: "AI",
    states: null,
  },
  {
    name: "Armenia",
    code: "AM",
    states: null,
  },
  {
    name: "Netherlands Antilles",
    code: "AN",
    states: null,
  },
  {
    name: "Angola",
    code: "AO",
    states: null,
  },
  {
    name: "Antarctica",
    code: "AQ",
    states: null,
  },
  {
    name: "Argentina",
    code: "AR",
    states: null,
  },
  {
    name: "American Samoa",
    code: "AS",
    states: null,
  },
  {
    name: "Austria",
    code: "AT",
    states: null,
  },
  {
    name: "Australia",
    code: "AU",
    states: null,
  },
  {
    name: "Aruba",
    code: "AW",
    states: null,
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    states: null,
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    states: null,
  },
  {
    name: "Barbados",
    code: "BB",
    states: null,
  },
  {
    name: "Bangladesh",
    code: "BD",
    states: null,
  },
  {
    name: "Belgium",
    code: "BE",
    states: null,
  },
  {
    name: "Burkina Faso",
    code: "BF",
    states: null,
  },
  {
    name: "Bulgaria",
    code: "BG",
    states: null,
  },
  {
    name: "Bahrain",
    code: "BH",
    states: null,
  },
  {
    name: "Burundi",
    code: "BI",
    states: null,
  },
  {
    name: "Benin",
    code: "BJ",
    states: null,
  },
  {
    name: "Bermuda",
    code: "BM",
    states: null,
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    states: null,
  },
  {
    name: "Bolivia",
    code: "BO",
    states: null,
  },
  {
    name: "Brazil",
    code: "BR",
    states: null,
  },
  {
    name: "Bahamas",
    code: "BS",
    states: null,
  },
  {
    name: "Bhutan",
    code: "BT",
    states: null,
  },
  {
    name: "Bouvet Island",
    code: "BV",
    states: null,
  },
  {
    name: "Botswana",
    code: "BW",
    states: null,
  },
  {
    name: "Belarus",
    code: "BY",
    states: null,
  },
  {
    name: "Belize",
    code: "BZ",
    states: null,
  },
  {
    name: "Canada",
    code: "CA",
    states: [
      {
        code: "AB",
        name: "Alberta",
      },
      {
        code: "BC",
        name: "British Columbia",
      },
      {
        code: "MB",
        name: "Manitoba",
      },
      {
        code: "NB",
        name: "New Brunswick",
      },
      {
        code: "NL",
        name: "Newfoundland and Labrador",
      },
      {
        code: "NS",
        name: "Nova Scotia",
      },
      {
        code: "NT",
        name: "Northwest Territories",
      },
      {
        code: "NU",
        name: "Nunavut",
      },
      {
        code: "ON",
        name: "Ontario",
      },
      {
        code: "PE",
        name: "Prince Edward Island",
      },
      {
        code: "QC",
        name: "Quebec",
      },
      {
        code: "SK",
        name: "Saskatchewan",
      },
      {
        code: "YT",
        name: "Yukon",
      },
    ],
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    states: null,
  },
  {
    name: "Congo, the Democratic Republic of the",
    code: "CD",
    states: null,
  },
  {
    name: "Central African Republic",
    code: "CF",
    states: null,
  },
  {
    name: "Congo",
    code: "CG",
    states: null,
  },
  {
    name: "Switzerland",
    code: "CH",
    states: null,
  },
  {
    name: "Cote D'Ivoire",
    code: "CI",
    states: null,
  },
  {
    name: "Cook Islands",
    code: "CK",
    states: null,
  },
  {
    name: "Chile",
    code: "CL",
    states: null,
  },
  {
    name: "Cameroon",
    code: "CM",
    states: null,
  },
  {
    name: "China",
    code: "CN",
    states: null,
  },
  {
    name: "Colombia",
    code: "CO",
    states: null,
  },
  {
    name: "Costa Rica",
    code: "CR",
    states: null,
  },
  {
    name: "Cuba, Republic of",
    code: "CU",
    states: null,
  },
  {
    name: "Cape Verde",
    code: "CV",
    states: null,
  },
  {
    name: "Curacao",
    code: "CW",
    states: null,
  },
  {
    name: "Christmas Island",
    code: "CX",
    states: null,
  },
  {
    name: "Cyprus",
    code: "CY",
    states: null,
  },
  {
    name: "Czech Republic",
    code: "CZ",
    states: null,
  },
  {
    name: "Germany",
    code: "DE",
    states: null,
  },
  {
    name: "Djibouti",
    code: "DJ",
    states: null,
  },
  {
    name: "Denmark",
    code: "DK",
    states: null,
  },
  {
    name: "Dominica",
    code: "DM",
    states: null,
  },
  {
    name: "Dominican Republic",
    code: "DO",
    states: null,
  },
  {
    name: "Ecuador",
    code: "EC",
    states: null,
  },
  {
    name: "Estonia",
    code: "EE",
    states: null,
  },
  {
    name: "Egypt",
    code: "EG",
    states: null,
  },
  {
    name: "Western Sahara",
    code: "EH",
    states: null,
  },
  {
    name: "Eritrea",
    code: "ER",
    states: null,
  },
  {
    name: "Spain",
    code: "ES",
    states: null,
  },
  {
    name: "Ethiopia",
    code: "ET",
    states: null,
  },
  {
    name: "Finland",
    code: "FI",
    states: null,
  },
  {
    name: "Fiji",
    code: "FJ",
    states: null,
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    states: null,
  },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    states: null,
  },
  {
    name: "Faroe Islands",
    code: "FO",
    states: null,
  },
  {
    name: "France",
    code: "FR",
    states: null,
  },
  {
    name: "Gabon",
    code: "GA",
    states: null,
  },
  {
    name: "United Kingdom",
    code: "GB",
    states: null,
  },
  {
    name: "Grenada",
    code: "GD",
    states: null,
  },
  {
    name: "Georgia",
    code: "GE",
    states: null,
  },
  {
    name: "French Guiana",
    code: "GF",
    states: null,
  },
  {
    name: "Guernsey",
    code: "GG",
    states: null,
  },
  {
    name: "Ghana",
    code: "GH",
    states: null,
  },
  {
    name: "Gibraltar",
    code: "GI",
    states: null,
  },
  {
    name: "Greenland",
    code: "GL",
    states: null,
  },
  {
    name: "Gambia",
    code: "GM",
    states: null,
  },
  {
    name: "Guinea",
    code: "GN",
    states: null,
  },
  {
    name: "Guadeloupe",
    code: "GP",
    states: null,
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    states: null,
  },
  {
    name: "Greece",
    code: "GR",
    states: null,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    states: null,
  },
  {
    name: "Guatemala",
    code: "GT",
    states: null,
  },
  {
    name: "Guam",
    code: "GU",
    states: null,
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    states: null,
  },
  {
    name: "Guyana",
    code: "GY",
    states: null,
  },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    states: null,
  },
  {
    name: "Honduras",
    code: "HN",
    states: null,
  },
  {
    name: "Croatia",
    code: "HR",
    states: null,
  },
  {
    name: "Haiti",
    code: "HT",
    states: null,
  },
  {
    name: "Hungary",
    code: "HU",
    states: null,
  },
  {
    name: "Indonesia",
    code: "ID",
    states: [
      {
        code: "AC",
        name: "Aceh",
      },
      {
        code: "BA",
        name: "Bali",
      },
      {
        code: "BB",
        name: "Bangka-Belitung",
      },
      {
        code: "BN",
        name: "Banten",
      },
      {
        code: "BB",
        name: "Bangka-Belitung",
      },
      {
        code: "BG",
        name: "Bengkulu",
      },
      {
        code: "GN",
        name: "Gandaria",
      },
      {
        code: "GO",
        name: "Gorontalo",
      },
      {
        code: "JK",
        name: "Jakarta",
      },
      {
        code: "JM",
        name: "Jambi",
      },
      {
        code: "JB",
        name: "Jawa Barat",
      },
      {
        code: "JT",
        name: "Jawa Tengah",
      },
      {
        code: "JR",
        name: "Jawa Timur",
      },
      {
        code: "KB",
        name: "Kalimantan Barat",
      },
      {
        code: "KS",
        name: "Kalimantan Selatan",
      },
      {
        code: "KT",
        name: "Kalimantan Tengah",
      },
      {
        code: "KD",
        name: "Kendal",
      },
      {
        code: "LP",
        name: "Lampung",
      },
      {
        code: "ML",
        name: "Maluku",
      },
      {
        code: "MU",
        name: "Maluku Utara",
      },
      {
        code: "NB",
        name: "Nusa Tenggara Barat",
      },
      {
        code: "NR",
        name: "Nusa Tenggara Timur",
      },
      {
        code: "PP",
        name: "Papua",
      },
      {
        code: "RI",
        name: "Riau",
      },
      {
        code: "RI",
        name: "Riau Kepulauan",
      },
      {
        code: "SO",
        name: "Solo",
      },
      {
        code: "SS",
        name: "Sulawesi Selatan",
      },
      {
        code: "ST",
        name: "Sulawesi Tengah",
      },
      {
        code: "SU",
        name: "Sulawesi Tenggara",
      },
      {
        code: "SU",
        name: "Sulawesi Utara",
      },
      {
        code: "SB",
        name: "Sumatera Barat",
      },
      {
        code: "SE",
        name: "Sumatera Selatan",
      },
      {
        code: "SR",
        name: "Sumatera Utara",
      },
      {
        code: "YG",
        name: "Yogyakarta",
      },
    ],
  },
  {
    name: "Ireland",
    code: "IE",
    states: null,
  },
  {
    name: "Israel",
    code: "IL",
    states: null,
  },
  {
    name: "Isle of Man",
    code: "IM",
    states: null,
  },
  {
    name: "India",
    code: "IN",
    states: [
      {
        code: "AN",
        name: "Andaman and Nicobar Islands",
      },
      {
        code: "AP",
        name: "Andhra Pradesh",
      },
      {
        code: "AR",
        name: "Arunachal Pradesh",
      },
      {
        code: "AS",
        name: "Assam",
      },
      {
        code: "BR",
        name: "Bihar",
      },
      {
        code: "CG",
        name: "Chandigarh",
      },
      {
        code: "CH",
        name: "Chhattisgarh",
      },
      {
        code: "DH",
        name: "Dadra and Nagar Haveli",
      },
      {
        code: "DD",
        name: "Daman and Diu",
      },
      {
        code: "DL",
        name: "Delhi",
      },
      {
        code: "GA",
        name: "Goa",
      },
      {
        code: "GJ",
        name: "Gujarat",
      },
      {
        code: "HR",
        name: "Haryana",
      },
      {
        code: "HP",
        name: "Himachal Pradesh",
      },
      {
        code: "JK",
        name: "Jammu and Kashmir",
      },
      {
        code: "JH",
        name: "Jharkhand",
      },
      {
        code: "KA",
        name: "Karnataka",
      },
      {
        code: "KL",
        name: "Kerala",
      },
      {
        code: "LD",
        name: "Lakshadweep",
      },
      {
        code: "MP",
        name: "Madhya Pradesh",
      },
      {
        code: "MH",
        name: "Maharashtra",
      },
      {
        code: "MN",
        name: "Manipur",
      },
      {
        code: "ML",
        name: "Meghalaya",
      },
      {
        code: "MZ",
        name: "Mizoram",
      },
      {
        code: "NL",
        name: "Nagaland",
      },
      {
        code: "OR",
        name: "Odisha",
      },
      {
        code: "PY",
        name: "Puducherry",
      },
      {
        code: "PB",
        name: "Punjab",
      },
      {
        code: "RJ",
        name: "Rajasthan",
      },
      {
        code: "SK",
        name: "Sikkim",
      },
      {
        code: "TN",
        name: "Tamil Nadu",
      },
      {
        code: "TS",
        name: "Telangana",
      },
      {
        code: "TR",
        name: "Tripura",
      },
      {
        code: "UP",
        name: "Uttar Pradesh",
      },
      {
        code: "UK",
        name: "Uttarakhand",
      },
      {
        code: "WB",
        name: "West Bengal",
      },
    ],
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    states: null,
  },
  {
    name: "Iraq",
    code: "IQ",
    states: null,
  },
  {
    name: "Iran, Islamic Republic of",
    code: "IR",
    states: null,
  },
  {
    name: "Iceland",
    code: "IS",
    states: null,
  },
  {
    name: "Italy",
    code: "IT",
    states: null,
  },
  {
    name: "HONG KONG",
    code: "HK",
    states: null,
  },
  {
    name: "Jersey",
    code: "JE",
    states: null,
  },
  {
    name: "Jamaica",
    code: "JM",
    states: null,
  },
  {
    name: "Jordan",
    code: "JO",
    states: null,
  },
  {
    name: "Japan",
    code: "JP",
    states: [
      {
        code: "01",
        name: "Hokkaido",
      },
      {
        code: "02",
        name: "Aomori",
      },
      {
        code: "03",
        name: "Iwate",
      },
      {
        code: "04",
        name: "Miyagi",
      },
      {
        code: "05",
        name: "Akita",
      },
      {
        code: "06",
        name: "Yamagata",
      },
      {
        code: "07",
        name: "Fukushima",
      },
      {
        code: "08",
        name: "Ibaraki",
      },
      {
        code: "09",
        name: "Tochigi",
      },
      {
        code: "10",
        name: "Gunma",
      },
      {
        code: "11",
        name: "Saitama",
      },
      {
        code: "12",
        name: "Chiba",
      },
      {
        code: "13",
        name: "Tokyo",
      },
      {
        code: "14",
        name: "Kanagawa",
      },
      {
        code: "15",
        name: "Niigata",
      },
      {
        code: "16",
        name: "Toyama",
      },
      {
        code: "17",
        name: "Ishikawa",
      },
      {
        code: "18",
        name: "Fukui",
      },
      {
        code: "19",
        name: "Yamanashi",
      },
      {
        code: "20",
        name: "Nagano",
      },
      {
        code: "21",
        name: "Gifu",
      },
      {
        code: "22",
        name: "Shizuoka",
      },
      {
        code: "23",
        name: "Aichi",
      },
      {
        code: "24",
        name: "Mie",
      },
      {
        code: "25",
        name: "Shiga",
      },
      {
        code: "26",
        name: "Kyoto",
      },
      {
        code: "27",
        name: "Osaka",
      },
      {
        code: "28",
        name: "Hyogo",
      },
      {
        code: "29",
        name: "Nara",
      },
      {
        code: "30",
        name: "Wakayama",
      },
      {
        code: "31",
        name: "Tottori",
      },
      {
        code: "32",
        name: "Shimane",
      },
      {
        code: "33",
        name: "Okayama",
      },
      {
        code: "34",
        name: "Hiroshima",
      },
      {
        code: "35",
        name: "Yamaguchi",
      },
      {
        code: "36",
        name: "Tokushima",
      },
      {
        code: "37",
        name: "Kagawa",
      },
      {
        code: "38",
        name: "Ehime",
      },
      {
        code: "39",
        name: "Kochi",
      },
      {
        code: "40",
        name: "Fukuoka",
      },
      {
        code: "41",
        name: "Saga",
      },
      {
        code: "42",
        name: "Nagasaki",
      },
      {
        code: "43",
        name: "Kumamoto",
      },
      {
        code: "44",
        name: "Oita",
      },
      {
        code: "45",
        name: "Miyazaki",
      },
      {
        code: "46",
        name: "Kagoshima",
      },
      {
        code: "47",
        name: "Okinawa",
      },
    ],
  },
  {
    name: "Kenya",
    code: "KE",
    states: null,
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    states: null,
  },
  {
    name: "Cambodia",
    code: "KH",
    states: null,
  },
  {
    name: "Kiribati",
    code: "KI",
    states: null,
  },
  {
    name: "Comoros",
    code: "KM",
    states: null,
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    states: null,
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: "KP",
    states: null,
  },
  {
    name: "Korea, Republic of",
    code: "KR",
    states: null,
  },
  {
    name: "Kuwait",
    code: "KW",
    states: null,
  },
  {
    name: "Cayman Islands",
    code: "KY",
    states: null,
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    states: null,
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    states: null,
  },
  {
    name: "Lebanon",
    code: "LB",
    states: null,
  },
  {
    name: "Saint Lucia",
    code: "LC",
    states: null,
  },
  {
    name: "Liechtenstein",
    code: "LI",
    states: null,
  },
  {
    name: "Sri Lanka",
    code: "LK",
    states: null,
  },
  {
    name: "Liberia",
    code: "LR",
    states: null,
  },
  {
    name: "Lesotho",
    code: "LS",
    states: null,
  },
  {
    name: "Lithuania",
    code: "LT",
    states: null,
  },
  {
    name: "Luxembourg",
    code: "LU",
    states: null,
  },
  {
    name: "Latvia",
    code: "LV",
    states: null,
  },
  {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    states: null,
  },
  {
    name: "Morocco",
    code: "MA",
    states: null,
  },
  {
    name: "Monaco",
    code: "MC",
    states: null,
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
    states: null,
  },
  {
    name: "Montenegro",
    code: "ME",
    states: null,
  },
  {
    name: "Sint Maarten",
    code: "MF",
    states: null,
  },
  {
    name: "Madagascar",
    code: "MG",
    states: null,
  },
  {
    name: "Marshall Islands",
    code: "MH",
    states: null,
  },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    states: null,
  },
  {
    name: "Mali",
    code: "ML",
    states: null,
  },
  {
    name: "Myanmar",
    code: "MM",
    states: null,
  },
  {
    name: "Mongolia",
    code: "MN",
    states: null,
  },
  {
    name: "Macao",
    code: "MO",
    states: null,
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    states: null,
  },
  {
    name: "Martinique",
    code: "MQ",
    states: null,
  },
  {
    name: "Mauritania",
    code: "MR",
    states: null,
  },
  {
    name: "Montserrat",
    code: "MS",
    states: null,
  },
  {
    name: "Malta",
    code: "MT",
    states: null,
  },
  {
    name: "Mauritius",
    code: "MU",
    states: null,
  },
  {
    name: "Maldives",
    code: "MV",
    states: null,
  },
  {
    name: "Malawi",
    code: "MW",
    states: null,
  },
  {
    name: "Mexico",
    code: "MX",
    states: null,
  },
  {
    name: "Malaysia",
    code: "MY",
    states: null,
  },
  {
    name: "Mozambique",
    code: "MZ",
    states: null,
  },
  {
    name: "Namibia",
    code: "NA",
    states: null,
  },
  {
    name: "New Caledonia",
    code: "NC",
    states: null,
  },
  {
    name: "Niger",
    code: "NE",
    states: null,
  },
  {
    name: "Norfolk Island",
    code: "NF",
    states: null,
  },
  {
    name: "Nigeria",
    code: "NG",
    states: null,
  },
  {
    name: "Nicaragua",
    code: "NI",
    states: null,
  },
  {
    name: "Netherlands",
    code: "NL",
    states: null,
  },
  {
    name: "Norway",
    code: "NO",
    states: null,
  },
  {
    name: "Nepal",
    code: "NP",
    states: null,
  },
  {
    name: "Nauru",
    code: "NR",
    states: null,
  },
  {
    name: "Niue",
    code: "NU",
    states: null,
  },
  {
    name: "Oman",
    code: "OM",
    states: null,
  },
  {
    name: "Panama",
    code: "PA",
    states: null,
  },
  {
    name: "Peru",
    code: "PE",
    states: null,
  },
  {
    name: "French Polynesia",
    code: "PF",
    states: null,
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    states: null,
  },
  {
    name: "Philippines",
    code: "PH",
    states: null,
  },
  {
    name: "Pakistan",
    code: "PK",
    states: null,
  },
  {
    name: "Poland",
    code: "PL",
    states: null,
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    states: null,
  },
  {
    name: "Pitcairn",
    code: "PN",
    states: null,
  },
  {
    name: "Puerto Rico",
    code: "PR",
    states: null,
  },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    states: null,
  },
  {
    name: "Portugal",
    code: "PT",
    states: null,
  },
  {
    name: "Palau",
    code: "PW",
    states: null,
  },
  {
    name: "Paraguay",
    code: "PY",
    states: null,
  },
  {
    name: "Qatar",
    code: "QA",
    states: null,
  },
  {
    name: "Reunion",
    code: "RE",
    states: null,
  },
  {
    name: "Romania",
    code: "RO",
    states: null,
  },
  {
    name: "Serbia",
    code: "RS",
    states: null,
  },
  {
    name: "Russian Federation",
    code: "RU",
    states: null,
  },
  {
    name: "Rwanda",
    code: "RW",
    states: null,
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    states: null,
  },
  {
    name: "Solomon Islands",
    code: "SB",
    states: null,
  },
  {
    name: "Seychelles",
    code: "SC",
    states: null,
  },
  {
    name: "Sudan",
    code: "SD",
    states: null,
  },
  {
    name: "Sweden",
    code: "SE",
    states: null,
  },
  {
    name: "Saint Helena",
    code: "SH",
    states: null,
  },
  {
    name: "Slovenia",
    code: "SI",
    states: null,
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    states: null,
  },
  {
    name: "Slovakia",
    code: "SK",
    states: null,
  },
  {
    name: "Sierra Leone",
    code: "SL",
    states: null,
  },
  {
    name: "Singapore",
    code: "SG",
    states: null,
  },
  {
    name: "San Marino",
    code: "SM",
    states: null,
  },
  {
    name: "Senegal",
    code: "SN",
    states: null,
  },
  {
    name: "Somalia",
    code: "SO",
    states: null,
  },
  {
    name: "Suriname",
    code: "SR",
    states: null,
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    states: null,
  },
  {
    name: "El Salvador",
    code: "SV",
    states: null,
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    states: null,
  },
  {
    name: "Swaziland",
    code: "SZ",
    states: null,
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    states: null,
  },
  {
    name: "Chad",
    code: "TD",
    states: null,
  },
  {
    name: "French Southern Territories",
    code: "TF",
    states: null,
  },
  {
    name: "Togo",
    code: "TG",
    states: null,
  },
  {
    name: "Thailand",
    code: "TH",
    states: null,
  },
  {
    name: "Tajikistan",
    code: "TJ",
    states: null,
  },
  {
    name: "Tokelau",
    code: "TK",
    states: null,
  },
  {
    name: "Timor-Leste",
    code: "TL",
    states: null,
  },
  {
    name: "Turkmenistan",
    code: "TM",
    states: null,
  },
  {
    name: "Tunisia",
    code: "TN",
    states: null,
  },
  {
    name: "Tonga",
    code: "TO",
    states: null,
  },
  {
    name: "Turkey",
    code: "TR",
    states: null,
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    states: null,
  },
  {
    name: "Tuvalu",
    code: "TV",
    states: null,
  },
  {
    name: "Taiwan",
    code: "TW",
    states: null,
  },
  {
    name: "Tanzania",
    code: "TZ",
    states: null,
  },
  {
    name: "Ukraine",
    code: "UA",
    states: null,
  },
  {
    name: "Uganda",
    code: "UG",
    states: null,
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    states: null,
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    states: null,
  },
  {
    name: " United States of America",
    code: "US",
    states: null,
  },
  {
    name: "United States",
    code: "US",
    states: null,
  },
  {
    name: "Uruguay",
    code: "UY",
    states: null,
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    states: null,
  },
  {
    name: "Vatican City",
    code: "VA",
    states: null,
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    states: null,
  },
  {
    name: "Venezuela",
    code: "VE",
    states: null,
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    states: null,
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    states: null,
  },
  {
    name: "Vietnam",
    code: "VN",
    states: null,
  },
  {
    name: "Vanuatu",
    code: "VU",
    states: null,
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    states: null,
  },
  {
    name: "Samoa",
    code: "WS",
    states: null,
  },
  {
    name: "Yemen",
    code: "YE",
    states: null,
  },
  {
    name: "Mayotte",
    code: "YT",
    states: null,
  },
  {
    name: "South Africa",
    code: "ZA",
    states: null,
  },
  {
    name: "Zambia",
    code: "ZM",
    states: null,
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    states: null,
  },
];

const RESTRICTED_COUNTRIES_CHECKOUT = [
  {
    name: 'Australia',
    code: 'AU',
    states: null
    // states: [
    //   {
    //     code: 'ACT',
    //     name: 'Australian Capital Territory',
    //   },
    //   {
    //     code: 'NSW',
    //     name: 'New South Wales',
    //   },
    //   {
    //     code: 'NT',
    //     name: 'Northern Territory',
    //   },
    //   {
    //     code: 'QLD',
    //     name: 'Queensland',
    //   },
    //   {
    //     code: 'SA',
    //     name: 'South Australia',
    //   },
    //   {
    //     code: 'TAS',
    //     name: 'Tasmania',
    //   },
    //   {
    //     code: 'VIC',
    //     name: 'Victoria',
    //   },
    //   {
    //     code: 'WA',
    //     name: 'Western Australia',
    //   },
    // ],
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    states: [
      {
        code: 'HKI',
        name: 'Hong Kong Island',
      },
      {
        code: 'KL',
        name: 'Kowloon',
      },
      {
        code: 'NT',
        name: 'New Territories',
      },
    ],
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    states: [
      {
        code: 'AUK',
        name: 'Auckland',
      },
      {
        code: 'BOP',
        name: 'Bay of Plenty',
      },
      {
        code: 'CAN',
        name: 'Canterbury',
      },
      {
        code: 'CIT',
        name: 'Chatham Islands Territory',
      },
      {
        code: 'GIS',
        name: 'Gisborne District',
      },
      {
        code: 'HKB',
        name: "Hawke's Bay",
      },
      {
        code: 'MWT',
        name: 'Manawatu-Wanganui',
      },
      {
        code: 'MBH',
        name: 'Marlborough District',
      },
      {
        code: 'NSN',
        name: 'Nelson City',
      },
      {
        code: 'NTL',
        name: 'Northland',
      },
      {
        code: 'OTA',
        name: 'Otago',
      },
      {
        code: 'STL',
        name: 'Southland',
      },
      {
        code: 'TKI',
        name: 'Taranaki',
      },
      {
        code: 'TAS',
        name: 'Tasman District',
      },
      {
        code: 'WKO',
        name: 'Waikato',
      },
      {
        code: 'WGN',
        name: 'Wellington',
      },
      {
        code: 'WTC',
        name: 'West Coast',
      },
    ],
  },
  {
    name: 'Singapore',
    code: 'SG',
    states: null,
  },
  {
    name: 'United States',
    code: 'US',
    states: [
      // {
      //   code: 'AA',
      //   name: 'Armed Forces Americas (except Canada)',
      // },
      // {
      //   code: 'AE',
      //   name: 'Armed Forces',
      // },
      {
        code: 'AK',
        name: 'Alaska',
      },
      {
        code: 'AL',
        name: 'Alabama',
      },
      // {
      //   code: 'AP',
      //   name: 'Armed Forces Pacific',
      // },
      {
        code: 'AR',
        name: 'Arkansas',
      },
      // {
      //   code: 'AS',
      //   name: 'American Samoa',
      // },
      {
        code: 'AZ',
        name: 'Arizona',
      },
      {
        code: 'CA',
        name: 'California',
      },
      {
        code: 'CO',
        name: 'Colorado',
      },
      {
        code: 'CT',
        name: 'Connecticut',
      },
      // {
      //   code: 'DC',
      //   name: 'District of Columbia',
      // },
      {
        code: 'DE',
        name: 'Delaware',
      },
      {
        code: 'FL',
        name: 'Florida',
      },
      // {
      //   code: 'FM',
      //   name: 'Federated States of Micronesia',
      // },
      {
        code: 'GA',
        name: 'Georgia',
      },
      // {
      //   code: 'GU',
      //   name: 'Guam',
      // },
      {
        code: 'HI',
        name: 'Hawaii',
      },
      {
        code: 'IA',
        name: 'Iowa',
      },
      {
        code: 'ID',
        name: 'Idaho',
      },
      {
        code: 'IL',
        name: 'Illinois',
      },
      {
        code: 'IN',
        name: 'Indiana',
      },
      {
        code: 'KS',
        name: 'Kansas',
      },
      {
        code: 'KY',
        name: 'Kentucky',
      },
      {
        code: 'LA',
        name: 'Louisiana',
      },
      {
        code: 'MA',
        name: 'Massachusetts',
      },
      {
        code: 'MD',
        name: 'Maryland',
      },
      {
        code: 'ME',
        name: 'Maine',
      },
      // {
      //   code: 'MH',
      //   name: 'Marshall Islands',
      // },
      {
        code: 'MI',
        name: 'Michigan',
      },
      {
        code: 'MN',
        name: 'Minnesota',
      },
      {
        code: 'MO',
        name: 'Missouri',
      },
      // {
      //   code: 'MP',
      //   name: 'Northern Mariana Islands',
      // },
      {
        code: 'MS',
        name: 'Mississippi',
      },
      {
        code: 'MT',
        name: 'Montana',
      },
      {
        code: 'NC',
        name: 'North Carolina',
      },
      {
        code: 'ND',
        name: 'North Dakota',
      },
      {
        code: 'NE',
        name: 'Nebraska',
      },
      {
        code: 'NH',
        name: 'New Hampshire',
      },
      {
        code: 'NJ',
        name: 'New Jersey',
      },
      {
        code: 'NM',
        name: 'New Mexico',
      },
      {
        code: 'NV',
        name: 'Nevada',
      },
      {
        code: 'NY',
        name: 'New York',
      },
      {
        code: 'OH',
        name: 'Ohio',
      },
      {
        code: 'OK',
        name: 'Oklahoma',
      },
      {
        code: 'OR',
        name: 'Oregon',
      },
      {
        code: 'PA',
        name: 'Pennsylvania',
      },
      {
        code: 'PR',
        name: 'Puerto Rico',
      },
      // {
      //   code: 'PW',
      //   name: 'Palau',
      // },
      {
        code: 'RI',
        name: 'Rhode Island',
      },
      {
        code: 'SC',
        name: 'South Carolina',
      },
      {
        code: 'SD',
        name: 'South Dakota',
      },
      {
        code: 'TN',
        name: 'Tennessee',
      },
      {
        code: 'TX',
        name: 'Texas',
      },
      {
        code: 'UT',
        name: 'Utah',
      },
      {
        code: 'VA',
        name: 'Virginia',
      },
      // {
      //   code: 'VI',
      //   name: 'Virgin Islands',
      // },
      {
        code: 'VT',
        name: 'Vermont',
      },
      {
        code: 'WA',
        name: 'Washington',
      },
      {
        code: 'WI',
        name: 'Wisconsin',
      },
      {
        code: 'WV',
        name: 'West Virginia',
      },
      {
        code: 'WY',
        name: 'Wyoming',
      },
    ],
  },
  // {
  //   name: 'India',
  //   code: 'IN',
  //   states: [
  //     {
  //       code: 'AN',
  //       name: 'Andaman and Nicobar Islands',
  //     },
  //     {
  //       code: 'AP',
  //       name: 'Andhra Pradesh',
  //     },
  //     {
  //       code: 'AR',
  //       name: 'Arunachal Pradesh',
  //     },
  //     {
  //       code: 'AS',
  //       name: 'Assam',
  //     },
  //     {
  //       code: 'BR',
  //       name: 'Bihar',
  //     },
  //     {
  //       code: 'CG',
  //       name: 'Chandigarh',
  //     },
  //     {
  //       code: 'CH',
  //       name: 'Chhattisgarh',
  //     },
  //     {
  //       code: 'DH',
  //       name: 'Dadra and Nagar Haveli',
  //     },
  //     {
  //       code: 'DD',
  //       name: 'Daman and Diu',
  //     },
  //     {
  //       code: 'DL',
  //       name: 'Delhi',
  //     },
  //     {
  //       code: 'GA',
  //       name: 'Goa',
  //     },
  //     {
  //       code: 'GJ',
  //       name: 'Gujarat',
  //     },
  //     {
  //       code: 'HR',
  //       name: 'Haryana',
  //     },
  //     {
  //       code: 'HP',
  //       name: 'Himachal Pradesh',
  //     },
  //     {
  //       code: 'JK',
  //       name: 'Jammu and Kashmir',
  //     },
  //     {
  //       code: 'JH',
  //       name: 'Jharkhand',
  //     },
  //     {
  //       code: 'KA',
  //       name: 'Karnataka',
  //     },
  //     {
  //       code: 'KL',
  //       name: 'Kerala',
  //     },
  //     {
  //       code: 'LD',
  //       name: 'Lakshadweep',
  //     },
  //     {
  //       code: 'MP',
  //       name: 'Madhya Pradesh',
  //     },
  //     {
  //       code: 'MH',
  //       name: 'Maharashtra',
  //     },
  //     {
  //       code: 'MN',
  //       name: 'Manipur',
  //     },
  //     {
  //       code: 'ML',
  //       name: 'Meghalaya',
  //     },
  //     {
  //       code: 'MZ',
  //       name: 'Mizoram',
  //     },
  //     {
  //       code: 'NL',
  //       name: 'Nagaland',
  //     },
  //     {
  //       code: 'OR',
  //       name: 'Odisha',
  //     },
  //     {
  //       code: 'PY',
  //       name: 'Puducherry',
  //     },
  //     {
  //       code: 'PB',
  //       name: 'Punjab',
  //     },
  //     {
  //       code: 'RJ',
  //       name: 'Rajasthan',
  //     },
  //     {
  //       code: 'SK',
  //       name: 'Sikkim',
  //     },
  //     {
  //       code: 'TN',
  //       name: 'Tamil Nadu',
  //     },
  //     {
  //       code: 'TS',
  //       name: 'Telangana',
  //     },
  //     {
  //       code: 'TR',
  //       name: 'Tripura',
  //     },
  //     {
  //       code: 'UP',
  //       name: 'Uttar Pradesh',
  //     },
  //     {
  //       code: 'UK',
  //       name: 'Uttarakhand',
  //     },
  //     {
  //       code: 'WB',
  //       name: 'West Bengal',
  //     },
  //   ],
  // },
  // {
  //   name: '--------',
  //   code: null,
  //   states: null,
  // },
  // {
  //   name: 'Austria',
  //   code: 'AT',
  //   states: null,
  // },
  // {
  //   name: 'Canada',
  //   code: 'CA',
  //   states: [
  //     {
  //       code: 'AB',
  //       name: 'Alberta',
  //     },
  //     {
  //       code: 'BC',
  //       name: 'British Columbia',
  //     },
  //     {
  //       code: 'MB',
  //       name: 'Manitoba',
  //     },
  //     {
  //       code: 'NB',
  //       name: 'New Brunswick',
  //     },
  //     {
  //       code: 'NL',
  //       name: 'Newfoundland and Labrador',
  //     },
  //     {
  //       code: 'NS',
  //       name: 'Nova Scotia',
  //     },
  //     {
  //       code: 'NT',
  //       name: 'Northwest Territories',
  //     },
  //     {
  //       code: 'NU',
  //       name: 'Nunavut',
  //     },
  //     {
  //       code: 'ON',
  //       name: 'Ontario',
  //     },
  //     {
  //       code: 'PE',
  //       name: 'Prince Edward Island',
  //     },
  //     {
  //       code: 'QC',
  //       name: 'Quebec',
  //     },
  //     {
  //       code: 'SK',
  //       name: 'Saskatchewan',
  //     },
  //     {
  //       code: 'YT',
  //       name: 'Yukon',
  //     },
  //   ],
  // },
  // {
  //   name: 'France',
  //   code: 'FR',
  //   states: null,
  // },
  // {
  //   name: 'Germany',
  //   code: 'DE',
  //   states: null,
  // },
  // {
  //   name: 'Indonesia',
  //   code: 'ID',
  //   states: [
  //     {
  //       code: 'AC',
  //       name: 'Aceh',
  //     },
  //     {
  //       code: 'BA',
  //       name: 'Bali',
  //     },
  //     {
  //       code: 'BB',
  //       name: 'Bangka-Belitung',
  //     },
  //     {
  //       code: 'BN',
  //       name: 'Banten',
  //     },
  //     {
  //       code: 'BB',
  //       name: 'Bangka-Belitung',
  //     },
  //     {
  //       code: 'BG',
  //       name: 'Bengkulu',
  //     },
  //     {
  //       code: 'GN',
  //       name: 'Gandaria',
  //     },
  //     {
  //       code: 'GO',
  //       name: 'Gorontalo',
  //     },
  //     {
  //       code: 'JK',
  //       name: 'Jakarta',
  //     },
  //     {
  //       code: 'JM',
  //       name: 'Jambi',
  //     },
  //     {
  //       code: 'JB',
  //       name: 'Jawa Barat',
  //     },
  //     {
  //       code: 'JT',
  //       name: 'Jawa Tengah',
  //     },
  //     {
  //       code: 'JR',
  //       name: 'Jawa Timur',
  //     },
  //     {
  //       code: 'KB',
  //       name: 'Kalimantan Barat',
  //     },
  //     {
  //       code: 'KS',
  //       name: 'Kalimantan Selatan',
  //     },
  //     {
  //       code: 'KT',
  //       name: 'Kalimantan Tengah',
  //     },
  //     {
  //       code: 'KD',
  //       name: 'Kendal',
  //     },
  //     {
  //       code: 'LP',
  //       name: 'Lampung',
  //     },
  //     {
  //       code: 'ML',
  //       name: 'Maluku',
  //     },
  //     {
  //       code: 'MU',
  //       name: 'Maluku Utara',
  //     },
  //     {
  //       code: 'NB',
  //       name: 'Nusa Tenggara Barat',
  //     },
  //     {
  //       code: 'NR',
  //       name: 'Nusa Tenggara Timur',
  //     },
  //     {
  //       code: 'PP',
  //       name: 'Papua',
  //     },
  //     {
  //       code: 'RI',
  //       name: 'Riau',
  //     },
  //     {
  //       code: 'RI',
  //       name: 'Riau Kepulauan',
  //     },
  //     {
  //       code: 'SO',
  //       name: 'Solo',
  //     },
  //     {
  //       code: 'SS',
  //       name: 'Sulawesi Selatan',
  //     },
  //     {
  //       code: 'ST',
  //       name: 'Sulawesi Tengah',
  //     },
  //     {
  //       code: 'SU',
  //       name: 'Sulawesi Tenggara',
  //     },
  //     {
  //       code: 'SU',
  //       name: 'Sulawesi Utara',
  //     },
  //     {
  //       code: 'SB',
  //       name: 'Sumatera Barat',
  //     },
  //     {
  //       code: 'SE',
  //       name: 'Sumatera Selatan',
  //     },
  //     {
  //       code: 'SR',
  //       name: 'Sumatera Utara',
  //     },
  //     {
  //       code: 'YG',
  //       name: 'Yogyakarta',
  //     },
  //   ],
  // },
  // {
  //   name: 'Netherlands',
  //   code: 'NL',
  //   states: null,
  // },
  // {
  //   name: 'Philippines',
  //   code: 'PH',
  //   states: [
  //     {
  //       code: 'ADN',
  //       name: 'Agusan Del Norte',
  //     },
  //     {
  //       code: 'Al',
  //       name: 'Albay',
  //     },
  //     {
  //       code: 'BT',
  //       name: 'Batangas',
  //     },
  //     {
  //       code: 'BG',
  //       name: 'Benguet',
  //     },
  //     {
  //       code: 'CG',
  //       name: 'Cagayan',
  //     },
  //     {
  //       code: 'CS',
  //       name: 'Camarines Sur',
  //     },
  //     {
  //       code: 'CZ',
  //       name: 'Capiz',
  //     },
  //     {
  //       code: 'CB',
  //       name: 'Cebu',
  //     },
  //     {
  //       code: 'DDN',
  //       name: 'Davao Del Norte',
  //     },
  //     {
  //       code: 'DDS',
  //       name: 'Davao Del Sur',
  //     },
  //     {
  //       code: 'DI',
  //       name: 'Dinagat Islands',
  //     },
  //     {
  //       code: 'IN',
  //       name: 'Ilocos Norte',
  //     },
  //     {
  //       code: 'IS',
  //       name: 'Ilocos Sur',
  //     },
  //     {
  //       code: 'ILO',
  //       name: 'Iloilo',
  //     },
  //     {
  //       code: 'LGN',
  //       name: 'Laguna',
  //     },
  //     {
  //       code: 'LDN',
  //       name: 'Lanao Del Norte',
  //     },
  //     {
  //       code: 'LT',
  //       name: 'Leyte',
  //     },
  //     {
  //       code: 'MM',
  //       name: 'Metropolitan Manila',
  //     },
  //     {
  //       code: 'MODL',
  //       name: 'Misamis Occidental',
  //     },
  //     {
  //       code: 'MONL',
  //       name: 'Misamis Oriental',
  //     },
  //     {
  //       code: 'MOODL',
  //       name: 'Negros Occidental',
  //     },
  //     {
  //       code: 'NE',
  //       name: 'Nueva Ecija',
  //     },
  //     {
  //       code: 'PLN',
  //       name: 'Palawan',
  //     },
  //     {
  //       code: 'PMNG',
  //       name: 'Pampanga',
  //     },
  //     {
  //       code: 'PNGS',
  //       name: 'Pangasinan',
  //     },
  //     {
  //       code: 'SMR',
  //       name: 'Samar',
  //     },
  //     {
  //       code: 'SHRFK',
  //       name: 'Shariff Kabunsuan',
  //     },
  //     {
  //       code: 'SCT',
  //       name: 'South Cotabato',
  //     },
  //     {
  //       code: 'TRLC',
  //       name: 'Tarlac',
  //     },
  //     {
  //       code: 'ZMBLS',
  //       name: 'Zambales',
  //     },
  //     {
  //       code: 'ZDSR',
  //       name: 'Zamboanga Del Sur',
  //     },
  //   ],
  // },
  // {
  //   name: 'Switzerland',
  //   code: 'CH',
  //   states: null,
  // },
  // // {
  // //   name: 'Taiwan',
  // //   code: 'TW',
  // //   states: null,
  // // },
  // {
  //   name: 'United Kingdom',
  //   code: 'GB',
  //   states: null,
  // },
  // { name: 'Andorra', code: 'AD', states: null },
  // { name: 'Albania', code: 'AL', states: null },
  // { name: 'American Samoa', code: 'AS', states: null },
  // { name: 'Azerbaijan', code: 'AZ', states: null },
  // { name: 'Belgium', code: 'BE', states: null },
  // { name: 'Bulgaria', code: 'BG', states: null },
  // { name: 'Belarus', code: 'BY', states: null },
  // { name: 'Cook Islands', code: 'CK', states: null },
  // { name: 'Czech Republic', code: 'CZ', states: null },
  // { name: 'Denmark', code: 'DK', states: null },
  // { name: 'Estonia', code: 'EE', states: null },
  // { name: 'Spain', code: 'ES', states: null },
  // { name: 'Finland', code: 'FI', states: null },
  // { name: 'Fiji', code: 'FJ', states: null },
  // { name: 'Faroe Islands', code: 'FO', states: null },
  // { name: 'Georgia', code: 'GE', states: null },
  // { name: 'Guernsey', code: 'GG', states: null },
  // { name: 'Gibraltar', code: 'GI', states: null },
  // { name: 'Greece', code: 'GR', states: null },
  // { name: 'Guam', code: 'GU', states: null },
  // { name: 'Croatia', code: 'HR', states: null },
  // { name: 'Hungary', code: 'HU', states: null },
  // { name: 'Ireland', code: 'IE', states: null },
  // { name: 'Isle of Man', code: 'IM', states: null },
  // //{ name: 'Iceland', code: 'IS', states: null },
  // { name: 'Italy', code: 'IT', states: null },
  // { name: 'Jersey', code: 'JE', states: null },
  // { name: 'Kyrgyzstan', code: 'KG', states: null },
  // { name: 'Kiribati', code: 'KI', states: null },
  // { name: 'Kazakhstan', code: 'KZ', states: null },
  // { name: 'Liechtenstein', code: 'LI', states: null },
  // { name: 'Lithuania', code: 'LT', states: null },
  // { name: 'Luxembourg', code: 'LU', states: null },
  // { name: 'Latvia', code: 'LV', states: null },
  // { name: 'Monaco', code: 'MC', states: null },
  // { name: 'Marshall Islands', code: 'MH', states: null },
  // { name: 'Malta', code: 'MT', states: null },
  // { name: 'New Caledonia', code: 'NC', states: null },
  // { name: 'Norway', code: 'NO', states: null },
  // { name: 'Nauru', code: 'NR', states: null },
  // { name: 'French Polynesia', code: 'PF', states: null },
  // { name: 'Papua New Guinea', code: 'PG', states: null },
  // { name: 'Poland', code: 'PL', states: null },
  // { name: 'Portugal', code: 'PT', states: null },
  // { name: 'Palau', code: 'PW', states: null },
  // { name: 'Romania', code: 'RO', states: null },
  // { name: 'Serbia', code: 'RS', states: null },
  // { name: 'Solomon Islands', code: 'SB', states: null },
  // { name: 'Sweden', code: 'SE', states: null },
  // { name: 'Slovenia', code: 'SI', states: null },
  // { name: 'Slovakia', code: 'SK', states: null },
  // { name: 'San Marino', code: 'SM', states: null },
  // { name: 'Tajikistan', code: 'TJ', states: null },
  // { name: 'Turkmenistan', code: 'TM', states: null },
  // { name: 'Tonga', code: 'TO', states: null },
  // { name: 'Tuvalu', code: 'TV', states: null },
  // { name: 'Ukraine', code: 'UA', states: null },
  // { name: 'Uzbekistan', code: 'UZ', states: null },
  // { name: 'Vanuatu', code: 'VU', states: null },
  // { name: 'Wallis and Futuna', code: 'WF', states: null },
  // { name: 'Samoa', code: 'WS', states: null },
  // { name: 'Russia', code: 'RU', states: null },
  // { name: 'N. Mariana Islands', code: 'MP', states: null },
  // { name: 'Moldova', code: 'MD', states: null },
  // { name: 'Macedonia', code: 'MK', states: null },
  // { name: 'Micronesia Fed. St.', code: 'FM', states: null },
  // { name: 'Bosnia & Herzegovina', code: 'BA', states: null },
  // { name: 'Armenia', code: 'AM', states: null },
];

module.exports = {
  COUNTRIES_WITH_STATES,
  RESTRICTED_COUNTRIES_CHECKOUT
};
