import React, { useEffect, useMemo, useState} from "react";
import {
  Modal,
  Select,
  Input,
  Form,
  Button,
  InputNumber,
  message,
  Row,
  Col,
  Typography,
} from "antd";
import { useDispatch } from "react-redux";
import { updateStock } from "../../actions/inventory";
import {createInventoryLogs} from "../../services/products"
import "./inputValueModal.scss";

const { Text } = Typography;

const InputStockValueModal = ({
  showInputValueModal,
  product,
  property,
  closeModal,
}) => {
  const customizedTitle = ` Update ${property.toUpperCase()} of ${
    product.name
  }`;
  const [stockValue, setStockValue] = useState(null);
  const [updateReason, setReason] = useState("");

  const dispatch = useDispatch();
  useEffect(()=>{
    if(showInputValueModal) {
      setStockValue(product[property])
    }

  },[showInputValueModal])

  const hideModal = function () {
    setStockValue(null);
      setReason("");
      closeModal();
  }

  const handleSubmit = () => {
    console.log("Here is the reason", updateReason);
    if (stockValue >= 0 && stockValue != product[property]) {
      
      dispatch(
        updateStock(
          product.id,
          {
            [property]: stockValue,
          },
          (res) => {
            if (res && res.error) {
              console.log(res.error.message);
              message.error(`Failed to update ${property} of ${product.name}`);
            } else {
              message.success(
                `Successfully updated ${property} of ${product.name}`
              );
              //if (updateReason.length > 0 ) {
                let logPayload = {
                  reason: updateReason,
                  impactedStock: property,
                  updatedQuantity: stockValue,
                  productName: product.name,
                  productId: product.id,
                  preupdatedValue: product[property],
                }
                createInventoryLogs(logPayload)
              //}
              dispatch({
                type: "SET_PRODUCT_STOCK_VALUE",
                payload: {
                  id: product.id,
                  property: property,
                  value: stockValue,
                },
              });
              hideModal()
              
            }
            
          }
        )
      );
    } else {
      message.error(`No changes made to ${property} of ${product.name}`);
      hideModal()
    } 
  };
  return (
    <Modal
      title={customizedTitle}
      visible={showInputValueModal}
      onCancel={hideModal}
      footer={[
        <Button
          form="serum_recommendation_form"
          className="ant-btn ant-btn-primary"
          key="submit"
          htmlType="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Row gutter={[0, 16]}>
        <Col span={6} style={{paddingTop:"12px"}}>
          <Text className="text-bold" style>QUANTITY</Text>
        </Col>

        <Col span={18}>
          <InputNumber
            name="Quantity"
            min={0}
            style={{width:"100%"}}
            value={stockValue}
            placeholder="No of products to add"
            onChange={(newValue) => setStockValue(newValue)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6} style={{paddingTop:"8px"}}>
          <Text className="text-bold">REASON</Text>
        </Col>
        <Col span={18}>
          <Input placeholder="Please enter reason" 
          value={updateReason}
          onChange={(val) => 
            setReason(val.target.value) }
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default InputStockValueModal;
