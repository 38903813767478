import RecommendationsApi from "services/recommendations";

function handleError(err) {
  console.error(err);
}


export function listRecommendations(result) {
  return {
    type: "LIST_RECOMMENDATIONS",
    payload: result
  };
}


export function addRecommendation(result) {
  return {
    type: "ADD_RECOMMENDATION",
    payload: result
  };
}

export const getRecommendationsList = () => async dispatch => {
   try {
    let res = await RecommendationsApi.getRecommendationsList();
    dispatch(listRecommendations(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};


export const createRecommendation = (payload) => async dispatch => {
  try {
    let res = await RecommendationsApi.createRecommendation(payload);
    dispatch(addRecommendation(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}


export const deleteRecommendation = (payload) => async dispatch => {
  try {
    let res = await RecommendationsApi.deleteRecommendation(payload);
    // dispatch(addRecommendation(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}


