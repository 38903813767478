import { combineReducers } from "redux";
import Products from "./products";
import Ingredients from "./ingredients";
import Tags from "./tags";
import Recommendations from "./recommendations";
import Category from "./category";
import Currency from "./currency";
import Assessment from "./assessment";
import Customer from "./customer";
import Login from "./login";
import Orders from "./orders";
import Discount from "./discount";
import Admin from "./admin";
import Benefits from "./benefits";
import Fulfillment from "./fulfillment";
import Referral from "./referral";
import Banner from "./banner";
import Reviews from "./reviews";
import Inventory from "./inventory";
import Tax from "./tax";
import Selfies from "./selfies";

export default combineReducers({
  Products,
  Ingredients,
  Tags,
  Category,
  Currency,
  Assessment,
  Customer,
  Login,
  Orders,
  Discount,
  Admin,
  Recommendations,
  Benefits,
  Fulfillment,
  Referral,
  Banner,
  Reviews,
  Inventory,
  Tax,
  Selfies,
});
