import { LIST_BENEFITS, SET_ACTIVE_BENEFIT, ADD_BENEFIT } from 'actions/benefits'

const initialState = {
    benefits: [],
    activeBenefit: null,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_ACTIVE_BENEFIT: 
        return setActiveBenefit(state, action);
      case LIST_BENEFITS:
        return listBenefits(state, action);
      case ADD_BENEFIT:
        return addBenefit(state, action);
      default:
        return state
    }
  }
  
  function setActiveBenefit(state, action) {
    return {
      ...state,
      activeBenefit: { ...action.payload }
    }
  }
  
  function listBenefits(state, action) {
    return {
      ...state, 
      benefits: [ ...action.payload ]
    };
  }
  
  function addBenefit(state, action) {
    return {
      ...state,
      activeBenefit: { ...action.payload }
    };
  }
  