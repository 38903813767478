import CustomerApi from "services/customer";

function handleError(err) {
  console.error(err);
}

export function customerList(result) {
  return {
    type: "CUSTOMER_LIST",
    payload: result,
  };
}
export function saveActiveCustomertype(result) {
  return {
    type: "SAVECUSTOMER_TYPE",
    payload: result,
  };
}
export function saveCustomerPageDetail(result) {
  return {
    type: "SAVECUSTOMER_PAGE",
    payload: result,
  };
}
export function customerAddressList(result) {
  return {
    type: "CUSTOMER_ADDRESS_LIST",
    payload: result,
  };
}
export function addNewAddress(result) {
  return {
    type: "ADD_NEW_ADDRESS",
    payload: result,
  };
}
export function setCustomer(result) {
  return {
    type: "SET_CUSTOMER",
    payload: result,
  };
}

function getCustomerTypes(data) {
  let activeStatusFilters = [];
  if (data && data.includes("customerType")) {
    let str = data.split("&");
    str.forEach((x) => {
      activeStatusFilters.push(x.split("=").pop());
    });
  }
  return activeStatusFilters;
}

export const getCustomerList = (query) => async (dispatch) => {
  try {
    let res = await CustomerApi.getCustomerList(query);
    dispatch(customerList(res.result.result));
    dispatch(saveActiveCustomertype(getCustomerTypes(query)));
    dispatch(saveCustomerPageDetail(res.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
  }
};

export const getCustomerExportList = (query, callback) => async () => {
  try {
    let res = await CustomerApi.getCustomerList(query);
    callback(res.result.result);
  } catch (err) {
    handleError(err);
  }
};

export const saveCustomerPage = (query) => async (dispatch) => {
  try {
    //let res = await CustomerApi.getCustomerList(query);
    // dispatch(customerList(res.result));
    // dispatch(saveCustomerPage(query));
    //return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const searchCustomerById = (id) => async (dispatch) => {
  try {
    let res = await CustomerApi.searchCustomerById(id);
    if (res && res.result) {
      dispatch(customerList(res.result.result));
    }
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const getCustomerAddressList = (payload, query) => async (dispatch) => {
  try {
    let res = await CustomerApi.getCustomerAddressList(payload, query);
    dispatch(customerAddressList(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const createNewAddress = (payload, updateAddressList) => async (
  dispatch
) => {
  try {
    let res = await CustomerApi.addNewAddress(payload);
    if (!updateAddressList) {
      dispatch(addNewAddress(res));
    }
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateAddress = (id, payload) => async (dispatch) => {
  try {
    let res = await CustomerApi.updateAddress(id, payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getCustomer = (id) => async (dispatch) => {
  try {
    let res = await CustomerApi.getCustomerById(id);
    dispatch(setCustomer(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateCustomer = (id, payload) => async (dispatch) => {
  try {
    let res = await CustomerApi.updateCustomerById(id, payload);
    dispatch(setCustomer(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getComments = (customerId) => async (dispatch) => {
  try {
    const res = await CustomerApi.getComments(customerId);
    // dispatch(setOrderComments(res));
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const postComment = (payload) => async (dispatch) => {
  try {
    const res = await CustomerApi.postComment(payload);
    // dispatch(getOrderComments(payload.orderId));
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const postReply = (customerId, payload) => async (dispatch) => {
  try {
    const res = await CustomerApi.postReply(customerId, payload);
    return res;
    // dispatch(setOrderComments(res));
  } catch (error) {
    handleError(error);
  }
};

export const updateComment = (customerId, commentId, comment) => async (dispatch) => {
  try {
    const res = await CustomerApi.updateComment(customerId, {
      commentId,
      comment,
    });
    return res;
    // dispatch(setOrderComments(res));
  } catch (error) {
    handleError(error);
  }
};

export const updateReply =
  (customerId, commentId, replyId, comment) => async (dispatch) => {
    try {
      const res = await CustomerApi.updateReply(customerId, {
        commentId,
        comment,
        replyId,
      });
      return res;
      // dispatch(setOrderComments(res));
    } catch (error) {
      handleError(error);
    }
  };
