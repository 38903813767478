import { Api } from "../config/request";

export const getIngredientById = id => {
  let url = `catalogue/ingredient/${id}`;
  return Api.getRequest(url);
};

export const getIngredientsList = query => {
  let url = `catalogue/ingredient/list`;
  return Api.getListRequest(url);
};

// export const getSearchedIngredients = (type, name, tags, sort) => {
//   let url = `catalogue/ingredient/search${name ? '='+name:''}?${type ? 'type='+type+'&' : ""}${name ? 'name='+name+'&' : ""}${tags ? 'tags='+tags+'&' : ""}${sort ? 'orderBy=createdAt:'+sort : ""}`
//   return Api.getListRequest(url);
// }

export const getSearchedIngredients = (type, name, tags, sort) => {
  let url = `catalogue/ingredient/search?search${name ? '='+name:''}`
  return Api.getListRequest(url);
}

export const createIngredient = (payload) => {
  let url = `catalogue/ingredient/`;
  return Api.postRequest(url, payload)
}

export const updateIngredientById = (id, payload) => {
  let url = `catalogue/ingredient/${id}`;
  return Api.patchRequest(url, payload);
}

export const deleteIngredientById = id => {
  let url = `catalogue/ingredient/${id}`;
  return Api.deleteRequest(url);
}

const Admins = {
    getIngredientById,
    getIngredientsList,
    createIngredient,
    updateIngredientById,
    getSearchedIngredients,
    deleteIngredientById
};

export default Admins;
