import React, { Component } from "react";
import "./../listOrders/listOrders.scss";
import {
  Table,
  Button,
  Icon,
  Input,
  Menu,
  Dropdown,
  message,
  Tag,
  Modal,
  Checkbox,
  DatePicker,
  Badge,
  Select,
  Form,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/orders";
import * as fulfillmentActions from "actions/fulfillment";
import { debounce, formatAddress } from "utils/helpers";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Link } from "react-router-dom";
import {
  paymentStatusMapping,
  orderFullfillmentStatusListing,
  oderTypeMapper,
  orderStatusFilterMapping,
  trackingDetailsCarriers,
} from "utils/constants";
import OrdersHeader from "../../../components/orders/OrdersHeader";
import * as assessmentActions from "actions/assessment";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const FULFILLMENT_ACTION_OPTION = [
  {
    key: "create_waybill",
    label: "Create waybill",
  },
  {
    key: "generate_label",
    label: "Generate Label",
  },
];

const WHATSAPP_STATUS_ACTION_OPTION = [
  {
    key: "sent",
    label: "Sent",
    code : 1,
    color:"green",
  },
  {
    key: "not_on_whatsapp",
    label: "Not on Whatsapp",
    code:3,
    color:"red"
  },
  {
    key: "purchased_already",
    label: "Purchased Already",
    code:4,
    color:"green"
  },
  {
    key: "Existing_chat",
    label: "Existing Chat",
    code:5,
    color:"blue"
  },
];

const ORDER_ADVANCED_SEARCH_OPTIONS = [
  // {
  //   key: "search",
  //   label: "Name",
  // },
  // {
  //   key: "user.id",
  //   label: "User ID",
  // },
  {
    key: "search",
    label: "Email",
  },
  // {
  //   key: "receiptId",
  //   label: "Receipt ID",
  // },
  // {
  //   key: "attempt",
  //   label: "Submission ID",
  // },
  // {
  //   key: "shortId",
  //   label: "Order ID",
  // },
  // {
  //   key: "promoCode",
  //   label: "Discount Code",
  // },
  // {
  //   key: "tag",
  //   label: "Tags",
  // },
  // {
  //   key: "chargedPrice",
  //   label: "Charged Price",
  // },
  // {
  //   key: "user.location.country",
  //   label: "Country",
  // },
];

const CART_STATUS = [
  {
    key: "all",
    label: "All",
  },

  {
    key: "init",
    label: "Viewed Results",
  },
  {
    key: "checkout",
    label: "Initiated Checkout",
  },
  {
    key: "payment",
    label: "Proceeded to payment",
  },
];

const PHONE_STATUS = [
  {
    key: "all",
    label: "All",
  },
  // {
  //   key: "without_phone",
  //   label: "Without Phone",
  // },
  {
    key: "with_phone",
    label: "With Phone",
  },
];

const { confirm } = Modal;

class abondenedCart extends Component {
  constructor(props) {
    super(props);
    this.allColumns = [
      "all",
      "orderId",
      "name",
      "email",
      "attempt",
      "submissionDate",
      "submissionTime",
      "chargedPrice",
      "code",
      "userId",
      "itemsPurchased",
      "orderStatus",
      "queryParam",
      "fulfillmentStatus",
      "stripeInvoiceId",
      "type",
      "tags",
      "notes",
      "customerNotes",
      "phone",
      "shippingAddress",
      "country",
      "totalWeight",
      "itemsQuantity",
      "action",
      "status",
    ];
    this.state = {
      searchKey: "",
      sortOrder: 1,
      activeAdvSearch: ORDER_ADVANCED_SEARCH_OPTIONS[0],
      activeCartStatus: [],
      activePhoneStatus:"",
      selectedCarts: [],
      selectedOrderIds: [],
      userType: "",
      activeFulfillMentFilters: [],
      abandonedCartList:[],
      activeStatusFilters: [],
      shownColumns: [
        "all",
        "orderId",
        "name",
        "email",
        "whatsAppMsgStatus",
        "phone",
        "attempt",
        "submissionDate",
        "submissionTime",
        "chargedPrice",
        "queryParam",
        "code",
        "userId",
        "itemsPurchased",
        "orderStatus",
        "fulfillmentStatus",
        "stripeInvoiceId",
        "type",
        "tags",
        "notes",
        "customerNotes",
        "phone",
        "shippingAddress",
        "shipmentId",
        "shipmentLabel",
        "fulfillmentStatusCode",
        "country",
        "totalWeight",
        "itemsQuantity",
        "action",
        "status",
      ],
      checked: true,
      disabled: false,
      showExportModal: false,
      exportModalLoading: false,
      startDate: undefined,
      endDate: undefined,
      showAssessmentModal: false,
      whatsAppModal: false,
      whatsAppMessageType: "abandoned_cart",
      questions: [
        "How would you best describe your T-Zone in the morning?",
        "How would you best describe your cheeks in the morning?",
        "What are your top 2 skin concerns?",
      ],
      fulfillmentModal: false,
      orderToFulfill: {},
    };
    this.csvRef = React.createRef();
    this.shoppingExportCsvRef = React.createRef();
  }
  componentDidMount() {
    // setting up userType for not visible actions
    const userType = localStorage.getItem("userType");
    this.setState({
      userType,
    });
    // setting up userType for not visible actions
    this.props.fetchAllAbandonedCartOrders(
      this.getQueryString(),
      this.props &&
        this.props.ordersPageDetails &&
        this.props.ordersPageDetails.currentPage &&
        this.props.ordersPageDetails.currentPage,
      undefined,
      this.state.startDate,
      this.state.endDate
    );
    this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
  }
  // checkbox
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  onColumnFilterChange = (e, item) => {
    if (item === "all") {
      if (e.target.checked) {
        this.setState({
          shownColumns: [...this.allColumns],
        });
      } else {
        this.setState({
          shownColumns: [],
        });
      }
      return;
    } else {
      this.setState((prevState, props) => {
        return {
          shownColumns: prevState.shownColumns.filter((col) => col !== "all"),
        };
      });
    }
    if (e.target.checked) {
      this.setState((prevState) => {
        let columnsArr = [...prevState.shownColumns, item];
        return {
          shownColumns: columnsArr,
        };
      });
    } else {
      this.setState((prevState) => {
        let columnsArr = prevState.shownColumns.filter((value) => {
          return value !== item;
        });
        return {
          shownColumns: columnsArr,
        };
      });
    }

    this.setState({
      checked: e.target.checked,
    });
  };
  // checkbox

  onChange = (e) => {};
  handleMenuClick = (e) => {};
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.props.fetchAllAbandonedCartOrders(
            `email=${this.state.searchKey}`,
            this.props &&
              this.props.ordersPageDetails &&
              this.props.ordersPageDetails.currentPage &&
              this.props.ordersPageDetails.currentPage,
            undefined,
            this.state.startDate,
            this.state.endDate
          );
        } else {
          this.props.fetchAllAbandonedCartOrders(
            this.getQueryString(),
            this.props &&
              this.props.ordersPageDetails &&
              this.props.ordersPageDetails.currentPage &&
              this.props.ordersPageDetails.currentPage,
            undefined,
            this.state.startDate,
            this.state.endDate
          );
        }
      }
    );
  }, 300);
  handleStatusFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.fetchAllOrders(
          `status=${value}`,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };
  handleStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeStatusFilters.indexOf(value) > -1) {
          newFilterList = state.activeStatusFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeStatusFilters, value];
        }
        return {
          activeStatusFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (index === 0 && this.state.activeStatusFilters.length === 0) {
              return `fulfillmentStatus=${value}`;
            } else {
              return `&fulfillmentStatus=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          filterQueryStatus + filterQueryFulfillment,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleFulfillStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeFulfillMentFilters.indexOf(value) > -1) {
          newFilterList = state.activeFulfillMentFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeFulfillMentFilters, value];
        }
        return {
          activeFulfillMentFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `status=${value}`;
            } else {
              return `&status=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeFulfillMentFilters.length === 0
            ) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          // filterQueryStatus + filterQueryFulfillment,
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleAdvSearchChange = (value) => {
    console.log(value);
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };
  handleCartPhoneStatusChange =( value )=>{
    console.log(value)
    this.setState(
      {
        activePhoneStatus: value,
      },
      () => {
        this.props.fetchAllAbandonedCartOrders(
          this.getQueryString(),
          this.props &&
            this.props.ordersPageDetails &&
            this.props.ordersPageDetails.currentPage &&
            this.props.ordersPageDetails.currentPage,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
    
  }

  handleCartStatusChange = (value) => {
    let { selectedCarts } = this.state;
    selectedCarts = [];
    selectedCarts.push(JSON.parse(value));
    this.setState(
      {
        activeCartStatus: JSON.parse(value),
        selectedCarts,
      },
      () => {
        this.props.fetchAllAbandonedCartOrders(
          this.getQueryString(),
          this.props &&
            this.props.ordersPageDetails &&
            this.props.ordersPageDetails.currentPage &&
            this.props.ordersPageDetails.currentPage,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };
  handleDownloadInvoice = () => {
    if (this.state.selectedOrderIds.length > 0) {
      window.open(
        `/invoice?orders=${this.state.selectedOrderIds.join()}`,
        "_blank"
      );
    }
  };

  handleDeleteOrder = (order) => {
    confirm({
      title: `Are you sure you want to delete this Order?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.deleteOrder(order.id).then((res) => {
          if (res) {
            message.success("Order delete successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleFulfillmentAction = (action) => {
    //console.log("======", action, this.state.selectedOrderIds);
    let fulFillmentAction = JSON.parse(action);
    confirm({
      title: `Are you sure you want to proceed?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        //console.log("+++++", fulFillmentAction, fulFillmentAction.key);
        if (fulFillmentAction.key === "create_waybill") {
          //console.log("LLLLL", action.key);
          this.createWaybillForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "generate_label") {
          this.generateLabelForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
          //this.handelGenerateLabel()
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  getQueryString = () => {
    let query = "";
    const { selectedCarts } = this.state;
    
      if (selectedCarts && selectedCarts.length > 0) {
        selectedCarts.map((x, index) => {
          if (x.key === "all") {
            return query;
          }
          if ((index = 0)) {
            query = "status=" + x.key;
          } else {
            query += "&status=" + x.key;
          }
        });
      }

      //console.log("++++++++++", this.state.activePhoneStatus);
      if( !this.state.activePhoneStatus){
        if (query) {
          query += "&phone=" + "with_phone&";
        } else {
          query = "phone=" + "with_phone&";
        }
      }else{
        let activePhoneStatus = JSON.parse(this.state.activePhoneStatus);
        if (activePhoneStatus.key !== "all") {
          if (query) {
            query += "&phone=" + activePhoneStatus.key + "&";
          } else {
            query = "phone=" + activePhoneStatus.key + "&";
          }
        }
      }
    return query;
  };

  handleShortAssessmentSummaryModal = (attempt) => {
    const {
      match: { params },
    } = attempt;
    this.props.getAssessmentById(attempt);
    this.setState({ showAssessmentModal: true });
  };

  showModal = () => {
    this.setState({
      showAssessmentModal: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      showAssessmentModal: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      showAssessmentModal: false,
    });
  };

  handelUpdateWhatsAppMessage = () => {
    this.props.form.validateFields(["message"], (err, values) => {
      if (!err) {
        this.props
          .updateWhatsAppMessage(this.state.whatsAppMessageType, values.message)
          .then((res) => {
            this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
            this.setState({ whatsAppModal: false });
          })
          .catch((err) => {
            this.setState({ whatsAppModal: false });
          });
      }
    });
  };

  handelWhatsAppMessage = (order,cartId, phone, msg) => {
    let name ="";
    if( order.user){
      name = order.user.firstName + " " + order.user.lastName;
    }
    
   let decoded_msg = decodeURIComponent(msg);
   let new_msg = decoded_msg.replace("{{ first_name }}", name);
    
    //let url = `https://wa.me/${phone}?text=${new_msg}`;
    let url = `https://web.whatsapp.com/send?phone=${phone}&text=${new_msg}`;
    return this.props.updateWhatsAppStatus(cartId).then((res) => {
      this.props.fetchAllAbandonedCartOrders(
        this.getQueryString(),
        this.props &&
          this.props.ordersPageDetails &&
          this.props.ordersPageDetails.currentPage &&
          this.props.ordersPageDetails.currentPage,
        undefined,
        this.state.startDate,
        this.state.endDate
      );
      message.success(`Whatsapp message send for phone: ${phone}`);
      window.open(url, "_blank");
    });
  };

  handleWhatsAppActionMenue = (action) => {
    //console.log(this.state.selectedOrderIds, action);
    confirm({
      title: `Are you sure you want to change Whatsapp status for this Order 
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
         let whatsappAction = JSON.parse(action);
         let message = whatsappAction.code;
         
           
        return this.props
          .updateMultiCartWhatsAppMsgStatus(
            this.state.selectedOrderIds,
            message
          )
          .then((res) => {
            this.props.fetchAllAbandonedCartOrders(
              this.getQueryString(),
              this.props &&
                this.props.ordersPageDetails &&
                this.props.ordersPageDetails.currentPage &&
                this.props.ordersPageDetails.currentPage,
              undefined,
              this.state.startDate,
              this.state.endDate
            );
          });
           
      },
      onCancel() {},
    });
  };

  handelUpdateFulfillment = (order) => {
    confirm({
      title: `Are you sure you want to generate Waybill for this Order 
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.createShipment(order.id).then((res) => {
          this.props.fetchAllOrders();
          if (res) {
            message.success("Waybill Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {},
    });
  };

  handelGenerateLabel = (order) => {
    confirm({
      title: `Are you sure you want to generate Label for this Order 
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.generateShipmentLabel(order.id).then((res) => {
          this.props.fetchAllOrders();
          if (res) {
            message.success("Label Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {},
    });
  };

  handelMarkShipmentShipped = (order) => {
    confirm({
      title: `Are you sure you want to mark this Order to Shipped
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToShipped(order.id).then((res) => {
          this.props.fetchAllOrders();
          if (res) {
            message.success("Shipment shipped  successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {},
    });
  };

  createWaybillForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.createShipmentInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter(),
        pageNo - 1,
        undefined,
        startDate,
        endDate
      );
      if (res) {
        message.success("Shipment shipped  successfully.");
      } else {
        message.error("Oops! Something went wrong");
      }
    });
  };

  generateLabelForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.generateLabelInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter(),
        pageNo - 1,
        undefined,
        startDate,
        endDate
      );
      if (res) {
        message.success("Label  generated successfully.");
      } else {
        message.error("Oops! Something went wrong");
      }
    });
  };

  handleUpdateFullfillmentStatusByBasicUser = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = {
          fulfillmentStatus: 4,
          trackingDetails: values.trackingDetails,
        };
        return this.props
          .updateOrder(this.state.orderToFulfill.id, payload)
          .then((res) => {
            this.props.fetchAllOrders();
            if (!res.error) {
              message.success("Order status changed to shipped");
              this.setState({ fulfillmentModal: false });
            } else {
              message.error(res.error.internalMessage);
            }
          });
      }
    });
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  SelectDates = (val) => {
    if (val.length === 0) {
      this.setState(
        {
          startDate: undefined,
          endDate: undefined,
        },
        () => this.props.fetchAllOrders("", 0)
      );
    } else {
      this.setState(
        {
          startDate: new Date(val[0]).toISOString(),
          endDate: new Date(val[1]).toISOString(),
        },
        () =>
          this.props.fetchAllOrders(
            "",
            0,
            undefined,
            this.state.startDate,
            this.state.endDate
          )
      );
    }
  };
  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  getOrderType(order) {
    if (order.isSubscribed) {
      if (
        order &&
        order.subscriptionId &&
        order.subscriptionId.period &&
        order.subscriptionId.period.value
      ) {
        if (order.subscriptionId.period.value / 30 > 1) {
          return `Subscription (${
            order.subscriptionId.period.value / 30
          } Months)`;
        }
        return "Subscription (1 Month)";
      }
      return "Subscription";
    }
    return "One-time";
  }

  getPhoneNumber(order = {}) {
    const phone = order?.usersAddress?.find(
      (x) => x.phone?.isdCode?.length && x.phone.phone.length > 6
    );
    if (phone) {
      return phone.phone.isdCode + " - " + phone.phone.phone;
    } else {
      return undefined;
    }
  }

  getCountry(order = {}) {
    const phone = order?.usersAddress?.find(
      (x) =>
        x.phone?.isdCode?.length &&
        x.phone.phone.length > 6 &&
        x.country?.length
    );
    if (phone) {
      return phone.country;
    } else {
      return undefined;
    }
  }

  getSelectedQueryStringFilter = () => {
    let queryString = "";
    const { activeFulfillMentFilters, activeStatusFilters } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `status=0&status=1&status=-2&status=-1`;
    }
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }
    if (queryString && queryString.length > 0) {
      queryString = queryString + "&";
    }
    return queryString;
  };

  getCartStatusname = (status) => {
    let name = "";
    if (status == "init") {
      name = "Viewed Results";
    } else if (status == "checkout") {
      name = "Initiated Checkout";
    } else if (status == "payment") {
      name = "Proceeded to payment";
    }
    return name;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const label = `${this.state.checked ? "Checked" : "Unchecked"}-${
      this.state.disabled ? "Disabled" : "Enabled"
    }`;
    const { activeAssessment } = this.props;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const selectedOrderIds = selectedRows.map((row) => row.id);
        //console.log(selectedOrderIds,);
        this.setState({
          selectedOrderIds,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };
    const columns = [
      {
        title: "Order Date",
        dataIndex: "submissionDate",
        key: "submissionDate",
        width: 130,
      },
      {
        title: "Order Time",
        dataIndex: "submissionTime",
        key: "submissionTime",
        width: 130,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        render: (user) => {
          return (
            <Link
              style={{ color: "#373873" }}
              to={`/customer/edit/${user && user.id}`}
              target="_blank"
            >
              {(user && user.firstName && user.firstName) || ""}{" "}
              {(user && user.lastName && user.lastName) || ""}
            </Link>
          );
        },
      },
      // {
      //   title: "WhatsApp",
      //   dataIndex: "whatsAppMsgStatus",
      //   key: "whatsAppMsgStatus",
      //   width: 130,
      //   render: (order) => {
      //     //console.log(order);
      //     let phone = this.getPhoneNumber(order);
      //     let action = WHATSAPP_STATUS_ACTION_OPTION.filter(
      //       (status) => status.code === order.whatsAppMsgStatus
      //     )[0];
      //     //console.log("dsjkjdksj", phone);
      //     if (phone) {
            
      //       return order.whatsAppMsgStatus === 0 ? (
      //         <div
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             let formatted_phone_no = phone.replace(/[-()+\s]/g, "");
      //             let msg =
      //               this.props.whatsAppMessageList.length > 0
      //                 ? typeof this.props.whatsAppMessageList[0] == "string"
      //                   ? this.props.whatsAppMessageList[0]
      //                   : this.props.whatsAppMessageList[0].message
      //                 : "";
      //             let text_msg = encodeURIComponent(msg);
      //             this.handelWhatsAppMessage(
      //               order,
      //               order._id,
      //               formatted_phone_no,
      //               text_msg
      //             );
      //           }}
      //           style={{
      //             color: "#373873",
      //             textDecoration: "underline",
      //             cursor: "pointer",
      //           }}
      //         >
      //           New message
      //         </div>
      //       ) : (
      //         <span style={{ color: action && action.color }}> {action && action.label}</span>
      //       );
      //     } else {
      //       return <div>-</div>;
      //     }
      //   },
      // },
      {
        title: "Phone Number",
        dataIndex: "phone",
        key: "phone",
        width: 170,
        render: (order) => {
          let phone = this.getPhoneNumber(order);
          if (phone) {
            return <div>{phone}</div>;
          } else {
            return <div>-</div>;
          }
        },
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 170,
        render: (order) => {
          let country = this.getCountry(order);
          if (country) {
            return <div>{country}</div>;
          } else {
            return <div>-</div>;
          }
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 170,
      },
      {
        title: "URL",
        dataIndex: "queryParam",
        key: "queryParam",
        width: 130,
      },
      // {
      //   title: "Submission ID",
      //   dataIndex: "attempt",
      //   key: "attempt",
      //   width: 120,
      //   render: (attempt) => {
      //     return (
      //       <div className="flex">
      //         <div
      //           style={{ color: "#373873" }}
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             attempt !== "-" &&
      //               this.handleShortAssessmentSummaryModal(attempt);
      //           }}
      //         >
      //           {attempt}
      //         </div>
      //       </div>
      //     );
      //   },
      // },

      {
        title: "Cart Status",
        dataIndex: "status",
        key: "status",
        width: 170,
        render: (status) => {
          return (
            <div style={{ textTransform: "capitalize" }}>
              {this.getCartStatusname(status)}
            </div>
          );
        },
      },

      {
        title: "Cart Value",
        dataIndex: "chargedPrice",
        key: "chargedPrice",
        width: 130,
      },
      {
        title: "Discount Code",
        dataIndex: "code",
        key: "code",
        width: 130,
      },
      {
        title: "Cart Item",
        dataIndex: "itemsPurchased",
        key: "itemsPurchased",
        width: 130,
      },
    ].filter((column) =>
      this.state.shownColumns.find(
        (hiddenColKey) => hiddenColKey === column.key
      )
    );

    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });
    headers.filter((head) => head.key !== "action");
    
    console.log('this.props.ordersList', this.props.ordersList);
    const mappedOrdersList =
      this.props.ordersList.length > 0
        ? this.props.ordersList.map((order) => {
            const itemsPurchased = order.items.map(x => x?.product?.ssu).join(', ');
            return {
              id: order._id,
              submissionTime: `${
                order.createdAt
                  ? momentTimezone(order.createdAt)
                      .tz("Asia/Singapore")
                      .format("hh:mm a")
                  : "-"
              }`,
              key: order.user && order.user.id,
              userId: order.user && order.user.id,
              email: order.user && order.user.email,
              name: order.user,
              whatsAppMsgStatus: order,
              phone: order,
              submissionDate: `${
                order.createdAt
                  ? moment(order.createdAt).format("DD/MM/YYYY")
                  : "-"
              }`,
              attempt: order.attempt ? order.attempt : "-",
              // attempt: "",
              chargedPrice:
                `${
                  order.currency &&
                  order.currency.code &&
                  order.currency.code.toUpperCase()
                }` +
                " " +
                `${order.totalAmountAfterDiscount === 0 ? order.totalAmount : order.totalAmountAfterDiscount}`,
              // totalPrice: order.totalPrice,
              orderId: order.id,
              queryParam: order.queryParam ? order.queryParam : "-",
              receiptId: "",
              orderStatus: "",
              invoice: "",
              origin: "",
              shippingAddress: "",
              shipmentId: "",
              fulfillmentStatusCode: "",
              shipmentLabel: "",
              itemsPurchased,
              itemsQuantity: "",
              action: "",
              type: "",
              totalWeight: "",
              tags: "",
              country: order,
              notes: "",
              customerNotes: "",
              stripeInvoiceId: "",
              fulfillmentStatus: "",
              promoType: "",
              code:
                order && order.promoCode && order.promoCode
                  ? order.promoCode
                  : "-",
              shipping: "",
              status: order && order.status && order.status,
              trackingDetails: "",
            };
          })
        : [];
    const csvData = mappedOrdersList.map((item) => {
      return {
        ...item,
        name: `${(item.name && item.name.firstName) || ""} ${
          (item.name && item.name.lastName) || ""
        }`,
        orderId: item.orderId && item.orderId.shortId && item.orderId.shortId,
        tags:
          item.tags &&
          item.tags
            .map((tag) => {
              return tag === Object(tag) ? tag.name : "";
            })
            .join(","),
      };
    });
    const menu = (
      <Menu
        onClick={(e) => this.handleStatusFilter("status", e.key)}
        selectedKeys={this.state.activeStatusFilters}
        multiple
        selectable
      >
        {Object.keys(orderStatusFilterMapping)
          .filter((x) => x != 2)
          .map((key) => {
            return (
              <Menu.Item key={key}>{orderStatusFilterMapping[key]}</Menu.Item>
            );
          })}
      </Menu>
    );
    const fulfillmentMenu = (
      <Menu
        onClick={(e) =>
          this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
        }
        selectedKeys={this.state.activeFulfillMentFilters}
        multiple
        selectable
      >
        {Object.keys(orderFullfillmentStatusListing).map((key) => {
          return (
            <Menu.Item key={key}>
              {orderFullfillmentStatusListing[key]}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const fulfillmentActionMenu = (
      <Menu
        onClick={(e) => this.handleFulfillmentAction(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {FULFILLMENT_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const whatsappActionMenu = (
      <Menu
        onClick={(e) => this.handleWhatsAppActionMenue(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {WHATSAPP_STATUS_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label, code: option.code, color : option.color })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const advancedSearchMenu = (
      <Menu
        onClick={(e) => this.handleAdvSearchChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {ORDER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const cartStatus = (
      <Menu
        onClick={(e) => this.handleCartStatusChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeCartStatus)]}
      >
        {CART_STATUS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const phoneStatus = (
      <Menu
        onClick={(e) => this.handleCartPhoneStatusChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activePhoneStatus)]}
      >
        {PHONE_STATUS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const columnFilterMenu = (
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        className="flex flex-col column-checkbox-wrapper"
      >
        {/* <div onClick={this.handleMenuStopClick} key="3">Close</div> */}
        {this.allColumns.map((item, index) => {
          return (
            <Checkbox
              key={index}
              checked={this.state.shownColumns.find((value) => {
                return value === item;
              })}
              onChange={(e) => this.onColumnFilterChange(e, item)}
            >
              {item
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Checkbox>
          );
        })}
      </div>
    );

    if (activeAssessment && activeAssessment.questionsAnswered) {
      var result = [];
      this.state.questions.forEach((question) => {
        let x = activeAssessment.questionsAnswered.find((questionObject) => {
          return question.includes(questionObject.question.text);
        });
        if (x) {
          result.push(x);
        }
      });
    }

    return (
      <div className="orders-page-list" style={{ marginLeft: "200px" }}>
        <OrdersHeader
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChangeInput={this.onChangeInput}
          fetchAllAbandonedCartOrders={this.props.fetchAllAbandonedCartOrders}
          csvData={csvData}
          allHeaders={headers}
          csvRef={this.csvRef}
          shoppingExportCsvRef={this.shoppingExportCsvRef}
          ordersList={this.props.ordersList}
          selectedOrderIds={this.state.selectedOrderIds}
          pageName="Abandoned Cart"
          selectedCarts={this.state.selectedCarts}
          totalCount={this.props.ordersPageDetails?.totalCount || 0}
        />
        <div className=" nav-container justify-between">
          <div className="flex"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
              marginRight: "15px",
            }}
          >
            <Button
              type="primary"
              className="mr-20"
              onClick={() => {
                this.setState({ whatsAppModal: true });
              }}
            >
              <Icon type="message"></Icon>
              Whatsapp Message
            </Button>
            <Dropdown
              overlay={whatsappActionMenu}
              disabled={this.state.selectedOrderIds.length === 0}
            >
              <Button
                className="dropdown-btn"
                style={
                  this.state.selectedOrderIds.length !== 0
                    ? {
                        background: "#373873",
                        color: "#fff",
                        height: "32px",
                        marginBottom: "10px",
                        marginRight: "20px",
                      }
                    : {
                        height: "32px",
                        marginBottom: "10px",
                        marginRight: "20px",
                      }
                }
              >
                Change Whatsapp Status <Icon type="down" />
              </Button>
            </Dropdown>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                type="primary"
                className="mr-20"
                onClick={() => {
                  this.props.history.push("/cart");
                }}
              >
                Create Cart
              </Button>
            </div>
          </div>
        </div>

        <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
          <Search
            placeholder={`Search by ${this.state.activeAdvSearch.label}`}
            onSearch={(value) => this.handleSearch(value)}
            style={{ width: "50%", marginRight: "5px" }}
            onKeyUp={(e) => this.handleSearch(e.target.value)}
          />
          <Dropdown overlay={advancedSearchMenu}>
            <Button
              className="dropdown-btn"
              style={{ flex: 1, marginRight: "5px" }}
            >
              Advanced Search
              <Icon type="down" />
            </Button>
          </Dropdown>
          <Dropdown overlay={cartStatus}>
            <Button
              className="dropdown-btn"
              style={{ flex: 1, marginRight: "5px" }}
            >
              Status
              <Icon type="down" />
            </Button>
          </Dropdown>
          <Dropdown overlay={phoneStatus}>
            <Button
              className="dropdown-btn"
              style={{ flex: 1, marginRight: "5px" }}
            >
              Phone Status
              <Icon type="down" />
            </Button>
          </Dropdown>
          <Button
            onClick={() => {
              this.setState({
                activeFulfillMentFilters: [],
                activeStatusFilters: [],
              });
              function disabledDate(current) {
                return current && current < moment().endOf("day");
              }
              this.props.fetchAllOrders(
                this.getSelectedQueryStringFilter("Clear All Filter"),
                0
              );
            }}
            disabled={
              this.state.activeFulfillMentFilters.length === 0 &&
              this.state.activeStatusFilters.length === 0
            }
            className="dropdown-btn"
            style={{ width: "140px", marginRight: "5px" }}
          >
            Clear All Filters
          </Button>
        </div>

        <div>
          <div style={{ background: "white" }}>
            {this.props.ordersPageDetails && (
              <Table
                rowClassName="cursor-pointer woo"
                columns={columns}
                dataSource={ mappedOrdersList}
                bordered
                size="small"
                rowSelection={rowSelection}
                pagination={{
                  current:
                    Number(this.props.ordersPageDetails.currentPage + 1) || 0,
                  total: this.props.ordersPageDetails.totalCount || 0,
                  pageSize: 20,
                  onChange: (pageNo) => {
                    this.setState({ pageNo: pageNo });
                    this.props.fetchAllAbandonedCartOrders(
                      this.getQueryString(),
                      pageNo - 1,
                      undefined,
                      this.state.startDate,
                      this.state.endDate
                    );
                  },
                }}
                scroll={{ x: 1500 }}
              />
            )}
          </div>
        </div>
        <Modal
          title="This will change order status to Shipped !"
          visible={this.state.fulfillmentModal}
          okText="Yes"
          onOk={this.handleUpdateFullfillmentStatusByBasicUser}
          cancelText="No"
          onCancel={() => {
            this.setState({ fulfillmentModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>TRACKING INFORMATION</div>
          <Form>
            <div className="flex flex-start">
              <Form.Item
                style={{ width: "calc(50% - 6px)", marginRight: "12px" }}
              >
                {getFieldDecorator(`trackingDetails.number`, {
                  initialValue:
                    this.state.orderToFulfill &&
                    this.state.orderToFulfill.trackingDetails
                      ? this.state.orderToFulfill.trackingDetails.number
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(<Input placeholder="Tracking Number" />)}
              </Form.Item>
              <Form.Item style={{ width: "calc(50% - 6px)" }}>
                {getFieldDecorator(`trackingDetails.carrier`, {
                  initialValue:
                    this.state.orderToFulfill &&
                    this.state.orderToFulfill.trackingDetails
                      ? this.state.orderToFulfill.trackingDetails.carrier
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(
                  <Select placeholder="Shipping Carrier">
                    {trackingDetailsCarriers.map((carrier) => (
                      <Option key={carrier.value}>{carrier.label}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </Form>
        </Modal>

        <Modal
          title="Whatsapp message"
          visible={this.state.whatsAppModal}
          okText="Yes"
          onOk={this.handelUpdateWhatsAppMessage}
          cancelText="No"
          onCancel={() => {
            this.setState({ whatsAppModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}> Whatsapp message</div>
          {/* tracking information */}
          <Form>
            <div className="flex flex-start">
              <Form.Item
                style={{ width: "calc(50% - 6px)", marginRight: "12px" }}
              >
                {getFieldDecorator(`message`, {
                  initialValue:
                    this.props.whatsAppMessageList &&
                    this.props.whatsAppMessageList.length > 0
                      ? this.props.whatsAppMessageList[0].message
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(
                  <Input.TextArea
                    style={{ maxWidth: "none", width: "478px" }}
                    placeholder="Hey there"
                  />
                )}
                <div style={{ paddingBottom: "10px" }}>
                  {" "}
                  Add dynamic field: {" {{ first_name }}"}{" "}
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Orders, Assessment } = state;
  return {
    ordersList: Orders.ordersList_Abandoned,
    ordersPageDetails: Orders.ordersPageDetails,
    activeAssessment: Assessment.activeAssessment,
    whatsAppMessageList: Orders.whatsAppMessageList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllAbandonedCartOrders: bindActionCreators(
      actions.getAbandonedCartOrders,
      dispatch
    ),
    deleteOrder: bindActionCreators(actions.deleteOrder, dispatch),
    updateOrder: bindActionCreators(actions.updateOrder, dispatch),
    createShipment: bindActionCreators(
      fulfillmentActions.createShipment,
      dispatch
    ),
    generateShipmentLabel: bindActionCreators(
      fulfillmentActions.generateShipmentLabel,
      dispatch
    ),
    markShipmentToShipped: bindActionCreators(
      fulfillmentActions.markShipmentToShipped,
      dispatch
    ),
    createShipmentInBulk: bindActionCreators(
      fulfillmentActions.createShipmentInBulk,
      dispatch
    ),
    generateLabelInBulk: bindActionCreators(
      fulfillmentActions.generateLabelInBulk,
      dispatch
    ),

    getAssessmentById: bindActionCreators(
      assessmentActions.getAssessmentById,
      dispatch
    ),
    updateWhatsAppStatus: bindActionCreators(
      actions.updateWhatsAppMsgStatusInCart,
      dispatch
    ),
    getWhatsAppMessage: bindActionCreators(
      actions.getWhatsAppMessage,
      dispatch
    ),
    updateWhatsAppMessage: bindActionCreators(
      actions.updateWhatsAppMessage,
      dispatch
    ),
    updateMultiCartWhatsAppMsgStatus:bindActionCreators(
      actions.updateMultiCartWhatsAppMsgStatus,
      dispatch
    ),
  };
}
const AbondenedCartForm = Form.create({ name: "orders_form" })(abondenedCart);

export default connect(mapStateToProps, mapDispatchToProps)(AbondenedCartForm);
