import React, { useEffect, useState } from "react";
import { Icon, Form, Input, Button, Upload, message } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as actions from "actions/banner";
import { getBase64, beforeUpload, UploadImage, getSafely } from "utils/helpers";

import "./styles.scss";
import iconHome from "static/img/icon/copy.svg";
import eye from "static/img/icon/eye.svg";

const inputs = [
    { text: "Headline Text ( use <span> for italics )", fieldValues: "headlineText" },
    { text: "Sub heading Text", fieldValues: "subHeadingText" },
    { text: "Logged in - Headline Text", fieldValues: "loggedInHeadlineText"},
    { text: "Logged in - Sub heading Text", fieldValues: "loggedInSubHeadingText"},
    { text: "Button Text", fieldValues: "buttonText" },
    { text: "Announcement Text", fieldValues: "announcementText" },
    { text: "Announcement Text Mobile", fieldValues: "announcementTextMob" },
]

const BannerPage = (props) => {
    const banner = props.banner;
    const { getFieldDecorator } = props.form;
    const [imageDes, setImageDes] = useState();
    const [imageMob, setImageMob] = useState();
    const [imageLoading, setImageLoading] = useState();
    const [imageLoadingMob, setImageLoadingMob] = useState();

    useEffect(() => {
        props.getBannerData();
    }, []);

    useEffect(() => {
        if (banner.images) {
            setImageDes(banner.images.des)
            setImageMob(banner.images.mob)
        }
    }, [banner.images]);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.setBannerData(values).then((res) => {
                    if (res) {
                        message.success(`Updated Successfully`);
                    } else {
                        message.error("Oops, Error");
                    }
                });
            }
        })
    }

    const uploadButton = (state) => {
        return (
            <div>
                <Icon type={state ? "loading" : "plus"} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
    };

    const handleImageChange = (info, imageType) => {
        if (info.file.status === "uploading") {
            if (imageType === "des") {
                setImageLoading(true);
            }
            if (imageType === "mob") {
                setImageLoadingMob(true);
            }
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) => {
                if (imageType === "des") {
                    setImageDes(imageUrl);
                    setImageLoading(false);
                }
                if (imageType === "mob") {
                    setImageMob(imageUrl);
                    setImageLoadingMob(false);
                }
            });
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        if (e && e.file && e.file.response) {
            return e.file.response;
        }
        return e.file;
    };

    return <div className="banner-page">
        <h2 className="title">
            Homepage Banner
        </h2>
        <div className="banner-page__buttons-wrap">
            <button className="button">
                <img src={iconHome} alt="icon" />
                <span>Duplicate</span>
            </button>
            <button className="button">
                <img src={eye} alt="icon" />
                <span>Views</span>
            </button>
        </div>
        <div className="banner-page__form-wrap">
            <Form onSubmit={handleSubmit}>
                <h3 className="banner-page__form-title">
                    Title
                </h3>
                {inputs.map((el, index) => <div key={index} className="banner-page__item-wrap">
                    <h4 className="banner-page__form-subtitle">
                        {el.text}
                    </h4>
                    <Form.Item>
                        {getFieldDecorator(el.fieldValues, {
                            initialValue: banner[el.fieldValues],
                            rules: [{ required: true, message: "Required" }],
                        })(<Input.TextArea
                            rows={1}
                            placeholder="Textarea placeholder"
                        />)}
                    </Form.Item>
                </div>)}
                <div className="banner-page__item-wrap banner-page__item-wrap_single-line">
                    <h4 className="banner-page__form-subtitle banner-page__form-subtitle_mb">
                        Announcement Bar Colour
                    </h4>
                    <Form.Item>
                        {getFieldDecorator('announcementBarColour', {
                            initialValue: banner.announcementBarColour,
                            rules: [{ required: true, message: "Required" }],
                        })(<Input
                            rows={1}
                            placeholder="Hexcode"
                        />)}
                    </Form.Item>
                </div>
                <div className="banner-page__item-wrap banner-page__item-wrap_single-line">
                    <h4 className="banner-page__form-subtitle banner-page__form-subtitle_mb">
                        Announcement Text Colour
                    </h4>
                    <Form.Item>
                        {getFieldDecorator('announcementTextColour', {
                            initialValue: banner.announcementTextColour,
                            rules: [{ required: true, message: "Required" }],
                        })(<Input
                            rows={1}
                            placeholder="Hexcode"
                        />)}
                    </Form.Item>
                </div>
                <div className="banner-page__item-wrap banner-page__item-wrap_single-line">
                    <h4 className="banner-page__form-subtitle banner-page__form-subtitle_mb">
                        Color of logos
                    </h4>
                    <Form.Item>
                        {getFieldDecorator('colorLogos', {
                            initialValue: banner.colorLogos,
                            rules: [{ required: true, message: "Required" }],
                        })(<Input
                            rows={1}
                            placeholder="Hexcode"
                        />)}
                    </Form.Item>
                </div>
                <h3 className="banner-page__form-title">
                    Images
                </h3>
                <div className="banner-page__images-wrap">
                    <div className="banner-page__item-wrap">
                        <h4 className="banner-page__form-subtitle">
                            Desktop
                        </h4>
                        <Form.Item>
                            {getFieldDecorator("images.des", {
                                getValueFromEvent: normFile,
                                initialValue: getSafely(
                                    () => banner.images.des,
                                    undefined
                                ),
                                rules: [
                                    {
                                        required: true,
                                        message: "Required",
                                    },
                                ],
                            })(
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) => handleImageChange(e, "des")}
                                    customRequest={UploadImage}
                                >
                                    {imageDes ? (
                                        <img
                                            src={imageDes}
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                        uploadButton(imageLoading)
                                    )}
                                </Upload>
                            )}
                        </Form.Item>
                    </div>
                    <div className="banner-page__item-wrap">
                        <h4 className="banner-page__form-subtitle">
                            Mobile
                        </h4>
                        <Form.Item>
                            {getFieldDecorator("images.mob", {
                                getValueFromEvent: normFile,
                                initialValue: getSafely(
                                    () => banner.images.mob,
                                    undefined
                                ),
                                rules: [
                                    {
                                        required: true,
                                        message: "Required",
                                    },
                                ],
                            })(
                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) => handleImageChange(e, "mob")}
                                    customRequest={UploadImage}
                                >
                                    {imageMob ? (
                                        <img
                                            src={imageMob}
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                        uploadButton(imageLoadingMob)
                                    )}
                                </Upload>
                            )}
                        </Form.Item>
                    </div>
                </div>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </Form>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    const { Banner } = state;
    return {
        banner: Banner,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBannerData: bindActionCreators(actions.setBannerData, dispatch),
        getBannerData: bindActionCreators(actions.getBannerData, dispatch),
    };
}

const BannerPageForm = Form.create({ name: "add_banner_form" })(BannerPage);

export default connect(mapStateToProps, mapDispatchToProps)(BannerPageForm);