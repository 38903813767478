import React, { Component, Fragment } from "react";
import Lightbox from "react-image-lightbox";
import "./assessmentDetailPage.scss";
import { Icon, Form } from "antd";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/admin";
import * as assessmentActions from "actions/assessment";
import AssessmentSummary from "components/assessment/AssessmentSummary";

class AssessmentDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "create",
      loading: false,
      selectedDiscountType: "",
      appliesToEntireOrder: true,
      appliesToAllUsers: true,
      setEndDate: false,
      lightBoxOpen: false,
    };
    this._getAnswer = this._getAnswer.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;
  
    if (params.id !== undefined) {
      this.props.getAssessmentById(params.id);
    } else {
      this.props.setActiveAdmin(null);
    }
  }

  _openLightBox = () => {
    const { activeAssessment } = this.props;
    if (activeAssessment && activeAssessment.selfieURL) {
      this.setState({ lightBoxOpen: true });
    }
  };

  _onCloseLightBox = () => {
    this.setState({ lightBoxOpen: false });
  };

  _getAnswer(item) {
    let answers = item.answer.filter(
      (answer) => answer && answer.text == null
    );
    if (answers?.length !== item?.answer?.length) {
      if (
         item.answer.length == 1 &&
        item.answer[0] == null &&
        item.answerText && item.answerText.length
      ) {
        return item.answerText;
      }
     else
      {
        let answersArray = item.answer
          .map((answer) => answer && answer.text).filter(function (el) {
            return el != null;
          });

          if(item.answerText && item.answerText.length) {
            answersArray.push(item.answerText);
          }
          
        return answersArray.join(", ")
      }
    } else {
      return (
        <>
          {item.answer.map((answer, i) => (
            <Fragment key={i}>
              {answer.icon && answer.icon.thumbURL ? (
                <>
                  {answer.icon.text}{" "}
                  <img
                    src={answer.icon.thumbURL}
                    alt={answer.icon.text}
                    style={{ height: "40px", display: "inline" }}
                  />
                </>
              ) : (
                ""
              )}
            </Fragment>
          ))}
        </>
      );
    }
  }

  render() {
    const { location, activeAssessment } = this.props;

    return (
      <div className="assessment-detail-page" style={{ marginLeft: "200px" }}>
        <Link
          // to={
          //   location && location.state && location.state.route
          //     ? location.state.route
          //     : "/assessment-attempt"
          // }
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          <div className="flex justify-start items-center cursor-pointer">
            <div>
              <Icon className="mr-4" type="left" />
            </div>
            <div className="text-14 pb-2">Back</div>
          </div>
        </Link>
        <h4
          style={{ color: "#373873", fontSize: "30px", marginBottom: "37px" }}
          className=""
        >
          User Assessment Summary
        </h4>
        {/* Code */}
        <div className="flex">
          <div style={{ width: "50%" }} className="assessment-card">
            <div
              style={{ paddingBottom: "10px" }}
              className="flex justify-between regular items-center"
            >
              <h4 className="text-base bold" style={{ color: "#262626" }}>
                User Summary
              </h4>
            </div>
            <div
              style={{ paddingTop: "0px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">Name:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment &&
                activeAssessment.user &&
                activeAssessment.user.firstName
                  ? activeAssessment.user.firstName
                  : ""}
                {activeAssessment &&
                activeAssessment.user &&
                activeAssessment.user.lastName
                  ? " " + activeAssessment.user.lastName
                  : ""}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">Email:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment &&
                activeAssessment.user &&
                activeAssessment.user.email
                  ? activeAssessment.user.email
                  : "-"}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">User ID:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment &&
                activeAssessment.user &&
                activeAssessment.user.id
                  ? activeAssessment.user.id
                  : "-"}
              </div>
            </div>
          </div>
          <div
            style={{ marginLeft: "20px", width: "50%" }}
            className="assessment-card"
          >
            <div
              style={{ paddingBottom: "10px" }}
              className="flex justify-between regular items-center"
            >
              <h4 className="text-base bold" style={{ color: "#262626" }}>
                Questions Summary
              </h4>
            </div>
            <div
              style={{ paddingTop: "0px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">
                Total Questions in Assessment:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment &&
                  activeAssessment.totalQuestionsInAssessment}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">
                Total Questions Answered:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment && activeAssessment.totalQuestionsAnswered}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">
                Last Answered Question:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment &&
                  activeAssessment.lastAnsweredQuestion &&
                  activeAssessment.lastAnsweredQuestion.text}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key">
                All Questions Completed By User:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value"
              >
                {activeAssessment &&
                activeAssessment.areQuestionsCompleted &&
                activeAssessment.areQuestionsCompleted
                  ? "Yes"
                  : "No"}
              </div>
            </div>
          </div>
        </div>
        {activeAssessment && (
          <AssessmentSummary
            activeAssessment={activeAssessment}
            openLightBox={this._openLightBox}
          />
        )}

        <div className="assessment-card">
          <div
            style={{ paddingBottom: "10px" }}
            className="flex justify-between regular items-center"
          >
            <h4 className="text-base bold" style={{ color: "#262626" }}>
              UTM
            </h4>
          </div>
          <div
            style={{ paddingTop: "0px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key">Browser:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value"
            >
              {activeAssessment && activeAssessment.utmBrowser
                ? activeAssessment.utmBrowser
                : ""}
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key">Device:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value"
            >
              {activeAssessment && activeAssessment.utmDevice
                ? activeAssessment.utmDevice
                : "-"}
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key">Location:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value"
            >
              {activeAssessment && activeAssessment.utmLocation
                ? activeAssessment.utmLocation
                : "-"}
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key">Website:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value"
            >
              {activeAssessment && activeAssessment.utmWebsite
                ? activeAssessment.utmWebsite
                : "-"}
            </div>
          </div>
        </div>

        <div className="assessment-card">
          <div
            style={{ paddingBottom: "10px" }}
            className="flex justify-between items-center"
          >
            <h4 className="text-base bold" style={{ color: "#262626" }}>
              Answers Summary
            </h4>
          </div>
          <div
            style={{ paddingTop: "0px" }}
            className="question-count flex flex-col"
          >
            {activeAssessment &&
              activeAssessment.questionsAnswered &&
              activeAssessment.questionsAnswered.length > 0 &&
              activeAssessment.questionsAnswered.map((item, index) => {
                return (
                  <div className="question-answer-wrapper" key={index}>
                    <div className="flex">
                      <div>{item && index + 1}.</div>
                      <div
                        style={{ paddingLeft: "3px" }}
                        className="a-question text-16 value"
                      >
                        <span
                          style={{ paddingLeft: "0px" }}
                          className="key regular text-16"
                        >
                          Question:
                        </span>{" "}
                        {item.question.text}
                      </div>
                    </div>
                    <div
                      style={{
                        paddingTop: "10px",
                        paddingLeft: "12px",
                        paddingBottom: "15px",
                      }}
                      className="a-question text-16 value"
                    >
                      <span
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                        className="key regular text-16"
                      >
                        Answer:
                      </span>
                      {item.answer && this._getAnswer(item)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="assessment-card">
          <div
            style={{ paddingBottom: "10px" }}
            className="flex justify-between items-center"
          >
            <h4 className="text-base bold" style={{ color: "#262626" }}>
              Recommended Products
            </h4>
          </div>
          <div
            style={{ paddingTop: "0px", paddingBottom: "20px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key">Order Purchased:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value"
            >
              {activeAssessment &&
              activeAssessment.purchasedOrder &&
              activeAssessment.purchasedOrder
                ? "Yes"
                : "No"}
            </div>
          </div>
          <div className="flex">
            {activeAssessment &&
              activeAssessment.recommendedProducts &&
              activeAssessment.recommendedProducts.products &&
              activeAssessment.recommendedProducts.products.map(
                (item, index) => {
                  return (
                    <div className="flex flex-col img-text" key={index}>
                      <img
                        alt={
                          item && item.product.name ? item.product.name : "-"
                        }
                        className="product-img"
                        src={
                          item &&
                          item.product &&
                          item.product.mainImage &&
                          item.product.mainImage &&
                          item.product.mainImage.withoutHover.mediaUrl
                        }
                      />
                      <div
                        style={{ marginTop: "20px", maxWidth: "70px" }}
                        className="image-text-container key"
                      >
                        {item && item.product.name ? item.product.name : "-"}
                      </div>
                    </div>
                  );
                }
              )}
            {activeAssessment &&
              activeAssessment.recommendedProducts &&
              activeAssessment.recommendedProducts.nightSerum && (
                <div style={{ paddingLeft: "30px" }}>
                  {activeAssessment.recommendedProducts.nightSerum &&
                    activeAssessment.recommendedProducts.nightSerum.mainImage &&
                    activeAssessment.recommendedProducts.nightSerum.mainImage
                      .withoutHover.mediaUrl && (
                      <img
                        src={
                          activeAssessment.recommendedProducts.nightSerum
                            .mainImage.withoutHover.mediaUrl
                        }
                        className="product-img"
                        alt=""
                      />
                    )}
                  <div
                    style={{ marginTop: "20px", maxWidth: "70px" }}
                    className="image-text-container key"
                  >
                    {activeAssessment &&
                      activeAssessment.recommendedProducts.nightSerum &&
                      activeAssessment.recommendedProducts.nightSerum.name}
                  </div>
                </div>
              )}
          </div>
        </div>
        {this.state.lightBoxOpen && (
          <Lightbox
            mainSrc={activeAssessment && activeAssessment.selfieURL}
            onCloseRequest={this._onCloseLightBox}
          />
        )}
      </div>
    );
  }
}

const AddForm = Form.create({ name: "add_ingredients_form" })(
  AssessmentDetailPage
);

function mapStateToProps(state) {
  const { Products, Customer, Currency, Admin, Assessment } = state;
  return {
    allProducts: Products.products,
    customerList: Customer.customerList,
    currencyList: Currency.currency,
    activeAdmin: Admin.activeAdmin,
    activeAssessment: Assessment.activeAssessment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createAdmin: bindActionCreators(actions.createAdmin, dispatch),
    updateAdmin: bindActionCreators(actions.updateAdmin, dispatch),
    getAdmin: bindActionCreators(actions.getAdmin, dispatch),
    getAssessmentAttemptList: bindActionCreators(
      assessmentActions.getAssessmentAttemptList,
      dispatch
    ),
    getAssessmentById: bindActionCreators(
      assessmentActions.getAssessmentById,
      dispatch
    ),
    setActiveAdmin: bindActionCreators(actions.setActiveAdmin, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);
