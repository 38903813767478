
const initialState = {
  shipmentInfo: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
      case "GET_SHIPMENT_COURIER":
          return getCourierService(state, action);
    default:
        return state;
    }    
}

function getCourierService(state,action){
    console.log("REDUCER being call", action.payload);
    return {
      ...state,
      shipmentInfo: action.payload ? action.payload  : null,
    };
}