import React, { Component, Fragment } from "react";
import "./addProducts.scss";
import {
  Icon,
  Select,
  Input,
  Button,
  Upload,
  Form,
  Divider,
  InputNumber,
  message,
  Col,
  Tooltip,
  Row,
} from "antd";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  getBase64,
  beforeUpload,
  UploadImage,
  filterObj,
  beforeUploadVideoMP4,
  beforeUploadVideoWebM,
} from "utils/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/products";
import * as ingredientActions from "actions/ingredients";
import * as tagsActions from "actions/tags";
import * as categoryActions from "actions/category";
import * as currencyActions from "actions/currency";
import * as benefitActions from "actions/benefits";
import { getActiveProductsList } from "services/products";

import CountryList from "utils/countryList";
import ProductRestriction from "components/products/ProductRestriction";
import { Link } from "react-router-dom";
import { getSafely } from "utils/helpers";
import { ReactSortable } from "react-sortablejs";
import Switch from "react-switch";
import { PRODUCT_TYPE, PERSONALIZED_CATEGORIES } from "utils/constants";
const { Option } = Select;

class AddProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [
        {
          fieldName: "Product Name **",
          key: "name",
          productType: ["product", "bundle", "merch"],
        },
        {
          fieldName: "Short Product Name **",
          key: "nameShort",
          productType: ["product", "bundle", "merch"],
        },
        {
          fieldName: "Hover heading",
          key: "hoverHeading",
          productType: ["product", "bundle"],
        },
        {
          fieldName: "Product Code",
          key: "productCode",
          productType: ["product", "bundle"],
        },
        {
          fieldName: "Internal Code",
          key: "internalCode",
          productType: ["product", "bundle"],
        },
        {
          fieldName: "Link",
          key: "link",
          productType: ["product", "bundle", "merch"],
        },
        {
          fieldName: "SSU",
          key: "ssu",
          productType: ["product", "bundle", "merch"],
        },
        {
          fieldName: "Size",
          key: "size",
          productType: ["merch"],
        },
        {
          fieldName: "Material",
          key: "material",
          productType: ["merch"],
        },
        {
          fieldName: "Includes for /gifts",
          key: "consistOfSet",
          productType: ["product", "bundle"],
        },
      ],
      linkPreOrder: [
        {
          fieldName: "Link Pre Order",
          key: "linkPreOrder",
          productType: ["product", "bundle"],
        },
      ],
      additionalSettings: [
        {
          fieldName: "Slug",
          key: "slug",
          productType: ["product", "bundle", "merch"],
        },
        {
          fieldName: "Yotpo ID",
          key: "yotpoId",
          productType: ["product", "bundle", "merch"],
        },
        {
          fieldName: "External ID",
          key: "externalId",
          productType: ["product", "bundle", "merch"],
        },
      ],
      textareaFields: [
        {
          fieldName: "Ingredients",
          key: "listIngredients",
        },
      ],
      errorMsgDescEditor: null,

      useEditorState: EditorState.createEmpty(),
      validateStatusUseEditor: "",
      errorMsgUseEditor: null,

      loading: false,

      imageLoading: false,

      videoMp4: [{ mediaUrl: "", order: 1, mediaType: "" }],
      videoMp4LoadingGallery: [],

      imageLoadingWithHover: false,
      imageUrl: "",
      imageUrlWithHover: "",

      imageLoadingSecondary: false,
      imageUrlSecondary: "",

      imageLoadingBanner: false,
      imageUrlBanner: "",

      mode: "create",
      activeProductId: null,

      // we use this array to synchronize prices in "activeProduct"
      // with prices in "currency list"
      sortedPrices: [],
      sortedLegacyPrices: [],
      ingredientList: [],
      benefitList: [],

      gallery: [{ mediaUrl: "", order: 1, mediaType: "" }],
      imageLoadingGallery: [],

      reviewImages: [{ mediaUrl: "", mediaType: "" }],
      reviewImageLoadingGallery: [],
      reviewVideos: [{ mediaUrl: "", mediaType: "" }],
      reviewVideosWebm: [{ mediaUrl: "", mediaType: "" }],
      reviewVideoLoadingGallery: [],

      videoWebm: [{ mediaUrl: "", order: 1, mediaType: "video/webm" }],
      videoLoadingGallery: [],

      isLiveState: false,
      restrictedCountry: [],
      allowedCountry: [],
      hideFromResults: false,
      isRecommended: false,
      isAvailable: false,
      isRecommendedOnSideCart: false,
      showInGiftsPage: false,
      isBestseller: false,
      products: [],
      activeProducts: [],
      productType: "product",
      isPromoApplicable: true,
      isSubDiscountApplicable: true,
      isSubscribable: true,
      showonSetPage: false,
      recommendedProductsList: [],
      recommendedProducts: [
        { id: "", productId: "" },
        { id: "", productId: "" },
        { id: "", productId: "" },
        { id: "", productId: "" },
      ],
    };
    this.addProduct = this.addProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.removeReviewVideos = this.removeReviewVideos.bind(this);
    this.removeReviewImages = this.removeReviewImages.bind(this);
    this.removeProductVideoMp4 = this.removeProductVideoMp4.bind(this);
    this.removeProductVideoWebm = this.removeProductVideoWebm.bind(this);
    this.removeProductGallery = this.removeProductGallery.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;
    this.props.fetchListProducts();

    getActiveProductsList().then((result) => {
      if (result) {
        this.setState({
          activeProducts: result?.filter((x) => x.productType !== "bundle"),
        });
      }
    });

    getActiveProductsList().then((result) => {
      if (result) {
        this.setState({
          recommendedProductsList: result?.filter((x) => {
            if (x.productType !== "Gift Set") {
              return x;
            }
          }),
        });
      }
    });

    if (params.id !== undefined) {
      this.setState({
        mode: "edit",
        activeProductId: params.id,
      });
      this.props.fetchAllCurrency().then(() => {
        this.props.getActiveProduct(params.id).then((res) => {
          if (!res) return;

          if (res.shortDescription !== undefined)
            this.setEditorInitialState(
              "shortdescEditorState",
              res.shortDescription
            );
          if (res.longDescription !== undefined)
            this.setEditorInitialState(
              "longdescEditorState",
              res.longDescription
            );
          if (res.directionsOfUse !== undefined)
            this.setEditorInitialState("useEditorState", res.directionsOfUse);

          if (res.type) {
            this.setState({
              isLiveState: res.type === "active",
            });
          }

          if (res?.productType === "bundle") {
            let products = [];
            if (res?.products.length) {
              products = res?.products.map((x) => {
                return {
                  id: x?.id?._id,
                  quantity: x?.quantity,
                  shortName: x?.shortName,
                };
              });
            }
            this.setState({
              products,
            });
          }
          if (res?.recommendedProducts?.length) {
            let recommendedProducts = [];
            recommendedProducts = res?.recommendedProducts.map((x) => {
              return {
                productId: x?.productId,
              };
            });
            this.setState({
              recommendedProducts,
            });
          }

          this.setState({
            productType: res?.productType,
          });

          if (res.mainImage) {
            this.setState({
              imageUrl: getSafely(
                () => res.mainImage.withoutHover.mediaUrl,
                ""
              ),
              imageUrlWithHover: getSafely(
                () => res.mainImage.withHover.mediaUrl,
                ""
              ),
              imageUrlSecondary: getSafely(
                () => res.secondaryImage.mediaUrl,
                ""
              ),
              imageUrlBanner: getSafely(() => res.bannerImage.mediaUrl, ""),
            });
          }
          if (res.videoWebm) {
            let arr = [...res.videoWebm];
            if (res.videoWebm.length < 3) {
              arr.push({
                mediaUrl: "",
                order: arr.length + 1,
                mediaType: "video/webm",
              });
            }
            this.setState({ videoWebm: arr });
          }
          if (res.videoMp4) {
            let arr = [...res.videoMp4];
            if (res.videoMp4.length < 3) {
              arr.push({ mediaUrl: "", order: arr.length + 1, mediaType: "" });
            }
            this.setState({ videoMp4: arr });
          }

          if (res.reviewImages) {
            let arr = [...res.reviewImages];
            if (res.reviewImages.length < 4) {
              arr.push({ mediaUrl: "", mediaType: "" });
            }
            this.setState({ reviewImages: arr });
          }
          if (res.reviewVideos) {
            let arr = [...res.reviewVideos];
            if (res.reviewVideos.length < 4) {
              arr.push({ mediaUrl: "", mediaType: "" });
            }
            this.setState({ reviewVideos: arr });
          }

          if (res.reviewVideosWebm) {
            let arr = [...res.reviewVideosWebm];
            if (res.reviewVideosWebm.length < 4) {
              arr.push({ mediaUrl: "", mediaType: "" });
            }
            this.setState({ reviewVideosWebm: arr });
          }

          if (res.gallery) {
            let arr = [...res.gallery];
            if (res.gallery.length < 5) {
              arr.push({ mediaUrl: "", order: 1, mediaType: "" });
            }
            this.setState({ gallery: arr });
          }
          if (res.prices && res.prices.length > 0) {
            let result = [];
            this.props.currencyList.forEach((currency, i) => {
              let found = res.prices.find(
                (price) => price.currency.code === currency.code
              );
              if (found) {
                result.push(found);
              } else {
                result.push({});
              }
            });
            this.setState({
              sortedPrices: result,
            });
          }
          if (res.legacyPrices && res.prices.length > 0) {
            let result = [];
            this.props.currencyList.forEach((currency, i) => {
              let found = res.legacyPrices.find(
                (price) => price.currency.code === currency.code
              );
              if (found) {
                result.push(found);
              } else {
                result.push({});
              }
            });
            this.setState({
              sortedLegacyPrices: result,
            });
          }

          if (res.restrictedCountry) {
            this.setState({
              restrictedCountry: res.restrictedCountry,
            });
          }

          if (res.allowedCountry) {
            this.setState({
              allowedCountry: res.allowedCountry,
            });
          }

          if (res.hideFromResults) {
            this.setState({
              hideFromResults: res.hideFromResults,
            });
          }
          if (res.isRecommended) {
            this.setState({
              isRecommended: res.isRecommended,
            });
          }
          if (res?.showInGiftsPage) {
            this.setState({
              showInGiftsPage: res.showInGiftsPage,
            });
          }
          if (
            res?.isPromoApplicable !== undefined ||
            res?.isPromoApplicable !== null
          ) {
            this.setState({
              isPromoApplicable: res.isPromoApplicable,
            });
          }
          if (
            res?.isSubDiscountApplicable !== undefined ||
            res?.isSubDiscountApplicable !== null
          ) {
            this.setState({
              isSubDiscountApplicable: res.isSubDiscountApplicable,
            });
          }
          if (
            res?.isSubscribable !== undefined ||
            res?.isSubscribable !== null
          ) {
            this.setState({
              isSubscribable: res.isSubscribable,
            });
          }
          if (res?.isBestseller) {
            this.setState({
              isBestseller: res.isBestseller,
            });
          }
          if (res.isAvailable) {
            this.setState({
              isAvailable: res.isAvailable,
            });
          }
          if (res?.isRecommendedOnSideCart) {
            this.setState({
              isRecommendedOnSideCart: res.isRecommendedOnSideCart,
            });
          }
        });
      });
    } else {
      this.props.setActiveProduct(null);
    }
    this.props.fetchAllIngredients().then((i) => {
      this.setState({
        ingredientList: i,
      });
    });

    this.props.fetchListBenefits().then((i) => {
      this.setState({
        benefitList: i,
      });
    });

    this.props.fetchAllTags();
    this.props.fetchAllCategory();
    this.props.fetchAllCurrency();
  }

  componentWillMount() {
    this.props.setActiveProduct(null);
  }

  setEditorInitialState = (key, value) => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          [key]: editorState,
        });
      }
    } else {
      this.setState({
        [key]: EditorState.createEmpty(),
      });
    }
  };
  isEditMode() {
    return this.state.mode === "edit";
  }
  handleImageChange = (info, imageType, index) => {
    if (info.file.status === "uploading") {
      if (imageType === "main") {
        this.setState({ imageLoading: true });
      } else if (imageType === "mainWithHover") {
        this.setState({ imageLoadingWithHover: true });
      } else if (imageType === "secondary") {
        this.setState({ imageLoadingSecondary: true });
      } else if (imageType === "banner") {
        this.setState({ imageLoadingBanner: true });
      } else if (imageType === "gallery") {
        let arr = this.state.imageLoadingGallery;
        arr[index] = true;
        this.setState({ imageLoadingGallery: arr });
      } else if (imageType === "reviewImages") {
        let arr = this.state.reviewImageLoadingGallery;
        arr[index] = true;
        this.setState({ reviewImageLoadingGallery: arr });
      } else if (imageType === "reviewVideos") {
        let arr = this.state.reviewVideoLoadingGallery;
        arr[index] = true;
        this.setState({ reviewVideoLoadingGallery: arr });
      } else if (imageType === "reviewVideosWebm") {
        let arr = this.state.reviewVideoLoadingGallery;
        arr[index] = true;
        this.setState({ reviewVideoLoadingGallery: arr });
      } else if (imageType === "video") {
        let arr = this.state.videoLoadingGallery;
        arr[index] = true;
        this.setState({ videoLoadingGallery: arr });
      } else if (imageType === "videoMp4") {
        let arr = this.state.videoMp4LoadingGallery;
        arr[index] = true;
        this.setState({ videoMp4LoadingGallery: arr });
      }
      return;
    }
    if (info.file.status === "done") {
      if (info.file.type) {
        this.props.form.setFieldsValue({
          "mainImage.mediaType": info.file.type,
        });
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        if (imageType === "main") {
          this.setState({
            imageUrl: info.file.response,
            imageLoading: false,
          });
          this.props.form.setFieldsValue({
            "mainImage.withoutHover.mediaType": info.file.type,
            "mainImage.withoutHover.mediaUrl": info.file.response,
          });
        } else if (imageType === "mainWithHover") {
          this.setState({
            imageUrlWithHover: info.file.response,
            imageLoadingWithHover: false,
          });
          this.props.form.setFieldsValue({
            "mainImage.withHover.mediaType": info.file.type,
            "mainImage.withHover.mediaUrl": info.file.response,
          });
        } else if (imageType === "secondary") {
          this.setState({
            imageUrlSecondary: info.file.response,
            imageLoadingSecondary: false,
          });
          this.props.form.setFieldsValue({
            "secondaryImage.mediaType": info.file.type,
            "secondaryImage.mediaUrl": info.file.response,
          });
        } else if (imageType === "banner") {
          this.setState({
            imageUrlBanner: info.file.response,
            imageLoadingBanner: false,
          });
          this.props.form.setFieldsValue({
            "bannerImage.mediaUrl": info.file.response,
            "bannerImage.mediaType": info.file.type,
          });
        } else if (imageType === "gallery") {
          let arr = this.state.imageLoadingGallery;
          let images = this.state.gallery;
          if (images[index].mediaUrl === "" && images.length < 5) {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
          }
          this.setState({
            gallery: images,
            imageLoadingBanner: arr,
          });
          let formArray = this.props.form.getFieldValue("gallery");
          formArray[index] = {
            mediaUrl: info.file.response,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            gallery: formArray,
          });
        } else if (imageType === "reviewImages") {
          let arr = this.state.reviewImageLoadingGallery;
          let images = this.state.reviewImages;
          if (images[index].mediaUrl === "" && images.length < 4) {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
          }
          this.setState({
            reviewImages: images,
            reviewImageLoadingGallery: arr,
          });
          let formArray = this.props.form.getFieldValue("reviewImages");
          formArray[index] = {
            mediaUrl: info.file.response,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            reviewImages: formArray,
          });
        } else if (imageType === "reviewVideos") {
          let arr = this.state.reviewVideoLoadingGallery;
          let images = this.state.reviewVideos;
          if (images[index].mediaUrl === "" && images.length < 4) {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
          }
          this.setState({
            reviewVideos: images,
            reviewVideoLoadingGallery: arr,
          });
          let formArray = this.props.form.getFieldValue("reviewVideos");
          formArray[index] = {
            mediaUrl: info.file.response,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            reviewVideos: formArray,
          });
        } else if (imageType === "reviewVideosWebm") {
          let arr = this.state.reviewVideoLoadingGallery;
          let images = this.state.reviewVideosWebm;
          if (images[index].mediaUrl === "" && images.length < 4) {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = info.file.response;
          }
          this.setState({
            reviewVideosWebm: images,
            reviewVideoLoadingGallery: arr,
          });
          let formArray = this.props.form.getFieldValue("reviewVideosWebm");
          formArray[index] = {
            mediaUrl: info.file.response,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            reviewVideosWebm: formArray,
          });
        } else if (imageType === "video") {
          let arr = this.state.videoLoadingGallery;
          let videos = [...this.state.videoWebm];
          if (videos[index].mediaUrl === "" && videos.length < 3) {
            arr[index] = false;
            videos[index].mediaUrl = info.file.response;
            videos[index].mediaType = "video/webm";
            videos.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            videos[index].mediaUrl = info.file.response;
            videos[index].mediaType = "video/webm";
          }
          this.setState({
            videoWebm: videos,
            videoLoadingBanner: arr,
          });
          let formArray = this.props.form.getFieldValue("videoWebm");
          formArray[index] = {
            mediaUrl: info.file.response,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            videoWebm: formArray,
          });
        } else if (imageType === "videoMp4") {
          let arr = this.state.videoMp4LoadingGallery;
          let videos = this.state.videoMp4;
          if (videos[index].mediaUrl === "" && videos.length < 3) {
            arr[index] = false;
            videos[index].mediaUrl = info.file.response;
            videos.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            videos[index].mediaUrl = info.file.response;
          }
          this.setState({
            videoMp4: videos,
            videoMp4LoadingBanner: arr,
          });
          let formArray = this.props.form.getFieldValue("videoMp4");
          formArray[index] = {
            mediaUrl: info.file.response,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            videoMp4: formArray,
          });
        }
      });
    }
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.file && e.file.response) {
      return e.file.response;
    }
    return e.file;
  };
  onUseEditorStateChange = (editorState) => {
    this.setState({
      useEditorState: editorState,
    });
  };
  onShortDescEditorStateChange = (editorState) => {
    this.setState({
      shortdescEditorState: editorState,
    });
  };
  onLongDescEditorStateChange = (editorState) => {
    this.setState({
      longdescEditorState: editorState,
    });
  };

  renderInputFields = (arr) => {
    const { getFieldDecorator } = this.props.form;
    return arr
      .filter((x) => x.productType.includes(this.state.productType))
      .map((obj, index) => {
        return (
          <div className="mb-8" key={index}>
            <div className="mb-4 text-black regular text-14">
              {obj.fieldName}
            </div>
            <Form.Item>
              {/* FIXME: Refactor this part*/}
              {obj.key !== "nameShort" &&
                obj.key !== "consistOfSet" &&
                getFieldDecorator(`${obj.key}`, {
                  initialValue: this.props.activeProduct
                    ? this.props.activeProduct[obj.key]
                    : undefined,
                  rules: [{ required: true, message: "Required" }],
                })(<Input />)}
              {/* For not required fields */}
              {(obj.key === "nameShort" || obj.key === "consistOfSet") &&
                getFieldDecorator(`${obj.key}`, {
                  initialValue: this.props.activeProduct
                    ? this.props.activeProduct[obj.key]
                    : undefined,
                  rules: [{ required: false, message: "Not required" }],
                })(<Input />)}
            </Form.Item>
          </div>
        );
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        values["directionsOfUse"] = draftToHtml(
          convertToRaw(this.state.useEditorState.getCurrentContent())
        );

        values["isLive"] = this.state.isLiveState;

        values.category = JSON.parse(values.category);
        // parse prices
        values.prices.forEach((price, index) => {
          values.prices[index] = {
            ...values.prices[index],
            currency: filterObj(["id", "code"], this.props.currencyList[index]),
          };
        });
        values.legacyPrices.forEach((price, index) => {
          values.legacyPrices[index] = {
            ...values.legacyPrices[index],
            currency: filterObj(["id", "code"], this.props.currencyList[index]),
          };
        });
        if (!values["secondaryImage"].mediaUrl) {
          delete values["secondaryImage"];
        }
        values["gallery"] = values.gallery.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["videoWebm"] = values.videoWebm.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["videoMp4"] = values.videoMp4.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["reviewImages"] = values.reviewImages.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["reviewVideos"] = values.reviewVideos.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["reviewVideosWebm"] = values.reviewVideosWebm.filter(
          (image) => image.mediaUrl !== "" && image.mediaUrl !== undefined
        );

        values["restrictedCountry"] = this.state.restrictedCountry;
        values["allowedCountry"] = this.state.allowedCountry;
        values["hideFromResults"] = this.state.hideFromResults;
        values["isRecommended"] = this.state.isRecommended;
        values["showInGiftsPage"] = this.state.showInGiftsPage;
        values["isBestseller"] = this.state.isBestseller;
        values["isAvailable"] = this.state.isAvailable;
        values["isRecommendedOnSideCart"] = this.state.isRecommendedOnSideCart;
        values["isPromoApplicable"] = this.state.isPromoApplicable;
        values["isSubDiscountApplicable"] = this.state.isSubDiscountApplicable;
        values["isSubscribable"] = this.state.isSubscribable;
        values["showonSetPage"] = this.state.showonSetPage;

        //if (this.state.productType != "bundle") {
        //delete values["recommendedProducts"];
        //}
        if (this.isEditMode()) {
          this.props
            .updateProduct(this.state.activeProductId, values)
            .then((res) => {
              this.setState({ loading: false });
              if (res && res.error) {
                message.error(res.error.internalMessage);
              } else if (res) {
                message.success(`Updated Successfully`);
              }
            })
            .catch((err) => {
              console.log("Error while editing", err);
            });
        } else {
          this.props
            .createProduct(values)
            .then((res) => {
              this.setState({ loading: false });
              if (res) {
                message.success(`Created Successfully`);
                this.props.history.push("/products");
              } else {
                message.error("Oops, Error");
              }
            })
            .catch((err) => {
              console.log("Error while adding", err);
            });
        }
      } else {
        console.log("err------", err);
        message.error(`Please enter ${Object.keys(err)[0]}`);
      }
    });
  };
  uploadButton = (state) => {
    return (
      <div>
        <Icon type={state ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
  };

  handleisLiveChange = () => {
    this.setState({
      isLiveState: !this.state.isLiveState,
    });
  };

  onChangeRestrictedCountry = (countries = []) => {
    this.setState({
      restrictedCountry: countries,
    });
  };

  onChangeAllowedCountry = (countries = []) => {
    this.setState({
      allowedCountry: countries,
    });
  };

  onToggleHideFromResults = () => {
    this.setState({
      hideFromResults: !this.state.hideFromResults,
    });
  };

  onToggleisRecommended = () => {
    this.setState({
      isRecommended: !this.state.isRecommended,
    });
  };

  onToggleisRecommendedOnSideCart = () => {
    this.setState({
      isRecommendedOnSideCart: !this.state.isRecommendedOnSideCart,
    });
  };

  onToggleisAvailable = () => {
    this.setState({
      isAvailable: !this.state.isAvailable,
    });
  };

  onToggleisGiftSets = () => {
    this.setState({
      showInGiftsPage: !this.state.showInGiftsPage,
    });
  };

  onToggleisBestseller = () => {
    this.setState({
      isBestseller: !this.state.isBestseller,
    });
  };

  onToggleSubDiscount = () => {
    this.setState({
      isSubDiscountApplicable: !this.state.isSubDiscountApplicable,
    });
  };

  onToggleSubscribable = () => {
    this.setState({
      isSubscribable: !this.state.isSubscribable,
    });
  };

  onToggleSetPage = () => {
    this.setState({
      showonSetPage: !this.state.showonSetPage,
    });
  };

  onTogglePromoDiscount = () => {
    this.setState({
      isPromoApplicable: !this.state.isPromoApplicable,
    });
  };

  getAllProductForAdd() {
    const { productList, form } = this.props;
    const data = form.getFieldValue("products");
    if (productList && data && data.some((x) => x !== undefined)) {
      return productList.reduce((acc, x) => {
        const item = data.find((d) => x.id === d.id);
        if (
          !item &&
          x.type === "active" &&
          !x.name.toLowerCase().includes("day and night set")
        ) {
          acc.push(x);
        }
        return acc;
      }, []);
    } else if (productList && !data) {
      return productList.reduce((acc, x) => {
        if (
          x.type === "active" &&
          !x.name.toLowerCase().includes("day and night set")
        ) {
          acc.push(x);
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  }

  addProduct() {
    let { products } = this.state;
    products.push({
      id: "",
      shortName: "",
      quantity: null,
    });
    this.setState({
      products: [...products],
    });
  }

  removeProduct(index) {
    const { form } = this.props;
    const productsValues = [...form.getFieldValue("products")];
    let products = [...this.state.products];
    products.splice(index, 1);
    productsValues.splice(index, 1);
    form.setFieldsValue({
      products: productsValues,
    });
    this.setState({
      products: [...products],
    });
  }

  removeReviewVideos(index) {
    const { form } = this.props;
    const reviewVideoValues = [...form.getFieldValue("reviewVideos")];
    let reviewVideos = [...this.state.reviewVideos];
    reviewVideos.splice(index, 1);
    reviewVideoValues.splice(index, 1);
    form.setFieldsValue({
      reviewVideos: reviewVideoValues,
    });
    this.setState({
      reviewVideos: [...reviewVideos],
    });
  }

  removeReviewVideosWebM(index) {
    const { form } = this.props;
    const reviewVideoValues = [...form.getFieldValue("reviewVideosWebm")];
    let reviewVideosWebm = [...this.state.reviewVideosWebm];
    reviewVideosWebm.splice(index, 1);
    reviewVideoValues.splice(index, 1);
    form.setFieldsValue({
      reviewVideosWebm: reviewVideoValues,
    });
    this.setState({
      reviewVideosWebm: [...reviewVideosWebm],
    });
  }

  removeReviewImages(index) {
    const { form } = this.props;
    const reviewImagesValues = [...form.getFieldValue("reviewImages")];
    let reviewImages = [...this.state.reviewImages];
    reviewImages.splice(index, 1);
    reviewImagesValues.splice(index, 1);
    form.setFieldsValue({
      reviewImages: reviewImagesValues,
    });
    this.setState({
      reviewImages: [...reviewImages],
    });
  }

  removeProductVideoMp4(index) {
    const { form } = this.props;
    const videoMp4Values = [...form.getFieldValue("videoMp4")];
    let videoMp4 = [...this.state.videoMp4];
    videoMp4.splice(index, 1);
    videoMp4Values.splice(index, 1);
    form.setFieldsValue({
      videoMp4: videoMp4Values,
    });
    this.setState({
      videoMp4: [...videoMp4],
    });
  }

  removeProductVideoWebm(index) {
    const { form } = this.props;
    const videoWebmValues = [...form.getFieldValue("videoWebm")];
    let videoWebm = [...this.state.videoWebm];
    videoWebm.splice(index, 1);
    videoWebmValues.splice(index, 1);
    form.setFieldsValue({
      videoWebm: videoWebmValues,
    });
    this.setState({
      videoWebm: [...videoWebm],
    });
  }

  removeProductGallery(index) {
    const { form } = this.props;
    const galleryValues = [...form.getFieldValue("gallery")];
    let gallery = [...this.state.gallery];
    gallery.splice(index, 1);
    galleryValues.splice(index, 1);
    form.setFieldsValue({
      gallery: galleryValues,
    });
    this.setState({
      gallery: [...gallery],
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { imageUrl, imageUrlWithHover, products, activeProducts } =
      this.state;
    const { activeProduct } = this.props;
    const { sortedPrices, sortedLegacyPrices } = this.state;
    return (
      <div className="products-page" style={{ marginLeft: "200px" }}>
        <div className="flex items-center justify-between w-full">
          <Link to="/products">
            <div className="flex items-center justify-start">
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="pb-2 text-14">Back</div>
            </div>
          </Link>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Save
          </Button>
        </div>

        <div className="mb-12 theme-color semibold text-30">
          {this.isEditMode() && this.props.activeProduct
            ? this.props.activeProduct.name
            : ""}
        </div>

        <Form onSubmit={this.handleSubmit}>
          <div className="flex">
            <div className="contentLeft">
              <div className="desc">
                <div className="flex justify-between">
                  <div className="text-black semibold text-18">Text</div>
                  <Form.Item>
                    {getFieldDecorator(`isLive`, {
                      initialValue:
                        activeProduct && activeProduct.type
                          ? activeProduct.type === "active"
                          : undefined,
                      rules: [{ required: false, message: "Required" }],
                    })(
                      <>
                        <div className="text-black semibold text-18">
                          Is Live
                        </div>
                        <Switch
                          onChange={this.handleisLiveChange}
                          checked={this.state.isLiveState}
                        />
                      </>
                    )}
                  </Form.Item>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  ** there is you can set two varints word, <br />
                  for example write *{String.fromCharCode("123")}word_GB,
                  word_US{String.fromCharCode("125")}* in a text
                </div>

                {this.renderInputFields(this.state.inputFields)}
                {!this.state.isAvailable &&
                  this.renderInputFields(this.state.linkPreOrder)}

                {this.state.productType !== "merch" && (
                  <div className="mb-14">
                    <div className="mb-4 text-black regular text-14">
                      Benefits (The page /result contains it)
                    </div>
                    <Form.Item
                      label=""
                      validateStatus={this.state.validateStatusUseEditor}
                      help={this.state.errorMsgUseEditor || ""}
                    >
                      <Editor
                        editorState={this.state.useEditorState}
                        editorToolbar="editorToolbar"
                        editorWrapper="editorWrapper"
                        editor="editor"
                        onEditorStateChange={this.onUseEditorStateChange}
                        wrapperStyle={{
                          border: "1px solid #F1F1F1",
                        }}
                      />
                    </Form.Item>
                  </div>
                )}
                {/* FIXME: remove Result Description Mobile 'A' / Result Description Mobile 'B'
                  after A/B test
                */}
                {this.state.productType !== "merch" && (
                  <>
                    <div className="">
                      <div className="mb-4 text-black regular text-14">
                        Result Description Mobile 'A'
                      </div>
                      <Form.Item>
                        {getFieldDecorator("resultDescriptionMobileA", {
                          initialValue:
                            this.props.activeProduct?.resultDescriptionMobileA,
                          rules: [{ required: false, message: "Required" }],
                        })(<Input.TextArea rows={5} />)}
                      </Form.Item>
                    </div>
                    <div className="">
                      <div className="mb-4 text-black regular text-14">
                        Result Description Mobile 'B'
                      </div>
                      <Form.Item>
                        {getFieldDecorator("resultDescriptionMobileB", {
                          initialValue:
                            this.props.activeProduct?.resultDescriptionMobileB,
                          rules: [{ required: false, message: "Required" }],
                        })(<Input.TextArea rows={5} />)}
                      </Form.Item>
                    </div>
                  </>
                )}
                <div className="">
                  <div className="mb-4 text-black regular text-14">
                    {this.state.productType === "merch"
                      ? "Description **"
                      : "Short Description **"}
                  </div>
                  <Form.Item>
                    {getFieldDecorator("shortDescription", {
                      initialValue: this.props.activeProduct?.shortDescription,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea rows={5} />)}
                  </Form.Item>
                </div>
                <div className="">
                  <div className="mb-4 text-black regular text-14">
                    Long Description **
                  </div>
                  <Form.Item>
                    {getFieldDecorator("longDescription", {
                      initialValue: this.props.activeProduct?.longDescription,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea rows={5} />)}
                  </Form.Item>
                </div>
                <div className="">
                  <div className="mb-4 text-black regular text-14">
                    {this.state.productType === "merch"
                      ? "Care Instructions"
                      : "Unique Description **"}
                  </div>
                  <Form.Item>
                    {getFieldDecorator("uniqueDescription", {
                      initialValue: this.props.activeProduct?.uniqueDescription,
                      rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea rows={5} />)}
                  </Form.Item>
                </div>
                {this.state.productType !== "merch" && (
                  <div className="">
                    <div className="mb-4 text-black regular text-14">
                      How To Use ** (use \n to add new line FE)
                    </div>
                    <Form.Item>
                      {getFieldDecorator("howToUse", {
                        initialValue: activeProduct?.howToUse,
                        rules: [{ required: true, message: "Required" }],
                      })(<Input.TextArea rows={5} />)}
                    </Form.Item>
                  </div>
                )}
              </div>
              <div className="images">
                <div className="flex justify-between mb-12">
                  <div className="text-black semibold text-18">Images</div>
                  {/* <p className="regular text-14 text-link">Add image from URL</p> */}
                </div>
                <div className="flex flex-col">
                  {/* <img /> */}
                  <div className="flex justify-between">
                    <Form.Item
                      label="Primary Image (main)"
                      className="relative"
                    >
                      {getFieldDecorator("mainImage.withoutHover.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue: getSafely(
                          () => activeProduct.mainImage.withoutHover.mediaUrl,
                          undefined
                        ),
                        rules: [
                          {
                            required: true,
                            message: "Required",
                          },
                        ],
                      })(
                        <>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) => this.handleImageChange(e, "main")}
                            customRequest={UploadImage}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              this.uploadButton(this.state.imageLoading)
                            )}
                          </Upload>
                          {imageUrl?.length !== 0 && (
                            <Button
                              className="remove-media-button-main"
                              size="small"
                              shape="circle"
                              icon="close"
                              onClick={() => {
                                this.setState({
                                  imageUrl: "",
                                });
                                this.props.form.setFieldsValue({
                                  "mainImage.withoutHover.mediaUrl": "",
                                });
                              }}
                            />
                          )}
                        </>
                      )}
                    </Form.Item>
                    <Form.Item
                      label="Primary Image (hover)"
                      className="relative"
                    >
                      {getFieldDecorator("mainImage.withHover.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue: getSafely(
                          () => activeProduct.mainImage.withHover.mediaUrl,
                          undefined
                        ),
                        rules: [
                          {
                            required: true,
                            message: "Required",
                          },
                        ],
                      })(
                        <>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) =>
                              this.handleImageChange(e, "mainWithHover")
                            }
                            customRequest={UploadImage}
                          >
                            {imageUrlWithHover ? (
                              <img
                                src={imageUrlWithHover}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              this.uploadButton(
                                this.state.imageLoadingWithHover
                              )
                            )}
                          </Upload>
                          {imageUrlWithHover?.length !== 0 && (
                            <Button
                              className="remove-media-button-main"
                              size="small"
                              shape="circle"
                              icon="close"
                              onClick={() => {
                                this.setState({
                                  imageUrlWithHover: "",
                                });
                                this.props.form.setFieldsValue({
                                  "mainImage.withHover.mediaUrl": "",
                                });
                              }}
                            />
                          )}
                        </>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("mainImage.withoutHover.mediaType", {
                        initialValue:
                          activeProduct &&
                          activeProduct.mainImage &&
                          activeProduct.mainImage.withoutHover
                            ? activeProduct.mainImage.withoutHover.mediaType
                            : "",
                      })(<span></span>)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("mainImage.withHover.mediaType", {
                        initialValue:
                          activeProduct && activeProduct.mainImage
                            ? activeProduct.mainImage.withHover.mediaType
                            : "",
                      })(<span></span>)}
                    </Form.Item>
                  </div>
                  <div className="flex justify-between">
                    <Form.Item
                      label="Secondary Image (results page)"
                      className="relative"
                    >
                      {getFieldDecorator("secondaryImage.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue: getSafely(
                          () => activeProduct.secondaryImage.mediaUrl,
                          undefined
                        ),
                        rules: [
                          {
                            required: false,
                            message: "Required",
                          },
                        ],
                      })(
                        <>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) =>
                              this.handleImageChange(e, "secondary")
                            }
                            customRequest={UploadImage}
                          >
                            {this.state.imageUrlSecondary ? (
                              <img
                                src={this.state.imageUrlSecondary}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              this.uploadButton(
                                this.state.imageLoadingSecondary
                              )
                            )}
                          </Upload>
                          {this?.state?.imageUrlSecondary?.length !== 0 && (
                            <Button
                              className="remove-media-button-main"
                              size="small"
                              shape="circle"
                              icon="close"
                              onClick={() => {
                                this.setState({
                                  imageUrlSecondary: "",
                                });
                                this.props.form.setFieldsValue({
                                  "secondaryImage.mediaUrl": "",
                                });
                              }}
                            />
                          )}
                        </>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("secondaryImage.mediaType", {
                        initialValue: getSafely(
                          () => activeProduct.secondaryImage.mediaType,
                          ""
                        ),
                      })(<span></span>)}
                    </Form.Item>
                  </div>
                  <div className="flex justify-between">
                    <Form.Item label="Banner Image" className="relative">
                      {getFieldDecorator("bannerImage.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue: getSafely(
                          () => activeProduct.bannerImage.mediaUrl,
                          undefined
                        ),
                        rules: [
                          {
                            required: true,
                            message: "Required",
                          },
                        ],
                      })(
                        <>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) =>
                              this.handleImageChange(e, "banner")
                            }
                            customRequest={UploadImage}
                          >
                            {this.state.imageUrlBanner ? (
                              <img
                                src={this.state.imageUrlBanner}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              this.uploadButton(this.state.imageLoadingBanner)
                            )}
                          </Upload>
                          {this?.state?.imageUrlBanner?.length !== 0 && (
                            <Button
                              className="remove-media-button"
                              size="small"
                              shape="circle"
                              icon="close"
                              onClick={() => {
                                this.setState({
                                  imageUrlBanner: "",
                                });
                                this.props.form.setFieldsValue({
                                  "bannerImage.mediaUrl": "",
                                });
                              }}
                            />
                          )}
                        </>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("bannerImage.mediaType", {
                        initialValue: getSafely(
                          () => activeProduct.bannerImage.mediaType,
                          ""
                        ),
                      })(<span></span>)}
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label="Gallery Images"
                      className="mb-0"
                    ></Form.Item>
                    <ReactSortable
                      list={this.state.gallery}
                      setList={(val) => this.setState({ gallery: val })}
                      className="flex flex-wrap"
                    >
                      {this.state.gallery.map((image, index) => {
                        return (
                          <div key={index}>
                            <Form.Item className="relative mb-0">
                              {getFieldDecorator(`gallery[${index}].mediaUrl`, {
                                getValueFromEvent: this.normFile,
                                initialValue: getSafely(
                                  () => image.mediaUrl,
                                  undefined
                                ),
                                rules: [
                                  {
                                    required: false,
                                    message: "Required",
                                  },
                                ],
                              })(
                                <>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) =>
                                      this.handleImageChange(
                                        e,
                                        "gallery",
                                        index
                                      )
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {this.state.gallery &&
                                    this.state.gallery[index] &&
                                    this.state.gallery[index].mediaUrl !==
                                      "" ? (
                                      <img
                                        src={this.state.gallery[index].mediaUrl}
                                        alt="avatar"
                                        style={{
                                          maxHeight: "88px",
                                          maxWidth: "88px",
                                          margin: "auto",
                                        }}
                                      />
                                    ) : (
                                      this.uploadButton(
                                        this.state.imageLoadingGallery[index]
                                      )
                                    )}
                                  </Upload>
                                  {image?.mediaUrl?.length !== 0 && (
                                    <Button
                                      className="remove-media-button"
                                      size="small"
                                      shape="circle"
                                      icon="close"
                                      onClick={() =>
                                        this.removeProductGallery(index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `gallery[${index}].mediaType`,
                                {
                                  initialValue: getSafely(
                                    () => image.mediaType,
                                    undefined
                                  ),
                                }
                              )(<span></span>)}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(`gallery[${index}].order`, {
                                initialValue: getSafely(
                                  () => index + 1,
                                  undefined
                                ),
                              })(<span></span>)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                  </div>
                  <div>
                    <Form.Item
                      label="Product Video webm format (size < 8 MB)"
                      className="mb-0"
                    ></Form.Item>
                    <ReactSortable
                      list={this.state.videoWebm}
                      setList={(val) => {
                        this.setState({ videoWebm: val });
                      }}
                      className="flex flex-wrap"
                    >
                      {this.state.videoWebm.map((video, index) => {
                        return (
                          <div key={index}>
                            <Form.Item className="relative mb-0">
                              {getFieldDecorator(
                                `videoWebm[${index}].mediaUrl`,
                                {
                                  getValueFromEvent: this.normFile,
                                  initialValue: getSafely(
                                    () => video.mediaUrl,
                                    undefined
                                  ),
                                  rules: [
                                    {
                                      required: false,
                                      message: "Required",
                                    },
                                  ],
                                }
                              )(
                                <>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadVideoWebM}
                                    onChange={(e) =>
                                      this.handleImageChange(e, "video", index)
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {video.mediaUrl !== "" ? (
                                      <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        style={{ width: "100px" }}
                                      >
                                        <source
                                          src={video.mediaUrl}
                                          type="video/webm"
                                        />
                                      </video>
                                    ) : (
                                      this.uploadButton(
                                        this.state.videoLoadingGallery[index]
                                      )
                                    )}
                                  </Upload>

                                  {video?.mediaUrl?.length !== 0 && (
                                    <Button
                                      className="remove-media-button"
                                      size="small"
                                      shape="circle"
                                      icon="close"
                                      onClick={() =>
                                        this.removeProductVideoWebm(index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Form.Item>
                            <Form.Item style={{ display: "none" }}>
                              {getFieldDecorator(
                                `videoWebm[${index}].mediaType`,
                                {
                                  initialValue: "video/webm",
                                }
                              )(<Input />)}
                            </Form.Item>
                            <Form.Item style={{ display: "none" }}>
                              {getFieldDecorator(`videoWebm[${index}].order`, {
                                initialValue: getSafely(
                                  () => index + 1,
                                  undefined
                                ),
                              })(<Input />)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                  </div>
                  <div>
                    <Form.Item
                      label="Product Video mp4 format (size < 8 MB)"
                      className="mb-0"
                    ></Form.Item>
                    <ReactSortable
                      list={this.state.videoMp4}
                      setList={(val) => this.setState({ videoMp4: val })}
                      className="flex flex-wrap"
                    >
                      {this.state.videoMp4.map((video, index) => {
                        return (
                          <div key={index}>
                            <Form.Item className="relative mb-0">
                              {getFieldDecorator(`videoMp4[${index}]mediaUrl`, {
                                getValueFromEvent: this.normFile,
                                initialValue: getSafely(
                                  () => video.mediaUrl,
                                  undefined
                                ),
                                rules: [
                                  {
                                    required: false,
                                    message: "Required",
                                  },
                                ],
                              })(
                                <>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadVideoMP4}
                                    onChange={(e) =>
                                      this.handleImageChange(
                                        e,
                                        "videoMp4",
                                        index
                                      )
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {this.state.videoMp4 &&
                                    this.state.videoMp4[index] &&
                                    this.state.videoMp4[index].mediaUrl !==
                                      "" ? (
                                      <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        style={{ width: "100px" }}
                                      >
                                        <source
                                          src={
                                            this.state.videoMp4[index].mediaUrl
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : (
                                      this.uploadButton(
                                        this.state.videoMp4LoadingGallery[index]
                                      )
                                    )}
                                  </Upload>
                                  {video?.mediaUrl?.length !== 0 && (
                                    <Button
                                      className="remove-media-button"
                                      size="small"
                                      shape="circle"
                                      icon="close"
                                      onClick={() =>
                                        this.removeProductVideoMp4(index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `videoMp4[${index}].mediaType`,
                                {
                                  initialValue: getSafely(
                                    () => video.mediaType,
                                    undefined
                                  ),
                                }
                              )(<span></span>)}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(`videoMp4[${index}].order`, {
                                initialValue: getSafely(
                                  () => index + 1,
                                  undefined
                                ),
                              })(<span></span>)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                  </div>

                  <div>
                    <Form.Item
                      label="Review section"
                      className="mb-0"
                    ></Form.Item>
                    <ReactSortable
                      list={this.state.reviewVideos}
                      setList={(val) => this.setState({ reviewVideos: val })}
                      className="flex flex-wrap"
                    >
                      {this.state.reviewVideos.map((video, index) => {
                        return (
                          <div key={index}>
                            <Form.Item
                              className="relative mb-0"
                              label="Upload Video"
                            >
                              {getFieldDecorator(
                                `reviewVideos[${index}]mediaUrl`,
                                {
                                  getValueFromEvent: this.normFile,
                                  initialValue: getSafely(
                                    () => video.mediaUrl,
                                    undefined
                                  ),
                                  rules: [
                                    {
                                      required: false,
                                      message: "Required",
                                    },
                                  ],
                                }
                              )(
                                <>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadVideoMP4}
                                    onChange={(e) =>
                                      this.handleImageChange(
                                        e,
                                        "reviewVideos",
                                        index
                                      )
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {video.mediaUrl !== "" ? (
                                      <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        style={{ width: "100px" }}
                                      >
                                        <source
                                          src={video.mediaUrl}
                                          type="video/webm"
                                        />
                                      </video>
                                    ) : (
                                      this.uploadButton(
                                        this.state.reviewVideoLoadingGallery[
                                          index
                                        ]
                                      )
                                    )}
                                  </Upload>
                                  {video?.mediaUrl?.length !== 0 && (
                                    <Button
                                      className="remove-media-button"
                                      size="small"
                                      shape="circle"
                                      icon="close"
                                      onClick={() =>
                                        this.removeReviewVideos(index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `reviewVideos[${index}].mediaType`,
                                {
                                  initialValue: getSafely(
                                    () => video.mediaType,
                                    undefined
                                  ),
                                }
                              )(<span></span>)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                  </div>
                  <div>
                    {/* <Form.Item
                      label="Review section"
                      className="mb-0"
                    ></Form.Item> */}
                    <ReactSortable
                      list={this.state.reviewVideosWebm}
                      setList={(val) =>
                        this.setState({ reviewVideosWebm: val })
                      }
                      className="flex flex-wrap"
                    >
                      {this.state.reviewVideosWebm.map((video, index) => {
                        return (
                          <div key={index}>
                            <Form.Item
                              className="relative mb-0"
                              label="Video Webm"
                            >
                              {getFieldDecorator(
                                `reviewVideosWebm[${index}]mediaUrl`,
                                {
                                  getValueFromEvent: this.normFile,
                                  initialValue: getSafely(
                                    () => video.mediaUrl,
                                    undefined
                                  ),
                                  rules: [
                                    {
                                      required: false,
                                      message: "Required",
                                    },
                                  ],
                                }
                              )(
                                <>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUploadVideoWebM}
                                    onChange={(e) =>
                                      this.handleImageChange(
                                        e,
                                        "reviewVideosWebm",
                                        index
                                      )
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {video.mediaUrl !== "" ? (
                                      <video
                                        autoPlay
                                        loop
                                        muted
                                        playsInline
                                        style={{ width: "100px" }}
                                      >
                                        <source
                                          src={video.mediaUrl}
                                          type="video/webm"
                                        />
                                      </video>
                                    ) : (
                                      this.uploadButton(
                                        this.state.reviewVideoLoadingGallery[
                                          index
                                        ]
                                      )
                                    )}
                                  </Upload>
                                  {video?.mediaUrl?.length !== 0 && (
                                    <Button
                                      className="remove-media-button"
                                      size="small"
                                      shape="circle"
                                      icon="close"
                                      onClick={() =>
                                        this.removeReviewVideos(index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `reviewVideosWebm[${index}].mediaType`,
                                {
                                  initialValue: getSafely(
                                    () => video.mediaType,
                                    undefined
                                  ),
                                }
                              )(<span></span>)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                    <ReactSortable
                      list={this.state.reviewImages}
                      setList={(val) => this.setState({ reviewImages: val })}
                      className="flex flex-wrap"
                    >
                      {this.state.reviewImages.map((image, index) => {
                        return (
                          <div key={index}>
                            <Form.Item
                              className="relative mb-0"
                              label="Upload Photo"
                            >
                              {getFieldDecorator(
                                `reviewImages[${index}].mediaUrl`,
                                {
                                  getValueFromEvent: this.normFile,
                                  initialValue: getSafely(
                                    () => image.mediaUrl,
                                    undefined
                                  ),
                                  rules: [
                                    {
                                      required: false,
                                      message: "Required",
                                    },
                                  ],
                                }
                              )(
                                <>
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={(e) =>
                                      this.handleImageChange(
                                        e,
                                        "reviewImages",
                                        index
                                      )
                                    }
                                    customRequest={UploadImage}
                                  >
                                    {image.mediaUrl !== "" ? (
                                      <img
                                        src={image.mediaUrl}
                                        alt="avatar"
                                        style={{
                                          maxHeight: "88px",
                                          maxWidth: "88px",
                                          margin: "auto",
                                        }}
                                      />
                                    ) : (
                                      this.uploadButton(
                                        this.state.reviewImageLoadingGallery[
                                          index
                                        ]
                                      )
                                    )}
                                  </Upload>
                                  {image?.mediaType?.length !== 0 && (
                                    <Button
                                      className="remove-media-button"
                                      size="small"
                                      shape="circle"
                                      icon="close"
                                      onClick={() =>
                                        this.removeReviewImages(index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `reviewImages[${index}].mediaType`,
                                {
                                  initialValue: getSafely(
                                    () => image.mediaType,
                                    undefined
                                  ),
                                }
                              )(<span></span>)}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </ReactSortable>
                  </div>
                </div>
              </div>
              <div className="pricing-products-container">
                <div className="">
                  <div className="text-base text-black bold">Pricing</div>
                  {/* <div className="text-black regular text-14">Price</div> */}
                </div>
                <div className="flex flex-col pricing-products">
                  {this.props.currencyList &&
                    this.props.currencyList.length > 0 &&
                    this.props.currencyList.map((currency, index) => {
                      return (
                        <>
                          <div
                            className="flex tab-pricing"
                            style={{ padding: "5px" }}
                          >
                            <Form.Item
                              style={{ marginRight: "6px" }}
                              extra={`Price in ${
                                currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                              }`}
                            >
                              {getFieldDecorator(`prices[${index}].price`, {
                                initialValue:
                                  sortedPrices.length > 0 && sortedPrices[index]
                                    ? sortedPrices[index].price
                                    : undefined,
                                rules: [
                                  { required: false, message: "Required" },
                                ],
                              })(
                                <InputNumber
                                  formatter={(value) =>
                                    ` ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                            </Form.Item>
                            <Form.Item
                              style={{ marginRight: "10px" }}
                              extra={`Strike Price in ${
                                currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                              }`}
                            >
                              {getFieldDecorator(
                                `prices[${index}].strikePrice`,
                                {
                                  initialValue:
                                    sortedPrices.length > 0 &&
                                    sortedPrices[index]
                                      ? sortedPrices[index].strikePrice
                                      : undefined,
                                  rules: [
                                    { required: false, message: "Required" },
                                  ],
                                }
                              )(
                                <InputNumber
                                  formatter={(value) =>
                                    ` ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                            </Form.Item>
                            <Form.Item
                              extra={`Tax in ${
                                currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                              }`}
                            >
                              {getFieldDecorator(`prices[${index}].tax`, {
                                initialValue:
                                  sortedPrices.length > 0 && sortedPrices[index]
                                    ? sortedPrices[index].tax
                                    : undefined,
                                rules: [
                                  { required: false, message: "Required" },
                                ],
                              })(
                                <InputNumber
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                              {/*   `${currency  && currency.code && currency.code.toUpperCase()}  */}
                            </Form.Item>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="pricing-products-container">
                <div className="">
                  <div className="text-base text-black bold">
                    Legacy Pricing
                  </div>
                  {/* <div className="text-black regular text-14">Price</div> */}
                </div>
                <div className="flex flex-col pricing-products">
                  {this.props.currencyList &&
                    this.props.currencyList.length > 0 &&
                    this.props.currencyList.map((currency, index) => {
                      return (
                        <>
                          <div
                            className="flex tab-pricing"
                            style={{ padding: "5px" }}
                          >
                            <Form.Item
                              style={{ marginRight: "6px" }}
                              extra={`Price in ${
                                currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                              }`}
                            >
                              {getFieldDecorator(
                                `legacyPrices[${index}].price`,
                                {
                                  initialValue:
                                    sortedLegacyPrices.length > 0 &&
                                    sortedLegacyPrices[index]
                                      ? sortedLegacyPrices[index].price
                                      : undefined,
                                  rules: [
                                    { required: false, message: "Required" },
                                  ],
                                }
                              )(
                                <InputNumber
                                  formatter={(value) =>
                                    ` ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                            </Form.Item>
                            <Form.Item
                              style={{ marginRight: "10px" }}
                              extra={`Strike Price in ${
                                currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                              }`}
                            >
                              {getFieldDecorator(
                                `legacyPrices[${index}].strikePrice`,
                                {
                                  initialValue:
                                    sortedLegacyPrices.length > 0 &&
                                    sortedLegacyPrices[index]
                                      ? sortedLegacyPrices[index].strikePrice
                                      : undefined,
                                  default: 0,
                                  rules: [
                                    { required: false, message: "Required" },
                                  ],
                                }
                              )(
                                <InputNumber
                                  formatter={(value) =>
                                    ` ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                            </Form.Item>
                            <Form.Item
                              extra={`Tax in ${
                                currency &&
                                currency.code &&
                                currency.code.toUpperCase()
                              }`}
                            >
                              {getFieldDecorator(`legacyPrices[${index}].tax`, {
                                initialValue:
                                  sortedLegacyPrices.length > 0 &&
                                  sortedLegacyPrices[index]
                                    ? sortedLegacyPrices[index].tax
                                    : undefined,
                                rules: [
                                  { required: false, message: "Required" },
                                ],
                              })(
                                <InputNumber
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  min={0.0}
                                  max={10000000}
                                />
                              )}
                              {/*   `${currency  && currency.code && currency.code.toUpperCase()}  */}
                            </Form.Item>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="shipping-products-container mb-30">
                <div className="shipping-products">
                  <div
                    style={{
                      borderBottom: "1px solid #f4f4f7",
                      paddingBottom: "15px",
                    }}
                    className="content-container-i"
                  >
                    <div className="text-base text-black bold">Shipping</div>
                    <div className="secon-text regular text-14">WEIGHT</div>
                    <div className="desc-shipping regular text-14">
                      Used to Calculate shipping rates at checkout and label
                      prices during fulfillment.
                    </div>
                    <div className="secon-text desc-shipping regular text-14">
                      Weight (gm)
                    </div>
                    <Form.Item>
                      {getFieldDecorator(`shippingMetadata.actualWeight`, {
                        initialValue:
                          activeProduct && activeProduct.shippingMetadata
                            ? activeProduct.shippingMetadata.actualWeight
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Required",
                          },
                        ],
                      })(
                        <Input
                          style={{ minWidth: "275px", marginTop: "10px" }}
                          min={0.1}
                        />
                      )}
                    </Form.Item>
                    <div className="secon-text desc-shipping regular text-14">
                      Size (ml)
                    </div>
                    <Form.Item>
                      {getFieldDecorator(`shippingMetadata.weight`, {
                        initialValue:
                          activeProduct && activeProduct.shippingMetadata
                            ? activeProduct.shippingMetadata.weight
                            : undefined,
                        rules: [{ required: true, message: "Required" }],
                      })(
                        <Input
                          style={{ minWidth: "275px", marginTop: "10px" }}
                          min={0.1}
                        />
                      )}
                    </Form.Item>
                    {/* Weight in ounces */}
                    <div className="secon-text desc-shipping regular text-14">
                      Size (ounce / oz) for US
                    </div>
                    <Form.Item>
                      {getFieldDecorator("shippingMetadata.weightOz", {
                        initialValue:
                          activeProduct && activeProduct.shippingMetadata
                            ? activeProduct.shippingMetadata.weightOz
                            : undefined,
                        rules: [{ required: true, message: "Required" }],
                      })(
                        <Input
                          style={{ minWidth: "275px", marginTop: "10px" }}
                          min={0.1}
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div className="secon-text regular text-14">
                    CUSTOMS INFORMATION
                  </div>
                  <div className="desc-shipping regular text-14">
                    Used by border officers to calculate duties when shipping
                    internationally. Shown on customs forms you print during
                    fulfillment.
                  </div>
                  <div className="secon-text desc-shipping regular text-14">
                    Country of origin
                  </div>
                  <Form.Item>
                    {getFieldDecorator(`shippingMetadata.countryOfOrigin`, {
                      initialValue:
                        activeProduct && activeProduct.shippingMetadata
                          ? activeProduct.shippingMetadata.countryOfOrigin
                          : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <Select
                        // style={{ minWidth: "275px", marginTop: "10px" }}
                        showSearch
                      >
                        {CountryList.map((country, index) => (
                          <Option key={index} value={country}>
                            {country}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <div className="secon-text desc-shipping regular text-14">
                    In most cases, where the product is manufactured
                  </div>
                  <div className="secon-text desc-shipping regular text-14">
                    HS (Harmonized System) code
                  </div>
                  <Form.Item>
                    {getFieldDecorator(`shippingMetadata.hsCode`, {
                      initialValue:
                        activeProduct && activeProduct.shippingMetadata
                          ? activeProduct.shippingMetadata.hsCode
                          : undefined,
                      rules: [{ required: true, message: "Required" }],
                    })(
                      <InputNumber
                        style={{ minWidth: "275px", marginTop: "10px" }}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="flex flex-col contentRight">
              {/* Product Type */}
              <div className="tags mb-28">
                <div className="flex justify-between">
                  <p className="mb-24 text-black semibold text-18">
                    Product Type
                  </p>
                </div>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Product">
                      {getFieldDecorator(`productType`, {
                        initialValue: activeProduct?.productType || "product",
                        rules: [{ required: true, message: "Required" }],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select product type"
                          onChange={(productType) => {
                            this.setState({
                              productType,
                            });
                            if (products.length === 0) {
                              this.addProduct();
                            }
                          }}
                        >
                          {PRODUCT_TYPE.map((x, i) => (
                            <Option value={x} key={i}>
                              {x}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {this.state.productType === "bundle" &&
                  products.map((item, index) => (
                    <Row
                      key={index}
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={[8, 0]}
                    >
                      <Col span={14}>
                        <Form.Item label="Product">
                          {getFieldDecorator(`products[${index}.id]`, {
                            initialValue: item?.id,
                            rules: [{ required: true, message: "Required" }],
                          })(
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select product"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.label
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {activeProducts.map((x) => (
                                <Option value={x.id} key={x.id}>
                                  {x.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Quantity">
                          {getFieldDecorator(`products[${index}].quantity`, {
                            initialValue: item?.quantity,
                            rules: [{ required: false, message: "Required" }],
                          })(
                            <InputNumber
                              formatter={(value) =>
                                ` ${value}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              min={0}
                              max={10000000}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="Short Name">
                          {getFieldDecorator(`products[${index}].shortName`, {
                            initialValue: item?.shortName,
                            rules: [{ required: false, message: "Required" }],
                          })(<Input />)}
                        </Form.Item>
                      </Col>
                      <Col span={2} className="">
                        <Form.Item>
                          {index === products?.length - 1 ? (
                            <Button
                              className="mt-40"
                              type="primary"
                              shape="circle"
                              icon="plus"
                              size="default"
                              onClick={this.addProduct}
                            />
                          ) : (
                            <Button
                              className="mt-40"
                              type="danger"
                              shape="circle"
                              icon="close"
                              size="default"
                              onClick={() => this.removeProduct(index)}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
              </div>
              {/* category */}
              <div className="products mb-28">
                <div className="flex justify-between">
                  <div className="text-black semibold text-18">Category</div>
                  {this.isEditMode() && (
                    <div className="regular text-14 text-link">Edit</div>
                  )}
                </div>
                <div className="mb-6 text-black regular text-14">
                  Select Category
                </div>
                <Form.Item label="">
                  {getFieldDecorator("category", {
                    initialValue:
                      activeProduct && activeProduct.category
                        ? JSON.stringify(activeProduct.category)
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: "Please select a category",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select a category"
                      style={{ width: "100%" }}
                      // onChange={handleChange}
                    >
                      {this.props.categoryList &&
                        this.props.categoryList.length > 0 &&
                        this.props.categoryList.map((category) => {
                          const value = JSON.stringify({
                            id: category._id,
                            name: category.name,
                            yotpoId: category.yotpoId,
                          });
                          return (
                            <Option value={value} key={category._id}>
                              {category.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              {/* ingredients */}
              <div className="ingredients mb-28">
                <div className="mb-10 text-black semibold text-18">
                  Ingredients
                </div>
                <div className="mb-10 text-black semibold text-18">
                  Inventory
                </div>
                <Form.Item label="USFC">
                  {getFieldDecorator(`usFCStock`, {
                    initialValue:
                      activeProduct && activeProduct.usFCStock
                        ? activeProduct.usFCStock
                        : undefined,
                    rules: [{ required: false, message: "Required" }],
                  })(
                    <InputNumber
                      style={{ marginTop: "10px" }}
                      min={0}
                      // max={10}
                      disabled={true}
                    />
                  )}
                </Form.Item>
                <Form.Item label="AUFC">
                  {getFieldDecorator(`auFCStock`, {
                    initialValue:
                      activeProduct && activeProduct.auFCStock
                        ? activeProduct.auFCStock
                        : undefined,
                    rules: [{ required: false, message: "Required" }],
                  })(
                    <InputNumber
                      style={{ marginTop: "10px" }}
                      min={0}
                      // max={10}
                      disabled={true}
                    />
                  )}
                </Form.Item>
                <Form.Item label="SGFC">
                  {getFieldDecorator(`sgFCStock`, {
                    initialValue:
                      activeProduct && activeProduct.sgFCStock
                        ? activeProduct.sgFCStock
                        : undefined,
                    rules: [{ required: false, message: "Required" }],
                  })(
                    <InputNumber
                      style={{ marginTop: "10px" }}
                      min={0}
                      // max={10}
                      // defaultValue={0.33}
                      disabled={true}
                    />
                  )}
                </Form.Item>
                <Form.Item label="SGWH">
                  {getFieldDecorator(`sgWHStock`, {
                    initialValue:
                      activeProduct && activeProduct.sgWHStock
                        ? activeProduct.sgWHStock
                        : undefined,
                    rules: [{ required: false, message: "Required" }],
                  })(
                    <InputNumber
                      style={{ marginTop: "10px" }}
                      min={0}
                      disabled={true}
                    />
                  )}
                </Form.Item>
                {this.state.productType !== "merch" && (
                  <>
                    <Divider />
                    <div className="mb-4 mb-6 text-black uppercase regular text-14">
                      hero ingredient
                    </div>
                    <Form.Item label="">
                      {getFieldDecorator("heroIngredient", {
                        initialValue:
                          activeProduct &&
                          activeProduct.heroIngredient &&
                          activeProduct.heroIngredient.id,
                        rules: [
                          {
                            required: true,
                            message: "Please select a hero ingredient",
                          },
                        ],
                      })(
                        <Select className="w-100">
                          {this.props.ingredientsList &&
                            this.props.ingredientsList.length > 0 &&
                            this.props.ingredientsList.map((ingredient) => {
                              return (
                                <Option
                                  value={ingredient._id}
                                  key={ingredient._id}
                                >
                                  {ingredient.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </Form.Item>
                    <Divider />
                    <div className="mb-4 mb-6 text-black uppercase regular text-14">
                      active ingredient
                    </div>
                    <Form.Item label="">
                      {getFieldDecorator("activeIngredients", {
                        initialValue:
                          activeProduct &&
                          activeProduct.activeIngredients &&
                          activeProduct.activeIngredients.length
                            ? activeProduct.activeIngredients.map(
                                (ingre) => ingre._id
                              )
                            : undefined,
                        rules: [
                          {
                            required: true,
                            message:
                              "Please select atleast one active ingredient",
                            type: "array",
                          },
                        ],
                      })(
                        <Select
                          className="w-100"
                          style={{ marginBottom: "16px" }}
                          mode="multiple"
                          onSearch={this.handleSearch}
                        >
                          {this.state.ingredientList.map((ingredient) => {
                            return (
                              <Option
                                value={ingredient._id}
                                key={ingredient._id}
                              >
                                {ingredient.name}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                    <Divider />
                    <div className="mb-4 mb-6 text-black uppercase regular text-14">
                      Full list of Ingredients
                    </div>
                    <Form.Item>
                      {getFieldDecorator(`listIngredients`, {
                        initialValue: this.props.activeProduct
                          ? this.props.activeProduct.listIngredients
                          : undefined,
                        rules: [{ required: true, message: "Required" }],
                      })(<Input.TextArea />)}
                    </Form.Item>
                  </>
                )}
              </div>

              {this.state.productType !== "merch" && (
                <div className="tags mb-28">
                  <div className="flex justify-between">
                    <p className="mb-24 text-black semibold text-18">
                      Benefits
                    </p>
                  </div>

                  <Form.Item label="">
                    {getFieldDecorator("benefits", {
                      initialValue:
                        activeProduct &&
                        activeProduct.benefits &&
                        activeProduct.benefits.length
                          ? activeProduct.benefits.map((ingre) => ingre._id)
                          : undefined,
                      rules: [
                        {
                          required: false,
                          message: "Please select atleast one tag",
                          type: "array",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Select a benefit"
                        style={{ width: "100%" }}
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.benefitList &&
                          this.state.benefitList.map((tag) => (
                            <Option value={tag._id} key={tag._id}>
                              {tag.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              )}
              <div className="tags mb-28">
                <div className="flex justify-between">
                  <p className="mb-24 text-black semibold text-18">
                    Additional Settings
                  </p>
                </div>
                {this.renderInputFields(this.state.additionalSettings)}
                <Form.Item label="Promo Discount Applicable" className="mb-8">
                  <Switch
                    onChange={this.onTogglePromoDiscount}
                    checked={this.state.isPromoApplicable}
                  />
                </Form.Item>
                <Form.Item
                  label="Subscription Discount Applicable"
                  className="mb-8"
                >
                  <Switch
                    onChange={this.onToggleSubDiscount}
                    checked={this.state.isSubDiscountApplicable}
                  />
                </Form.Item>
                <Form.Item label="Is Subscribable" className="mb-8">
                  <Switch
                    onChange={this.onToggleSubscribable}
                    checked={this.state.isSubscribable}
                  />
                </Form.Item>
                {this.state.productType == "bundle" && (
                  <Form.Item label="Show on /set page" className="mb-8">
                    <Switch
                      onChange={this.onToggleSetPage}
                      checked={this.state.showonSetPage}
                    />
                  </Form.Item>
                )}
                <ProductRestriction
                  restrictedCountry={this.state.restrictedCountry}
                  category={false}
                  onChangeRestrictedCountry={this.onChangeRestrictedCountry}
                  allowedCountry={this.state.allowedCountry}
                  onChangeAllowedCountry={this.onChangeAllowedCountry}
                  hideFromResults={this.state.hideFromResults}
                  onToggleHideFromResults={this.onToggleHideFromResults}
                  isRecommended={this.state.isRecommended}
                  onToggleisRecommended={this.onToggleisRecommended}
                  isRecommendedOnSideCart={this.state.isRecommendedOnSideCart}
                  onToggleisRecommendedOnSideCart={
                    this.onToggleisRecommendedOnSideCart
                  }
                  isAvailable={this.state.isAvailable}
                  onToggleisAvailable={this.onToggleisAvailable}
                  showInGiftsPage={this.state.showInGiftsPage}
                  onToggleisGiftSets={this.onToggleisGiftSets}
                  isBestseller={this.state.isBestseller}
                  onToggleisBestseller={this.onToggleisBestseller}
                />
              </div>
              {
                <div className="tags mb-28">
                  <div className="flex justify-between">
                    <p className="mb-24 text-black semibold text-18">
                      Recommended Products
                    </p>
                  </div>
                  {this.state.recommendedProducts &&
                    this.state.recommendedProducts.map((item, index) => (
                      <Row>
                        <Col span={24}>
                          <Form.Item label="Product">
                            {getFieldDecorator(
                              `recommendedProducts[${index}].productId`,
                              {
                                initialValue: item.productId,
                                rules: [
                                  {
                                    required: true,
                                    message: "Required Recommended Product",
                                  },
                                ],
                              }
                            )(
                              <Select
                                style={{ width: "100%" }}
                                placeholder="Select product "
                                // onChange={(productType) => {
                                //   console.log("This is being print", productType);
                                //   this.setState({
                                //     recommendedProducts[index]: productType,
                                //   });
                                //   if (products.length === 0) {
                                //     this.addProduct();
                                //   }
                                // }}
                              >
                                {this.state.recommendedProductsList.map((x) => (
                                  <Option value={x.id} key={x.id}>
                                    {x.name}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                </div>
              }
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Ingredients, Products, Tags, Category, Currency } = state;
  return {
    ingredientsList: Ingredients.ingredients,
    productList: Products.products,
    categoryList: Category.category,
    currencyList: Currency.currency,
    tags: Tags.tags,
    activeProduct: Products.activeProduct,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListBenefits: bindActionCreators(
      benefitActions.getSearchedBenefits,
      dispatch
    ),
    fetchListProducts: bindActionCreators(actions.getProductsList, dispatch),
    fetchAllIngredients: bindActionCreators(
      ingredientActions.getSearchedIngredients,
      dispatch
    ),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
    fetchAllCategory: bindActionCreators(
      categoryActions.getSearchedCategory,
      dispatch
    ),
    fetchAllCurrency: bindActionCreators(
      currencyActions.getSearchedCurrency,
      dispatch
    ),
    createProduct: bindActionCreators(actions.createProduct, dispatch),
    updateProduct: bindActionCreators(actions.updateProduct, dispatch),
    getActiveProduct: bindActionCreators(actions.getProduct, dispatch),
    setActiveProduct: bindActionCreators(actions.setActiveProduct, dispatch),
  };
}

const AddProductsForm = Form.create({ name: "add_products_form" })(AddProducts);

export default connect(mapStateToProps, mapDispatchToProps)(AddProductsForm);
