import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router
} from "react-router-dom";
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from "store/configureStore";
const store = configureStore();

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <App />
        </Provider>
    </Router>,
    document.getElementById('root')
);
