import { Api } from "../config/request";

export const getPreSignedURLFromAWSUpload = query => {
  let url = `catalogue/ingredient/list`;
  return Api.getListRequest(url);
};

export const getPreSignedURL = payload => {
  return Api.postRequest(`documents/upload`, payload);
};

const CommonAPI = {
    getPreSignedURL
};

export default CommonAPI;
