import React, { Component } from "react";
import "./selfies.scss";
import { Badge, Modal, Table } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/selfies";
import { Link } from "react-router-dom";
import moment from "moment";

class Selfies extends Component {
  state = {
    modalTitle: "",
    showModal: false,
    modalData: {},
  };

  componentDidMount() {
    this.props.getSelfies().then((res) => {
      if (this.props.location.search?.includes("id")) {
        let id = this.props.location.search.replace("?id=", "");
        let selfie = this.props.selfieList.find((x) => x._id === id);
        if (selfie) {
          this.setState({
            modalTitle: selfie?.user?.firstName + " " + selfie?.user?.lastName,
            showModal: true,
            modalData: selfie,
          });
        }
      }
    });
  }

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email, row) => (
        <Link
          style={{ color: "#373873" }}
          to={`/customer/edit/${row?.userId}`}
          target="_blank"
        >
          {email}
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Consent",
      dataIndex: "consentToShare",
      key: "consentToShare",
      render: (state) => {
        return state ? <>✅</> : <>❌</>;
      },
    },
    {
      title: "Author Photo",
      dataIndex: "image",
      key: "image",
      render: (image, row) => (
        <img
          src={image}
          style={{ maxHeight: "60px" }}
          alt="selfie"
          onClick={() => {
            this.setState({
              modalTitle: row?.name,
              showModal: true,
              modalData: row,
            });
          }}
        />
      ),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
  ];

  render() {
    let mappedReviewsList = this.props?.selfieList?.map((x) => ({
      _id: x._id,
      userId: x?.user?._id,
      isActive: x.isActive,
      consentToShare: x.consentToShare,
      rating: x.rating,
      name: `${x?.user?.firstName} ${x?.user?.lastName}`,
      email: x?.user?.email,
      image: x.image,
      createdAt: moment(x.createdAt).format("DD MMMM YYYY"),
    }));
    return (
      <div className="selfie-page">
        <div className="flex items-center mb-36">
          <div className="theme-color semibold text-30 mr-14 mb-0">Selfies</div>
        </div>
        <div style={{ background: "white" }}>
          <Table
            rowClassName="cursor-pointer"
            columns={this.columns}
            dataSource={mappedReviewsList}
            bordered
            rowKey={(record) => record._id}
            size="small"
          />
        </div>
        <Modal
          title={this.state.modalTitle}
          visible={this.state.showModal}
          onCancel={() => {
            this.setState({ showModal: false });
            this.props.history.replace({
              pathname: "/progress-selfies",
            });
          }}
          footer={[
            <div className="flex items-center justify-between pl-30 pr-30">
              <div className="flex items-center">
                <span className="mr-10">Consent to share:</span>
                <span className="text-14">
                  {this.state.modalData?.consentToShare ? <>✅</> : <>❌</>}
                </span>
              </div>
              <div className="flex items-center ml-20">
                <span className="mr-10">CreatedAt:</span>
                <span className="text-14">
                  {moment(this.state.modalData?.createdAt).format(
                    "DD MMMM YYYY"
                  )}
                </span>
              </div>
            </div>,
          ]}
        >
          <div className="flex items-center justify-center ">
            <img
              src={this.state.modalData?.image}
              alt="selfie"
              style={{ maxHeight: "400px" }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Selfies } = state;
  return {
    selfieList: Selfies.list,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSelfies: bindActionCreators(actions.getSelfiesList, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Selfies);
