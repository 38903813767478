import React from "react";
import { message } from "antd";
import CommonAPI from "services/common";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  orderFullfillmentStatusListing,
  orderFulfillmentStatusLabel,
  paymentStatusMapping,
} from "./constants";
import stripeLogo from "static/img/icon/Stripe_Logo.svg";
import paypalLogo from "static/img/icon/PayPal_logo.svg";
import braintreeLogo from "static/img/icon/Braintree.svg";
import shopifyLogo from "static/img/icon/Shopify.svg";
import afterpayLogo from 'static/img/icon/Afterpay_Logo.png';
import applePay from 'static/img/icon/Apple_Pay_logo.svg';
import klarna from 'static/img/icon/Klarna_logo.svg';

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  if (e?.file?.response) {
    return e.file.response;
  }
  return e.file;
};

export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

export function beforeUploadVideoMP4(file) {
  const isMP4 = file.type === "video/mp4";
  if (!isMP4) {
    message.error("You can only upload only MP4 file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 8;
  if (!isLt2M) {
    message.error("Image must smaller than 8MB!");
  }
  return isMP4 && isLt2M;
}

export function beforeUploadVideoWebM(file) {
  const isWebm = file.type === "video/webm";
  if (!isWebm) {
    message.error("You can only upload Webm file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 8;
  if (!isLt2M) {
    message.error("Image must smaller than 8MB!");
  }
  return isWebm && isLt2M;
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

var BASE64_MARKER = ";base64,";

export function convertDataURIToBinary(dataURI) {
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export const UploadImage = async (options) => {
  try {
    const preSignedURLResp = await CommonAPI.getPreSignedURL({
      file1: options.file.name,
    });
    // options.onSuccess(null, preSignedURLResp.file1.accessUrl);
    const base64Img = await toBase64(options.file);
    const blobImg = convertDataURIToBinary(base64Img);
    axios
      .put(preSignedURLResp.file1.preSignedURL, blobImg)
      .then((res) => {
        options.onSuccess(
          preSignedURLResp.file1.accessURL.replace("yours-dev.s3-ap-southeast-1.amazonaws.com","cdn.lovefromyours.com"),
          preSignedURLResp.file1.accessURL.replace("yours-dev.s3-ap-southeast-1.amazonaws.com","cdn.lovefromyours.com")
        );
      })
      .catch((err) => {
        options.onError();
      });
  } catch (err) {
    console.log(err);
  }
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// filters out key in object not matching array elements
export const filterObj = (arr, obj) => {
  let copyObj = { ...obj };
  for (let key in copyObj) {
    if (arr.includes(key) === false) {
      delete copyObj[key];
    }
  }
  return copyObj;
};

export const getSafely = (fn, defaultVal) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const logout = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location = "/login";
};

export const getUserInitials = (name) => {
  if (!name) {
    return "";
  }
  let arr = name.split(" ");
  if (arr && arr.length > 0) {
    return `${arr[0] ? arr[0][0] : ""}${arr[1] ? arr[1][0] : ""}`;
  } else {
    return "";
  }
};

export const removeCommaFromAddress = (address) => {
  if (address[address.length - 1] === ",") {
    return address.trim().slice(0, -1);
  }

  return address.trim();
};

export const formatAddress = (addressObj = {}) => {
  if(addressObj == null){
    return '';
  }
  const { street, apartment, city, state, zipCode } = addressObj;
  let formattedAddress = "";
  if (street && !isEmpty(street)) {
    formattedAddress = formattedAddress + removeCommaFromAddress(street);
  }
  if (apartment && !isEmpty(apartment)) {
    formattedAddress = formattedAddress.concat(
      street ? `, ${apartment}` : removeCommaFromAddress(apartment)
    );
  }

  if (city && !isEmpty(city)) {
    formattedAddress = formattedAddress.concat(
      street || apartment ? `, ${city}` : removeCommaFromAddress(city)
    );
  }

  if (state && !isEmpty(state)) {
    formattedAddress = formattedAddress.concat(
      street || apartment || city ? `, ${state}` : removeCommaFromAddress(state)
    );
  }

  if (zipCode && !isEmpty(zipCode)) {
    formattedAddress = formattedAddress.concat(
      street || apartment || city || state
        ? `, ${zipCode}`
        : removeCommaFromAddress(zipCode)
    );
  }

  return formattedAddress;
};

export const isCurrentDate = (time) => {
  moment(time).isSame(new Date(), "day");
};

export const formatShippingExport = (data) => {
  if (isEmpty(data)) {
    return [];
  }

  return data.map((order) => {
    const {
      shortId,
      user,
      shippingAddress,
      totalWeight,
      currency,
      chargedPrice,
      status,
    } = order;
    if (shippingAddress) {
      const { street, apartment, city, state, zipCode } = shippingAddress;
      return {
        order_number: shortId,
        payment_status: paymentStatusMapping[status],
        name: user && `${user.firstName} ${user.lastName}`,
        email: user && user.email,
        order_placement_date: `${
          order.createdAt ? moment(order.createdAt).format("DD/MM/YYYY") : "-"
        }`,
        order_placement_time: `${
          order.createdAt ? moment(order.createdAt).format("hh:mm a") : "-"
        }`,
        address_1: street && street,
        address_2: formatAddress({ apartment, city, state }),
        city: shippingAddress && shippingAddress.city,
        postal_code: zipCode && zipCode,
        state: shippingAddress && shippingAddress.state,
        country: shippingAddress && shippingAddress.country,
        phone_country_code:
          shippingAddress &&
          shippingAddress.phone &&
          shippingAddress.phone.isdCode.slice(1),
        phone_number:
          shippingAddress &&
          shippingAddress.phone &&
          shippingAddress.phone.phone,
        total_weight: `${totalWeight}gm`,
        declared_value_currency: currency && currency.code.toUpperCase(),
        declared_value: chargedPrice,
        shipping_name: `${
          shippingAddress.firstName && shippingAddress.firstName
        } ${shippingAddress.lastName && shippingAddress.lastName}`,
      };
    } else {
      return {};
    }
  });
};

const getPhoneNumber = (order) => {
  if (order && order.shippingAddress && order.shippingAddress.phone) {
    return (
      order.shippingAddress.phone.isdCode +
      " - " +
      order.shippingAddress.phone.phone
    );
  }
  return "-";
};

const getOrderType = (order) => {
  if (order.isSubscribed) {
    if (
      order &&
      order.subscriptionId &&
      order.subscriptionId.period &&
      order.subscriptionId.period.value
    ) {
      if (order.subscriptionId.period.value / 30 > 1) {
        return `Subscription (${
          order.subscriptionId.period.value / 30
        } Months)`;
      }
      return "Subscription (1 Month)";
    }
    return "Subscription";
  }
  return "One-time";
};

export const mapOrderData = (ordersList = []) => {
  const mappedOrdersList =
    ordersList.length > 0
      ? ordersList.map((order) => {
          const itemsPurchased = order.items.map(x => x?.product?.ssu).join(', ');
          return {
            id: order.id,
            submissionTime: `${
              order.createdAt ? moment(order.createdAt).format("hh:mm a") : "-"
            }`,
            key: order.id,
            userId: order.user.id && order.user.id.id ? order.user.id.id : "-",
            email: order.user.email,
            submissionDate: `${
              order.createdAt
                ? moment(order.createdAt).format("DD/MM/YYYY")
                : "-"
            }`,
            // attempt: order.shortId ? order.shortId : "-",
            attempt: order.attempt ? order.attempt : "-",
            chargedPrice:
              `${
                order.currency &&
                order.currency.code &&
                order.currency.code.toUpperCase()
              }` +
              " " +
              `${order.chargedPrice}`,
            // totalPrice: order.totalPrice,
            receiptId: order.receiptId,
            orderStatus: paymentStatusMapping[order.status],
            invoice: "",
            origin: order.origin,
            shippingAddress: formatAddress(order.shippingAddress),
            shipmentId: order.trackingNumber,
            fulfillmentStatusCode: order ? order.fulfillmentStatus : 0,
            shipmentLabel: order,
            phone: getPhoneNumber(order),
            itemsPurchased,
            itemsQuantity: order.items
              ? order.items.map((item) => item.quantity).join(", ")
              : [],
            action: order.id,
            type:
              order && order.orderType ? order.orderType.toUpperCase() : "-",
            subscriptionType: getOrderType(order),
            totalWeight: order.totalWeight,
            paymentMethod: order.paymentMethod,
            giftSet: order?.gift.to ? "Yes" : "No",
            giftSetNote: order?.gift.message ? "Yes" : "No",
            country: order.shippingAddress
              ? order.shippingAddress.country
              : "-",
            notes: order.notes ? order.notes : "",
            customerNotes:
              order && order.user && order.user.id ? order.user.id.notes : "",
            stripeInvoiceId:
              order && order.stripeInvoiceId ? order.stripeInvoiceId : "-",
            fulfillmentStatus: order
              ? orderFulfillmentStatusLabel[order.fulfillmentStatus]
              : "-",
            promoType:
              order && order.promotion && order.promotion.promoType
                ? order.promotion.promoType
                : "-",
            code:
              order && order.promotion && order.promotion.code
                ? order.promotion.code
                : "-",
            status: order && order.status ? order.status : "-",
            shipping: `${
              order && order.currency && order.currency.code
                ? order.currency.code.toUpperCase()
                : "-"
            }${order && order.shipping ? order.shipping : "-"}`,
            trackingDetails: order.trackingDetails,
            name: `${order.user.firstName || ""} ${order.user.lastName || ""}`,
            orderId: order.shortId,
            daySerum: getProductName(order, "day serum"),
            dayCream: getProductName(order, "day cream"),
            nightSerum: getProductName(order, "night serum"),
            nightCream: getProductName(order, "night cream"),
            SSU: getEssentials(order, "ssu"),
            CFC: getEssentials(order, "CFC"),
            BBT: getEssentials(order, "BBT"),
            ELYES: getEssentials(order, "ELYES"),
            CSM_FB: getFreebie_minis(order),
            Extras: getExtraProduct(order),
            tags:
              order.tags &&
              order.tags
                .map((tag) => {
                  return tag === Object(tag) ? tag.name : "";
                })
                .join(","),
          };
        })
      : [];
  return mappedOrdersList;
};

const getProductRecommendedType = (product, productArray) => {
    let productCategory = product?.product?.category?.name;
    let recommendationType = productCategory;
    productCategory = productCategory.toLowerCase();
    let ProductName = product?.product?.name?.toLowerCase();

    if (productCategory.includes("face")) {
      //console.log( "XXXXXXXXXX" )
      if (ProductName === "drop of light") {
        recommendationType = "Day Serum";
        return recommendationType;
      }
      if (ProductName === "drop of youth") {
        recommendationType = "Night Serum";
        return recommendationType;
      } else {
        if (ProductName === "drop of softness") {
          if (!productArray?.includes("drop of light")) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night Serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of balance") {
          if (
            !(
              productArray?.includes("drop of light") ||
              productArray?.includes("drop of softness")
            )
          ) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of perfection") {
          if (!productArray?.includes("drop of youth")) {
            recommendationType = "Night Serum";
            return recommendationType;
          } else {
            recommendationType = "Day Serum";
            return recommendationType;
          }
        }
      }
    }

    return recommendationType;
  }


  const getProductInitials =(name) =>{
    if (name) {
      return name
        .split(" ")
        .map((word, index) => {
          if (index < 3) {
            return word.charAt(0).toUpperCase();
          }
        })
        .join("");
    }
  }

  const getProductName = (order, orderType) => {
    if (!order) {
      return "";
    }
    let product_name = "";
    let quantity = "";
    let productNameArray = order.items?.map((p) =>
      p.product.name.toLowerCase()
    );
    for (let i = 0; i < order.items.length; i++) {
      let item = order.items[i];
      let recommendationType = item?.recommendationType?.toLowerCase();
      if (!recommendationType) {
        recommendationType = getProductRecommendedType(
          item,
          productNameArray
        );
        recommendationType = recommendationType.toLowerCase().trim();
      }
      if (
        recommendationType === "day cream " ||
        recommendationType === "moisturiser"
      ) {
        recommendationType = "day cream";
      }
      if (recommendationType.toLowerCase().trim() === orderType.toLowerCase()) {
        product_name =
          item?.product?.ssu || getProductInitials(item?.product?.name);
        quantity = item?.quantity;
        break;
      }
    }

    if (product_name) {
      return product_name + " - " + quantity;
    } else {
      return quantity;
    }
  }

  const getExtraProduct= (order) => {
    if (!order) {
      return "";
    }

    let productNameArray = order.items?.map((p) =>
      p.product.name.toLowerCase()
    );

    let recommendedType = [];
    let extra_product = [];

    for (let i = 0; i < order.items.length; i++) {
      let item = order.items[i];
      let recommendationType = item?.recommendationType?.toLowerCase();
      if (!recommendationType) {
        recommendationType = getProductRecommendedType(
          item,
          productNameArray
        );
        recommendationType = recommendationType.toLowerCase().trim();
      }
      if (
        recommendationType === "day cream " ||
        recommendationType === "moisturiser"
      ) {
        recommendationType = "day cream";
      }
      if (
        recommendationType.includes("day") ||
        recommendationType.includes("night")
      ) {
        if (!recommendedType.includes(recommendationType)) {
          recommendedType.push(recommendationType);
        } else {
          const itemName =
            item?.product?.ssu || getProductInitials(item?.product?.name);
          let quantity = item.quantity;
          extra_product.push(itemName + "-" + quantity);
        }
      } else {
        if (
          recommendationType == "extras" ||
          item?.product?.category?.name?.toLowerCase() == "bundle" ||
          item?.product?.category?.name?.toLowerCase() == "bundles" ||
          item?.product?.category?.name?.toLowerCase() == "merch" ||
          item?.product?.category?.name?.toLowerCase() == 'complimentary' ||
          !item?.product?.ssu ||
          item?.product?.ssu == "ACP"
        ) {
          const itemName =
            item?.product?.ssu || getProductInitials(item?.product?.name);
          let quantity = item.quantity;
          extra_product.push(itemName + "-" + quantity);
        }
      }
    }
    return extra_product.join(",");
  }

  const getEssentials=(order, productName) => {
    if (!order) {
      return "";
    }

    let product_name = "";
    let quantity = "";

    order.items.forEach((item) => {
      if (item?.product?.ssu?.toLowerCase() === productName.toLowerCase()) {
        product_name = item?.product?.ssu;
        quantity = item?.quantity;
      }
    });

    if (product_name) {
      return product_name + " - " + quantity;
    } else {
      return quantity;
    }

    // return  quantity;
  }

  const getFreebie_minis = (order) =>{
    if (!order) {
      return "";
    }

    let product_name = "";
    let quantity = "";
    let item_name = "";
    order.items.forEach((item) => {
      if (
        item?.product?.ssu?.toLowerCase() === "mexfo" ||
        item?.product?.ssu?.toLowerCase() === "fbs" ||
        item?.product?.ssu?.toLowerCase() === "mexfo2"
      ) {
        product_name = item?.product?.ssu;
        quantity = item?.quantity;
        item_name += " " + product_name + "(3)" + "- " + quantity;
      }
    });

    return item_name;
    // return  quantity;
  }


export const getPaymentMethod = (paymentMethod = "") => {
  switch (paymentMethod) {
    case "stripe": {
      return (
        <div>
          <img src={stripeLogo} alt="stripe" height="35" />
        </div>
      );
    }

    case "stripeUSA": {
      return (
        <div>
          <img src={stripeLogo} alt="stripe" height="35" />
        </div>
      );
    }

    case "stripeAUS": {
      return (
        <div>
          <img src={stripeLogo} alt="stripe" height="35" />
        </div>
      );
    }

    case 'stripe-afterpay': {
      return (
        <div>
          <img src={afterpayLogo} alt="afterpay" height="35" />
        </div>
      );
    }
    
    case 'stripe-afterpay-AU': {
      return (
        <div>
          <img src={afterpayLogo} alt="afterpay" height="35" />
        </div>
      );
    } 

    case 'stripe-klarna-US': {
      return (
        <div>
          <img src={klarna} alt="klarna" height="25" />
        </div>
      );
    } 

    case "shopify": {
      return (
        <div>
          <img src={shopifyLogo} alt="shopify" height="35" />
        </div>
      );
    }
    case "paypal": {
      return (
        <div>
          <img
            src={braintreeLogo}
            height="20"
            style={{
              marginTop: "10px",
              marginBottom: "5px",
            }}
            alt="braintree"
          />
        </div>
      );
    }
    case "paypal-og": {
      return (
        <div>
          <img
            src={paypalLogo}
            height="18"
            style={{
              marginTop: "10px",
              marginBottom: "5px",
            }}
            alt="paypal"
          />
        </div>
      );
    }

    case "stripe-apple-pay": {
      return (
        <div>
          <img
            src={applePay}
            height="18"
            style={{
              marginTop: "10px",
              marginBottom: "5px",
            }}
            alt="applePay"
          />
        </div>
      );
    }

    default:
      return <div>-</div>;
  }
};

export const addQueryParam = (param, valueArr) => {
  if (valueArr?.length > 0) {
    const queryArr = []
    valueArr.map((value) => {
      queryArr.push(
        `${param}=${value}`
      );
    });
    const queryCountry = queryArr.join('&')
    return '&' + queryCountry
  } else {
    return ''
  }
}



export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return "";
  }
  return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1);
};

export const handleDownload = (url, filename) => {
  let config ={
    headers :{
      'Access-Control-Allow-Origin' :'*'
    }
  }
  console.log("Label Url",url);
  axios
    .get(url, {
      responseType: "blob",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*"
      },
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
};