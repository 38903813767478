import DiscountApi from "services/discount";
import * as queryString from "query-string";

function handleError(err) {
  console.error(err);
}

export function setActiveDiscount(payload) {
  return {
    type: "SET_ACTIVE_DISCOUNT",
    payload
  }
};

export function listDiscount(result) {
  return {
    type: "LIST_DISCOUNT",
    payload: result
  };
}

export function listBulkDiscount(result) {
  return {
    type: "LIST_BULK_DISCOUNT",
    payload: result,
  };
}

export function listBulkDiscountCodes(result) {
  return {
    type: "LIST_BULK_DISCOUNT_CODES",
    payload: result,
  };
}
export function updateDiscountList(result) {
  return {
    type: "UPDATE_DISCOUNT_LIST",
    payload: result
  }
};

export function addDiscount(result) {
  return {
    type: "ADD_DISCOUNT",
    payload: result
  };
}

export const getDiscount = (id ,type) => async dispatch => {
  try {
    let res = await DiscountApi.getDiscountById(id,type);
    dispatch(setActiveDiscount(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const getDiscountList = () => async dispatch => {
   try {
    let res = await DiscountApi.getDiscountsList();
    dispatch(listDiscount(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getSearchedDiscount = payload => async dispatch => {
  try {
    let query = queryString.stringify(payload);
    let res = await DiscountApi.getSearchedDiscounts(query);
    dispatch(listDiscount(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
    return err;
  }
}



export const getBulkSearchedDiscount = (payload) => async (dispatch) => {
  try {
    let query = queryString.stringify(payload);
    let res = await DiscountApi.getBulkSearchedDiscounts(query);
    dispatch(listBulkDiscount(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getBulkSearchedDiscountCodes = (payload) => async (dispatch) => {
  try {
    let query = queryString.stringify(payload);
    let res = await DiscountApi.getBulkSearchedDiscountsCodes(query);
    dispatch(listBulkDiscount(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const createDiscount = (payload) => async dispatch => {
  try {
    let res = await DiscountApi.createDiscount(payload);
    dispatch(addDiscount(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateDiscount = (id, payload) => async dispatch => {
  try {
    let res = await DiscountApi.updateDiscountById(id, payload);
    if(res) {
      dispatch(updateDiscountList(res));
      dispatch(setActiveDiscount(res));
    }
    return res;
  } catch (err) {
    handleError(err);
  }
}

//addMoreDiscount

export const addMoreDiscount = (id, payload) => async (dispatch) => {
  try {
    let res = await DiscountApi.addMoreDiscountById(id, payload);
    if (res) {
      //dispatch(updateDiscountList(res));
      //dispatch(setActiveDiscount(res));
    }
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const deleteDiscount = id => async dispatch => {
  try {
    let res = await DiscountApi.deleteDiscountById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
}
