import React from "react";
import "./header.scss";
import logo from "static/img/logo.svg";
 
const Header = props => {
  return (
    <div className="component-header flex items-center">
      <img src={logo} className="logo" alt="main-logo" />
      {/* <Input className="search-input" placeholder="Search" />; */}
    </div>
  );
};

export default Header;
