import actionTypes from "actions/actionTypes";

const initialState = {
  list: [],
  activeSelfie: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.LIST_SELFIES:
      return {
        ...state,
        list: [...action.payload],
      };
    case actionTypes.SET_ACTIVE_SELFIE:
      return {
        ...state,
        activeSelfie: [...action.payload],
      };
    default:
      return state;
  }
}
