import { Api } from "services/config/request";

export const addingBannerData = (payload) => {
    let url = `banner`;
    return Api.postRequest(url, payload);
}

export const gettingBannerData = () => {
    let url = `banner`;
    return Api.getRequest(url);
}

const Banner = {
    addingBannerData,
    gettingBannerData
};

export default Banner;