import { Api } from "../config/request";

export const getOrders = (query, pageNo,pageSize,startDate,endDate) => {
  let url = `orders/admin/list?${query ? query : ""}${
    pageNo ? "&page=" + pageNo : ""
  }${pageSize ? `&&pageSize=${pageSize}`:''}${startDate ? `&startDate=${startDate}&endDate=${endDate}` : ''}`;
  return Api.getListRequest(url);
};

export const getAbandonedCartOrder = async(query, pageNo,pageSize,startDate,endDate) => {
  let url = `orders/admin/cart?${query ? query : ""}${
    pageNo ? "page=" + pageNo : ""
  }${pageSize ? `&&pageSize=${pageSize}`:''}${startDate ? `&startDate=${startDate}&endDate=${endDate}` : ''}`;
  return Api.getListRequest(url);
};

export const createOrder = (payload) => {
  let url = `orders/admin`;
  return Api.postRequest(url, payload);
};

export const getOrder = (id) => {
  let url = `orders/admin/${id}`;
  return Api.getRequest(url);
};

export const updateWaybill = (id) => {
  let url = `orders/admin//update-waybill/${id}`;
  return Api.patchRequest(url);
};

export const updateOrder = (id, payload) => {
  let url = `orders/admin/update-details/${id}`;
  return Api.patchRequest(url, payload);
};

export const changeProductsInOrder = (payload) => {
  let url = `orders/admin/change-products`;
  return Api.patchRequest(url, payload);
};

export const addProductsInOrder = (payload) => {
  let url = `orders/admin/add-more-products`;
  return Api.patchRequest(url, payload);
};

export const removeProductsFromOrder = (payload) => {
  let url = `orders/admin/remove-product`;
  return Api.patchRequest(url, payload);
};

export const getOrdersInvoice = (orderIds) => {
  let url = `orders/invoices?orderId=${orderIds}`;
  return Api.getRequest(url);
};

export const deleteOrder = (id) => {
  let url = `orders/admin/${id}`;
  return Api.deleteRequest(url);
};
export const createRefund = (payload) => {
  let url = `payment/refund`;
  return Api.postRequest(url, payload);
};
export const getRefund = (id) => {
  let url = `payment/refund/list?order=${id}`;
  return Api.getRequest(url);
};
export const skipSubscription = (id, payload) => {
  let url = `subscription/skip-subscription/${id}`;
  return Api.patchRequest(url, payload);
};

export const cancelSubscription = (id, payload) => {
  let url = `subscription/cancel-subscription/${id}`;
  return Api.patchRequest(url, payload);
};

export const editSubscription = (id, payload) => {
  let url = `subscription/edit-subscription/${id}`;
  return Api.patchRequest(url, payload);
};

export const updateSubscriptionProduct = (id, payload) => {
  let url = `subscription/update-product/${id}`;
  return Api.patchRequest(url, payload);
}
export const getSubscriptions = (id) => {
  let url = `subscription/search/?user=${id}&list=all`;
  return Api.getRequest(url);
};

export const updateWhatsAppMsgStatus = (orderId, messageStatus) => {
  let url = `orders/admin/update-whatsApp-msg/${orderId}`;
  return Api.patchRequest(url, { messageStatus: messageStatus });
};

export const updateWhatsAppMsgStatusInCart=( cartId ) =>{
  let url = `orders/admin/cart-whatsApp-msg-status/${cartId}`;
  return Api.patchRequest(url,{});
};

export const updateWhatsAppMessage = (messageType, message) => {
  let url = `orders/admin/update-whatsApp-message/${messageType}`;
  return Api.patchRequest(url, { message: message });
};

export const updateMultiCartWhatsAppMsgStatus = (cartIds, messageStatus) => {
  let url = `orders/admin/update-cart-multi-whatsApp-message`;
  return Api.patchRequest(url, {
    cartIds: cartIds,
    messageStatus: messageStatus,
  });
};

export const updateMultiOrdersWhatsAppMsgStatus = (orderIds, messageStatus) => {
  let url = `orders/admin/update-orders-multi-whatsApp-message`;
  return Api.patchRequest(url, {
    orderIds: orderIds,
    messageStatus: messageStatus,
  });
};

export const getWhatsAppMessage = (messageType) => {
  let url = `orders/admin/get-whatsApp-message/${messageType}`;
  return Api.getRequest(url);
};

export const getSubscription = (id) => {
  let url = `subscription/detail/${id}`;
  return Api.getRequest(url);
};

export const getSubscriptionHistory = (id) => {
  let url = `logshistory/subscriptions/${id}`;
  return Api.getRequest(url);
};

export const getCart = (id) => {
  let url = `orders/cart/${id}`;
  return Api.getRequest(url);
}

export const addToCart = (payload) => {
  let url = `orders/cart/add`;
  return Api.postRequest(url, payload);
}

export const updateCart = (payload) => {
  let url = `orders/cart/update`;
  return Api.patchRequest(url, payload);
}

export const applyPromoToCart = (payload) => {
  let url = `orders/cart/apply-promo`;
  return Api.patchRequest(url, payload);
}

export const removePromo = (payload) => {
  let url = `orders/cart/remove-promo`;
  return Api.patchRequest(url, payload);
}

export const updateCartType = (payload) => {
  let url = `orders/cart/updateCartDetails`;
  return Api.patchRequest(url, payload);
}
export const downloadMultipleInvoice = (orderIds) =>{
  let url = "orders/admin/download-invoice";
  return Api.postRequest(url, {orderIds});
}; 

export const downloadMultipleLabels = (orderIds) => {
  let url = "fulfillment/admin/download-labels";
  return Api.postRequest(url, { orderIds });
}; 

export const downloadMultipleIngredients = (orderIds) => {
  let url = "fulfillment/admin/download-ingredients";
  return Api.postRequest(url, { orderIds });
}; 

export const getLastOrdersForCustomer = ( customerId) =>{
  let url = "orders/admin/get-last-related-orders";
  return Api.postRequest(url, { customerId });
};

export const updateShippingAddress = ( orderId, addressId, Address) =>{
  let url = `orders/admin/update-shipping-Address/${orderId}/${addressId}`;
  return Api.patchRequest(url, Address);
}

export const getOrderComments = (orderId) => {
  let url = `orders/admin/comments/${orderId}`;
  return Api.getRequest(url);
}

export const postOrderComment = (payload) => {
  let url = `orders/admin/comments`;
  return Api.postRequest(url, payload);
}

export const postOrderReply = (orderId, payload) => {
  let url = `orders/admin/comments/${orderId}`;
  return Api.postRequest(url, payload);
}

export const updateComment = (orderId, payload) => {
  let url = `orders/admin/comments/${orderId}`;
  return Api.patchRequest(url, payload);
}

export const updateReply = (orderId, payload) => {
  let url = `orders/admin/reply/${orderId}`;
  return Api.patchRequest(url, payload);
}

export const updateFlaggedStatus = (payload) => {
  let url = `orders/admin/update-flagged-status`;
  return Api.postRequest(url, payload);
};

export const updateFlaggedstatuses = (payload) => {
  let url = `orders/admin/update-orders-flagged-status`;
  return Api.postRequest(url, payload);
};

export const getProductWiseCount = (query) => {
  let url = `orders/admin/product-wise/count?${query?query:''}`;
  return Api.getRequest(url);
};

export const getOrderHistory = id => {
  let url = `logshistory/orders/${id}`;
  return Api.getListRequest(url)

}
const OrderAPI = {
  getOrders,
  getAbandonedCartOrder,
  createOrder,
  getOrder,
  updateOrder,
  getOrdersInvoice,
  deleteOrder,
  changeProductsInOrder,
  createRefund,
  getRefund,
  skipSubscription,
  updateWhatsAppMsgStatus,
  updateWhatsAppMessage,
  getWhatsAppMessage,
  updateWhatsAppMsgStatusInCart,
  updateMultiCartWhatsAppMsgStatus,
  updateMultiOrdersWhatsAppMsgStatus,
  getSubscription,
  getSubscriptions,
  getCart,
  addToCart,
  updateCart,
  applyPromoToCart,
  removePromo,
  updateCartType,
  downloadMultipleInvoice,
  getLastOrdersForCustomer,
  updateShippingAddress,
  downloadMultipleLabels,
  downloadMultipleIngredients,
  getOrderComments,
  postOrderComment,
  postOrderReply,
  updateComment,
  updateReply,
  updateFlaggedStatus,
  updateFlaggedstatuses,
  getProductWiseCount,
  removeProductsFromOrder,
  getSubscriptionHistory,
  addProductsInOrder,
  updateWaybill,
};

export default OrderAPI;
