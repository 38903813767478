import React, { Component } from "react";
import "./customerSubscription.scss";
import { Link } from "react-router-dom";
import {
  Table,
  Icon,
  Select,
  Input,
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  message,
  Layout,
  Card,
  Modal,
  DatePicker,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import HistoryBlock from "components/historyBloc/historyBloc";
import { getSubscriptionHistory } from "services/orders/index";

import * as actions from "actions/customer";
import * as orderActions from "actions/orders";
import * as productActions from "actions/products";
import * as customerActions from "actions/customer";
import { getProductById, getProductsList } from "services/products";
import { CustomerAddress } from "../../customerPage/customerAddress/customerAddress";
import CreateAddressForm from "forms/createAddressForm";
import {
  skipSubscription,
  cancelSubscription,
  editSubscription,
  updateSubscriptionProduct,
} from "./../../../services/orders";

import { getSafely } from "utils/helpers";
import BinIcon from "static/img/icon/Bin.svg";
import API from "services/config/apiUrl";

// const { Header, Footer, Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;
const FormItem = Form.Item;
const { TextArea } = Input;

const getColumns = ({
  getFieldDecorator,
  productListByCategory,
  handleFetchProductsByCategory,
  deleteProduct,
  subscription,
}) => {
  return [
    {
      title: "Items",
      dataIndex: "product",
      key: "items",
      render: (product, row, i) => (
        <div className="flex items-center">
          <img
            src={product?.mainImage?.withoutHover?.mediaUrl}
            alt="product"
            style={{
              height: "45px",
              width: "45px",
              minWidth: "44px",
              objectFit: "cover",
              objectPosition: "center",
              marginRight: "10px",
            }}
          ></img>
          <div className="flex items-center">
            <p className="m0 text-black">{product?.name}</p>
            <span className="text-grey ml-2">({row.recommendationType})</span>
          </div>
        </div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "product",
      key: "weight",
      render: (product, row, i) => {
        return (
          <>
            {product?.shippingMetadata?.weight}
            <div className="d-none">
              <FormItem>
                {getFieldDecorator(`data[${i}].productId`, {
                  initialValue: row.product._id,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(<InputNumber min={1} max={10} placeholder="ProductId" />)}
              </FormItem>
            </div>
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, row, i) => (
        <FormItem>
          {getFieldDecorator(`data[${i}].quantity`, {
            initialValue: quantity,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<InputNumber min={1} max={10} placeholder="Quantity" />)}
        </FormItem>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (product, row, i) => {
        return (
          <>
            {subscription?.currency && subscription?.currency.toUpperCase()}{" "}
            {product}
          </>
        );
      },
    },
    {
      title: "Replace with",
      dataIndex: "product",
      key: "replasewith",
      render: (product, row, i) => (
        <FormItem className="product-replace">
          {getFieldDecorator(`data[${i}].newProduct`, {
            initialValue: undefined,
            rules: [
              {
                required: false,
                message: "Required",
              },
            ],
          })(
            <Select
              className="table-item"
              placeholder="Select product to replace with"
              onFocus={() =>
                handleFetchProductsByCategory(
                  getSafely(() => product?._id, null)
                )
              }
            >
              {productListByCategory &&
                productListByCategory.map((product) => (
                  <Option value={product._id} key={product._id}>
                    {product.name}
                  </Option>
                ))}
            </Select>
          )}
        </FormItem>
      ),
    },
    {
      title: "",
      dataIndex: "delete",
      key: "action",
      render: (quantity, row, i) => (
        <Button type="danger" shape="circle" onClick={() => deleteProduct(i)}>
          <img src={BinIcon} className="m-auto" alt="sort" />
        </Button>
      ),
    },
  ];
};

class CustomerSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "",
      email: "",
      name: "",
      subscription: {},
      noOfProducts: 4,
      productListByCategory: [],
      showDeleteModal: false,
      selectedProductIndex: 0,
      showAddProductModal: false,
      addSelectedProduct: undefined,
      showShippingAddressForm: false,
      showBillingAddressForm: false,
      cancelModalVisible: false,
      cancelSubscriptionText: "",
      customerId: undefined,
      customerSubscriptionId: undefined,
      currentSubscriptionPeriodValue: null,
      newSubscriptionPeriodValue: null,
      rescheduleSubscriptionDate: null,
      subscriptionHistoryLogs: [],
    };
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
    this.handleFetchProductsByCategory =
      this.handleFetchProductsByCategory.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.getNewTotal = this.getNewTotal.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAllProductForAdd = this.getAllProductForAdd.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const customerId = params.cusId;
    const customerSubscriptionId = params.subId;
    this.setState({
      customerSubscriptionId,
      customerId,
    });
    const userType = localStorage.getItem("userType");
    this.setState({
      userType,
    });
    if (customerId) {
      this.props.fetchCustomer(customerId).then((res) => {
        if (res) {
          this.setState({
            email: res.email,
            name: `${res.firstName} ${res.lastName}`,
          });
        }
      });
    }
    if (customerSubscriptionId) {
      this.props
        .fetchCustomerSubsciption(customerSubscriptionId)
        .then((res) => {
          if (res) {
            this.setState({
              subscription: JSON.parse(JSON.stringify(res)),
              currentSubscriptionPeriodValue: res.period.value,
            });
          }
        });
      getSubscriptionHistory(customerSubscriptionId).then((res) => {
        console.log(res);
        if (res) {
          this.setState({ subscriptionHistoryLogs: res });
        }
      });
    }

    this.props.fetchAllProducts();
  }

  async handleFetchProductsByCategory(productId) {
    try {
      this.setState({
        productListByCategory: [],
      });
      const productObj = await getProductById(productId);
      const productCategoryId = productObj.category.id;
      const productListByCategory = await getProductsList(
        `category.id=${productCategoryId}`
      );
      const { subscription } = this.state;
      let itemsInCategory = [...productListByCategory.result.result];
      itemsInCategory = itemsInCategory.filter(function (item) {
        for (var subscriptionItem of subscription.items) {
          if (item.id === subscriptionItem.product._id) return false;
        }
        return true;
      });

      this.setState({
        productListByCategory: itemsInCategory,
      });
    } catch (err) {
      message.error("Oops! Something went wrong while fetching products list");
    }
  }

  showDeleteConfirm() {
    const { subscription, selectedProductIndex } = this.state;
    confirm({
      title: `Do you want to remove ${subscription.items[selectedProductIndex].product.name} from this subscription?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        subscription.items.splice(selectedProductIndex, 1);
        this.setState({
          subscription: JSON.parse(JSON.stringify(subscription)),
        });
      },
      onCancel: () => {
        this.setState({
          selectedProductIndex: 0,
        });
      },
    });
  }

  deleteProduct(index) {
    this.setState(
      {
        selectedProductIndex: index,
      },
      () => this.showDeleteConfirm()
    );
  }

  addProduct() {
    const { allProducts } = this.props;
    const { subscription } = this.state;
    const product = allProducts.find(
      (x) => x.id == this.state.addSelectedProduct
    );
    const price = subscription.applyLegacyPrice
      ? product.legacyPrices.find(
          (x) =>
            x.currency.code.toLowerCase() ===
            subscription?.currency.toLowerCase()
        )
      : product.prices.find(
          (x) =>
            x.currency.code.toLowerCase() ===
            subscription?.currency.toLowerCase()
        );

    this.state.subscription.items.push({
      isSubscribed: true,
      price: `${price.price}`,
      priceAfterDiscount: price.price,
      product: {
        bannerImage: product.bannerImage,
        category: product.category,
        id: product.id,
        _id: product.id,
        mainImage: product.mainImage,
        name: product.name,
        secondaryImage: product.secondaryImage,
        shippingMetadata: product.shippingMetadata,
      },
      quantity: 1,
      recommendationType: product.category.name,
    });

    this.setState({
      subscription: JSON.parse(JSON.stringify(this.state.subscription)),
      showAddProductModal: false,
      addSelectedProduct: undefined,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { subscription } = this.state;
    const { allProducts } = this.props;
    message.info("Updating subscription now, please wait");
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const items = subscription.items.map((x) => {
          let item = values.data.find((i) => x.product._id == i.productId);
          if (item && item.newProduct) {
            const product = allProducts.find((y) => y.id === item.newProduct);
            const price = subscription.applyLegacyPrice
              ? product.legacyPrices.find(
                  (x) =>
                    x.currency.code.toLowerCase() ===
                    subscription?.currency.toLowerCase()
                )
              : product.prices.find(
                  (x) =>
                    x.currency.code.toLowerCase() ===
                    subscription?.currency.toLowerCase()
                );
            return {
              isSubscribed: true,
              price: `${price.price}`,
              priceAfterDiscount: price.price,
              product: product.id,
              quantity: item.quantity,
              recommendationType: product.category.name,
            };
          } else {
            return { ...x, product: x.product._id, quantity: item?.quantity };
          }
        });
        try {
          await updateSubscriptionProduct(this.state.customerSubscriptionId, {
            products: items,
          });
          message.success("Subscription updated!");
          this.componentDidMount();
        } catch (error) {
          message.error("Failed to update the subscription. Please try again.");
        }
      }
    });
  }

  handleCancelOk = async () => {
    message.info("Updating subscription now, please wait");
    const payload = {
      isActive: false,
      cancelSubReason: this.state.cancelSubscriptionText,
    };
    if (this.state.cancelSubscriptionText) {
      try {
        const result = await cancelSubscription(
          this.state.customerSubscriptionId,
          payload
        );
        this.componentDidMount();
        if (result) {
          this.setState({
            cancelModalVisible: false,
            cancelSubscriptionText: "",
          });
        }
        message.success("Subscription Cancelled Successfully");
      } catch (error) {
        message.error("Failed to update the subscription. Please try again.");
      }
    }
  };

  handlePause = async (isPaused) => {
    try {
      message.info("Updating subscription now, please wait");
      await skipSubscription(this.state.customerSubscriptionId, {
        isPaused,
      });
      if (isPaused) {
        message.success("Subscription Paused Successfully");
      } else {
        message.success("Subscription Activated Successfully");
      }
      this.componentDidMount();
    } catch (error) {
      message.error("Failed to update the subscription. Please try again.");
    }
  };

  updateSubscriptionTime = async () => {
    try {
      message.info("Updating subscription now, please wait");
      this.state.newSubscriptionPeriodValue &&
        (await editSubscription(this.state.customerSubscriptionId, {
          subscriptionPeriod: {
            value: parseInt(this.state.newSubscriptionPeriodValue),
            frequency: "days",
          },
        }));
      this.state.rescheduleSubscriptionDate &&
        (await skipSubscription(this.state.customerSubscriptionId, {
          skipSubscriptionPeriod: {
            date: this.state.rescheduleSubscriptionDate,
          },
          productsToChange: {},
        }));
      message.success("Subscription Schedule Updated!");
      this.componentDidMount();
    } catch (error) {
      message.error("Failed to update the subscription. Please try again.");
    }
  };

  getAllProductForAdd() {
    const { allProducts, form } = this.props;
    let { data } = form.getFieldsValue();
    if (!data) {
      data = [];
    }
    if (allProducts && data) {
      return allProducts.reduce((acc, x) => {
        const item = data.find(
          (d) => x.id === d.newProduct || x.id === d.productId
        );
        if (!item && x.type === "active") {
          acc.push(x);
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  }

  getNewTotal() {
    const { subscription } = this.state;
    const { allProducts } = this.props;
    const { data } = this.props.form.getFieldsValue();
    if (data && Object.keys(subscription).length && allProducts.length) {
      const total = data.reduce((acc, x) => {
        const product = allProducts.find((y) => {
          if (x.newProduct !== undefined) {
            return y.id === x.newProduct;
          } else {
            return y.id === x.productId;
          }
        });
        if (product) {
          const price = subscription.applyLegacyPrice
            ? product.legacyPrices.find(
                (x) =>
                  x.currency.code.toLowerCase() ===
                  subscription?.currency.toLowerCase()
              )
            : product.prices.find(
                (x) =>
                  x.currency.code.toLowerCase() ===
                  subscription?.currency.toLowerCase()
              );
          return price.price * x.quantity + acc;
        } else {
          return acc;
        }
      }, 0);
      return Math.round(total * 0.85).toFixed(2);
    }
  }

  render() {
    const {
      name,
      email,
      subscription,
      productListByCategory,
      showAddProductModal,
    } = this.state;

    const {
      form: { getFieldDecorator },
    } = this.props;
    const columns = getColumns({
      getFieldDecorator,
      productListByCategory,
      handleFetchProductsByCategory: this.handleFetchProductsByCategory,
      deleteProduct: this.deleteProduct,
      subscription: subscription,
    });

    return (
      <>
        {Object.keys(subscription).length !== 0 && (
          <Layout className="customer-edit-subscription-page">
            <Row>
              <Col>
                <div
                  className="pointer"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <div className="flex justify-start items-center cursor-pointer">
                    <div>
                      <Icon className="mr-4" type="left" />
                    </div>
                    <div className="text-14 pb-2">{name}</div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <div className="flex mb-20 mt-20">
                  <div className="theme-color semibold text-30 mr-10">
                    Active Subscription
                  </div>
                  <div className="flex items-center">
                    {subscription &&
                      moment(subscription?.createdAt).format(
                        "MMMM DD, YYYY [at] HH:MM A"
                      )}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                {subscription.isActive && (
                  <div className="flex justify-end mb-20 mt-20">
                    {subscription?.isPaused ? (
                      <Button
                        type="primary"
                        onClick={() => this.handlePause(false)}
                      >
                        <Icon type="play-circle" />
                        Active Subscription
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#ffc069",
                          borderColor: "transparent",
                        }}
                        onClick={() => this.handlePause(true)}
                      >
                        <Icon type="pause-circle" />
                        Pause Subscription
                      </Button>
                    )}
                    <Button
                      type="danger"
                      className="ml-20"
                      onClick={() =>
                        this.setState({
                          cancelModalVisible: true,
                        })
                      }
                    >
                      <Icon type="close-circle" />
                      Cancel Subscription
                    </Button>
                  </div>
                )}
              </Col>
            </Row>

            <Row gutter={[20]}>
              <Col span={15}>
                <Card>
                  <Form onSubmit={this.handleSubmit}>
                    <div className="flex justify-between">
                      <p className="semibold text-18 text-black m0">
                        Edit Subscription
                      </p>
                      <p className="mb-12 text-12 m0">
                        Order Date:{" "}
                        {subscription &&
                          moment(parseInt(subscription?.nextFulfilment)).format(
                            "MMM DD, YYYY [at] hh:mm a"
                          )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="semibold text-18 text-black">
                        Subscription - #{subscription?.shortId}
                      </p>
                      <p className="semibold text-18 text-black">
                        Current Total:{" "}
                        {subscription?.currency &&
                          subscription?.currency.toUpperCase()}{" "}
                        {parseInt(subscription?.chargedPrice).toFixed(2)}
                      </p>
                    </div>

                    {subscription?.items && (
                      <Table
                        className="subscription-item-table"
                        dataSource={subscription?.items}
                        columns={columns}
                        pagination={false}
                      />
                    )}
                    <div className="flex justify-between items-center mt-12">
                      <div>
                        <Button
                          onClick={() =>
                            this.setState({
                              showAddProductModal: true,
                            })
                          }
                          disabled={!subscription.isActive}
                        >
                          Add Product
                        </Button>
                      </div>

                      <div>
                        <p className="semibold text-18 text-black m0">
                          New Total:{" "}
                          {subscription?.currency &&
                            subscription?.currency.toUpperCase()}{" "}
                          {subscription.totalPrice}
                        </p>
                      </div>

                      <div>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={!subscription.isActive}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Card>
                <Card className="mt-20imp">
                  <p className="semibold text-18 text-black m0 mb-10">
                    Payment Details
                  </p>
                  <div className="flex justify-between border-bottom mt-10 pb-5">
                    <div>
                      <p className="mb-5">Card Expiry Date</p>
                      <p className="mb-5">Subtotal</p>
                      <p className="mb-5">Subscription Discount</p>
                      <p className="mb-5">
                        Promotion (
                        {subscription?.promotion &&
                          subscription?.promotion?.code}
                        )
                      </p>
                    </div>
                    <div>
                      <p className="mb-5">
                        {subscription && subscription.cardExpiryDate
                          ? subscription.cardExpiryDate
                          : "-"}
                      </p>

                      <p className="mb-5">
                        {subscription?.items ? subscription?.items.length : "-"}{" "}
                        items
                      </p>
                      <p className="mb-5">15% </p>
                    </div>
                    <div>
                      <p className="mb-5">
                        {subscription &&
                        subscription?.cardExpiryDate?.length > 0 &&
                        subscription.cardDetails.brand
                          ? subscription.cardDetails.brand
                          : "-"}
                      </p>
                      <p className="mb-5">
                        ${parseInt(subscription?.totalPrice).toFixed(2)}
                      </p>
                      <p className="mb-5">
                        ${subscription.subscriptionDiscount}
                      </p>
                      <p className="mb-5">${subscription.promoDiscount}</p>
                    </div>
                  </div>
                  <div className="flex justify-between mt-10">
                    <p className="semibold text-18 text-black m0">Total</p>
                    <p className="semibold text-18 text-black m0">
                      ${parseInt(subscription?.chargedPrice).toFixed(2)}
                    </p>
                  </div>
                </Card>
              </Col>
              <Col span={9}>
                <Card>
                  <p className="semibold text-18 text-black mb-0">Customer</p>
                  <div className="mt-10 border-bottom">
                    <Link
                      to={`/customer/edit/${subscription?.user}`}
                      className="semibold pad-bottom capitalize cust-name text-link-imp"
                    >
                      {name}
                    </Link>
                  </div>
                  <div className="mt-10 border-bottom pb-10">
                    <p className="text-18 text-black semibold mb-0 ">
                      Contact Information
                    </p>
                    <strong className="semibold text-link text-14">
                      {email}
                    </strong>
                  </div>
                  <div className="mt-10 border-bottom pb-10">
                    <p className="semibold text-18 text-black mb-0 mt-10">
                      Last Order Trial Status
                    </p>
                    <p className="mb-12 text-12 m0">
                      Last Order Trial Date:{" "}
                      {(subscription &&
                        subscription?.lastFulfilment &&
                        moment(parseInt(subscription?.lastFulfilment)).format(
                          "MMM DD, YYYY "
                        )) ||
                        "Not Charged till date"}
                    </p>
                    <p className="mb-12 text-12 m0">
                      Payment Status:{" "}
                      {(subscription &&
                        subscription?.lastFulfilmentDetails &&
                        subscription?.lastFulfilmentDetails
                          ?.lastPaymentStatus) ||
                        "Not Charged"}
                    </p>
                    <p className="mb-12 text-12 m0">
                      {subscription &&
                        subscription?.lastFulfilmentDetails &&
                        subscription?.lastFulfilmentDetails?.lastPaymentError}
                    </p>
                    <div className="flex justify-between mt-14"></div>
                  </div>
                  <div className="mt-10 border-bottom">
                    <div className="flex item-center justify-between">
                      <p className="text-18 text-black semibold mb-0">
                        Shipping Address
                      </p>
                      {subscription.isActive && (
                        <div
                          onClick={() => {
                            this.setState((prevState) => {
                              return {
                                showShippingAddressForm:
                                  !prevState.showShippingAddressForm,
                              };
                            });
                          }}
                          style={{ color: "#363874", fontSize: "14px" }}
                          className="regular cursor-pointer"
                        >
                          Edit
                        </div>
                      )}
                    </div>
                    {subscription?.shippingAddress && (
                      <CustomerAddress
                        showAddressType={true}
                        addressContent={subscription?.shippingAddress}
                      />
                    )}
                    {this.state.showShippingAddressForm && (
                      <CreateAddressForm
                        activeAddress={subscription?.shippingAddress}
                        customerId={subscription?.user}
                        updateAddress={this.props.updateAddress}
                        activeAddressId={
                          subscription ? subscription.shippingAddress._id : ""
                        }
                        onAddressCreation={() => {
                          this.setState({
                            showShippingAddressForm: false,
                          });
                          this.componentDidMount();
                        }}
                        showPrimaryKey={true}
                      />
                    )}
                  </div>
                  <div className="mt-10 ">
                    <div className="flex item-center justify-between">
                      <p className="text-18 text-black semibold mb-0">
                        Billing Address
                      </p>
                      {subscription.isActive && (
                        <div
                          onClick={() => {
                            this.setState((prevState) => {
                              return {
                                showBillingAddressForm:
                                  !prevState.showBillingAddressForm,
                              };
                            });
                          }}
                          style={{ color: "#363874", fontSize: "14px" }}
                          className="regular cursor-pointer"
                        >
                          Edit
                        </div>
                      )}
                    </div>
                    {subscription?.billingAddress && (
                      <CustomerAddress
                        showAddressType={true}
                        addressContent={subscription?.billingAddress}
                      />
                    )}
                    {this.state.showBillingAddressForm && (
                      <CreateAddressForm
                        activeAddress={subscription?.billingAddress}
                        customerId={subscription?.user}
                        updateAddress={this.props.updateAddress}
                        activeAddressId={
                          subscription ? subscription.billingAddress._id : ""
                        }
                        onAddressCreation={() => {
                          this.setState({
                            showBillingAddressForm: false,
                          });
                          this.componentDidMount();
                        }}
                        showPrimaryKey={true}
                      />
                    )}
                  </div>
                </Card>
                <Card className="mt-20imp">
                  <p className="semibold text-18 text-black mb-0">
                    Subscription Reschedule
                  </p>
                  <table className="subscription-reschedule-table">
                    <tr className="border_bottom">
                      <td>
                        <p className="text-black text-14 semibold m0">
                          Next Date:
                        </p>
                      </td>
                      <td className="prl-7">
                        <p className="text-black text-14 semibold flex-1 m0">
                          {moment(parseInt(subscription.nextFulfilment)).format(
                            "MMM DD, YYYY"
                          )}
                        </p>
                      </td>
                      <td>
                        <DatePicker
                          className="w-100"
                          onChange={(val, dateString) => {
                            if (val) {
                              this.setState({
                                rescheduleSubscriptionDate: val,
                              });
                            }
                          }}
                          disabledDate={(current) =>
                            current && current < moment().endOf("day")
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="text-black text-14 semibold m0">
                          Frequency:
                        </p>
                      </td>
                      <td className="prl-7">
                        <p className="text-black text-14 semibold flex-1 m0">
                          {subscription.period.value / 30} Months
                        </p>
                      </td>
                      <td>
                        <Select
                          defaultValue={
                            this.state.currentSubscriptionPeriodValue
                          }
                          onChange={(value) =>
                            this.setState({
                              newSubscriptionPeriodValue: value,
                            })
                          }
                        >
                          <Option value={30}>1 Month</Option>
                          <Option value={60}>2 Months</Option>
                          <Option value={90}>3 Months</Option>
                        </Select>
                      </td>
                    </tr>
                  </table>
                  <div className="flex justify-end">
                    <Button
                      type="primary"
                      onClick={this.updateSubscriptionTime}
                      disabled={!subscription.isActive}
                    >
                      Save
                    </Button>
                  </div>
                </Card>
                {subscription?.paymentMethod?.includes("stripe") && (
                  <Card className="mt-20imp">
                    <p className="semibold text-18 text-black mb-0">
                      Update Card Link
                    </p>
                    <div className="flex justify-between mt-14">
                      <Input
                        value={`${API.WEB_URL}/update-cards?user=${this.state.customerId}&subscription=${this.state.customerSubscriptionId}`}
                        readOnly
                      />
                      <CopyToClipboard
                        text={`${API.WEB_URL}/update-cards?user=${this.state.customerId}&subscription=${this.state.customerSubscriptionId}`}
                        onCopy={() => message.success("Copied!")}
                      >
                        <Button
                          type="primary"
                          className="ml-5 copy-to-clipboard-button"
                        >
                          Copy link
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </Card>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={15} style={{ paddingBottom: "25px" }}>
                <div style={{ background: "#ececec" }}>
                  <Card className="mt-20imp" bordered={true}>
                    <HistoryBlock
                      results={this.state.subscriptionHistoryLogs}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
          </Layout>
        )}
        <Modal
          title="Add Product"
          visible={showAddProductModal}
          onOk={this.addProduct}
          onCancel={() =>
            this.setState({
              showAddProductModal: false,
            })
          }
        >
          <Select
            placeholder="Select"
            onChange={(value) =>
              this.setState({
                addSelectedProduct: value,
              })
            }
          >
            {this.getAllProductForAdd().map((x) => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </Modal>

        <Modal
          title="Cancel Subscription"
          visible={this.state.cancelModalVisible}
          onOk={this.handleCancelOk}
          onCancel={() =>
            this.setState({
              cancelModalVisible: false,
              cancelSubscriptionText: "",
            })
          }
        >
          <div className=" nav-container justify-between">
            <div className="flex"></div>
            Please, help us process your request by telling us why you are
            cancelling (mandatory)
            <TextArea
              rows={4}
              value={this.state.cancelSubscriptionText}
              onChange={(e) =>
                this.setState({
                  cancelSubscriptionText: e.target.value,
                })
              }
              style={{ marginTop: "5px" }}
            />
            <div className="flex"></div>
            <div style={{ marginTop: "15px" }}></div>
          </div>
        </Modal>
      </>
    );
  }
}

const CustomerSubscriptionForm = Form.create({
  name: "edit_customersubscription_form",
})(CustomerSubscription);

function mapStateToProps(state) {
  const { Customer, Products } = state;
  return {
    allProducts: Products.products,
    activeCustomer: Customer.activeCustomer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomer: bindActionCreators(actions.getCustomer, dispatch),
    fetchCustomerSubsciption: bindActionCreators(
      orderActions.getSubscription,
      dispatch
    ),
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    updateAddress: bindActionCreators(customerActions.updateAddress, dispatch),
    fetchCustomerAddressList: bindActionCreators(
      customerActions.getCustomerAddressList,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerSubscriptionForm);
