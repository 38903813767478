import React, { memo, useState, Fragment, useMemo } from "react";
import { Icon, message } from "antd";
import { CSVLink } from "react-csv";

import { formatShippingExport, isEmpty, mapOrderData } from "utils/helpers";
import ExportModal from "./ExportModal";

const ALL_EXPORT = "ALL_EXPORT";
const SHIPPING_EXPORT = "SHIPPING_EXPORT";

const headers = [
  { label: "Order Number", key: "order_number" },
  { label: "Payment Status", key: "payment_status" },
  { label: "Name", key: "name" },
  { label: "Email", key: "email" },
  { label: "Order Placement Date", key: "order_placement_date" },
  { label: "Order Placement Time", key: "order_placement_time" },
  { label: "Address Line 1", key: "address_1" },
  { label: "Shipping Name", key: "shipping_name" },
  { label: "Address Line 2", key: "address_2" },
  { label: "City", key: "city" },
  { label: "ZIP/Postal Code", key: "postal_code" },
  { label: "State/Province", key: "state" },
  { label: "Country", key: "country" },
  { label: "Phone Country Code", key: "phone_country_code" },
  { label: "Phone Number", key: "phone_number" },
  { label: "Total Weight", key: "total_weight" },
  { label: "Declared Value Currency", key: "declared_value_currency" },
  { label: "Declared Value", key: "declared_value" },
];

const OrdersHeader = ({
  startDate,
  endDate,
  fetchAllOrders,
  ordersList,
  selectedOrderIds,
  csvData,
  allHeaders,
  csvRef,
  shoppingExportCsvRef,
  pageName,
  fetchAllAbandonedCartOrders,
  selectedCarts,
  pageNo,
  totalCount,
  recordsPerPage,
  filter,
  allowShippingExport,
  allowOrderExport,
  toggleExportingDataState,
}) => {
  const [showExportModal, toggleExportModal] = useState(false);
  const [exportModalLoading, toggleExportModalLoading] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(20);
  const [exportMode, setExportMode] = useState(0);
  const [exportType, setExportType] = useState(null);
  const [orders, setOrder] = useState([]);

  const getQueryString = (selectedCarts) => {
    let query = "";
    if (selectedCarts && selectedCarts.length > 0) {
      selectedCarts.map((x, index) => {
        if (x.key === "all") {
          return query;
        }
        if ((index = 0)) {
          query = "status=" + x.key;
        } else {
          query += "&status=" + x.key;
        }
      });
    }
    return query;
  };

  const transformsCartStatusName = (csvData) => {
    if (csvData && csvData.length > 0) {
      csvData.map((x) => {
        if (x.status == "init") {
          x.status = "Viewed Results";
        } else if (x.status == "checkout") {
          x.status = "Initiated Checkout";
        } else if (x.status == "payment") {
          x.status = "Proceeded to payment";
        }
      });
    }
    return csvData;
  };

  const openAllExportLink = () => {
    csvRef.current && csvRef.current.link.click();
  };

  const openShippingExportLink = () => {
    shoppingExportCsvRef.current && shoppingExportCsvRef.current.link.click();
  };

  let shippingExportData = useMemo(() => {
    const data =
      exportMode === 1
        ? orders.filter((data) => selectedOrderIds.includes(data.id))
        : orders;
    return formatShippingExport(data);
  }, [orders, selectedOrderIds, exportMode]);

  let allExportData = useMemo(() => {
    if (selectedOrderIds.length > 0 && exportMode === 1) {
      return orders.filter((data) => selectedOrderIds.includes(data.id));
    }

    return orders;
  }, [selectedOrderIds, orders, exportMode]);

  let allAbandonedExportData = useMemo(() => {
    if (selectedOrderIds.length > 0 && exportMode === 1) {
      return csvData.filter((data) => selectedOrderIds.includes(data.id));
    }
    if (csvData && csvData.length > 0) {
      csvData = transformsCartStatusName(csvData);
    }
    return csvData;
  }, [selectedOrderIds, csvData, exportMode]);

  const onExport = (type = ALL_EXPORT) => {
    setExportType(type);
    toggleExportModal(true);
    toggleExportingDataState( true);
  };

  const onChangeInput = (value) => {
    setNumberOfRows(value);
  };

  const getPageName = (pageName) => {
    let name = pageName;
    if (pageName == "Completed Order") {
      name = "Completed Orders";
    } else if (pageName == "Incomplete Order") {
      name = "Incomplete Orders";
    } else if (pageName == "Abandoned Cart") {
      name = "Abandoned Carts";
    } else if (pageName == "Ready to Pack") {
      name = "Ready to Pack";
    }

    return name;
  };

  const onClickOk = async () => {
    let query = "";
    if (pageName === "Completed Order" || pageName === "Incomplete Order") {
      if (pageName === "Completed Order") {
        query = "status=2&status=3&status=4";
      } else {
        query = "status=0&status=1&status=-2&status=-1";
      }
      toggleExportModalLoading(true);
      let rowCount = pageNo ? numberOfRows * pageNo : numberOfRows;
      fetchAllOrders(filter, 0, rowCount, startDate, endDate, (result) => {
        toggleExportModal(false);
        toggleExportModalLoading(false);
        toggleExportingDataState(false);
        if (exportType === ALL_EXPORT) {
          shippingExportData = [];
          setOrder(mapOrderData(result));
          openAllExportLink();          
        } else {
          allExportData = [];
          setOrder(result);
          openShippingExportLink();
        }
      });
    } else if (pageName === "Abandoned Cart") {
      query = getQueryString(selectedCarts);
      toggleExportModalLoading(true);
      let res = await fetchAllAbandonedCartOrders(
        query,
        0,
        numberOfRows,
        startDate,
        endDate
      );
      if (res) {
        toggleExportModal(false);
        toggleExportModalLoading(false);
        if (exportType === ALL_EXPORT) {
          //setOrder(mapOrderData(res));
          openAllExportLink();
        } else {
          openShippingExportLink();
        }
      }
    }
  };

  const closeModal = () => {
    toggleExportModal(false);
    setNumberOfRows(20);
    shippingExportData = [];
    allExportData = [];
  };

  return (
    <Fragment>
      <div className="flex flex-col">
        <div className="theme-color semibold text-30 mr-14">
          {getPageName(pageName)}
        </div>
        {!isEmpty(ordersList) && (
          <div className="mt-10">
            <div
              style={{
                display: `${allowOrderExport == "yes" ? "" : "none"}`,
              }}
              className="flex items-center export-btn cursor-pointer"
              onClick={() => onExport(ALL_EXPORT)}
            >
              <Icon type="export" className="mr-6" />
              <div>Export Everything</div>
            </div>
            <div
              style={{
                display: `${allowShippingExport == "yes" ? "" : "none"}`,
              }}
              className="flex items-center export-btn cursor-pointer"
              onClick={() => onExport(SHIPPING_EXPORT)}
            >
              <Icon type="car" className="mr-6" />
              <div>Export Shipping Info</div>
            </div>
          </div>
        )}
      </div>
      <ExportModal
        showExportModal={showExportModal}
        exportModalLoading={exportModalLoading}
        toggleExportModal={toggleExportModal}
        onClickOk={onClickOk}
        numberOfRows={numberOfRows}
        onChangeInput={onChangeInput}
        exportMode={exportMode}
        setExportMode={setExportMode}
        selectedOrderIds={selectedOrderIds}
        totalCount={totalCount}
        closeModal={closeModal}
      />
      <CSVLink
        headers={headers}
        data={shippingExportData}
        ref={shoppingExportCsvRef}
        style={{ display: "none" }}
        filename="shipping-orders.csv"
      />
      <CSVLink
        data={
          pageName == "Abandoned Cart" ? allAbandonedExportData : allExportData
        }
        headers={allHeaders}
        ref={csvRef}
        style={{ display: "none" }}
        filename="orders.csv"
      />
    </Fragment>
  );
};

export default memo(OrdersHeader);
