import axios from "axios";
import API from "./apiUrl";
import { logout } from "utils/helpers";
import { message } from "antd";
const _authorizationHeaders = () => {
  return {
    Authorization: window.localStorage.getItem("authToken"),
    "Content-Type": "application/json",
  };
};

// interceptor to handle 401 error
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status === 401) {
//       logout();
//     }
//     return error;
//   }
// );

const handleError = (err) => {
  console.log(`Api call error in services -> request.js : `, err);
  // message.error(`${err.status} ${err.statusText}`);
  // notification.open({
  //   message: `${err.status}`,
  //   description:
  //     `${err.statusText}`,
  // });
  if (err.status) {
    // logout if unauthorized
    if (err.status === 401) {
      logout();
      return;
    }
  }
};

export const getRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios
      .get(API.API_URL + url, {
        headers: Object.assign({}, headers),
      })
      .catch((err) => {
        handleError(err.request);
        return null;
      });
    if (res) {
      return res.data.result ? res.data.result : res.data;
    }
  } catch (err) {
    handleError(err);
  }
};

export const getListRequest = async (
  url,
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios
      .get(API.API_URL + url, {
        headers: Object.assign({}, headers),
      })
      .catch((err) => {
        handleError(err.request);
        return null;
      });
    if (res) {
      return res.data.result ? res.data : res.data;
    }
  } catch (err) {
    handleError(err);
  }
};

export const postRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API.API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
    }).catch((err) => {
      return Promise.reject(err);
    });
    if (res) {
      return res.data.result ? res.data.result : res.data;
    }
  } catch (err) {
    err.response && err.response.data && typeof err.response.data.error.message ==="string" && message.error(
      err.response.data.error.message
    );
    return Promise.reject(err.response.data);
  }
};

export const putRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API.API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
    }).catch((err) => {
      handleError(err.request);
      return null;
    });
    if (res) {
      return res.data.result ? res.data.result : res.data;
    }
  } catch (err) {
    handleError(err);
  }
};

export const patchRequest = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: API.API_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      data,
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteRequest = async (url, headers = _authorizationHeaders()) => {
  try {
    const res = await axios({
      url: API.API_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
    }).catch((err) => {
      handleError(err.request);
      return null;
    });
    if (res) {
      return res.data.result ? res.data.result : res.data;
    }
  } catch (err) {
    handleError(err);
  }
};

export const Api = {
  deleteRequest,
  getListRequest,
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
};
