import React from "react";
import "./invoiceSheet.scss";
import logo from "static/img/icon/logo-res1.png";
import moment from "moment";

function InvoiceSheet(props) {
  const { data } = props;

  const getAddress = (address) => {
    let addressArray = [];
    if (address.street) {
      addressArray.push(address.street);
    }
    if (address.apartment) {
      addressArray.push(address.apartment);
    }

    if (address.city) {
      addressArray.push(address.city);
    }
    if (address.state) {
      addressArray.push(address.state);
    }
    if (address.country) {
      addressArray.push(address.country);
    }
    return addressArray.join(", ");
  };

  const getPhoneNumber = (address) => {
    const { phone } = address;
    return phone ? phone?.isdCode + " " + phone.phone : "";
  };

  const getChargePrice = (data) => {
    if (data?.tax_details?.tax_amount) {
      return (
        Number(data.chargedPrice) + Number(data?.tax_details?.tax_amount / 100)
      );
    } else {
      return data.chargedPrice;
    }
  };

  return (
    <section className="sheet padding-1020mm">
      {/* <!-- Write HTML just like a web page --> */}
      <div className="mb-2 text-xs text-center regular theme-color">
        Commercial Invoice
      </div>
      <article className="invoice-header-wrapper">
        <img alt="invoice" className="logo-invoice" src={logo}></img>
        <h1 className="text-center theme-color text-18 mw regular">
          Yours Personalised Skincare
        </h1>
      </article>
      <hr className="invoice-border"></hr>
      <div className="relative flex justify-between wrapper-hr">
        <hr className="invoice-border-ab"></hr>
        <div style={{ padding: "0 15px" }}>
          <div className="text-xs bold theme-color">
            Date:{" "}
            <span className="text-xs regular theme-color">
              {moment(data.createdAt).format("MMMM DD, YYYY")}
            </span>
          </div>
          <div className="text-xs bold customer-invoice theme-color">
            Customer:
            <div className="text-xs regular address-invoice theme-color">
              {data.shippingAddress.firstName +
                " " +
                data.shippingAddress.lastName}{" "}
              <br />
              {getAddress(data.shippingAddress)} <br />
              {data.shippingAddress.phone && (
                <>Tel: {getPhoneNumber(data.shippingAddress)}</>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="text-xs bold theme-color">
            Receipt:{" "}
            <span className="text-xs regular theme-color">
              {`#${data.receiptId}`}
            </span>
          </div>
          <div className="text-xs bold customer-invoice theme-color">
            Sender:
            <div className="text-xs regular address-invoice theme-color">
              {data.shippingAddress.country == "United States"
                ? `Yours Personalised Skincare 600 N Broad Street Suite 5 # 647 Middletown, DE 19709`
                : `Yours Personalised Skincare 89A Tanjong Pagar Road, Singapore -
              088510 Tel: +65 82628624`}
            </div>
          </div>
        </div>
      </div>
      <hr className="invoice-border-next"></hr>
      <div style={{ padding: "0 15px" }} className="text-xs bold theme-color">
        Order Number:{" "}
        <span className="text-xs regular theme-color">#{data.shortId}</span>
      </div>
      <hr className="invoice-border number"></hr>
      <div style={{ padding: "0 15px" }} className="flex justify-between">
        <div className="text-xs bold product-in theme-color">Product</div>
        <div className="text-xs bold quantity-in theme-color">Quantity</div>
        <div className="text-xs bold price-in theme-color">Price</div>
      </div>
      {/* <hr className="invoice-border number"></hr> */}
      <hr className="invoice-border number"></hr>

      {data.items.map((item, index) => (
        <div
          key={index}
          style={{ padding: "0 15px" }}
          className="flex items-center justify-between"
        >
          <div className="product-in">
            <img
              src={item.product.mainImage.withoutHover.mediaUrl}
              className="productImg"
              alt="product"
            />
            <span
              style={{ paddingLeft: "15px" }}
              className="text-xs regular theme-color"
            >
              {item.product.name}
            </span>
          </div>
          <div className="text-xs theme quantity-in -color">
            {item.quantity}
          </div>

          <div className="text-xs theme-color price-in">
            {props.currency} {item.amount}
          </div>
        </div>
      ))}

      <hr className="invoice-border number"></hr>
      <div style={{ padding: "0 15px" }} className="flex sub-total-container">
        <div className="text-xs theme-color sub-total">
          Sub-total ( {data.items.length}{" "}
          {data.items.length > 1 ? "item" : "items"})
        </div>
        <div className="text-xs theme-color price-total">{`${props.currency} ${data.totalPrice}`}</div>
      </div>
      <div style={{ padding: "0 15px" }} className="flex sub-total-container">
        <div className="text-xs theme-color sub-total">Discount</div>
        <div className="text-xs theme-color price-total">{`${props.currency} ${(
          data.totalPrice +
          data.shipping -
          data.chargedPrice
        ).toFixed(2)}`}</div>
      </div>
      <div style={{ padding: "0 15px" }} className="flex sub-total-container">
        <div className="text-xs theme-color sub-total">Shipping</div>
        <div className="text-xs theme-color price-total">{`${props.currency} ${data.shipping}`}</div>
      </div>
      {(data?.tax_details?.tax_amount !== 0 ||
        data?.tax_details?.tax_amount !== null ||
        data?.tax_details?.tax_amount !== undefined) && (
        <div style={{ padding: "0 15px" }} className="flex sub-total-container">
          <div className="text-xs theme-color sub-total">Tax</div>
          <div className="text-xs theme-color price-total">{`${
            props.currency
          } ${data?.tax_details?.tax_amount / 100}`}</div>
        </div>
      )}
      <div className="flex total-amount">
        <div className="theme-color sub-total bold text-14">TOTAL</div>
        <div className="theme-color price-total bold text-14">{`${
          props.currency
        } ${getChargePrice(data)}`}</div>
      </div>
      <div className="text-center theme-color any-questions text regular text-10">
        If you have any questions, contact us at hello@lovefromyours.com or
        message us at: yours.sg/chat
      </div>
      <div className="text-center theme-color any-questions receiving regular text-10">
        You're receiving this email because you made a purchase at Yours
        Skinlabs Pte Ltd, which partners with Stripe to provide invoicing and
        payment processing.
      </div>
    </section>
  );
}

export default InvoiceSheet;
