import React, { Component } from "react";
import "./listAdmin.scss";
import {
  Table,
  Button,
  Icon,
  Input,
  Menu,
  Dropdown,
  Badge,
  Modal,
  InputNumber,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "utils/helpers";
import * as actions from "actions/admin";
import { CSVLink } from "react-csv";
const { Search } = Input;
const CUSTOMER_ADVANCED_SEARCH_OPTIONS = [
  {
    key: "firstName",
    label: "First Name",
  },
  {
    key: "_id",
    label: "Admin ID",
  },
  {
    key: "email",
    label: "Email",
  },
];

const customerTypeKeys = {
  basic: "Basic",
  admin: "Admin",
  packer: "Packer",
};
class listAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      sortOrder: 1,
      activeAdvSearch: CUSTOMER_ADVANCED_SEARCH_OPTIONS[0],
      activeStatusFilters: [],
      showExportModal:false,
      exportModalLoading:false,
      numberOfRows:200
    };
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    this.props.getSearchedAdmin("", this.state.numberOfRows);
  }
  onChangeInput = value => {
    this.setState({numberOfRows:value})
  }
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.props.getSearchedAdmin(
            `${this.state.activeAdvSearch.key}=${value}`,
            this.state.numberOfRows
          );
        } else {
          this.props.getSearchedAdmin("", this.state.numberOfRows);
        }
      }
    );
  }, 300);
  handleAdvSearchChange = (value) => {
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };
  // handleStatusFilter = (value) => {
  //   this.setState(
  //     {
  //       activeFilter: value,
  //     },
  //     () => {
  //       if (this.state.activeFilter !== "null") {
  //         this.props.getSearchedAdmin(`role=${value}`);
  //       } else {
  //         this.props.getSearchedAdmin();
  //       }
  //     }
  //   );
  // };
  handleStatusFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.getSearchedAdmin(`role=${value}`, this.state.numberOfRows);
      }
    );
  };
  handleStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeStatusFilters.indexOf(value) > -1) {
          newFilterList = state.activeStatusFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeStatusFilters, value];
        }
        return {
          activeStatusFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        // const filterQueryFulfillment = this.state.activeFulfillMentFilters
        //   .map((value, index) => {
        //     if (index === 0 && this.state.activeStatusFilters.length === 0) {
        //       return `fulfillmentStatus=${value}`;
        //     } else {
        //       return `&fulfillmentStatus=${value}`;
        //     }
        //   })
        //   .join("");
        this.props.getSearchedAdmin(filterQueryStatus, this.state.numberOfRows);
      }
    );
  };
  handleSort = (value) => {
    this.setState(
      (prevState) => {
        return {
          sortOrder: prevState.sortOrder === "1" ? "-1" : "1",
        };
      },
      () =>
        this.props.getSearchedAdmin(
          `orderBy=createdAt:${this.state.sortOrder}`,
          this.state.numberOfRows
        )
    );
  };
  // getDiscountStatus = (startDate, endDate) => {
  //   return moment().isBetween(moment(startDate), moment(endDate))
  //     ? "Active"
  //     : "Expired";
  // };
  // getDiscountDuration = (startDate, endDate) => {
  //   const start = isCurrentDate(startDate)
  //     ? "From Today"
  //     : moment(startDate).format("DD MMM");
  //   const end = isCurrentDate(endDate)
  //     ? "Till Today"
  //     : moment(endDate).format("DD MMM");
  //   return `${start} - ${end}`;
  // };
  render() {
    const columns = [
      {
        title: "First Name",
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
      },
      {
        title: "Admin ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 100,

        render: (admin) => {
          return (
            <div className="flex">
              <div
                onClick={(e) => {
                  const win = window.open(`/add-admin/${admin.id}`, "_blank");
                    win.focus();
                }}
              >
                Edit
              </div>
            </div>
          );
        },
      },
    ];
    let headers= columns.map(item=>{
      return {label:item.title,
         key:item.key}
     })
     headers=headers.filter(head=>head.key!=="action")
    const mappedList =
      this.props.adminList.length > 0 ?
      this.props.adminList.map((admin) => {
        return {
          firstName: admin.firstName,
          email: admin.email,
          role: customerTypeKeys[admin.role],
          id: admin.id,
        };
      }):[];
      const csvData= mappedList.map(
        (item)=>{
            return {...item}
          } 
        )

    // const menu = (
    //   <Menu onClick={(e) => this.handleStatusFilter(e.key)}>
    //     <Menu.Item key={null}>All</Menu.Item>
    //     <Menu.Item key="admin">Admin</Menu.Item>
    //     <Menu.Item key="basic">Basic</Menu.Item>
    //   </Menu>
    // );
    const menu = (
      <Menu
        onClick={(e) => this.handleStatusFilter("role", e.key)}
        selectedKeys={this.state.activeStatusFilters}
        multiple
        selectable
      >
        {Object.keys(customerTypeKeys).map((key) => {
          return <Menu.Item key={key}>{customerTypeKeys[key]}</Menu.Item>;
        })}
      </Menu>
    );
    const advSearch = (
      <Menu onClick={(e) => this.handleAdvSearchChange(e.key)}
      selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {CUSTOMER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <div className="admin-page-list" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div className="theme-color semibold text-30 mr-14">Admins</div>
          <div className="flex items-center cursor-pointer" 
          onClick={() => {
            this.setState({showExportModal:true})
            }}>
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>
        <div className="flex nav-container justify-between">
          <div className="sub-text-top-left theme-color">All</div>
          <div style={{ marginTop: "-14px" }}>
            <Button
              type="primary"
              onClick={() => {
                const win = window.open(`/add-admin`, "_blank");
                    win.focus();
               // this.props.history.push("/add-admin")
              }}
              style={{ marginTop: "-14px" }}
            >
              <Icon type="plus"></Icon>
              Add Admin
            </Button>
          </div>
        </div>

        <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
          <Search
            placeholder={`Search by ${this.state.activeAdvSearch.label}`}
            onSearch={(value) => this.handleSearch(value)}
            style={{ width: "70%", marginRight: "5px" }}
            onKeyUp={(e) => this.handleSearch(e.target.value)}
          />
          <Dropdown className="filter-search" overlay={advSearch}>
            <Button className="dropdown-btn">
              Advanced Search <Icon type="down" />
            </Button>
          </Dropdown>
          <Dropdown overlay={menu}>
            <Button
              className={`dropdown-btn ${
                this.state.activeStatusFilters.length > 0 && "filter-selected"
              }`}
              style={{ flex: 1, marginRight: "5px", marginLeft: "5px" }}
            >
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge
                  count={
                    this.state.activeStatusFilters.length>0
                      ? this.state.activeStatusFilters.length
                      : 0
                  }
                >
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Admin Type <Icon type="down" />
            </Button>
          </Dropdown>
          {/* <Button className="dropdown-btn"
            style={{ display: "flex", alignItems: "center",marginRight:'5px' }}
            onClick={this.handleSort}
          >
            <span className="mr-4">Sort</span>
            <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
          </Button> */}
          <Button
            className="dropdown-btn"
            onClick={() => {
              this.setState({
                activeStatusFilters: [],
              });
              this.props.getSearchedAdmin("", this.state.numberOfRows);
            }}
            style={{ marginRight: "5px", width: "140px" }}
          >
            Clear All Filters
          </Button>
        </div>
        <div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedList}
              bordered
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                  
                    // this.props.history.push(`/add-admin/${record.id}`);
                  },
                };
              }}
              size="small"
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={()=>{this.setState({showExportModal:false})}}
          onOk={async ()=>{
            this.setState({exportModalLoading:true})
            await this.props.getSearchedAdmin(undefined,this.state.numberOfRows);
            this.setState({exportModalLoading:false,showExportModal:false})
            this.csvRef.current && this.csvRef.current.link.click()
          }}
        >
          <InputNumber min={1} defaultValue={this.state.numberOfRows}  onChange={this.onChangeInput}/>
        </Modal>
        <CSVLink data={csvData} headers={headers} ref={this.csvRef} style={{display:"none"}}
          filename='admins.csv'/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Admin } = state;
  return {
    adminList: Admin.adminList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSearchedAdmin: bindActionCreators(actions.getSearchedAdmin, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(listAdmin);
