import React, { Component } from "react";
import "./refundOrder.scss";
import {
  Icon,
  Input,
  Button,
  Form,
  Row,
  InputNumber,
  Checkbox,
  message,
} from "antd";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/orders";
import * as productActions from "actions/products";
import { orderFullfillmentStatusMapping } from "utils/constants";
import { getSafely } from "utils/helpers";

class Refund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfProducts: 1,
      loading: false,
      subtotatAmount: 0,
      chosenRefundAmount: 0,
      numberOfItemsToBeRefunded: 0,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getOrder(this.props.match.params.id).then(async (res) => {
      if (res && !res.error) {
        let items = res.items.map((x) => ({
          ...x,
          amountAfterDiscount: x.amountAfterDiscount / x.quantity,
        }));
        await this.setState({
          noOfProducts: items.length,
          numberOfItemsToBeRefunded: items
            ? items.reduce((a, b) => a + (b.quantity || 0), 0)
            : 0,
          subtotatAmount: items
            ? items
                .reduce(
                  (a, b) => a + (b.quantity * b.amountAfterDiscount || 0),
                  0
                )
                .toFixed(2)
            : 0,
          items: items.map((item) => {
            return {
              quantity: item.quantity,
              amountAfterDiscount: item.amountAfterDiscount,
              amount: item.amount,
              product: item.product.id,
            };
          }),
        });
        let tax = 0
        if(res.tax_details?.tax_amount !== null) {
          tax = res.tax_details?.tax_amount/100;
        }
        this.props.form.setFieldsValue({
          amount:
            this.props.location.state &&
            this.props.location.state.maxRefundAmount >= 0 &&
            this.props.location.state.maxRefundAmount <
              this.state.subtotatAmount
              ? (Number(this.props.location.state.maxRefundAmount) + tax).toFixed(2)
              : (Number(this.state.subtotatAmount) + tax).toFixed(2),
        });
      }
    });
  }

  handleSubmit = () => {
    // if (this.state.numberOfItemsToBeRefunded < 1) {
    //   message.destroy();
    //   message.error("Select atleast 1 product");
    //   return;
    // }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.createRefund(values);
      }
    });
  };

  createRefund = (values) => {
    this.setState({ loading: true });
    values["orderId"] = this.props.match.params.id;
    values["items"] = this.state.items.filter((item) => item.quantity > 0 );
    this.props.createRefund(values).then((res) => {
      this.setState({ loading: false });
      if (res) {
        if (!res.error) {
          message.success("Refund created");
        }
      }
    });
  };

  getTax(price) {
    const { activeOrder } = this.props;
    let tax = 0;
    if(activeOrder?.tax_details?.tax_amount !== null) {
      tax = activeOrder?.tax_details?.tax_amount/100;
    }
    if(typeof price === 'string'){
      tax=(tax+Number(price)).toFixed(2)
    }
    return tax;
  }

  render() {
    const { activeOrder } = this.props;
    const { getFieldDecorator } = this.props.form;
    
    return (
      <div className="orders-page" style={{ marginLeft: "200px" }}>
        <div className="flex justify-between w-full mb-4">
          <Link to={`/orders/edit/${this.props.match.params.id}`}>
            <div className="flex justify-start items-center cursor-pointer">
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="text-14 pb-2">
                {this.props.activeOrder
                  ? "#" + this.props.activeOrder.shortId
                  : ""}
              </div>
            </div>
          </Link>
        </div>

        <div className="flex mb-12 justify-between">
          <div className="text-black text-30 mr-10">Refund</div>
          <Button
            onClick={() =>
              this.props.history.push(
                `/orders/edit/${this.props.match.params.id}`
              )
            }
          >
            Cancel
          </Button>
        </div>
        <Form>
          <div className="flex">
            {/* content left */}
            <div className="contentLeft">
              <div className="desc">
                <div className="mb-12 flex">
                  {activeOrder &&
                    (activeOrder.fulfillmentStatus !== 6 ||
                    activeOrder.fulfillmentStatus !== 7 ? (
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#52c41a"
                        style={{ marginRight: "10px", fontSize: "20px" }}
                      />
                    ) : (
                      <Icon
                        type="warning"
                        theme="twoTone"
                        style={{ marginRight: "10px", fontSize: "20px" }}
                      />
                    ))}
                  <div
                    className="bold mb-12 text-base text-black"
                    style={{ lineHeight: 1.2 }}
                  >
                    {activeOrder &&
                      Object.keys(orderFullfillmentStatusMapping).find(
                        (key) =>
                          orderFullfillmentStatusMapping[key] ===
                          activeOrder.fulfillmentStatus
                      )}
                  </div>
                </div>
                {new Array(this.state.noOfProducts).fill(1).map((n, index) => (
                  <Row type="flex" justify="space-between" key={index}>
                    {activeOrder &&
                      activeOrder.items[index] &&
                      activeOrder.items[index].product && (
                        <div
                          style={{ marginTop: "5px" }}
                          className="flex justify-between items-center w-full"
                        >
                          <div
                            className="flex items-container-product"
                            style={{ width: "35%", alignItems: "center" }}
                          >
                            <img
                              src={
                                activeOrder &&
                                activeOrder.items &&
                                activeOrder.items[index] &&
                                activeOrder.items[index].product &&
                                activeOrder.items[index].product.mainImage &&
                                activeOrder.items[index].product.mainImage
                                  .withoutHover &&
                                activeOrder.items[index].product.mainImage
                                  .withoutHover.mediaUrl
                              }
                              alt="product"
                              style={{
                                height: "45px",
                                width: "45px",
                                minWidth: "44px",
                                objectFit: "cover",
                                objectPosition: "center",
                                marginRight: "10px",
                              }}
                            ></img>
                            <div>{activeOrder.items[index].product.name}</div>
                          </div>

                          <div style={{ width: "15%", marginLeft: "10px" }}>
                            {activeOrder &&
                              activeOrder.items[index] &&
                              activeOrder.items[index].product &&
                              activeOrder.items[index].product
                                .shippingMetadata &&
                              activeOrder.items[index].product.shippingMetadata
                                .weight}
                          </div>
                          <Form.Item style={{ marginBottom: 0, width: "30%" }}>
                            {getFieldDecorator(`items[${index}].quantity`, {
                              initialValue: activeOrder && activeOrder.items[index]
                                  ? activeOrder.items[index].quantity
                                  : 0,
                              rules: [
                                {
                                  required: false,
                                  message: "Required",
                                },
                              ],
                            })(
                              <InputNumber
                                min={0}
                                formatter={(value) => {
                                  return `${value} /${
                                    activeOrder && activeOrder.items[index]
                                      ? activeOrder.items[index].quantity
                                      : 1
                                  }`;
                                }}
                                max={
                                  activeOrder && activeOrder.items[index]
                                    ? activeOrder.items[index].quantity
                                    : 0
                                }
                                placeholder="Quantity"
                                onChange={async (val) => {
                                  await this.setState((prevState) => {
                                    let items = [...prevState.items];
                                    items[index].quantity = val;
                                    return {
                                      items: items,
                                      subtotatAmount: items
                                        ? items
                                            .reduce(
                                              (a, b) =>
                                                a +
                                                (b.quantity *
                                                  b.amountAfterDiscount || 0),
                                              0
                                            )
                                            .toFixed(2)
                                        : 0,
                                      numberOfItemsToBeRefunded: items
                                        ? items.reduce(
                                            (a, b) => a + (b.quantity || 0),
                                            0
                                          )
                                        : 0,
                                    };
                                  });
                                  this.props.form.setFieldsValue({
                                    amount: this.state.subtotatAmount,
                                  });
                                }}
                              />
                            )}
                          </Form.Item>
                          <div style={{ width: "20%" }}>
                            {activeOrder &&
                            activeOrder.currency &&
                            activeOrder.currency.code
                              ? activeOrder.currency.code.toUpperCase() + " "
                              : ""}
                            {activeOrder &&
                            activeOrder.items[index] &&
                            activeOrder.items[index].amountAfterDiscount
                              ? (
                                  activeOrder.items[index].amountAfterDiscount *
                                  this.props.form.getFieldValue(
                                    `items[${index}].quantity`
                                  )
                                ).toFixed(2)
                              : "-"}
                          </div>
                        </div>
                      )}
                  </Row>
                ))}
              </div>
              <div className="desc">
                <div className="bold mb-12 text-base text-black">
                  Reason for refund
                </div>
                <Form.Item style={{ marginBottom: 0 }}>
                  {getFieldDecorator(`reason`, {
                    initialValue: getSafely(
                      () => activeOrder.reason,
                      undefined
                    ),
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                  })(<Input style={{ width: "100%" }} />)}
                </Form.Item>
                <p>Only you and other staff can see this reason.</p>
              </div>
            </div>

            {/* content right */}
            <div className="contentRight flex flex-col">
              <div className="desc">
                <div
                  className="bold text-base text-black"
                  style={{ padding: "26px 18px 0 25px" }}
                >
                  Summary
                </div>
                <div style={{ padding: "24px 18px 0 25px" }}>
                  <div className="flex justify-between">
                    <div>
                      <div style={{ width: "100px" }} className="regular">
                        Items subtotal
                      </div>
                      <div className="regular text-14 black-color">
                        {this.state.numberOfItemsToBeRefunded} item
                        {this.state.numberOfItemsToBeRefunded > 1 ? "s" : ""}
                      </div>
                    </div>
                    <div className="regular">
                      {activeOrder &&
                      activeOrder.currency &&
                      activeOrder.currency.code
                        ? activeOrder.currency.code.toUpperCase() +
                          " " +
                          this.state.subtotatAmount
                        : ""}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: "5px",
                    }}
                    className="flex justify-between"
                  >
                    <div className="flex">
                      <div
                        style={{
                          width: "140px",
                          color: "#656565",
                          fontSize: "14px",
                        }}
                        className="semibold"
                      >
                        Refund total
                      </div>
                    </div>
                    <div className="semibold">
                      {activeOrder &&
                        activeOrder.currency &&
                        activeOrder.currency.code.toUpperCase()}{" "}
                      {this.props.form.getFieldValue("amount") ||
                        (this.props.location.state &&
                        this.props.location.state.maxRefundAmount >= 0 &&
                        this.props.location.state.maxRefundAmount <
                          this.state.subtotatAmount
                          ? this.getTax(this.props.location.state.maxRefundAmount)
                          : this.getTax(this.state.subtotatAmount))}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #e8e8e8",
                    padding: "20px 18px 0 25px",
                  }}
                  className="mt-20"
                >
                  <div className="semibold"> REFUND AMOUNT</div>
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator(`amount`, {
                      initialValue:
                        this.props.location.state &&
                        this.props.location.state.maxRefundAmount >= 0
                          ? this.props.location.state.maxRefundAmount
                          : this.state.subtotatAmount,
                      rules: [
                        {
                          required: false,
                          message: "Required",
                        },
                      ],
                    })(
                      <Input
                        style={{ width: "100%" }}
                        type="number"
                        prefix={`${
                          activeOrder && activeOrder.currency
                            ? activeOrder.currency.code.toUpperCase()
                            : ""
                        }`}
                      />
                    )}
                  </Form.Item>
                  <p>
                    {this.props.location.state &&
                    this.props.location.state.maxRefundAmount >= 0
                      ? this.getTax(this.props.location.state.maxRefundAmount)
                      : this.getTax(this.state.subtotatAmount)}{" "}
                    {activeOrder &&
                      activeOrder.currency &&
                      activeOrder.currency.code.toUpperCase()}{" "}
                    available for refund.
                  </p>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #e8e8e8",
                    padding: "20px 18px 25px 25px",
                  }}
                  className="mt-20"
                >
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={this.handleSubmit}
                    loading={this.state.loading}
                  >
                    Refund{" "}
                    {this.props.form.getFieldValue("amount") ||
                      this.state.subtotatAmount}{" "}
                    {activeOrder &&
                      activeOrder.currency &&
                      activeOrder.currency.code.toUpperCase()}{" "}
                  </Button>
                  <div className="flex mt-16">
                    <Form.Item style={{ marginRight: 10 }}>
                      {getFieldDecorator(`notification`, {
                        initialValue: true,
                      })(<Checkbox defaultChecked />)}
                    </Form.Item>
                    <div style={{marginTop: '10px'}}>
                      Send a <span className="theme-color">notification</span>{" "}
                      to the customer
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const RefundForm = Form.create({ name: "add_ingredients_form" })(Refund);

function mapStateToProps(state) {
  const { Products, Orders } = state;
  let activeOrder = Orders?.activeOrder;
  if (activeOrder) {
    activeOrder.items = activeOrder?.items.map((x) => ({
      ...x,
      amountAfterDiscount: x.amountAfterDiscount / x.quantity,
    }));
  }

  return {
    allProducts: Products.products,
    activeOrder: activeOrder,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    getOrder: bindActionCreators(actions.getOrder, dispatch),
    createRefund: bindActionCreators(actions.createRefund, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundForm);
