import { Api } from "../config/request";


export const getRecommendationsList = () => {
  let url = `catalogue/other-recommendation/list`;
  return Api.getListRequest(url);
};

export const createRecommendation = payload => {
  let url = `catalogue/other-recommendation/`;
  return Api.postRequest(url, payload);
};
 export const deleteRecommendation = (id,payload) =>{
  let url = `catalogue/other-recommendation/${id}`;
  return Api.deleteRequest(url, payload);
 }
const Admins = {
  getRecommendationsList,
  createRecommendation,
  deleteRecommendation
};

export default Admins;
