import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Link } from "react-router-dom";
import classnames from 'classnames';
import {
  Table,
  Button,
  Icon,
  Input,
  Menu,
  Dropdown,
  message,
  Tag,
  Modal,
  Checkbox,
  DatePicker,
  Badge,
  Select,
  Form,
  Tooltip
} from "antd";

import "./../listOrders/listOrders.scss";

import * as actions from "actions/orders";
import * as fulfillmentActions from "actions/fulfillment";
import { 
  debounce, 
  formatAddress, 
  getPaymentMethod,
  addQueryParam,
} from "utils/helpers";
import {
  paymentStatusMapping,
  orderFullfillmentStatusListing,
  oderTypeMapper,
  whatsAppMessageStatus,
  orderStatusFilterMapping,
  trackingDetailsCarriers,
  countryFilterMapping,
} from "utils/constants";
import OrdersHeader from "../../../components/orders/OrdersHeader";
import * as assessmentActions from "actions/assessment";


const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const FULFILLMENT_ACTION_OPTION = [
  {
    key: "create_waybill",
    label: "Create waybill",
  },
  {
    key: "generate_label",
    label: "Generate Label",
  },
];

const WHATSAPP_STATUS_ACTION_OPTION = [
  {
    key: "sent",
    label: "Sent",
    code: 1,
    color: "green",
  },
  {
    key: "not_on_whatsapp",
    label: "Not on Whatsapp",
    code: 3,
    color: "red",
  },
  {
    key: "purchased_already",
    label: "Purchased Already",
    code: 4,
    color: "green",
  },
  {
    key: "Existing_chat",
    label: "Existing Chat",
    code: 5,
    color: "blue",
  },
];


const ORDER_ADVANCED_SEARCH_OPTIONS = [
  {
    key: "search",
    label: "Name",
  },
  {
    key: "user.id",
    label: "User ID",
  },
  {
    key: "search",
    label: "Email",
  },
  // {
  //   key: "receiptId",
  //   label: "Receipt ID",
  // },
  {
    key: "attempt",
    label: "Submission ID",
  },
  {
    key: "shortId",
    label: "Order ID",
  },
  {
    key: "promoCode",
    label: "Discount Code",
  },
  {
    key: "tag",
    label: "Tags",
  },
  {
    key: "chargedPrice",
    label: "Charged Price",
  },
  {
    key: "user.location.country",
    label: "Country",
  },
  {
    key: "paymentEmail",
    label: "Partner Email",
  },
];

const basicAdminColumnsFilters = [
  "all",
  "submissionDate",
  "submissionTime",
  "orderStatus",
  "orderId",
  "attempt",
  "name",
  "whatsAppMsgStatus",
  "country",
  "chargedPrice",
  "fulfillmentStatus",
  "code",
  "userId",
  "itemsPurchased",
  "paymentMethod",
  "goGreenPackaging",
  "type",
  "itemsQuantity",
];

const adminColumnsFilter = [
  "all",
  "orderId",
  "name",
  "email",
  "attempt",
  "submissionDate",
  "submissionTime",
  "chargedPrice",
  "code",
  "userId",
  "itemsPurchased",
  "orderStatus",
  "fulfillmentStatus",
  "stripeInvoiceId",
  "type",
  "tags",
  "notes",
  "customerNotes",
  "phone",
  "whatsAppMsgStatus",
  "shippingAddress",
  "country",
  "totalWeight",
  "itemsQuantity",
  "action",
  "paymentMethod",
  "goGreenPackaging",
  "paymentEmail",
  "partnerEmail",
];

const { confirm } = Modal;

class IncompleteOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrderData: null,
      searchKey: "",
      sortOrder: 1,
      activeAdvSearch: ORDER_ADVANCED_SEARCH_OPTIONS[2],
      activeCountryFilter: [],
      selectedOrderIds: [],
      userType: "",
      activeFulfillMentFilters: [],
      activeStatusFilters: [],
      shownColumns: this.getFilters(),
      checked: true,
      disabled: false,
      showExportModal: false,
      exportModalLoading: false,
      startDate: undefined,
      endDate: undefined,
      showAssessmentModal: false,
      whatsAppModal: false,
      whatsAppMessageType: "order_pending",
      questions: [
        "How would you best describe your T-Zone in the morning?",
        "How would you best describe your cheeks in the morning?",
        "What are your top 2 skin concerns?",
        "Ever had any adverse reactions to a skincare ingredient?",
      ],
      fulfillmentModal: false,
      orderToFulfill: {},
      lightBoxOpen: false,
    };
    this.csvRef = React.createRef();
    this.shoppingExportCsvRef = React.createRef();
  }
  componentDidMount() {
    // setting up userType for not visible actions
    const userType = localStorage.getItem("userType");
    this.setState({
      userType,
    });
    //console.log(4, userType);
    // setting up userType for not visible actions
    this.props.fetchAllOrders(
      `status=0&status=1&status=-2&status=-1`,
      this.props &&
        this.props.ordersPageDetails &&
        this.props.ordersPageDetails.currentPage &&
        this.props.ordersPageDetails.currentPage,
      undefined,
      this.state.startDate,
      this.state.endDate,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters
    );
    this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
  }
  // checkbox
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  getAllOrders = () => {
    this.props.fetchAllOrders(
      `status=0&status=1&status=-2&status=-1`,
      this.props &&
        this.props.ordersPageDetails &&
        this.props.ordersPageDetails.currentPage &&
        this.props.ordersPageDetails.currentPage,
      undefined,
      this.state.startDate,
      this.state.endDate,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters
    );
  };

  onColumnFilterChange = (e, item) => {
    console.log(e.target.checked, 123, item);
    // if(item === 'all' && e.target.checked) {
    //   this.setState({
    //     shownColumns: [...this.allColumns]
    //   })
    //   return;
    // }
    // else {
    //   this.setState((prevState, props) => { return {
    //     shownColumns: prevState.shownColumns.filter( col => col !== 'all')
    //    }});
    // }
    if (item === "all") {
      if (e.target.checked) {
        this.setState({
          shownColumns: [...this.getFilters()],
        });
      } else {
        this.setState({
          shownColumns: [],
        });
      }
      return;
    } else {
      this.setState((prevState, props) => {
        return {
          shownColumns: prevState.shownColumns.filter((col) => col !== "all"),
        };
      });
    }
    if (e.target.checked) {
      this.setState((prevState) => {
        let columnsArr = [...prevState.shownColumns, item];
        return {
          shownColumns: columnsArr,
        };
      });
    } else {
      this.setState((prevState) => {
        let columnsArr = prevState.shownColumns.filter((value) => {
          return value !== item;
        });
        return {
          shownColumns: columnsArr,
        };
      });
    }

    this.setState({
      checked: e.target.checked,
    });
  };
  // checkbox

  onChange = (e) => {
    console.log(e);
  };
  handleMenuClick = (e) => {
    console.log(e);
  };
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.props.fetchAllOrders(
            `${
              this.state.activeAdvSearch.key
            }=${value}${this.getSelectedQueryStringForSearch()}`,
            0,
            undefined,
            this.state.startDate,
            this.state.endDate
          );
        } else {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            0,
            undefined,
            this.state.startDate,
            this.state.endDate
          );
        }
      }
    );
  }, 300);
  handleStatusFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.fetchAllOrders(
          `status=${value}`,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };
  handleStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeStatusFilters.indexOf(value) > -1) {
          newFilterList = state.activeStatusFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeStatusFilters, value];
        }
        return {
          activeStatusFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (index === 0 && this.state.activeStatusFilters.length === 0) {
              return `fulfillmentStatus=${value}`;
            } else {
              return `&fulfillmentStatus=${value}`;
            }
          })
          .join("");
        const filterQueryCountry = this.state.activeCountryFilter
          .map((value, index) => {
            if (index === 0 && this.state.activeStatusFilters.length === 0) {
              return `user.location.country=${value}`;
            } else {
              return `&user.location.country=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          filterQueryStatus + 
          filterQueryFulfillment +
          filterQueryCountry,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate,
          this.state.activeStatusFilters,
          this.state.activeFulfillMentFilters
        );
      }
    );
  };

  handleCountryFilter = (value) => {
    this.setState(
      (state) => {
        const newFilterList = []
        const indexRow = state.activeCountryFilter.indexOf('ROW')
        if (
          (value === 'ROW' && state.activeCountryFilter.length > 0 && !(indexRow > -1)) ||
          (indexRow > -1 && value !== 'ROW')
        ) {
          newFilterList.push(value)
        } else {
          newFilterList.push(...state.activeCountryFilter);
          const index = state.activeCountryFilter.indexOf(value)
          if (index > -1) {
            newFilterList.splice(index, 1)
          } else {
            newFilterList.push(value);
          }
        }
        return {
          activeCountryFilter: newFilterList,
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        )
      }
    );
  };

  handleFulfillStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeFulfillMentFilters.indexOf(value) > -1) {
          newFilterList = state.activeFulfillMentFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeFulfillMentFilters, value];
        }
        return {
          activeFulfillMentFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `status=${value}`;
            } else {
              return `&status=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeFulfillMentFilters.length === 0
            ) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          // filterQueryStatus + filterQueryFulfillment,
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate,
          this.state.activeStatusFilters,
          this.state.activeFulfillMentFilters
        );
      }
    );
  };

  handleAdvSearchChange = (value) => {
    console.log(value);
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };

  handleDeleteOrder = (order) => {
    confirm({
      title: `Are you sure you want to delete this Order?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.deleteOrder(order.id).then((res) => {
          if (res) {
            message.success("Order delete successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleFulfillmentAction = (action) => {
    //console.log("======", action, this.state.selectedOrderIds);
    let fulFillmentAction = JSON.parse(action);
    confirm({
      title: `Are you sure you want to proceed?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        //console.log("+++++", fulFillmentAction, fulFillmentAction.key);
        if (fulFillmentAction.key === "create_waybill") {
          //console.log("LLLLL", action.key);
          this.createWaybillForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "generate_label") {
          this.generateLabelForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
          //this.handelGenerateLabel()
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // handleShortAssessmentSummaryModal = (attempt)=>{
  //   console.log(attempt,12)
  //   const {
  //     match: { params },
  //   } = attempt;
  //   this.props.getAssessmentById(attempt);
  //   confirm({
  //     title: `Are you sure you want to delete this Order?`,
  //     okText: "Yes",
  //     cancelText: "No",
  //     // onOk: () => {
  //     //   return this.props.deleteOrder(order.id).then((res) => {
  //     //     if (res) {
  //     //       message.success("Order delete successfully.");
  //     //     } else {
  //     //       message.error("Oops! Something went wrong");
  //     //     }
  //     //   });
  //     // },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // }

  handleShortAssessmentSummaryModal = (attempt, orderData) => {
    const {
      match: { params },
    } = attempt;
    this.props.getAssessmentById(attempt);
    this.setState({ showAssessmentModal: true, selectedOrderData: orderData });
  };

  showModal = () => {
    this.setState({
      showAssessmentModal: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      showAssessmentModal: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      showAssessmentModal: false,
    });
  };

  handelUpdateFulfillment = (order) => {
    confirm({
      title: `Are you sure you want to generate Waybill for this Order 
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.createShipment(order.id).then((res) => {
          this.props.fetchAllOrders();
          if (res) {
            message.success("Waybill Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleWhatsAppActionMenue = (action) => {
    console.log(this.state.selectedOrderIds, action);
    confirm({
      title: `Are you sure you want to change whatsapp status for this Order 
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
         let whatsappAction = JSON.parse(action);
         let message = whatsappAction.code;
         
           
        return this.props
          .updateMultiOrdersWhatsAppMsgStatus(
            this.state.selectedOrderIds,
            message
          )
          .then((res) => {
            this.getAllOrders();
            //this.props.fetchAllOrders();
          });
           
      },
      onCancel() {},
    });
  };

  handelGenerateLabel = (order) => {
    confirm({
      title: `Are you sure you want to generate Label for this Order 
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.generateShipmentLabel(order.id).then((res) => {
          this.props.fetchAllOrders();
          if (res) {
            message.success("Label Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelMarkShipmentShipped = (order) => {
    confirm({
      title: `Are you sure you want to mark this Order to Shipped
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToShipped(order.id).then((res) => {
          this.props.fetchAllOrders();
          if (res) {
            message.success("Shipment shipped  successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  createWaybillForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.createShipmentInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter(),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log( res);
        message.success("Shipment shipped  successfully.");
      } else {
        message.error("Oops! Something went wrong");
      }
    });
  };

  generateLabelForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    //console.log( "generateLabelForMultipleShipment", orderIds )
    this.props.generateLabelInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter(),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        message.success("Label  generated successfully.");
      } else {
        message.error("Oops! Something went wrong");
      }
    });
  };

  handleUpdateFullfillmentStatusByBasicUser = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = {
          fulfillmentStatus: 4,
          trackingDetails: values.trackingDetails,
        };
        return this.props
          .updateOrder(this.state.orderToFulfill.id, payload)
          .then((res) => {
            this.props.fetchAllOrders();
            if (!res.error) {
              message.success("Order status changed to shipped");
              this.setState({ fulfillmentModal: false });
            } else {
              message.error(res.error.internalMessage);
            }
          });
      }
    });
  };

  handelWhatsAppMessage = (order,orderId, phone, msg) => {
    
    let name = "";
    if (order.user) {
      name = order.user.firstName + " " + order.user.lastName;
    }

    let decoded_msg = decodeURIComponent(msg);
    let new_msg = decoded_msg.replace("{{ first_name }}", name);
    //console.log(new_msg, msg, decoded_msg, this.props.whatsAppMessageList);
    //let url = `https://wa.me/${phone}?text=${new_msg}`;
    let url = `https://web.whatsapp.com/send?phone=${phone}&text=${new_msg}`;
    //console.log(url);
    return this.props
      .updateWhatsAppStatus(
        orderId,
        whatsAppMessageStatus.incomplete_order_send
      )
      .then((res) => {
        this.getAllOrders();
        message.success(`whatsapp message send for phone: ${phone}`);
        window.open(url, "_blank");
      });
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  SelectDates = (val) => {
    if (val.length === 0) {
      this.setState(
        {
          startDate: undefined,
          endDate: undefined,
        },
        () => this.props.fetchAllOrders(this.getSelectedQueryStringFilter(), 0)
      );
    } else {
      this.setState(
        {
          startDate: new Date(val[0]).toISOString(),
          endDate: new Date(val[1]).toISOString(),
        },
        () =>
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            0,
            undefined,
            this.state.startDate,
            this.state.endDate,
            this.state.activeStatusFilters,
            this.state.activeFulfillMentFilters
          )
      );
    }
  };
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  getOrderType(order) {
    if (order.isSubscribed) {
      if (
        order &&
        order.subscriptionId &&
        order.subscriptionId.period &&
        order.subscriptionId.period.value
      ) {
        if (order.subscriptionId.period.value / 30 > 1) {
          return `Subscription (${
            order.subscriptionId.period.value / 30
          } Months)`;
        }
        return "Subscription (1 Month)";
      }
      return "Subscription";
    }
    return "One-time";
  }

  getPhoneNumber(order) {
    if (order && order.shippingAddress && order.shippingAddress.phone) {
      return (
        order.shippingAddress.phone.isdCode +
        " - " +
        order.shippingAddress.phone.phone
      );
    }
    return "-";
  }
  getSelectedQueryStringForSearch = () => {
    let queryString = "";
    const { 
      activeFulfillMentFilters, 
      activeStatusFilters,
      activeCountryFilter
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `&status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `&status=0&status=1&status=-2&status=-1`;
    }

    queryString += addQueryParam('user.location.country', activeCountryFilter)

    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `&fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    return queryString;
  };

  getSelectedQueryStringFilter = () => {
    let queryString = "";
    const {
      activeFulfillMentFilters,
      activeStatusFilters,
      activeCountryFilter,
      searchKey,
      activeAdvSearch,
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `status=0&status=1&status=-2&status=-1`;
    }

    queryString += addQueryParam('user.location.country', activeCountryFilter)

    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}&`;
    }
    if (queryString && queryString.length > 0) {
      queryString = queryString + "&";
    }
    return queryString;
  };

  isCountryStatusApplied = () => {
    const { activeCountryFilter } = this.state;
    return !!(activeCountryFilter?.length > 0 || this.props.ordersPageDetails?.activeCountryFilter?.length > 0)
  };

  isPaymentStatusApplied = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  getCountryStatusCount = () => this.state.activeCountryFilter?.length || 0;

  getPaymentStatusCount = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters.length > 0)
    ) {
      if (activeStatusFilters && activeStatusFilters.length > 0) {
        return activeStatusFilters.length;
      } else {
        return this.props.ordersPageDetails.activeStatusFilters.length;
      }
    } else {
      return 0;
    }
  };

  isFullfillmentStatusApplied = () => {
    const { activeFulfillMentFilters } = this.state;
    if (
      (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  getFullfillmentStatusCount = () => {
    const { activeFulfillMentFilters } = this.state;
    if (
      (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters.length > 0)
    ) {
      if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
        return activeFulfillMentFilters.length;
      } else {
        return this.props.ordersPageDetails.activeFulfillMentFilters.length;
      }
    } else {
      return 0;
    }
  };

  isClearAllFilterDisabled = () => {
    if (
      !this.isFullfillmentStatusApplied() && 
      !this.isPaymentStatusApplied() &&
      !this.isCountryStatusApplied()
      ) {
      return true;
    } else {
      return false;
    }
  };

  _onCloseLightBox = () => {
    this.setState({ lightBoxOpen: false });
  };

  _openLightBox = () => {
    const { activeAssessment } = this.props;
    if (activeAssessment && activeAssessment.selfieURL) {
      this.setState({ lightBoxOpen: true });
    }
  };

  getFilters = () => {
    if (localStorage.getItem("userType") === "basic") {
      return basicAdminColumnsFilters;
    }
    return adminColumnsFilter;
  };

  handelUpdateWhatsAppMessage = () => {
    //console.log("sdsdsds======", this.props.whatsAppMessageList);
    this.props.form.validateFields(["message"], (err, values) => {
      //console.log( err,values)
      if (!err) {
        //console.log("+++++++++")
        this.props
          .updateWhatsAppMessage(this.state.whatsAppMessageType, values.message)
          .then((res) => {
            this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
            this.setState({ whatsAppModal: false });
            // console.log(res);
          })
          .catch((err) => {
            this.setState({ whatsAppModal: false });
            console.log("err", err);
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const label = `${this.state.checked ? "Checked" : "Unchecked"}-${
      this.state.disabled ? "Disabled" : "Enabled"
    }`;
    const { activeAssessment } = this.props;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const selectedOrderIds = selectedRows.map((row) => row.id);
        this.setState({
          selectedOrderIds,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };
    const columns = [
      {
        title: "Order Date",
        dataIndex: "submissionDate",
        key: "submissionDate",
        width: 130,
      },
      {
        title: "Order Time",
        dataIndex: "submissionTime",
        key: "submissionTime",
        width: 130,
      },
      {
        title: "Payment Status",
        dataIndex: "orderStatus",
        key: "orderStatus",
        width: 130,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        width: 120,
        render: (order) => {
          return (
            <div className="flex">
              <Link
                to={`/orders/edit/${order.id}`}
                target="_blank"
                style={{ color: "#363864" }}
              >
                {order.shortId}
              </Link>
              {order?.comments?.length !=0 && <Tooltip title="This order has a message trail">
                <Icon type="message" style={{
                  width: '40px',
                  height: '40px'
                }} />
              </Tooltip>}
            </div>
          );
        },
      },
      {
        title: "Submission ID",
        dataIndex: "attempt",
        key: "attempt",
        width: 120,
        render: (attempt, row) => {
          return (
            <div className="flex">
              <div
                style={{ color: "#373873" }}
                onClick={(e) => {
                  e.stopPropagation();
                  attempt !== "-" &&
                    this.handleShortAssessmentSummaryModal(attempt, row);
                }}
              >
                {attempt}
              </div>
            </div>
          );
        },
      },

      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        render: (user) => (
          <Link
            to={`/customer/edit/${user.id && user.id.id}`}
            target="_blank"
            style={{ color: "#373873" }}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   this.props.history.push({
            //     pathname: `/customer/edit/${user.id.id}`,
            //     state: {
            //       route: "/orders",
            //     },
            //   });
            // }}
          >
            {user.firstName || ""} {user.lastName || ""}
          </Link>
        ),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        width: 130,
      },
      {
        title: "WhatsApp",
        dataIndex: "whatsAppMsgStatus",
        key: "whatsAppMsgStatus",
        width: 130,
        render: (order) => {
          //console.log(order)
          let phone = this.getPhoneNumber(order);
          let action = WHATSAPP_STATUS_ACTION_OPTION.filter(
            (status) => status.code === order.whatsAppMsgStatus
          )[0];
          
          if(!phone){
            
            return ( <div>-</div>)
          }
          return order.whatsAppMsgStatus <
            whatsAppMessageStatus.incomplete_order_send ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                let formatted_phone_no = phone.replace(/[-()+\s]/g, "");
                let msg =
                  this.props.whatsAppMessageList.length > 0
                    ? typeof this.props.whatsAppMessageList[0] == "string" ? this.props.whatsAppMessageList[0] : this.props.whatsAppMessageList[0].message
                    : "";
                let text_msg = encodeURIComponent(msg);
                this.handelWhatsAppMessage(
                  order,
                  order.id,
                  formatted_phone_no,
                  text_msg
                );
              }}
              style={{
                color: "#373873",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              New message
            </div>
          ) : (
            <span style={{ color: action && action.color }}>
              {" "}
              {
               action && action.label
              }{" "}
            </span>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 170,
      },

      {
        title: "Partner Email",
        dataIndex: "partnerEmail",
        key: "partnerEmail",
        width: 170,
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 130,
      },
      {
        title: "Shipping Address",
        dataIndex: "shippingAddress",
        key: "shippingAddress",
        width: 200,
      },
      {
        title: "Charged Price",
        dataIndex: "chargedPrice",
        key: "chargedPrice",
        width: 130,
      },
      {
        title: "Customer Notes",
        dataIndex: "customerNotes",
        key: "customerNotes",
        width: 130,
      },
      {
        title: "Order Notes",
        dataIndex: "notes",
        key: "notes",
        width: 130,
      },
      {
        title: "Fulfillment Status",
        dataIndex: "fulfillmentStatus",
        key: "fulfillmentStatus",
        width: 130,
      },

      {
        title: "Discount Code",
        dataIndex: "code",
        key: "code",
        width: 130,
      },

      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        width: 150,
        render: (userId) => (
          <Link
            to={`/customer/edit/${userId}`}
            target="_blank"
            style={{ color: "#373873" }}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   this.props.history.push({
            //     pathname: `/customer/edit/${userId}`,
            //     state: {
            //       route: "/orders",
            //     },
            //   });
            // }}
          >
            {userId}
          </Link>
        ),
      },

      {
        title: "Items purchased",
        dataIndex: "itemsPurchased",
        key: "itemsPurchased",
        width: 130,
      },

      {
        title: "Payment Processor",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        width: 130,
        render: (paymentMethod) => {
          return getPaymentMethod(paymentMethod);
        },
      },
      {
        title: "Packaging",
        dataIndex: "goGreenPackaging",
        key: "goGreenPackaging",
        width: 130,
        render: (goGreenPackaging) => {
          return (
            <div className="flex-center">
              {goGreenPackaging && <span className="go-green-list-icon" role="img" aria-label="go-green">
                🌍 {"  "}♻️
              </span>}
            </div>
          );
        },
      },
      {
        title: "Order Type",
        dataIndex: "type",
        key: "type",
        width: 130,
      },
      {
        title: "Tags",
        key: "tags",
        dataIndex: "tags",
        width: 130,
        render: (tags) => (
          <span>
            {tags &&
              tags.map((tag, index) => {
                return (
                  <Tag className="mb-4" key={index}>
                    {tag === Object(tag) ? tag.name : ""}
                  </Tag>
                );
              })}
          </span>
        ),
      },
      {
        title: "Shipment Id",
        dataIndex: "shipmentId",
        key: "shipmentId",
        width: 130,
      },
      {
        title: "Shipping Label",
        dataIndex: "shipmentLabel",
        key: "shipmentLabel",
        render: (order) => (
          <>
            {order && order.trackingNumber && order.fulfillmentStatus > 1 ? (
              <a
                style={{ color: "#373873" }}
                href={`https://goeasyship.s3.amazonaws.com/${order.trackingNumber}_label.pdf`}
              >
                Label
              </a>
            ) : (
              ""
            )}
          </>
        ),
        width: 130,
      },
      {
        title: "Total Weight",
        dataIndex: "totalWeight",
        key: "totalWeight",
        width: 100,
      },
      {
        title: "Items quantity",
        dataIndex: "itemsQuantity",
        key: "itemsQuantity",
        width: 130,
      },

      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 140,

        // render: (order) => {
        //   return (
        //     <div className="flex">

        //       {order.type === "Paid" && this.state.userType === "admin" && (
        //         <div>
        //           {order.fulfillmentStatus ===
        //             orderFullfillmentStatusListing[0] && (
        //               <div
        //                 onClick={(e) => {
        //                   e.stopPropagation();
        //                   // console.log("===== fulfillment", order);
        //                   this.handelUpdateFulfillment(order);
        //                 }}
        //               >
        //                 Create waybill
        //               </div>
        //             )}
        //           {order.fulfillmentStatus ===
        //             orderFullfillmentStatusListing[1] && (
        //               <div
        //                 onClick={(e) => {
        //                   e.stopPropagation();
        //                   //console.log("===== fulfillment", order);
        //                   this.handelGenerateLabel(order);
        //                 }}
        //               >
        //                 Generate Label
        //               </div>
        //             )}
        //         </div>
        //       )}
        //     </div>
        //   );
        // },
        render: (order) => {
          return (
            <div className="flex">
              {order.orderStatus === paymentStatusMapping[2] &&
                this.state.userType === "admin" && (
                  <div>
                    {order.fulfillmentStatus ===
                      orderFullfillmentStatusListing[0] && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          // console.log("===== fulfillment", order);
                          this.handelUpdateFulfillment(order);
                        }}
                      >
                        Create waybill
                      </div>
                    )}
                    {order.fulfillmentStatus ===
                      orderFullfillmentStatusListing[1] && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelGenerateLabel(order);
                        }}
                      >
                        Generate Label
                      </div>
                    )}
                    {order.fulfillmentStatus ===
                      orderFullfillmentStatusListing[2] && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelOrderReadyPickup(order);
                        }}
                      >
                        Mark Ready for Pickup
                      </div>
                    )}
                    {order.fulfillmentStatus ===
                      orderFullfillmentStatusListing[4] && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelOrderShipped(order);
                        }}
                      >
                        Mark Order Shipped
                      </div>
                    )}
                    {order.fulfillmentStatus ===
                      orderFullfillmentStatusListing[5] && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelOrderDeliver(order);
                        }}
                      >
                        Mark Order Delivered
                      </div>
                    )}
                  </div>
                )}

              {/* {order.orderStatus !== paymentStatusMapping[2] &&
                order.type !== "Paid" &&
                this.state.userType === "admin" && (
                  <div
                    className="delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleDeleteOrder(order);
                    }}
                  >
                    {this.state.userType === "admin" && (
                      <span style={{ padding: "4px" }}>|</span>
                    )}
                    Delete
                  </div>
                )} */}
            </div>
          );
        },
      },
    ].filter((column) =>
      this.state.shownColumns.find(
        (hiddenColKey) => hiddenColKey === column.key
      )
    );

    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });
    headers.filter((head) => head.key !== "action");

    const mappedOrdersList =
      this.props.ordersList.length > 0
        ? this.props.ordersList.map((order) => {
            const itemsPurchased = order.items.map(x => x?.product?.ssu).join(', ');
            return {
              id: order.id,
              submissionTime: `${
                order.createdAt
                  ? momentTimezone(order.createdAt)
                      .tz("Asia/Singapore")
                      .format("hh:mm a")
                  : "-"
              }`,
              key: order.id,
              userId: order.user && order.user.id && order.user.id.id,
              email: order.user.email,
              name: order.user,
              submissionDate: `${
                order.createdAt
                  ? moment(order.createdAt).format("DD/MM/YYYY")
                  : "-"
              }`,
              // attempt: order.shortId ? order.shortId : "-",
              attempt: order.attempt ? order.attempt : "-",
              chargedPrice:
                `${
                  order.currency &&
                  order.currency.code &&
                  order.currency.code.toUpperCase()
                }` +
                " " +
                `${order.chargedPrice}`,
              // totalPrice: order.totalPrice,
              orderId: order,
              receiptId: order.receiptId,
              orderStatus: paymentStatusMapping[order.status],
              invoice: "",
              origin: order.origin,
              shippingAddress: formatAddress(order.shippingAddress),
              shipmentId: order.trackingNumber,
              fulfillmentStatusCode: order ? order.fulfillmentStatus : 0,
              shipmentLabel: order,
              phone: this.getPhoneNumber(order),
              whatsAppMsgStatus: order,
              itemsPurchased,
              itemsQuantity: order.items
                ? order.items.map((item) => item.quantity).join(", ")
                : [],
              action: order.id,
              type: this.getOrderType(order),
              totalWeight: order.totalWeight,
              tags: order.tags,
              paymentMethod: order.paymentMethod,
              goGreenPackaging: order?.goGreenPackaging,
              country: order.shippingAddress
                ? order.shippingAddress.country
                : "-",
              notes: order.notes ? order.notes : "",
              customerNotes:
                order && order.user && order.user.id ? order.user.id.notes : "",
              stripeInvoiceId:
                order && order.stripeInvoiceId ? order.stripeInvoiceId : "-",
              fulfillmentStatus: order
                ? orderFullfillmentStatusListing[order.fulfillmentStatus]
                : "-",
              promoType:
                order && order.promotion && order.promotion.promoType
                  ? order.promotion.promoType
                  : "-",
              code:
                order && order.promotion && order.promotion.code
                  ? order.promotion.code
                  : "-",
              status: order && order.status ? order.status : "-",
              shipping: `${
                order && order.currency && order.currency.code
                  ? order.currency.code.toUpperCase()
                  : "-"
              }${order && order.shipping ? order.shipping : "-"}`,
              trackingDetails: order.trackingDetails,
              partnerEmail: order && order.paymentEmail && order.paymentEmail,
            };
          })
        : [];
    const csvData = mappedOrdersList.map((item) => {
      return {
        ...item,
        name: `${item.name.firstName || ""} ${item.name.lastName || ""}`,
        orderId: item.orderId.shortId,
        tags:
          item.tags &&
          item.tags
            .map((tag) => {
              return tag === Object(tag) ? tag.name : "";
            })
            .join(","),
      };
    });

    const filterByCountryMenu = (
      <Menu
        onClick={(e) => this.handleCountryFilter(e.key)}
        selectedKeys={this.state.activeCountryFilter}
        multiple
        selectable
      >
        {Object.keys(countryFilterMapping).map((key) => {
          return (
            <Menu.Item key={key}>
              {countryFilterMapping[key]}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const menu = (
      <Menu
        onClick={(e) => this.handleStatusFilter("status", e.key)}
        selectedKeys={this.state.activeStatusFilters}
        multiple
        selectable
      >
        {Object.keys(orderStatusFilterMapping)
          .filter((x) => x != 2)
          .map((key) => {
            return (
              <Menu.Item key={key}>{orderStatusFilterMapping[key]}</Menu.Item>
            );
          })}
      </Menu>
    );
    const fulfillmentMenu = (
      <Menu
        onClick={(e) =>
          this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
        }
        selectedKeys={this.state.activeFulfillMentFilters}
        multiple
        selectable
      >
        {Object.keys(orderFullfillmentStatusListing).map((key) => {
          return (
            <Menu.Item key={key}>
              {orderFullfillmentStatusListing[key]}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const fulfillmentActionMenu = (
      <Menu
        onClick={(e) => this.handleFulfillmentAction(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {FULFILLMENT_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const whatsappActionMenu = (
      <Menu
        onClick={(e) => this.handleWhatsAppActionMenue(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {WHATSAPP_STATUS_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({
                key: option.key,
                label: option.label,
                code: option.code,
                color:option.color,
              })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const advancedSearchMenu = (
      <Menu
        onClick={(e) => this.handleAdvSearchChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {ORDER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          if(this.state.activeCountryFilter.length > 0 && option.label === 'Country') {
            return 
          } else {
            return (
              <Menu.Item
                key={JSON.stringify({ key: option.key, label: option.label })}
              >
                {option.label}
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );
    const columnFilterMenu = (
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        className="flex flex-col column-checkbox-wrapper"
      >
        {/* <div onClick={this.handleMenuStopClick} key="3">Close</div> */}
        {this.getFilters().map((item, index) => {
          return (
            <Checkbox
              key={index}
              checked={this.state.shownColumns.find((value) => {
                return value === item;
              })}
              onChange={(e) => this.onColumnFilterChange(e, item)}
            >
              {item
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Checkbox>
          );
        })}
      </div>
    );
    if (activeAssessment && activeAssessment.questionsAnswered) {
      var result = [];
      this.state.questions.forEach((question) => {
        let x = activeAssessment.questionsAnswered.find((questionObject) => {
          return question.includes(questionObject.question.text);
        });
        if (x) {
          result.push(x);
        }
      });
    }

    return (
      <div className="orders-page-list" style={{ marginLeft: "200px" }}>
        <OrdersHeader
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onChangeInput={this.onChangeInput}
          fetchAllOrders={this.props.fetchAllExportOrders}
          csvData={csvData}
          allHeaders={headers}
          csvRef={this.csvRef}
          shoppingExportCsvRef={this.shoppingExportCsvRef}
          ordersList={this.props.ordersList}
          selectedOrderIds={this.state.selectedOrderIds}
          pageName="Incomplete Order"
          pageNo={this.state.pageNo}
          totalCount={this.props.ordersPageDetails?.totalCount || 0}
          allowShippingExport={
            localStorage.getItem("userType") === "admin" ? "yes" : "no"
          }
          allowOrderExport={
            localStorage.getItem("userType") === "admin" ? "yes" : "no"
          }
        />

        <Modal
          title="Assessment Summary"
          visible={this.state.showAssessmentModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {result &&
            result.map((item) => {
              return (
                <div className="q-a-wrapper">
                  <div className="flex">
                    <div style={{ minWidth: "70px" }} className="text-sm bold">
                      Question:
                    </div>
                    <div
                      style={{ paddingLeft: "5px" }}
                      className="text-sm regular"
                    >
                      {item && item.question && item.question.text}
                    </div>
                  </div>

                  <div>
                    {item &&
                      item.answer.map((answerValue) => {
                        return (
                          <div className="flex">
                            <div
                              style={{ minWidth: "74px" }}
                              className="text-sm bold"
                            >
                              Answer:
                            </div>
                            <div
                              style={{ paddingLeft: "5px" }}
                              className="text-sm regular"
                            >
                              {answerValue && answerValue.text}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key bold">
              Total Questions Answered:
            </div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value regular"
            >
              {activeAssessment && activeAssessment.totalQuestionsAnswered}
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key bold">
              Last Answered Question:
            </div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value regular"
            >
              {activeAssessment &&
                activeAssessment.lastAnsweredQuestion &&
                activeAssessment.lastAnsweredQuestion.text}
            </div>
          </div>

          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key bold">Selfie Url:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value regular"
            >
              <a
                style={{ paddingLeft: "0px" }}
                href="javascript:void(0)"
                onClick={this._openLightBox}
              >
                {activeAssessment && activeAssessment.selfieURL
                  ? activeAssessment.selfieURL
                  : "Not Available"}
              </a>
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key bold">Location:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value regular"
            >
              {activeAssessment &&
                activeAssessment.weatherData &&
                activeAssessment.weatherData.data &&
                activeAssessment.weatherData.data.location}
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key bold">Customer Notes:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value regular"
            >
              {this.state.selectedOrderData &&
              this.state.selectedOrderData.customerNotes
                ? this.state.selectedOrderData.customerNotes
                : ""}
            </div>
          </div>
          <div
            style={{ paddingTop: "15px" }}
            className="question-count flex items-center"
          >
            <div className="a-question text-16 key bold">Order Purchased:</div>
            <div
              style={{ paddingLeft: "5px" }}
              className="a-question text-16 value regular"
            >
              {activeAssessment &&
              activeAssessment.purchasedOrder &&
              activeAssessment.purchasedOrder
                ? "Yes"
                : "No"}
            </div>
          </div>

          <div className="text-sm bold" style={{ margin: "10px 0" }}>
            Recommended Products:
          </div>
          <div className="flex">
            {activeAssessment &&
              activeAssessment.recommendedProducts &&
              activeAssessment.recommendedProducts.products &&
              activeAssessment.recommendedProducts.products.map((item) => {
                return (
                  <div className="flex flex-col img-text">
                    <img
                      alt=""
                      className="product-img"
                      src={
                        item &&
                        item.product &&
                        item.product.mainImage &&
                        item.product.mainImage &&
                        item.product.mainImage.withoutHover.mediaUrl
                      }
                    />
                    <div
                      style={{ marginTop: "20px", maxWidth: "74px" }}
                      className="image-text-container bold key"
                    >
                      {item && item.product.name ? item.product.name : "-"}
                    </div>
                  </div>
                );
              })}
            {activeAssessment &&
              activeAssessment.recommendedProducts &&
              activeAssessment.recommendedProducts.nightSerum && (
                <div style={{ paddingLeft: "30px" }}>
                  {activeAssessment.recommendedProducts.nightSerum &&
                    activeAssessment.recommendedProducts.nightSerum.mainImage &&
                    activeAssessment.recommendedProducts.nightSerum.mainImage
                      .withoutHover.mediaUrl && (
                      <img
                        src={
                          activeAssessment.recommendedProducts.nightSerum
                            .mainImage.withoutHover.mediaUrl
                        }
                        className="product-img"
                        alt=""
                      />
                    )}
                  <div
                    style={{ marginTop: "20px", maxWidth: "70px" }}
                    className="image-text-container key bold"
                  >
                    {activeAssessment &&
                      activeAssessment.recommendedProducts.nightSerum &&
                      activeAssessment.recommendedProducts.nightSerum.name}
                  </div>
                </div>
              )}
          </div>
        </Modal>
        {this.state.lightBoxOpen && (
          <Lightbox
            mainSrc={activeAssessment && activeAssessment.selfieURL}
            onCloseRequest={this._onCloseLightBox}
          />
        )}
        <div className=" nav-container justify-between">
          <div className="flex"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "15px",
              marginRight: "15px",
            }}
          >
            <RangePicker
              onChange={(val) => {
                this.SelectDates(val);
              }}
              allowClear={true}
              disabledDate={this.disabledDate}
            />
            <Button
              type="primary"
              className="mr-20"
              onClick={() => {
                this.setState({ whatsAppModal: true });
              }}
              // disabled={this.state.selectedOrderIds.length === 0}
            >
              <Icon type="message"></Icon>
              Whatsapp Message
            </Button>
            <Dropdown
              overlay={whatsappActionMenu}
              disabled={this.state.selectedOrderIds.length === 0}
            >
              <Button
                className="dropdown-btn"
                style={
                  this.state.selectedOrderIds.length !== 0
                    ? {
                        background: "#373873",
                        color: "#fff",
                        height: "32px",
                        marginBottom: "10px",
                        marginRight: "20px",
                      }
                    : {
                        height: "32px",
                        marginBottom: "10px",
                        marginRight: "20px",
                      }
                }
              >
                Change Whatsapp Status <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
          <div className="flex"></div>
          <div style={{ marginTop: "15px" }}></div>
        </div>

        <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
          <Search
            placeholder={`Search by ${this.state.activeAdvSearch.label}`}
            onSearch={(value) => this.handleSearch(value)}
            style={{ width: "50%", marginRight: "5px" }}
            onKeyUp={(e) => this.handleSearch(e.target.value)}
          />
          <Dropdown overlay={advancedSearchMenu}>
            <Button
              className="dropdown-btn"
              style={{ flex: 1, marginRight: "5px" }}
            >
              {this.state.activeAdvSearch.label === "Email"
                ? "Advanced Search"
                : this.state.activeAdvSearch.label}
              <Icon type="down" />
            </Button>
          </Dropdown>
          <Dropdown
            onVisibleChange={this.handleVisibleChange}
            visible={this.state.visible}
            overlay={columnFilterMenu}
          >
            <Button
              onClick={(e) => e.preventDefault()}
              className="dropdown-btn"
              style={{ flex: 1, marginRight: "5px" }}
            >
              Column Filters <Icon type="down" />
            </Button>
          </Dropdown>

          <Dropdown overlay={filterByCountryMenu}>
            <Button
              className={classnames("dropdown-btn", {
                "filter-selected": this.isCountryStatusApplied(),
              })}
              style={{ flex: 1, marginRight: "5px" }}
            >
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge count={this.getCountryStatusCount()}>
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Country
              <Icon type="down" />
            </Button>
          </Dropdown>

          <Dropdown overlay={menu}>
            <Button
              className={`dropdown-btn ${
                this.isPaymentStatusApplied() && "filter-selected"
              }`}
              style={{ flex: 1, marginRight: "5px" }}
            >
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge count={this.getPaymentStatusCount()}>
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Payment Status <Icon type="down" />
            </Button>
          </Dropdown>
          <Dropdown overlay={fulfillmentMenu}>
            <Button
              className={`dropdown-btn ${
                this.isFullfillmentStatusApplied() && "filter-selected"
              }`}
              style={{ flex: 1, marginRight: "5px" }}
            >
              {" "}
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge count={this.getFullfillmentStatusCount()}>
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Fulfillment Status <Icon type="down" />
            </Button>
          </Dropdown>
          <Button
            onClick={() => {
              this.setState({
                activeFulfillMentFilters: [],
                activeStatusFilters: [],
                activeCountryFilter: [],
              });
              function disabledDate(current) {
                // Can not select days before today and today
                return current && current < moment().endOf("day");
              }
              this.props.fetchAllOrders(
                `status=0&status=1&status=-2&status=-1`,
                0
              );
            }}
            // disabled={
            //   this.state.activeFulfillMentFilters.length === 0 &&
            //   this.state.activeStatusFilters.length === 0
            // }
            disabled={this.isClearAllFilterDisabled()}
            className="dropdown-btn"
            style={{ width: "140px", marginRight: "5px" }}
          >
            Clear All Filters
          </Button>
        </div>
        <div>
          <div style={{ background: "white" }}>
            {this.props.ordersPageDetails && (
              <Table
                rowClassName="cursor-pointer woo"
                columns={columns}
                dataSource={mappedOrdersList}
                bordered
                size="small"
                rowSelection={rowSelection}
                pagination={{
                  current:
                    Number(this.props.ordersPageDetails.currentPage + 1) || 0,
                  total: this.props.ordersPageDetails.totalCount || 0,
                  pageSize: 20,
                  onChange: (pageNo) => {
                    this.setState({ pageNo: pageNo });
                    this.props.fetchAllOrders(
                      this.getSelectedQueryStringFilter(),
                      pageNo - 1,
                      undefined,
                      this.state.startDate,
                      this.state.endDate,
                      this.state.activeStatusFilters,
                      this.state.activeFulfillMentFilters
                    );
                  },
                }}
                scroll={{ x: 1500 }}
              />
            )}
          </div>
        </div>
        <Modal
          title="This will change order status to Shipped !"
          visible={this.state.fulfillmentModal}
          okText="Yes"
          onOk={this.handleUpdateFullfillmentStatusByBasicUser}
          cancelText="No"
          onCancel={() => {
            this.setState({ fulfillmentModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>TRACKING INFORMATION</div>
          {/* tracking information */}
          <Form>
            <div className="flex flex-start">
              <Form.Item
                style={{ width: "calc(50% - 6px)", marginRight: "12px" }}
              >
                {getFieldDecorator(`trackingDetails.number`, {
                  initialValue:
                    this.state.orderToFulfill &&
                    this.state.orderToFulfill.trackingDetails
                      ? this.state.orderToFulfill.trackingDetails.number
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(<Input placeholder="Tracking Number" />)}
              </Form.Item>
              <Form.Item style={{ width: "calc(50% - 6px)" }}>
                {getFieldDecorator(`trackingDetails.carrier`, {
                  initialValue:
                    this.state.orderToFulfill &&
                    this.state.orderToFulfill.trackingDetails
                      ? this.state.orderToFulfill.trackingDetails.carrier
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(
                  <Select placeholder="Shipping Carrier">
                    {trackingDetailsCarriers.map((carrier) => (
                      <Option key={carrier.value}>{carrier.label}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </Form>
        </Modal>

        <Modal
          title="Whatsapp message"
          visible={this.state.whatsAppModal}
          okText="Yes"
          onOk={this.handelUpdateWhatsAppMessage}
          cancelText="No"
          onCancel={() => {
            this.setState({ whatsAppModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}> Whatsapp message</div>
          {/* tracking information */}
          <Form>
            <div className="flex flex-start">
              <Form.Item
                style={{ width: "calc(50% - 6px)", marginRight: "12px" }}
              >
                {getFieldDecorator(`message`, {
                  initialValue:
                    this.props.whatsAppMessageList &&
                    this.props.whatsAppMessageList.length > 0
                      ? this.props.whatsAppMessageList[0].message
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(
                  <Input.TextArea
                    placeholder="Hey there"
                    style={{ maxWidth: "none", width: "478px" }}
                  />
                )}
                <div style={{ paddingBottom: "10px" }}>
                  {" "}
                  Add dynamic field: {" {{ first_name }}"}{" "}
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Orders, Assessment } = state;
  return {
    ordersList: Orders.ordersList,
    ordersPageDetails: Orders.ordersPageDetails,
    activeAssessment: Assessment.activeAssessment,
    whatsAppMessageList: Orders.whatsAppMessageList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllOrders: bindActionCreators(actions.getOrders, dispatch),
    fetchAllExportOrders: bindActionCreators(actions.exportOrders, dispatch),
    deleteOrder: bindActionCreators(actions.deleteOrder, dispatch),
    updateOrder: bindActionCreators(actions.updateOrder, dispatch),
    createShipment: bindActionCreators(
      fulfillmentActions.createShipment,
      dispatch
    ),
    generateShipmentLabel: bindActionCreators(
      fulfillmentActions.generateShipmentLabel,
      dispatch
    ),
    markShipmentToShipped: bindActionCreators(
      fulfillmentActions.markShipmentToShipped,
      dispatch
    ),
    createShipmentInBulk: bindActionCreators(
      fulfillmentActions.createShipmentInBulk,
      dispatch
    ),
    generateLabelInBulk: bindActionCreators(
      fulfillmentActions.generateLabelInBulk,
      dispatch
    ),

    getAssessmentById: bindActionCreators(
      assessmentActions.getAssessmentById,
      dispatch
    ),
    updateWhatsAppStatus: bindActionCreators(
      actions.updateWhatsAppMsgStatus,
      dispatch
    ),
    getWhatsAppMessage: bindActionCreators(
      actions.getWhatsAppMessage,
      dispatch
    ),
    updateWhatsAppMessage: bindActionCreators(
      actions.updateWhatsAppMessage,
      dispatch
    ),
    updateMultiOrdersWhatsAppMsgStatus: bindActionCreators(
      actions.updateMultiOrdersWhatsAppMsgStatus,
      dispatch
    ),
  };
}
const IncompleteOrdersForm = Form.create({ name: "orders_form" })(
  IncompleteOrders
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncompleteOrdersForm);
