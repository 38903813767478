import React, { useState, useEffect } from "react";
import "./sidebar.scss";
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { logout, getUserInitials } from "utils/helpers";
import { isPermitted, sideBarLinks } from "utils/constants";
const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar = (props) => {
  const [userType, setUserType] = useState('');
  
  const getDefaultSelected = () => {
    const currentPathname = props.location.pathname;
    if (currentPathname.includes("products")) {
      return "products";
    } else if (currentPathname.includes("home")) {
      return "home";
    } else if (currentPathname.includes("ingredients")) {
      return "ingredients";
    } else if (currentPathname.includes("category")) {
      return "category";
    } else if (currentPathname.includes("customer")) {
      return "customers";
    } else if (currentPathname.includes("inventory")) {
      return "inventory"; 
    }else if (currentPathname.includes("admin")) {
      return "admin";
    }
  };
  
  useEffect(() => {
    setUserType(localStorage.getItem("userType"));
  }, []);

  return (
    <div className="component-sidebar fixed">
      <Sider className="sidebar" width={200} style={{ background: "#fff" }}>
        <div className="user-data-container flex items-center">
          <div className="user-data-img">{getUserInitials(props.name)}</div>
          <p className="user-data-name theme-color bold">{props.name}</p>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelected()}
          style={{ height: "100%", borderRight: 0 }}
        >
          {sideBarLinks.map((x) => {
            if (isPermitted(userType, x.key)) {
              if (x.links) {
                return (
                  <SubMenu
                    key={x.key}
                    title={
                      <span>
                        <Icon type={x.icon} />
                        {x.name}
                      </span>
                    }
                  >
                    {x.links.map((item, index) => {
                      if(isPermitted(userType, item.key)){
                        return (
                          <Menu.Item key={item.name}>
                            <Link to={item.path}>{item.name}</Link>
                          </Menu.Item>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <Menu.Item key={x.key}>
                    <Icon type={x.icon} />
                    <Link to={x.path} style={{ display: "inline-block" }}>
                      {x.name}
                    </Link>
                  </Menu.Item>
                );
              }
            } else {
              return null;
            }
          })}
          <Menu.Item key="logout" onClick={logout}>
            <Icon type="logout" />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};

export default withRouter(Sidebar);
