import React, { Component } from "react";
import "./loginPage.scss";
import { Icon } from "antd";
import { Button } from "antd";
import Logo from "static/img/global/lovefromyours-logo.svg";
import * as loginPageActions from "actions/login";

class loginPage extends Component {
  googleLogin = () => {
    loginPageActions.googleLoginRequest();
  };

  render() {
    return (
      <div className="view-login-page flex justify-center items-center h-100">
        <div className="form-wrapper flex flex-col justify-center items-center">
          <img src={Logo} alt="Get Yours" className="mb-24" />
          <p className="mw bold text-32 theme-color mb-24">Hey There!</p>
          <Button type="primary" htmlType="button" onClick={this.googleLogin}>
            <Icon type="google" /> Google Login
          </Button>
        </div>
      </div>
    );
  }
}

export default loginPage;
