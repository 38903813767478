import React, {Component} from 'react';
// import './questionList.scss'
import "../assessmentList/assessmentList.scss";
import { Icon, Button, Table, Badge,InputNumber,Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/assessment";
import moment from "moment";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";


class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExportModal:false,
      exportModalLoading:false,
      numberOfRows:10
    };
    this.csvRef = React.createRef();
  }
  onChangeInput = value => {
    this.setState({numberOfRows:value})
  }
    componentDidMount() {
      const { match : { params : {id} } } = this.props;
      this.props.fetchQuestionList(id);
    }
        
    handleEditQuestions = (questionObj) => {
      const {pathname} = this.props.location;
      const win = window.open(`${pathname}/edit/${questionObj.id}`, "_blank");
      win.focus();
    }

    render() {
      const { questionList } = this.props;
      // const menu = (
      //   <Menu onClick={this.handleMenuClick}>
      //     <Menu.Item key="1">
      //       <Icon type="user" />
      //       1st menu item
      //     </Menu.Item>
      //     <Menu.Item key="2">
      //       <Icon type="user" />
      //       2nd menu item
      //     </Menu.Item>
      //     <Menu.Item key="3">
      //       <Icon type="user" />
      //       3rd item
      //     </Menu.Item>
      //   </Menu>
      // );
      const columns = [
        {
          title: "Text",
          dataIndex: "text",
          key: "text",
        },
        {
          title: "Help Text",
          dataIndex: "helpText",
          key: "helpText"
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt"
        },
        {
          title: "Active",
          dataIndex: "isActive",
          key: "isActive",
          render: state => {
            return state ? (
              <Badge status="success" />
            ) : (
              <Badge status="default" />
            );
          }
        },
        {
          title: "Multi Select",
          dataIndex: "isMultiSelect",
          key: "isMultiSelect",
          render: state => {
            return state ? (
              <Badge status="success" />
            ) : (
              <Badge status="default" />
            );
          }
        },
        {
          title: "Range",
          dataIndex: "range",
          key: "range",
          render: state => {
            return state ? (
              <Badge status="success" />
            ) : (
              <Badge status="default" />
            );
          }
        }, 
        {
          title: "Last Question",
          dataIndex: "isLastQuestion",
          key: "isLastQuestion",
          render: state => {
            return state ? (
              <Badge status="success" />
            ) : (
              <Badge status="default" />
            );
          }
        },
      ];
      let headers= columns.map(item=>{
        return {label:item.title,
           key:item.key}
       })
      const mappedQuestionList =
        questionList &&
        questionList.map(question => {
        return {
          id: question.id,
          text: question.text,
          helpText: question.helpText,
          createdAt: moment(question.createAt).format("DD MMMM YYYY"),
          isActive: question.isActive,
          isMultiSelect: question.isMultiSelect,
          range: question.optionsAreRange,
          isLastQuestion: question.isLastQuestion
        };
      });
      const csvData = mappedQuestionList.map((item) => {
        return {
          ...item,
          isActive: item.isActive ? "true" : "false",
          isLastQuestion: item.isLastQuestion ? "true" : "false",
          isMultiSelect: item.isMultiSelect ? "true" : "false",
          range: item.range ? "true" : "false",
        };
      }).slice(0,this.state.numberOfRows);
      return (
        <div className="assessment-list-page" style={{ marginLeft: "200px" }}>
          <Link to="/assessment">
            <div className="flex justify-start items-center mb-10">
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="text-14 pb-2">Back</div>
            </div>
          </Link>
          <div className="flex items-center mb-36">
            <div className="theme-color semibold text-30 mr-14">
              Question List
            </div>
            <div className="flex items-center cursor-pointer" 
            onClick={() => {
            this.setState({showExportModal:true})
            }}>
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
          </div>

          <div className="flex nav-container justify-between">
            <div className="sub-text-top-left theme-color">All</div>
            <Button
              type="primary"
              onClick={() => {
                const { pathname } = this.props.location;
                const win = window.open(`${pathname}/edit`, "_blank");
                win.focus();
                //this.props.history.push(`${pathname}/edit`);
              }}
              style={{ marginTop: "-14px" }}
            >
              <Icon type="plus"></Icon>
              Add Question
            </Button>
          </div>

          <div>
            <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
              {/* <Search
                placeholder="Search question"
                onSearch={value => this.handleSearch(value)}
                style={{ width: "57.14%", marginRight: "5px" }}
                onKeyUp={e => this.handleSearch(e.target.value)}
              />
              <Dropdown overlay={menu}>
                <Button style={{ flex: 1, marginRight: "5px" }}>
                  Filters <Icon type="down" />
                </Button>
              </Dropdown>
              <Button
                style={{ display: "flex", alignItems: "center" }}
                onClick={this.handleSort}
              >
                <span className="mr-4">Sort</span>
                <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
              </Button> */}
            </div>
            <div style={{ background: "white" }}>
              <Table
                rowClassName="cursor-pointer"
                columns={columns}
                dataSource={mappedQuestionList}
                bordered
                size="small"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.handleEditQuestions(record);
                    },
                  };
                }}
              />
            </div>
          </div>
          <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={()=>{this.setState({showExportModal:false})}}
          onOk={async ()=>{
            this.setState({exportModalLoading:true})
            this.setState({exportModalLoading:false,showExportModal:false})
            this.csvRef.current && this.csvRef.current.link.click()
          }}
        >
          <InputNumber min={1} defaultValue={this.state.numberOfRows}  onChange={this.onChangeInput}/>
        </Modal>
        <CSVLink data={csvData} headers={headers} ref={this.csvRef} style={{display:"none"}}
          filename='questions.csv'/>
        </div>
      );
    }
  }

function mapStateToProps(state) {
  const { Assessment } = state;
  return {
    questionList: Assessment.questionList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuestionList: bindActionCreators(
      actions.getSearchedQuestion,
      dispatch
    )  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
