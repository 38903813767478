import { Api } from "../config/request";

const getReviewById = id => {
  const url = `reviews/${id}`;
  return Api.getRequest(url);
};

const getReviewsList = query => {
  const url = `reviews/list-card`;
  return Api.getListRequest(url);
};

const createReview = (payload) => {
  const url = `reviews/`;
  return Api.postRequest(url, payload)
}

const updateReviewById = (id, payload) => {
  const url = `reviews/${id}`;
  return Api.patchRequest(url, payload);
}

const deleteReviewById = id => {
  const url = `reviews/${id}`;
  return Api.deleteRequest(url);
}

const Review = {
  getReviewById,
  getReviewsList,
  createReview,
  updateReviewById,
  deleteReviewById
};

export default Review;
