import React, { Component } from "react";
import "./list.scss";
import {
  Table,
  Tag,
  Input,
  Dropdown,
  Button,
  Icon,
  Menu,
  Select,
  Modal,
  InputNumber,
} from "antd";
import SortIcon from "static/img/icon/sortIcon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/benefits";
import * as tagsActions from "actions/tags";
import { debounce } from "utils/helpers";
import { CSVLink } from "react-csv";
const { Search } = Input;
const { Option } = Select;

class ListBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      sortOrder: 1,
      showExportModal: false,
      exportModalLoading: false,
      numberOfRows: 10,
    };
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    this.props.fetchListBenefits().then(() => this.props.fetchAllTags());
  }
  onChangeInput = (value) => {
    this.setState({ numberOfRows: value });
  };
  onChange = (e) => {
    console.log(e);
  };
  handleMenuClick = (e) => {
    console.log(e);
  };
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        this.props.fetchListBenefits(undefined, value);
      }
    );
  }, 300);
  handleSort = (value) => {
    this.setState(
      (prevState) => {
        return {
          sortOrder: prevState.sortOrder === "1" ? "-1" : "1",
        };
      },
      () =>
        this.props.fetchListBenefits(
          undefined,
          undefined,
          undefined,
          this.state.sortOrder
        )
    );
  };
  handleFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.fetchListBenefits(value);
      }
    );
  };
  handleTagSearch = (value) => {
    const tagObj = value
      .map((x) => {
        let item = JSON.parse(x);
        return item._id;
      })
      .join(",");
    this.props.fetchListBenefits(undefined, undefined, tagObj);
  };
  render() {
    const columns = [
      // {
      // title: () => <Checkbox onChange={this.onChange}></Checkbox>,
      // dataIndex: "Icon",
      // key: "Icon",
      // render: () => <Checkbox onChange={this.onChange}></Checkbox>
      // },
      {
        title: "Photo",
        dataIndex: "Photo",
        key: "Photo",
        render: (photo) => {
          return (
            <img
              src={photo.mediaUrl}
              style={{ maxHeight: "60px" }}
              alt="benefits"
            />
          );
        },
      },
      {
        title: "Benefit",
        dataIndex: "BenefitsName",
        key: "BenefitsName",
      },
      // {
      //   title: "Short Description",
      //   dataIndex: "ShortDescription",
      //   key: "ShortDescription",
      //   render: (desc) => {
      //     return <div dangerouslySetInnerHTML={{ __html: desc }} />;
      //   },
      // },
      // {
      //   title: "Long Description",
      //   dataIndex: "LongDescription",
      //   key: "LongDescription",
      //   render: (desc) => {
      //     return <div dangerouslySetInnerHTML={{ __html: desc }} />;
      //   },
      // },
      {
        title: "Tags",
        key: "tags",
        dataIndex: "tags",
        render: (tags) => (
          <span>
            {tags.map((tag, i) => {
              return (
                <Tag className="mb-4" key={i}>
                  {tag === Object(tag) ? tag.name : ""}
                </Tag>
              );
            })}
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
    ];
    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });
    const mappedBenefitsList =
      this.props.benefitsList.length > 0
        ? this.props.benefitsList.map((benefits) => {
            return {
              id: benefits.id,
              Icon: "",
              Photo: benefits.mainImage,
              BenefitsName: benefits.name,
              ShortDescription: benefits.shortDescription,
              LongDescription: benefits.longDescription,
              tags: benefits.tags,
              type: benefits.type === "active" ? "Active" : "Inactive",
            };
          })
        : [];
    const csvData = mappedBenefitsList
      .map((item) => {
        return {
          ...item,
          Photo: item.Photo ? item.Photo.mediaUrl : "-",
          tags: item.tags
            .map((tag) => {
              return tag === Object(tag) ? tag.name : "";
            })
            .join(","),
        };
      })
      .slice(0, this.state.numberOfRows);
    const filterMenu = (
      <Menu
        onClick={(e) => this.handleFilter(e.key)}
        selectedKeys={[this.state.activeFilter || ""]}
      >
        <Menu.Item key="active">Active Benefits</Menu.Item>
        <Menu.Item key="inactive">Inactive Benefits</Menu.Item>
      </Menu>
    );

    const advSearch = (
      <Select
        // defaultValue="tag1"
        placeholder="Search by tag"
        style={{ width: "25%", marginRight: "5px", overflow: "scroll" }}
        onChange={this.handleTagSearch}
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {this.props.tags &&
          this.props.tags.map((tag, i) => (
            <Option value={JSON.stringify(tag)} key={i}>
              {tag.name}
            </Option>
          ))}
      </Select>
    );

    return (
      <div className="benefits-page-list" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div
            className="theme-color semibold text-30 mr-14"
            style={{ marginBottom: "0px" }}
          >
            Benefits List
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              this.setState({ showExportModal: true });
            }}
          >
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>

        <div className="flex nav-container justify-between">
          <div className="sub-text-top-left theme-color">All</div>
          <Button
            type="primary"
            onClick={() => {
              const win = window.open(`/benefits/add`, "_blank");
              win.focus();
              //this.props.history.push("/benefits/add")
            }}
            style={{ marginTop: "-14px" }}
          >
            <Icon type="plus"></Icon>
            Add Benefits
          </Button>
        </div>

        <div>
          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            <Search
              placeholder="Search benefit name"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              onKeyUp={(e) => this.handleSearch(e.target.value)}
            />
            {advSearch}
            <Dropdown overlay={filterMenu}>
              <Button style={{ flex: 1, marginRight: "5px" }}>
                Filters <Icon type="down" />
              </Button>
            </Dropdown>
            <Button
              style={{ width: "12.50%", display: "flex", alignItems: "center" }}
              onClick={this.handleSort}
            >
              <span className="mr-4">Sort</span>
              <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
            </Button>
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedBenefitsList}
              bordered
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    const win = window.open(`/benefits/edit/${record.id}`, "_blank");
                    win.focus();
                  },
                };
              }}
              size="small"
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={() => {
            this.setState({ showExportModal: false });
          }}
          onOk={async () => {
            this.setState({ exportModalLoading: true });
            this.setState({
              exportModalLoading: false,
              showExportModal: false,
            });
            this.csvRef.current && this.csvRef.current.link.click();
          }}
        >
          <InputNumber
            min={1}
            defaultValue={this.state.numberOfRows}
            onChange={this.onChangeInput}
          />
        </Modal>
        <CSVLink
          data={csvData}
          headers={headers}
          ref={this.csvRef}
          style={{ display: "none" }}
          filename="benefits.csv"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Benefits, Tags } = state;
  return {
    benefitsList: Benefits.benefits,
    tags: Tags.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListBenefits: bindActionCreators(
      actions.getSearchedBenefits,
      dispatch
    ),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListBenefits);
