
const initialState = {
  recommendations: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "LIST_RECOMMENDATIONS":
      return listRecommendations(state, action);
    case "ADD_RECOMMENDATION":
      return addRecommendation(state, action);
    default:
      return state;
  }
}


function listRecommendations(state, action) {
  return {
    ...state, 
    recommendations: [ ...action.payload ]
  };
}

function addRecommendation(state, action) {
  return {
    ...state,
  };
}

