import fulfillmentApi from "services/fulfillment";
function handleError(err) {
  console.error(err);
}

export const createShipment = (orderId) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.createShipment(orderId);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const generateShipmentLabel = (orderId) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.generateShipmentLabel(orderId);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markShipmentToShipped = (orderId) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToShipped(orderId);
    return res;
  } catch (err) {
    handleError(err);
  }
};

//createShipmentInBulk,

export const createShipmentInBulk = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.createShipmentInBulk(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const generateLabelInBulk = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.generateLabelInBulk(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getShipmentByOrderId = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.getShipmentByOrderId(orderIds);
    console.log("getShipmentByOrderId", res);
    dispatch(getShipmentOrder(res.courier));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export function getShipmentOrder(payload) {
  return {
    type: "GET_SHIPMENT_COURIER",
    payload,
  };
}

export const markShipmentToReadyForPack = (orderId) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToReadyForPack(orderId);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markShipmentToDelivered = (orderId) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToDelivered(orderId);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markShipmentToDeliveredMultiple = (orderIds) => async (
  dispatch
) => {
  try {
    let res = await fulfillmentApi.markShipmentToDeliveredMultiple(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};
export const markShipmentToShippedMultiple = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToShippedMultiple(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};
export const markShipmentToReadyForPackMultiple = (
  payload,
 
) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToReadyForPackMultiple(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const requestPickup = (orderIds, pickupTime )=> async (dispatch) =>{
  try{
    console.log("ACTION",orderIds, pickupTime);
    let res = await fulfillmentApi.requestPickup({orderIds, pickupTime});
    return res;
  }catch(err){
     handleError(err);
  }
  
}

export const getActiveProduct = () => async (dispatch) => {
  try {
    let res = await fulfillmentApi.getActiveProduct();
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markShipmentToHoldMultiple = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToHoldMultiple(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markOrderReturnedMultiple = (payload) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markOrderReturnedMultiple(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markShipmentToCancelMultiple = (payload) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentToCancelMultiple(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markDisposedMultipleShipment = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markDisposedMultipleShipment(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const markShipmentPackedMultiple = orderIds => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentPackedMultiple(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
} ;

export const markShipmentPackingMultiple = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.markShipmentPackingMultiple(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const reGenerateWaybillMultiple = (orderIds) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.reGenerateWaybillMultiple(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateTrackingDetail = (payload, callback) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.updateTrackingDetail(payload);
    callback();
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const addTrackingDetail = (payload, callback) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.addTrackingDetail(payload);
    callback();
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateShipmentLabel = (orderId, payload) => async (dispatch) => {
  try {
    let res = await fulfillmentApi.updateShipmentLabel(orderId,payload);
    //callback();
    return res;
  } catch (err) {
    handleError(err);
  }
};
