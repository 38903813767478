
const initialState = {
  ingredients: [],
  activeIngredient: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_INGREDIENT": 
      return setActiveIngredient(state, action);
    case "LIST_INGREDIENTS":
      return listIngredients(state, action);
    case "ADD_INGREDIENT":
      return addIngredient(state, action);
    default:
      return state
  }
}

function setActiveIngredient(state, action) {
  return {
    ...state,
    activeIngredient: { ...action.payload }
  }
}

function listIngredients(state, action) {
  return {
    ...state, 
    ingredients: [ ...action.payload ]
  };
}

function addIngredient(state, action) {
  return {
    ...state,
    activeIngredient: { ...action.payload }
  };
}
