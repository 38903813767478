
const initialState = {
  products: [],
  activeProduct: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_PRODUCT":
      return setActiveProduct(state, action);
    case "LIST_PRODUCTS":
      return listProducts(state, action);
    case "ADD_PRODUCT":
      return addProduct(state, action);
    default:
      return state;
  }
}

function setActiveProduct(state, action) {
  return {
    ...state,
    activeProduct: action.payload ? { ...action.payload } : null
  }
}

function listProducts(state, action) {
  return {
    ...state, 
    products: [ ...action.payload ]
  };
}

function addProduct(state, action) {
  return {
    ...state,
    activeProduct: [ ...action.payload ]
  };
}