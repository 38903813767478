import { Api } from "../config/request";

export const createShipment = (id, payload) => {
  let url = `fulfillment/admin/create-shipment/${id}`;
  return Api.postRequest(url);
};

export const generateShipmentLabel = (orderId, payload) => {
  let url = `fulfillment/admin/generate-label/${orderId}`;
  return Api.postRequest(url);
};

export const markShipmentToShipped = (orderId, payload) => {
  let url = `fulfillment/admin/mark-shipment-shipped/${orderId}`;
  return Api.postRequest(url);
};

export const markShipmentToDelivered = (orderId, payload) => {
  let url = `fulfillment/admin/mark-shipment-delivered/${orderId}`;
  return Api.postRequest(url);
};

export const markShipmentToReadyForPack = (orderId, payload) => {
  let url = `fulfillment/admin/mark-shipment-ready-for-pack/${orderId}`;
  return Api.postRequest(url);
};

export const markShipmentToShippedMultiple = (orderIds, payload) => {
  let url = `fulfillment/admin/multiple-shipment-shipped`;
  return Api.postRequest(url, orderIds);
};

export const markShipmentToDeliveredMultiple = (orderIds, payload) => {
  let url = `fulfillment/admin/multiple-shipment-delivered`;
  return Api.postRequest(url, orderIds);
};

export const markShipmentToReadyForPackMultiple = (payload) => {
  let url = `fulfillment/admin/multiple-shipment-ready-for-pack`;
  return Api.postRequest(url, payload);
};

export const markShipmentPackedMultiple = (orderIds, payload) =>{
  let url = `fulfillment/admin/multiple-shipment-packed`;
  return Api.postRequest(url, orderIds);
};

export const markShipmentPackingMultiple = (orderIds, payload) => {
  let url = `fulfillment/admin/multiple-shipment-packing`;
  return Api.postRequest(url, orderIds);
};

export const reGenerateWaybillMultiple= (orderIds, payload) => {
  let url = `fulfillment/admin/regenerate-waybill`;
  return Api.postRequest(url, orderIds);
};


export const createShipmentInBulk = (orderIds, payload) => {
  let url = `fulfillment/admin/create-bulk-shipment`;
  return Api.postRequest(url, orderIds);
};

export const generateLabelInBulk = (orderIds, payload) => {
  let url = `fulfillment/admin/generate-bulk-label`;
  return Api.postRequest(url, orderIds);
};

export const getShipmentByOrderId = (orderId) => {
  let url = `fulfillment/admin/get-shipment/${orderId}`;
  return Api.getRequest(url);
};

export const markOrderReturnedMultiple = (paylaod) => {
  let url = `fulfillment/admin/mark-order-returned`;
  return Api.postRequest(url, paylaod);
};

;

export const requestPickup = (payload) => {
  let url = `fulfillment/admin/requestPickup`;
  console.log(payload);
  return Api.postRequest(url, payload);
};

export const getActiveProduct = () =>{
   let url = `catalogue/product/list-products`;
  return Api.getRequest(url);
}

export const addTrackingDetail = (payload) => {
  let url = `fulfillment/admin/add-tracking-details`;
  return Api.postRequest(url, payload);
};

export const updateTrackingDetail = (payload) => {
  let url = `fulfillment/admin/update-tracking-details`;
  return Api.postRequest(url, payload);
};

export const markShipmentToHoldMultiple = (orderIds, payload) => {
  let url = `fulfillment/admin/mark-shipment-on-hold`;
  return Api.postRequest(url, orderIds);
};

export const markShipmentToCancelMultiple = ( payload) => {
  let url = `fulfillment/admin/mark-shipment-canceled`;
  return Api.postRequest(url, payload);
};

export const markDisposedMultipleShipment = (orderIds, payload) => {
  let url = `fulfillment/admin/mark-shipment-disposed`;
  return Api.postRequest(url, orderIds);
};

export const updateShipmentLabel = ( orderId, payload) =>{
  let url = `fulfillment/admin/updateShipmentLabel/${orderId}`;
  return Api.postRequest(url, payload);
}

const FulfillmentAPI = {
  createShipment,
  generateShipmentLabel,
  markShipmentToShipped,
  createShipmentInBulk,
  generateLabelInBulk,
  getShipmentByOrderId,
  markShipmentToReadyForPack,
  markShipmentToDelivered,
  markShipmentToReadyForPackMultiple,
  markShipmentToShippedMultiple,
  markShipmentToDeliveredMultiple,
  updateTrackingDetail,
  addTrackingDetail,
  markShipmentToHoldMultiple,
  markShipmentToCancelMultiple,
  markDisposedMultipleShipment,
  markShipmentPackedMultiple,
  markOrderReturnedMultiple,
  markShipmentPackingMultiple,
  reGenerateWaybillMultiple,
  requestPickup,
  updateShipmentLabel,
  getActiveProduct,
};

export default FulfillmentAPI;
