import React from "react";
import { Button, Form, Input, Select, Switch } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as customerActions from "actions/customer";
const { Option } = Select;

const CreateAddressForm = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.activeAddress && props.customerId) {
          values["user"] = props.customerId;
          if( props.addressType === "ShippingAddress"){
            props
              .updateShippingAddress(
                props.orderId,
                props.activeAddressId,
                values
              )
              .then((res) => {
                props.onAddressCreation(res);
              });
          }else{
            props.updateAddress(props.activeAddressId, values).then((res) => {
              props.onAddressCreation(res);
            });
          }
          
        } else {
          values["user"] = props.customerId;
          props.addAddress(values).then((res) => {
            props.onAddressCreation(res);
          });
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const { activeAddress } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Address Type">
        {getFieldDecorator("title", {
          initialValue: activeAddress ? activeAddress.title : undefined,
          rules: [
            {
              required: true,
              message: "Please select your address type",
            },
          ],
        })(
          <Select placeholder="Please Select Address Type">
            <Option value="home">Home</Option>
            <Option value="work">Work</Option>
            <Option value="others">other</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="First Name">
        {getFieldDecorator("firstName", {
          initialValue: activeAddress ? activeAddress.firstName : undefined,
          rules: [
            {
              required: true,
              message: "Please enter firstName",
            },
          ],
        })(<Input placeholder="Please enter firstName" />)}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator("lastName", {
          initialValue: activeAddress ? activeAddress.lastName : undefined,
          rules: [
            {
              required: true,
              message: "Please enter lastName",
            },
          ],
        })(<Input placeholder="Please enter lastName" />)}
      </Form.Item>
      <Form.Item label="Street / Area - (Address Line 1)">
        {getFieldDecorator("street", {
          initialValue: activeAddress ? activeAddress.street : undefined,
          rules: [
            {
              required: true,
              message: "Please enter Apartment / Block!",
            },
          ],
        })(<Input placeholder="Enter Street / Area" />)}
      </Form.Item>
      <Form.Item label="Apartment / Block - (Address Line 2)">
        {getFieldDecorator("apartment", {
          initialValue: activeAddress ? activeAddress.apartment : undefined,
          rules: [
            {
              required: true,
              message: "Please enter Apartment / Block!",
            },
          ],
        })(<Input placeholder="Enter Apartment / Block" />)}
      </Form.Item>

      <Form.Item label="Address Line 3 (Optional) ">
        {getFieldDecorator("addressLine3", {
          initialValue: activeAddress ? activeAddress.addressLine3 : undefined,
          rules: [
            {
              required: false,
              message: "Please enter addressLine3",
            },
          ],
        })(<Input placeholder="Enter addressLine3" />)}
      </Form.Item>
      <Form.Item label="City">
        {getFieldDecorator("city", {
          initialValue: activeAddress ? activeAddress.city : undefined,
          rules: [
            {
              required: true,
              message: "Please Enter City",
            },
          ],
        })(<Input placeholder="Enter City" />)}
      </Form.Item>
      <Form.Item label="Zipcode">
        {getFieldDecorator("zipCode", {
          initialValue: activeAddress ? activeAddress.zipCode : undefined,
          rules: [
            {
              required: true,
              message: "Please Enter Zipcode",
            },
          ],
        })(<Input placeholder="Enter Zipcode" />)}
      </Form.Item>
      <Form.Item label="State">
        {getFieldDecorator("state", {
          initialValue: activeAddress ? activeAddress.state : undefined,
          rules: [
            {
              required: true,
              message: "Please Enter State",
            },
          ],
        })(<Input placeholder="Enter State" />)}
      </Form.Item>
      <Form.Item label="Country">
        {getFieldDecorator("country", {
          initialValue: activeAddress ? activeAddress.country : undefined,
          rules: [
            {
              required: true,
              message: "Please Enter Country",
            },
          ],
        })(<Input placeholder="Enter Country" />)}
      </Form.Item>
      {props.showPrimaryKey && (
        <Form.Item label="Is this your primary address">
          {getFieldDecorator("isPrimary", {
            valuePropName: "checked",
            initialValue: activeAddress ? activeAddress.isPrimary : false,
            rules: [
              {
                required: true,
              },
            ],
          })(<Switch />)}
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const createAddressForm = Form.create({ name: "create_address_form" })(
  CreateAddressForm
);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    addAddress: bindActionCreators(customerActions.createNewAddress, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(createAddressForm);
