import React, { memo } from "react";

const AssessmentSummary = ({ activeAssessment, openLightBox }) => {
  return (
    <div className="assessment-card">
      <div
        style={{ paddingBottom: "10px" }}
        className="flex justify-between regular items-center"
      >
        <h4 className="text-base bold" style={{ color: "#262626" }}>
          Assessment Summary
        </h4>
      </div>
      <div
        style={{ paddingTop: "0px" }}
        className="question-count flex items-center"
      >
        <div className="a-question text-16 key">Assessment Status:</div>
        <div
          style={{ paddingLeft: "5px" }}
          className="a-question text-16 value"
        >
          {activeAssessment.status && activeAssessment.status
            ? activeAssessment.status
            : "-"}
        </div>
      </div>
      <div
        style={{ paddingTop: "15px" }}
        className="question-count flex items-center"
      >
        <div className="a-question text-16 key">Weather Completed:</div>
        <div
          style={{ paddingLeft: "5px" }}
          className="a-question text-16 value"
        >
          {activeAssessment.isWeatherCompleted ? "Yes" : "No"}
        </div>
      </div>
      <div
        style={{ paddingTop: "15px" }}
        className="question-count flex items-center"
      >
        <div className="a-question text-16 key">Location Input:</div>
        <div
          style={{ paddingLeft: "5px" }}
          className="a-question text-16 value"
        >
          {(activeAssessment &&
            activeAssessment.weatherData &&
            activeAssessment.weatherData.data.location) ||
            "Not set"}
        </div>
      </div>

      <div
        style={{ paddingTop: "15px" }}
        className="question-count flex items-center"
      >
        <div className="a-question text-16 key">Selfie Url:</div>

        <div
          style={{ paddingLeft: "5px" }}
          className={activeAssessment.selfieURL ? "selfie-url-hyperlink" : ""}
          onClick={openLightBox}
        >
          {" "}
          {activeAssessment.selfieURL
            ? activeAssessment.selfieURL + ".jpg"
            : "Not Available"}
        </div>
      </div>
    </div>
  );
};

export default memo(AssessmentSummary);
