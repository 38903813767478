
const initialState = {
  adminList: [],
  activeAdmin: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_ADMIN":
      return setActiveAdmin(state, action);
    case "LIST_ADMIN":
      return listAdmin(state, action);
    case "ADD_ADMIN":
      return addAdmin(state, action);
    case "UPDATE_ADMIN_LIST":
      return updateAdminList(state, action);
    default:
      return state;
  }
}

function setActiveAdmin(state, action) {
  return {
    ...state,
    activeAdmin: { ...action.payload }
  }
}

function listAdmin(state, action) {
  return {
    ...state, 
    adminList: [ ...action.payload ]
  };
}

function addAdmin(state, action) {
  return {
    ...state,
    adminList: [ { ...action.payload }, ...state.adminList ]
  };
}

function updateAdminList(state, action) {

  const updatedAdminList = [...state.adminList].map( admin => admin.id === action.payload.id ? {...action.payload} : admin);
  return {
    ...state,
    adminList: updatedAdminList
  };
}
