const initialState = {
  tax: [],
  activeTag: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LIST_TAX":
      return listTax(state, action);
    case "ADD_TAX":
      return addTax(state, action);
    case "UPDATE_TAX_LIST":
      return updateTaxList(state, action);
    case "SET_ACTIVE_TAX":
      return setActiveTax(state, action);
    default:
      return state;
  }
}

function listTax(state, action) {
  return {
    ...state,
    tax: [...action.payload],
  };
}

function addTax(state, action) {
  return {
    ...state,
    tax: [{ ...action.payload }, ...state.tax],
  };
}

function setActiveTax(state, action) {
  return {
    ...state,
    activeTax: { ...action.payload },
  };
}

function updateTaxList(state, action) {
  const updatedTaxList = [...state.tax].map((tax) =>
    tax.id === action.payload.id ? { ...action.payload } : tax
  );
  return {
    ...state,
    tax: updatedTaxList,
  };
}
