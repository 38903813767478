import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Table,
  Button,
  Icon,
  Input,
  notification,
  Menu,
  Dropdown,
  message,
  Tag,
  Spin,
  Modal,
  Checkbox,
  DatePicker,
  Badge,
  Tooltip,
  Select,
  Form,
} from "antd";

import "./../listOrders/listOrders.scss";

import LoadingOverlay from "react-loading-overlay";

//import { connect } from "react-redux";
//import { bindActionCreators } from "redux";
import * as actions from "actions/orders";
import * as fulfillmentActions from "actions/fulfillment";
import {
  debounce,
  formatAddress,
  getPaymentMethod,
  handleDownload,
  addQueryParam,
  capitalizeFirstLetter,
} from "utils/helpers";
import {
  paymentStatusMapping,
  orderFullfillmentStatusListing,
  orderFullfillmentStatusEmoji,
  orderFullfillmentStatusMapping,
  orderFulfillmentStatusLabel,
  oderTypeMapper,
  orderTypeFilter,
  orderStatusFilterMapping,
  trackingDetailsCarriers,
  orderStatusMapping,
  orderPaymentCompleteStatusFilterMapping,
  whatsAppMessageStatus,
  countryFilterMapping,
  FULFILLMENT_ERROR_STATUS,
  orderFlaggedStatus,
} from "utils/constants";
import OrdersHeader from "../../../components/orders/OrdersHeader";
import FlaggedModal from "components/orders/FlaggedModal";
import * as assessmentActions from "actions/assessment";
import LoaderImg from "static/img/icon/Spinner-1s-200px.gif";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;

const FULFILLMENT_ACTION_OPTION = [
  {
    key: "order_ready_pack",
    label: "Ready to Pack  👍",
  },
  // {
  //   key: "order_hold",
  //   label: "Mark on Hold",
  // },
  // {
  //   key: "create_waybill",
  //   label: "Create Waybill 📝",
  // },
  // {
  //   key: "generate_label",
  //   label: "Generate Label 📑",
  // },

  // {
  //   key: "order_shipped",
  //   label: "Mark Shipped 🚛",
  // },
  // {
  //   key: "order_delivered",
  //   label: "Mark Delivered 📦",
  // },
  {
    key: "order_hold",
    label: "Mark on Hold ✋",
  },
  {
    key: "order_canceled",
    label: "Mark Cancelled ❎",
  },
  {
    key: "order_flagged",
    label: "Mark Flagged 🚩",
  },
  {
    key: "order_resolved",
    label: "Mark Resolved ✅",
  },
  {
    key: "order_returned",
    label: "Mark Returned 📥",
  },
  {
    key: "order_disposed",
    label: "Mark Disposed ",
  },
];

const Cancel_Reason = [
  {
    key: "Cx - No longer needed",
    label: "Cx - No longer needed ",
  },
  {
    key: "Cx - Financial Reasons",
    label: "Cx - Financial Reasons ",
  },
  {
    key: "Cx - Placed new order",
    label: "Cx - Placed new order ",
  },
  {
    key: "YS - Technical Bug ",
    label: "YS - Technical Bug",
  },
  {
    key: "YS - Duplicate",
    label: "YS - Duplicate",
  },
  {
    key: "YS - INF Order",
    label: "YS - INF Order",
  },
  {
    key: "YS - Test Order",
    label: "YS - Test Order",
  },
];

const RECORD_PER_PAGE = [
  {
    key: "20",
    label: "20",
  },
  {
    key: "30",
    label: "30",
  },
  {
    key: "40",
    label: "40",
  },
  {
    key: "50",
    label: "50",
  },
  {
    key: "100",
    label: "100",
  },
];

const ORDER_ADVANCED_SEARCH_OPTIONS = [
  {
    key: "search",
    label: "Name",
  },
  {
    key: "phone.phone",
    label: "Phone ",
  },
  {
    key: "user.id",
    label: "User ID",
  },
  {
    key: "search",
    label: "Email",
  },
  {
    key: "trackingNumber",
    label: "Tracking No",
  },
  {
    key: "attempt",
    label: "Submission ID",
  },
  {
    key: "shortId",
    label: "Order ID",
  },
  {
    key: "promoCode",
    label: "Discount Code",
  },
  {
    key: "tag",
    label: "Tags",
  },
  {
    key: "chargedPrice",
    label: "Charged Price",
  },
  {
    key: "user.location.country",
    label: "Country",
  },

  {
    key: "paymentEmail",
    label: "Partner Email",
  },
];

const INVOICE_DOWNLOAD_ACTION_OPTION = [
  {
    key: "view invoice",
    label: "View Invoice",
  },
  {
    key: "download invoice",
    label: "Download Invoice",
  },
];

const WHATSAPP_STATUS_ACTION_OPTION = [
  {
    key: "sent",
    label: "Sent",
    code: 2,
    color: "green",
  },
  {
    key: "not_on_whatsapp",
    label: "Not on Whatsapp",
    code: 3,
    color: "red",
  },
  {
    key: "purchased_already",
    label: "Purchased Already",
    code: 4,
    color: "green",
  },
  {
    key: "Existing_chat",
    label: "Existing Chat",
    code: 5,
    color: "blue",
  },
];

const basicAdminColumnsFilters = [
  "all",
  "submissionDate",
  "submissionTime",
  "latest_submission",
  "orderStatus",
  "orderId",
  "attempt",
  "name",
  // "whatsAppMsgStatus",
  "country",
  "chargedPrice",
  "fulfillmentStatus",
  "paymentEmail",
  "code",
  // "userId",
  "itemsPurchased",
  "shippingAddress",
  //"pdfLink",
  "paymentMethod",
  //"giftSet",
  "customerNotes",
  "subscriptionType",
  "giftSetNote",
  "goGreenPackaging",
  "type",
  //"itemsQuantity",
  //"fulfillmentErr",
  "phone",
];

const adminColumnsFilter = [
  "all",
  "orderId",
  "name",
  "email",
  "attempt",
  "latest_submission",
  "submissionDate",
  "submissionTime",
  "chargedPrice",
  "code",
  //"userId",
  "itemsPurchased",
  //"pdfLink",
  "orderStatus",
  "fulfillmentStatus",
  "stripeInvoiceId",
  "type",
  //"tags",
  "notes",
  "customerNotes",
  "phone",
  "whatsAppMsgStatus",
  "shippingAddress",
  "shipmentId",
  //"shipmentLabel",
  "country",
  //"totalWeight",
  "itemsQuantity",
  "action",
  "paymentMethod",
  //"giftSet",
  "giftSetNote",
  //"goGreenPackaging",
  "newOrderStatus",
  "subscriptionType",
  "partnerEmail",
  //"fulfillmentErr",
  "daySerum",
  "dayCream",
  "nightSerum",
  "nightCream",
  "SSU",
  "CFC",
  "BBT",
  "ELYES",
  "CSM_FB",
  "Extras",
];

const { confirm } = Modal;
const { SubMenu } = Menu;

const Loader = () => {
  return <img src={LoaderImg} alt="loader" style={{ width: "100%" }} />;
};

class CompletedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrderData: null,
      searchKey: "",
      sortOrder: 1,
      activeAdvSearch: ORDER_ADVANCED_SEARCH_OPTIONS[3],
      activeCountryFilter: [],
      recordsPerpage: RECORD_PER_PAGE[0],
      alternateLabelAU: false,
      selectedOrderIds: [],
      userType: "",
      activeFulfillMentFilters: [],
      activeStatusFilters: [],
      fulfillmentErrorStatus: [],
      shownColumns: this.getFilters(),
      showRelatedOrdersModal: false,
      checked: true,
      disabled: false,
      showExportModal: false,
      isExportingData: false,
      exportModalLoading: false,
      startDate: undefined,
      activeOrderTypeFilter: [],
      endDate: undefined,
      showAssessmentModal: false,
      customerTotalOrder: 0,
      customerTotalSubscription: 0,
      customerOrderListState: [],
      whatsAppModal: false,
      whatsAppMessageType: "order_completed",
      questions: [
        "How would you best describe your T-Zone in the morning?",
        "How would you best describe your cheeks in the morning?",
        "What are your top 2 skin concerns?",
        "Ever had any adverse reactions to a skincare ingredient?",
        "Which ingredient caused it?",
        "What's your age?",
        "Any product preference you'd like us to consider?",
      ],
      productList: [],
      activeProductList: [],
      fulfillmentModal: false,
      orderToFulfill: {},
      lightBoxOpen: false,
      showLoader: false,
      showFlaggedModal: false,
      showRTPModal: false,
      cancelOrderModal: false,
      showOrderReturnModal: false,
      scheduleRTPTime: "",
      rtpDateCheckbox: false,
      flaggedStatus: {},
    };
    this.csvRef = React.createRef();
    this.orderReturnedReasonRef = React.createRef();
    this.shoppingExportCsvRef = React.createRef();
    this.updateFlaggedStatus = this.updateFlaggedStatus.bind(this);
    this.cancelOrderReasonRef = React.createRef();
  }

  toggleExportingDataState = (state) => {
    //console.log(" state for exporting ",  state )
    this.setState({ isExportingData: state });
  };

  getFilters = () => {
    if (localStorage.getItem("userType") === "basic") {
      return basicAdminColumnsFilters;
    }
    return adminColumnsFilter;
  };

  getProductListOrder(product) {
    let order;
    switch (product) {
      case "Bounce Back Toner":
        order = 1;
        break;
      case "Cloud Factory Cleanser":
        order = 2;
        break;
      case "Eye Love You Eye Serum":
        order = 3;
        break;
      case "Sunny Side Up SPF 30 Mist":
        order = 4;
        break;
      case "Drop of Balance":
        order = 5;
        break;
      case "Drop of Light":
        order = 6;
        break;
      case "Drop of Perfection":
        order = 7;
        break;
      case "Drop of Softness":
        order = 8;
        break;
      case "Drop of Youth":
        order = 9;
        break;
      case "Crazy Rich Moisturiser":
        order = 10;
        break;
      case "Dew Date":
        order = 11;
        break;
      case "Matte & Moist":
        order = 12;
        break;
      case "DND Nights":
        order = 13;
        break;
      case "Milky Way":
        order = 14;
        break;
      // case "Bounce Back Toner":
      //   order = 15;
      //   break;
      // case "Bounce Back Toner":
      //   order = 16;
      //   break;
      // case "Bounce Back Toner":
      //   order = 17;
      //   break;
      // case "Bounce Back Toner":
      //   break;

      default:
        break;
    }
    return order;
  }

  componentDidMount() {
    const { activeStatusFilters } = this.state;
    // setting up userType for not visible actions
    const userType = localStorage.getItem("userType");
    this.setState({
      userType,
    });
    this.props.getActiveProduct().then((res) => {
      let productList = [];
      res.forEach((prod) => {
        //console.log(prod.name);
        if (prod.name !== "Personalised Day and Night Set") {
          let orderNo = this.getProductListOrder(prod.name);
          prod.orderNo = orderNo;
          productList.push(prod);
        }
      });

      productList.sort((a, b) => a.orderNo - b.orderNo);
      this.setState({
        productList: productList,
      });
    });
    // setting up userType for not visible actions
    let queryString = `status=2&status=3&status=4&pageSize=${this.state.recordsPerpage.key}`;
    queryString += addQueryParam("type", this.state.activeOrderTypeFilter);
    this.props.fetchAllOrders(
      queryString,
      this.props &&
        this.props.ordersPageDetails &&
        this.props.ordersPageDetails.currentPage &&
        this.props.ordersPageDetails.currentPage,
      undefined,
      this.state.startDate,
      this.state.endDate,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters
    );
    this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
  }

  getAllOrders = () => {
    this.props.fetchAllOrders(
      `status=2&status=3&status=4`,
      this.props &&
        this.props.ordersPageDetails &&
        this.props.ordersPageDetails.currentPage &&
        this.props.ordersPageDetails.currentPage,
      undefined,
      this.state.startDate,
      this.state.endDate,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters
    );
  };
  // checkbox
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  onColumnFilterChange = (e, item) => {
    // if(item === 'all' && e.target.checked) {
    //   this.setState({
    //     shownColumns: [...this.allColumns]
    //   })
    //   return;
    // }
    // else {
    //   this.setState((prevState, props) => { return {
    //     shownColumns: prevState.shownColumns.filter( col => col !== 'all')
    //    }});
    // }
    if (item === "all") {
      if (e.target.checked) {
        this.setState({
          shownColumns: [...this.getFilters()],
        });
      } else {
        this.setState({
          shownColumns: [],
        });
      }
      return;
    } else {
      this.setState((prevState, props) => {
        return {
          shownColumns: prevState.shownColumns.filter((col) => col !== "all"),
        };
      });
    }
    if (e.target.checked) {
      this.setState((prevState) => {
        let columnsArr = [...prevState.shownColumns, item];
        return {
          shownColumns: columnsArr,
        };
      });
    } else {
      this.setState((prevState) => {
        let columnsArr = prevState.shownColumns.filter((value) => {
          return value !== item;
        });
        return {
          shownColumns: columnsArr,
        };
      });
    }

    this.setState({
      checked: e.target.checked,
    });
  };
  // checkbox

  onChange = (e) => {
    //console.log(e);
  };
  handleMenuClick = (e) => {
    //console.log(e);
  };
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
        selectedOrderIds: [],
      },
      () => {
        const querySearch =
          this.state.searchKey && this.state.activeAdvSearch.key + "=" + value;
        const queryString =
          querySearch + this.getSelectedQueryStringForSearch();
        this.props.fetchAllOrders(
          queryString,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  }, 300);
  handleStatusFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.fetchAllOrders(
          `status=${value}`,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };
  handleStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeStatusFilters.indexOf(value) > -1) {
          newFilterList = state.activeStatusFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeStatusFilters, value];
        }
        return {
          activeStatusFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (index === 0 && this.state.activeStatusFilters.length === 0) {
              return `fulfillmentStatus=${value}`;
            } else {
              return `&fulfillmentStatus=${value}`;
            }
          })
          .join("");
        const filterQueryCountry = this.state.activeCountryFilter
          .map((value, index) => {
            if (index === 0 && this.state.activeStatusFilters.length === 0) {
              return `user.location.country=${value}`;
            } else {
              return `&user.location.country=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          filterQueryStatus +
            filterQueryFulfillment +
            filterQueryCountry +
            "&pageSize=" +
            this.state.recordsPerpage.key,
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleOrderTypeFilter = (value) => {
    this.setState(
      (state) => {
        let newFilterList = [];
        //const indexRow = state.activeProductList.indexOf("ROW");
        if (state.activeOrderTypeFilter.indexOf(value) > -1) {
          newFilterList = state.activeOrderTypeFilter.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeOrderTypeFilter, value];
        }
        window.sessionStorage.setItem("page", 1);
        window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
        return {
          activeOrderTypeFilter: newFilterList,
          pageNo: 1,
          selectedOrderIds: [],
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  isOrderTypeStatusApplied = () => {
    const { activeOrderTypeFilter } = this.state;
    return !!(
      activeOrderTypeFilter?.length > 0 ||
      this.props.activeProductList?.activeOrderTypeFilter?.length > 0
    );
  };

  getOrderTypeStatusCount = () => this.state.activeOrderTypeFilter?.length || 0;

  handleFulfillStatusFilter = (filterKey, value) => {
    //console.log("fuillfillment status=====", value)
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (filterKey === "fulfillmentStatus") {
          if (state.activeFulfillMentFilters.indexOf(value) > -1) {
            newFilterList = state.activeFulfillMentFilters.filter(
              (elem) => elem !== value
            );
          } else {
            newFilterList = [...state.activeFulfillMentFilters, value];
          }
          return {
            activeFulfillMentFilters: newFilterList,
          };
        } else if (filterKey === "fulfillmentError") {
          if (state.fulfillmentErrorStatus.indexOf(value) > -1) {
            newFilterList = state.fulfillmentErrorStatus.filter(
              (elem) => elem !== value
            );
          } else {
            newFilterList = [...state.fulfillmentErrorStatus, value];
          }
          return {
            fulfillmentErrorStatus: newFilterList,
          };
        }
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `status=${value}`;
            } else {
              return `&status=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeFulfillMentFilters.length === 0
            ) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          //filterQueryStatus + filterQueryFulfillment,
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleAdvSearchChange = (value) => {
    //console.log(value);
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };

  handleProductFilter = (value) => {
    this.setState(
      (state) => {
        let newFilterList = [];
        //const indexRow = state.activeProductList.indexOf("ROW");
        if (state.activeProductList.indexOf(value) > -1) {
          newFilterList = state.activeProductList.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeProductList, value];
        }
        //window.sessionStorage.setItem("page", 1);
        //window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
        return {
          activeProductList: newFilterList,
          pageNo: 1,
          selectedOrderIds: [],
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleCountryFilter = (value) => {
    this.setState(
      (state) => {
        let newFilterList = [];
        newFilterList.push(...state.activeCountryFilter);
        const index = state.activeCountryFilter.indexOf(value);
        if (index > -1) {
          newFilterList.splice(index, 1);
        } else {
          const indexRow = state.activeCountryFilter.indexOf("ROW");
          const indexSG_FC = state.activeCountryFilter.indexOf("SG_FC");
          if (
            value === "ROW" ||
            value == "SG_FC" ||
            indexRow > -1 ||
            indexSG_FC > -1
          ) {
            newFilterList = [value];
          } else {
            newFilterList.push(value);
          }
        }
        return {
          activeCountryFilter: newFilterList,
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleDownloadInvoice = (action) => {
    if (this.state.selectedOrderIds.length > 0) {
      let invoiceAction = JSON.parse(action);

      if (invoiceAction.key === "view invoice") {
        window.open(
          `/invoice?orders=${this.state.selectedOrderIds.join()}`,
          "_blank"
        );
      } else if (invoiceAction.key === "download invoice") {
        confirm({
          title: `Are you sure you want to download invoice `,
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            return this.props
              .downloadMultipleInvoice(this.state.selectedOrderIds)
              .then((res) => {
                if (res) {
                  message.success(
                    "Generating files. Download link will be mail to you shortly"
                  );
                } else {
                  message.error("Oops! Something went wrong");
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    }
  };

  handleDeleteOrder = (order) => {
    confirm({
      title: `Are you sure you want to delete this Order?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.deleteOrder(order.id).then((res) => {
          if (res) {
            message.success("Order delete successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  updateFlaggedStatus() {
    this.setState({
      showFlaggedModal: false,
      flaggedStatus: {},
      selectedOrderIds: [],
    });
  }

  handleFlaggedAction = (flaggedStatus) => {
    this.setState({
      showFlaggedModal: true,
      flaggedStatus:
        flaggedStatus === "order_flagged"
          ? orderFlaggedStatus[0]
          : orderFlaggedStatus[1],
    });
  };

  handleFulfillmentAction = (action) => {
    //console.log("======", action, this.state.selectedOrderIds);
    let fulFillmentAction = JSON.parse(action);
    if (
      fulFillmentAction.key === "order_flagged" ||
      fulFillmentAction.key === "order_resolved"
    ) {
      this.handleFlaggedAction(fulFillmentAction.key);
      return;
    }

    if (fulFillmentAction.key === "order_canceled") {
      this.setState({
        cancelOrderModal: true,
      });
      return;
    }

    if (fulFillmentAction.key === "order_ready_pack") {
      this.setState({
        showRTPModal: true,
      });
      return;
    }

    if (fulFillmentAction.key == "order_returned") {
      this.setState({
        showOrderReturnModal: true,
      });
      return;
    }

    let pop_message = "Are you sure you want to proceed?";
    if (fulFillmentAction.key === "order_shipped") {
      pop_message =
        "Are you sure? This will trigger an sms and an email to the customer(s).";
    }
    confirm({
      title: pop_message,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        //console.log("+++++", fulFillmentAction, fulFillmentAction.key);
        if (fulFillmentAction.key === "create_waybill") {
          //console.log("LLLLL", action.key);
          this.setState({
            showLoader: true,
          });
          this.createWaybillForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "generate_label") {
          this.generateLabelForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
          //this.handelGenerateLabel()
        }
        // if (fulFillmentAction.key === "order_ready_pack") {
        //   this.markReadyToPackMultipleShipment({
        //     orderIds: this.state.selectedOrderIds,
        //   });
        // }
        if (fulFillmentAction.key === "order_shipped") {
          this.markShippedMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_delivered") {
          this.markDeliveredMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_hold") {
          this.markHoldMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        // if (fulFillmentAction.key === "order_canceled") {
        //   this.markCanceledMultipleShipment({
        //     orderIds: this.state.selectedOrderIds,
        //   });
        // }
        if (fulFillmentAction.key === "order_disposed") {
          this.markDisposedMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleWhatsAppActionMenue = (action) => {
    //console.log(this.state.selectedOrderIds, action);
    confirm({
      title: `Are you sure you want to change Whatsapp status for this Order
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        let whatsappAction = JSON.parse(action);
        let message = whatsappAction.code;

        return this.props
          .updateMultiOrdersWhatsAppMsgStatus(
            this.state.selectedOrderIds,
            message
          )
          .then((res) => {
            this.getAllOrders();
            //this.props.fetchAllOrders();
          });
      },
      onCancel() {},
    });
  };

  // handleShortAssessmentSummaryModal = (attempt)=>{
  //   console.log(attempt,12)
  //   const {
  //     match: { params },
  //   } = attempt;
  //   this.props.getAssessmentById(attempt);
  //   confirm({
  //     title: `Are you sure you want to delete this Order?`,
  //     okText: "Yes",
  //     cancelText: "No",
  //     // onOk: () => {
  //     //   return this.props.deleteOrder(order.id).then((res) => {
  //     //     if (res) {
  //     //       message.success("Order delete successfully.");
  //     //     } else {
  //     //       message.error("Oops! Something went wrong");
  //     //     }
  //     //   });
  //     // },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // }

  handleShortAssessmentSummaryModal = (attempt, orderData) => {
    const {
      match: { params },
    } = attempt;
    this.props.getAssessmentById(attempt);
    this.setState({ showAssessmentModal: true, selectedOrderData: orderData });
  };

  handleUserLatestAssessmentSummaryModal = (userId, orderData) => {
    if (!userId) {
      message.error("Oops! No user id for this order");
      return;
    }
    this.props.getLatestAssessmentByUserId(userId);
    this.setState({ showAssessmentModal: true, selectedOrderData: orderData });
  };

  showModal = () => {
    this.setState({
      showAssessmentModal: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      showAssessmentModal: false,
      showRelatedOrdersModal: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      showAssessmentModal: false,
      showRelatedOrdersModal: false,
    });
  };

  handelWhatsAppMessage = (order, orderId, phone, msg) => {
    let name = "";
    if (order.user) {
      name = order.user.firstName + " " + order.user.lastName;
    }

    let decoded_msg = decodeURIComponent(msg);
    let new_msg = decoded_msg.replace("{{ first_name }}", name);
    let url = `https://web.whatsapp.com/send?phone=${phone}&text=${new_msg}`;
    //let url = `https://wa.me/${phone}?text=${new_msg}`;

    return this.props
      .updateWhatsAppStatus(orderId, whatsAppMessageStatus.completed_order_send)
      .then((res) => {
        this.getAllOrders();
        message.success(`Whatsapp message send for phone: ${phone}`);
        window.open(url, "_blank");
      });
  };

  handelUpdateFulfillment = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to generate Waybill for this Order
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.createShipment(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Waybill Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelGenerateLabel = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to generate Label for this Order
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.generateShipmentLabel(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Label Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelOrderReadyPack = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to mark Order to ready for pickup
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToReadyForPack(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Order status change to Ready for pickup.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  handelOrderDeliver = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to mark order to deliver
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToDelivered(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Order status change to Ready for pickup.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  handelOrderShipped = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure? This will trigger an sms and an email to the customer(s).`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToShipped(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Shipment shipped  successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelMarkShipmentShipped = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;

    confirm({
      title: `Are you sure? This will trigger an sms and an email to the customer(s).
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToShipped(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Shipment shipped  successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  getCustomerOrder = (customerOrderList, orderData) => {
    //console.log("getCustomerOrder=========", customerOrderList);
    const itemList =
      customerOrderList &&
      customerOrderList.lastOrders &&
      customerOrderList.lastOrders.length > 0
        ? customerOrderList.lastOrders.map((order) => {
            //console.log("HHHHHHHHHHHHH", order);
            return {
              key: order.id,
              order: order,
              orderDate: moment(order.createdAt).format(
                "MMM DD, YYYY [at] hh:mm a"
              ),
              products:
                order.items &&
                order.items.map((item) => item.product.name).join(","),
              paymentStatus: paymentStatusMapping[order.status],
              OrderType: order.orderType,
            };
          })
        : [];
    this.setState({
      customerOrderListState: itemList,
      showRelatedOrdersModal: true,
      customerTotalOrder: customerOrderList.OrdersCount,
      customerTotalSubscription: customerOrderList.subscriptionOrderCount,
      selectedOrderData: orderData,
    });
    return orderData;
  };

  handelRequestForRelatedOrders = (customerId, orderData) => {
    //console.log("handelRequestForRelatedOrders");
    this.props
      .getLastOrdersForCustomer(customerId, customerId, orderData)
      .then((res) => {
        this.getCustomerOrder(res, orderData);
      });
    // this.setState({
    //   showRelatedOrdersModal: true,
    //   selectedOrderData: orderData,
    // });
    //this.props.getLatestAssessmentByUserId
  };

  createWaybillForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;

    this.props.createShipmentInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Waybill created",
            description: ` WayBill created successfully for ${res.success.length} orders`,
            // onClick: () => {
            //   console.log("Notification Clicked!");
            // },
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: (
              <>
                {" "}
                `Fail to create WayBill for {res.fail.length} orders.
                <span style={{ color: "red", cursor: "pointer" }}>
                  {" "}
                  Click to view
                </span>
                `
              </>
            ),
            onClick: () => {
              this.setState(
                (state, props) => {
                  return { fulfillmentErrorStatus: [-1] };
                },
                () => {
                  //console.log("++++++++++",this.state.fulfillmentErrorStatus);
                  this.props.fetchAllOrders(
                    //filterQueryStatus + filterQueryFulfillment,
                    this.getSelectedQueryStringFilter(),
                    0,
                    undefined,
                    this.state.startDate,
                    this.state.endDate
                  );
                }
              );
              //console.log("Notification Clicked!");
            },
          });
        }

        // message.success(
        //   `WayBill success for ${res.success.length} and failed for ${res.fail.length}`
        // );
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  generateLabelForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    //console.log( "generateLabelForMultipleShipment", orderIds )
    this.props.generateLabelInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Label Generated",
            description: ` Label Generated successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: (
              <> `Fail to Generated Label for {res.fail.length} orders.`</>
            ),
          });
        }
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  markOrderReturnedMultiple = (payload) => {
    const { pageNo, startDate, endDate } = this.state;
    //console.log(payload);
    if (!payload.reason) {
      message.error("Enter a valid reason for marking order as returned");
      return;
    }
    this.props.markOrderReturnedMultiple(payload).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      this.setState({
        showOrderReturnModal: false,
      });
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Order returned",
            description: `success for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  markReadyToPackMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    let isSchedule = this.state.rtpDateCheckbox;
    let scheduleTime = this.state.scheduleRTPTime;
    let alternateLabelAU = this.state.alternateLabelAU;
    //console.log("isSchedule", isSchedule, scheduleTime);
    if (isSchedule) {
      if (!scheduleTime) {
        message.error(
          "Schedule date can't be empty in case of scheduling it to ready to pack  ."
        );
      }
    }
    let data = {
      orderIds: this.state.selectedOrderIds,
      isSchedule,
      scheduleTime,
      alternateLabelAU,
    };
    this.props.markShipmentToReadyForPackMultiple(data).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      this.setState({
        showRTPModal: false,
        isSchedule: false,
        scheduleTime: "",
        rtpDateCheckbox: false,
      });
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Ready To pack ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  markShippedMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToShippedMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Order Shipped ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  markDeliveredMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToDeliveredMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Order Delivered ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  markHoldMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToHoldMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Order Mark on Hold ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
        message.error("Oops! Something went wrong");
      }
    });
  };

  markDisposedMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markDisposedMultipleShipment(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Order Disposed ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        message.error("Oops! Something went wrong");
        this.setState({
          showLoader: false,
          //selectedOrderIds: failedIds,
        });
      }
    });
  };
  markCanceledMultipleShipment = (payload) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToCancelMultiple(payload).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
          cancelOrderModal: false,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Order Canceled ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        message.error("Oops! Something went wrong");
        this.setState({
          showLoader: false,
          cancelOrderModal: false,
          //selectedOrderIds: failedIds,
        });
      }
    });
  };

  handleUpdateFullfillmentStatusByBasicUser = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let payload = {
          fulfillmentStatus: 4,
          trackingDetails: values.trackingDetails,
        };
        return this.props
          .updateOrder(this.state.orderToFulfill.id, payload)
          .then((res) => {
            this.props.fetchAllOrders();
            if (!res.error) {
              message.success("Order status changed to shipped");
              this.setState({ fulfillmentModal: false });
            } else {
              message.error(res.error.internalMessage);
            }
          });
      }
    });
  };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  SelectDates = (val) => {
    if (val.length === 0) {
      this.setState(
        {
          startDate: undefined,
          endDate: undefined,
        },
        () => this.props.fetchAllOrders(this.getSelectedQueryStringFilter(), 0)
      );
    } else {
      this.setState(
        {
          startDate: new Date(val[0]).toISOString(),
          endDate: new Date(val[1]).toISOString(),
        },
        () =>
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter("Initial"),
            0,
            undefined,
            this.state.startDate,
            this.state.endDate
          )
      );
    }
  };
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  getOrderType(order) {
    if (order.isSubscribed) {
      if (
        order &&
        order.subscriptionId &&
        order.subscriptionId.period &&
        order.subscriptionId.period.value
      ) {
        if (order.subscriptionId.period.value / 30 > 1) {
          return `Subscription (${
            order.subscriptionId.period.value / 30
          } Months)`;
        }
        return "Subscription (1 Month)";
      }
      return "Subscription";
    }
    return "One-time";
  }

  getPhoneNumber(order) {
    if (order && order.shippingAddress && order.shippingAddress.phone) {
      return (
        order.shippingAddress.phone.isdCode +
        " - " +
        order.shippingAddress.phone.phone
      );
    }
    return "-";
  }

  getSelectedQueryforActions = () => {
    let queryString = "";
    const { activeFulfillMentFilters, activeStatusFilters } = this.state;
    queryString = `status=2`;
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    return queryString;
  };

  // getSelectedQueryStringFilter = (src = "") => {
  //   let queryString = "";
  //   const { activeFulfillMentFilters, activeStatusFilters, activeAdvSearch, searchKey } = this.state;
  //   if (src == "Clear All Filter" || src == "Initial") {
  //     queryString = `status=2&status=3&status=4`;
  //   } else {
  //     if (activeStatusFilters && activeStatusFilters.length > 0) {
  //       activeStatusFilters.forEach((x, index) => {
  //         if (index === 0) {
  //           queryString = `status=${x}`;
  //         } else {
  //           queryString += `&status=${x}`;
  //         }
  //       });
  //     }
  //     if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
  //       activeFulfillMentFilters.forEach((x, index) => {
  //         if (index === 0) {
  //           if (queryString && queryString.length > 0) {
  //             queryString += `&fulfillmentStatus=${x}`;
  //           } else {
  //             queryString = `fulfillmentStatus=${x}`;
  //           }
  //         } else {
  //           queryString += `&fulfillmentStatus=${x}`;
  //         }
  //       });
  //     }
  //     if (queryString && queryString.length > 0) {
  //       queryString = queryString + "&";
  //     }
  //   }
  //   if (searchKey && searchKey.length > 0 && activeAdvSearch && activeAdvSearch.key) {
  //     queryString = `${queryString}${activeAdvSearch.key}=${searchKey}&`
  //   }

  //   console.log("queryString====================================", queryString);
  //   return queryString;
  // };

  getSelectedQueryStringFilter = () => {
    let queryString = "";
    const {
      activeFulfillMentFilters,
      fulfillmentErrorStatus,
      activeStatusFilters,
      activeCountryFilter,
      activeProductList,
      activeOrderTypeFilter,
      searchKey,
      activeAdvSearch,
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `status=2&status=3&status=4`;
    }

    queryString += addQueryParam("user.location.country", activeCountryFilter);
    queryString += addQueryParam("product", activeProductList);
    queryString += addQueryParam("type", activeOrderTypeFilter);
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }
    if (fulfillmentErrorStatus.length > 0) {
      if (queryString && queryString.length > 0) {
        queryString += `&fulfillmentStatus=-1`;
      } else {
        queryString += `fulfillmentStatus=-1`;
      }
      fulfillmentErrorStatus.forEach((x, index) => {
        if (x !== -1) {
          queryString += `&fulfillmentErr=${x}`;
        }
      });
    }
    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}&`;
    }
    if (queryString && queryString.length > 0) {
      //console.log("PAGE size ===== ", this.state.isExportingData);
      if (!this.state.isExportingData) {
        queryString =
          queryString + "&pageSize=" + this.state.recordsPerpage.key + "&";
      }
    }
    return queryString;
  };
  isFullfillmentStatusApplied = () => {
    const { activeFulfillMentFilters } = this.state;
    if (
      (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  isProductStatusApplied = () => {
    const { activeProductList } = this.state;
    return !!(
      activeProductList?.length > 0 ||
      this.props.activeProductList?.activeProductList?.length > 0
    );
  };

  isCountryStatusApplied = () => {
    const { activeCountryFilter } = this.state;
    return !!(
      activeCountryFilter?.length > 0 ||
      this.props.ordersPageDetails?.activeCountryFilter?.length > 0
    );
  };

  getCountryStatusCount = () => this.state.activeCountryFilter?.length || 0;
  getActiveProductCount = () => this.state.activeProductList?.length || 0;
  getFullfillmentStatusCount = () => {
    const { activeFulfillMentFilters } = this.state;
    if (
      (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters.length > 0)
    ) {
      if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
        return activeFulfillMentFilters.length;
      } else {
        return this.props.ordersPageDetails.activeFulfillMentFilters.length;
      }
    } else {
      return 0;
    }
  };
  isClearAllFilterDisabled = () => {
    if (
      !this.isFullfillmentStatusApplied() &&
      !this.isPaymentStatusApplied() &&
      !this.isProductStatusApplied() &&
      !this.isCountryStatusApplied()
    ) {
      return true;
    } else {
      return false;
    }
  };

  _onCloseLightBox = () => {
    this.setState({ lightBoxOpen: false });
  };

  _openLightBox = () => {
    const { activeAssessment } = this.props;
    if (activeAssessment && activeAssessment.selfieURL) {
      this.setState({ lightBoxOpen: true });
    }
  };

  getPaymentStatusCount = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters.length > 0)
    ) {
      if (activeStatusFilters && activeStatusFilters.length > 0) {
        return activeStatusFilters.length;
      } else {
        return this.props.ordersPageDetails.activeStatusFilters.length;
      }
    } else {
      return 0;
    }
  };

  isPaymentStatusApplied = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  getSelectedQueryStringForSearch = () => {
    let queryString = "";
    const {
      activeFulfillMentFilters,
      activeStatusFilters,
      activeCountryFilter,
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `&status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `&status=2&status=3&status=4`;
    }

    queryString += addQueryParam("user.location.country", activeCountryFilter);

    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `&fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    return queryString;
  };

  handlerecordPerpageAction = (value) => {
    this.setState(
      {
        recordsPerpage: JSON.parse(value),
      },
      () => {
        this.props.fetchAllOrders(
          `status=2&status=3&status=4&pageSize=${this.state.recordsPerpage.key}`,
          this.props &&
            this.props.ordersPageDetails &&
            this.props.ordersPageDetails.currentPage &&
            this.props.ordersPageDetails.currentPage,
          undefined,
          this.state.startDate,
          this.state.endDate,
          this.props.ordersPageDetails &&
            this.props.ordersPageDetails.activeStatusFilters &&
            this.props.ordersPageDetails.activeStatusFilters,
          this.props.ordersPageDetails &&
            this.props.ordersPageDetails.activeFulfillMentFilters &&
            this.props.ordersPageDetails.activeFulfillMentFilters
        );
      }
    );
  };
  handelUpdateWhatsAppMessage = () => {
    //console.log("sdsdsds======", this.props.whatsAppMessageList);
    this.props.form.validateFields(["message"], (err, values) => {
      //console.log(err, values);
      if (!err) {
        //console.log("+++++++++");
        this.props
          .updateWhatsAppMessage(this.state.whatsAppMessageType, values.message)
          .then((res) => {
            this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
            this.setState({ whatsAppModal: false });
            //console.log(res);
          })
          .catch((err) => {
            console.log("err", err);
            this.setState({ whatsAppModal: false });
          });
      }
    });
  };

  getSelectedQueryForCSV = () => {
    let queryString = "";
    const {
      activeFulfillMentFilters,
      activeStatusFilters,
      searchKey,
      activeAdvSearch,
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `status=2&status=3&status=4`;
    }
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}&`;
    }

    return queryString;
  };

  getProductRecommendedType(product, productArray) {
    let productCategory = product?.product?.category?.name;
    let recommendationType = productCategory;
    productCategory = productCategory?.toLowerCase();
    let ProductName = product?.product?.name?.toLowerCase();

    if (productCategory.includes("face")) {
      //console.log( "XXXXXXXXXX" )
      if (ProductName === "drop of light") {
        recommendationType = "Day Serum";
        return recommendationType;
      }
      if (ProductName === "drop of youth") {
        recommendationType = "Night Serum";
        return recommendationType;
      } else {
        if (ProductName === "drop of softness") {
          if (!productArray?.includes("drop of light")) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night Serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of balance") {
          if (
            !(
              productArray?.includes("drop of light") ||
              productArray?.includes("drop of softness")
            )
          ) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of perfection") {
          if (!productArray?.includes("drop of youth")) {
            recommendationType = "Night Serum";
            return recommendationType;
          } else {
            recommendationType = "Day Serum";
            return recommendationType;
          }
        }
      }
    }

    return recommendationType;
  }

  getPrinterToolLink(products, shippingAddress) {
    //console.log("======products", products, user);
    let day_routine = [];
    let night_routine = [];
    let day_serum = "";
    let day_cream = "";
    let night_serum = "";
    let night_cream = "";
    let productNameArray = products?.map((p) => p?.product?.name.toLowerCase());
    if (products) {
      products.forEach((prod) => {
        let recommendationType = prod?.recommendationType
          ?.toLowerCase()
          ?.trim();
        //console.log("HHHH", recommendationType,prod);;
        if (!recommendationType) {
          recommendationType = this.getProductRecommendedType(
            prod,
            productNameArray
          );
          recommendationType = recommendationType.toLowerCase()?.trim();
          //console.log("recommendationType", recommendationType);
        }
        if (
          recommendationType?.includes("day") ||
          recommendationType?.includes("moisturiser")
        ) {
          if (day_routine.length <= 2) {
            if (recommendationType == "day serum") {
              day_serum = prod.product._id;
            } else if (
              recommendationType == "moisturiser" ||
              recommendationType == "day cream"
            ) {
              day_cream = prod.product._id;
            }
            //day_routine.push(prod.product._id);
          }
        }
        if (recommendationType?.includes("night")) {
          if (night_routine.length <= 2) {
            if (recommendationType == "night cream") {
              night_cream = prod.product._id;
            } else if (recommendationType == "night serum") {
              night_serum = prod.product._id;
            }
            //night_routine.push(prod.product._id);
          }
        }
      });
    }
    day_routine = [day_serum, day_cream];
    night_routine = [night_serum, night_cream];
    let day_routine_str = day_routine.join(",");
    //let day_routine_str =day_serum +  day_cream;
    let night_routine_str = night_routine.join(",");

    let name =
      capitalizeFirstLetter(shippingAddress?.firstName) +
      " " +
      capitalizeFirstLetter(shippingAddress?.lastName);

    let url = `http://tools.yours.sg/mini-lfy.php?day_routine=${day_routine_str}&night_routine=${night_routine_str}&name=${name}`;
    //console.log("==== ", url);
    return url;
  }

  itemsPurchased(order) {
    // let productInitials="";
    // let  items =  order.items || [ ];
    // let productNameArray = items?.map((p) => p.product.name.toLowerCase());
    // items =items.map( item =>{
    //   if(item && item.product && item.product.name){
    //     let recommendationType = item?.product?.recommendationType?.toLowerCase();
    //     if (!recommendationType) {
    //       recommendationType = this.getProductRecommendedType(
    //         item,
    //         productNameArray
    //       );
    //     }
    //     item.product.recommendationType = recommendationType;

    //   }

    //   let defaultsortOrder = 5;

    //   switch (item?.product?.recommendationType.toLowerCase().trim()) {
    //     case "day serum":
    //       item.sortOrder = 1;
    //       break;
    //     case "day cream":
    //       item.sortOrder = 2;
    //       break;
    //     case "night serum":
    //       item.sortOrder = 3;
    //       break;
    //     case "night cream":
    //       item.sortOrder = 4;
    //       break;
    //     default:
    //       item.sortOrder = defaultsortOrder++;
    //       break;
    //   }
    //   return item;
    // });

    // items = items.sort((a, b) => {
    //   return a.sortOrder - b.sortOrder;
    // });

    // return items.map( item =>{
    //   if(item && item.product && item.product.name){
    //     productInitials = item.product.name.split(" ").map(name =>  name[0]).join('');
    //     let recommendationType = item?.product?.recommendationType?.toLowerCase();

    //     if (
    //       recommendationType.includes("day") ||
    //       recommendationType.includes("moisturiser")
    //     ) {
    //       productInitials = productInitials + "-" + "D";
    //     }

    //     if(recommendationType.includes('night')){
    //       productInitials = productInitials + "-" + 'N';
    //     }

    //   }
    //     return productInitials;
    // }).join(', ');

    return order.items.map((x) => x?.product?.ssu).join(", ");
  }

  getProductInitials(name) {
    if (name) {
      return name
        .split(" ")
        .map((word, index) => {
          if (index < 3) {
            return word.charAt(0).toUpperCase();
          }
        })
        .join("");
    }
  }

  getProductName(order, orderType) {
    if (!order) {
      return "";
    }
    let product_name = "";
    let quantity = "";
    let productNameArray = order.items?.map((p) =>
      p?.product?.name?.toLowerCase()
    );
    for (let i = 0; i < order.items.length; i++) {
      let item = order.items[i];
      let recommendationType = item?.recommendationType?.toLowerCase()?.trim();
      if (!recommendationType) {
        recommendationType = this.getProductRecommendedType(
          item,
          productNameArray
        );
        recommendationType = recommendationType.toLowerCase().trim();
      }
      if (
        recommendationType === "day cream " ||
        recommendationType === "moisturiser"
      ) {
        recommendationType = "day cream";
      }
      if (recommendationType.toLowerCase().trim() === orderType.toLowerCase()) {
        product_name =
          item?.product?.ssu || this.getProductInitials(item?.product?.name);
        quantity = item?.quantity;
        break;
      }
    }

    if (product_name) {
      return product_name + " - " + quantity;
    } else {
      return quantity;
    }
  }

  getExtraProduct(order) {
    if (!order) {
      return "";
    }

    let productNameArray = order.items?.map((p) =>
      p?.product?.name?.toLowerCase()
    );

    let recommendedType = [];
    let extra_product = [];

    for (let i = 0; i < order.items.length; i++) {
      let item = order.items[i];
      let recommendationType = item?.recommendationType?.toLowerCase();
      if (!recommendationType) {
        recommendationType = this.getProductRecommendedType(
          item,
          productNameArray
        );
        recommendationType = recommendationType.toLowerCase().trim();
      }
      if (
        recommendationType === "day cream " ||
        recommendationType === "moisturiser"
      ) {
        recommendationType = "day cream";
      }
      if (
        recommendationType.includes("day") ||
        recommendationType.includes("night")
      ) {
        if (!recommendedType.includes(recommendationType)) {
          recommendedType.push(recommendationType);
        } else {
          const itemName =
            item?.product?.ssu || this.getProductInitials(item?.product?.name);
          let quantity = item.quantity;
          extra_product.push(itemName + "-" + quantity);
        }
      } else {
        if (
          recommendationType == "extras" ||
          item?.product?.category?.name?.toLowerCase() == "bundle" ||
          item?.product?.category?.name?.toLowerCase() == "bundles" ||
          item?.product?.category?.name?.toLowerCase() == "merch" ||
          item?.product?.category?.name?.toLowerCase() == "complimentary" ||
          !item?.product?.ssu ||
          item?.product?.ssu == "ACP" ||
          item?.product?.ssu == "mEXFO30"
        ) {
          const itemName =
            item?.product?.ssu || this.getProductInitials(item?.product?.name);
          let quantity = item.quantity;
          extra_product.push(itemName + "-" + quantity);
        }
      }
    }
    return extra_product.join(",");
  }

  getEssentials(order, productName) {
    if (!order) {
      return "";
    }

    let product_name = "";
    let quantity = "";

    order.items.forEach((item) => {
      if (item?.product?.ssu?.toLowerCase() === productName.toLowerCase()) {
        product_name = item?.product?.ssu;
        quantity = item?.quantity;
      }
    });

    if (product_name) {
      return product_name + " - " + quantity;
    } else {
      return quantity;
    }

    // return  quantity;
  }

  getFreebie_minis(order) {
    if (!order) {
      return "";
    }

    let product_name = "";
    let quantity = "";
    let item_name = "";
    order.items.forEach((item) => {
      if (
        item?.product?.ssu?.toLowerCase() === "mexfo" ||
        item?.product?.ssu?.toLowerCase() === "fbs" ||
        item?.product?.ssu?.toLowerCase() === "mexfo2"
      ) {
        product_name = item?.product?.ssu;
        quantity = item?.quantity;
        item_name += " " + product_name + "(3)" + "- " + quantity;
      }
    });

    return item_name;
    // return  quantity;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const label = `${this.state.checked ? "Checked" : "Unchecked"}-${
      this.state.disabled ? "Disabled" : "Enabled"
    }`;
    const { activeAssessment } = this.props;
    const rowSelection = {
      type: "checkbox",
      selectedRowKeys: this.state.selectedOrderIds,
      onChange: (selectedRowKeys, selectedRows, a) => {
        this.setState({
          selectedOrderIds: selectedRowKeys,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    const columns = [
      {
        title: "Order Date",
        dataIndex: "submissionDate",
        key: "submissionDate",
        width: 130,
      },
      {
        title: "Order Time",
        dataIndex: "submissionTime",
        key: "submissionTime",
        width: 130,
      },
      {
        title: "Fulfillment Status",
        dataIndex: "fulfillmentStatus",
        key: "fulfillmentStatus",
        width: 150,
      },
      {
        title: "Payment Status",
        dataIndex: "orderStatus",
        key: "orderStatus",
        width: 130,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        width: 120,
        render: (order) => {
          return (
            <div className="flex">
              <Link
                style={{ color: "#363864" }}
                target="_blank"
                to={`/orders/edit/${order.id}`}
                // onClick={(e) => {
                //   this.props.history.push(`/orders/edit/${order.id}`);
                // }}
              >
                {order.shortId}
              </Link>
              {order?.comments?.length != 0 && (
                <Tooltip title="This order has a message trail">
                  <Icon
                    type="message"
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: "Submission ID",
        dataIndex: "attempt",
        key: "attempt",
        width: 120,
        render: (attempt, row) => {
          return (
            <div className="flex">
              <div
                style={{ color: "#373873" }}
                onClick={(e) => {
                  e.stopPropagation();
                  attempt !== "-" &&
                    this.handleShortAssessmentSummaryModal(attempt, row);
                }}
              >
                {attempt}
              </div>
            </div>
          );
        },
      },
      {
        title: "Latest Submission",
        dataIndex: "latest_submission",
        key: "latest_submission",
        width: 100,
        render: (userId, row) => {
          return (
            <div className="flex">
              <div
                style={{ color: "#373873" }}
                onClick={(e) => {
                  e.stopPropagation();
                  userId !== "-" &&
                    this.handleUserLatestAssessmentSummaryModal(userId, row);
                }}
              >
                Latest
              </div>
            </div>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        render: (user) => (
          <Link
            style={{ color: "#373873" }}
            to={`/customer/edit/${user.id && user.id.id}`}
            target="_blank"
          >
            {user.firstName || ""} {user.lastName || ""}
          </Link>
        ),
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        width: 130,
      },
      {
        title: "WhatsApp",
        dataIndex: "whatsAppMsgStatus",
        key: "whatsAppMsgStatus",
        width: 130,
        render: (order) => {
          //console.log(order)
          let action = WHATSAPP_STATUS_ACTION_OPTION.filter(
            (status) => status.code === order.whatsAppMsgStatus
          )[0];
          let phone = this.getPhoneNumber(order);
          if (!phone) {
            return <div>-</div>;
          }
          return order.whatsAppMsgStatus <
            whatsAppMessageStatus.completed_order_send ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                let formatted_phone_no = phone.replace(/[-()+\s]/g, "");
                let msg =
                  this.props.whatsAppMessageList.length > 0
                    ? typeof this.props.whatsAppMessageList[0] == "string"
                      ? this.props.whatsAppMessageList[0]
                      : this.props.whatsAppMessageList[0].message
                    : "";
                let text_msg = encodeURIComponent(msg);
                this.handelWhatsAppMessage(
                  order,
                  order.id,
                  formatted_phone_no,
                  text_msg
                );
              }}
              style={{
                color: "#373873",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              New message
            </div>
          ) : (
            <span style={{ color: action && action.color }}>
              {" "}
              {action && action.label}{" "}
            </span>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 170,
      },
      {
        title: "Partner Email",
        dataIndex: "partnerEmail",
        key: "partnerEmail",
        width: 170,
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 130,
      },
      {
        title: "Shipping Address",
        dataIndex: "shippingAddress",
        key: "shippingAddress",
        width: 200,
      },
      {
        title: "Charged Price",
        dataIndex: "chargedPrice",
        key: "chargedPrice",
        width: 130,
      },
      {
        title: "Customer Notes",
        dataIndex: "customerNotes",
        key: "customerNotes",
        width: 130,
      },
      {
        title: "Order Notes",
        dataIndex: "notes",
        key: "notes",
        width: 130,
      },
      // {
      //   title: "Order Status",
      //   dataIndex: "newOrderStatus",
      //   key: "newOrderStatus",
      //   width: 130,
      // },

      {
        title: "Discount Code",
        dataIndex: "code",
        key: "code",
        width: 130,
      },
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        width: 150,
        render: (order) => {
          let userId = order.user && order.user.id && order.user.id.id;
          return (
            <>
              <Link
                style={{ color: "#373873" }}
                to={`/customer/edit/${userId}`}
                target="_blank"
              >
                {userId}
              </Link>
            </>
          );
        },
      },
      {
        title: "User previous orders",
        dataIndex: "userId",
        key: "userId1",
        width: 150,
        render: (order) => {
          let userId = order.user && order.user.id && order.user.id.id;
          return (
            <>
              <div
                style={{ color: "#373873" }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handelRequestForRelatedOrders(userId, order);
                }}
              >
                previous orders
              </div>
            </>
          );
        },
      },
      {
        title: "Items purchased",
        dataIndex: "itemsPurchased",
        key: "itemsPurchased",
        width: 130,
      },
      {
        title: "Pdf Link",
        dataIndex: "pdfLink",
        key: "pdfLink",
        width: 130,
        render: (pdfLink) => {
          // console.log(" pdfLink=====", order);
          return (
            <div
              className="semibold cursor-pointer invoice-animate"
              style={{
                fontSize: "14px",
                color: "#363874",
                paddingLeft: "5px",
                float: "right",
                marginTop: "-24px",
              }}
            >
              <a href={pdfLink} target="_blank">
                🖨️ Printer PDF{" "}
              </a>
            </div>
          );
        },
      },
      {
        title: "Payment Processor",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        width: 130,
        render: (paymentMethod) => {
          return getPaymentMethod(paymentMethod);
        },
      },
      {
        title: "Contains Gift Set",
        dataIndex: "giftSet",
        key: "giftSet",
        width: 80,
      },
      {
        title: "Contains Gift Set Note",
        dataIndex: "giftSetNote",
        key: "giftSetNote",
        width: 80,
      },
      {
        title: "Packaging",
        dataIndex: "goGreenPackaging",
        key: "goGreenPackaging",
        width: 130,
        render: (goGreenPackaging) => {
          return (
            <div className="flex-center">
              {goGreenPackaging && (
                <span
                  className="go-green-list-icon"
                  role="img"
                  aria-label="go-green"
                >
                  🌍 {"  "}♻️
                </span>
              )}
            </div>
          );
        },
      },
      {
        title: "Order Type",
        dataIndex: "type",
        key: "type",
        width: 130,
      },
      {
        title: "Subscription Type",
        dataIndex: "subscriptionType",
        key: "subscriptionType",
        width: 130,
      },
      {
        title: "Tags",
        key: "tags",
        dataIndex: "tags",
        width: 130,
        render: (tags) => (
          <span>
            {tags &&
              tags.map((tag, index) => {
                return (
                  <Tag className="mb-4" key={index}>
                    {tag === Object(tag) ? tag.name : ""}
                  </Tag>
                );
              })}
          </span>
        ),
      },
      {
        title: "Shipment Id",
        dataIndex: "shipmentId",
        key: "shipmentId",
        width: 130,
      },
      {
        title: "Shipping Label",
        dataIndex: "shipmentLabel",
        key: "shipmentLabel",
        render: (order) => (
          <>
            {order?.shipmentLabelUrl ? (
              <a
                onClick={() => {
                  let filename =
                    order?.user?.firstName +
                    "_" +
                    order?.user?.lastName +
                    "_" +
                    order?.shippingAddress?.country +
                    ".pdf";

                  handleDownload(order?.shipmentLabelUrl, filename);
                }}
                style={{ color: "#373873" }}
                //href={order.shipmentLabelUrl}
              >
                Label
              </a>
            ) : (
              ""
            )}
          </>
        ),
        width: 130,
      },
      {
        title: "Total Weight",
        dataIndex: "totalWeight",
        key: "totalWeight",
        width: 100,
      },
      {
        title: "Items quantity",
        dataIndex: "itemsQuantity",
        key: "itemsQuantity",
        width: 130,
      },
      {
        title: " Fulfillment Error",
        dataIndex: "fulfillmentErr",
        key: "fulfillmentErr",
        width: 180,
        render: (order) => {
          return (
            <div style={{ color: "red" }}>
              {order.fulfillmentStatus == -1 ? (
                <Tooltip title={order?.fulfillmentError?.message}>
                  {FULFILLMENT_ERROR_STATUS[order?.fulfillmentError?.errCode]}
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      {
        title: "Day Serum",
        dataIndex: "daySerum",
        key: "daySerum",
        width: 60,
      },
      {
        title: "Day Cream",
        dataIndex: "dayCream",
        key: "dayCream",
        width: 60,
      },
      {
        title: "Night Serum",
        dataIndex: "nightSerum",
        key: "nightSerum",
        width: 60,
      },
      {
        title: "Night Cream",
        dataIndex: "nightCream",
        key: "nightCream",
        width: 60,
      },
      {
        title: "SSU",
        dataIndex: "SSU",
        key: "SSU",
        width: 60,
      },
      {
        title: "CFC",
        dataIndex: "CFC",
        key: "CFC",
        width: 60,
      },
      {
        title: "BBT",
        dataIndex: "BBT",
        key: "BBT",
        width: 60,
      },
      {
        title: "ELYES",
        dataIndex: "ELYES",
        key: "ELYES",
        width: 65,
      },
      {
        title: "mEXFO / FBS",
        dataIndex: "CSM_FB",
        key: "CSM_FB",
        width: 65,
      },
      {
        title: "Extras",
        dataIndex: "Extras",
        key: "Extras",
        width: 65,
      },

      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 180,

        render: (order) => {
          return (
            <div className="flex">
              {(order.orderStatus === paymentStatusMapping[2] ||
                order.orderStatus === paymentStatusMapping[3]) &&
                this.state.userType === "admin" && (
                  <div>
                    {/* {(order.fulfillmentStatusCode ===
                      orderFullfillmentStatusMapping["Ready to Pack"] ||
                      order.fulfillmentStatusCode ===
                        orderFullfillmentStatusMapping["WayBill Failed"]) && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // console.log("===== fulfillment", order);
                          this.handelUpdateFulfillment(order);
                        }}
                      >
                        Create waybill <span>📝</span>
                      </div>
                    )} */}
                    {/* {order.fulfillmentStatusCode ===
                      orderFullfillmentStatusMapping["Waybill generated"] && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelGenerateLabel(order);
                        }}
                      >
                        Generate Label <span>📑</span>
                      </div>
                    )} */}
                    {order.fulfillmentStatusCode ===
                      orderFullfillmentStatusMapping["New"] && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelOrderReadyPack(order);
                        }}
                      >
                        Mark Ready to pack <span>👍</span>
                      </div>
                    )}
                    {/* {order.fulfillmentStatusCode ===
                      orderFullfillmentStatusMapping["Label generated"] && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelOrderShipped(order);
                        }}
                      >
                        Mark Order Shipped <span>🚛</span>
                      </div>
                    )} */}
                    {/* {order.fulfillmentStatusCode ===
                      orderFullfillmentStatusMapping["Shipped"] && (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          //console.log("===== fulfillment", order);
                          this.handelOrderDeliver(order);
                        }}
                      >
                        Mark Order Delivered <span>📦</span>
                      </div>
                    )} */}
                    {order.fulfillmentStatusCode ===
                      orderFullfillmentStatusMapping["Delivered"] && (
                      <div>Order Delivered</div>
                    )}
                  </div>
                )}

              {/* {order.orderStatus !== paymentStatusMapping[2] &&
                order.type !== "Paid" &&
                this.state.userType === "admin" && (
                  <div
                    className="delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleDeleteOrder(order);
                    }}
                  >
                    {this.state.userType === "admin" && (
                      <span style={{ padding: "4px" }}>|</span>
                    )}
                    Delete
                  </div>
                )} */}
            </div>
          );
        },
      },
    ].filter((column) =>
      this.state.shownColumns.find(
        (hiddenColKey) => hiddenColKey === column.key
      )
    );
    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });
    headers.filter((head) => head.key !== "action");

    const mappedOrdersList =
      this.props.ordersList.length > 0
        ? this.props.ordersList.map((order, index) => {
            // const itemsPurchased =
            //   order.items &&
            //   order.items
            //     .map(
            //       (item) =>
            //         item.product &&
            //         item.product.name &&
            //         item.product.name
            //           .split(" ")
            //           .map(function (productName) {
            //             return productName[0];
            //           })
            //           .join("")
            //     )
            //     .join(", ");
            return {
              key: order.id,
              id: order.id,
              // submissionTime: `${order.createdAt
              //     ? moment(order.createdAt).format("hh:mm a")
              //     : "-"
              //   }`,
              submissionTime: `${
                order.createdAt
                  ? momentTimezone(order.createdAt)
                      .tz("Asia/Singapore")
                      .format("hh:mm a")
                  : "-"
              }`,
              userId: order,
              email: order.user.email,
              name: order.user,
              submissionDate: `${
                order.createdAt
                  ? moment(order.createdAt).format("DD/MM/YYYY")
                  : "-"
              }`,
              // attempt: order.shortId ? order.shortId : "-",
              attempt: order.attempt ? order.attempt : "-",
              latest_submission: order?.user?.id?.id,
              chargedPrice:
                `${
                  order.currency &&
                  order.currency.code &&
                  order.currency.code.toUpperCase()
                }` +
                " " +
                `${order.chargedPrice}`,
              // totalPrice: order.totalPrice,
              orderId: order,
              receiptId: order.receiptId,
              orderStatus: paymentStatusMapping[order.status],
              invoice: "",
              origin: order.origin,
              shippingAddress: formatAddress(order.shippingAddress),
              shipmentId: order.trackingNumber,
              newOrderStatus:
                order && order.orderStatus != "undefined"
                  ? orderStatusMapping[order.orderStatus]
                  : "-",
              fulfillmentStatus: order
                ? orderFulfillmentStatusLabel[order.fulfillmentStatus] +
                  " " +
                  orderFullfillmentStatusEmoji[order.fulfillmentStatus]
                : "-",
              fulfillmentStatusCode: order ? order.fulfillmentStatus : 0,
              shipmentLabel: order,
              phone: this.getPhoneNumber(order),
              whatsAppMsgStatus: order,
              itemsPurchased: this.itemsPurchased(order),
              goGreenPackaging: order?.goGreenPackaging,
              itemsQuantity: order.items
                ? order.items.map((item) => item.quantity).join(", ")
                : [],
              action: order.id,
              //type: this.getOrderType(order),
              type:
                order && order.orderType ? order.orderType.toUpperCase() : "-",
              subscriptionType:
                order && order.subscriptionType
                  ? order.subscriptionType.toUpperCase()
                  : "-",
              totalWeight: order.totalWeight,
              tags: order.tags,
              paymentMethod: order.paymentMethod,
              giftSet: order?.gift?.to ? "Yes" : "No",
              giftSetNote: order?.gift?.message ? "Yes" : "No",
              country: order.shippingAddress
                ? order.shippingAddress.country
                : "-",
              notes: order.notes ? order.notes : "",
              customerNotes:
                order && order.user && order.user.id ? order.user.id.notes : "",
              stripeInvoiceId:
                order && order.stripeInvoiceId ? order.stripeInvoiceId : "-",

              promoType:
                order && order.promotion && order.promotion.promoType
                  ? order.promotion.promoType
                  : "-",
              code:
                order && order.promotion && order.promotion.code
                  ? order.promotion.code
                  : "-",
              status: order && order.status ? order.status : "-",
              shipping: `${
                order && order.currency && order.currency.code
                  ? order.currency.code.toUpperCase()
                  : "-"
              }${order && order.shipping ? order.shipping : "-"}`,
              trackingDetails: order.trackingDetails,
              partnerEmail: order && order.paymentEmail && order.paymentEmail,
              pdfLink: this.getPrinterToolLink(
                order?.items,
                order?.shippingAddress
              ),
              fulfillmentErr: order,
              daySerum: this.getProductName(order, "day serum"),
              dayCream: this.getProductName(order, "day cream"),
              nightSerum: this.getProductName(order, "night serum"),
              nightCream: this.getProductName(order, "night cream"),
              SSU: this.getEssentials(order, "ssu"),
              CFC: this.getEssentials(order, "CFC"),
              BBT: this.getEssentials(order, "BBT"),
              ELYES: this.getEssentials(order, "ELYES"),
              CSM_FB: this.getFreebie_minis(order),
              Extras: this.getExtraProduct(order),
            };
          })
        : [];
    const csvData = mappedOrdersList.map((item) => {
      return {
        ...item,
        name: `${item.name.firstName || ""} ${item.name.lastName || ""}`,
        orderId: item.orderId.shortId,
        tags:
          item.tags &&
          item.tags
            .map((tag) => {
              return tag === Object(tag) ? tag.name : "";
            })
            .join(","),
      };
    });
    const menu = (
      <Menu
        onClick={(e) => this.handleStatusFilter("status", e.key)}
        selectedKeys={this.state.activeStatusFilters}
        multiple
        selectable
      >
        {Object.keys(orderPaymentCompleteStatusFilterMapping).map((key) => {
          return (
            <Menu.Item key={key}>
              {orderPaymentCompleteStatusFilterMapping[key]}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    const orderTypeFilterMenu = (
      <Menu
        onClick={(e) => this.handleOrderTypeFilter(e.key)}
        selectedKeys={this.state.activeOrderTypeFilter}
        multiple
        selectable
      >
        {orderTypeFilter &&
          orderTypeFilter.map((type) => {
            return <Menu.Item key={type.key}>{type.label}</Menu.Item>;
          })}
      </Menu>
    );
    const fulfillmentMenu = (
      <Menu
        selectedKeys={this.state.activeFulfillMentFilters}
        multiple
        selectable
      >
        <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={0}
        >
          New <span> ✨ </span>
        </Menu.Item>
        <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={10}
        >
          Ready to Pack <span> 👍 </span>
        </Menu.Item>
        {Object.keys(orderFullfillmentStatusListing).map((key) => {
          return (
            <Menu.Item
              onClick={(e) =>
                this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
              }
              key={key}
            >
              {orderFullfillmentStatusListing[key] +
                " " +
                orderFullfillmentStatusEmoji[key]}
            </Menu.Item>
          );
        })}
        {/* <SubMenu key={6} title="WayBill Failed  ⚠️ ">
          {Object.keys(FULFILLMENT_ERROR_STATUS).map((key) => {
            return (
              <Menu.Item
                onClick={(e) =>
                  this.handleFulfillStatusFilter("fulfillmentError", e.key)
                }
                key={key}
              >
                {FULFILLMENT_ERROR_STATUS[key]}
              </Menu.Item>
            );
          })}
        </SubMenu> */}
      </Menu>
    );

    const fulfillmentActionMenu = (
      <Menu
        onClick={(e) => this.handleFulfillmentAction(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {FULFILLMENT_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
        {localStorage.userType != "packer" && (
          <Menu.Item
            key={JSON.stringify({
              key: "order_delivered",
              label: "Mark Delivered 📦",
            })}
          >
            Mark Delivered 📦
          </Menu.Item>
        )}
      </Menu>
    );

    const recordPerpage = (
      <Menu
        onClick={(e) => this.handlerecordPerpageAction(e.key)}
        selectedKeys={[JSON.stringify(this.state.recordsPerpage)]}
      >
        {RECORD_PER_PAGE.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const InvoiceActionMenu = (
      <Menu
        onClick={(e) => this.handleDownloadInvoice(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {INVOICE_DOWNLOAD_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({
                key: option.key,
                label: option.label,
                //code: option.code,
              })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    const whatsappActionMenu = (
      <Menu
        onClick={(e) => this.handleWhatsAppActionMenue(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {WHATSAPP_STATUS_ACTION_OPTION.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({
                key: option.key,
                label: option.label,
                code: option.code,
                color: option.color,
              })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    const advancedSearchMenu = (
      <Menu
        onClick={(e) => this.handleAdvSearchChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {ORDER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          if (
            this.state.activeCountryFilter.length > 0 &&
            option.label === "Country"
          ) {
            return;
          } else {
            return (
              <Menu.Item
                key={JSON.stringify({ key: option.key, label: option.label })}
              >
                {option.label}
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );

    const filterByCountryMenu = (
      <Menu
        onClick={(e) => this.handleCountryFilter(e.key)}
        selectedKeys={this.state.activeCountryFilter}
        multiple
        selectable
      >
        {Object.keys(countryFilterMapping).map((key) => {
          return <Menu.Item key={key}>{countryFilterMapping[key]}</Menu.Item>;
        })}
      </Menu>
    );

    const filterProductMenu = (
      <Menu
        onClick={(e) => this.handleProductFilter(e.key)}
        selectedKeys={this.state.activeProductList}
        multiple
        selectable
      >
        {this.state.productList &&
          this.state.productList.map((prod) => {
            return <Menu.Item key={prod._id}>{prod.name}</Menu.Item>;
          })}
        {/* <Menu.Item key={"day_night_4"}>{"Day & Night Set X 4"}</Menu.Item> */}
        {/* <Menu.Item key={"day_night_3"}>{"Day & Night Set X 3"}</Menu.Item> */}
      </Menu>
    );

    const columnFilterMenu = (
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        className="flex flex-col column-checkbox-wrapper"
      >
        {/* <div onClick={this.handleMenuStopClick} key="3">Close</div> */}
        {this.getFilters().map((item, index) => {
          return (
            <Checkbox
              key={index}
              checked={this.state.shownColumns.find((value) => {
                return value === item;
              })}
              onChange={(e) => this.onColumnFilterChange(e, item)}
            >
              {item
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Checkbox>
          );
        })}
      </div>
    );
    // let questions = [
    //   'How would you best describe your T-Zone in the morning?',
    //   'How would you best describe your cheeks in the morning?',
    //   'What are your top 2 skin concerns?'
    //   ];
    if (activeAssessment && activeAssessment.questionsAnswered) {
      var result = [];
      this.state.questions.forEach((question) => {
        let x = activeAssessment.questionsAnswered.find((questionObject) => {
          return question.includes(questionObject.question.text);
        });
        if (x) {
          result.push(x);
        }
      });
    }

    const orderColumns = [
      {
        title: "Order ID",
        dataIndex: "order",
        key: "order",
        render: (order) => (
          <>
            <a
              className="cursor-pointer"
              style={{ color: "#373873" }}
              href={`/orders/edit/${order.id}`}
              target="_blank"
            >
              {order.shortId}
            </a>
            <Icon type="message" />
          </>
        ),
      },
      {
        title: "products purchased",
        dataIndex: "products",
        key: "products",
      },
      {
        title: "date/time of these purchases ",
        dataIndex: "orderDate",
        key: "orderDate",
      },
      {
        title: "payment status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
      },
      {
        title: "Order type",
        dataIndex: "OrderType",
        key: "OrderType",
      },
    ];

    return (
      <LoadingOverlay
        active={this.state.showLoader}
        spinner={<Loader />}
        styles={{
          wrapper: {
            width: "100%",
            //height: '100%',
            overflow: true ? "hidden" : "scroll",
          },
        }}
        text="Loading your content..."
      >
        <div className="orders-page-list" style={{ marginLeft: "200px" }}>
          <OrdersHeader
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChangeInput={this.onChangeInput}
            fetchAllOrders={this.props.fetchAllExportOrders}
            csvData={csvData}
            allHeaders={headers}
            csvRef={this.csvRef}
            shoppingExportCsvRef={this.shoppingExportCsvRef}
            ordersList={this.props.ordersList}
            selectedOrderIds={this.state.selectedOrderIds}
            pageName="Completed Order"
            pageNo={this.state.pageNo ? this.state.pageNo : 1}
            totalCount={this.props.ordersPageDetails?.totalCount || 0}
            recordsPerPage={this.state.recordsPerpage}
            filter={this.getSelectedQueryStringFilter()}
            allowShippingExport={
              localStorage.getItem("userType") === "admin" ? "yes" : "no"
            }
            allowOrderExport={
              localStorage.getItem("userType") === "admin" ? "yes" : "no"
            }
            toggleExportingDataState={this.toggleExportingDataState}
          />

          <Modal
            title="User Order Summary"
            visible={this.state.showRelatedOrdersModal}
            //visible={true}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={700}
          >
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">Total Order:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {this.state.customerTotalOrder}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">
                Total subscriptions:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {this.state.customerTotalSubscription}
              </div>
            </div>
            {this.props.customerOrderList && (
              <Table
                columns={orderColumns}
                pagination={false}
                dataSource={this.state.customerOrderListState}
              />
            )}
          </Modal>

          <Modal
            title="Assessment Summary"
            visible={this.state.showAssessmentModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <div className="">
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href={`assessment-detail/${
                  activeAssessment && activeAssessment.id
                }`}
              >
                {" "}
                view Assessment
              </a>
            </div>
            {result &&
              result.map((item) => {
                return (
                  <div className="q-a-wrapper">
                    <div className="flex">
                      <div
                        style={{ minWidth: "70px" }}
                        className="text-sm bold"
                      >
                        Question:
                      </div>
                      <div
                        style={{ paddingLeft: "5px" }}
                        className="text-sm regular"
                      >
                        {item && item.question && item.question.text}
                      </div>
                    </div>

                    <div>
                      {item &&
                        item.answer.map((answerValue) => {
                          return (
                            <div className="flex">
                              <div
                                style={{ minWidth: "74px" }}
                                className="text-sm bold"
                              >
                                Answer:
                              </div>
                              <div
                                style={{ paddingLeft: "5px" }}
                                className="text-sm regular"
                              >
                                {(answerValue && answerValue.text) ||
                                  item?.answerText}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">
                Total Questions Answered:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {activeAssessment && activeAssessment.totalQuestionsAnswered}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">
                Last Answered Question:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {activeAssessment &&
                  activeAssessment.lastAnsweredQuestion &&
                  activeAssessment.lastAnsweredQuestion.text}
              </div>
            </div>

            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">Selfie Url:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                <a
                  style={{ paddingLeft: "0px" }}
                  href="javascript:void(0)"
                  onClick={this._openLightBox}
                >
                  {activeAssessment && activeAssessment.selfieURL
                    ? activeAssessment.selfieURL
                    : "Not Available"}
                </a>
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">Location:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {activeAssessment &&
                  activeAssessment.weatherData &&
                  activeAssessment.weatherData.data &&
                  activeAssessment.weatherData.data.location}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">Customer Notes:</div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {this.state.selectedOrderData &&
                this.state.selectedOrderData.customerNotes
                  ? this.state.selectedOrderData.customerNotes
                  : ""}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">
                Order Purchased:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {activeAssessment &&
                activeAssessment.purchasedOrder &&
                activeAssessment.purchasedOrder
                  ? "Yes"
                  : "No"}
              </div>
            </div>
            <div
              style={{ paddingTop: "15px" }}
              className="question-count flex items-center"
            >
              <div className="a-question text-16 key bold">
                Assessment Date:
              </div>
              <div
                style={{ paddingLeft: "5px" }}
                className="a-question text-16 value regular"
              >
                {activeAssessment && activeAssessment.createdAt}
              </div>
            </div>

            <div className="text-sm bold" style={{ margin: "10px 0" }}>
              Recommended Products:
            </div>
            <div className="flex">
              {activeAssessment &&
                activeAssessment.recommendedProducts &&
                activeAssessment.recommendedProducts.products &&
                activeAssessment.recommendedProducts.products.map((item) => {
                  return (
                    <div className="flex flex-col img-text">
                      <img
                        alt=""
                        className="product-img"
                        src={
                          item &&
                          item.product &&
                          item.product.mainImage &&
                          item.product.mainImage &&
                          item.product.mainImage.withoutHover.mediaUrl
                        }
                      />
                      <div
                        style={{ marginTop: "20px", maxWidth: "74px" }}
                        className="image-text-container bold key"
                      >
                        {item && item.product.name ? item.product.name : "-"}
                      </div>
                    </div>
                  );
                })}
              {activeAssessment &&
                activeAssessment.recommendedProducts &&
                activeAssessment.recommendedProducts.nightSerum && (
                  <div style={{ paddingLeft: "30px" }}>
                    {activeAssessment.recommendedProducts.nightSerum &&
                      activeAssessment.recommendedProducts.nightSerum
                        .mainImage &&
                      activeAssessment.recommendedProducts.nightSerum.mainImage
                        .withoutHover.mediaUrl && (
                        <img
                          src={
                            activeAssessment.recommendedProducts.nightSerum
                              .mainImage.withoutHover.mediaUrl
                          }
                          className="product-img"
                          alt=""
                        />
                      )}
                    <div
                      style={{ marginTop: "20px", maxWidth: "70px" }}
                      className="image-text-container key bold"
                    >
                      {activeAssessment &&
                        activeAssessment.recommendedProducts.nightSerum &&
                        activeAssessment.recommendedProducts.nightSerum.name}
                    </div>
                  </div>
                )}
            </div>
          </Modal>
          {this.state.lightBoxOpen && (
            <Lightbox
              mainSrc={activeAssessment && activeAssessment.selfieURL}
              onCloseRequest={this._onCloseLightBox}
            />
          )}
          <div className=" nav-container justify-between">
            <div className="flex"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <RangePicker
                onChange={(val) => {
                  this.SelectDates(val);
                }}
                allowClear={true}
                disabledDate={this.disabledDate}
              />
              <Button
                type="primary"
                className="mr-20"
                onClick={() => {
                  this.setState({ whatsAppModal: true });
                }}
                // disabled={this.state.selectedOrderIds.length === 0}
              >
                <Icon type="message"></Icon>
                Whatsapp Message
              </Button>
              <Dropdown
                overlay={whatsappActionMenu}
                disabled={this.state.selectedOrderIds.length === 0}
              >
                <Button
                  className="dropdown-btn"
                  style={
                    this.state.selectedOrderIds.length !== 0
                      ? {
                          background: "#373873",
                          color: "#fff",
                          height: "32px",
                          marginBottom: "10px",
                          marginRight: "20px",
                        }
                      : {
                          height: "32px",
                          marginBottom: "10px",
                          marginRight: "20px",
                        }
                  }
                >
                  Change Whatsapp Status <Icon type="down" />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={InvoiceActionMenu}
                disabled={this.state.selectedOrderIds.length === 0}
              >
                <Button
                  className="dropdown-btn"
                  style={
                    this.state.selectedOrderIds.length !== 0
                      ? {
                          background: "#373873",
                          color: "#fff",
                          height: "32px",
                          marginBottom: "10px",
                          marginRight: "20px",
                        }
                      : {
                          height: "32px",
                          marginBottom: "10px",
                          marginRight: "20px",
                        }
                  }
                >
                  <Badge count={this.state.selectedOrderIds.length}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                  Invoice Actions <Icon type="down" />
                </Button>
              </Dropdown>

              {/* <Button
              type="primary"
              className="mr-20"
              onClick={this.handleDownloadInvoice}
              disabled={this.state.selectedOrderIds.length === 0}
            >
              <Icon type="download"></Icon>
              Download Invoice
            </Button> */}
              <Button
                type="primary"
                onClick={() => this.props.history.push("/orders/edit")}
                style={{}}
              >
                <Icon type="plus"></Icon>
                Add Order
              </Button>
            </div>
            <div className="flex"></div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: "15px", marginRight: "15px" }}>
                <Dropdown
                  overlay={fulfillmentActionMenu}
                  disabled={this.state.selectedOrderIds.length === 0}
                >
                  <Button
                    className="dropdown-btn"
                    style={
                      this.state.selectedOrderIds.length !== 0
                        ? {
                            background: "#373873",
                            color: "#fff",
                            height: "32px",
                            marginBottom: "10px",
                          }
                        : { height: "32px", marginBottom: "10px" }
                    }
                  >
                    <Badge count={this.state.selectedOrderIds.length}>
                      <a href="#" className="head-example" />
                    </Badge>{" "}
                    Order Action <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
              <div style={{ marginTop: "15px", marginLeft: "15px" }}>
                <Dropdown
                  overlay={recordPerpage}
                  //disabled={this.state.selectedOrderIds.length === 0}
                >
                  <Button
                    className="dropdown-btn"
                    style={
                      this.state.selectedOrderIds.length !== 0
                        ? {
                            background: "#373873",
                            color: "#fff",
                            height: "32px",
                            marginBottom: "10px",
                          }
                        : { height: "32px", marginBottom: "10px" }
                    }
                  >
                    {`Records Per Page - ${this.state.recordsPerpage.key}`}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            <Search
              placeholder={`Search by ${this.state.activeAdvSearch.label}`}
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              onKeyUp={(e) => this.handleSearch(e.target.value)}
            />

            <Dropdown overlay={advancedSearchMenu}>
              <Button
                className="dropdown-btn"
                style={{ flex: 1, marginRight: "5px" }}
              >
                {this.state.activeAdvSearch.label === "Email"
                  ? "Advanced Search"
                  : this.state.activeAdvSearch.label}
                <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.visible}
              overlay={columnFilterMenu}
            >
              <Button
                onClick={(e) => e.preventDefault()}
                className="dropdown-btn"
                style={{ flex: 1, marginRight: "5px" }}
              >
                Column Filters <Icon type="down" />
              </Button>
            </Dropdown>

            <Dropdown overlay={filterByCountryMenu}>
              <Button
                className={classnames("dropdown-btn", {
                  "filter-selected": this.isCountryStatusApplied(),
                })}
                style={{ flex: 1, marginRight: "5px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getCountryStatusCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Country
                <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown overlay={orderTypeFilterMenu}>
              <Button
                className={classnames("dropdown-btn", {
                  "filter-selected": this.isOrderTypeStatusApplied(),
                })}
                style={{ flex: 1, marginRight: "5px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getOrderTypeStatusCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Order Type
                <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown overlay={filterProductMenu}>
              <Button
                className={classnames("dropdown-btn", {
                  "filter-selected": this.isProductStatusApplied(),
                })}
                style={{ flex: 1, marginRight: "5px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getActiveProductCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Products
                <Icon type="down" />
              </Button>
            </Dropdown>

            <Dropdown overlay={menu}>
              <Button
                className={`dropdown-btn ${
                  this.state.activeStatusFilters.length > 0 && "filter-selected"
                }`}
                style={{ flex: 1, marginRight: "5px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getPaymentStatusCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Payment Status <Icon type="down" />
              </Button>
            </Dropdown>

            <Dropdown overlay={fulfillmentMenu}>
              <Button
                className={`dropdown-btn ${
                  this.isFullfillmentStatusApplied() && "filter-selected"
                }`}
                style={{ flex: 1, marginRight: "5px" }}
              >
                {" "}
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getFullfillmentStatusCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Fulfillment Status <Icon type="down" />
              </Button>
            </Dropdown>
            <Button
              onClick={() => {
                this.setState({
                  activeFulfillMentFilters: [],
                  activeStatusFilters: [],
                  selectedOrderIds: [],
                });
                function disabledDate(current) {
                  // Can not select days before today and today
                  return current && current < moment().endOf("day");
                }
                this.props.fetchAllOrders(`status=2&status=3&status=4`);
              }}
              // disabled={
              //   this.state.activeFulfillMentFilters.length === 0 &&
              //   this.state.activeStatusFilters.length === 0
              // }
              disabled={this.isClearAllFilterDisabled()}
              className="dropdown-btn"
              style={{ width: "140px", marginRight: "5px" }}
            >
              Clear All Filters
            </Button>
          </div>
          <div>
            <div style={{ background: "white" }}>
              {this.props.ordersPageDetails && (
                <Table
                  //rowClassName="cursor-pointer woo"
                  rowClassName={(order, index) => {
                    if (
                      order?.orderId?.flaggedStatus == 2 &&
                      this.state.flaggedStatusFilter !== "2" &&
                      this.state.activeFulfillMentFilters !== -1
                    ) {
                      return "table-row-green";
                    } else if (order.fulfillmentStatusCode === -1) {
                      return "table-row-error";
                    } else {
                      if (index % 2 === 0) {
                        return "table-row-light";
                      } else {
                        return "table-row-dark";
                      }
                    }
                    // return order.fulfillmentStatusCode === -1
                    //   ? "table-row-error"
                    //   : index % 2 === 0
                    //   ? "table-row-light"
                    //   : "table-row-dark";
                  }}
                  columns={columns}
                  dataSource={mappedOrdersList}
                  bordered
                  size="small"
                  rowSelection={rowSelection}
                  pagination={{
                    current:
                      Number(this.props.ordersPageDetails.currentPage + 1) || 0,
                    total: this.props.ordersPageDetails.totalCount || 0,
                    pageSize: Number(this.state.recordsPerpage.key),
                    onChange: (pageNo) => {
                      this.setState({ pageNo: pageNo, selectedOrderIds: [] });
                      this.props.fetchAllOrders(
                        this.getSelectedQueryStringFilter("Initial"),
                        pageNo - 1,
                        undefined,
                        this.state.startDate,
                        this.state.endDate,
                        this.state.activeStatusFilters,
                        this.state.activeFulfillMentFilters
                      );
                    },
                  }}
                  scroll={{ x: 1500 }}
                />
              )}
            </div>
          </div>
          <Modal
            title="This will change order status to Shipped !"
            visible={this.state.fulfillmentModal}
            okText="Yes"
            onOk={this.handleUpdateFullfillmentStatusByBasicUser}
            cancelText="No"
            onCancel={() => {
              this.setState({ fulfillmentModal: false });
              this.props.form.resetFields();
            }}
          >
            <div style={{ paddingBottom: "10px" }}>TRACKING INFORMATION</div>
            {/* tracking information */}
            <Form>
              <div className="flex flex-start">
                <Form.Item
                  style={{ width: "calc(50% - 6px)", marginRight: "12px" }}
                >
                  {getFieldDecorator(`trackingDetails.number`, {
                    initialValue:
                      this.state.orderToFulfill &&
                      this.state.orderToFulfill.trackingDetails
                        ? this.state.orderToFulfill.trackingDetails.number
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                  })(<Input placeholder="Tracking Number" />)}
                </Form.Item>
                <Form.Item style={{ width: "calc(50% - 6px)" }}>
                  {getFieldDecorator(`trackingDetails.carrier`, {
                    initialValue:
                      this.state.orderToFulfill &&
                      this.state.orderToFulfill.trackingDetails
                        ? this.state.orderToFulfill.trackingDetails.carrier
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                  })(
                    <Select placeholder="Shipping Carrier">
                      {trackingDetailsCarriers.map((carrier) => (
                        <Option key={carrier.value}>{carrier.label}</Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </Form>
          </Modal>
          <Modal
            title="Whatsapp message"
            visible={this.state.whatsAppModal}
            okText="Yes"
            onOk={this.handelUpdateWhatsAppMessage}
            cancelText="No"
            onCancel={() => {
              this.setState({ whatsAppModal: false });
              this.props.form.resetFields();
            }}
          >
            <div style={{ paddingBottom: "10px" }}> Whatsapp message</div>
            {/* tracking information */}
            <Form>
              <div className="flex flex-start">
                <Form.Item
                  style={{ width: "calc(50% - 6px)", marginRight: "12px" }}
                >
                  {getFieldDecorator(`message`, {
                    initialValue:
                      this.props.whatsAppMessageList &&
                      this.props.whatsAppMessageList.length > 0
                        ? this.props.whatsAppMessageList[0].message
                        : "",
                    rules: [
                      {
                        required: true,
                        message: "Required",
                      },
                    ],
                  })(
                    <Input.TextArea
                      placeholder="Hey there"
                      style={{ maxWidth: "none", width: "478px" }}
                    />
                  )}
                  <div style={{ paddingBottom: "10px" }}>
                    {" "}
                    Add dynamic field: {" {{ first_name }}"}{" "}
                  </div>
                </Form.Item>
              </div>
            </Form>
          </Modal>
          <FlaggedModal
            orderId={this.state.selectedOrderIds}
            showFlaggedModal={this.state.showFlaggedModal}
            flaggedStatus={this.state.flaggedStatus}
            updateFlaggedStatus={this.props.updateFlaggedstatuses}
            onClickOk={this.updateFlaggedStatus}
            closeModal={() =>
              this.setState({
                showFlaggedModal: false,
              })
            }
          />
        </div>
        <Modal
          title="Cancel Orders"
          visible={this.state.cancelOrderModal}
          okText="Yes"
          onOk={() => {
            let reason = this.state.cancelReason;

            if (reason == "other") {
              // console.log(
              //   "other box value",
              //   this.cancelOrderReasonRef.current.state.value
              // );
              reason = this.cancelOrderReasonRef.current.state.value;
            }

            this.markCanceledMultipleShipment({
              orderIds: this.state.selectedOrderIds,
              reason: reason,
            });
          }}
          cancelText="No"
          onCancel={() => {
            this.setState({ cancelOrderModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            {" "}
            <p>Are you sure you want to proceed? </p>
            <Select
              placeholder="Select Reason"
              onChange={(val) => {
                this.setState({ cancelReason: val });
              }}
            >
              {Cancel_Reason.map((reason, index) => (
                <Option value={reason.key} key={index}>
                  {reason.label}
                </Option>
              ))}

              <Option value="other" key="other">
                Other
              </Option>
            </Select>
          </div>
          {this.state.cancelReason == "other" && (
            <Input
              placeholder="Type your reason"
              ref={this.cancelOrderReasonRef}
            />
          )}
        </Modal>
        <Modal
          title="Ready To Pack"
          visible={this.state.showRTPModal}
          okText="Yes"
          onOk={() => {
            this.markReadyToPackMultipleShipment({
              orderIds: this.state.selectedOrderIds,
            });
          }}
          cancelText="No"
          onCancel={() => {
            this.setState({
              showRTPModal: false,
              rtpDateCheckbox: false,
              alternateLabelAU: false,
            });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            {" "}
            <p>Are you sure you want to proceed? </p>
            <Checkbox
              checked={this.state.rtpDateCheckbox}
              onChange={(e) => {
                this.setState({
                  rtpDateCheckbox: e.target.checked,
                  alternateLabelAU: false,
                });
              }}
            >
              Schedule: Ready To Pack{" "}
            </Checkbox>
            <Checkbox
              checked={this.state.alternateLabelAU}
              onChange={(e) => {
                this.setState({
                  alternateLabelAU: e.target.checked,
                  rtpDateCheckbox: false,
                });
              }}
            >
              Create Alternate Label for AU:{" "}
            </Checkbox>
          </div>
          {this.state.rtpDateCheckbox && (
            <DatePicker
              placeholder="Select Schedule Date"
              disabledDate={(current) => {
                return current && current < moment().endOf("day");
              }}
              onChange={(value, dateString) => {
                //console.log(value, dateString);
                this.setState({
                  scheduleRTPTime: dateString,
                });
              }}
              format="YYYY-MM-DD"
            />
          )}
        </Modal>
        <Modal
          title="Order Return"
          visible={this.state.showOrderReturnModal}
          okText="Yes"
          onOk={() => {
            console.log(this.orderReturnedReasonRef.current);
            this.markOrderReturnedMultiple({
              orderIds: this.state.selectedOrderIds,
              reason: this.orderReturnedReasonRef.current.state.value,
            });
          }}
          cancelText="No"
          onCancel={() => {
            this.setState({
              showOrderReturnModal: false,
            });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            {" "}
            <p>Are you sure you want to proceed? </p>
            <TextArea
              //value={}
              ref={this.orderReturnedReasonRef}
              onChange={(e) => {
                this.setState({ reasonForOrderReturned: e.target.value });
              }}
              placeholder="Enter comment"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </div>
        </Modal>
      </LoadingOverlay>
    );
  }
}

function mapStateToProps(state) {
  const { Orders, Assessment } = state;
  return {
    ordersList: Orders.ordersList,
    ordersPageDetails: Orders.ordersPageDetails,
    activeAssessment: Assessment.activeAssessment,
    whatsAppMessageList: Orders.whatsAppMessageList,
    customerOrderList: Orders.customerOrderList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllOrders: bindActionCreators(actions.getOrders, dispatch),
    fetchAllExportOrders: bindActionCreators(actions.exportOrders, dispatch),
    deleteOrder: bindActionCreators(actions.deleteOrder, dispatch),
    updateOrder: bindActionCreators(actions.updateOrder, dispatch),
    createShipment: bindActionCreators(
      fulfillmentActions.createShipment,
      dispatch
    ),
    generateShipmentLabel: bindActionCreators(
      fulfillmentActions.generateShipmentLabel,
      dispatch
    ),
    markShipmentToShipped: bindActionCreators(
      fulfillmentActions.markShipmentToShipped,
      dispatch
    ),
    markShipmentToReadyForPack: bindActionCreators(
      fulfillmentActions.markShipmentToReadyForPack,
      dispatch
    ),
    markShipmentToDelivered: bindActionCreators(
      fulfillmentActions.markShipmentToDelivered,
      dispatch
    ),
    markShipmentToReadyForPackMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToReadyForPackMultiple,
      dispatch
    ),
    markShipmentToDeliveredMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToDeliveredMultiple,
      dispatch
    ),
    markShipmentToShippedMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToShippedMultiple,
      dispatch
    ),
    createShipmentInBulk: bindActionCreators(
      fulfillmentActions.createShipmentInBulk,
      dispatch
    ),
    generateLabelInBulk: bindActionCreators(
      fulfillmentActions.generateLabelInBulk,
      dispatch
    ),
    markShipmentToHoldMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToHoldMultiple,
      dispatch
    ),
    markShipmentToCancelMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToCancelMultiple,
      dispatch
    ),
    markDisposedMultipleShipment: bindActionCreators(
      fulfillmentActions.markDisposedMultipleShipment,
      dispatch
    ),
    markOrderReturnedMultiple: bindActionCreators(
      fulfillmentActions.markOrderReturnedMultiple,
      dispatch
    ),
    getAssessmentById: bindActionCreators(
      assessmentActions.getAssessmentById,
      dispatch
    ),
    getLatestAssessmentByUserId: bindActionCreators(
      assessmentActions.getLatestAssessmentByUserId,
      dispatch
    ),
    updateWhatsAppStatus: bindActionCreators(
      actions.updateWhatsAppMsgStatus,
      dispatch
    ),
    getWhatsAppMessage: bindActionCreators(
      actions.getWhatsAppMessage,
      dispatch
    ),
    updateWhatsAppMessage: bindActionCreators(
      actions.updateWhatsAppMessage,
      dispatch
    ),
    updateMultiOrdersWhatsAppMsgStatus: bindActionCreators(
      actions.updateMultiOrdersWhatsAppMsgStatus,
      dispatch
    ),
    downloadMultipleInvoice: bindActionCreators(
      actions.downloadMultipleInvoice,
      dispatch
    ),
    getActiveProduct: bindActionCreators(
      fulfillmentActions.getActiveProduct,
      dispatch
    ),
    getLastOrdersForCustomer: bindActionCreators(
      actions.getLastOrdersForCustomer,
      dispatch
    ),
    updateFlaggedstatuses: bindActionCreators(
      actions.updateFlaggedstatuses,
      dispatch
    ),
  };
}
const CompletedOrdersForm = Form.create({ name: "orders_form" })(
  CompletedOrders
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedOrdersForm);
