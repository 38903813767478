import { Api } from "../config/request";

export const getProductById = id => {
  let url = `catalogue/product/${id}`;
  return Api.getRequest(url);
};

export const getProductsList = query => {
  let url = `catalogue/product/list${query ? "?"+query : ""}`;
  return Api.getListRequest(url);
};

export const getActiveProductsList = () => {
  let url = `catalogue/product/search?type=active`;
  return Api.getRequest(url);
}

export const getSearchedProducts = (type, name, tags, sort) => {
  let url = `catalogue/product/search?${type ? 'type='+type+'&' : ""}${name ? 'name='+name+'&' : ""}${tags ? 'tags='+tags+'&' : ""}${sort ? 'orderBy=createdAt:'+sort : ""}`;
  return Api.getListRequest(url);
}

export const createProduct = (payload) => {
  let url = `catalogue/product/`;
  return Api.postRequest(url, payload)
}

export const updateProductById = (id, payload) => {
  let url = `catalogue/product/${id}`;
  return Api.patchRequest(url, payload);
}

export const updateProductStock = (id,payload) => {
  let url = `catalogue/product/${id}/inventory`;
  return Api.patchRequest(url,payload)
}

export const createInventoryLogs = (payload) => {
  let url = `catalogue/product/inventory/logs`;
  return Api.postRequest(url,payload)
}

const Admins = {
    getProductById,
    getProductsList,
    getSearchedProducts,
    createProduct,
    updateProductById,
    updateProductStock,
    createInventoryLogs,
    getActiveProductsList
};

export default Admins;
