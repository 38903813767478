import React, {Component} from 'react';
import './notFoundPage.scss'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as notFoundPageActions from "../../store/notFoundPage/actions";
export default class notFoundPage extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return <div className="view-not-found-page">Hello! component notFoundPage</div>;
    }
  }
// export default connect(
//     ({ notFoundPage }) => ({ ...notFoundPage }),
//     dispatch => bindActionCreators({ ...notFoundPageActions }, dispatch)
//   )( notFoundPage );