import TaxApi from "services/tax";
import * as queryString from "query-string";

function handleError(err) {
  console.error(err);
}

export function listTax(result) {
  return {
    type: "LIST_TAX",
    payload: result,
  };
}

export function addTax(result) {
  return {
    type: "ADD_TAX",
    payload: result,
  };
}

export function updateTaxList(result) {
  return {
    type: "UPDATE_TAX_LIST",
    payload: result,
  };
}

export function setActiveTax(payload) {
  return {
    type: "SET_ACTIVE_TAX",
    payload,
  };
}

export const getSearchedTax = (payload) => async (dispatch) => {
  try {
    let query = queryString.stringify(payload);
    let res = await TaxApi.getTaxList(query);
    dispatch(listTax(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const createTax = (payload) => async (dispatch) => {
  try {
    let res = await TaxApi.createTax(payload);
    dispatch(addTax(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateTax = (id, payload) => async (dispatch) => {
  try {
    let res = await TaxApi.updateTaxById(id, payload);
    dispatch(updateTaxList(res));
    dispatch(setActiveTax(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};
