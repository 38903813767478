import { LIST_REFERRALS, LIST_EXPORT_REFERRALS } from "actions/referral/types";

const initialState = {
  referrals: {
    count: 0,
    result: [],
    next: 0,
    totalCount: 0,
    prev: 0,
  },
  exports: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_REFERRALS:
      return listReferral(state, action);
    case LIST_EXPORT_REFERRALS:
      return listExportReferral(state, action);
    default:
      return state;
  }
}

function listReferral(state, action) {
  return {
    ...state,
    referrals: { ...action.payload },
  };
}

function listExportReferral(state, action) {
  return {
    ...state,
    exports: [...action.payload],
  };
}
