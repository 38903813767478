import React, {Component} from 'react';
import './assessmentList.scss'
import { Icon, Button, Table, Badge, message, Modal, InputNumber } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/assessment";
import { CSVLink } from "react-csv";
import moment from "moment";

class AssessmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showExportModal:false,
          exportModalLoading:false,
          numberOfRows:10
        };
        this.csvRef = React.createRef();
    }
    componentDidMount() {
      this.props.fetchAssessmentList();
    }
    onChangeInput = value => {
      this.setState({numberOfRows:value})
    }
    handleViewQuestions = (assessmentObj) => {
      const win = window.open(`/assessment/${assessmentObj.id}/questions`, "_blank");
      win.focus();
    }
    
    render() {
      const { assessmentList } = this.props;
      // const menu = (
      //   <Menu onClick={this.handleMenuClick}>
      //     <Menu.Item key="1">
      //       <Icon type="user" />
      //       1st menu item
      //     </Menu.Item>
      //     <Menu.Item key="2">
      //       <Icon type="user" />
      //       2nd menu item
      //     </Menu.Item>
      //     <Menu.Item key="3">
      //       <Icon type="user" />
      //       3rd item
      //     </Menu.Item>
      //   </Menu>
      // );
      const columns = [
        {
          title: "Published",
          dataIndex: "isPublished",
          key: "isPublished",
          render: state => {
            return state ? (
              <Badge status="success" />
            ) : (
              <Badge status="default" />
            );
          }
        },
        {
          title: "Active",
          dataIndex: "isActive",
          key: "isActive",
          render: state => {
            return state ? (
              <Badge status="success" />
            ) : (
              <Badge status="default" />
            );
          }
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt"
        },
        {
          title: "Assessment ID",
          dataIndex: "id",
          key: "id"
        }
      ];
      let headers= columns.map(item=>{
        return {label:item.title,
           key:item.key}
       })
      const mappedAssessmentList =
        assessmentList ?
        assessmentList.map(assessment => {
        return {
          id: assessment.id,
          isPublished: assessment.isPublished,
          isActive: assessment.isActive,
          createdAt: moment(assessment.createdAt).format("DD MMMM YYYY")
        };
      }):[];
      const csvData= mappedAssessmentList.map(
        (item)=>{
            return {...item,isActive:item.isActive ? "true" :"false",isPublished:item.isPublished?"true":"false"}
          } 
        ).slice(0,this.state.numberOfRows)
      return (
        <div className="assessment-list-page" style={{ marginLeft: "200px" }}>
          <div className="flex items-center mb-36">
            <div className="theme-color semibold text-30 mr-14">
              Assessment List
            </div>
            {localStorage.getItem("userType") === "admin" && (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  this.setState({ showExportModal: true });
                }}
              >
                <Icon type="export" className="mr-4" />
                <div>Export</div>
              </div>
            )}
          </div>

          <div className="flex nav-container justify-between">
            <div className="sub-text-top-left theme-color">All</div>
            <Button
              type="primary"
              onClick={() => message.info("coming soon")}
              style={{ marginTop: "-14px" }}
            >
              <Icon type="plus"></Icon>
              Add Assessment
            </Button>
          </div>

          <div>
            <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
              {/* <Search
                placeholder="Search assessment"
                onSearch={value => this.handleSearch(value)}
                style={{ width: "57.14%", marginRight: "5px" }}
                onKeyUp={e => this.handleSearch(e.target.value)}
              />
              <Dropdown overlay={menu}>
                <Button style={{ flex: 1, marginRight: "5px" }}>
                  Filters <Icon type="down" />
                </Button>
              </Dropdown>
              <Button
                style={{ display: "flex", alignItems: "center" }}
                onClick={this.handleSort}
              >
                <span className="mr-4">Sort</span>
                <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
              </Button> */}
            </div>
            <div style={{ background: "white" }}>
              <Table
                rowClassName="cursor-pointer"
                columns={columns}
                dataSource={mappedAssessmentList}
                bordered
                size="small"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.handleViewQuestions(record);
                    },
                  };
                }}
              />
            </div>
          </div>
          <Modal
            title="How many rows to be exported ?"
            visible={this.state.showExportModal}
            okText="Export"
            confirmLoading={this.state.exportModalLoading}
            onCancel={() => {
              this.setState({ showExportModal: false });
            }}
            onOk={async () => {
              this.setState({ exportModalLoading: true });
              this.setState({
                exportModalLoading: false,
                showExportModal: false,
              });
              this.csvRef.current && this.csvRef.current.link.click();
            }}
          >
            <InputNumber
              min={1}
              defaultValue={this.state.numberOfRows}
              onChange={this.onChangeInput}
            />
          </Modal>
          <CSVLink
            data={csvData}
            headers={headers}
            ref={this.csvRef}
            style={{ display: "none" }}
            filename="assessments.csv"
          />
        </div>
      );
    }
  }

function mapStateToProps(state) {
  const { Assessment } = state;
  return {
    assessmentList: Assessment.assessmentList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAssessmentList: bindActionCreators(
      actions.getSearchedAssessment,
      dispatch
    )  
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList);
