import React, { useMemo, useState } from "react";
import { Modal, Select, Input, Form, Button, InputNumber, Row ,Col} from "antd";
import { useDispatch  } from "react-redux";
import * as actions from "actions/orders";
const { Option } = Select;
const AddProductsModal = ({
  showModal,
  onClickOk,
  closeModal,
  orderId,
  form,
  allProducts,
  orderItems
}) => {
  const dispatch = useDispatch();
  const [noOfProducts, setNoOfProducts] = useState(1);
  const { getFieldDecorator } = form;
  
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      //console.log("values", values);
      if (!err) {
        const payload = { products: values, order: orderId };
        //console.log( payload)
        onClickOk(payload);
        closeModal();
      }
    });
  };
  return (
    <Modal
      title="Add More products"
      visible={showModal}
      onCancel={closeModal}
      onOk={onClickOk}
      footer={[
        <Button
          form="add_product"
          className="ant-btn ant-btn-primary"
          key="submit"
          htmlType="submit"
        >
          Save
        </Button>,
      ]}
    >
      <InputNumber
        min={1}
        max={10}
        value={noOfProducts}
        placeholder="No of products to add"
        style={{ marginBottom: "20px" }}
        // disabled={this.isEditMode()}
        onChange={(n) => {
          if (!(n < 1 || n > 10)) {
            setNoOfProducts(n);
          }
        }}
      />
      <Form id="add_product" onSubmit={handleSubmit}>
        {new Array(noOfProducts).fill(1).map((n, index) => (
          <Row type="flex" justify="" key={index}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator(`products[${index}].product`, {
                  initialValue: undefined,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(
                  <Select
                    placeholder="Select product to add"
                    value={""}
                    onChange={(val) => {
                    }}
                  >
                    {allProducts &&
                      allProducts
                        .filter((p) => !orderItems?.includes(p.id))
                        .map((product) => (
                          <Option value={product.id} key={product.id}>
                            {product.name}
                          </Option>
                        ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item className="d-none">
                {getFieldDecorator(`products[${index}].recommendationType`, {
                  initialValue: allProducts.find(
                    (e) =>
                      e.id == form.getFieldValue(`products[${index}].product`)
                  )?.category?.name,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={6} style={{ paddingLeft: "20px" }}>
              <Form.Item>
                {getFieldDecorator(`products[${index}].quantity`, {
                  initialValue: 1,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(<InputNumber min={1} max={10} placeholder="Quantity" />)}
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Form>
    </Modal>
  );
};

const addProductModal = Form.create({ name: "add_product" })(
  AddProductsModal
);

export default addProductModal;
