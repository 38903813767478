import React, { Component } from "react";
import "./createCart.scss";
import {
  Table,
  Icon,
  Select,
  Input,
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  message,
  Layout,
  Card,
  Modal,
  Radio,
  Tag,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import * as actions from "actions/customer";
import * as orderActions from "actions/orders";
import * as productActions from "actions/products";
import * as customerActions from "actions/customer";
import { getProductById, getProductsList } from "services/products";

import { getSafely } from "utils/helpers";
import BinIcon from "static/img/icon/Bin.svg";
import API from "services/config/apiUrl";
import { CopyToClipboard } from "react-copy-to-clipboard";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

// const { Header, Footer, Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;
const FormItem = Form.Item;

const currency = [
  {
    name: "SGD",
    id: "sgd",
  },
  { name: "NZD", id: "nzd" },
  { name: "AUD", id: "aud" },
  { name: "USD", id: "usd" },
  { name: "HKD", id: "hkd" },
];

const getColumns = ({
  getFieldDecorator,
  productListByCategory,
  handleFetchProductsByCategory,
  deleteProduct,
  currency_name = "",
  allProducts,
  items,
}) => {
  return [
    {
      title: "Items",
      dataIndex: "product",
      key: "items",
      render: (product, row, i) => (
        <div className="flex items-center">
          {product?.productImage?.mediaUrl ? (
            <img
              src={product?.productImage?.mediaUrl}
              alt="product"
              style={{
                height: "45px",
                width: "45px",
                minWidth: "44px",
                objectFit: "cover",
                objectPosition: "center",
                marginRight: "10px",
              }}
            />
          ) : (
            <img
              src={product?.mainImage?.withoutHover?.mediaUrl}
              alt="product"
              style={{
                height: "45px",
                width: "45px",
                minWidth: "44px",
                objectFit: "cover",
                objectPosition: "center",
                marginRight: "10px",
              }}
            />
          )}
          <div className="flex items-center">
            <p className="m0 text-black">{product.name}</p>
            <FormItem className="recommendation-type">
              {getFieldDecorator(`data[${i}].recommendationType`, {
                initialValue: row.recommendationType,
                rules: [
                  {
                    required: true,
                    message: "Required",
                  },
                ],
              })(<Input type="text" placeholder="Recommendation Type" />)}
            </FormItem>
          </div>
        </div>
      ),
    },
    {
      title: "Weight",
      dataIndex: "product",
      key: "weight",
      render: (product, row, i) => {
        return (
          <>
            {product?.shippingMetadata?.weight}kg
            <div className="d-none">
              <FormItem>
                {getFieldDecorator(`data[${i}].productId`, {
                  initialValue: row.id,
                  rules: [
                    {
                      required: true,
                      message: "Required",
                    },
                  ],
                })(<InputNumber min={1} max={10} placeholder="ProductId" />)}
              </FormItem>
            </div>
          </>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, row, i) => (
        <FormItem>
          {getFieldDecorator(`data[${i}].quantity`, {
            initialValue: quantity,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ],
          })(<InputNumber min={1} max={10} placeholder="Quantity" />)}
        </FormItem>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (product, row, i) => {
        const item = allProducts.find((x) => x.id === row.id);
        let price = item?.prices
          ? item?.prices.find(
              (x) =>
                x.currency.code.toLowerCase() === currency_name.toLowerCase()
            ).price
          : 0;
        return (
          <>
            {currency_name.toUpperCase()} {price}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "product",
      key: "replasewith",
      render: (product, row, i) => (
        <>
          {!row?.product?.category?.name
            .toLowerCase()
            .includes("essentials") && (
            <FormItem className="product-replace">
              {getFieldDecorator(`data[${i}].newProduct`, {
                initialValue: undefined,
                rules: [
                  {
                    required: false,
                    message: "Required",
                  },
                ],
              })(
                <Select
                  className="table-item"
                  placeholder="Select product to replace with"
                  onFocus={() =>
                    handleFetchProductsByCategory(
                      getSafely(() => product?.id, null)
                    )
                  }
                >
                  {productListByCategory &&
                    productListByCategory.map((product) => (
                      <Option value={product.id} key={product.id}>
                        {product.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "delete",
      key: "action",
      render: (quantity, row, i) => (
        <>
          {items.length != 1 && (
            <Button
              type="danger"
              shape="circle"
              onClick={() => deleteProduct(i)}
            >
              <img src={BinIcon} className="m-auto" alt="sort" />
            </Button>
          )}
        </>
      ),
    },
  ];
};

class CreateCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "",
      email: "",
      name: "",
      cart: {
        items: [],
      },
      subscription: {},
      noOfProducts: 4,
      productListByCategory: [],
      showDeleteModal: false,
      selectedProductIndex: 0,
      showAddProductModal: false,
      addSelectedProduct: undefined,
      showShippingAddressForm: false,
      showBillingAddressForm: false,
      cancelModalVisible: false,
      cancelSubscriptionText: "",
      customerId: undefined,
      customerSubscriptionId: undefined,
      currentSubscriptionPeriodValue: 60,
      newSubscriptionPeriodValue: 60,
      rescheduleSubscriptionDate: null,
      orderType: true,
      promoCode: "",
      currency_name: "usd",
      promoCodeError: "",
    };
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
    this.handleFetchProductsByCategory =
      this.handleFetchProductsByCategory.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.getNewTotal = this.getNewTotal.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAllProductForAdd = this.getAllProductForAdd.bind(this);
    this.onOrderTypeChange = this.onOrderTypeChange.bind(this);
    this.updateOrderType = this.updateOrderType.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
    this.removePromoCode = this.removePromoCode.bind(this);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    if (params.cartId) {
      try {
        const cart = await this.props.fetchCart(params.cartId);
        this.setState({
          cartId: cart.id,
          cart: {
            ...cart,
            items: cart.items.map((x) => ({
              ...x,
              id: x.product,
              product: x.productData,
            })),
          },
          orderType: cart.isSubscribed,
          currentSubscriptionPeriodValue: cart.subscriptionPeriod || 60,
        });
      } catch (error) {
        message.error("Failed to find the cart, Please create new one");
        this.props.history.push("/cart");
      }

      // if (cart?.user) {
      //   this.props.fetchCustomer(cart.user).then((res) => {
      //     if (res) {
      //       this.setState({
      //         email: res.email,
      //         name: `${res.firstName} ${res.lastName}`,
      //       });
      //     }
      //   });
      // }
    }
    this.props.fetchAllProducts();
  }

  async handleFetchProductsByCategory(productId) {
    try {
      this.setState({
        productListByCategory: [],
      });
      const productObj = await getProductById(productId);
      const productCategoryId = productObj.category.id;
      const productListByCategory = await getProductsList(
        `category.id=${productCategoryId}`
      );
      const { cart } = this.state;
      let itemsInCategory = [...productListByCategory.result.result];
      itemsInCategory = itemsInCategory.filter(function (item) {
        for (var cartItem of cart.items) {
          if (item.id === cartItem.product._id) return false;
        }
        return true;
      });

      this.setState({
        productListByCategory: itemsInCategory,
      });
    } catch (err) {
      message.error("Oops! Something went wrong while fetching products list");
    }
  }

  showDeleteConfirm() {
    const { cart, selectedProductIndex, cartId } = this.state;
    confirm({
      title: `Do you want to remove ${cart.items[selectedProductIndex].product.name} from this subscription?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        let product = cart.items[selectedProductIndex];
        if (!product._id) {
          cart.items.splice(selectedProductIndex, 1);
          this.setState({
            cart: JSON.parse(JSON.stringify(cart)),
          });
        } else {
          let items = [
            {
              product: product.id,
              quantity: 0,
            },
          ];
          this.props
            .updateCart({
              id: cartId,
              items,
            })
            .then(async (x) => {
              await this.componentDidMount();
            });
        }
      },
      onCancel: () => {
        this.setState({
          selectedProductIndex: 0,
        });
      },
    });
  }

  deleteProduct(index) {
    this.setState(
      {
        selectedProductIndex: index,
      },
      () => this.showDeleteConfirm()
    );
  }

  addProduct() {
    if (!this.state.addSelectedProduct) {
      message.error("Please select product to add");
    }
    const { allProducts } = this.props;
    const { cart } = this.state;
    const product = allProducts.find(
      (x) => x.id == this.state.addSelectedProduct
    );
    const price = product.prices.find(
      (x) =>
        x.currency.code.toLowerCase() === this.state.currency_name.toLowerCase()
    );
    let items = this.state.cart.items || [];
    items.push({
      isSubscribed: true,
      price: `${price.price}`,
      priceAfterDiscount: price.price,
      product: {
        bannerImage: product.bannerImage,
        category: product.category,
        id: product.id,
        _id: product.id,
        mainImage: product.mainImage,
        name: product.name,
        secondaryImage: product.secondaryImage,
        shippingMetadata: product.shippingMetadata,
        yotpoId: product.yotpoId,
      },
      id: product.id,
      quantity: 1,
      recommendationType: product.category.name,
    });

    this.setState({
      cart: { ...cart, items },
      showAddProductModal: false,
      addSelectedProduct: undefined,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { cart } = this.state;
    const { allProducts } = this.props;
    message.info("Updating cart now, please wait");
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const items = cart.items.map((x) => {
          let item = values.data.find((i) => x.id === i.productId);
          if (item && item.newProduct) {
            const product = allProducts.find((y) => y.id === item.newProduct);
            return {
              product: product.id,
              quantity: item.quantity,
              recommendationType: product.category.name,
              yotpoId: x.product.yotpoId,
            };
          } else {
            return {
              recommendationType: x.product.category.name,
              product: x.id,
              quantity: item?.quantity ? item?.quantity : x.quantity,
              yotpoId: x.yotpoId,
            };
          }
        });
        try {
          const payload = {
            items,
            currency_name: this.state.currency_name,
            user: cart.user || undefined,
          };
          if (this.state.cartId) {
            payload.id = this.state.cartId;
          }
          let res;
          if (payload.id) {
            await this.props.addToCart(payload);
            res = await this.props.updateCart(payload);
          } else {
            res = await this.props.addToCart(payload);
          }

          if (res) {
            message.success(
              this.state.cartId ? "cart updated!" : "cart created"
            );
            if (this.state.cartId) {
              await this.componentDidMount();
            } else {
              this.props.history.push(`/cart/${res.id}`);
            }
          } else {
            message.error(
              `Failed to ${
                this.state.cartId ? "update" : "create"
              } the cart. Please try again.`
            );
          }
        } catch (error) {
          message.error(
            `Failed to ${
              this.state.cartId ? "update" : "create"
            } the cart. Please try again.`
          );
        }
      }
    });
  }

  getAllProductForAdd() {
    const { allProducts, form } = this.props;
    const { data } = form.getFieldsValue();
    if (allProducts && data) {
      return allProducts.reduce((acc, x) => {
        const item = data.find(
          (d) => x.id === d.newProduct || x.id === d.productId
        );
        if (
          !item &&
          x.type === "active" &&
          !x.name.toLowerCase().includes("day and night set")
        ) {
          acc.push(x);
        }
        return acc;
      }, []);
    } else if (allProducts && !data) {
      return allProducts.reduce((acc, x) => {
        if (
          x.type === "active" &&
          !x.name.toLowerCase().includes("day and night set")
        ) {
          acc.push(x);
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  }

  getNewTotal() {
    const { currency_name } = this.state;
    const { allProducts } = this.props;
    const { data } = this.props.form.getFieldsValue();
    if (data && allProducts.length) {
      const total = data.reduce((acc, x) => {
        const product = allProducts.find((y) => {
          if (x.newProduct !== undefined) {
            return y.id === x.newProduct;
          } else {
            return y.id === x.productId;
          }
        });
        let currency = currency_name;
        if (product) {
          const price = product.prices.find(
            (x) => x.currency.code.toLowerCase() === currency.toLowerCase()
          );
          return price.price * x.quantity + acc;
        } else {
          return acc;
        }
      }, 0);
      return Math.round(total).toFixed(2);
    } else {
    }
  }

  onOrderTypeChange(value) {
    this.setState({
      orderType: value.target.value,
    });
  }

  async updateOrderType() {
    try {
      message.info("Updating cart now, please wait");
      await this.props.updateCartType({
        cartId: this.state.cartId,
        isSubscribed: this.state.orderType,
        subscriptionPeriod: this.state.currentSubscriptionPeriodValue,
      });
      message.success("Cart updated successfully");
    } catch (error) {
      message.error("Error while updating cart order type");
    }
  }

  async applyPromoCode() {
    try {
      message.info("Apply promo code to cart now, please wait");
      let res = await this.props.applyPromoCode({
        promoCode: this.state.promoCode,
        cartId: this.state.cartId,
      });
      if (res.error) {
        message.error("Error while applying promo code to cart");
        this.setState({
          promoCodeError: res.error.message,
          promoCode: "",
        });
      } else {
        this.setState({
          promoCodeError: "",
          promoCode: "",
        });
        message.success("Promo code Applied");
        await this.componentDidMount();
      }
    } catch (error) {
      message.error("Error while applying promo code to cart");
    }
  }

  async removePromoCode() {
    try {
      message.info("Removing from cart now, please wait");
      const res = await this.props.removePromoCode({
        cartId: this.state.cartId,
      });
      if (res.error) {
        message.error("Error while removing promo code to cart");
        this.setState({
          promoCodeError: res.error.message,
        });
      } else {
        message.success("Promo code removed");
        await this.componentDidMount();
      }
    } catch (error) {
      message.error("Error while removing promo code to cart");
    }
  }

  render() {
    const {
      name,
      email,
      cartId,
      cart,
      productListByCategory,
      showAddProductModal,
      orderType,
      currency_name,
    } = this.state;

    const {
      form: { getFieldDecorator },
      allProducts,
    } = this.props;
    const columns = getColumns({
      getFieldDecorator,
      productListByCategory,
      handleFetchProductsByCategory: this.handleFetchProductsByCategory,
      deleteProduct: this.deleteProduct,
      currency_name: currency_name,
      allProducts,
      items: cart?.items,
    });

    const total = this.getNewTotal();

    return (
      <>
        <Layout className="customer-subscription-page">
          <Row>
            <Col>
              <div
                className="pointer"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <div className="flex justify-start items-center cursor-pointer">
                  <div>
                    <Icon className="mr-4" type="left" />
                  </div>
                  <div className="text-14 pb-2">Back</div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="flex mb-20 mt-20">
                <div className="theme-color semibold text-30 mr-10">
                  Create Cart
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={[20]}>
            <Form onSubmit={this.handleSubmit}>
              <Col span={15}>
                <Card>
                  <div className="flex justify-between">
                    <p className="semibold text-18 text-black m0">
                      Add Products
                    </p>
                    <div className="flex justify-between items-center">
                      <p className="text-16 text-black m0">Currency: </p>
                      <Select
                        defaultValue={this.state.currency_name}
                        onChange={(value) =>
                          this.setState({
                            currency_name: value,
                          })
                        }
                      >
                        {currency.map((x) => (
                          <Option value={x.id}>{x.name}</Option>
                        ))}
                      </Select>
                    </div>
                    <p className="mb-12 text-12 m0">
                      {cart
                        ? moment(cart?.createdAt).format(
                            "MMMM DD, YYYY [at] HH:MM A"
                          )
                        : moment().format("MMMM DD, YYYY [at] HH:MM A")}
                    </p>
                  </div>

                  {cart?.items && (
                    <Table
                      className="subscription-item-table mt-10"
                      dataSource={cart?.items}
                      columns={columns}
                      pagination={false}
                    />
                  )}
                  <div className="flex justify-between items-center mt-12">
                    <div>
                      <Button
                        type="primary"
                        onClick={() =>
                          this.setState({
                            showAddProductModal: true,
                          })
                        }
                      >
                        Add Product
                      </Button>
                    </div>

                    <div>
                      <p className="semibold text-18 text-black m0">
                        New Total: {currency_name.toUpperCase()}
                        {total}
                      </p>
                    </div>
                    <div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ml-20"
                      >
                        {cartId ? "Update" : "Save"}
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            </Form>
            {this.state.cartId && (
              <Col span={9}>
                {/* {cart.user && (
                <Card>
                  <p className="semibold text-18 text-black mb-0">Customer</p>
                  <div className="mt-10 border-bottom">
                    <Link
                      to={`/customer/edit/${subscription?.user}`}
                      className="semibold pad-bottom capitalize cust-name text-link-imp"
                    >
                      {name}
                    </Link>
                  </div>
                  <div className="mt-10 border-bottom pb-10">
                    <p className="text-18 text-black semibold mb-0 ">
                      Contact Information
                    </p>
                    <strong className="semibold text-link text-14">
                      {email}
                    </strong>
                  </div>
                </Card>
              )} */}
                <Card>
                  <Radio.Group
                    defaultValue={0}
                    onChange={this.onOrderTypeChange}
                    className="w-100"
                  >
                    <Radio
                      style={radioStyle}
                      value={true}
                      checked={orderType === true}
                      className="order-type-block"
                    >
                      Subscribe{" "}
                      <Select
                        className="period-ant-select"
                        defaultValue={this.state.currentSubscriptionPeriodValue}
                        onChange={(value) =>
                          this.setState({
                            currentSubscriptionPeriodValue: value,
                          })
                        }
                      >
                        <Option value={30}>1 Month</Option>
                        <Option value={60}>2 Months</Option>
                        <Option value={90}>3 Months</Option>
                      </Select>
                      {total && (
                        <div>{Math.round(Number(total) * 0.85).toFixed(2)}</div>
                      )}
                    </Radio>
                    <Radio
                      style={radioStyle}
                      value={false}
                      checked={orderType === false}
                      className="order-type-block"
                    >
                      One Time Buy
                      {total && (
                        <span>{Math.round(Number(total)).toFixed(2)}</span>
                      )}
                    </Radio>
                  </Radio.Group>
                  <hr className="opacity-2" />
                  <div className="flex justify-end">
                    <Button type="primary" onClick={this.updateOrderType}>
                      Save
                    </Button>
                  </div>
                </Card>

                <Card className="mt-20imp">
                  <div className="flex justify-between">
                    <p className="semibold text-18 text-black m0">
                      Payment Details
                    </p>
                  </div>
                  {cart.promoCode ? (
                    <div className="mt-14">
                      <Tag
                        color="#373873"
                        closable
                        onClose={this.removePromoCode}
                      >
                        {cart.promoCode}
                      </Tag>
                      <p className="text-12 text-gray m0">
                        {cart.promoDescription}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div className="flex justify-between mt-14">
                        <Input
                          className="edit-address-input"
                          type="text"
                          defaultValue={this.state.promoCode}
                          placeholder="Promo code"
                          onChange={(e) =>
                            this.setState({
                              promoCode: e.target.value,
                            })
                          }
                        />
                        <Button
                          type="primary"
                          className="ml-5"
                          htmlType="button"
                          onClick={this.applyPromoCode}
                        >
                          Add Code
                        </Button>
                      </div>

                      {this.state.promoCodeError && (
                        <Tag
                          className="promo-error-message"
                          color="volcano"
                          closable
                          onClose={() =>
                            this.setState({
                              promoCodeError: "",
                              promoCode: "",
                            })
                          }
                        >
                          {this.state.promoCodeError}
                        </Tag>
                      )}
                    </>
                  )}
                </Card>

                <Card className="mt-20imp">
                  <div className="flex justify-between">
                    <p className="semibold text-18 text-black m0">Link</p>
                  </div>
                  <div className="flex justify-between mt-14">
                    <Input
                      value={`${API.WEB_URL}/cart?cartId=${this.state.cartId}`}
                      readOnly
                    />
                    <CopyToClipboard
                      text={`${API.WEB_URL}/cart?cartId=${this.state.cartId}`}
                      onCopy={() => message.success("Copied!")}
                    >
                      <Button
                        type="primary"
                        className="ml-5 copy-to-clipboard-button"
                      >
                        Copy link
                      </Button>
                    </CopyToClipboard>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </Layout>

        <Modal
          title="Add Product"
          visible={showAddProductModal}
          onOk={this.addProduct}
          onCancel={() =>
            this.setState({
              showAddProductModal: false,
            })
          }
        >
          <Select
            placeholder="Select"
            onChange={(value) =>
              this.setState({
                addSelectedProduct: value,
              })
            }
          >
            {this.getAllProductForAdd().map((x) => (
              <Option value={x.id} key={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        </Modal>
      </>
    );
  }
}

const CreateCartForm = Form.create({
  name: "createcart_form",
})(CreateCart);

function mapStateToProps(state) {
  const { Customer, Products } = state;
  return {
    allProducts: Products.products,
    activeCustomer: Customer.activeCustomer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomer: bindActionCreators(actions.getCustomer, dispatch),
    fetchCart: bindActionCreators(orderActions.getCart, dispatch),
    addToCart: bindActionCreators(orderActions.addToCart, dispatch),
    updateCart: bindActionCreators(orderActions.updateCart, dispatch),
    applyPromoCode: bindActionCreators(orderActions.applyPromoCode, dispatch),
    removePromoCode: bindActionCreators(orderActions.removePromoCode, dispatch),
    updateCartType: bindActionCreators(orderActions.updateCartType, dispatch),
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    updateAddress: bindActionCreators(customerActions.updateAddress, dispatch),
    fetchCustomerAddressList: bindActionCreators(
      customerActions.getCustomerAddressList,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCartForm);
