import React, { memo } from "react";
import { Form, Select } from "antd";
import Switch from "react-switch";

import "./ProductRestriction.scss";

const { Option } = Select;

const ALLOWED_COUNTRIES = [
  {
    code: "US",
    name: "USA",
  },
  {
    code: "AU",
    name: "Australia",
  },
  {
    code: "SG",
    name: "Singapore",
  },
  {
    code: "HK",
    name: "Hongkong",
  },
  {
    code: "NZ",
    name: "New Zealand",
  },
  {
    code: "ID",
    name: "Indonesia",
  },
  {
    code: "IN",
    name: "India",
  },
  {
    code: "FR",
    name: "France",
  },
  {
    code: "DE",
    name: "Germany",
  },
  {
    code: "UK",
    name: "United Kingdom",
  },
];

const ProductRestriction = (props) => {
  const handleChangeAllowed = (selectedItems) => {
    props.onChangeAllowedCountry(selectedItems);
  };

  const handleChangeRestricted = (selectedItems) => {
    props.onChangeRestrictedCountry(selectedItems);
  };

  return (
    <div className="mb-8">
      <Form.Item label="Availblility in stock" className="mb-8">
        <Switch onChange={props.onToggleisAvailable} checked={props.isAvailable} />
      </Form.Item>
      {props.onToggleHideFromResults && 
        <Form.Item label="Show on results page" className="mb-8">
          <Switch onChange={props.onToggleHideFromResults} checked={!props.hideFromResults} />
        </Form.Item>
      } 
      {props.category &&
        <Form.Item label="Show on products page" className="mb-8">
          <Switch onChange={props.onToggleisProducts} checked={props.isProducts} />
        </Form.Item>
      }
      {props.onToggleisRecommended &&
      <Form.Item label="Show In Recommendation Section (Product collection page)" className="mb-8">
        <Switch onChange={props.onToggleisRecommended} checked={props.isRecommended} />
      </Form.Item>
      }
      {props.onToggleisRecommendedOnSideCart &&
      <Form.Item label="Show In RECOMMENDED FOR YOU (Side Cart)" className="mb-8">
        <Switch onChange={props.onToggleisRecommendedOnSideCart} checked={props.isRecommendedOnSideCart} />
      </Form.Item>
      }
      {props.onToggleisGiftSets &&
      <Form.Item label="Show In /gifts (greatest hits)" className="mb-8">
        <Switch onChange={props.onToggleisGiftSets} checked={props.showInGiftsPage} />
      </Form.Item>
      }
      {props.onToggleisBestseller &&
      <Form.Item label="Show 'Bestseller' label" className="mb-8">
        <Switch onChange={props.onToggleisBestseller} checked={props.isBestseller} />
      </Form.Item>
      }
      <Form.Item label="Display in Geo Location" className="mb-8">
        <Select
          className="w-100"
          style={{ marginBottom: "16px" }}
          mode="multiple"
          value={props.allowedCountry}
          onChange={(items) => handleChangeAllowed(items)}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {ALLOWED_COUNTRIES.map((country, index) => {
            const disabled = props.restrictedCountry.includes(country.code);
            return (
              <Option value={country.code} key={index} disabled={disabled}>
                {country.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Hide in Geo Location" className="mb-8">
        <Select
          className="w-100"
          style={{ marginBottom: "16px" }}
          mode="multiple"
          value={props.restrictedCountry}
          onChange={(items) => handleChangeRestricted(items)}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {ALLOWED_COUNTRIES.map((country, index) => {
            const disabled = props.allowedCountry.includes(country.code);
            return (
              <Option value={country.code} key={index} disabled={disabled}>
                {country.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

export default memo(ProductRestriction);
