import AdminApi from "services/admin";

function handleError(err) {
  console.error(err);
}

export function setActiveAdmin(payload) {
  return {
    type: "SET_ACTIVE_ADMIN",
    payload
  }
};

export function listAdmin(result) {
  return {
    type: "LIST_ADMIN",
    payload: result
  };
}

export function updateAdminList(result) {
  return {
    type: "UPDATE_ADMIN_LIST",
    payload: result
  }
};

export function addAdmin(result) {
  return {
    type: "ADD_ADMIN",
    payload: result
  };
}

export const getAdmin = id => async dispatch => {

  try {
  
    let res = await AdminApi.getAdminById(id);
    dispatch(setActiveAdmin(res));
  
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const getAdminList = () => async dispatch => {
   try {
    let res = await AdminApi.getAdminList();
    dispatch(listAdmin(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getSearchedAdmin = (name,pageSize) => async dispatch => {
  try {
    let res = await AdminApi.getSearchedAdmin(name,pageSize);
    dispatch(listAdmin(res.result.result));
    return res.result;
  } catch (err) {
    handleError(err);
    return err;
  }
}

export const createAdmin = (payload) => async dispatch => {
  try {
    let res = await AdminApi.createAdmin(payload);
    dispatch(addAdmin(res.result));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateAdmin = (id, payload) => async dispatch => {
  try {
    let res = await AdminApi.updateAdminById(id, payload);
    if(res) {
    
      dispatch(updateAdminList(res));
      dispatch(setActiveAdmin(res));
    }
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const deleteAdmin = id => async dispatch => {
  try {
    let res = await AdminApi.deleteAdminById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
}
