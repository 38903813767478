import React, { Component } from "react";
import "../productsPage/listProducts/listProducts.scss";
import {
  Table,
  Input,
  Dropdown,
  Button,
  Icon,
  Menu,
  Modal,
  InputNumber,
  Checkbox,
  Badge,
} from "antd";
import "./assessmentAttemptPage.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSafely } from "utils/helpers";
import { getAssessmentAttemptList } from "actions/assessment";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { debounce } from "utils/helpers";
import { assessmentStatusMapping } from "../../utils/constants";
import { CSVLink } from "react-csv";
import { getPreSignedURL } from "services/common";
const { Search } = Input;

const CUSTOMER_ADVANCED_SEARCH_OPTIONS = [
  // {
  //   key:"user.firstName",
  //   label:"First Name"
  // },
  {
    key: "user.id",
    label: "User ID",
  },
  // {
  //   key: "user._id",
  //   label: "User ID",
  // },
  {
    key: "_id",
    label: "Submission ID",
  },
  {
    key: "user.email",
    label: "Email",
  },
  // {
  //   key: "user.firstName",
  //   label: "first name",
  // },
  // {
  //   key: "lastName",
  //   label: "last name",
  // },
  // {
  //   key: "user.email",
  //   label: "email",
  // },
];
class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.allColumns = [
      "all",
      "name",
      "email",
      "id",
      "submissionId",
      "submissionDate",
      "submissionTime",
      "userId",
      "QuestionsCompleted",
      "WeatherCompleted",
      "selfieCompleted",
      "status",
    ];
    this.state = {
      searchKey: "",
      sortOrder: 1,
      activeAdvSearch: CUSTOMER_ADVANCED_SEARCH_OPTIONS[2],
      activeAssessmentActiveFilter: [],
      activeAssessmentStatusFilter: [],
      shownColumns: [
        "all",
        "name",
        "email",
        "id",
        "submissionId",
        "submissionDate",
        "submissionTime",
        "userId",
        "QuestionsCompleted",
        "WeatherCompleted",
        "selfieCompleted",
        "status",
      ],
      checked: true,
      showExportModal: false,
      exportModalLoading: false,
      numberOfRows: 20,
      pageNo: 0
    };
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    this.props.getAssessmentAttemptList("",
      this.props && this.props.assessmentAttemptPageDetails &&
      this.props.assessmentAttemptPageDetails.currentPage,
      undefined,
      this.props.assessmentAttemptPageDetails && this.props.assessmentAttemptPageDetails.activeAssessmentActiveFilter,
      this.props.assessmentAttemptPageDetails && this.props.assessmentAttemptPageDetails.activeAssessmentStatusFilter,

    );
  }
  onChangeInput = (value) => {
    this.setState({ numberOfRows: value });
  };
  getAge = (questionsAnswered) => {
    let age = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd3d23a6a24011295a1ae35");
      if (index !== -1) {
        age = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return age;
  }
  getGender = (questionsAnswered) => {
    let gender = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd3ca802e035d1060c98c36");
      if (index !== -1) {
        gender = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return gender;
  }
  getMakeup = (questionsAnswered) => {
    let makeup = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd664adcc1e553d5a377dc3");
      if (index !== -1) {
        makeup = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return makeup;
  }

  getWater = (questionsAnswered) => {
    let water = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd6523fcc1e553d5a377c9e");
      if (index !== -1) {
        water = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return water;
  }

  getStress = (questionsAnswered) => {
    let stress = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd635b3693d032e4d156bec");
      if (index !== -1) {
        stress = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return stress;
  }
  getLifeStyle = (questionsAnswered) => {
    let data = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd658ddcc1e553d5a377d2e");
      if (index !== -1) {
        questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer.length > 0 &&
          questionsAnswered && questionsAnswered[index].answer.map((x, i) => {
            data = data + x.text + ",";
          })
      }
    }

    if(data&&data.length>0){
      data=data.slice(0,-1)
    }
    return data;
  }
  getSmoke = (questionsAnswered) => {
    let smoke = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd66642cc1e553d5a377dd8");
      if (index !== -1) {
        smoke = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return smoke;
  }

  getSleepHours = (questionsAnswered) => {
    let data = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd65088cc1e553d5a377c82");
      if (index !== -1) {
        data = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return data;
  }

  getSkinTone = (questionsAnswered) => {
    let data = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd3e8ba6a24011295a1ae70");
      if (index !== -1) {
        data = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return data;
  }

  getEverAllergy = (questionsAnswered) => {
    let data = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd3ed206a24011295a1ae7f");
      if (index !== -1) {
        data = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[0].text &&
          questionsAnswered && questionsAnswered[index].answer[0].text
      }
    }
    return data;
  }

  getSkinConcern = (questionsAnswered, i) => {
    let data = "";
    if (questionsAnswered && questionsAnswered.length > 0) {
      const index = questionsAnswered && questionsAnswered.findIndex(x => x.question.id == "5dd3e3e06a24011295a1ae56");
      if (index !== -1) {
        data = questionsAnswered && questionsAnswered[index].answer &&
          questionsAnswered && questionsAnswered[index].answer[i] &&
          questionsAnswered && questionsAnswered[index].answer[i].text &&
          questionsAnswered && questionsAnswered[index].answer[i].text
      }
    }
    return data;
  }


  getProduct = (recommendedProducts, index) => {
    let product = "";
    if (recommendedProducts && recommendedProducts.products && recommendedProducts.products.length > 0) {
      product = recommendedProducts.products && recommendedProducts.products[index] &&
        recommendedProducts.products && recommendedProducts.products[index].product &&
        recommendedProducts.products && recommendedProducts.products[index].product.name &&
        recommendedProducts.products && recommendedProducts.products[index].product.name

    }
    return product;
  }

  getLocation = (weatherData) => {
    let location = "";
    if (weatherData && weatherData.data && weatherData.data.location) {
      location = weatherData.data && weatherData.data.location;
    }
    return location;
  }

  //
  handleActiveAssessmentFilter = (filterKey, value) => {
    const { pageNo } = this.state;
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeAssessmentActiveFilter.indexOf(value) > -1) {
          newFilterList = state.activeAssessmentActiveFilter.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeAssessmentActiveFilter, value];
        }
        return {
          activeAssessmentActiveFilter: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeAssessmentActiveFilter
          .map((value, index) => {
            if (index === 0) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeAssessmentStatusFilter
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeAssessmentActiveFilter.length === 0
            ) {
              return `status=${value}`;
            } else {
              return `&status=${value}`;
            }
          })
          .join("");
        this.props.getAssessmentAttemptList(
          `${filterQueryStatus}${filterQueryFulfillment}${pageNo > 0 ? '&' : ''}`,
          pageNo,
          undefined,
          this.state.activeAssessmentActiveFilter,
          this.state.activeAssessmentStatusFilter
        );
      }
    );
  };
  getSelectedQueryStringFilter = (src = "") => {
    let queryString = "";
    const { activeFulfillMentFilters, activeStatusFilters } = this.state;
    if ((src == "Clear All Filter") || (src == "Initial")) {
      queryString = `status=2`;
    }
    else {
      if (activeStatusFilters && activeStatusFilters.length > 0) {
        activeStatusFilters.forEach((x, index) => {
          if (index === 0) {
            queryString = `status=${x}`;
          } else {
            queryString += `&status=${x}`;
          }
        });
      }
      if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
        activeFulfillMentFilters.forEach((x, index) => {
          if (index === 0) {
            if (queryString && queryString.length > 0) {
              queryString += `&fulfillmentStatus=${x}`;
            } else {
              queryString = `fulfillmentStatus=${x}`;
            }
          } else {
            queryString += `&fulfillmentStatus=${x}`;
          }
        });
      }
      if (queryString && queryString.length > 0) {
        queryString = queryString + "&";
      }
    }

    return queryString;
  };

  handleActiveAssessmentStatusFilter = (filterKey, value) => {
    const {
      pageNo
    } = this.state;
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeAssessmentStatusFilter.indexOf(value) > -1) {
          newFilterList = state.activeAssessmentStatusFilter.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeAssessmentStatusFilter, value];
        }
        return {
          activeAssessmentStatusFilter: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeAssessmentActiveFilter
          .map((value, index) => {
            if (index === 0) {
              return `areQuestionsCompleted=${value}`;
            } else {
              return `&areQuestionsCompleted=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeAssessmentStatusFilter
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeAssessmentStatusFilter.length === 0
            ) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        this.props.getAssessmentAttemptList(
          `${filterQueryStatus}${filterQueryFulfillment}${pageNo > 0 ? '&' : ''}`,
          pageNo,
          undefined,
          this.state.activeAssessmentActiveFilter,
          this.state.activeAssessmentStatusFilter
        );
      }
    );
  };
  handleAdvSearchChange = (value) => {
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.props.getAssessmentAttemptList(
            `${this.state.activeAdvSearch.key}=${value}${this.state.activeAdvSearch.key == "user.email" ? '&' + this.getAppliedFiltersQueryString() : ''}`
          );
        } else {
          this.props.getAssessmentAttemptList(this.getAppliedFiltersQueryString());
        }
      }
    );
  }, 300);
  handleFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.fetchListProducts(value);
      }
    );
  };
  // handleSort = value => {
  //   debugger
  //   this.setState(
  //     prevState => {
  //       return {
  //         sortOrder: prevState.sortOrder === "1" ? "-1" : "1"
  //       };
  //     },
  //     () => this.props.getAssessmentAttemptList(`orderBy=createdAt:${this.state.sortOrder}`)
  //   );
  // };
  handleTagSearch = (value) => {
    this.props.fetchListProducts(undefined, undefined, value);
  };
  onColumnFilterChange = (e, item) => {
    if (item === "all") {
      if (e.target.checked) {
        this.setState({
          shownColumns: [...this.allColumns],
        });
      } else {
        this.setState({
          shownColumns: [],
        });
      }
      return;
    } else {
      this.setState((prevState, props) => {
        return {
          shownColumns: prevState.shownColumns.filter((col) => col !== "all"),
        };
      });
    }
    if (e.target.checked) {
      this.setState((prevState) => {
        let columnsArr = [...prevState.shownColumns, item];
        return {
          shownColumns: columnsArr,
        };
      });
    } else {
      this.setState((prevState) => {
        let columnsArr = prevState.shownColumns.filter((value) => {
          return value !== item;
        });
        return {
          shownColumns: columnsArr,
        };
      });
    }

    this.setState({
      checked: e.target.checked,
    });
  };
  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  isActiveAssessmentActiveFilterApplied = () => {
    const {
      activeAssessmentActiveFilter,
    } = this.state;
    if ((activeAssessmentActiveFilter && activeAssessmentActiveFilter.length > 0) || (this.props.assessmentAttemptPageDetails && this.props.assessmentAttemptPageDetails.activeAssessmentActiveFilter && this.props.assessmentAttemptPageDetails.activeAssessmentActiveFilter.length > 0)) {
      return true;
    }
    else {
      return false;
    }
  }

  getActiveAssessmentActiveFilterCount = () => {
    const {
      activeAssessmentActiveFilter,
    } = this.state;
    if ((activeAssessmentActiveFilter && activeAssessmentActiveFilter.length > 0) || (this.props.assessmentAttemptPageDetails && this.props.assessmentAttemptPageDetails.activeAssessmentActiveFilter && this.props.assessmentAttemptPageDetails.activeAssessmentActiveFilter.length > 0)) {
      if (activeAssessmentActiveFilter && activeAssessmentActiveFilter.length > 0) {
        return activeAssessmentActiveFilter.length;
      }
      else {
        return this.props.assessmentAttemptPageDetails.activeAssessmentActiveFilter.length;
      }
    }
    else {
      return 0;
    }
  }

  isActiveAssessmentStatusFilterApplied = () => {
    const {
      activeAssessmentStatusFilter,
    } = this.state;
    if ((activeAssessmentStatusFilter && activeAssessmentStatusFilter.length > 0) || (this.props.assessmentAttemptPageDetails && this.props.assessmentAttemptPageDetails.activeAssessmentStatusFilter && this.props.assessmentAttemptPageDetails.activeAssessmentStatusFilter.length > 0)) {
      return true;
    }
    else {
      return false;
    }
  }

  getActiveAssessmentStatusFilterCount = () => {
    const {
      activeAssessmentStatusFilter,
    } = this.state;
    if ((activeAssessmentStatusFilter && activeAssessmentStatusFilter.length > 0) || (this.props.assessmentAttemptPageDetails && this.props.assessmentAttemptPageDetails.activeAssessmentStatusFilter && this.props.assessmentAttemptPageDetails.activeAssessmentStatusFilter.length > 0)) {
      if (activeAssessmentStatusFilter && activeAssessmentStatusFilter.length > 0) {
        return activeAssessmentStatusFilter.length;
      }
      else {
        return this.props.assessmentAttemptPageDetails.activeAssessmentStatusFilter.length;
      }
    }
    else {
      return 0;
    }
  }

  isClearAllFilterDisabled = () => {
    if (!this.isActiveAssessmentActiveFilterApplied() && !this.isActiveAssessmentStatusFilterApplied()) {
      return true;
    }
    else {
      return false;
    }
  }

  getAppliedFiltersQueryString = () => {
    const {
      activeAssessmentActiveFilter,
      activeAssessmentStatusFilter
    } = this.state;
    let string = "";
    if (activeAssessmentActiveFilter && activeAssessmentActiveFilter.length > 0) {
      activeAssessmentActiveFilter.map((x, index) => {
        if (index == 0) {
          string = string + "areQuestionsCompleted=" + x;
        }
        else {
          string = string + "&areQuestionsCompleted=" + x;
        }

      })
    }
    if (activeAssessmentStatusFilter && activeAssessmentStatusFilter.length > 0) {
      activeAssessmentStatusFilter.map((x, index) => {
        if (string && string.length > 0 && index == 0) {
          string = string + "&status=" + x;
        }
        else {
          if (index == 0) {
            string = string + "status=" + x;
          }
          else {
            string = string + "&status=" + x;
          }

        }

      })
    }
    return string;
  }

  render() {

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Assessment Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Submission ID",
        dataIndex: "submissionId",
        key: "submissionId",
        render: (submissionId) => (
          <div style={{ color: "#363864" }}>{submissionId}</div>
        ),
      },
      {
        title: "Submission Date",
        dataIndex: "submissionDate",
        key: "submissionDate",
      },
      {
        title: "Submission Time",
        dataIndex: "submissionTime",
        key: "submissionTime",
      },
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        render: (userId) => (
          <div
            style={{ color: "#373873" }}
            onClick={(e) => {
              e.stopPropagation();
              const win = window.open(`/customer/edit/${userId}`, "_blank");
              win.focus();

              // this.props.history.push({
              //   pathname: `/customer/edit/${userId}`,
              //   state: {
              //     route: "/assessment-attempt",
              //   },
              // });
            }}
          >
            {userId}
          </div>
        ),
      },
      // {
      //   title: "Date",
      //   dataIndex: "date",
      //   key: "date",
      // },
      {
        title: "Questions Completed",
        dataIndex: "QuestionsCompleted",
        key: "QuestionsCompleted",
      },
      {
        title: "Weather Completed By User",
        dataIndex: "WeatherCompleted",
        key: "WeatherCompleted",
      },
      {
        title: "Selfie Completed",
        dataIndex: "selfieCompleted",
        key: "selfieCompleted",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      // {
      //   title: "Selfie URL",
      //   dataIndex: "selfieURL",
      //   key: "selfieURL"
      // }
    ].filter((column) =>
      this.state.shownColumns.find(
        (hiddenColKey) => hiddenColKey === column.key
      )
    );
    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });

    const ExportColumns = [
      {
        label: "Skin assessment ID",
        key: "id"
      },
      {
        label: "Age",
        key: "age"
      },
      {
        label: "Created At",
        key: "date"
      },
      {
        label: "Email",
        key: "email"
      },
      {
        label: "Gender",
        key: "gender"
      },
      {
        label: "Allergy (Y/N)",
        key: "allergy"
      },
      {
        label: "Allergen (product they are allergic to)",
        key: "allergicProduct"
      },
      {
        label: "Location",
        key: "location"
      },
      {
        label: "Uses makeup (Y/N)",
        key: "makeup"
      },
      {
        label: "Name",
        key: "name"
      },
      {
        label: "Product sensitivities 1 (pregnancy safe / vegan)",
        key: "productSensitiviies1"
      },
      {
        label: "Product sensitivities 2 (pregnancy safe / vegan)",
        key: "productSensitiviies2"
      },
      {
        label: "Recommended product 1",
        key: "recommendedproduct1"
      },
      {
        label: "Recommended product 2",
        key: "recommendedproduct2"
      },
      {
        label: "Recommended product 3",
        key: "recommendedproduct3"
      },
      {
        label: "Recommended product 4",
        key: "recommendedproduct4"
      },
      {
        label: "Selfie url",
        key: "selfiUrl"
      },
      {
        label: "Skin concern 1",
        key: "skinConcern1"
      },
      {
        label: "Skin concern 2",
        key: "skinConcern2"
      },
      {
        label: "Skin description",
        key: "skinDescription"
      },
      {
        label: "Skin tone",
        key: "skinTone"
      },
      {
        label: "Hours of sleep",
        key: "sleepHours"
      },
      {
        label: "Smokes (Y/N)",
        key: "smoke"
      },
      {
        label: "Lifestyle",
        key: "lifeStyle"
      },
      {
        label: "Stress",
        key: "stress"
      },
      {
        label: "Water",
        key: "water"
      },
      {
        label: "Submission complete",
        key: "status"
      },
    ]
    const advSearch = (
      <Menu
        onClick={(e) => this.handleAdvSearchChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {CUSTOMER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );


    const mappedAssessmentAttemptList =
      this.props.assessmentAttemptList.length > 0
        ? this.props.assessmentAttemptList.map((assessment) => {
          return {
            name:
              `${assessment && assessment.user && assessment.user.firstName
                ? assessment.user.firstName + " "
                : ""
              }` +
              `${assessment && assessment.user && assessment.user.lastName
                ? assessment.user.lastName
                : ""
              }`,
            email: `${assessment &&
              assessment.user &&
              assessment.user.email &&
              assessment.user.email
              ? assessment.user.email
              : "-"
              }`,
            id: `${assessment.assessment}`,
            userId:
              assessment && assessment.user && assessment.user.id
                ? assessment.user.id
                : "",
            date: moment(assessment.createdAt).format("DD/MM/YYYY"),
            submissionTime: `${
              assessment.createdAt
                ? momentTimezone(assessment.createdAt)
                    .tz('Asia/Singapore')
                    .format("hh:mm a")
                : "-"
            }`,
            submissionDate: moment(assessment.updatedAt).format("DD/MM/YYYY"),
            status: assessment && assessmentStatusMapping[assessment.status],
            email:
              assessment && assessment.user && assessment.user.email
                ? assessment.user.email
                : "",
            submissionId: assessment.id ? assessment.id : "-",
            QuestionsCompleted: assessment.areQuestionsCompleted
              ? "Yes"
              : "No",
            WeatherCompleted: assessment.isWeatherCompleted ? "Yes" : "No",
            selfieCompleted: assessment.isSelfieCompleted ? "Yes" : "No",
            assessmentDetails: assessment && assessment.assessmentDetails && assessment.assessmentDetails,
            age: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getAge(assessment.assessmentDetails.questionsAnswered),
            gender: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getGender(assessment.assessmentDetails.questionsAnswered),
            location: assessment && assessment.assessmentDetails && assessment.assessmentDetails.weatherData &&
              this.getLocation(assessment.assessmentDetails.weatherData),
            makeup: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getMakeup(assessment.assessmentDetails.questionsAnswered),
            recommendedproduct1: assessment && assessment.assessmentDetails && assessment.assessmentDetails.recommendedProducts &&
              this.getProduct(assessment.assessmentDetails.recommendedProducts, 0),
            recommendedproduct2: assessment && assessment.assessmentDetails && assessment.assessmentDetails.recommendedProducts &&
              this.getProduct(assessment.assessmentDetails.recommendedProducts, 1),
            recommendedproduct3: assessment && assessment.assessmentDetails && assessment.assessmentDetails.recommendedProducts &&
              this.getProduct(assessment.assessmentDetails.recommendedProducts, 2),
            recommendedproduct4: assessment && assessment.assessmentDetails && assessment.assessmentDetails.recommendedProducts &&
              this.getProduct(assessment.assessmentDetails.recommendedProducts, 3),
            selfiUrl: assessment.isSelfieCompleted==true ? assessment.assessmentDetails&&assessment.assessmentDetails.selfieURL&&assessment.assessmentDetails.selfieURL : "",
            water: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getWater(assessment.assessmentDetails.questionsAnswered),
            stress: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getStress(assessment.assessmentDetails.questionsAnswered),
            lifeStyle: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getLifeStyle(assessment.assessmentDetails.questionsAnswered),
            smoke: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getSmoke(assessment.assessmentDetails.questionsAnswered),
            sleepHours: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getSleepHours(assessment.assessmentDetails.questionsAnswered),
            skinTone: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getSkinTone(assessment.assessmentDetails.questionsAnswered),
            allergy: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getEverAllergy(assessment.assessmentDetails.questionsAnswered),
            allergicProduct: "",
            productSensitiviies1: "",
            productSensitiviies2: "",
            skinConcern1: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getSkinConcern(assessment.assessmentDetails.questionsAnswered, 0),
            skinConcern2: assessment && assessment.assessmentDetails && assessment.assessmentDetails.questionsAnswered &&
              this.getSkinConcern(assessment.assessmentDetails.questionsAnswered, 1),
            skinDescription: ""
          };
        })
        : [];
    const csvData = mappedAssessmentAttemptList.map((item) => {
      return { ...item };
    });
    const menu = (
      <Menu
        onClick={(e) =>
          this.handleActiveAssessmentFilter("areQuestionsCompleted", e.key)
        }
        selectedKeys={this.state.activeAssessmentActiveFilter}
        multiple
        selectable
      >
        {/* <Menu.Item key={null}>All</Menu.Item> */}
        <Menu.Item key="true">Yes</Menu.Item>
        <Menu.Item key="false">No</Menu.Item>
      </Menu>
    );
    const statusMenu = (
      <Menu
        onClick={(e) =>
          this.handleActiveAssessmentStatusFilter("status", e.key)
        }
        selectedKeys={this.state.activeAssessmentStatusFilter}
        multiple
        selectable
      >
        {/* <Menu.Item key={null}>All</Menu.Item> */}
        <Menu.Item key="completed">Completed</Menu.Item>
        <Menu.Item key="pending">Pending</Menu.Item>
        <Menu.Item key="cancelled">Cancelled</Menu.Item>
      </Menu>
    );

    const columnFilterMenu = (
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        className="flex flex-col column-checkbox-wrapper"
      >
        {this.allColumns.map((item, index) => {
          return (
            <Checkbox
              checked={this.state.shownColumns.find((value) => {
                return value === item;
              })}
              onChange={(e) => this.onColumnFilterChange(e, item)}
            >
              {item
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Checkbox>
          );
        })}
      </div>
    );

    return (
      <div className="assessment-list-page" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div className="theme-color semibold text-30 mr-14">
            Skin Assessment Panel
          </div>
          {localStorage.getItem("userType") === "admin" && (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                this.setState({ showExportModal: true });
              }}
            >
              <Icon type="export" className="mr-4" />
              <div>Export</div>
            </div>
          )}
        </div>

        <div className="flex items-center"></div>

        <div>
          <div className="table-controls flex">
            <Search
              placeholder={`Search by ${this.state.activeAdvSearch.label}`}
              onSearch={(value) => this.handleSearch(value)}
              onKeyUp={(e) => this.handleSearch(e.target.value)}
            />
            <Dropdown className="filter-search adv-search" overlay={advSearch}>
              <Button className="dropdown-btn">
                Advanced Search <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown
              onVisibleChange={this.handleVisibleChange}
              visible={this.state.visible}
              overlay={columnFilterMenu}
            >
              <Button
                onClick={(e) => e.preventDefault()}
                className="dropdown-btn"
                style={{ flex: 1, marginRight: "5px", marginLeft: "10px" }}
              >
                Column Filters <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown overlay={menu}>
              <Button
                className={`dropdown-btn ${
                  this.isActiveAssessmentActiveFilterApplied() &&
                  "filter-selected"
                }`}
                style={{ marginRight: "10px", marginLeft: "10px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getActiveAssessmentActiveFilterCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Completed Assessment <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown overlay={statusMenu}>
              <Button
                className={`dropdown-btn ${
                  this.isActiveAssessmentStatusFilterApplied() &&
                  "filter-selected"
                }`}
                style={{ marginRight: "10px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getActiveAssessmentStatusFilterCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Status <Icon type="down" />
              </Button>
            </Dropdown>
            {/* <Button
              style={{ display: "flex", alignItems: "center" }}
              onClick={this.handleSort}
            >
              <span className="mr-4">Sort</span>
              <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
            </Button> */}
            <Button
              onClick={() => {
                this.setState({
                  activeAssessmentActiveFilter: [],
                  activeAssessmentStatusFilter: [],
                });
                this.props.getAssessmentAttemptList("", 0);
              }}
              disabled={this.isClearAllFilterDisabled()}
              className="dropdown-btn"
              style={{ width: "140px", marginRight: "5px", marginLeft: "5px" }}
            >
              Clear All Filters
            </Button>
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedAssessmentAttemptList}
              bordered
              size="small"
              scroll={{ x: 1500 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    const win = window.open(
                      `/assessment-detail/${record.submissionId}`,
                      "_blank"
                    );
                    win.focus();

                    // this.props.history.push(
                    //   `/assessment-detail/${record.submissionId}`,
                    //   {
                    //     state: record.submissionId,
                    //   }
                    // );
                  },
                };
              }}
              // pagination={{
              //   current: getSafely(
              //     () => this.props.assessmentAttemptPageDetails.currentPage,
              //     0
              //   ),
              //   total: this.props.assessmentAttemptPageDetails
              //     ? this.props.assessmentAttemptPageDetails.totalCount /
              //       this.props.assessmentAttemptPageDetails.count
              //     : 0,
              //   pageSize: getSafely(
              //     () => this.props.assessmentAttemptPageDetails.count,
              //     0
              //   ),
              //   onChange: (pageNo) => {
              //     this.props.getAssessmentAttemptList("", pageNo);
              //   },
              // }}
              pagination={{
                current: getSafely(
                  () =>
                    this.props.assessmentAttemptPageDetails.currentPage + 1 ||
                    0,
                  0
                ),
                total:
                  (this.props.assessmentAttemptPageDetails &&
                    this.props.assessmentAttemptPageDetails.totalCount) ||
                  0,
                pageSize: 20,
                onChange: (pageNo) => {
                  this.props.getAssessmentAttemptList(
                    `${this.getAppliedFiltersQueryString()}${
                      this.getAppliedFiltersQueryString() &&
                      this.getAppliedFiltersQueryString().length > 0
                        ? "&"
                        : ""
                    }`,
                    pageNo - 1
                  );
                  this.setState({ pageNo: pageNo - 1 });
                },
              }}
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={() => {
            this.setState({ showExportModal: false });
          }}
          onOk={async () => {
            const { pageNo } = this.state;
            this.setState({ exportModalLoading: true });
            await this.props.getAssessmentAttemptList(
              `&src=Export&${this.getAppliedFiltersQueryString()}`,
              pageNo,
              this.state.numberOfRows
            );
            this.setState({
              exportModalLoading: false,
              showExportModal: false,
            });
            this.csvRef.current && this.csvRef.current.link.click();
          }}
        >
          <InputNumber
            min={1}
            defaultValue={this.state.numberOfRows}
            onChange={this.onChangeInput}
          />
        </Modal>
        <CSVLink
          data={csvData}
          // headers={headers}
          headers={ExportColumns}
          ref={this.csvRef}
          style={{ display: "none" }}
          filename="skin-assessments.csv"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Assessment } = state;
  return {
    assessmentAttemptList: Assessment.assessmentAttemptList,
    assessmentAttemptPageDetails: Assessment.assessmentAttemptPageDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAssessmentAttemptList: bindActionCreators(
      getAssessmentAttemptList,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProducts);
