import React, { Component } from "react";
import "./addAdmin.scss";
import { Icon, Select, Input, Button, Form, message, Checkbox, Typography } from "antd";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/admin";
import { orderTypeFilter } from "utils/constants";
const { Option } = Select;
const { Text } = Typography;

class AddAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "create",
      loading: false,
      selectedDiscountType: "",
      appliesToEntireOrder: true,
      appliesToAllUsers: true,
      setEndDate: false,
      activeOrderType: [],
    };
  }

  isEditMode() {
    return this.state.mode === "edit";
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  handleOrderTypeFilter = (value) => {
    //console.log(value, value);
    this.setState({ activeOrderType :value });
    // this.setState(
    //   (state) => {
    //     let newFilterList = [];
    //     //const indexRow = state.activeProductList.indexOf("ROW");
    //     if (state.activeOrderType.indexOf(value) > -1) {
    //       newFilterList = state.activeOrderType.filter(
    //         (elem) => elem !== value
    //       );
    //     } else {
    //       newFilterList = [...state.activeOrderType, value];
    //     }
    //     console.log("newFilterList", newFilterList);
    //     return {
    //       activeOrderType: newFilterList,
          
    //     };
    //   }
    // );
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (!Object.values(values.allowedCountry).some((x) => x === true)) {
        message.error("Please select at least one Allowed Country");
        return;
      }
      values.allowedCountry.AU = true;
      this.setState({
        loading: true,
      });
      if (this.isEditMode()) {
        this.props.updateAdmin(this.state.activeAdminId, values).then((res) => {
          this.setState({
            loading: false,
          });
          if (!res) {
            message.error("Something went wrong!");
            return;
          }
          message.success("Admin Updated successfully");
          this.props.history.push("/admin");
        });
      } else {
        this.props.createAdmin(values).then((res) => {
          this.setState({
            loading: false,
          });
          if (!res) {
            message.error("Something went wrong!");
            return;
          }
          message.success("Admin successfully created");
          this.props.history.push("/admin");
        });
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;

    if (params.id !== undefined) {
      this.props.getAdmin(params.id).then((res) => {
        if (res) {
          this.setState({
            mode: "edit",
            activeAdminId: res.id,
            activeOrderType: res.allowedOrderType,
            // appliesToAllUsers: res.appliesTo.allUsers,
            // appliesToEntireOrder: res.appliesTo.entireOrder,
            // selectedDiscountType: res.discountType
          });
        }
      });
    } else {
      this.props.setActiveAdmin(null);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { activeAdmin } = this.props;
    return (
      <div
        className="admin-page"
        style={{ marginLeft: "200px", paddingBottom: "30px" }}
      >
        <Form>
          <div className="flex w-full items-center justify-between">
            <Link to="/admin">
              <div className="flex justify-start items-center cursor-pointer">
                <div>
                  <Icon className="mr-4" type="left" />
                </div>
                <div className="text-14 pb-2">Back</div>
              </div>
            </Link>
            {/* save btn main */}
            <Button
              type="primary"
              loading={this.state.loading}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
          <h4
            style={{ color: "#373873", fontSize: "30px", marginBottom: "37px" }}
            className=""
          >
            {this.isEditMode() ? "Update" : "Create"} Admin
          </h4>
          {/* Code */}
          <div className="discount-title-card">
            <div
              style={{ paddingBottom: "16px" }}
              className="flex justify-between items-center"
            >
              <h4 className="text-base" style={{ color: "#262626" }}>
                {this.isEditMode() ? "Update" : "Create"} Admin Form
              </h4>
              {/* <h4 style={{ color: "#363874", fontSize: "14px" }}>
              Generate Code
            </h4> */}
            </div>
            <Form.Item>
              {getFieldDecorator(`firstName`, {
                initialValue: activeAdmin ? activeAdmin.firstName : undefined,
                rules: [{ required: true, message: "Required" }],
              })(<Input placeholder="First Name" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(`lastName`, {
                initialValue: activeAdmin ? activeAdmin.lastName : undefined,
                rules: [{ required: true, message: "Required" }],
              })(<Input placeholder="Last Name" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(`email`, {
                initialValue: activeAdmin ? activeAdmin.email : undefined,
                rules: [{ required: true, message: "Required" }],
              })(<Input placeholder="Email" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(`role`, {
                initialValue: activeAdmin ? activeAdmin.role : undefined,
                rules: [{ required: true, message: "Required" }],
              })(
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Admin type"
                >
                  <Option value={"admin"}>Admin</Option>
                  <Option value={"basic"}>Basic</Option>
                  <Option value={"packer"}>Packer</Option>
                </Select>
              )}
            </Form.Item>
            {this.state.mode === "create" && (
              <>
                <Form.Item label="Password" hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
                <Form.Item label="Confirm Password" hasFeedback>
                  {getFieldDecorator("confirm", {
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>
              </>
            )}
            <div>
              <Form.Item label="Allowed Countries:">
                {getFieldDecorator("allowedCountry.US", {
                  initialValue: activeAdmin?.allowedCountry?.US,
                  valuePropName: "checked",
                })(<Checkbox>United States (US)</Checkbox>)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("allowedCountry.AU", {
                  initialValue: activeAdmin?.allowedCountry?.AU,
                  valuePropName: "checked",
                })(<Checkbox>Australia (AU)</Checkbox>)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("allowedCountry.SG", {
                  initialValue: activeAdmin?.allowedCountry?.SG,
                  valuePropName: "checked",
                })(<Checkbox>Singapore (SG)</Checkbox>)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("allowedCountry.ROW", {
                  initialValue: activeAdmin?.allowedCountry?.ROW,
                  valuePropName: "checked",
                })(<Checkbox>ROW (Rest of the world)</Checkbox>)}
              </Form.Item>
              
              <Form.Item>
                {getFieldDecorator("allowedOrderType", {
                  initialValue: activeAdmin?.allowedInfluencer,
                  valuePropName: "checked",
                })(
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => this.handleOrderTypeFilter(e)}
                    placeholder="Allow order type"
                    // tokenSeparators={[","]}
                    value={this.state.activeOrderType}
                    mode="multiple"
                    selectable
                  >
                    {console.log("ssssss", this.state.activeOrderType)}
                    {orderTypeFilter.map((type) => {
                      return <Option value={type.key}>{type.label}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const AddAdminForm = Form.create({ name: "add_ingredients_form" })(AddAdmin);

function mapStateToProps(state) {
  const { Products, Customer, Currency, Admin } = state;
  return {
    allProducts: Products.products,
    customerList: Customer.customerList,
    currencyList: Currency.currency,
    activeAdmin: Admin.activeAdmin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createAdmin: bindActionCreators(actions.createAdmin, dispatch),
    updateAdmin: bindActionCreators(actions.updateAdmin, dispatch),
    getAdmin: bindActionCreators(actions.getAdmin, dispatch),
    setActiveAdmin: bindActionCreators(actions.setActiveAdmin, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAdminForm);
