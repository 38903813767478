import React, { Component, useMemo } from "react";
import {
  Upload,
  Icon,
  message,
  Layout,
  Row,
  Col,
  Card,
  notification,
} from "antd";
import API from "../../../services/config/apiUrl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const { Dragger } = Upload;

const _authorizationHeaders = () => {
  return {
    Authorization: window.localStorage.getItem("authToken"),
    //"Content-Type": "application/json",
  };
};

class ShortenUrl extends Component {
    constructor(props) {
         super(props);
    }

    render() {

    const props = {
      name: "file",
      multiple: false,
      accept:'.csv',
      action: API.API_URL + "orders/admin/generate-shortUrl",
      headers:_authorizationHeaders(),
      onChange(info) {
        console.log( "INFO",info)
        const { status } = info.file;
        if (status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (status === "done") {
          notification.success({ message : "FIle uploaded", description:"File uploaded successfully. We are processing it. we will Send the file on slack."});
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

        return (
          <Layout className="referral-page">
            <Row>
              <Col span={12}>
                <div className="flex mb-20 mt-20">
                  <div className="theme-color semibold text-30 mr-10">
                    Generate Short url
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} >
                <Card>
                  <div className=" ">
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Upload csv with column: url.
                      </p>
                    </Dragger>
                  </div>
                </Card>
              </Col>
            </Row>
          </Layout>
        );
    }
}

export default connect()(ShortenUrl);
//mapStateToProps,
//mapDispatchToProps
