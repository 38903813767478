import React, { Component, useMemo } from "react";
import Lightbox from "react-image-lightbox";
import "./../listOrders/listOrders.scss";
import classnames from "classnames";
import { CSVLink } from "react-csv";
import {
  Table,
  Button,
  Icon,
  Input,
  notification,
  Menu,
  Dropdown,
  message,
  Pagination,
  Tag,
  Spin,
  Modal,
  Checkbox,
  DatePicker,
  Badge,
  Tooltip,
  Select,
  Form,
  Tabs,
} from "antd";

import LoadingOverlay from "react-loading-overlay";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/orders";
import * as fulfillmentActions from "actions/fulfillment";
import {
  debounce,
  formatAddress,
  getPaymentMethod,
  handleDownload,
  addQueryParam,
  capitalizeFirstLetter,
} from "utils/helpers";
import moment from "moment";
import momentTimezone from "moment-timezone";
import {
  paymentStatusMapping,
  orderFullfillmentStatusListing,
  orderFullfillmentStatusEmoji,
  orderFullfillmentStatusMapping,
  orderFulfillmentStatusLabel,
  oderTypeMapper,
  orderStatusFilterMapping,
  trackingDetailsCarriers,
  orderStatusMapping,
  orderPaymentCompleteStatusFilterMapping,
  whatsAppMessageStatus,
  getCountryFilterMapping,
  getDefaultCountryFilter,
  FULFILLMENT_ERROR_STATUS,
  orderFlaggedStatus,
  checkAllowedCountries,
  orderTypeFilter,
  countryCodeToCountryName,
} from "utils/constants";
import { COUNTRIES_WITH_STATES } from "utils/countryMap";
import OrdersHeader from "../../../components/orders/OrdersHeader";
import FlaggedModal from "components/orders/FlaggedModal";
import * as assessmentActions from "actions/assessment";
import { Link } from "react-router-dom";
import LoaderImg from "static/img/icon/Spinner-1s-200px.gif";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
// in gms
const WEIGHT_THRESHOLD = 380; //300;

const FULFILLMENT_ACTION_OPTION = [
  {
    key: "order_ready_pack",
    label: "Ready to Pack  👍",
  },
  // {
  //   key: "order_hold",
  //   label: "Mark on Hold",
  // },
  // {
  //   key: "create_waybill",
  //   label: "Create Waybill 📝",
  // },
  // {
  //   key: "generate_label",
  //   label: "Generate Label 📑",
  // },
  {
    key: "order_packing",
    label: "Mark Packing 👷‍♀️",
  },
  {
    key: "order_packed",
    label: "Mark Packed 🎁",
  },
  {
    key: "order_shipped",
    label: "Mark Shipped 🚛",
  },
  // {
  //   key: "order_delivered",
  //   label: "Mark Delivered 📦",
  // },
  // {
  //   key: "order_hold",
  //   label: "Mark on Hold ✋",
  // },
  // {
  //   key: "order_canceled",
  //   label: "Mark Cancelled ❎",
  // },
  {
    key: "order_flagged",
    label: "Mark Flagged 🚩",
  },
  {
    key: "order_resolved",
    label: "Mark Resolved ✅",
  },
];

const RECORD_PER_PAGE = [
  {
    key: "20",
    label: "20",
  },
  {
    key: "30",
    label: "30",
  },
  {
    key: "40",
    label: "40",
  },
  {
    key: "50",
    label: "50",
  },
  {
    key: "100",
    label: "100",
  },
  {
    key: "200",
    label: "200",
  },
];

const ORDER_ADVANCED_SEARCH_OPTIONS = [
  {
    key: "shortId",
    label: "Order ID",
  },
  {
    key: "search",
    label: "Name",
  },
  {
    key: "trackingNumber",
    label: "Tracking No",
  },
];

const basicAdminColumnsFilters = [
  "all",
  "submissionDate",
  "submissionTime",
  "orderStatus",
  "notes",
  "daySerum",
  "dayCream",
  "nightSerum",
  "nightCream",
  "SSU",
  "CFC",
  "BBT",
  "ELYES",
  "CSM_FB",
  "Extras",
  "orderId",
  "name",
  "shippingName",
  "country",
  "fulfillmentStatus",
  "code",
  "userId",
  "itemsPurchased",
  "pdfLink",
  "paymentMethod",
  "giftSet",
  "giftSetNote",
  "type",
  "subscriptionType",
  "itemsQuantity",
  "flaggedStatus",
  "fulfillmentErr",
  "paymentStatus",
  "shipmentLabel",
  "packageType",
];

const adminColumnsFilter = [
  "all",
  "orderId",
  "name",
  "shippingName",
  "paymentStatus",
  "submissionDate",
  "submissionTime",
  "code",
  "userId",
  "itemsPurchased",
  "pdfLink",
  "orderStatus",
  "daySerum",
  "dayCream",
  "nightSerum",
  "nightCream",
  "SSU",
  "CFC",
  "BBT",
  "ELYES",
  "CSM_FB",
  "Extras",
  "fulfillmentStatus",
  "stripeInvoiceId",
  "type",
  "tags",
  "notes",
  "customerNotes",
  "phone",
  "shippingAddress",
  "shipmentId",
  "shipmentLabel",
  "country",
  "totalWeight",
  "itemsQuantity",
  "action",
  "giftSet",
  "giftSetNote",
  "goGreenPackaging",
  "subscriptionType",
  "flaggedStatus",
  "fulfillmentErr",
  "packageType",
];

const { confirm } = Modal;
const { SubMenu } = Menu;
const { TabPane } = Tabs;

const Loader = () => {
  return <img src={LoaderImg} alt="loader" style={{ width: "100%" }} />;
};

class FulfillmentOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrderData: null,
      searchKey: "",
      sortOrder: "asc",
      sortField: "",
      activeAdvSearch: ORDER_ADVANCED_SEARCH_OPTIONS[0],
      recordsPerpage: RECORD_PER_PAGE[0],
      selectedOrderIds: [],
      userType: "",
      activeCountryFilter: [],
      activeFulfillMentFilters: ["10"],
      activeOrderTypeFilter:
        JSON.parse(localStorage.getItem("allowedOrderType")) || [],
      flaggedStatusFilter: "",
      activeStatusFilters: [],
      fulfillmentErrorStatus: [],
      activeProductList: [],
      shownColumns: this.getFilters(),
      showRelatedOrdersModal: false,
      checked: true,
      isExportingData: false,
      disabled: false,
      pageNo: 1,
      requestPickupTime: "",
      showSKUModal: false,
      totalOrderToPack: 0,
      //showExportModal: false,
      exportModalLoading: false,
      startDate: undefined,
      endDate: undefined,
      //showAssessmentModal: false,
      customerTotalOrder: 0,
      customerTotalSubscription: 0,
      customerOrderListState: [],
      showRequestPickupModal: false,
      productList: [],
      fulfillmentModal: false,
      orderToFulfill: {},
      lightBoxOpen: false,
      showLoader: false,
      showFlaggedModal: false,
      flaggedStatus: {},
      exportedDataSet: [],
      exportedAUPackingDataSet: [],
    };
    this.csvRef = React.createRef();
    this.csvRefAUPackingList = React.createRef();
    this.shoppingExportCsvRef = React.createRef();
    this.onTabChange = this.onTabChange.bind(this);
    this.showActiveTab = this.showActiveTab.bind(this);
    this.updateFlaggedStatus = this.updateFlaggedStatus.bind(this);
  }

  getFilters = () => {
    if (localStorage.getItem("userType") === "basic") {
      return basicAdminColumnsFilters;
    }
    return adminColumnsFilter;
  };

  QueryStringToJSON(query) {
    var pairs = query.split("&");

    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split("=");
      //console.log(pair);
      if (pair[1]) {
        //console.log("=====", result[pair[0]]);
        if (result[pair[0]]) {
          let val = result[pair[0]];
          if (Array.isArray(val)) {
            val.push(decodeURIComponent(pair[1]));
          } else {
            result[pair[0]] = [val, decodeURIComponent(pair[1])];
          }
        } else {
          result[pair[0]] = decodeURIComponent(pair[1] || "");
        }
        //console.log(result);
      }
    });

    return JSON.parse(JSON.stringify(result));
  }

  getProductListOrder(product) {
    let order;
    switch (product) {
      case "Bounce Back Toner":
        order = 1;
        break;
      case "Cloud Factory Cleanser":
        order = 2;
        break;
      case "Eye Love You Eye Serum":
        order = 3;
        break;
      case "Sunny Side Up SPF 30 Mist":
        order = 4;
        break;
      case "Drop of Balance":
        order = 5;
        break;
      case "Drop of Light":
        order = 6;
        break;
      case "Drop of Perfection":
        order = 7;
        break;
      case "Drop of Softness":
        order = 8;
        break;
      case "Drop of Youth":
        order = 9;
        break;
      case "Crazy Rich Moisturiser":
        order = 10;
        break;
      case "Dew Date":
        order = 11;
        break;
      case "Matte & Moist":
        order = 12;
        break;
      case "DND Nights":
        order = 13;
        break;
      case "Milky Way":
        order = 14;
        break;
      // case "Bounce Back Toner":
      //   order = 15;
      //   break;
      // case "Bounce Back Toner":
      //   order = 16;
      //   break;
      // case "Bounce Back Toner":
      //   order = 17;
      //   break;
      // case "Bounce Back Toner":
      //   break;

      default:
        break;
    }
    return order;
  }

  componentDidMount() {
    const { activeStatusFilters } = this.state;
    // setting up userType for not visible actions
    const userType = localStorage.getItem("userType");
    this.setState({
      userType,
    });

    this.props.getActiveProduct().then((res) => {
      let productList = [];
      res.forEach((prod) => {
        //console.log(prod.name);
        if (prod.name !== "Personalised Day and Night Set") {
          let orderNo = this.getProductListOrder(prod.name);
          prod.orderNo = orderNo;
          productList.push(prod);
        }
      });

      productList.sort((a, b) => a.orderNo - b.orderNo);
      this.setState({
        productList: productList,
      });
    });
    let queryString = `&status=2&status=3&fulfillmentStatus=10&flaggedStatus=0&flaggedStatus=2&pageSize=${this.state.recordsPerpage.key}`;
    queryString += addQueryParam("type", this.state.activeOrderTypeFilter);
    let currentPage = this.state.pageNo;
    if (window.sessionStorage.getItem("filters")) {
      queryString = window.sessionStorage.getItem("filters");
      let query_Obj = this.QueryStringToJSON(queryString);
      let activeFulfillMentFilters = ["10"];
      let activeProductList = [];
      let recordsPerpage = this.state.recordsPerpage;
      let activeCountryFilter = [];
      let selectedOrderIds = this.state.selectedOrderIds;
      let activeFlagState = this.flaggedStatusFilter;
      if (query_Obj.fulfillmentStatus) {
        activeFulfillMentFilters = Array.isArray(query_Obj.fulfillmentStatus)
          ? query_Obj.fulfillmentStatus
          : [query_Obj.fulfillmentStatus];
      }
      if (query_Obj["product"]) {
        activeProductList = Array.isArray(query_Obj["product"])
          ? query_Obj["product"]
          : [query_Obj["product"]];
      }
      if (query_Obj["pageSize"]) {
        let page_size = query_Obj["pageSize"] || "20";
        recordsPerpage = { key: page_size, label: page_size };
      }
      if (query_Obj["user.location.country"]) {
        activeCountryFilter = Array.isArray(query_Obj["user.location.country"])
          ? query_Obj["user.location.country"]
          : [query_Obj["user.location.country"]];
      }
      if (query_Obj["page"]) {
        currentPage = query_Obj["page"];
      }
      if (query_Obj["flaggedStatus"]) {
        let flagState = Array.isArray(query_Obj["flaggedStatus"])
          ? query_Obj["flaggedStatus"]
          : [query_Obj["flaggedStatus"]];
        if (flagState.length == 1) {
          if (flagState.includes(1)) {
            activeFlagState = flagState[0];
          } else {
            activeFlagState = flagState[0];
          }
        }
      }
      this.setState({
        activeCountryFilter: activeCountryFilter,
        recordsPerpage: recordsPerpage,
        activeProductList: activeProductList,
        activeFulfillMentFilters: activeFulfillMentFilters,
        flaggedStatusFilter: activeFlagState,
        //pageNo:currentPage,
      });
    } else {
      let activeCountryFilter = [];
      activeCountryFilter = getDefaultCountryFilter();
      if (activeCountryFilter.length) {
        queryString += `&user.location.country=${
          countryCodeToCountryName[activeCountryFilter[0]]
        }`;
        this.setState({
          activeCountryFilter: [
            countryCodeToCountryName[activeCountryFilter[0]],
          ],
        });
      }
    }

    if (window.sessionStorage.getItem("page")) {
      currentPage = window.sessionStorage.getItem("page");
      this.setState({
        pageNo: window.sessionStorage.getItem("page"),
      });
      // currentPage = window.sessionStorage.getItem("page");
    }
    if (window.sessionStorage.getItem("selectedItem")) {
      this.setState({
        selectedOrderIds: JSON.parse(
          window.sessionStorage.getItem("selectedItem")
        ),
      });
    }
    // setting up userType for not visible actions
    this.props
      .fetchAllOrders(
        queryString,
        (currentPage || this.state.pageNo) - 1,
        undefined,
        this.state.startDate,
        this.state.endDate,
        this.props.ordersPageDetails &&
          this.props.ordersPageDetails.activeStatusFilters &&
          this.props.ordersPageDetails.activeStatusFilters,
        this.props.ordersPageDetails &&
          this.props.ordersPageDetails.activeFulfillMentFilters &&
          this.props.ordersPageDetails.activeFulfillMentFilters
      )
      .then((res) => {
        //console.log(res);
        this.setState({ totalOrderToPack: res?.totalCount });
        // let productList = this.state.productList;
        // res.result.forEach(element => {
        //   element.items.forEach( item => {
        //     let quantity = item.quantity;
        //     let productId = item.product.id;
        //     let product = productList.find(e => e.id == productId);
        //     if( product){
        //       product.quantity = (product?.quantity || 0) +  quantity
        //     }
        //   } )
        // });
        // console.log("productList", productList);
      });
    //this.props.getWhatsAppMessage(this.state.whatsAppMessageType);
  }

  getAllOrders = () => {
    this.props.fetchAllOrders(
      `status=2&status=3&fulfillmentStatus=10&fulfillmentStatus=1&fulfillmentStatus=2&fulfillmentStatus=4&fulfillmentStatus=-1`,
      this.state.pageNo - 1,
      undefined,
      this.state.startDate,
      this.state.endDate,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters,
      this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters
    );
  };
  // checkbox
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };

  toggleDisable = () => {
    this.setState({ disabled: !this.state.disabled });
  };

  onColumnFilterChange = (e, item) => {
    // if(item === 'all' && e.target.checked) {
    //   this.setState({
    //     shownColumns: [...this.allColumns]
    //   })
    //   return;
    // }
    // else {
    //   this.setState((prevState, props) => { return {
    //     shownColumns: prevState.shownColumns.filter( col => col !== 'all')
    //    }});
    // }
    if (item === "all") {
      if (e.target.checked) {
        this.setState({
          shownColumns: [...this.getFilters()],
        });
      } else {
        this.setState({
          shownColumns: [],
        });
      }
      return;
    } else {
      this.setState((prevState, props) => {
        return {
          shownColumns: prevState.shownColumns.filter((col) => col !== "all"),
        };
      });
    }
    if (e.target.checked) {
      this.setState((prevState) => {
        let columnsArr = [...prevState.shownColumns, item];
        return {
          shownColumns: columnsArr,
        };
      });
    } else {
      this.setState((prevState) => {
        let columnsArr = prevState.shownColumns.filter((value) => {
          return value !== item;
        });
        return {
          shownColumns: columnsArr,
        };
      });
    }

    this.setState({
      checked: e.target.checked,
    });
  };
  // checkbox

  onChange = (e) => {
    //console.log(e);
  };
  handleMenuClick = (e) => {
    //console.log(e);
  };
  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            0,
            undefined,
            this.state.startDate,
            this.state.endDate
          );
        } else {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            0,
            undefined,
            this.state.startDate,
            this.state.endDate
          );
        }
      }
    );
  }, 300);

  handleOrderTypeFilter = (value) => {
    this.setState(
      (state) => {
        let newFilterList = [];
        //const indexRow = state.activeProductList.indexOf("ROW");
        if (state.activeOrderTypeFilter.indexOf(value) > -1) {
          newFilterList = state.activeOrderTypeFilter.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeOrderTypeFilter, value];
        }
        window.sessionStorage.setItem("page", 1);
        window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
        return {
          activeOrderTypeFilter: newFilterList,
          pageNo: 1,
          selectedOrderIds: [],
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleProductFilter = (value) => {
    this.setState(
      (state) => {
        let newFilterList = [];
        //const indexRow = state.activeProductList.indexOf("ROW");
        if (state.activeProductList.indexOf(value) > -1) {
          newFilterList = state.activeProductList.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeProductList, value];
        }
        window.sessionStorage.setItem("page", 1);
        window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
        return {
          activeProductList: newFilterList,
          pageNo: 1,
          selectedOrderIds: [],
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleCountryFilter = (value) => {
    this.setState(
      (state) => {
        let newFilterList = [];
        newFilterList.push(...state.activeCountryFilter);
        const index = state.activeCountryFilter.indexOf(value);
        if (index > -1) {
          newFilterList.splice(index, 1);
        } else {
          const indexRow = state.activeCountryFilter.indexOf("ROW");
          const indexSG_FC = state.activeCountryFilter.indexOf("SG_FC");
          if (
            value === "ROW" ||
            value == "SG_FC" ||
            indexRow > -1 ||
            indexSG_FC > -1
          ) {
            newFilterList = [value];
          } else {
            newFilterList.push(value);
          }
        }
        window.sessionStorage.setItem("page", 1);
        window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
        if (!newFilterList.length && !checkAllowedCountries()) {
          let countryFilter = getDefaultCountryFilter();
          if (countryFilter.length) {
            newFilterList.push(countryCodeToCountryName[countryFilter[0]]);
          }
        }
        return {
          activeCountryFilter: newFilterList,
          pageNo: 1,
          selectedOrderIds: [],
        };
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleFlaggedStatusFilter = (value) => {
    let query = this.getSelectedQueryStringFilter();
    this.props.fetchAllOrders(
      query,
      0,
      undefined,
      this.state.startDate,
      this.state.endDate
    );
  };

  handleFulfillStatusFilter = (filterKey, value) => {
    //console.log("fuillfillment status=====", value)
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (filterKey === "fulfillmentStatus") {
          if (state.activeFulfillMentFilters.indexOf(value) > -1) {
            newFilterList = state.activeFulfillMentFilters.filter(
              (elem) => elem !== value
            );
          } else {
            newFilterList = [...state.activeFulfillMentFilters, value];
          }
          return {
            activeFulfillMentFilters: newFilterList,
          };
        } else if (filterKey === "fulfillmentError") {
          if (state.fulfillmentErrorStatus.indexOf(value) > -1) {
            newFilterList = state.fulfillmentErrorStatus.filter(
              (elem) => elem !== value
            );
          } else {
            newFilterList = [...state.fulfillmentErrorStatus, value];
          }
          return {
            fulfillmentErrorStatus: newFilterList,
          };
        }
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `status=${value}`;
            } else {
              return `&status=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeFulfillMentFilters
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeFulfillMentFilters.length === 0
            ) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
        this.props.fetchAllOrders(
          //filterQueryStatus + filterQueryFulfillment,
          this.getSelectedQueryStringFilter(),
          0,
          undefined,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  handleAdvSearchChange = (value) => {
    //console.log(value);
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };
  handleDownloadInvoice = (action) => {
    if (this.state.selectedOrderIds.length > 0) {
      let invoiceAction = JSON.parse(action);

      if (invoiceAction.key === "view invoice") {
        window.open(
          `/invoice?orders=${this.state.selectedOrderIds.join()}`,
          "_blank"
        );
      } else if (invoiceAction.key === "download invoice") {
        confirm({
          title: `Are you sure you want to download invoice `,
          okText: "Yes",
          cancelText: "No",
          onOk: () => {
            return this.props
              .downloadMultipleInvoice(this.state.selectedOrderIds)
              .then((res) => {
                if (res) {
                  message.success(
                    "Generating files. Download link will be mail to you shortly"
                  );
                } else {
                  message.error("Oops! Something went wrong");
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    }
  };

  handelDownloadLabel = () => {
    if (this.state.selectedOrderIds.length > 0) {
      confirm({
        title: `Are you sure you want to download AWB Labels `,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          return this.props
            .downloadMultipleLabels(this.state.selectedOrderIds)
            .then((res) => {
              if (res) {
                let failedList = res.fail;
                let failedIds = failedList.map((fail) => fail._id);

                this.setState({
                  showLoader: false,
                  //selectedOrderIds: failedIds,
                });
                //console.log(res);
                if (res.success.length > 0) {
                  notification.success({
                    message: "Label download is processing",
                    description: ` Label download started for ${res.success.length} orders. Download link will be mail to you shortly `,
                    // onClick: () => {
                    //   console.log("Notification Clicked!");
                    // },
                  });
                }

                if (res.fail.length > 0) {
                  notification.error({
                    message: "Processing Failed",
                    description: (
                      <> `Download failed for {res.fail.length} orders` `</>
                    ),
                  });
                }
                // message.success(
                //   "Generating files. Download link will be mail to you shortly"
                // );
              } else {
                message.error("Oops! Something went wrong");
              }
            });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  handelDownloadIngredientsSheet = () => {
    if (this.state.selectedOrderIds.length > 0) {
      confirm({
        title: `Are you sure you want to download IngredientsSheet `,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          return this.props
            .downloadMultipleIngredients(this.state.selectedOrderIds)
            .then((res) => {
              if (res) {
                message.success(
                  "Generating files. Download link will be mail to you shortly"
                );
              } else {
                message.error("Oops! Something went wrong");
              }
            });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  handelRequestPickup = () => {
    const orderIds = this.state.selectedOrderIds;
    const pickupTime = this.state.requestPickupTime;
    if (!pickupTime) {
      notification.warning({
        message: "",
        description: "Please select pickup date .",
      });
      return;
    }
    this.props.requestPickup(orderIds, pickupTime).then((res) => {
      if (res) {
        this.setState({
          selectedOrderIds: [],
          showRequestPickupModal: false,
        });
        const pickupResponse = res;
        //console.log(pickupResponse);
        const pickupId = pickupResponse.pickupId;
        const shipmentsCount = pickupResponse.shipmentsCount;
        let msg = `Pickup requested successfully for ${shipmentsCount} Shipments. PickupId ${pickupId}`;
        message.success(msg);
      } else {
        message.error("Oops! Something went wrong");
      }
    });
  };

  updateFlaggedStatus() {
    this.setState({
      showFlaggedModal: false,
      flaggedStatus: {},
      selectedOrderIds: [],
    });
  }

  handleFlaggedAction = (flaggedStatus) => {
    this.setState({
      showFlaggedModal: true,
      flaggedStatus:
        flaggedStatus === "order_flagged"
          ? orderFlaggedStatus[0]
          : orderFlaggedStatus[1],
    });
  };

  handleFulfillmentAction = (action) => {
    let fulFillmentAction = JSON.parse(action);
    if (
      fulFillmentAction.key === "order_flagged" ||
      fulFillmentAction.key === "order_resolved"
    ) {
      this.handleFlaggedAction(fulFillmentAction.key);
      return;
    }
    let pop_message = "Are you sure you want to proceed?";
    if (fulFillmentAction.key === "order_shipped") {
      pop_message =
        "Are you sure? This will trigger an sms and an email to the customer(s).";
    }
    confirm({
      title: pop_message,
      content: ["order_packing", "order_packed"].includes(fulFillmentAction.key)
        ? "this action will trigger email to the customer."
        : "",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        //console.log("+++++", fulFillmentAction, fulFillmentAction.key);
        this.setState({
          showLoader: true,
        });
        if (fulFillmentAction.key === "create_waybill") {
          //console.log("LLLLL", action.key);

          if (this.state.selectedOrderIds.length > 50) {
            notification.warning({
              message: "",
              description:
                "You can generate WayBill for max 50 Orders. You have selected more than that",
            });
          } else {
            // this.setState({
            //   showLoader: true,
            // });
            this.createWaybillForMultipleShipment({
              orderIds: this.state.selectedOrderIds,
            });
          }
        }
        if (fulFillmentAction.key === "generate_label") {
          this.generateLabelForMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
          //this.handelGenerateLabel()
        }
        if (fulFillmentAction.key === "order_ready_pack") {
          this.markReadyToPackMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_shipped") {
          this.markShippedMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_delivered") {
          this.markDeliveredMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_hold") {
          this.markHoldMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_canceled") {
          this.markCanceledMultipleShipment({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_packed") {
          this.markShipmentPackedMultiple({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "order_packing") {
          this.markShipmentPackingMultiple({
            orderIds: this.state.selectedOrderIds,
          });
        }
        if (fulFillmentAction.key === "regenerate_waybill") {
          this.reGenerateWaybillMultiple({
            orderIds: this.state.selectedOrderIds,
          });
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  showRequestPickupModal = () => {
    this.setState({
      showRequestPickupModal: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      showRequestPickupModal: false,
      //showRelatedOrdersModal: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      showRequestPickupModal: false,
      //showRelatedOrdersModal: false,
    });
  };

  handelUpdateFulfillment = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to generate Waybill for this Order
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.createShipment(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Waybill Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelGenerateLabel = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to generate Label for this Order
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.generateShipmentLabel(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Label Generated successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelOrderReadyPack = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to mark Order to ready for pickup
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToReadyForPack(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Order status change to Ready for pickup.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  handelOrderDeliver = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure you want to mark order to deliver
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToDelivered(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Order status change to Ready for pickup.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  handelOrderShipped = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;
    confirm({
      title: `Are you sure? This will trigger an sms and an email to the customer(s)
      `,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToShipped(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Shipment shipped  successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handelMarkShipmentShipped = (order) => {
    const {
      pageNo,
      startDate,
      endDate,
      activeStatusFilters,
      activeFulfillMentFilters,
    } = this.state;

    confirm({
      title: `Are you sure? This will trigger an sms and an email to the customer(s).
      ?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.markShipmentToShipped(order.id).then((res) => {
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter(),
            pageNo - 1,
            undefined,
            startDate,
            endDate,
            activeStatusFilters,
            activeFulfillMentFilters
          );
          if (res) {
            message.success("Shipment shipped  successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  getCustomerOrder = (customerOrderList, orderData) => {
    //console.log("getCustomerOrder=========", customerOrderList);
    const itemList =
      customerOrderList &&
      customerOrderList.lastOrders &&
      customerOrderList.lastOrders.length > 0
        ? customerOrderList.lastOrders.map((order) => {
            //console.log("HHHHHHHHHHHHH", order);
            return {
              key: order.id,
              order: order,
              orderDate: moment(order.createdAt).format(
                "MMM DD, YYYY [at] hh:mm a"
              ),
              products:
                order.items &&
                order.items.map((item) => item.product.name).join(","),
              paymentStatus: paymentStatusMapping[order.status],
              OrderType: order.orderType,
            };
          })
        : [];
    this.setState({
      customerOrderListState: itemList,
      showRelatedOrdersModal: true,
      customerTotalOrder: customerOrderList.OrdersCount,
      customerTotalSubscription: customerOrderList.subscriptionOrderCount,
      selectedOrderData: orderData,
    });
    return orderData;
  };

  handelRequestForRelatedOrders = (customerId, orderData) => {
    //console.log("handelRequestForRelatedOrders");
    this.props
      .getLastOrdersForCustomer(customerId, customerId, orderData)
      .then((res) => {
        this.getCustomerOrder(res, orderData);
      });
    // this.setState({
    //   showRelatedOrdersModal: true,
    //   selectedOrderData: orderData,
    // });
    //this.props.getLatestAssessmentByUserId
  };

  createWaybillForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;

    this.props.createShipmentInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);

        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Waybill created",
            description: ` WayBill created successfully for ${res.success.length} orders`,
            // onClick: () => {
            //   console.log("Notification Clicked!");
            // },
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: (
              <>
                {" "}
                `Fail to create WayBill for {res.fail.length} orders.
                <span style={{ color: "red", cursor: "pointer" }}>
                  {" "}
                  Click to view
                </span>
                `
              </>
            ),
            onClick: () => {
              this.setState(
                (state, props) => {
                  return { fulfillmentErrorStatus: [-1] };
                },
                () => {
                  //console.log("++++++++++",this.state.fulfillmentErrorStatus);
                  this.props.fetchAllOrders(
                    //filterQueryStatus + filterQueryFulfillment,
                    this.getSelectedQueryStringFilter(),
                    0,
                    undefined,
                    this.state.startDate,
                    this.state.endDate
                  );
                }
              );
              //console.log("Notification Clicked!");
            },
          });
        }

        // message.success(
        //   `WayBill success for ${res.success.length} and failed for ${res.fail.length}`
        // );
      } else {
        notification.error({
          message: "Processing Failed to generate Waybill",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  generateLabelForMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    //console.log( "generateLabelForMultipleShipment", orderIds )
    this.props.generateLabelInBulk(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);

        if (res.success.length > 0) {
          notification.success({
            message: "Label Generated",
            description: ` Label Generated successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: (
              <> `Fail to Generated Label for {res.fail.length} orders.`</>
            ),
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Generated Label",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markReadyToPackMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToReadyForPackMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Ready To pack ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Ready To pack Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markShippedMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToShippedMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        if (res.success.length > 0) {
          notification.success({
            message: "Order Shipped ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Shipped Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markDeliveredMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToDeliveredMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        //console.log(res);
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });

        if (res.success.length > 0) {
          notification.success({
            message: "Order Delivered ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Delivered Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markHoldMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToHoldMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Order Mark on Hold ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Hold Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markCanceledMultipleShipment = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentToCancelMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Order Canceled ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Cancel Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  reGenerateWaybillMultiple = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.reGenerateWaybillMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "waybill Regenerating ",
            description: `success for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Packed Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markShipmentPackingMultiple = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentPackingMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Order Packing ",
            description: `successfull for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Packed Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  markShipmentPackedMultiple = (orderIds) => {
    const { pageNo, startDate, endDate } = this.state;
    this.props.markShipmentPackedMultiple(orderIds).then((res) => {
      this.props.fetchAllOrders(
        this.getSelectedQueryStringFilter("Initial"),
        pageNo - 1,
        undefined,
        startDate,
        endDate,
        this.state.activeStatusFilters,
        this.state.activeFulfillMentFilters
      );
      if (res) {
        let failedList = res.fail;
        let failedIds = failedList.map((fail) => fail._id);
        this.setState({
          showLoader: false,
          selectedOrderIds: failedIds,
        });
        //console.log(res);
        if (res.success.length > 0) {
          notification.success({
            message: "Order Packed ",
            description: `successfully for ${res.success.length} orders`,
          });
        }

        if (res.fail.length > 0) {
          notification.error({
            message: "Processing Failed",
            description: <> `Fail to process {res.fail.length} orders.`</>,
          });
        }
      } else {
        notification.error({
          message: "Processing Failed to Packed Orders",
          description: `Something went wrong. Refresh page and try again`,
        });
        this.setState({
          showLoader: false,
          selectedOrderIds: [],
        });
      }
    });
  };

  // handleUpdateFullfillmentStatusByBasicUser = () => {
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       let payload = {
  //         fulfillmentStatus: 4,
  //         trackingDetails: values.trackingDetails,
  //       };
  //       return this.props
  //         .updateOrder(this.state.orderToFulfill.id, payload)
  //         .then((res) => {
  //           this.props.fetchAllOrders();
  //           if (!res.error) {
  //             message.success("Order status changed to shipped");
  //             this.setState({ fulfillmentModal: false });
  //           } else {
  //             message.error(res.error.internalMessage);
  //           }
  //         });
  //     }
  //   });
  // };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  SelectDates = (val) => {
    //console.log( "VAALLLL",val);
    if (val.length === 0) {
      this.setState(
        {
          startDate: undefined,
          endDate: undefined,
        },
        () => this.props.fetchAllOrders(this.getSelectedQueryStringFilter(), 0)
      );
    } else {
      this.setState(
        {
          startDate: new Date(val[0]).toISOString(),
          endDate: new Date(val[1]).toISOString(),
        },
        () =>
          this.props.fetchAllOrders(
            this.getSelectedQueryStringFilter("Initial"),
            0,
            undefined,
            moment(this.state.startDate, "YYYY-MM-DD").toDate().toISOString(),
            moment(this.state.endDate, "YYYY-MM-DD")
              .add(1, "days")
              .toISOString()
          )
      );
    }
  };
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  getOrderType(order) {
    if (order.isSubscribed) {
      if (
        order &&
        order.subscriptionId &&
        order.subscriptionId.period &&
        order.subscriptionId.period.value
      ) {
        if (order.subscriptionId.period.value / 30 > 1) {
          return `Subscription (${
            order.subscriptionId.period.value / 30
          } Months)`;
        }
        return "Subscription (1 Month)";
      }
      return "Subscription";
    }
    return "One-time";
  }

  getPhoneNumber(order) {
    if (order && order.shippingAddress && order.shippingAddress.phone) {
      return (
        order.shippingAddress.phone.isdCode +
        " - " +
        order.shippingAddress.phone.phone
      );
    }
    return "-";
  }

  isCountryStatusApplied = () => {
    const { activeCountryFilter } = this.state;
    return !!(
      activeCountryFilter?.length > 0 ||
      this.props.ordersPageDetails?.activeCountryFilter?.length > 0
    );
  };

  isProductStatusApplied = () => {
    const { activeProductList } = this.state;
    return !!(
      activeProductList?.length > 0 ||
      this.props.activeProductList?.activeProductList?.length > 0
    );
  };

  isOrderTypeStatusApplied = () => {
    const { activeOrderTypeFilter } = this.state;
    return !!(
      activeOrderTypeFilter?.length > 0 ||
      this.props.activeProductList?.activeOrderTypeFilter?.length > 0
    );
  };

  getOrderTypeStatusCount = () => this.state.activeOrderTypeFilter?.length || 0;

  getCountryStatusCount = () => this.state.activeCountryFilter?.length || 0;
  getActiveProductCount = () => this.state.activeProductList?.length || 0;
  getSelectedQueryforActions = () => {
    let queryString = "";
    const { activeFulfillMentFilters, activeStatusFilters } = this.state;
    queryString = `status=2`;
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    return queryString;
  };

  getSelectedQueryStringFilter = () => {
    let queryString = "status=2&status=3";
    const {
      activeFulfillMentFilters,
      fulfillmentErrorStatus,
      activeStatusFilters,
      activeCountryFilter,
      searchKey,
      activeAdvSearch,
      activeProductList,
      activeOrderTypeFilter,
      sortField,
      sortOrder,
      flaggedStatusFilter,
    } = this.state;
    //console.log( )
    if (sortField) {
      queryString += `&sortOrder=${sortOrder}`;
      queryString += `&sortField=${sortField}`;
    }

    //queryString += addQueryParam("sortField", sortField);
    queryString += addQueryParam("user.location.country", activeCountryFilter);
    queryString += addQueryParam("product", activeProductList);
    queryString += addQueryParam("type", activeOrderTypeFilter);
    if (!flaggedStatusFilter?.length) {
      if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
        activeFulfillMentFilters.forEach((x, index) => {
          if (index === 0) {
            if (queryString && queryString.length > 0) {
              queryString += `&fulfillmentStatus=${x}`;
            } else {
              queryString = `fulfillmentStatus=${x}`;
            }
          } else {
            queryString += `&fulfillmentStatus=${x}`;
          }
        });
      }
      if (fulfillmentErrorStatus.length > 0) {
        if (queryString && queryString.length > 0) {
          queryString += `&fulfillmentStatus=-1`;
        } else {
          queryString += `fulfillmentStatus=-1`;
        }
        fulfillmentErrorStatus.forEach((x, index) => {
          if (x !== -1) {
            queryString += `&fulfillmentErr=${x}`;
          }
        });
      }

      if (
        fulfillmentErrorStatus.length == 0 &&
        activeFulfillMentFilters.length === 0
      ) {
        queryString +=
          "&fulfillmentStatus=10&fulfillmentStatus=1&fulfillmentStatus=2&fulfillmentStatus=4&fulfillmentStatus=-1";
      }
      queryString += "&flaggedStatus=0&flaggedStatus=2";
    } else {
      if (flaggedStatusFilter == "2") {
        queryString += `&fulfillmentStatus=10&fulfillmentStatus=1&fulfillmentStatus=2&fulfillmentStatus=-1&flaggedStatus=${flaggedStatusFilter}`;
      } else {
        queryString += `&flaggedStatus=${flaggedStatusFilter}`;
      }
    }
    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}`;
    }
    if (queryString && queryString.length > 0) {
      if (!this.state.isExportingData) {
        queryString =
          queryString + "&pageSize=" + this.state.recordsPerpage.key + "&";
      }
    }
    this.setState({
      selectedOrderIds: [],
    });
    window.sessionStorage.setItem("filters", queryString);
    window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
    return queryString;
  };

  isFullfillmentStatusApplied = () => {
    const { activeFulfillMentFilters } = this.state;
    if (
      (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeFulfillMentFilters &&
        this.props.ordersPageDetails.activeFulfillMentFilters.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  isCountryFilterApplied = () => {
    const { activeCountryFilter } = this.state;
    if (activeCountryFilter && activeCountryFilter.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  getFullfillmentStatusCount = () => {
    // const { activeFulfillMentFilters, fulfillmentErrorStatus } = this.state;
    // if (
    //   (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
    //   fulfillmentErrorStatus.length > 0
    // ) {
    //   return activeFulfillMentFilters.length + fulfillmentErrorStatus.length;
    // } else {
    //   return 0;
    // }
    const { fulfillmentErrorStatus } = this.state;
    if (fulfillmentErrorStatus.length > 0) {
      return fulfillmentErrorStatus.length;
    } else {
      return 0;
    }
  };

  // getFullfillmentStatusCount = () => {
  //   const { activeFulfillMentFilters } = this.state;
  //   if (
  //     (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) ||
  //     (this.props.ordersPageDetails &&
  //       this.props.ordersPageDetails.activeFulfillMentFilters &&
  //       this.props.ordersPageDetails.activeFulfillMentFilters.length > 0)
  //   ) {
  //     if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
  //       return activeFulfillMentFilters.length;
  //     } else {
  //       return this.props.ordersPageDetails.activeFulfillMentFilters.length;
  //     }
  //   } else {
  //     return 0;
  //   }
  // };

  getFullfillmentErrorStatusCount = () => {
    const { fulfillmentErrorStatus } = this.state;
    if (fulfillmentErrorStatus && fulfillmentErrorStatus.length > 0) {
      return fulfillmentErrorStatus.length;
    } else {
      return 0;
    }
  };
  isClearAllFilterDisabled = () => {
    if (
      !this.isFullfillmentStatusApplied() &&
      !this.isFulfillmentErrorStatusApplied() &&
      !this.isCountryFilterApplied() &&
      !this.isProductStatusApplied() &&
      this.state.selectedOrderIds > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  _onCloseLightBox = () => {
    this.setState({ lightBoxOpen: false });
  };

  _openLightBox = () => {
    const { activeAssessment } = this.props;
    if (activeAssessment && activeAssessment.selfieURL) {
      this.setState({ lightBoxOpen: true });
    }
  };

  getPaymentStatusCount = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.ordersPageDetails &&
        this.props.ordersPageDetails.activeStatusFilters &&
        this.props.ordersPageDetails.activeStatusFilters.length > 0)
    ) {
      if (activeStatusFilters && activeStatusFilters.length > 0) {
        return activeStatusFilters.length;
      } else {
        return this.props.ordersPageDetails.activeStatusFilters.length;
      }
    } else {
      return 0;
    }
  };

  isFulfillmentErrorStatusApplied = () => {
    const { fulfillmentErrorStatus } = this.state;
    if (fulfillmentErrorStatus && fulfillmentErrorStatus.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  getSelectedQueryStringForSearch = () => {
    let queryString = "&status=2&status=3";
    const {
      activeFulfillMentFilters,
      activeStatusFilters,
      activeCountryFilter,
    } = this.state;
    // if (activeStatusFilters && activeStatusFilters.length > 0) {
    //   activeStatusFilters.forEach((x, index) => {
    //     if (index === 0) {
    //       queryString = `&status=${x}`;
    //     } else {
    //       queryString += `&status=${x}`;
    //     }
    //   });
    // } else {
    //   queryString = `&status=2&status=3&status=4`;
    // }
    queryString += addQueryParam("user.location.country", activeCountryFilter);
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `&fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    } else {
      queryString =
        "&fulfillmentStatus=10&fulfillmentStatus=1&fulfillmentStatus=2&fulfillmentStatus=4&fulfillmentStatus=-1";
    }

    return queryString;
  };

  handlerecordPerpageAction = (value) => {
    this.setState(
      {
        recordsPerpage: JSON.parse(value),
      },
      () => {
        this.props.fetchAllOrders(
          this.getSelectedQueryStringFilter(),
          this.state.pageNo - 1,
          undefined,
          this.state.startDate,
          this.state.endDate,
          this.props.ordersPageDetails &&
            this.props.ordersPageDetails.activeStatusFilters &&
            this.props.ordersPageDetails.activeStatusFilters,
          this.props.ordersPageDetails &&
            this.props.ordersPageDetails.activeFulfillMentFilters &&
            this.props.ordersPageDetails.activeFulfillMentFilters
        );
      }
    );
  };

  getSelectedQueryForCSV = () => {
    let queryString = "";
    const {
      activeFulfillMentFilters,
      activeStatusFilters,
      searchKey,
      activeAdvSearch,
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `status=${x}`;
        } else {
          queryString += `&status=${x}`;
        }
      });
    } else {
      queryString = `status=2&status=3`;
    }
    if (activeFulfillMentFilters && activeFulfillMentFilters.length > 0) {
      activeFulfillMentFilters.forEach((x, index) => {
        if (index === 0) {
          if (queryString && queryString.length > 0) {
            queryString += `&fulfillmentStatus=${x}`;
          } else {
            queryString = `fulfillmentStatus=${x}`;
          }
        } else {
          queryString += `&fulfillmentStatus=${x}`;
        }
      });
    }

    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}&`;
    }

    return queryString;
  };

  getProductRecommendedType(product, productArray) {
    let productCategory = product?.product?.category?.name;
    let recommendationType = productCategory;
    productCategory = productCategory?.toLowerCase();
    let ProductName = product?.product?.name?.toLowerCase();

    if (productCategory?.includes("face")) {
      //console.log( "XXXXXXXXXX" )
      if (ProductName === "drop of light") {
        recommendationType = "Day Serum";
        return recommendationType;
      }
      if (ProductName === "drop of youth") {
        recommendationType = "Night Serum";
        return recommendationType;
      } else {
        if (ProductName === "drop of softness") {
          if (!productArray?.includes("drop of light")) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night Serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of balance") {
          if (
            !(
              productArray?.includes("drop of light") ||
              productArray?.includes("drop of softness")
            )
          ) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of perfection") {
          if (!productArray?.includes("drop of youth")) {
            recommendationType = "Night Serum";
            return recommendationType;
          } else {
            recommendationType = "Day Serum";
            return recommendationType;
          }
        }
      }
    }

    return recommendationType;
  }

  getPrinterToolLink(products, shippingAddress) {
    let day_routine = [];
    let night_routine = [];
    let day_serum = "";
    let day_cream = "";
    let night_serum = "";
    let night_cream = "";
    let productNameArray = products?.map((p) =>
      p?.product?.name?.toLowerCase()
    );
    if (products) {
      products.forEach((prod) => {
        let recommendationType = prod?.product?.recommendationType
          ?.toLowerCase()
          ?.trim();

        if (!recommendationType) {
          recommendationType = this.getProductRecommendedType(
            prod,
            productNameArray
          );
          recommendationType = recommendationType?.toLowerCase().trim();
        }
        if (
          recommendationType?.includes("day") ||
          recommendationType?.includes("moisturiser")
        ) {
          if (day_routine.length <= 2) {
            if (recommendationType == "day serum") {
              day_serum = prod.product._id;
            } else if (
              recommendationType == "moisturiser" ||
              recommendationType == "day cream"
            ) {
              day_cream = prod.product._id;
            }
          }
        }
        if (recommendationType?.includes("night")) {
          if (night_routine.length <= 2) {
            if (recommendationType == "night cream") {
              night_cream = prod.product._id;
              //console.log('night cream ', prod.product.name);
            } else if (recommendationType == "night serum") {
              night_serum = prod.product._id;
              //console.log('night serum ', prod.product.name);
            }
          }
        }
      });
    }

    day_routine = [day_serum, day_cream];
    night_routine = [night_serum, night_cream];

    let day_routine_str = day_routine.join(",");
    let night_routine_str = night_routine.join(",");

    let name =
      capitalizeFirstLetter(shippingAddress?.firstName) +
      " " +
      capitalizeFirstLetter(shippingAddress?.lastName);

    let url = `http://tools.yours.sg/mini-lfy.php?day_routine=${day_routine_str}&night_routine=${night_routine_str}&name=${name}`;
    //console.log("==== ", url);
    return url;
  }

  itemsPurchased(order) {
    return order.items.map((x) => x?.product?.ssu).join(", ");
  }

  getEssentials(order, productName, isExport = false) {
    if (!order) {
      return "";
    }

    let product_name = "";
    let quantity = "";

    order.items.forEach((item) => {
      if (item?.product?.ssu?.toLowerCase() === productName.toLowerCase()) {
        product_name = item?.product?.ssu;
        quantity = item?.quantity;
      }
    });

    if (product_name) {
      if (productName == "CFC" && isExport == true) {
        product_name = "cloud";
      }
      return product_name + " - " + quantity;
    } else {
      return quantity;
    }

    // return  quantity;
  }

  getFreebie_minis(order, isExport = false) {
    if (!order) {
      return "";
    }

    let product_name = "";
    let quantity = "";
    let item_name = "";
    order.items.forEach((item) => {
      if (
        item?.product?.ssu?.toLowerCase() === "mexfo" ||
        item?.product?.ssu?.toLowerCase() === "fbs" ||
        item?.product?.ssu?.toLowerCase() === "mexfo2"
      ) {
        product_name = item?.product?.ssu;
        quantity = item?.quantity;

        if (product_name.toLowerCase() == "mexfo" && isExport == true) {
          quantity = quantity * 3;
          item_name += " " + product_name + "- " + quantity;
        } else {
          item_name += " " + product_name + "(3)" + "- " + quantity;
        }
      }
    });

    return item_name;
    // return  quantity;
  }
  handelPageChange(pageNo) {
    this.setState({ pageNo: pageNo });
    this.props.fetchAllOrders(
      this.getSelectedQueryStringFilter("Initial"),
      pageNo - 1,
      undefined,
      this.state.startDate,
      this.state.endDate,
      this.state.activeStatusFilters,
      this.state.activeFulfillMentFilters
    );
  }

  getProductInitials(name) {
    if (name) {
      return name
        .split(" ")
        .map((word, index) => {
          if (index < 3) {
            return word.charAt(0).toUpperCase();
          }
        })
        .join("");
    }
  }

  getProductName(order, orderType, isSerum = false) {
    if (!order) {
      return "";
    }
    let product_name = "";
    let quantity = "";
    let productNameArray = order?.items?.map((p) =>
      p?.product?.name?.toLowerCase()
    );
    for (let i = 0; i < order?.items?.length; i++) {
      let item = order.items[i];
      let recommendationType = item?.recommendationType?.toLowerCase();
      if (!recommendationType) {
        recommendationType = this.getProductRecommendedType(
          item,
          productNameArray
        );
        recommendationType = recommendationType?.toLowerCase().trim();
      }
      if (
        recommendationType === "day cream " ||
        recommendationType === "moisturiser"
      ) {
        recommendationType = "day cream";
      }
      if (
        recommendationType?.toLowerCase().trim() === orderType.toLowerCase()
      ) {
        product_name =
          item?.product?.ssu || this.getProductInitials(item?.product?.name);

        if (orderType.toLowerCase().includes("serum") && isSerum == true) {
          product_name = item?.product?.name;
        }
        quantity = item?.quantity;
        break;
      }
    }

    if (product_name) {
      return product_name + " - " + quantity;
    } else {
      return quantity;
    }
  }

  getPackingType(order) {
    let packageType = " 📩";
    let shippedByBox = ["cfc", "bbt"];

    if (order.type == "influencer") {
      packageType = "Box 📦";
      return packageType;
    }

    let items = order.items;
    let totalWeight = 0;

    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let product = item?.product;
      if (product?.category?.name?.toLowerCase() != "bundles") {
        let ssuCode = product?.ssu.toLowerCase();
        if (shippedByBox.includes(ssuCode)) {
          packageType = "Box 📦";
          return packageType;
        }
        let weight = product?.shippingMetadata?.actualWeight * item.quantity;
        totalWeight = totalWeight + weight;
      } else {
        packageType = "Box 📦";
        return packageType;
      }
    }

    if (totalWeight > WEIGHT_THRESHOLD) {
      packageType = "Box 📦";
      return packageType;
    }

    return packageType;
  }

  // getPackingType(order){
  //   let packageType = " 📩";

  //   let shippedByBox = [ 'cfc', 'bbt', 'hat'];
  //   let nonBottleSKU = ["acp", 'mexfo', 'elyes'];
  //   let bottleSKU = ["face serum", 'day cream', "night cream"];
  //   let SSU = 'ssu';

  //   if(order.type == 'influencer'){
  //     packageType = "Box 📦";
  //     return packageType;;
  //   }

  //   let items = order.items;
  //   if(items.length >3 ){
  //     packageType = "Box 📦";
  //     return packageType;
  //   }

  //   let totalUnits= 0;
  //   let nonBottleUnit=0
  //   let bottleUnit=0;
  //   for( let i=0; i< items.length ;i++){
  //     let prod = items[i];
  //     let category=prod.product?.category.name.toLowerCase();
  //     let ssuCode =prod.product?.ssu.toLowerCase();
  //     totalUnits = totalUnits +prod.quantity;
  //     if( shippedByBox.includes(ssuCode)){
  //       packageType = "Box 📦";
  //       return packageType;
  //     }

  //     if( nonBottleSKU.includes(ssuCode)){
  //       nonBottleUnit = nonBottleUnit + prod.quantity;
  //       if (nonBottleUnit > 3) {
  //         packageType = "Box 📦";
  //         return packageType;
  //       }
  //     }

  //     if(category != 'essentials'  ){
  //       if( bottleSKU.includes( category)){
  //         bottleUnit = bottleUnit + prod.quantity;
  //         if (bottleUnit > 1) {
  //           packageType = "Box 📦";
  //           return packageType;
  //         }
  //       }

  //     }else{
  //       if(ssuCode == 'ssu'){
  //          bottleUnit = bottleUnit + prod.quantity;
  //          if(  bottleUnit >1){
  //           packageType = "Box 📦";
  //           return packageType;
  //       }
  //       }
  //     }
  //   }
  //    return packageType;
  // }

  getExtraProduct(order) {
    if (!order) {
      return "";
    }

    let productNameArray = order.items?.map((p) =>
      p?.product?.name?.toLowerCase()
    );

    let recommendedType = [];
    let extra_product = [];

    for (let i = 0; i < order.items.length; i++) {
      let item = order.items[i];
      let recommendationType = item?.recommendationType?.toLowerCase();
      if (!recommendationType) {
        recommendationType = this.getProductRecommendedType(
          item,
          productNameArray
        );
        recommendationType = recommendationType?.toLowerCase().trim();
      }
      if (
        recommendationType === "day cream " ||
        recommendationType === "moisturiser"
      ) {
        recommendationType = "day cream";
      }
      if (
        recommendationType?.includes("day") ||
        recommendationType?.includes("night")
      ) {
        if (!recommendedType.includes(recommendationType)) {
          recommendedType.push(recommendationType);
        } else {
          const itemName =
            item?.product?.ssu || this.getProductInitials(item?.product?.name);
          let quantity = item.quantity;
          extra_product.push(itemName + "-" + quantity);
        }
      } else {
        if (
          recommendationType == "extras" ||
          item?.product?.category?.name?.toLowerCase() == "bundle" ||
          item?.product?.category?.name?.toLowerCase() == "bundles" ||
          item?.product?.category?.name?.toLowerCase() == "merch" ||
          item?.product?.category?.name?.toLowerCase() == "complimentary" ||
          !item?.product?.ssu ||
          item?.product?.ssu == "ACP" ||
          item?.product?.ssu == "mEXFO30"
        ) {
          const itemName =
            item?.product?.ssu || this.getProductInitials(item?.product?.name);
          let quantity = item.quantity;
          extra_product.push(itemName + "-" + quantity);
        }
      }
    }
    return extra_product.join(",");
  }

  getOrderTypeIcon(order) {
    if (!order) {
      return "";
    }
    if (order.orderType.toLowerCase() === "re-order") {
      //return "🔄";
      return (
        <Tooltip title={order.orderType}>
          <span role="img">
            {" "}
            {window.navigator.appVersion.indexOf("Win") != -1 ? "🔁" : "🔄"}
          </span>
        </Tooltip>
      );
    } else if (order.orderType.toLowerCase() === "first-time") {
      //return "1️⃣";
      return (
        <Tooltip title={order.orderType}>
          <span role="img">1️⃣</span>
        </Tooltip>
      );
    } else return "";
  }

  formatOrders(orders) {
    let orderList = orders.map((items, index) => {
      let order = {
        //serialNo: index + 1,
        letter: items?.shippingAddress?.firstName?.charAt(0)?.toUpperCase(),
        orderId: items?.shortId,
        shippingName:
          items?.shippingAddress?.firstName +
          " " +
          items.shippingAddress?.lastName,
        orderType: items.orderType,
        orderNote: items.notes,
        daySerum: this.getProductName(items, "day serum", true),
        dayCream: this.getProductName(items, "day cream"),
        nightSerum: this.getProductName(items, "night serum", true),
        nightCream: this.getProductName(items, "night cream"),
        SSU: this.getEssentials(items, "ssu"),
        CFC: this.getEssentials(items, "CFC", true),
        BBT: this.getEssentials(items, "BBT"),
        ELYES: this.getEssentials(items, "ELYES"),
        CSM_FB: this.getFreebie_minis(items, true),
        Extras: this.getExtraProduct(items),
        packageType: this.getPackingType(items),
      };
      order["set"] =
        order.daySerum && order.dayCream && order.nightSerum && order.nightCream
          ? "Day & Night Set"
          : "";

      return order;
    });
    orderList.sort(this.sortByName);
    return orderList.map((order, index) => {
      order.serialNo = index + 1;
      return order;
    });
  }

  formatAUPackingList(orders, selectedOrderIds) {
    // let selectedOrderIds = this.state.selectedOrderIds;

    if (selectedOrderIds.length > 0) {
      // console.log("selectedOrderIds", selectedOrderIds);
      orders = orders.filter((a) => {
        return selectedOrderIds.includes(a._id);
      });
    }
    orders.sort((a, b) => {
      let aName = a?.shippingAddress?.firstName;
      let bName = b?.shippingAddress?.firstName;
      return aName?.localeCompare(bName);
    });

    let packingList = [];
    for (let i = 0; i < orders.length; i++) {
      let ele = orders[i];
      let items = ele?.items;
      let isFullSet = false;
      //console.log("itesms", items);
      let orderId = ele?.shortId;
      let deliveryDate = momentTimezone(ele.createdAt)
        .tz("Asia/Singapore")
        .format("DD/MM/YYYY");
      let customerInitials =
        ele?.shippingAddress?.firstName + " " + ele?.shippingAddress?.lastName;
      // ?.charAt(0)
      // ?.toUpperCase();
      let instruction = "Box";
      let packageType = this.getPackingType(ele);
      if (packageType.includes("Box")) {
        instruction = "Box";
      } else {
        instruction = "Polymailer";
      }

      let daySerum = this.getProductName(ele, "day serum");
      let nightSerum = this.getProductName(ele, "night serum");
      let dayCream = this.getProductName(ele, "day cream");
      let nightCream = this.getProductName(ele, "night cream");

      if (daySerum) {
        daySerum = daySerum.split("-")[0].trim().toUpperCase();
        instruction += ` | ${daySerum} AM,`;
      }

      if (nightSerum) {
        nightSerum = nightSerum.split("-")[0].trim().toUpperCase();
        instruction += ` | ${nightSerum} PM`;
      }
      if (dayCream) {
        dayCream = dayCream.split("-")[0].trim().toUpperCase();
      }
      if (nightCream) {
        nightCream = nightCream.split("-")[0].trim().toUpperCase();
      }

      let PSet = [daySerum, nightSerum, dayCream, nightCream];
      // console.log("ppppppppp",PSet);
      if (daySerum && nightSerum && dayCream && nightCream) {
        if (ele.orderType == "first-time") {
          let tray = "PPB_Tray_4x30";
          isFullSet = true;
          if (PSet.includes("DOS")) {
            tray = "PPB_Tray_3x30";
          }
          instruction += ` | ${tray}`;
          if (ele.orderType == "first-time") {
            instruction += " | HWCard";
          }
        }
      }

      if (items && items.length > 0) {
        items.map((prod, index) => {
          let productSKU =
            prod?.product?.ssu || this.getProductInitials(prod?.product?.name);
          let quantity = prod?.quantity;
          if (productSKU.toLowerCase() == "mexfo") {
            quantity = 3 * prod?.quantity;
          }

          let product_desc = prod?.product?.name;
          //let recommendationType = prod?.product?.recommendationType;

          let orderList = {
            Delivery_date: deliveryDate,
            customer_reference: orderId,
            Customer_name: customerInitials,
            street: "",
            suburb: "",
            postcode: "",
            state: "",
            value: "",
            sku_code: productSKU,
            description: product_desc,
            unit_of_measure: "unit",
            qty: quantity,
            Instructions: "",
          };
          if (index == 0) {
            orderList["Instructions"] = instruction;
          }
          packingList.push(orderList);
        });
        if (isFullSet) {
          packingList.push({
            Delivery_date: deliveryDate,
            customer_reference: orderId,
            Customer_name: customerInitials,
            street: "",
            suburb: "",
            postcode: "",
            state: "",
            value: "",
            sku_code: "PPB",
            description: "Purple Box",
            unit_of_measure: "unit",
            qty: 1,
            Instructions: "",
          });
        }
      }
    }
    return packingList;
  }

  sortByName(a, b) {
    //console.log( "a.shippingAddress", a);
    var aName = a.shippingName.toLowerCase();
    var bName = b.shippingName.toLowerCase();
    //return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
    return aName.localeCompare(bName);
  }

  handelSKUCount() {
    let activeCountryFilter = this.state.activeCountryFilter;
    let queryString = addQueryParam("country", activeCountryFilter);
    //console.log("country queryString", queryString);
    this.props.getProductWiseCount(queryString);
    this.setState({ showSKUModal: true });
  }

  getProductSKUCount() {
    // console.log( "getProductSKUCount" );
    const productList = this.props.productWiseCount;
    //console.log("getProductSKUCount", productList, productList.length);
    if (!productList || productList.length == 0) {
      return "";
    }

    return (
      <div style={{ width: "250px", height: "300px", display: "inline-block" }}>
        {productList[0]?.result.map((prod, index) => {
          return (
            <div style={{ flex: 1 }} key={index}>
              {" "}
              <span> {prod.product}: </span> {"        "}{" "}
              <span style={{ fontWeight: "bold" }}>{prod.quantity} </span>
            </div>
          );
        })}
      </div>
    );
  }

  getSubscriptionType(order) {
    if (!order) {
      return "";
    }
    if (order.subscriptionType == "no-subscription") {
      if (order.orderType.toLowerCase() === "re-order") {
        return (
          <Tooltip title={order.subscriptionType}>
            <span role="img"> OT RO</span>
          </Tooltip>
        );
      } else if (order.orderType.toLowerCase() === "first-time") {
        return (
          <Tooltip title={order.subscriptionType}>
            <span role="img"> OT FT</span>
          </Tooltip>
        );
      }
    } else if (
      order.subscriptionType.toLowerCase() == "subscription-first-order"
    ) {
      return (
        <Tooltip title={order.subscriptionType}>
          <span role="img"> SUB FT</span>
        </Tooltip>
      );
    } else if (order.subscriptionType.toLowerCase() == "subscription-reorder") {
      return (
        <Tooltip title={order.subscriptionType}>
          <span role="img"> SUB RO</span>
        </Tooltip>
      );
    } else {
    }
  }
  getShippingCountryCode(order) {
    if (!order.shippingAddress) {
      return "";
    }

    const country_list = COUNTRIES_WITH_STATES.filter(
      (country) =>
        country.name.toLowerCase() ===
        order.shippingAddress.country.toLowerCase()
    );
    if (country_list.length > 0) {
      return country_list[0].code;
    } else {
      return "";
    }
  }

  showActiveTab() {
    //console.log("HELLLLo")
    let activeTab = "";
    if (this.state.flaggedStatusFilter == 1) {
      activeTab = "flagged";
      //return "flagged";
    } else if (this.state.flaggedStatusFilter == 2) {
      activeTab = "resolved";
    } else {
      activeTab = `${this.state.activeFulfillMentFilters[0]}`;
    }
    //console.log("activeTab", activeTab);
    return activeTab;
  }

  onTabChange(key) {
    if (key === "flagged" || key === "resolved") {
      let filter =
        key === "flagged"
          ? orderFlaggedStatus[0].status
          : orderFlaggedStatus[1].status;
      this.setState(
        {
          activeFulfillMentFilters: [],
          selectedOrderIds: [],
          fulfillmentErrorStatus: [],
          flaggedStatusFilter: String(filter),
        },
        () => {
          this.handleFlaggedStatusFilter(filter);
        }
      );
    } else {
      this.setState({
        activeFulfillMentFilters: [],
        selectedOrderIds: [],
        flaggedStatusFilter: "",
      });
      this.handleFulfillStatusFilter("fulfillmentStatus", key);
    }
    window.sessionStorage.setItem("selectedItem", JSON.stringify([]));
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const label = `${this.state.checked ? "Checked" : "Unchecked"}-${
      this.state.disabled ? "Disabled" : "Enabled"
    }`;
    const { activeAssessment } = this.props;
    const rowSelection = {
      type: "checkbox",
      selectedRowKeys: this.state.selectedOrderIds,
      onChange: (selectedRowKeys, selectedRows, a) => {
        this.setState({
          selectedOrderIds: selectedRowKeys,
        });
        window.sessionStorage.setItem(
          "selectedItem",
          JSON.stringify(selectedRowKeys)
        );
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    const columns = [
      // {
      //   title: "Order Date",
      //   dataIndex: "submissionDate",
      //   key: "submissionDate",
      //   width: 130,
      // },
      // {
      //   title: "Order Time",
      //   dataIndex: "submissionTime",
      //   key: "submissionTime",
      //   width: 130,
      // },
      {
        title: "Fulfillment",
        dataIndex: "fulfillmentStatus",
        key: "fulfillmentStatus",
        width: 90,
        render: (order) => {
          if (!this.state.showLoader) {
            return order
              ? orderFulfillmentStatusLabel[order.fulfillmentStatus] +
                  " " +
                  orderFullfillmentStatusEmoji[order.fulfillmentStatus]
              : "-";
          } else {
            if (this.state.selectedOrderIds.includes(order.id)) {
              return <Spin />;
            } else {
              return order
                ? orderFulfillmentStatusLabel[order.fulfillmentStatus] +
                    " " +
                    orderFullfillmentStatusEmoji[order.fulfillmentStatus]
                : "-";
            }
          }
        },
      },
      {
        title: "Payment",
        dataIndex: "orderStatus",
        key: "orderStatus",
        width: 70,
        render: (paymentStatus) => {
          if (paymentStatus === 2) {
            return (
              <Tooltip title={paymentStatusMapping[paymentStatus]}>
                <span role="img">
                  {window.navigator.appVersion.indexOf("linux") ? "✔️" : "✅"}
                </span>
              </Tooltip>
            );
          } else if (paymentStatus === 3) {
            return (
              <Tooltip title={paymentStatusMapping[paymentStatus]}>
                <span role="img">
                  {window.navigator.appVersion.indexOf("linux") ? "✅" : "✔️"}
                </span>
              </Tooltip>
            );
          }
        },
      },
      {
        title: "Order Date",
        dataIndex: "submissionDate",
        key: "submissionDate",
        sorter: true,
        width: 100,
      },

      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
        width: 90,
        render: (order) => {
          return (
            <div className="flex">
              <Link
                style={{ color: "#363864" }}
                target="_blank"
                to={`/orders/edit/${order.id}`}
                // onClick={(e) => {
                //   this.props.history.push(`/orders/edit/${order.id}`);
                // }}
              >
                {order.shortId}
              </Link>
              {order?.comments?.length != 0 && (
                <Tooltip title="This order has a message trail">
                  <Icon
                    type="message"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      // {
      //   title: "Name",
      //   dataIndex: "name",
      //   key: "name",
      //   width: 90,
      //   render: (user) => (
      //     <Link
      //       style={{ color: "#373873" }}
      //       to={`/customer/edit/${user.id && user.id.id}`}
      //       target="_blank"
      //     >
      //       {user?.firstName || ""} {user?.lastName || ""}
      //     </Link>
      //   ),
      // },
      {
        title: "Shipping Name",
        dataIndex: "shippingName",
        //sorter: true,
        key: "shippingName",
        width: 90,
        sorter: (a, b) => {
          //console.log( "a.shippingAddress", a);
          var aName = a.shippingName.toLowerCase();
          var bName = b.shippingName.toLowerCase();
          //return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
          return aName.localeCompare(bName);
        },

        // render: (address) =>
        //   (address?.firstName || "") + " " + (address?.lastName || ""),
      },

      {
        title: "Country",
        dataIndex: "country",
        key: "country",
        width: 60,
      },

      {
        title: "Order Notes",
        dataIndex: "notes",
        key: "notes",
        width: 100,
      },
      // {
      //   title: "Items purchased",
      //   dataIndex: "itemsPurchased",
      //   key: "itemsPurchased",
      //   width: 90,
      // },

      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 45,
      },
      {
        title: "Subscription",
        dataIndex: "subscriptionType",
        key: "subscriptionType",
        width: 80,
      },

      // {
      //   title: "Items quantity",
      //   dataIndex: "itemsQuantity",
      //   key: "itemsQuantity",
      //   width: 90,
      // },
      {
        title: "Day Serum",
        dataIndex: "daySerum",
        key: "daySerum",
        width: 60,
      },
      {
        title: "Day Cream",
        dataIndex: "dayCream",
        key: "dayCream",
        width: 60,
      },
      {
        title: "Night Serum",
        dataIndex: "nightSerum",
        key: "nightSerum",
        width: 60,
      },
      {
        title: "Night Cream",
        dataIndex: "nightCream",
        key: "nightCream",
        width: 60,
      },
      {
        title: "SSU",
        dataIndex: "SSU",
        key: "SSU",
        width: 60,
      },
      {
        title: "CFC",
        dataIndex: "CFC",
        key: "CFC",
        width: 60,
      },
      {
        title: "BBT",
        dataIndex: "BBT",
        key: "BBT",
        width: 60,
      },
      {
        title: "ELYES",
        dataIndex: "ELYES",
        key: "ELYES",
        width: 65,
      },
      {
        title: "mEXFO / FBS",
        dataIndex: "CSM_FB",
        key: "CSM_FB",
        width: 65,
      },
      {
        title: "Extras",
        dataIndex: "Extras",
        key: "Extras",
        width: 65,
      },
      {
        title: "Package Type",
        dataIndex: "packageType",
        key: "packageType",
        width: 50,
      },
      {
        title: "PDF",
        dataIndex: "pdfLink",
        key: "pdfLink",
        width: 55,
        render: (pdfLink) => {
          // console.log(" pdfLink=====", order);
          return (
            <div
              className="semibold cursor-pointer invoice-animate"
              style={{
                fontSize: "14px",
                color: "#363874",
                //paddingLeft: "5px",
                //float: "right",
                //marginTop: "-24px",
              }}
            >
              <a href={pdfLink} target="_blank">
                🖨️ PDF{" "}
              </a>
            </div>
          );
        },
      },
      {
        title: "AWB Label",
        dataIndex: "shipmentLabel",
        key: "shipmentLabel",
        width: 48,
        render: (order) => (
          <>
            {order?.shipmentLabelUrl ? (
              <a
                onClick={() => {
                  let filename =
                    order?.user?.firstName +
                    "_" +
                    order?.user?.lastName +
                    "_" +
                    order?.shippingAddress?.country +
                    "_" +
                    order.shortId +
                    ".pdf";

                  handleDownload(order?.shipmentLabelUrl, filename);
                }}
                style={{ color: "#373873" }}
                //href={order.shipmentLabelUrl}
              >
                Label
              </a>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        title: "Flagged Note",
        dataIndex: "flaggedStatus",
        key: "flaggedStatus",
        width: 70,
        render: (order, row) => {
          let comment = row?.orderId?.comments
            ?.slice()
            ?.reverse()
            ?.find((x) => x.flaggedStatus == this.state.flaggedStatusFilter);
          return <div>{comment?.comment}</div>;
        },
      },
      {
        title: " Error",
        dataIndex: "fulfillmentErr",
        key: "fulfillmentErr",
        width: 70,
        render: (order) => {
          return (
            <div style={{ color: "red" }}>
              {order.fulfillmentStatus == -1 ? (
                <Tooltip title={order?.fulfillmentError?.message}>
                  {FULFILLMENT_ERROR_STATUS[
                    order?.fulfillmentError?.errCode
                  ].toLowerCase()}
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
    ].filter((column) => {
      let item = this.state.shownColumns.find(
        (hiddenColKey) => hiddenColKey === column.key
      );
      if (item === "pdfLink" || item === "shipmentLabel") {
        return this.state?.flaggedStatusFilter?.length === 0;
      } else if (item === "flaggedStatus") {
        return this.state?.flaggedStatusFilter?.length !== 0;
      } else {
        return item;
      }
    });
    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });
    headers.filter((head) => head.key !== "action");

    const mappedOrdersList =
      this.props.ordersList.length > 0
        ? this.props.ordersList.map((order, index) => {
            return {
              key: order.id,
              id: order.id,
              name: order.user,
              shippingName:
                order?.shippingAddress?.firstName +
                " " +
                order?.shippingAddress?.lastName,
              orderId: order,
              submissionDate: `${
                order.createdAt
                  ? momentTimezone(order.createdAt)
                      .tz("Asia/Singapore")
                      .format("DD/MM/YYYY hh:mm a")
                  : "-"
              }`,
              shipmentId: order.trackingNumber,
              // fulfillmentStatus: order
              //   ? orderFulfillmentStatusLabel[order.fulfillmentStatus] +
              //     " " +
              //     orderFullfillmentStatusEmoji[order.fulfillmentStatus]
              //   : "-",
              fulfillmentStatus: order,
              orderStatus: order.status,
              paymentStatus: paymentStatusMapping[order.status],
              fulfillmentStatusCode: order ? order.fulfillmentStatus : 0,
              shipmentLabel: order,
              itemsPurchased: this.itemsPurchased(order),
              itemsQuantity: order.items
                ? order.items.map((item) => item.quantity).join(", ")
                : [],
              action: order.id,
              orderType: order && order.orderType,
              type:
                order && order.orderType ? this.getOrderTypeIcon(order) : "",
              subscriptionType:
                order && order.subscriptionType
                  ? this.getSubscriptionType(order)
                  : "",
              country: this.getShippingCountryCode(order),
              notes: order.notes ? order.notes : "",
              daySerum: this.getProductName(order, "day serum"),
              dayCream: this.getProductName(order, "day cream"),
              nightSerum: this.getProductName(order, "night serum"),
              nightCream: this.getProductName(order, "night cream"),
              SSU: this.getEssentials(order, "ssu"),
              CFC: this.getEssentials(order, "CFC"),
              BBT: this.getEssentials(order, "BBT"),
              ELYES: this.getEssentials(order, "ELYES"),
              CSM_FB: this.getFreebie_minis(order),
              Extras: this.getExtraProduct(order),
              status: order && order.status ? order.status : "-",
              trackingDetails: order.trackingDetails,
              packageType: this.getPackingType(order),
              pdfLink: this.getPrinterToolLink(
                order?.items,
                order?.shippingAddress
              ),
              fulfillmentErr: order,
            };
          })
        : [];
    // let csvData = mappedOrdersList.map((item,index) => {
    //   console.log( item.shippingName);
    //   return {
    //     ...item,
    //     name: `${item.shippingName || ""}`,
    //     orderId: item.orderId.shortId,
    //     serialNo:index+1,
    //     letter:item?.shippingName?.charAt(0)?.toUpperCase(),
    //     set:
    //       (item.daySerum &&
    //         item.dayCream &&
    //         item.nightSerum &&
    //         item.nightCream) ||
    //       (item.daySerum && item.dayCream && item.nightSerum && item.CFC)
    //         ? "Day & Night Skincare Set"
    //         : "",
    //   };
    // });

    const exportHeaders = [
      { label: "No.", key: "serialNo" },
      { label: "Letter", key: "letter" },
      { label: "Order ID", key: "orderId" },
      { label: "Name", key: "shippingName" },
      { label: "Set", key: "set" },
      { label: "Order Type", key: "orderType" },
      { label: "OrderNote", key: "orderNote" },
      { label: "Day Serum", key: "daySerum" },
      { label: "Day Cream", key: "dayCream" },
      { label: "Night Serum", key: "nightSerum" },
      { label: "Night Cream", key: "nightCream" },
      { label: "SSU", key: "SSU" },
      { label: "CFC", key: "CFC" },
      { label: "BBT", key: "BBT" },
      { label: "ELYES", key: "ELYES" },
      { label: "CSM_FB", key: "CSM_FB" },
      { label: "Extra", key: "Extras" },
      { label: "Package Type", key: "packageType" },
    ];

    const exportAUPackingListHeaders = [
      { label: "Delivery_date", key: "Delivery_date" },
      { label: "customer_reference", key: "customer_reference" },
      { label: "company_name", key: "Customer_name" },
      { label: "street", key: "street" },
      { label: "suburb", key: "suburb" },
      { label: "postcode", key: "postcode" },
      { label: "state", key: "state" },
      { label: "value", key: "value" },
      { label: "SKU Code", key: "sku_code" },
      { label: "description", key: "description" },
      { label: "unit_of_measure", key: "unit_of_measure" },
      { label: "qty", key: "qty" },
      { label: "Instructions", key: "Instructions" },
    ];

    let countryFilterMapping = getCountryFilterMapping();

    const orderTypeFilterMenu = (
      <Menu
        onClick={(e) => this.handleOrderTypeFilter(e.key)}
        selectedKeys={this.state.activeOrderTypeFilter}
        multiple
        selectable
      >
        {orderTypeFilter &&
          orderTypeFilter.map((type) => {
            return <Menu.Item key={type.key}>{type.label}</Menu.Item>;
          })}
      </Menu>
    );
    const filterByCountryMenu = (
      <Menu
        onClick={(e) => this.handleCountryFilter(e.key)}
        selectedKeys={this.state.activeCountryFilter}
        multiple
        selectable
      >
        {countryFilterMapping &&
          Object.keys(countryFilterMapping).map((key) => {
            return <Menu.Item key={key}>{countryFilterMapping[key]}</Menu.Item>;
          })}
      </Menu>
    );

    const filterProductMenu = (
      <Menu
        onClick={(e) => this.handleProductFilter(e.key)}
        selectedKeys={this.state.activeProductList}
        multiple
        selectable
      >
        {this.state.productList &&
          this.state.productList.map((prod) => {
            return <Menu.Item key={prod._id}>{prod.name}</Menu.Item>;
          })}
        <Menu.Item key={"day_night_4"}>{"Day & Night Set X 4"}</Menu.Item>
        {/* <Menu.Item key={"day_night_3"}>{"Day & Night Set X 3"}</Menu.Item> */}
      </Menu>
    );

    const fulfillmentMenu = (
      <Menu
        selectedKeys={[
          // ...this.state.activeFulfillMentFilters,
          ...this.state.fulfillmentErrorStatus,
        ]}
        multiple
        selectable
      >
        {/* <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={10}
        >
          Ready to Pack <span> {orderFullfillmentStatusEmoji[10]} </span>
        </Menu.Item>
        <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={1}
        >
          Waybill Generated <span> {orderFullfillmentStatusEmoji[1]} </span>
        </Menu.Item>
        <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={2}
        >
          Label Generated <span> {orderFullfillmentStatusEmoji[2]} </span>
        </Menu.Item>
        <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={3}
        >
          Packed <span> {orderFullfillmentStatusEmoji[3]} </span>
        </Menu.Item>
        <Menu.Item
          onClick={(e) =>
            this.handleFulfillStatusFilter("fulfillmentStatus", e.key)
          }
          key={4}
        >
          Shipped <span> {orderFullfillmentStatusEmoji[4]} </span>
        </Menu.Item> */}

        <SubMenu key={6} title="WayBill Failed  ⚠️ ">
          {Object.keys(FULFILLMENT_ERROR_STATUS).map((key) => {
            return (
              <Menu.Item
                onClick={(e) =>
                  this.handleFulfillStatusFilter("fulfillmentError", e.key)
                }
                key={key}
              >
                {FULFILLMENT_ERROR_STATUS[key]}
              </Menu.Item>
            );
          })}
        </SubMenu>
      </Menu>
    );

    const fulfillmentActionMenu = (
      <Menu
        onClick={(e) => this.handleFulfillmentAction(e.key)}
        //selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {this.state.flaggedStatusFilter?.length === 0
          ? FULFILLMENT_ACTION_OPTION.map((option) => {
              return (
                <Menu.Item
                  key={JSON.stringify({ key: option.key, label: option.label })}
                >
                  {option.label}
                </Menu.Item>
              );
            })
          : [
              FULFILLMENT_ACTION_OPTION[FULFILLMENT_ACTION_OPTION.length - 1],
            ].map((option) => {
              return (
                <Menu.Item
                  key={JSON.stringify({ key: option.key, label: option.label })}
                >
                  {option.label}
                </Menu.Item>
              );
            })}
        {localStorage.userType != "packer" && (
          <Menu.Item
            key={JSON.stringify({
              key: "order_delivered",
              label: "Mark Delivered",
            })}
          >
            Mark Delivered 📦
          </Menu.Item>
        )}
        {localStorage.userType != "packer" && (
          <Menu.Item
            key={JSON.stringify({
              key: "regenerate_waybill",
              label: "Regenerate Waybill",
            })}
          >
            Regenerate Waybill
          </Menu.Item>
        )}
      </Menu>
    );

    const recordPerpage = (
      <Menu
        onClick={(e) => this.handlerecordPerpageAction(e.key)}
        selectedKeys={[JSON.stringify(this.state.recordsPerpage)]}
      >
        {RECORD_PER_PAGE.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const advancedSearchMenu = (
      <Menu
        onClick={(e) => this.handleAdvSearchChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {ORDER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    const columnFilterMenu = (
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        className="flex flex-col column-checkbox-wrapper"
      >
        {/* <div onClick={this.handleMenuStopClick} key="3">Close</div> */}
        {this.getFilters().map((item, index) => {
          return (
            <Checkbox
              key={index}
              checked={this.state.shownColumns.find((value) => {
                return value === item;
              })}
              onChange={(e) => this.onColumnFilterChange(e, item)}
            >
              {item
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Checkbox>
          );
        })}
      </div>
    );
    // let questions = [
    //   'How would you best describe your T-Zone in the morning?',
    //   'How would you best describe your cheeks in the morning?',
    //   'What are your top 2 skin concerns?'
    //   ];
    if (activeAssessment && activeAssessment.questionsAnswered) {
      var result = [];
      this.state.questions.forEach((question) => {
        let x = activeAssessment.questionsAnswered.find((questionObject) => {
          return question.includes(questionObject.question.text);
        });
        //console.log({ x });
        if (x) {
          result.push(x);
        }
      });
    }

    const orderColumns = [
      {
        title: "Order ID",
        dataIndex: "order",
        key: "order",
        render: (order) => (
          <a
            className="cursor-pointer"
            style={{ color: "#373873" }}
            href={`/orders/edit/${order.id}`}
            target="_blank"
          >
            {order.shortId}
          </a>
        ),
      },
      {
        title: "products purchased",
        dataIndex: "products",
        key: "products",
      },
      // {
      //   title: "date/time of these purchases ",
      //   dataIndex: "orderDate",
      //   key: "orderDate",
      // },
      {
        title: "payment status",
        dataIndex: "orderStatus",
        key: "orderStatus",
      },
      {
        title: "Order type",
        dataIndex: "OrderType",
        key: "OrderType",
      },
    ];

    return (
      <LoadingOverlay
        active={this.state.showLoader}
        spinner={<Loader />}
        styles={{
          wrapper: {
            width: "100%",
            //height: '100%',
            overflow: true ? "hidden" : "scroll",
          },
        }}
        text="Loading your content..."
      >
        <div className="orders-page-list" style={{ marginLeft: "200px" }}>
          <OrdersHeader
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChangeInput={this.onChangeInput}
            fetchAllOrders={this.props.fetchAllExportOrders}
            //csvData={csvData}
            allHeaders={headers}
            //csvRef={this.csvRef}
            //shoppingExportCsvRef={this.shoppingExportCsvRef}
            ordersList={this.props.ordersList}
            selectedOrderIds={this.state.selectedOrderIds}
            pageName="Ready to Pack"
            pageNo={this.state.pageNo ? this.state.pageNo : 1}
            totalCount={this.props.ordersPageDetails?.totalCount || 0}
            recordsPerPage={this.state.recordsPerpage}
            filter={this.getSelectedQueryForCSV()}
            allowShippingExport="No"
            allowOrderExport="No"
          />

          <div className=" nav-container justify-between">
            <div className="flex"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "15px",
                marginRight: "15px",
              }}
            >
              <RangePicker
                onChange={(val) => {
                  this.SelectDates(val);
                }}
                allowCleaClearClear={true}
                disabledDate={this.disabledDate}
              />
              <Dropdown
                overlay={fulfillmentActionMenu}
                disabled={this.state.selectedOrderIds.length === 0}
              >
                <Button
                  className="dropdown-btn"
                  style={
                    this.state.selectedOrderIds.length !== 0
                      ? {
                          background: "#373873",
                          color: "#fff",
                          height: "32px",
                          marginBottom: "10px",
                        }
                      : { height: "32px", marginBottom: "10px" }
                  }
                >
                  <Badge count={this.state.selectedOrderIds.length}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                  Order Action <Icon type="down" />
                </Button>
              </Dropdown>
              <Dropdown
                overlay={recordPerpage}
                //disabled={this.state.selectedOrderIds.length === 0}
              >
                <Button
                  className="dropdown-btn"
                  style={
                    this.state.selectedOrderIds.length !== 0
                      ? {
                          background: "#373873",
                          color: "#fff",
                          height: "32px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                        }
                      : {
                          height: "32px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                        }
                  }
                >
                  {`Records Per Page - ${this.state.recordsPerpage.key}`}
                  <Icon type="down" />
                </Button>
              </Dropdown>
              {/* <Button
                type="primary"
                className="mr-20"
                onClick={() => {
                  this.handelDownloadLabel();
                }}
                style={{ marginLeft: "10px" }}
                disabled={this.state.selectedOrderIds.length === 0}
              >
                <Badge count={this.state.selectedOrderIds.length}>
                  <a href="#" className="head-example" />
                </Badge>{" "}
                <Icon type="notebook"></Icon>
                📑 Print Labels
              </Button>
              <Button
                type="primary"
                className="mr-20"
                onClick={() => {
                  this.handelDownloadIngredientsSheet();
                }}
                disabled={this.state.selectedOrderIds.length === 0}
              >
                <Badge count={this.state.selectedOrderIds.length}>
                  <a href="#" className="head-example" />
                </Badge>{" "}
                <Icon type="notebook"></Icon>
                🖨️ Print Ing. Sheets
              </Button> */}
              <Button
                type="primary"
                className="mr-20"
                onClick={() => {
                  this.setState({
                    showRequestPickupModal: true,
                    requestPickupTime: "",
                  });
                }}
                disabled={this.state.selectedOrderIds.length === 0}
              >
                <Badge count={this.state.selectedOrderIds.length}>
                  <a href="#" className="head-example" />
                </Badge>{" "}
                <Icon type="notebook"></Icon>
                Request Pickup
              </Button>
            </div>
            <div className="flex"></div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginTop: "15px", marginRight: "15px" }}></div>
              <div style={{ marginTop: "15px", marginLeft: "15px" }}></div>
            </div>
          </div>

          <div
            className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between"
            style={{ padding: "8px" }}
          >
            <Search
              placeholder={`Search by ${this.state.activeAdvSearch.label}`}
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              onKeyUp={(e) => this.handleSearch(e.target.value)}
            />
            <Dropdown overlay={advancedSearchMenu}>
              <Button
                className="dropdown-btn"
                style={{ flex: 1, marginRight: "5px" }}
              >
                {this.state.activeAdvSearch.label === "Name"
                  ? "Advanced Search"
                  : this.state.activeAdvSearch.label}
                <Icon type="down" />
              </Button>
            </Dropdown>
            {countryFilterMapping && (
              <Dropdown overlay={filterByCountryMenu}>
                <Button
                  className={classnames("dropdown-btn", {
                    "filter-selected": this.isCountryStatusApplied(),
                  })}
                  style={{ flex: 1, marginRight: "5px" }}
                >
                  <span
                    style={{ position: "absolute", right: "7px", top: "0px" }}
                  >
                    <Badge count={this.getCountryStatusCount()}>
                      <a href="#" className="head-example" />
                    </Badge>{" "}
                  </span>
                  Country
                  <Icon type="down" />
                </Button>
              </Dropdown>
            )}
            <Dropdown overlay={orderTypeFilterMenu}>
              <Button
                className={classnames("dropdown-btn", {
                  "filter-selected": this.isOrderTypeStatusApplied(),
                })}
                style={{ flex: 1, marginRight: "5px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getOrderTypeStatusCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Order Type
                <Icon type="down" />
              </Button>
            </Dropdown>
            <Dropdown overlay={filterProductMenu}>
              <Button
                className={classnames("dropdown-btn", {
                  "filter-selected": this.isProductStatusApplied(),
                })}
                style={{ flex: 1, marginRight: "5px" }}
              >
                <span
                  style={{ position: "absolute", right: "7px", top: "0px" }}
                >
                  <Badge count={this.getActiveProductCount()}>
                    <a href="#" className="head-example" />
                  </Badge>{" "}
                </span>
                Products
                <Icon type="down" />
              </Button>
            </Dropdown>

            <Button
              onClick={() => {
                window.sessionStorage.setItem(
                  "selectedItem",
                  JSON.stringify([])
                );
                window.sessionStorage.setItem("filters", "");
                window.sessionStorage.setItem("page", "1");
                let activeCountryFilter = [];
                activeCountryFilter = getDefaultCountryFilter();
                this.setState(
                  {
                    activeFulfillMentFilters: ["10"],
                    activeStatusFilters: [],
                    fulfillmentErrorStatus: [],
                    activeCountryFilter: activeCountryFilter.length
                      ? [countryCodeToCountryName[activeCountryFilter[0]]]
                      : [],
                    selectedOrderIds: [],
                    activeProductList: [],
                    selectedOrderIds: [],
                    pageNo: 1,
                  },
                  () => {
                    this.props.fetchAllOrders(
                      this.getSelectedQueryStringFilter()
                    );
                  }
                );
              }}
              // disabled={
              //   this.state.activeFulfillMentFilters.length === 0 &&
              //   this.state.activeStatusFilters.length === 0
              // }
              disabled={this.isClearAllFilterDisabled()}
              className="dropdown-btn"
              style={{ width: "140px", marginRight: "5px" }}
            >
              Clear All Filters
            </Button>
          </div>
          <div>
            <div style={{ background: "white" }}>
              {this.props.ordersPageDetails && (
                <>
                  <Tabs
                    defaultActiveKey={`${this.state.activeFulfillMentFilters[0]}`}
                    onChange={this.onTabChange}
                    activeKey={this.showActiveTab()}
                  >
                    <TabPane
                      tab={`  ${
                        this.state.activeFulfillMentFilters[0] == 10
                          ? this.props.ordersPageDetails.totalCount
                          : ""
                      } Ready to Pack ${orderFullfillmentStatusEmoji[10]}`}
                      key="10"
                    ></TabPane>
                    {/* <TabPane
                      tab={`  ${
                        this.state.activeFulfillMentFilters[0] == 1
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      } Waybill Generated ${orderFullfillmentStatusEmoji[1]}`}
                      key="1"
                    ></TabPane> */}
                    {/* <TabPane
                      tab={` ${
                        this.state.activeFulfillMentFilters[0] == 2
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      } Label Generated ${orderFullfillmentStatusEmoji[2]}`}
                      key="2"
                    ></TabPane> */}
                    <TabPane
                      tab={` ${
                        this.state.activeFulfillMentFilters[0] == 13
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      } Packing ${orderFullfillmentStatusEmoji[13]}`}
                      key="13"
                    ></TabPane>
                    <TabPane
                      tab={` ${
                        this.state.activeFulfillMentFilters[0] == 3
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      } Packed ${orderFullfillmentStatusEmoji[3]}`}
                      key="3"
                    ></TabPane>
                    <TabPane
                      tab={` ${
                        this.state.activeFulfillMentFilters[0] == 4
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      } Shipped ${orderFullfillmentStatusEmoji[4]}`}
                      key="4"
                    ></TabPane>
                    <TabPane
                      tab={` ${
                        this.state.activeFulfillMentFilters[0] == -1
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      } WayBill Failed ${orderFullfillmentStatusEmoji["-1"]}`}
                      key="-1"
                    ></TabPane>
                    <TabPane
                      tab={` ${
                        this.state.flaggedStatusFilter == 1
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      }  ${orderFlaggedStatus[0].label}`}
                      key="flagged"
                    ></TabPane>
                    <TabPane
                      tab={` ${
                        this.state.flaggedStatusFilter == 2
                          ? "(" + this.props.ordersPageDetails.totalCount + ")"
                          : ""
                      }  ${orderFlaggedStatus[1].label}`}
                      key="resolved"
                    ></TabPane>
                  </Tabs>
                  {this.state.activeFulfillMentFilters[0] == 10 && (
                    <>
                      <Button
                        className={""}
                        style={{
                          flex: 1,
                          marginLeft: "10px",
                          marginTop: "10px",
                          position: "absolute",
                          zIndex: "20",
                        }}
                        onClick={() => {
                          this.handelSKUCount();
                        }}
                      >
                        Product count
                        {/* <Icon type="down" /> */}
                      </Button>
                      {localStorage?.userType != "packer" && (
                        <Button
                          onClick={() => {
                            this.setState(
                              { isExportingData: true },
                              function () {
                                this.props
                                  .fetchAllOrders(
                                    this.getSelectedQueryStringFilter() +
                                      "&pageSize=20000"
                                  )
                                  .then((res) => {
                                    const sortedResult = this.formatOrders(
                                      res.result
                                    );
                                    //console.log("sorted result", sortedResult);
                                    this.setState(
                                      {
                                        isExportingData: false,
                                        exportedDataSet: sortedResult,
                                      },
                                      () => {
                                        setTimeout(() => {
                                          this.csvRef.current.link.click();
                                        }, 300);
                                      }
                                    );
                                  });
                              }
                            );
                          }}
                          style={{
                            left: "360px",
                            marginTop: "10px",
                            position: "absolute",
                            zIndex: "20",
                          }}
                        >
                          Export US orders{" "}
                        </Button>
                      )}
                      {localStorage?.userType != "packer" && (
                        <Button
                          onClick={() => {
                            this.setState(
                              { isExportingData: true },
                              function () {
                                let selectedOrderIds =
                                  this.state.selectedOrderIds;
                                this.props
                                  .fetchAllOrders(
                                    this.getSelectedQueryStringFilter() +
                                      "&pageSize=20000"
                                  )
                                  .then((res) => {
                                    const sortedResult =
                                      this.formatAUPackingList(
                                        res.result,
                                        selectedOrderIds
                                      );
                                    //console.log("sorted result", sortedResult);
                                    this.setState(
                                      {
                                        isExportingData: false,
                                        exportedAUPackingDataSet: sortedResult,
                                      },
                                      () => {
                                        setTimeout(() => {
                                          this.csvRefAUPackingList.current.link.click();
                                        }, 300);
                                      }
                                    );
                                  });
                              }
                            );
                          }}
                          style={{
                            left: "512px",
                            marginTop: "10px",
                            position: "absolute",
                            marginRight: "10px",
                            zIndex: "20",
                          }}
                        >
                          Export AU orders{" "}
                        </Button>
                      )}
                      <Button
                        className={""}
                        style={{
                          flex: 1,
                          marginLeft: "441px",
                          marginTop: "10px",
                          position: "absolute",
                          zIndex: "20",
                        }}
                        disabled={this.state.selectedOrderIds.length === 0}
                        onClick={() => {
                          this.handelDownloadLabel();
                        }}
                      >
                        <Badge count={this.state.selectedOrderIds.length}>
                          <a href="#" className="head-example" />
                        </Badge>{" "}
                        <span style={{ padding: "0px 20px" }}>
                          📑 Bulk Labels{" "}
                        </span>
                        {/* <Icon type="down" /> */}
                      </Button>
                      <Button
                        className={""}
                        style={{
                          flex: 1,
                          marginLeft: "621px",
                          marginTop: "10px",
                          position: "absolute",
                          zIndex: "20",
                        }}
                        disabled={this.state.selectedOrderIds.length === 0}
                        onClick={() => {
                          this.handelDownloadIngredientsSheet();
                        }}
                      >
                        <Badge count={this.state.selectedOrderIds.length}>
                          <a href="#" className="head-example" />
                        </Badge>{" "}
                        <span style={{ padding: "0px 20px" }}>
                          🖨️ Bulk Ingredient{" "}
                        </span>
                        {/* <Icon type="down" /> */}
                      </Button>
                      <CSVLink
                        headers={exportHeaders}
                        data={this.state.exportedDataSet}
                        ref={this.csvRef}
                        style={{}}
                        filename="ready-to-pack.csv"
                      />

                      <CSVLink
                        headers={exportAUPackingListHeaders}
                        data={this.state.exportedAUPackingDataSet}
                        ref={this.csvRefAUPackingList}
                        style={{}}
                        filename="au_packing-list.csv"
                      />
                    </>
                  )}

                  {this.state.activeFulfillMentFilters[0] == -1 && (
                    <>
                      {localStorage?.userType != "packer" && (
                        <>
                          <Button
                            onClick={() => {
                              this.setState(
                                { isExportingData: true },
                                function () {
                                  let selectedOrderIds =
                                    this.state.selectedOrderIds;
                                  this.props
                                    .fetchAllOrders(
                                      this.getSelectedQueryStringFilter() +
                                        addQueryParam("user.location.country", [
                                          "Australia",
                                        ]) +
                                        "&pageSize=20000"
                                    )
                                    .then((res) => {
                                      const sortedResult =
                                        this.formatAUPackingList(
                                          res.result,
                                          selectedOrderIds
                                        );
                                      //console.log("sorted result", sortedResult);
                                      this.setState(
                                        {
                                          isExportingData: false,
                                          exportedAUPackingDataSet:
                                            sortedResult,
                                        },
                                        () => {
                                          setTimeout(() => {
                                            this.csvRefAUPackingList.current.link.click();
                                          }, 300);
                                        }
                                      );
                                    });
                                }
                              );
                            }}
                            style={{
                              left: "238px",
                              marginTop: "10px",
                              position: "absolute",
                              marginRight: "10px",
                              zIndex: "20",
                            }}
                          >
                            Export AU orders{" "}
                          </Button>

                          <Button
                            className={""}
                            style={{
                              flex: 1,
                              marginLeft: "178px",
                              marginTop: "10px",
                              position: "absolute",
                              zIndex: "20",
                            }}
                            disabled={this.state.selectedOrderIds.length === 0}
                            onClick={() => {
                              this.handelDownloadIngredientsSheet();
                            }}
                          >
                            <Badge count={this.state.selectedOrderIds.length}>
                              <a href="#" className="head-example" />
                            </Badge>{" "}
                            <span style={{ padding: "0px 20px" }}>
                              🖨️ Bulk Ingredient{" "}
                            </span>
                            {/* <Icon type="down" /> */}
                          </Button>

                          <CSVLink
                            headers={exportAUPackingListHeaders}
                            data={this.state.exportedAUPackingDataSet}
                            ref={this.csvRefAUPackingList}
                            style={{}}
                            filename="au_packing-list.csv"
                          />
                        </>
                      )}
                    </>
                  )}

                  <Table
                    //rowClassName="cursor-pointer woo"
                    rowClassName={(order, index) => {
                      if (
                        order?.orderId?.flaggedStatus == 2 &&
                        order.fulfillmentStatusCode !== 4 &&
                        this.state.activeFulfillMentFilters !== -1
                      ) {
                        return "table-row-green";
                      } else if (order.fulfillmentStatusCode === -1) {
                        return "table-row-error";
                      } else {
                        if (index % 2 === 0) {
                          return "table-row-light";
                        } else {
                          return "table-row-dark";
                        }
                      }
                    }}
                    columns={columns}
                    dataSource={mappedOrdersList}
                    bordered
                    size="small"
                    rowSelection={
                      this.state.flaggedStatusFilter !== "2"
                        ? rowSelection
                        : false
                    }
                    onChange={(pagination, filters, sorter, extra) => {
                      let sortOrder = "asc";
                      let sortField = "";
                      if (sorter.order == "descend") {
                        sortOrder = "desc";
                      }
                      if (sorter.field === "shippingName") {
                        sortField = "firstName";
                      } else if (sorter.field === "submissionDate") {
                        sortField = "createdAt";
                      }

                      this.setState(
                        { sortField: sortField, sortOrder: sortOrder },
                        () => {
                          this.props.fetchAllOrders(
                            this.getSelectedQueryStringFilter("Initial"),
                            pagination.current - 1,
                            undefined,
                            this.state.startDate,
                            this.state.endDate,
                            this.state.activeStatusFilters,
                            this.state.activeFulfillMentFilters
                          );
                        }
                      );
                    }}
                    pagination={{
                      position: "both",
                      current: Number(this.state.pageNo) || 0,
                      total: this.props.ordersPageDetails.totalCount || 0,
                      pageSize: Number(this.state.recordsPerpage.key),
                      onChange: (pageNo) => {
                        this.setState({ pageNo: pageNo });
                        window.sessionStorage.setItem("page", pageNo);
                        this.props.fetchAllOrders(
                          this.getSelectedQueryStringFilter("Initial"),
                          pageNo - 1,
                          undefined,
                          this.state.startDate,
                          this.state.endDate,
                          this.state.activeStatusFilters,
                          this.state.activeFulfillMentFilters
                        );
                      },
                    }}
                    scroll={{ x: 1500 }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <Modal
          title="Request Pickup"
          visible={this.state.showRequestPickupModal}
          okText="Yes"
          onOk={this.handelRequestPickup}
          cancelText="No"
          onCancel={() => {
            this.setState({ showRequestPickupModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            {" "}
            <p>Are you sure you want to proceed? </p>
            [Only for SG 🇸🇬 ] Select a pick up date. Default pick up time sent
            to EasyShip will be 9am to 6pm.{" "}
          </div>
          <DatePicker
            //showTime={{ format: "YYYY-MM-DD" }}
            placeholder="Select Pickup Date"
            disabledDate={(current) => {
              return current && current < moment().endOf("day");
            }}
            onChange={(value, dateString) => {
              this.setState({
                requestPickupTime: dateString,
              });
            }}
            format="YYYY-MM-DD"
            // onOk={(value,dateString) => {
            //   console.log("rr",value, dateString);
            // }}
          />
        </Modal>

        <Modal
          title="SKU Count"
          visible={this.state.showSKUModal}
          okText="Yes"
          cancelText="No"
          onCancel={() => {
            this.setState({ showSKUModal: false });
            this.props.form.resetFields();
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            <div className="" style={{ marginBottom: "10px" }}>
              Date & Time :{" "}
              <span style={{ fontWeight: "bold", marginRight: "80px;" }}>
                {" "}
                {momentTimezone()
                  .tz("Asia/Singapore")
                  .format("DD/MM/YYYY hh:mm a")}{" "}
              </span>
              Total order:{" "}
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {this.props?.productWiseCount[0]?.count}
              </span>
            </div>
            <div className="">
              {this.state.showSKUModal && this.getProductSKUCount()}
            </div>
          </div>
        </Modal>
        <FlaggedModal
          orderId={this.state.selectedOrderIds}
          showFlaggedModal={this.state.showFlaggedModal}
          flaggedStatus={this.state.flaggedStatus}
          updateFlaggedStatus={this.props.updateFlaggedstatuses}
          onClickOk={this.updateFlaggedStatus}
          closeModal={() =>
            this.setState({
              showFlaggedModal: false,
            })
          }
        />
      </LoadingOverlay>
    );
  }
}

function mapStateToProps(state) {
  const { Orders, Assessment } = state;
  return {
    ordersList: Orders.ordersList,
    ordersPageDetails: Orders.ordersPageDetails,
    activeAssessment: Assessment.activeAssessment,
    whatsAppMessageList: Orders.whatsAppMessageList,
    customerOrderList: Orders.customerOrderList,
    productWiseCount: Orders.productWiseCount,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllOrders: bindActionCreators(actions.getOrders, dispatch),
    fetchAllExportOrders: bindActionCreators(actions.exportOrders, dispatch),
    deleteOrder: bindActionCreators(actions.deleteOrder, dispatch),
    updateOrder: bindActionCreators(actions.updateOrder, dispatch),
    createShipment: bindActionCreators(
      fulfillmentActions.createShipment,
      dispatch
    ),
    generateShipmentLabel: bindActionCreators(
      fulfillmentActions.generateShipmentLabel,
      dispatch
    ),
    markShipmentToShipped: bindActionCreators(
      fulfillmentActions.markShipmentToShipped,
      dispatch
    ),
    markShipmentToReadyForPack: bindActionCreators(
      fulfillmentActions.markShipmentToReadyForPack,
      dispatch
    ),
    markShipmentToDelivered: bindActionCreators(
      fulfillmentActions.markShipmentToDelivered,
      dispatch
    ),
    markShipmentToReadyForPackMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToReadyForPackMultiple,
      dispatch
    ),
    markShipmentToDeliveredMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToDeliveredMultiple,
      dispatch
    ),
    markShipmentToShippedMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToShippedMultiple,
      dispatch
    ),
    createShipmentInBulk: bindActionCreators(
      fulfillmentActions.createShipmentInBulk,
      dispatch
    ),
    generateLabelInBulk: bindActionCreators(
      fulfillmentActions.generateLabelInBulk,
      dispatch
    ),
    markShipmentToHoldMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToHoldMultiple,
      dispatch
    ),
    markShipmentToCancelMultiple: bindActionCreators(
      fulfillmentActions.markShipmentToCancelMultiple,
      dispatch
    ),
    markShipmentPackedMultiple: bindActionCreators(
      fulfillmentActions.markShipmentPackedMultiple,
      dispatch
    ),
    markShipmentPackingMultiple: bindActionCreators(
      fulfillmentActions.markShipmentPackingMultiple,
      dispatch
    ),
    reGenerateWaybillMultiple: bindActionCreators(
      fulfillmentActions.reGenerateWaybillMultiple,
      dispatch
    ),
    getAssessmentById: bindActionCreators(
      assessmentActions.getAssessmentById,
      dispatch
    ),
    getLatestAssessmentByUserId: bindActionCreators(
      assessmentActions.getLatestAssessmentByUserId,
      dispatch
    ),
    requestPickup: bindActionCreators(
      fulfillmentActions.requestPickup,
      dispatch
    ),
    getActiveProduct: bindActionCreators(
      fulfillmentActions.getActiveProduct,
      dispatch
    ),
    // getWhatsAppMessage: bindActionCreators(
    //   actions.getWhatsAppMessage,
    //   dispatch
    // ),
    // updateWhatsAppMessage: bindActionCreators(
    //   actions.updateWhatsAppMessage,
    //   dispatch
    // ),
    // updateMultiOrdersWhatsAppMsgStatus: bindActionCreators(
    //   actions.updateMultiOrdersWhatsAppMsgStatus,
    //   dispatch
    // ),
    downloadMultipleInvoice: bindActionCreators(
      actions.downloadMultipleInvoice,
      dispatch
    ),
    downloadMultipleLabels: bindActionCreators(
      actions.downloadMultipleLabels,
      dispatch
    ),
    downloadMultipleIngredients: bindActionCreators(
      actions.downloadMultipleIngredients,
      dispatch
    ),
    getLastOrdersForCustomer: bindActionCreators(
      actions.getLastOrdersForCustomer,
      dispatch
    ),
    updateFlaggedstatuses: bindActionCreators(
      actions.updateFlaggedstatuses,
      dispatch
    ),
    getProductWiseCount: bindActionCreators(
      actions.getProductWiseCount,
      dispatch
    ),
  };
}
const FulfillmentOrdersForm = Form.create({ name: "fulfillment_orders_form" })(
  FulfillmentOrders
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentOrdersForm);
