import ReferralApi from "services/referral";
import { LIST_EXPORT_REFERRALS, LIST_REFERRALS } from "./types";

function handleError(err) {
  console.error(err);
}

export function listReferral(result) {
  return {
    type: LIST_REFERRALS,
    payload: result,
  };
}

export function listExportReferral(result) {
  return {
    type: LIST_EXPORT_REFERRALS,
    payload: result,
  };
}

export const exportReferrals = (payload) => async (dispatch) => {
  try {
    let res = await ReferralApi.getReferrals(payload);
    if (res) {
      //   dispatch(listExportReferral(res.result ? res.result.result : []));
      // dispatch(
      //   setPageDetails({
      //     currentPage: pageNo,
      //     ...res.result,
      //     activeStatusFilters,
      //     activeFulfillMentFilters,
      //   })
      // );
      // return res.result;
    }
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getReferrals = (payload) => async (dispatch) => {
  try {
    let res = await ReferralApi.getReferrals(payload);
    if (res) {
      console.log(res.result);
      dispatch(listReferral(res.result ? res.result : {}));
      // dispatch(
      //   setPageDetails({
      //     currentPage: pageNo,
      //     ...res.result,
      //     activeStatusFilters,
      //     activeFulfillMentFilters,
      //   })
      // );
      return res.result;
    }
  } catch (err) {
    handleError(err);
    return err;
  }
};
