import React, { useMemo } from "react";
import { Modal, Select, Input, Form, Button } from "antd";
import { useDispatch } from "react-redux";
const { Option } = Select;
const SerumTypeModal = ({
  showSerumTypeModal,
  onClickOk,
  closeModal,
  index,
  trackingNumber = "",
  recommendationType = "",
  form,
}) => {
  const dispatch = useDispatch();
  const { getFieldDecorator } = form;
  const recommendationOptions = useMemo(() => ["Night Serum", "Day Serum","Extras"], []);
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        dispatch({
          type: "SET_ACTIVE_ORDER_ITEM_RECOMMENDATION",
          payload: {
            index: index,
            value: values.serumType,
          },
        });
        closeModal();
      }
    });
  };
  return (
    <Modal
      title="Select Serum Recommendation Type"
      visible={showSerumTypeModal}
      okText="Add"
      onCancel={closeModal}
      onOk={onClickOk}
      footer={[
        <Button
          form="serum_recommendation_form"
          className="ant-btn ant-btn-primary"
          key="submit"
          htmlType="submit"
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="serum_recommendation_form" onSubmit={handleSubmit}>
        <Form.Item label="Recommendation Type">
          {getFieldDecorator("serumType", {
            initialValue: null,
            rules: [
              {
                required: false,
                message: "Please select serum type",
              },
            ],
          })(
            <Select placeholder="Select">
              {recommendationOptions.map((serumLabel) => (
                <Option value={serumLabel} key={serumLabel}>
                  {serumLabel}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const serumTypeModal = Form.create({ name: "serum_recommendation_form" })(
  SerumTypeModal
);

export default serumTypeModal;
