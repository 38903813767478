import TagsApi from "services/tags";

function handleError(err) {
  console.error(err);
}

export function setActiveTag(payload) {
  return {
    type: "SET_ACTIVE_TAG",
    payload
  }
};

export function listTags(result) {
  return {
    type: "LIST_TAGS",
    payload: result
  };
}

export function updateTagList(result) {
  return {
    type: "UPDATE_TAG_LIST",
    payload: result
  }
};

export function addTag(result) {
  return {
    type: "ADD_TAG",
    payload: result
  };
}

export const getTag = id => async dispatch => {
  try {
    let res = await TagsApi.getTagById(id);
    dispatch(setActiveTag(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const getTagsList = () => async dispatch => {
   try {
    let res = await TagsApi.getTagsList();
    dispatch(listTags(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getSearchedTags = name => async dispatch => {
  try {
    let res = await TagsApi.getSearchedTags(name);
    dispatch(listTags(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
    return err;
  }
}

export const createTag = (payload) => async dispatch => {
  try {
    let res = await TagsApi.createTag(payload);
    dispatch(addTag(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateTag = (id, payload) => async dispatch => {
  try {
    let res = await TagsApi.updateTagById(id, payload);
    dispatch(updateTagList(res));
    dispatch(setActiveTag(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const deleteTag = id => async dispatch => {
  try {
    let res = await TagsApi.deleteTagById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
}
