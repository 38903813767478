import ProductsApi from "services/products";

function handleError(err) {
  console.error(err);
}

export function setActiveProduct(payload) {
  return {
    type: "SET_ACTIVE_PRODUCT",
    payload
  }
};

export function listProducts(result) {
  return {
    type: "LIST_PRODUCTS",
    payload: result
  };
}

export const getProduct = id => async dispatch => {
  try {
    let res = await ProductsApi.getProductById(id);
    dispatch(setActiveProduct(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const getProductsList = () => async dispatch => {
   try {
    let res = await ProductsApi.getProductsList();
    dispatch(listProducts(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSearchedProducts = (type, name, tags, sort) => async dispatch => {
  try {
    let res = await ProductsApi.getSearchedProducts(type, name, tags, sort);
    dispatch(listProducts(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
}

export const createProduct = (payload) => async dispatch => {
  try {
    let res = await ProductsApi.createProduct(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateProduct = (id, payload) => async dispatch => {
  try {
    let res = await ProductsApi.updateProductById(id, payload);
    dispatch(setActiveProduct(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}
