import React, { useState } from "react";
import { Modal, Input, Form, Button, message } from "antd";
const { TextArea } = Input;

const OrderFlaggedModal = ({
  orderId,
  showFlaggedModal,
  updateFlaggedStatus,
  flaggedStatus,
  onClickOk,
  closeModal,
  form,
}) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    form.validateFields((err, values) => {
      if (!err) {
        updateFlaggedStatus({
          comment: values.message,
          status: flaggedStatus.status,
          orderId: orderId,
        }).then((res) => {
          setLoading(false);
          if (res && res.error) {
            console.log(res.error.message);
            message.error(`Failed Order marked as ${flaggedStatus.label}`);
          } else {
            message.success(`Order marked as ${flaggedStatus.label}`);
            form.resetFields();
            onClickOk();
          }
        }).catch(e => {
          setLoading(false);
          message.error(`Failed to marked as ${flaggedStatus.label}`);
        });
      }
    });
  };

  return (
    <Modal
      title={`${flaggedStatus.modelTitle}`}
      visible={showFlaggedModal}
      okText="Add"
      onCancel={closeModal}
      onOk={onClickOk}
      footer={[
        <Button key="button" htmlType="button" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          form="order_flagging_form"
          className="ant-btn ant-btn-primary"
          key="submit"
          htmlType="submit"
          loading={loading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="order_flagging_form" onSubmit={handleSubmit}>
        <Form.Item label="Note">
          {getFieldDecorator("message", {
            initialValue: "",
            rules: [
              {
                required: true,
                message: "Please enter message",
              },
            ],
          })(<TextArea placeholder="Enter message" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const FlaggedModal = Form.create({ name: "order_flagging_form" })(
  OrderFlaggedModal
);

export default FlaggedModal;
