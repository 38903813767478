import React from "react";

function capitalizeFirstLetter(string) {
  // in props there is no name
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const CustomerAddress = (props) => {
  //console.log("Ayush",props.addressContent);
  return (
    <div className="address-content">
      {props.addressContent && props.addressContent.title && (
        <span className="regular">
          {props.addressContent && props.addressContent.firstName &&
            capitalizeFirstLetter(props.addressContent.firstName) +
              " " +
              capitalizeFirstLetter(props.addressContent.lastName) +
              " "}
          
        </span>
      )}
      {props.addressContent && (
        <address>
          {props.addressContent.apartment && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.apartment
                ? props.addressContent.apartment + " ,"
                : ""}{" "}
            </span>
          )}
          {props.addressContent.street && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.street
                ? props.addressContent.street + " ,"
                : ""}{" "}
            </span>
          )}
          {props.addressContent.addressLine3 && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.addressLine3
                ? props.addressContent.addressLine3 + " ,"
                : ""}{" "}
            </span>
          )}
          {props.addressContent.city && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.city
                ? props.addressContent.city + " ,"
                : ""}{" "}
            </span>
          )}
          {props.addressContent.zipCode && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.zipCode
                ? props.addressContent.zipCode + " ,"
                : ""}{" "}
            </span>
          )}
          {props.addressContent.state && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.state
                ? props.addressContent.state + " ,"
                : ""}{" "}
            </span>
          )}
          {props.addressContent.country && (
            <span
              className={`${props.boldAddress ? "bold-address" : ""} regular`}
            >
              {props.addressContent.country
                ? props.addressContent.country + ""
                : ""}{" "}
            </span>
          )}
        </address>
      )}
    </div>
  );
};
