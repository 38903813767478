import { Api } from "../config/request";
import * as queryString from "query-string";
export const getReferrals = (payload) => {
  let query = queryString.stringify(payload);
  let url = `user/admin/referrals?${query}`;
  return Api.getListRequest(url);
};

const Referrals = {
  getReferrals,
};

export default Referrals;
