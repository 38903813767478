import { Api } from "../config/request";

const getSelfiesList = () => {
  const url = `image-analysis/admin/progress-tracker/list`;
  return Api.getListRequest(url);
};

const Selfies = {
  getSelfiesList,
};

export default Selfies;
