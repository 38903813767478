import { Api } from "../config/request";

export const getCategoryById = id => {
  let url = `catalogue/category/${id}`;
  return Api.getRequest(url);
};

export const getCategoryList = query => {
  let url = `catalogue/category/list`;
  return Api.getListRequest(url);
};

export const getSearchedCategory = (name) => {
  let url = `catalogue/category/search?${name ? 'name='+name : ""}`;
  return Api.getListRequest(url);
}
export const getSearchedIngredients = (type, name, tags) => {
  let url = `catalogue/ingredient/search?${type ? 'type='+type+'&' : ""}${name ? 'name='+name+'&' : ""}${tags ? 'tags='+tags+'&' : ""}`
  return Api.getListRequest(url);
}

export const createCategory = (payload) => {
  let url = `catalogue/category/`;
  return Api.postRequest(url, payload)
}

export const updateCategoryById = (id, payload) => {
  let url = `catalogue/category/${id}`;
  return Api.patchRequest(url, payload);
}

const Category = {
    getCategoryById,
    getCategoryList,
    getSearchedCategory,
    createCategory,
    updateCategoryById
};

export default Category;
