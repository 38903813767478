import { Api } from "../config/request";

export const getAdminById = id => {

  let url = `user/cms-user/${id}`;
  return Api.getRequest(url);
};

// export const getDiscountsList = query => {
//   let url = `user/cms-user/list/`;
//   return Api.getListRequest(url);
// };

export const getSearchedAdmin = (query,pageSize) => {
  let url = `user/cms-user/list?${query ? query : ""}${pageSize ? `&&pageSize=${pageSize}`:''}`;
  return Api.getListRequest(url);
};

export const createAdmin = payload => {
  let url = `user/cms-user/`;
  return Api.postRequest(url, payload);
};

export const updateAdminById = (id, payload) => {
  let url = `user/cms-user/${id}`;
  return Api.patchRequest(url, payload);
};

export const deleteAdminById = id => {
  let url = `user/cms-user/${id}`;
  return Api.deleteRequest(url);
};

const Admin = {
  getAdminById,
  // getDiscountsList,
  getSearchedAdmin,
  createAdmin,
  updateAdminById,
  deleteAdminById
};

export default Admin;
