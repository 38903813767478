import CategoryApi from "services/category";

function handleError(err) {
  console.error(err);
}

export function setActiveCategory(payload) {
  return {
    type: "SET_ACTIVE_CATEGORY",
    payload
  }
};

export function listCategory(result) {
  return {
    type: "LIST_CATEGORY",
    payload: result
  };
};

export const getCategory = id => async dispatch => {
  try {
    let res = await CategoryApi.getCategoryById(id);
    dispatch(setActiveCategory(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const getCategoryList = () => async dispatch => {
   try {
    let res = await CategoryApi.getCategoryList();
    dispatch(listCategory(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSearchedCategory = (name) => async dispatch => {
  try {
    let res = await CategoryApi.getSearchedCategory(name);
    dispatch(listCategory(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
}

export const createCategory = (payload) => async dispatch => {
  try {
    let res = await CategoryApi.createCategory(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateCategory = (id, payload) => async dispatch => {
  try {
    let res = await CategoryApi.updateCategoryById(id, payload);
    dispatch(setActiveCategory(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}
