import React, { Component } from "react";
import "./listDiscount.scss";
import {
  Table,
  Button,
  Icon,
  Input,
  Menu,
  Modal,
  Dropdown,
  message,
  Badge,
  Tooltip,
  InputNumber,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce, isCurrentDate, getSafely } from "utils/helpers";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as actions from "actions/discount";
import SortIcon from "static/img/icon/sortIcon.svg";
import { ORDER_TYPES_MAPPER, PromoCategory } from "utils/constants";
const { Search } = Input;

const { confirm } = Modal;
class listDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      sortOrder: -1,
      activeDiscountTypeFilter: [],
      activeDiscountStatusFilter: [],
      activeCategory: undefined,
      showExportModal: false,
      exportModalLoading: false,
      numberOfRows: 200,
      pageNo: 0,
      activeDiscountType: undefined,
      userType: null,
      activeDiscountStatus: undefined,
      orderBy: undefined,
    };
    this.csvRef = React.createRef();
    this.getDiscount = this.getDiscount.bind(this);
  }
  componentDidMount() {
    //
    this.setState({ userType: localStorage.getItem("userType") });
    // this.props.getSearchedDiscount(`page=${this.state.pageNo}`);
    this.getDiscount(this.state.pageNo);
  }
  onChangeInput = (value) => {
    this.setState({ numberOfRows: value });
  };
  handleDeleteDiscount = (discount) => {
    let payload = {
      isDeleted: true,
    };
    confirm({
      title: `Are you sure you want to delete this Discount?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.updateDiscount(discount.id, payload).then((res) => {
          this.props.getSearchedDiscount(`page=${this.state.pageNo}`);
          if (res) {
            message.success("discount delete successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleDuplicateDiscount = (discount) => {
    let payload = {
      createDuplicate : true
    };
    confirm({
      title: `Are you sure you want to duplicate this Discount?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.updateDiscount(discount.id, payload).then((res) => {
          this.props.getSearchedDiscount(`page=${this.state.pageNo}`);
          //debugger;
          //if (res) {
            message.success("Discount Duplicated successfully");
          // } else {
          //   message.error("Oops! Something went wrong");
          // }
          window.location.reload(true);
        }).catch((e)=> {
          message.error("Oops! Something went wrong");
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.getDiscount(0);
        } else {
          this.getDiscount(0);
        }
      }
    );
  }, 300);

  handleDiscountTypeFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeDiscountTypeFilter.indexOf(value) > -1) {
          newFilterList = state.activeDiscountTypeFilter.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeDiscountTypeFilter, value];
        }
        return {
          activeDiscountTypeFilter: newFilterList,
        };
      },
      () => {}
    );
  };

  handleDiscountStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeDiscountStatusFilter.indexOf(value) > -1) {
          newFilterList = state.activeDiscountStatusFilter.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeDiscountStatusFilter, value];
        }
        return {
          activeDiscountStatusFilter: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeDiscountTypeFilter
          .map((value, index) => {
            if (index === 0) {
              return `isActive=${value}`;
            } else {
              return `&isActive=${value}`;
            }
          })
          .join("");
        const filterQueryFulfillment = this.state.activeDiscountStatusFilter
          .map((value, index) => {
            if (
              index === 0 &&
              this.state.activeDiscountStatusFilter.length === 0
            ) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");
          console.log(filterQueryStatus + filterQueryFulfillment);
        // this.props.getSearchedDiscount(
        //   `page=${this.state.pageNo}&` +
        //     filterQueryStatus +
        //     filterQueryFulfillment,
        //   0
        // );
      }
    );
  };
  handleSort = (value) => {
    this.setState(
      (prevState) => {
        return {
          sortOrder: prevState.sortOrder === "1" ? "-1" : "1",
        };
      },
      () =>
        this.getDiscount(0)
    );
  };
  getDiscountStatus = (startDate, endDate) => {
    return moment().isBetween(moment(startDate), moment(endDate))
      ? "Active"
      : "Expired";
  };
  getDiscountDuration = (startDate, endDate) => {
    const start = isCurrentDate(startDate)
      ? "From Today"
      : moment(startDate).format("DD MMM YYYY");
    const end = isCurrentDate(endDate)
      ? "Till Today"
      : moment(endDate).format("DD MMM YYYY");
    return `${start} - ${end}`;
  };

  getDiscountType = (promotionType) => {
    if (promotionType == "promoCode") {
      return "Promo code";
    } else if (promotionType == "automatic") {
      return "Automatic";
    } else if (promotionType == "buy_x_get_y") {
      return "Free";
    }
  };

  getDiscount(page) {
    const { activeDiscountType, activeDiscountStatus, sortOrder, searchKey, activeCategory } = this.state;
    this.props.getSearchedDiscount({
      page,
      onModel: "CMSUser",
      isActive: activeDiscountType,
      status: activeDiscountStatus,
      orderBy: `createdAt:${sortOrder}`,
      isBulkPromo:false,
      category : activeCategory,
      code: searchKey.length ? searchKey : undefined
    });
    
  }

  render() {
    const columns = [
      {
        title: "Created By Name",
        dataIndex: "name",
        key: "name",
        width: 180,
      },
      // {
      //   title: "Created By Email",
      //   dataIndex: "email",
      //   key: "email",
      //   width: 180,
      // },
      {
        title: "Promo Code",
        dataIndex: "promoCode",
        key: "promoCode",
      },
      {
        title: "Discount Type",
        dataIndex: "discountType",
        key: "discountType",
      },
      {
        title: "Discount Amount",
        dataIndex: "discountAmount",
        key: "discountAmount",
      },
      {
        title: "Discount Description",
        dataIndex: "discountDescription",
        key: "discountDescription",
      },
      {
        title: "Order Type",
        dataIndex: "orderType",
        key: "orderType",
        render: (orderType) => {
          return (
            <div>
              <div>
                {orderType && orderType.oneTime == true ? "One Time" : ""}
              </div>
              <div>
                {orderType && orderType.firstSubscription == true
                  ? "First Subscription"
                  : ""}
              </div>
              <div>
                {orderType && orderType.allSubscription == true
                  ? "All Subscription"
                  : ""}
              </div>
            </div>
          );
        },
      },
      {
        title: "Times Applied",
        dataIndex: "timesUsed",
        key: "timesUsed",
        sorter: (a, b) => a.timesUsed - b.timesUsed,
      },
      {
        title: "Times Redeemed",
        dataIndex: "timesTotalRedeemed",
        key: "timesTotalRedeemed",
        sorter: (a, b) => a.timesTotalRedeemed - b.timesTotalRedeemed,
      },
      {
        title: (
          <Tooltip title="1x1USD + 0.75*(SGD) + 0.6 (NZD+AUD)">
            <span>Sales (USD) </span>
          </Tooltip>
        ),
        dataIndex: "sales",
        key: "sales",
        //sorter: (a, b) => a.order.timesTotalRedeemed - b.timesTotalRedeemed,
      },
      {
        title: "Subscription Orders",
        dataIndex: "timesSubRedeemed",
        key: "timesSubRedeemed",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (discount) => {
          let status = "active";
          if (discount) {
            if (discount.startDate) {
              if (moment(discount.startDate) > moment()) {
                return "upcoming";
              }
              if (discount.endDate) {
                if (moment(discount.endDate) < moment()) {
                  return "expired";
                }
              }
              return status;
            } else {
              return status;
            }
          }
        },
      },

      {
        title: "category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
      },
      {
        title: "Duplicate",
        key: "duplicate",
        width: 100,

        render: (discount) => {
          return (
            <div className="flex">
              <div
                className="duplicate-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleDuplicateDiscount(discount);
                }}
              >
                Duplicate
              </div>
            </div>
          );
        },
      },
      ...(this.state.userType !== "basic"
        ? [
            {
              title: "Action",
              key: "action",
              fixed: "right",
              width: 100,

              render: (discount) => {
                return (
                  <div className="flex">
                    <div
                      className="delete-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.handleDeleteDiscount(discount);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                );
              },
            },
          ]
        : []),
    ];
    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });
    const discountStatusMapping = {
      upcoming: "Upcoming",
      active: "Active",
      expired: "Expired",
    };

    const mappedDiscountList =
      this.props.discountList?.result?.length > 0
        ? this.props.discountList?.result?.map((discount) => {
            return {
              key: discount._id,
              name:
                `${
                  discount?.createdBy?.firstName
                    ? discount?.createdBy?.firstName
                    : ""
                }` +
                `${
                  discount?.createdBy?.lastName
                    ? " " + discount?.createdBy?.lastName
                    : ""
                }`,
              email: `${
                discount?.createdBy?.email ? discount?.createdBy?.email : ""
              }`,
              category: PromoCategory.find((e) => e.key == discount?.category)
                ?.label,
              promoCode: discount?.code,
              discountAmount:
                discount?.percentageValue ||
                getSafely(
                  () =>
                    discount.fixedValue[discount.fixedValue.length - 1].price,
                  "-"
                ),
              discountDescription: discount.description,
              // status: this.getDiscountStatus(discount.startDate, discount.endDate),
              status: discount,
              timesUsed: discount?.timesUsed,
              timesTotalRedeemed: discount?.timesTotalRedeemed,
              timesSubRedeemed: discount?.timesSubRedeemed,
              sales: discount?.orders?.totalAmount
                ? '$'+ Math.round(discount?.orders?.totalAmount)
                : '$' + 0,
              duration: this.getDiscountDuration(
                discount.startDate,
                discount.endDate
              ),
              discountType: this.getDiscountType(discount.promotionType),
              id: discount._id,
              orderType: discount.orderType,
            };
          })
        : [];
    const csvData = mappedDiscountList
      ? mappedDiscountList
          .map((item) => {
            return { ...item };
          })
          .slice(0, this.state.numberOfRows)
      : "";
    const menu = (
      <Menu
        onClick={(e) => {
          if (this.state.activeDiscountType === e.key) {
            this.setState({ activeDiscountType: undefined, pageNo: 0 }, () => {
              this.getDiscount(0);
            });
          } else {
            this.setState({ activeDiscountType: e.key, pageNo: 0 }, () => {
              this.getDiscount(0);
            });
          }
        }}
        selectedKeys={this.state.activeDiscountType}
        selectable
      >
        {/* <Menu.Item key={null}>All</Menu.Item> */}
        <Menu.Item key="true">Activated</Menu.Item>
        <Menu.Item key="false">Deactivated</Menu.Item>
      </Menu>
    );
    const statusMenu = (
      <Menu
        // onClick={(e) => this.handleDiscountStatusFilter("status", e.key)}
        onClick={(e) => {
          if (this.state.activeDiscountStatus === e.key) {
            this.setState({ activeDiscountStatus: undefined, pageNo: 0 }, () => {
              this.getDiscount(0);
            });
          } else {
            this.setState({ activeDiscountStatus: e.key, pageNo: 0 }, () => {
              this.getDiscount(0);
            });
          }
        }}
        selectedKeys={this.state.activeDiscountStatus}
        selectable
      >
        {/* <Menu.Item key={null}>All</Menu.Item> */}
        {/* <Menu.Item key="active">Active</Menu.Item> */}
        <Menu.Item key="upcoming">Upcoming</Menu.Item>
        <Menu.Item key="expired">Expired</Menu.Item>
      </Menu>
    );

    const CategoryMenu = (
      <Menu
        // onClick={(e) => this.handleDiscountStatusFilter("status", e.key)}
        onClick={(e) => {
          if (this.state.activeCategory === e.key) {
            this.setState({ activeCategory: undefined, pageNo: 0 }, () => {
              this.getDiscount(0);
            });
          } else {
            this.setState({ activeCategory: e.key, pageNo: 0 }, () => {
              this.getDiscount(0);
            });
          }
        }}
        selectedKeys={this.state.activeCategory}
        selectable
      >
        {PromoCategory.map((category) => {
          return <Menu.Item key={category.key}>{category.label}</Menu.Item>;
        })}
      </Menu>
    );

    return (
      <div className="discount-page-list" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div className="theme-color semibold text-30 mr-14">Discounts</div>
          {localStorage.getItem("userType") === "admin" && (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                this.setState({ showExportModal: true });
              }}
            >
              <Icon type="export" className="mr-4" />
              <div>Export</div>
            </div>
          )}
        </div>

        <div style={{}}>
          <Link
            type="primary"
            target="_blank"
            color="primary"
            to="/add-discount/add/bulk"
            style={{
              marginRight: "10px",
              backgroundColor: "#363874",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "9.725rem",
              height: "2rem",
              borderRadius: "0.25rem",
              color: "white",
              float: "right",
            }}
          >
            <Icon style={{ marginRight: "0.5rem" }} type="plus"></Icon>
            Add Bulk Discount
          </Link>
        </div>
        <div style={{}}>
          <Link
            type="primary"
            target="_blank"
            color="primary"
            to="/add-discount"
            style={{
              marginRight: "10px",
              backgroundColor: "#363874",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "8.625rem",
              height: "2rem",
              borderRadius: "0.25rem",
              color: "white",
              float: "right",
            }}
          >
            <Icon style={{ marginRight: "0.5rem" }} type="plus"></Icon>
            Add Discount
          </Link>
        </div>

        <div className="flex nav-container justify-between">
          <div className="sub-text-top-left theme-color">All</div>

          {<></>}
        </div>
        <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
          <Search
            placeholder={`Search Promo code`}
            onSearch={(value) => this.handleSearch(value)}
            style={{ width: "70%", marginRight: "5px" }}
            onKeyUp={(e) => this.handleSearch(e.target.value)}
          />
          <Dropdown overlay={menu}>
            <Button
              className={`dropdown-btn ${
                this.state.activeDiscountType !== undefined && "filter-selected"
              }`}
              style={{ flex: 1, marginRight: "5px" }}
            >
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge
                  count={this.state.activeDiscountType !== undefined ? "!" : 0}
                >
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Activation Status <Icon type="down" />
            </Button>
          </Dropdown>

          <Dropdown overlay={CategoryMenu}>
            <Button
              className={`dropdown-btn ${
                this.state.activeCategory !== undefined && "filter-selected"
              }`}
              style={{ flex: 1, marginRight: "5px" }}
            >
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge
                  count={this.state.activeCategory !== undefined ? "!" : 0}
                >
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Discount Category <Icon type="down" />
            </Button>
          </Dropdown>
          <Dropdown overlay={statusMenu}>
            <Button
              className={`dropdown-btn ${
                this.state.activeDiscountStatus !== undefined &&
                "filter-selected"
              }`}
              style={{ flex: 1, marginRight: "5px" }}
            >
              <span style={{ position: "absolute", right: "7px", top: "0px" }}>
                <Badge
                  count={
                    this.state.activeDiscountStatus !== undefined ? "!" : 0
                  }
                >
                  <a href="#" className="head-example" />
                </Badge>{" "}
              </span>
              Discount Status <Icon type="down" />
            </Button>
          </Dropdown>
          <Button
            className="dropdown-btn"
            style={{ display: "flex", alignItems: "center" }}
            onClick={this.handleSort}
          >
            <span className="mr-4">Sort</span>
            <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
          </Button>
          <Button
            onClick={() => {
              this.setState({
                activeDiscountType: undefined,
                activeDiscountStatus: undefined,
                sortOrder: -1,
              });
              this.getDiscount(0);
            }}
            className="dropdown-btn"
            style={{ width: "140px", marginRight: "5px", marginLeft: "5px" }}
          >
            Clear All Filters
          </Button>
        </div>
        <div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedDiscountList}
              bordered
              onRow={(record) => {
                return {
                  onClick: () => {
                    //if (this.state.userType !== "basic") {
                    const win = window.open(
                      `/add-discount/${record.id}`,
                      "_blank"
                    );
                    win.focus();
                    //}
                    return;
                  },
                };
              }}
              size="small"
              scroll={{ x: 1500 }}
              pagination={{
                current: this.state.pageNo,
                total: this.props.discountList.totalCount || 0,
                pageSize: this.state.numberOfRows,
                onChange: (pageNo) => {
                  this.setState({ pageNo: pageNo });
                  this.getDiscount(pageNo - 1);
                },
              }}
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={() => {
            this.setState({ showExportModal: false });
          }}
          onOk={async () => {
            this.setState({ exportModalLoading: true });
            this.setState({
              exportModalLoading: false,
              showExportModal: false,
            });
            this.csvRef.current && this.csvRef.current.link.click();
          }}
        >
          <InputNumber
            min={1}
            defaultValue={this.state.numberOfRows}
            onChange={this.onChangeInput}
          />
        </Modal>
        <CSVLink
          data={csvData}
          headers={headers}
          ref={this.csvRef}
          style={{ display: "none" }}
          filename="discounts.csv"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Discount } = state;
  return {
    discountList: Discount.discountList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSearchedDiscount: bindActionCreators(
      actions.getSearchedDiscount,
      dispatch
    ),
    updateDiscount: bindActionCreators(actions.updateDiscount, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(listDiscount);
