import React, { Component } from "react";
import "./addOrder.scss";
import {
  Icon,
  Select,
  Input,
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  message,
  Tooltip,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/orders";
import * as productActions from "actions/products";
import * as customerActions from "actions/customer";
import * as fulfillmentActions from "actions/fulfillment";
import {
  orderFlaggedStatus,
  ORDER_TYPES,
  paymentStatusMapping,
  SERUM_IDS,
  hideSensitiveDataForRole,
} from "utils/constants";
import CreateAddressForm from "forms/createAddressForm";
import CreatePhoneForm from "forms/createPhoneForm";
import {
  getSafely,
  getPaymentMethod,
  debounce,
  handleDownload,
  capitalizeFirstLetter,
} from "utils/helpers";
import {
  getProductById,
  getProductsList,
  getActiveProductsList,
} from "services/products";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { CustomerAddress } from "../../customerPage/customerAddress/customerAddress";
import {
  orderFullfillmentStatusMapping,
  trackingDetailsCarriers,
  ORDER_TYPES_MAPPER,
  orderFullfillmentStatusListing,
  orderFulfillmentStatusLabel,
  orderFullfillmentStatusEmoji,
} from "utils/constants";
import * as tagsActions from "actions/tags";
import * as assessmentActions from "actions/assessment";
import { ISDCodes } from "forms/createPhoneForm/isdCodes";
import TrackingModal from "components/orders/TrackingModal";
import SerumTypeModal from "components/orders/SerumTypeModal";
import AddProductsModal from "components/orders/AddProductsModal";
import Comments from "components/orders/Comments";
import ErrorBoundary from "components/errorBoundary";
import FlaggedModal from "components/orders/FlaggedModal";
import HistoryBlock from "components/historyBloc/historyBloc";
import { getOrderHistory } from "services/orders/index";
import API from "services/config/apiUrl";
const { Option } = Select;
const { Search } = Input;
const { confirm } = Modal;

class AddOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "create",
      noOfProducts: 4,
      orderType: ORDER_TYPES[0],
      selectedCustomerId: null,
      selectedCustomer: {},
      phoneNumber: {},
      activeProductList: [],
      selectedCustomerAddressId: "",
      productType: {},
      showShippingAddressForm: false,
      showBillingAddressForm: false,
      showPhoneNumberForm: false,
      loading: false,
      productListByCategory: [],
      loadingMarkAs: false,
      showAddProductModal: false,
      showShippingAddressFormOnCreation: false,
      showBillingAddressFormOnCreation: true,
      updateDisabled: false,
      notesEdit: true,
      customerNotesEdit: true,
      orderNotesEdit: true,
      prevNote: "",
      note: "",
      orderNote: "",
      trackingModal: false,
      flaggedStatus: {},
      showFlaggedModal: false,
      showSerumTypeModal: false,
      activeItemIndex: null,
      orderHistoryLogs: [],
      submissionId: "",
    };
    this.commentRef = React.createRef();
    this.closeTrackingModal = this.closeTrackingModal.bind(this);
    this.openTrackingModal = this.openTrackingModal.bind(this);
    this.updateTrackingDetail = this.updateTrackingDetail.bind(this);
    this.handleFlagged = this.handleFlagged.bind(this);
    this.updateFlaggedStatus = this.updateFlaggedStatus.bind(this);
    this.closeSerumTypeModal = this.closeSerumTypeModal.bind(this);
    this.addNewProducts = this.addNewProducts.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;
    if (params.id !== undefined) {
      this.setState({
        mode: "edit",
        activeOrderId: params.id,
        updateDisabled: true,
      });
      this.props.getOrder(params.id).then((res) => {
        if (res) {
          this.setState({
            noOfProducts: res.items.length,
          });
          if (res?.user?.id?.notes) {
            this.setState({
              customerNotesEdit: false,
            });
          }
          if (res?.notes) {
            this.setState({
              orderNotesEdit: false,
            });
          }
          this.props.fetchCustomerAddressList(res.user?.id?.id);
          this.props.getCompletedAssessment(res.user?.id?.id);
          this.props.fetchAllTags();
          this.props.getShipmentByOrderId(params.id);
          this.setState({ selectedCustomerId: res.user?.id?.id });
        } else {
          //this.props.fetchCustomerAddressList(this.state.selectedCustomer.id);
          //this.props.getCompletedAssessment(this.state.selectedCustomer.id);
        }
      });
      this.props.getRefundForOrder(params.id);
      getOrderHistory(params.id).then((res) => {
        if (res) {
          this.setState({ orderHistoryLogs: res.result });
        }
      });
    } else {
      this.props.resetActiveOrder();
    }
    this.props.fetchAllProducts();
    this.props.fetchCustomerList();
  }

  isEditMode() {
    return this.state.mode === "edit";
  }

  handleDeleteOrder = () => {
    if (this.state.activeOrderId) {
      this.props.deleteOrder(this.state.activeOrderId).then((res) => {
        if (res) {
          message.success("Order deleted successfully.");
          this.props.history.goBack();
        } else {
          message.error("Oops! Something went wrong");
        }
      });
    }
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.isEditMode()
          ? this.handleUpdate(values)
          : this.handleCreate(values);
      }
    });
  };

  handleSubmitShippingAddress = () => {
    this.props.form.validateFields((err, values) => {
      console.log(err, values);
      if (!err) {
      }
    });
  };

  handleCreate = (values) => {
    // console.log("CREATE ORDERS", values)
    if (!values.shippingAddress) {
      message.error("Please create a shipping address");
      return;
    }
    if (!values.billingAddress) {
      message.error("Please create a billing address");
      return;
    }
    values["user"] =
      this.state.selectedCustomer._id || JSON.parse(values.user)._id;
    this.setState({ loading: true });
    this.props.updateAddress(values["shippingAddress"], {
      phone: { phone: values.phone, isdCode: values.isdCode },
      user: values["user"],
    });
    delete values["isdCode"];
    delete values["phone"];
    this.props.createOrder(values).then((res) => {
      this.setState({ loading: false });
      if (res && !res.error) {
        message.success("Order Created Successfully!");
        this.props.history.push("/orders");
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  handleUpdate = async (values) => {
    // change products in order
    let flag = 0;
    if (values.items.some((item) => item.newProduct)) {
      const { activeOrder } = this.props;
      let payload = {};
      payload["order"] = activeOrder.id;

      let productObj = values.items.reduce((acc, x, index) => {
        if (x.newProduct) {
          acc[activeOrder.items[index]._id] = {
            id: x.newProduct,
            recommendationType: x.recommendationType,
            itemId: activeOrder.items[index]._id,
          };
        }

        return acc;
      }, {});

      payload["products"] = { ...productObj };
      await this.props.changeProductsInOrder(payload).then((res) => {
        this.setState({ loading: false });
        if (res.error) {
          const errMsg = res.error.message;
          message.error(
            `Oops! Failed to replace products${errMsg ? ": " + errMsg : ""}`
          );
          flag = 1;
        } else if (res) {
          // this.props.history.push("/orders");
        } else {
          message.error("Oops! Failed to replace products.");
        }
      });
    }
    if (flag === 1 && values.fulfillmentStatus !== 5) {
      return;
    }
    const payload = {};
    if (values.shippingAddress)
      payload["shippingAddress"] = values.shippingAddress;
    if (values.billingAddress)
      payload["billingAddress"] = values.billingAddress;
    // if (
    //   values.trackingDetails &&
    //   values.trackingDetails.number &&
    //   values.trackingDetails.carrier
    // )
    //payload["trackingDetails"] = values.trackingDetails;
    // if (values.fulfillmentStatus || values.fulfillmentStatus === 0)
    //   payload["fulfillmentStatus"] = values.fulfillmentStatus;
    if (values.notes) payload["notes"] = values.notes;
    if (values.tags) payload["tags"] = values.tags;
    this.props.updateOrder(this.state.activeOrderId, payload).then((res) => {
      this.setState({ loading: false });
      if (res && res.error) {
        message.error(
          typeof res.error.message === "string"
            ? res.error.message
            : res.error.internalMessage
        );
      } else {
        message.success("Order Updated Successfully!");
      }
    });
  };

  getAddressStr = (address) => {
    return `${address.apartment || ""}, ${address.street || ""}, ${
      address.city || ""
    },
      ${address.state || ""}, ${address.country || ""}`;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCustomerId !== this.state.selectedCustomerId) {
      this.props
        .fetchCustomerAddressList(this.state.selectedCustomerId)
        .then((res) => {
          if (res && res.result) {
            // set both on address list update
            this.props.form.setFieldsValue({
              shippingAddress:
                this.state.selectedCustomer &&
                this.state.selectedCustomer.primaryAddress
                  ? this.state.selectedCustomer.primaryAddress.id
                  : undefined,
            });
            this.props.form.setFieldsValue({
              billingAddress:
                this.state.selectedCustomer &&
                this.state.selectedCustomer.primaryAddress
                  ? this.state.selectedCustomer.primaryAddress.id
                  : undefined,
            });
            this.props.form.setFieldsValue({
              isdCode:
                this.state.selectedCustomer &&
                this.state.selectedCustomer.primaryAddress &&
                this.state.selectedCustomer.primaryAddress.phone
                  ? this.state.selectedCustomer.primaryAddress.phone.isdCode
                  : this.state.phoneNumber && this.state.phoneNumber.isdCode,
            });
            this.props.form.setFieldsValue({
              phone:
                this.state.selectedCustomer &&
                this.state.selectedCustomer.primaryAddress &&
                this.state.selectedCustomer.primaryAddress.phone
                  ? this.state.selectedCustomer.primaryAddress.phone.phone
                  : this.state.phoneNumber && this.state.phoneNumber.phone,
            });
            if (res.result.length === 0) {
              this.setState({ showShippingAddressFormOnCreation: true });
            } else {
              this.setState({
                showShippingAddressFormOnCreation: false,
              });
            }
          }
        });
    }
  }

  handleDownloadInvoice = (id) => {
    if (id) window.open(`/invoice?orders=${id}`, "_blank");
  };

  handleFetchProductsByCategory = async (productId) => {
    try {
      this.setState({
        productListByCategory: [],
      });
      const productObj = await getProductById(productId);
      const productCategoryId = productObj.category.id;
      // Commenting the querry condition
      // const productListByCategory = await getProductsList(
      //   `category.id=${productCategoryId}`
      // );
      const productListByCategory = await getActiveProductsList();
      const { activeOrder } = this.props;
      let itemsInCategory = [...productListByCategory];
      itemsInCategory = itemsInCategory.filter(function (item) {
        // commenting out so that we do not filter the products
        // for (var activeOrderItem of activeOrder.items) {
        //   if (item.id === activeOrderItem.product._id) return false;
        // }
        return true;
      });

      this.setState({
        productListByCategory: itemsInCategory,
      });
    } catch (err) {
      message.error("Oops! Something went wrong while fetching products list");
    }
  };

  getOrderType(order) {
    if (order && order.isSubscribed) {
      if (
        order &&
        order.subscriptionId &&
        order.subscriptionId.period &&
        order.subscriptionId.period.value
      ) {
        if (order.subscriptionId.period.value / 30 > 1) {
          return `Subscription (${
            order.subscriptionId.period.value / 30
          } Months)`;
        }
        return "Subscription (1 Month)";
      }
      return "Subscription";
    }
    return "One-time";
  }

  getFulfillmentStatus(order) {
    if (order) {
      let orderStatus =
        orderFulfillmentStatusLabel[order.fulfillmentStatus] +
        " " +
        orderFullfillmentStatusEmoji[order.fulfillmentStatus];

      //return orderStatus;
      return (
        <Tooltip
          title={
            order && order.fulfillmentStatus == -1
              ? order.fulfillmentError?.message
              : ""
          }
        >
          <p>
            <span className="failed-reason">{orderStatus}</span>
          </p>
        </Tooltip>
      );
    }
  }

  getOrderPaymentMethod(order) {
    return getPaymentMethod(order?.paymentMethod);
  }

  // Tracking methods start here
  openTrackingModal() {
    this.setState({
      trackingModal: true,
    });
  }

  closeTrackingModal() {
    this.setState({
      trackingModal: false,
    });
  }

  closeSerumTypeModal() {
    this.setState({
      showSerumTypeModal: false,
    });
  }

  addNewProducts(payload) {
    this.props.addProductsInOrder(payload).then((res) => {
      if (!res || res.error) {
        message.error("Oops! Something went wrong");
      } else {
        this.componentDidMount();
        message.success("product added successfully.");
      }
    });
  }

  updateTrackingDetail(values) {
    if (this.props.activeOrder.trackingNumber) {
      this.props.updateTrackingDetail(
        {
          orderId: this.props.match.params.id,
          ...values,
        },
        () => {
          this.closeTrackingModal();
          this.componentDidMount();
        }
      );
    } else {
      this.props.addTrackingDetail(
        {
          orderId: this.props.match.params.id,
          ...values,
        },
        () => {
          this.closeTrackingModal();
          this.componentDidMount();
        }
      );
    }
  }
  // Tracking methods end here

  isShopifyOrder(order) {
    if (order) {
      return !order?.tags.some((x) => x.name === "shopify");
    }
    return true;
  }

  textTruncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str?.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  getProductRecommendedType(product, productArray) {
    let productCategory = product?.product?.category?.name;
    let recommendationType = productCategory;
    productCategory = productCategory.toLowerCase();
    let ProductName = product?.product?.name?.toLowerCase();

    if (productCategory.includes("face")) {
      //console.log( "XXXXXXXXXX" )
      if (ProductName === "drop of light") {
        recommendationType = "Day Serum";
        return recommendationType;
      }
      if (ProductName === "drop of youth") {
        recommendationType = "Night Serum";
        return recommendationType;
      } else {
        if (ProductName === "drop of softness") {
          if (!productArray?.includes("drop of light")) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night Serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of balance") {
          if (
            !(
              productArray?.includes("drop of light") ||
              productArray?.includes("drop of softness")
            )
          ) {
            recommendationType = "Day Serum";
            return recommendationType;
          } else {
            recommendationType = "Night serum";
            return recommendationType;
          }
        }

        if (ProductName === "drop of perfection") {
          if (!productArray?.includes("drop of youth")) {
            recommendationType = "Night Serum";
            return recommendationType;
          } else {
            recommendationType = "Day Serum";
            return recommendationType;
          }
        }
      }
    }

    return recommendationType;
  }

  getPrinterToolLink(products, shippingAddress) {
    //console.log("======products", products, user);
    let day_routine = [];
    let night_routine = [];
    let day_serum = "";
    let day_cream = "";
    let night_serum = "";
    let night_cream = "";
    let productNameArray = products?.map((p) => p.product.name.toLowerCase());
    if (products) {
      products.forEach((prod) => {
        let recommendationType = prod?.recommendationType
          ?.toLowerCase()
          ?.trim();
        //console.log("HHHH", recommendationType,prod);;
        if (!recommendationType) {
          recommendationType = this.getProductRecommendedType(
            prod,
            productNameArray
          );
          recommendationType = recommendationType?.toLowerCase()?.trim();
          //console.log("recommendationType", recommendationType);
        }
        if (
          recommendationType?.includes("day") ||
          recommendationType?.includes("moisturiser")
        ) {
          if (day_routine.length <= 2) {
            if (recommendationType == "day serum") {
              day_serum = prod.product._id;
              console.log("day serum ", prod.product.name);
            } else if (
              recommendationType == "moisturiser" ||
              recommendationType == "day cream"
            ) {
              console.log("day cream ", prod.product.name);
              day_cream = prod.product._id;
            }
            //day_routine.push(prod.product._id);
          }
        }
        if (recommendationType?.includes("night")) {
          if (night_routine.length <= 2) {
            if (recommendationType == "night cream") {
              night_cream = prod.product._id;
              console.log("night cream ", prod.product.name);
            } else if (recommendationType == "night serum") {
              night_serum = prod.product._id;
              console.log("night serum ", prod.product.name);
            }
            //night_routine.push(prod.product._id);
          }
        }
      });
    }
    day_routine = [day_serum, day_cream];
    night_routine = [night_serum, night_cream];
    let day_routine_str = day_routine.join(",");
    //let day_routine_str =day_serum +  day_cream;
    let night_routine_str = night_routine.join(",");

    let name =
      capitalizeFirstLetter(shippingAddress?.firstName) +
      " " +
      capitalizeFirstLetter(shippingAddress?.lastName);

    let url = `http://tools.yours.sg/mini-lfy.php?day_routine=${day_routine_str}&night_routine=${night_routine_str}&name=${name}`;
    //console.log("==== ", url);
    return url;
  }

  handleFlagged(status) {
    this.setState({
      flaggedStatus: status,
      showFlaggedModal: true,
    });
  }

  updateFlaggedStatus() {
    this.setState({
      showFlaggedModal: false,
      flaggedStatus: {},
    });
    this.props.getOrder(this.props?.match?.params?.id);
    this.commentRef.current.updateComments();
  }

  setPhoneNumber(addressId) {
    let phone = {};
    let selectedCustomerAddressId = addressId;
    //let phoneCode;
    if (this.props.customerAddressList.length > 0) {
      phone = this.props.customerAddressList?.find(
        (e) => "" + e._id == "" + addressId
      )?.phone;
    }

    this.setState({
      phoneNumber: phone,
      selectedCustomerAddressId: selectedCustomerAddressId,
    });
    this.props.form.setFieldsValue({
      shippingAddress: selectedCustomerAddressId,
      billingAddress: selectedCustomerAddressId,
      phone: phone?.phone,
      isdCode: phone?.isdCode,
    });
  }

  handleSearch = debounce(async (value) => {
    //console.log(value);
    let query = `email=${value}`;
    this.props
      .fetchCustomerList(query)
      .then((res) => {
        if (res) {
          const userData = res[0];
          let phone = {};
          let selectedCustomerAddressId = "";
          if (this.props.customerAddressList.length > 0) {
            phone = this.props.customerAddressList[0].phone;
            selectedCustomerAddressId = this.props.customerAddressList[0]._id;
          }

          setTimeout(() => {
            this.props.form.setFieldsValue({
              type: userData?.isInfluencer ? "influencer" : "",
              appeasement_reason: "Influencer",
              shippingAddress: selectedCustomerAddressId,
              billingAddress: selectedCustomerAddressId,
              phone: phone?.phone,
              isdCode: phone?.isdCode,
              user: JSON.stringify(userData),
            });
          }, 500);

          let productList = [];
          if (userData) {
            this.props.getLatestResultForUser(userData?._id).then((result) => {
              let products = result?.result?.products;

              if (products) {
                products.forEach((prod) => {
                  productList.push({
                    product: prod.product,
                    recommendationType: prod.recommendationType,
                  });
                });
              }

              this.props.allProducts.forEach((prod) => {
                if (
                  prod.ssu.toLowerCase() === "ssu" ||
                  prod.ssu.toLowerCase() == "cfc"
                ) {
                  productList.push({
                    product: prod.id,
                    recommendationType: prod.category?.name,
                  });
                }
              });

              this.setState({
                submissionId: result?.result?.attempt,
                orderType: userData?.isInfluencer ? "influencer" : "",
                noOfProducts: productList.length,
                activeProductList: productList,
                selectedCustomerId: userData?._id,
                selectedCustomer: userData,
                phoneNumber: phone,
                selectedCustomerAddressId:
                  this.props.customerAddressList.length > 0
                    ? this.props.customerAddressList[0]._id
                    : "",
              });
            });
          }
        }
      })
      .catch((err) => {});
  });

  getTrackingStatus(activeOrder, status) {
    if (activeOrder && activeOrder.fulfillmentStatus) {
      if (status == 4) {
        const fulfillmentShippedData = activeOrder?.fulfillmentEvents?.find(
          (e) => e.status == 4
        );
        let daysToShipped = "";
        let shippedDate = "";
        if (fulfillmentShippedData) {
          shippedDate = moment(fulfillmentShippedData?.createdTime);
          //console.log("shippedDate", shippedDate);
          let orderCreatedAt = moment(activeOrder.createdAt);
          //console.log("orderCreatedAt", orderCreatedAt);
          daysToShipped = shippedDate.diff(orderCreatedAt, "days");
          shippedDate = momentTimezone(shippedDate)
            .tz("Asia/Singapore")
            .format("MMMM DD, YYYY [at] HH:MM A");
        }

        if (shippedDate) {
          return (
            <>
              {" "}
              <span style={{ color: "#373873" }}>{shippedDate} SGT </span> Total
              time to ship the order:{" "}
              <span style={{ color: "#373873" }}> {daysToShipped} Days </span>{" "}
            </>
          );
        } else {
          return (
            <>
              {" "}
              <span style={{ color: "#373873" }}>{shippedDate} SGT </span>
            </>
          );
        }
      } else if (status == 5) {
        const fulfillmentShippedData = activeOrder?.fulfillmentEvents?.find(
          (e) => e.status == 4
        );
        const fulfillmentDeliveredData = activeOrder?.fulfillmentEvents?.find(
          (e) => e.status == 5
        );
        let daysDeliver = "";
        let deliveredDate = "";
        if (fulfillmentShippedData && fulfillmentDeliveredData) {
          deliveredDate = moment(fulfillmentDeliveredData?.createdTime);
          let shippedDate = moment(fulfillmentShippedData?.createdTime);
          //let orderCreatedAt = moment(activeOrder.createdAt);
          daysDeliver = deliveredDate.diff(shippedDate, "days");
          deliveredDate = momentTimezone(deliveredDate)
            .tz("Asia/Singapore")
            .format("MMMM DD, YYYY [at] HH:MM A");
        }

        if (deliveredDate) {
          return (
            <>
              <span style={{ color: "#373873" }}>{deliveredDate} SGT </span>{" "}
              Total time to ship the order :{" "}
              <span style={{ color: "#373873" }}> {daysDeliver} Days </span>{" "}
            </>
          );
        } else {
          return (
            <>
              <span style={{ color: "#373873" }}>
                orderFullfillmentStatusListing[activeOrder.fulfillmentStatus]{" "}
              </span>
            </>
          );
        }
      } else {
        return "";
      }
    }
  }

  getTrackingInfo(activeOrder, courierService) {
    let trackingNo = 0;
    let trackingUrl = 0;
    if (activeOrder && activeOrder.trackingNumber) {
      trackingNo = activeOrder.trackingNumber;
      if (courierService == "DHL") {
        return (
          <a
            style={{ color: "#373873" }}
            target="_blank"
            href={`https://www.dhl.com/en/express/tracking.html?AWB=${trackingNo}&brand=DHL`}
          >
            {trackingNo}
          </a>
        );
      } else {
        return (
          <a
            style={{ color: "#373873" }}
            target="_blank"
            href={`https://www.trackmyshipment.co/shipment-tracking/${trackingNo}`}
          >
            {trackingNo}
          </a>
        );
      }
      // if (activeOrder.shippingCountry == "singapore") {
      //   return (
      //     <a
      //       style={{ color: "#373873" }}
      //       target="_blank"
      //       href={`https://www.trackmyshipment.co/shipment-tracking/${trackingNo}`}
      //     >
      //       {trackingNo}
      //     </a>
      //   );
      // } else if (activeOrder.shippingCountry == "united states") {
      //   return (
      //     <a
      //       style={{ color: "#373873" }}
      //       target="_blank"
      //       href={`https://www.trackmyshipment.co/shipment-tracking/${trackingNo}`}
      //     >
      //       {trackingNo}
      //     </a>
      //   );
      // } else {
      //   return (
      //     <a
      //       style={{ color: "#373873" }}
      //       target="_blank"
      //       href={`https://www.dhl.com/en/express/tracking.html?AWB=${trackingNo}&brand=DHL`}
      //     >
      //       {trackingNo}
      //     </a>
      //   );
      // }
    }
    return "";
  }

  getPromoCode(activeOrder) {
    //activeDiscount;

    if (activeOrder && activeOrder.promotion && activeOrder.promotion.code) {
      let promoLink = `/add-discount/${activeOrder.promotion._id}`;
      if (activeOrder.promotion.isBulkPromo == true) {
        promoLink = `/add-discount/edit/bulk/${activeOrder.promotion.promoConfig}`;
      }
      return (
        <div
          style={{
            color: "#363874",
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
            display: "block",
          }}
          onClick={() => {
            // window.location.href = promoLink;
            window.open(promoLink, "_blank");
          }}
        >
          {" "}
          ({activeOrder.promotion.code}){" "}
        </div>
      );
    }

    return "";
  }

  updateTrackingLabel(activeOrder, val) {
    if (!val) {
      return;
    }
    const orderId = activeOrder?._id;
    const fulfillmentStatus = activeOrder?.fulfillmentStatus;
    let payload = {
      courier: val,
      orderId: orderId,
    };
    if (["0", "10", "-1"].includes("" + fulfillmentStatus)) {
      confirm({
        title: `Are you sure? You want to update this Label.`,
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          return this.props
            .updateShipmentLabel(orderId, payload)
            .then((res) => {
              if (!res || res.error) {
                message.error("Oops! Something went wrong");
              } else {
                message.success("Label  updated successfully.");
                setTimeout(() => {
                  this.props
                    .getOrder(this.props?.match?.params?.id)
                    .then((result) => {
                      message.success("Label  updated successfully.");
                    });
                }, 500);
              }
            });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      message.error("Invalid Fullfillment Status ");
    }
  }

  removeProduct(activeOrder, index) {
    //console.log("itemssss");
    //console.log("ITEM+++=", activeOrder.items[index], activeOrder.items[index].product.id);
    const payload = {
      orderId: activeOrder._id,
      product: activeOrder.items[index].product.id,
    };
    let allowedFullfillment = [0, 10, 11, -1];
    if (!allowedFullfillment.includes(activeOrder.fulfillmentStatus)) {
      message.success(
        "Not allowed. As order is either shipped or not valid to update "
      );
      return;
    }

    confirm({
      title: `Are you sure? You want to remove this product.`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.removeProductsFromOrder(payload).then((res) => {
          if (!res || res.error) {
            message.error("Oops! Something went wrong");
          } else {
            message.success("product removed successfully.");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  // getPrinterToolLink(products , user){

  //    let day_routine = [];
  //    let night_routine =[]
  //   if(products){
  //     products.forEach((prod) => {

  //       if (prod?.recommendationType?.toLowerCase().includes("day") || prod?.recommendationType?.toLowerCase().includes("moisturiser")) {
  //         day_routine.push(prod.product._id);

  //       }
  //       if (prod?.recommendationType?.toLowerCase().includes("night")) {
  //         night_routine.push(prod.product._id);
  //       }
  //     });
  //   }

  //   let day_routine_str = day_routine.join(",");
  //   let night_routine_str = night_routine.join(",");

  //   let name =user?.firstName + " " + user?.lastName;

  //   return `http://tools.yours.sg/mini-lfy.php?day_routine=${day_routine_str}&night_routine=${night_routine_str}&name=${name}`;

  // }

  getTotalChargePrice() {
    let tax = 0;
    let total = 0;
    if (this.props?.activeOrder?.tax_details?.tax_amount) {
      tax = this.props?.activeOrder?.tax_details?.tax_amount / 100;
    }
    if (this.props?.activeOrder?.chargedPrice) {
      total = this.props?.activeOrder?.chargedPrice;
    }
    return tax + total;
  }

  render() {
    const { getFieldDecorator, setFieldValue } = this.props.form;
    const {
      allProducts,
      customerList,
      customerAddressList,
      activeOrder,
      shipmentInfo,
      assessment,
    } = this.props;
    const { productListByCategory } = this.state;
    let discountCode =
      activeOrder &&
      (activeOrder.promoCode || activeOrder.subscriptionDiscount == 0)
        ? Number(activeOrder.totalPrice) -
          activeOrder.chargedPrice -
          activeOrder.fullSetDiscount +
          activeOrder.shipping
        : 0;
    let fullSetDiscount = this.props.activeOrder
      ? this.props.activeOrder.fullSetDiscount
      : 0;
    //let promoDiscount = this.props.activeOrder? this.props.activeOrder.promoDiscount : 0;
    let totalDiscount = discountCode + fullSetDiscount;
    let phoneNumber =
      this.props.activeOrder && this.props.activeOrder.shippingAddress
        ? this.props.activeOrder.shippingAddress?.phone
        : null;
    let totalAmountRefunded = this.props.refund.length
      ? this.props.refund.reduce((a, b) => a + (b.totalAmount.value || 0), 0)
      : 0;
    let maxRefundAmount = activeOrder
      ? activeOrder.chargedPrice - totalAmountRefunded
      : 0;

    let assessmentId = assessment?.id;

    console.log(
      "this.props.activeOrder.items",
      this?.props?.activeOrder?.items
    );

    return (
      <div className="orders-page" style={{ marginLeft: "200px" }}>
        <div className="flex justify-between w-full mb-4 items-center">
          <Link
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <div
              className="flex justify-start items-center cursor-pointer"
              onClick={this.props.toggleIngredientsState}
            >
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="text-14 pb-2">Back</div>
            </div>
          </Link>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
            disabled={this.state.updateDisabled}
          >
            {this.isEditMode() ? "Update" : "Create"}
          </Button>
        </div>

        <div className="flex mb-20">
          <div className="theme-color semibold text-30 mr-10">
            {this.isEditMode() && this.props.activeOrder
              ? "#" + this.props.activeOrder.shortId
              : ""}
          </div>

          {this.isEditMode() && (
            <div className="flex items-center">
              {activeOrder &&
                moment(activeOrder.createdAt).format(
                  "MMMM DD, YYYY [at] HH:MM A"
                )}
            </div>
          )}
        </div>

        <div className="flex mb-14 justify-between">
          <div className="flex mb-14 items-center">
            {this.isEditMode() && (
              <div
                className="flex mr-26 cursor-pointer"
                onClick={() =>
                  this.handleDownloadInvoice(activeOrder ? activeOrder.id : "")
                }
              >
                <Icon type="printer" className="mr-6"></Icon>
                <p>Print</p>
              </div>
            )}

            {this.isEditMode() &&
            this.isShopifyOrder(this.props.activeOrder) &&
            this.props.activeOrder &&
            this.props.activeOrder.status === 2 &&
            this.props.activeOrder.type === "default" &&
            maxRefundAmount > 0 ? (
              <div
                className="flex mr-26 cursor-pointer"
                onClick={() =>
                  this.props.history.push({
                    pathname: `/orders/refund/${this.props.match.params.id}`,
                    state: { maxRefundAmount: maxRefundAmount.toFixed(2) },
                  })
                }
              >
                <Icon type="reload" className="mr-6"></Icon>
                <p>Refund</p>
              </div>
            ) : null}

            {this.props.activeOrder &&
            this.props.activeOrder.status === -2 &&
            this.props.activeOrder.orderPaymentFailReason ? (
              <div className="flex">
                <Tooltip
                  title={this.props.activeOrder.orderPaymentFailReason.toLowerCase()}
                >
                  <p>
                    <span className="failed-reason">
                      {this.props.activeOrder.orderPaymentFailReason.length <=
                      24
                        ? this.props.activeOrder.orderPaymentFailReason.toLowerCase()
                        : this.props.activeOrder.orderPaymentFailReason
                            .toLowerCase()
                            .substr(0, 24) + "..."}
                    </span>
                  </p>
                </Tooltip>
              </div>
            ) : (
              ""
            )}

            {this.isEditMode() &&
            this.props.activeOrder &&
            (this.props.activeOrder.status === 2 ||
              this.props.activeOrder.status === 3 ||
              this.props.activeOrder.status === 4) &&
            this.props.activeOrder.type === "default" ? (
              <div className="flex mr-26">
                {/* <Icon type="appstore" className="mr-6"></Icon> */}
                <p className="refunded-pill">
                  {/* {this.props.refund.length <= 0
                    ? "Not Refunded"
                    : maxRefundAmount <= 0
                    ? "Fully Refunded"
                    : "Partially Refunded"} */}
                  {this.props.refund.length <= 0
                    ? "Not Refunded"
                    : activeOrder && paymentStatusMapping[activeOrder.status]}
                </p>
              </div>
            ) : null}
            {this.props.activeOrder &&
              this.props.activeOrder.goGreenPackaging && (
                <div className="flex">
                  <span className="go-green" role="img" aria-label="go-green">
                    🌍 {"  "}♻️
                  </span>
                </div>
              )}
          </div>
          <div className="flex ml-26 mb-16">
            {activeOrder?.flaggedStatus === 1 ? (
              <Button
                type="primary"
                htmlType="button"
                onClick={() => this.handleFlagged(orderFlaggedStatus[1])}
              >
                {"Mark as Resolved ✅"}
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="button"
                onClick={() => this.handleFlagged(orderFlaggedStatus[0])}
              >
                {"Mark as Flagged 🚩"}
              </Button>
            )}
          </div>
        </div>
        <Form>
          <div className="flex justify-between">
            {/* content left */}
            <div className="contentLeft">
              <div className="desc">
                <div className="flex pb-10">
                  <div className="flex flex-col">
                    <div className="flex">
                      <p className="semibold text-18 text-black">
                        {this.isEditMode() ? "Order Summary" : "Add Products"}
                      </p>
                      <div>
                        {/* <span style={{paddingRight:'2px'}}>Short ID:</span>{this.props.activeOrder && this.props.activeOrder.shortId} */}
                      </div>
                    </div>
                    {!this.isEditMode() ? (
                      <InputNumber
                        min={1}
                        max={10}
                        value={this.state.noOfProducts}
                        placeholder="No of products to add"
                        style={{ marginBottom: "20px" }}
                        disabled={this.isEditMode()}
                        onChange={(n) =>
                          this.setState((prevState) => {
                            return {
                              noOfProducts:
                                n < 1 || n > 10 ? prevState.noOfProducts : n,
                            };
                          })
                        }
                      />
                    ) : (
                      <div className="mb-12 flex">
                        {activeOrder &&
                          (activeOrder.status >= 0 &&
                          activeOrder.status <= 6 &&
                          activeOrder.status !== 1 ? (
                            <Icon
                              type="check-circle"
                              theme="twoTone"
                              twoToneColor="#52c41a"
                              style={{ marginRight: "10px", fontSize: "20px" }}
                            />
                          ) : (
                            <Icon
                              type="warning"
                              theme="twoTone"
                              style={{ marginRight: "10px", fontSize: "20px" }}
                            />
                          ))}
                        <p>
                          {activeOrder &&
                            paymentStatusMapping[activeOrder.status]}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col ml-18">
                    <div className="flex">
                      <div className="">
                        <p className="semibold text-18 text-black">
                          Order Type
                        </p>
                      </div>
                    </div>
                    <div className="mb-12 flex">
                      {this.getOrderType(activeOrder)}
                    </div>
                  </div>
                  <div className="flex flex-col ml-18">
                    <div className="flex">
                      <div className="">
                        <p className="semibold text-18 text-black mb-5">
                          Payment Method
                        </p>
                      </div>
                    </div>
                    <div className="mb-12 flex">
                      {this.getOrderPaymentMethod(activeOrder)}
                    </div>
                  </div>
                  <div className="flex flex-col ml-18">
                    <div className="flex">
                      <div className="">
                        <p className="semibold text-18 text-black mb-5">
                          Order Status
                        </p>
                      </div>
                    </div>
                    <div className="mb-12 flex">
                      {this.getFulfillmentStatus(activeOrder)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div
                    className="bold"
                    style={{ width: this.isEditMode() ? "30%" : "50%" }}
                  >
                    Items
                  </div>
                  {this.isEditMode() && (
                    <div
                      className="bold"
                      style={{
                        width: this.isEditMode() ? "20%" : "50%",
                        paddingLeft: this.isEditMode() ? "" : "20px",
                        marginLeft: this.isEditMode() ? "10px" : "0px",
                      }}
                    >
                      Weight
                    </div>
                  )}

                  {this.isEditMode() && (
                    <div
                      className="bold"
                      style={{
                        width: this.isEditMode() ? "20%" : "50%",
                        paddingLeft: this.isEditMode() ? "" : "20px",
                        marginLeft: this.isEditMode() ? "10px" : "0px",
                      }}
                    >
                      Source
                    </div>
                  )}
                  <div
                    className="bold"
                    style={{
                      width: this.isEditMode() ? "20%" : "50%",
                      textAlign: "center",
                      paddingLeft: this.isEditMode() ? "" : "162px",
                    }}
                  >
                    Quantity
                  </div>
                  {!this.isEditMode() && (
                    <div
                      className="bold"
                      style={{
                        width: this.isEditMode() ? "20%" : "50%",
                        paddingLeft: this.isEditMode() ? "" : "72px",
                      }}
                    >
                      Type
                    </div>
                  )}

                  {this.isEditMode() && hideSensitiveDataForRole() && (
                    <div className="bold" style={{ width: "30%" }}>
                      Select Product to replace with
                    </div>
                  )}
                </div>

                {new Array(this.state.noOfProducts).fill(1).map((n, index) => (
                  <Row type="flex" justify="" key={index}>
                    {!this.isEditMode() ? (
                      <Col span={12}>
                        <Form.Item>
                          {getFieldDecorator(`items[${index}].product`, {
                            initialValue: this.state.activeProductList[index]
                              ? this.state.activeProductList[index]?.product
                              : undefined,
                            rules: [
                              {
                                required: !this.isEditMode(),
                                message: "Required",
                              },
                            ],
                          })(
                            <Select
                              placeholder="Select product to add"
                              value={
                                this.state.activeProductList[index]?.product
                              }
                              disabled={this.isEditMode()}
                              onChange={(val) => {
                                let productType = this.state.productType;
                                let productCategory = allProducts.find(
                                  (e) => e.id === val
                                );
                                // console.log(
                                //   "productCategory====",
                                //   productCategory?.category?.name
                                // );

                                let activeProductList =
                                  this.state?.activeProductList;
                                if (
                                  activeProductList &&
                                  activeProductList[index]
                                ) {
                                  activeProductList[index].recommendationType =
                                    productCategory?.category?.name;
                                } else {
                                  activeProductList.push({});
                                  activeProductList[index].recommendationType =
                                    productCategory?.category?.name;
                                }
                                productType[index] =
                                  productCategory?.category?.name;
                                //console.log("PRODUCT TYPE", productType);
                                this.setState({
                                  productType: productType,
                                  activeProductList: activeProductList,
                                });
                              }}
                            >
                              {allProducts &&
                                allProducts.map((product) => (
                                  <Option value={product.id} key={product.id}>
                                    {product.name}
                                  </Option>
                                ))}
                            </Select>
                          )}
                        </Form.Item>
                        <Form.Item className="d-none">
                          {getFieldDecorator(
                            `items[${index}].recommendationType`,
                            {
                              initialValue: this.state.activeProductList[index]
                                ?.recommendationType
                                ? this.state.activeProductList[index]
                                    ?.recommendationType
                                : this.state?.productType[index]?.trim(),
                            }
                          )(<Input />)}
                        </Form.Item>
                      </Col>
                    ) : (
                      activeOrder &&
                      activeOrder.items[index] &&
                      activeOrder.items[index].product && (
                        <div
                          style={{ marginTop: "5px" }}
                          className="flex justify-between items-center w-full"
                        >
                          <div
                            className="flex items-container-product"
                            style={{ width: "30%", alignItems: "center" }}
                          >
                            <img
                              src={
                                activeOrder &&
                                activeOrder.items &&
                                activeOrder.items[index] &&
                                activeOrder.items[index].product &&
                                activeOrder.items[index].product.mainImage &&
                                activeOrder.items[index].product.mainImage
                                  .withoutHover &&
                                activeOrder.items[index].product.mainImage
                                  .withoutHover.mediaUrl
                              }
                              alt="product"
                              style={{
                                height: "45px",
                                width: "45px",
                                minWidth: "44px",
                                objectFit: "cover",
                                objectPosition: "center",
                                marginRight: "10px",
                              }}
                            ></img>
                            <div>
                              {activeOrder.items[index].product.name} <br />{" "}
                              {activeOrder.items[index].recommendationType
                                ? activeOrder.items[index].recommendationType
                                : this.getProductRecommendedType(
                                    activeOrder.items[index],
                                    activeOrder.items.map((p) => p.name)
                                  )}
                            </div>
                          </div>

                          <div style={{ width: "20%", marginLeft: "10px" }}>
                            {activeOrder &&
                              activeOrder.items[index] &&
                              activeOrder.items[index].product &&
                              activeOrder.items[index].product
                                .shippingMetadata &&
                              activeOrder.items[index].product.shippingMetadata
                                .weight}
                          </div>
                          <div style={{ width: "20%", marginLeft: "10px" }}>
                            <Tooltip
                              title={activeOrder?.items[index]?.item_source}
                              //overlayInnerStyle={{ width: "600px" }}
                              overlayStyle={{ maxWidth: "600px" }}
                            >
                              {this.textTruncate(
                                activeOrder?.items[index]?.item_source,
                                30
                              )}
                            </Tooltip>
                          </div>
                          <div style={{ width: "20%", textAlign: "center" }}>
                            {activeOrder.items[index].quantity}
                          </div>
                          {hideSensitiveDataForRole() && (
                            <Form.Item
                              className="product-replace"
                              style={{ width: "30%" }}
                            >
                              {getFieldDecorator(`items[${index}].newProduct`, {
                                initialValue: undefined,
                                rules: [
                                  {
                                    required: false,
                                    message: "Required",
                                  },
                                ],
                              })(
                                <Select
                                  placeholder="Select product to replace with"
                                  onChange={(value) => {
                                    let categoryName = allProducts
                                      .find((e) => e.id === value)
                                      ?.category?.name?.trim();
                                    let catKey = `items[${index}].recommendationType`;
                                    let setV = {};
                                    //this.props.form.setFieldsValue(setV);
                                    if (SERUM_IDS.includes(value)) {
                                      this.setState({
                                        showSerumTypeModal: true,
                                      });
                                      setV[catKey] = "";
                                      this.setState({ activeItemIndex: index });
                                    } else {
                                      this.props.activeOrder.items[
                                        index
                                      ].recommendationType = categoryName;
                                    }

                                    this.setState({ updateDisabled: false });
                                  }}
                                  onFocus={() =>
                                    this.handleFetchProductsByCategory(
                                      getSafely(
                                        () =>
                                          activeOrder.items[index].product.id,
                                        null
                                      )
                                    )
                                  }
                                >
                                  {productListByCategory &&
                                    productListByCategory.map((product) => (
                                      <Option
                                        value={product.id}
                                        key={product.id}
                                      >
                                        {product.name}
                                      </Option>
                                    ))}
                                </Select>
                              )}
                            </Form.Item>
                          )}
                          <Form.Item className="d-none">
                            {getFieldDecorator(
                              `items[${index}].recommendationType`,
                              {
                                initialValue:
                                  this.props.activeOrder &&
                                  this.props.activeOrder.items[index] &&
                                  this.props.activeOrder.items[index]
                                    .recommendationType
                                    ? this.props.activeOrder.items[index]
                                        .recommendationType
                                    : "",
                              }
                            )(<Input />)}
                          </Form.Item>
                          {(this.props?.activeOrder?.promotion?.discountType ==
                            "free" ||
                            this?.props?.activeOrder?.items[
                              index
                            ]?.product?.category?.id.includes(
                              "6392b9560e3f1017881bc338"
                            )) && (
                            <span
                              onClick={() => {
                                this.removeProduct(
                                  this.props.activeOrder,
                                  index
                                );
                              }}
                            >
                              <Icon
                                type="delete"
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "30px",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          )}
                        </div>
                      )
                    )}
                    {!this.isEditMode() && (
                      <Col span={6} style={{ paddingLeft: "20px" }}>
                        <Form.Item>
                          {getFieldDecorator(`items[${index}].quantity`, {
                            initialValue:
                              activeOrder && activeOrder.items[index]
                                ? activeOrder.items[index].quantity
                                : 1,
                            rules: [
                              {
                                required: !this.props.isEditMode,
                                message: "Required",
                              },
                            ],
                          })(
                            <InputNumber
                              min={1}
                              max={50}
                              placeholder="Quantity"
                            />
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    {!this.isEditMode() &&
                      this.state.productType[index]?.trim() == "Face Serum" && (
                        <Col span={6} style={{ paddingLeft: "20px" }}>
                          <Form.Item>
                            {getFieldDecorator(
                              `items[${index}].recommendationType`,
                              {
                                initialValue: undefined,

                                rules: [
                                  {
                                    required: !this.props.isEditMode,
                                    message: "Required",
                                  },
                                ],
                              }
                            )(
                              <Select
                                placeholder="Select Serum Type"
                                onChange={(val) => {
                                  // this.setState({});
                                }}
                              >
                                <Option value="Day Serum" key="day_serum">
                                  Day Serum
                                </Option>
                                <Option value="Night Serum" key="night_serum">
                                  Night Serum
                                </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      )}
                  </Row>
                ))}

                {hideSensitiveDataForRole() && (
                  <div style={{ marginTop: "23px" }} className="flex">
                    <div
                      style={{ color: "#8c8c8c", fontSize: "14px" }}
                      className="regular"
                    >
                      Skin assessment id:
                    </div>
                    {activeOrder && activeOrder.attempt ? (
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/assessment-detail/${activeOrder.attempt}`
                          )
                        }
                        style={{
                          fontSize: "14px",
                          color: "#363874",
                          paddingLeft: "5px",
                        }}
                        className="semibold cursor-pointer invoice-animate"
                      >
                        {activeOrder.attempt}
                      </div>
                    ) : assessmentId || this.state?.submissionId ? (
                      <div
                        onClick={() =>
                          this.props.history.push(
                            `/assessment-detail/${
                              assessmentId || this.state?.submissionId
                            }`
                          )
                        }
                        style={{
                          fontSize: "14px",
                          color: "#363874",
                          paddingLeft: "5px",
                        }}
                        className="semibold cursor-pointer invoice-animate"
                      >
                        {assessmentId || this.state?.submissionId}
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#363874",
                          paddingLeft: "5px",
                        }}
                        className="semibold"
                      >
                        {"Not Available"}
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="semibold cursor-pointer invoice-animate"
                  style={{
                    fontSize: "14px",
                    color: "#363874",
                    paddingLeft: "5px",
                    float: "right",
                    marginTop: "-24px;",
                  }}
                >
                  {" "}
                  <a
                    href={this.getPrinterToolLink(
                      activeOrder?.items,
                      activeOrder?.shippingAddress
                    )}
                    target="_blank"
                  >
                    🖨️ Printer PDF{" "}
                  </a>{" "}
                </div>
                <div style={{ "margin-top": "20px" }}>
                  {this.props?.activeOrder?.type != "default" &&
                    this.isEditMode() && (
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => {
                          this.setState({
                            showAddProductModal: true,
                          });
                        }}
                      >
                        {" "}
                        ➕ Add more items
                      </Button>
                    )}
                </div>
              </div>

              {/* order fullfillment and order tracking */}
              {this.isEditMode() && (
                <div className="desc">
                  <div className="flex">
                    <div style={{ flex: 1 }}>
                      <div className="flex justify-between">
                        <div className="bold text-base text-black">
                          Fulfillment Status
                        </div>
                      </div>

                      {/* <div style={{ paddingBottom: "10px" }}>
                    TRACKING INFORMATION
                  </div> */}
                      {/* tracking information */}
                      <div
                        className="flex flex-start"
                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                      >
                        <span>Payment Status: </span>
                        <span style={{ paddingLeft: "10px" }}>
                          {" "}
                          {this.props.activeOrder &&
                            paymentStatusMapping[this.props.activeOrder.status]}
                        </span>
                      </div>
                      <div
                        className="flex flex-start"
                        style={{ paddingBottom: "10px" }}
                      >
                        <span>Tracking No: </span>
                        <span style={{ paddingLeft: "10px" }}>
                          {this.getTrackingInfo(
                            this.props.activeOrder,
                            shipmentInfo?.courierService
                          )}{" "}
                          ↗️
                        </span>
                      </div>
                      <div
                        className="flex flex-start"
                        style={{ paddingBottom: "10px" }}
                      >
                        <span> Courier Service:</span>
                        <span style={{ paddingLeft: "10px" }}>
                          {"  "}
                          {shipmentInfo && shipmentInfo.courierService}
                        </span>
                      </div>
                      <div
                        className="flex flex-start"
                        style={{ paddingBottom: "10px" }}
                      >
                        <span>Order Status: </span>
                        <span style={{ paddingLeft: "10px" }}>
                          {activeOrder &&
                            orderFulfillmentStatusLabel[
                              activeOrder.fulfillmentStatus
                            ] +
                              orderFullfillmentStatusEmoji[
                                activeOrder.fulfillmentStatus
                              ]}
                        </span>
                      </div>
                      <div
                        className="flex flex-start"
                        style={{ paddingBottom: "10px" }}
                      >
                        <span>Shipped At: </span>
                        <span style={{ paddingLeft: "10px" }}>
                          {" "}
                          {activeOrder &&
                            (activeOrder.fulfillmentStatus == 5 ||
                              activeOrder.fulfillmentStatus == 4) &&
                            this.getTrackingStatus(activeOrder, 4)}
                        </span>
                      </div>
                      <div
                        className="flex flex-start"
                        style={{ paddingBottom: "10px" }}
                      >
                        <span>Delivered At: </span>
                        <span style={{ paddingLeft: "10px" }}>
                          {" "}
                          {this.props.activeOrder &&
                            this.props.activeOrder.fulfillmentStatus == 5 &&
                            this.getTrackingStatus(this.props.activeOrder, 5)}
                        </span>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div className="">
                        {this.props.activeOrder?.shipmentLabelUrl && (
                          <div
                            className=""
                            style={{
                              fontSize: " 14px",
                              color: "rgb(54, 56, 116)",
                              paddingLeft: "5px",
                              float: "right",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let filename =
                                activeOrder?.user?.firstName +
                                "_" +
                                activeOrder?.user?.lastName +
                                "_" +
                                activeOrder?.shippingAddress?.country +
                                "_" +
                                activeOrder.shortId +
                                ".pdf";

                              handleDownload(
                                this.props.activeOrder?.shipmentLabelUrl,
                                filename
                              );
                            }}
                          >
                            Download Label <span> 📑</span>
                          </div>
                        )}

                        <div style={{ width: "190px", float: "right" }}>
                          <div className="bold text-base text-black">
                            Update Label
                          </div>
                          <Select
                            style={{}}
                            placeholder="Select courier"
                            onChange={(val) => {
                              this.updateTrackingLabel(
                                this.props.activeOrder,
                                val
                              );
                            }}
                          >
                            <Option value="" key="select">
                              Select Label
                            </Option>
                            <Option value="dhl" key="DHL">
                              DHL
                            </Option>
                            <Option value="easyship" key="ES">
                              Eashyship
                            </Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* order fullfillment and order tracking */}
              {this.isEditMode() && (
                <div className="desc">
                  <div className="flex justify-between">
                    <div className="bold text-base text-black">
                      Payment Status
                    </div>
                    {/* fullfillment status */}
                  </div>
                  <div style={{ marginTop: "31px" }} className="">
                    <div className="flex justify-between">
                      <div className="flex">
                        <div style={{ width: "100px" }} className="regular">
                          Subtotal
                        </div>
                      </div>
                      <div className="regular">
                        {activeOrder &&
                        activeOrder.totalPrice &&
                        activeOrder.currency &&
                        activeOrder.currency.code
                          ? activeOrder.currency.code.toUpperCase() +
                            " " +
                            activeOrder.totalPrice
                          : ""}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "5px" }}
                      className="flex justify-between"
                    >
                      <div className="flex">
                        <div style={{ width: "100px" }} className="regular">
                          Shipping
                        </div>
                        {/* <div
                          style={{ paddingLeft: "15px" }}
                          className="regular"
                        >
                          Standard Shipping
                        </div> */}
                      </div>
                      <div className="regular">
                        {/* {activeOrder &&
                          activeOrder.currency &&
                          activeOrder.currency.symbol} */}
                        {activeOrder &&
                        activeOrder.shipping &&
                        activeOrder.currency &&
                        activeOrder.currency.code
                          ? activeOrder.currency.code.toUpperCase() +
                            " " +
                            activeOrder.shipping
                          : ""}
                      </div>
                    </div>
                    {/* <div style={{marginTop:'5px'}} className="flex justify-between">
                  <div className="flex">
                <div style={{width:'100px'}} className>Tax </div>
                <div style={{paddingLeft:'15px'}} className></div>
                </div>
                <div className>{activeOrder && activeOrder.currency && activeOrder.currency.symbol}{activeOrder && activeOrder.tax} </div>
                </div> */}
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                      className="flex justify-between"
                    >
                      <div className="flex">
                        <div
                          style={{
                            width: "140px",
                            color: "#656565",
                            fontSize: "14px",
                          }}
                          className="regular"
                        >
                          Full Set Discount
                        </div>
                      </div>
                      <div className="regular">
                        {activeOrder?.currency?.code
                          ? activeOrder.currency.code.toUpperCase()
                          : ""}{" "}
                        {activeOrder && activeOrder.fullSetDiscount
                          ? activeOrder.fullSetDiscount.toFixed(2)
                          : "0"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                      className="flex justify-between"
                    >
                      <div className="flex">
                        <div
                          style={{
                            width: "140px",
                            color: "#656565",
                            fontSize: "14px",
                          }}
                          className="regular"
                        >
                          Subscription Discount
                        </div>
                      </div>
                      <div className="regular">
                        {activeOrder &&
                        activeOrder.subscriptionDiscount &&
                        activeOrder.currency &&
                        activeOrder.currency.code
                          ? activeOrder.currency.code.toUpperCase() +
                            " " +
                            activeOrder.subscriptionDiscount.toFixed(2)
                          : ""}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                      className="flex justify-between"
                    >
                      <div className="flex">
                        <div
                          style={{
                            width: "140px",
                            color: "#656565",
                            fontSize: "14px",
                          }}
                          className="regular"
                        >
                          Discount Code
                          {this.getPromoCode(activeOrder)}
                        </div>
                      </div>
                      <div className="regular">
                        {activeOrder &&
                        activeOrder.currency &&
                        activeOrder.currency.code
                          ? activeOrder.currency.code.toUpperCase()
                          : " "}{" "}
                        {activeOrder && discountCode
                          ? discountCode.toFixed(2)
                          : "0"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                      className="flex justify-between"
                    >
                      <div className="flex">
                        <div
                          style={{
                            width: "140px",
                            color: "#656565",
                            fontSize: "14px",
                          }}
                          className="regular"
                        >
                          Total Discount
                        </div>
                      </div>
                      <div className="regular">
                        {/* {activeOrder &&
                          activeOrder.currency &&
                          activeOrder.currency.symbol}
                        {activeOrder && activeOrder.chargedPrice}{" "} */}
                        {activeOrder &&
                        activeOrder.currency &&
                        activeOrder.currency.code
                          ? activeOrder.currency.code.toUpperCase() +
                            " " +
                            totalDiscount.toFixed(2)
                          : "-"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                      }}
                      className="flex justify-between"
                    >
                      <div className="flex">
                        <div
                          style={{
                            width: "140px",
                            color: "#656565",
                            fontSize: "14px",
                          }}
                          className="regular"
                        >
                          Tax Amount{" "}
                          {activeOrder?.tax_details?.tax_value && (
                            <>({activeOrder?.tax_details?.tax_value}%)</>
                          )}
                        </div>
                      </div>
                      <div className="regular">
                        {activeOrder?.currency?.code
                          ? activeOrder.currency.code.toUpperCase()
                          : ""}{" "}
                        {activeOrder?.tax_details?.tax_amount
                          ? activeOrder.tax_details.tax_amount / 100
                          : "0.00"}
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "10px",
                        borderTop: "1px solid #e8e8e8",
                      }}
                      className="flex justify-between"
                    >
                      <div
                        style={{
                          color: "#262626",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                        className="semibold"
                      >
                        Total Price
                      </div>
                      <div
                        style={{
                          color: "#656565",
                          fontSize: "14px",
                          paddingTop: "10px",
                        }}
                        className="semibold"
                      >
                        {activeOrder &&
                          activeOrder.currency &&
                          activeOrder.currency.code.toUpperCase()}
                        {activeOrder && " " + this.getTotalChargePrice()}{" "}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "22px" }}
                      className="flex items-center"
                    >
                      <div
                        style={{
                          width: "90px",
                          color: "#8c8c8c",
                          fontSize: "18px",
                        }}
                        className="bold cursor-pointer"
                      >
                        Invoice Id
                      </div>
                      {activeOrder && activeOrder.receiptId ? (
                        <div
                          onClick={() =>
                            this.handleDownloadInvoice(
                              activeOrder ? activeOrder.id : ""
                            )
                          }
                          className="semibold cursor-pointer invoice-animate"
                          style={{ color: "#363874" }}
                        >
                          {" "}
                          {activeOrder.receiptId ? activeOrder.receiptId : ""}
                        </div>
                      ) : (
                        <div className="regular">Not Available </div>
                      )}

                      {/* {this.isEditMode() && (
            <div
              className="flex mr-26 cursor-pointer"
              onClick={() =>
                this.handleDownloadInvoice(activeOrder ? activeOrder.id : "")
              }
            >
              <Icon type="printer" className="mr-6"></Icon>
              <p>Print</p>
            </div>
          )} */}
                    </div>
                    {activeOrder?.subscriptionId &&
                      hideSensitiveDataForRole() && (
                        <div
                          style={{ marginTop: "22px" }}
                          className="flex items-center"
                        >
                          <div
                            style={{
                              width: "120px",
                              color: "#8c8c8c",
                              fontSize: "18px",
                            }}
                            className="bold cursor-pointer"
                          >
                            Subscription
                          </div>
                          <Link
                            style={{ color: "#363864" }}
                            target="_blank"
                            to={`/customer/${activeOrder.user?.id?.id}/subscription/${activeOrder?.subscriptionId?.id}`}
                          >
                            {activeOrder?.subscriptionId?.id}
                          </Link>
                        </div>
                      )}
                  </div>
                </div>
              )}

              {this.isEditMode() && this.props.refund.length
                ? this.props.refund.map((refundVal, index) => {
                    return (
                      <div className="desc" key={index}>
                        <p className="semibold text-18 text-black">Refunded</p>
                        <div className="flex justify-between">
                          <div
                            style={{
                              width: "100%",
                              marginBottom: "10px",
                              borderBottom: "1px solid #e8e8e8",
                              paddingBottom: "10px",
                            }}
                          >
                            <span className="bold">Reason:</span>{" "}
                            {refundVal?.reason}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="bold" style={{ width: "50%" }}>
                            Items
                          </div>
                          <div
                            className="bold"
                            style={{
                              width: "25%",
                              marginLeft: "10px",
                            }}
                          >
                            Quantity
                          </div>
                          <div
                            className="bold"
                            style={{
                              width: "25%",
                              textAlign: "right",
                              marginLeft: "10px",
                            }}
                          >
                            Amount
                          </div>
                        </div>

                        <div>
                          {refundVal.items.map((item) => {
                            let product = this.props.allProducts.find(
                              (itm) => itm.id === item.product
                            );
                            return (
                              <Row
                                type="flex"
                                justify="space-between"
                                key={item.product}
                              >
                                {product && (
                                  <div
                                    style={{ marginTop: "5px" }}
                                    className="flex justify-between items-center w-full"
                                  >
                                    <div
                                      className="flex items-container-product"
                                      style={{
                                        width: "50%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          product.mainImage &&
                                          product.mainImage.withoutHover &&
                                          product.mainImage.withoutHover
                                            .mediaUrl
                                        }
                                        alt="product"
                                        style={{
                                          height: "45px",
                                          width: "45px",
                                          minWidth: "44px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                          marginRight: "10px",
                                        }}
                                      ></img>
                                      <div>{product.name}</div>
                                    </div>

                                    <div
                                      style={{
                                        width: "25%",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {item.quantity}
                                    </div>
                                    <div
                                      style={{
                                        width: "25%",
                                        marginLeft: "10px",
                                        textAlign: "right",
                                      }}
                                    >
                                      {activeOrder &&
                                        activeOrder.currency &&
                                        activeOrder.currency.code.toUpperCase()}{" "}
                                      {item.amountAfterDiscount.toFixed(2)}
                                    </div>
                                  </div>
                                )}
                              </Row>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            borderTop: "1px solid #e8e8e8",
                          }}
                          className="flex justify-between"
                        >
                          <div
                            style={{
                              color: "#262626",
                              fontSize: "14px",
                              paddingTop: "10px",
                            }}
                            className="semibold"
                          >
                            Refunded
                          </div>
                          <div
                            style={{
                              color: "#656565",
                              fontSize: "14px",
                              paddingTop: "10px",
                            }}
                            className="semibold"
                          >
                            {activeOrder &&
                              activeOrder.currency &&
                              activeOrder.currency.code.toUpperCase()}{" "}
                            {refundVal.totalAmount.value}{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div className="desc">
                <p className="semibold text-18 text-black">Type</p>
                <Form.Item>
                  {getFieldDecorator(`type`, {
                    initialValue: activeOrder ? activeOrder.type : undefined,
                    rules: [
                      {
                        required: !this.isEditMode(),
                        message: "Required",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select order type"
                      disabled={this.isEditMode()}
                      value={this.state.orderType}
                      onChange={(option) =>
                        this.setState({ orderType: option })
                      }
                      style={{ width: "100%" }}
                    >
                      {Object.keys(ORDER_TYPES_MAPPER).map((type, index) => (
                        <Option className="capitalize" value={type} key={index}>
                          {ORDER_TYPES_MAPPER[type]}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                {((this.state.orderType == "wholesale" && !this.isEditMode()) ||
                  (activeOrder && activeOrder.type == "wholesale")) && (
                  <div style={{ marginTop: "20px" }}>
                    <Form.Item label="Enter payment link ">
                      {getFieldDecorator(`wholesalePaymentLink`, {
                        initialValue:
                          activeOrder?.wholesalePaymentLink || undefined,
                        rules: [
                          {
                            required: !this.isEditMode(),
                            message: "Required",
                          },
                        ],
                      })(
                        <Input
                          placeholder=" payment link"
                          disabled={this.isEditMode()}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </div>
                )}
                {((this.state.orderType !== "gift" && !this.isEditMode()) ||
                  (activeOrder && activeOrder.type !== "gift")) && (
                  <div style={{ marginTop: "20px" }}>
                    <Form.Item label="Reason">
                      {getFieldDecorator(`appeasement_reason`, {
                        initialValue: undefined,
                        rules: [
                          {
                            required: !this.isEditMode(),
                            message: "Required",
                          },
                        ],
                      })(
                        <Input
                          placeholder=" Reason"
                          disabled={this.isEditMode()}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </div>
                )}
                {((this.state.orderType === "gift" && !this.isEditMode()) ||
                  (activeOrder && activeOrder.type === "gift")) && (
                  <div style={{ marginTop: "20px" }}>
                    <Form.Item label="Gift message">
                      {getFieldDecorator(`gift.salutation`, {
                        initialValue: getSafely(
                          () => activeOrder.gift.salutation,
                          undefined
                        ),
                        rules: [
                          {
                            required: !this.isEditMode(),
                            message: "Required",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Dear"
                          disabled={this.isEditMode()}
                          style={{ width: "20%" }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator(`gift.name`, {
                        initialValue: getSafely(
                          () => activeOrder.gift.name,
                          undefined
                        ),
                        rules: [
                          {
                            required: !this.isEditMode,
                            message: "Required",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Navneet"
                          disabled={this.isEditMode()}
                          style={{ width: "20%" }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator(`gift.message`, {
                        initialValue: getSafely(
                          () => activeOrder.gift.message,
                          undefined
                        ),
                        rules: [
                          {
                            required: !this.isEditMode(),
                            message: "Required",
                          },
                        ],
                      })(
                        <Input
                          placeholder="A small gift for you"
                          disabled={this.isEditMode()}
                          style={{ width: "100%" }}
                        />
                      )}
                    </Form.Item>
                  </div>
                )}
              </div>
              {activeOrder && (
                <div className="desc comments">
                  <p className="semibold text-18 text-black mb-10">Comments</p>
                  <ErrorBoundary>
                    <Comments ref={this.commentRef} orderId={activeOrder?.id} />
                  </ErrorBoundary>
                </div>
              )}
            </div>

            {/* content right */}
            <div className="contentRight flex flex-col">
              <div className="tags mb-28">
                {!this.isEditMode() && (
                  <div className="mb-24">
                    <p className="semibold text-18 text-black mb-0">Customer</p>
                    {this.state.selectedCustomerId && (
                      <p className="text-16 theme-color font-normal">
                        {this.state.selectedCustomer.firstName +
                          " " +
                          this.state.selectedCustomer.lastName +
                          ", " +
                          this.state.selectedCustomer.email}
                      </p>
                    )}
                  </div>
                )}

                {hideSensitiveDataForRole() && (
                  <>
                    {!this.isEditMode() ? (
                      <>
                        <Search
                          placeholder={`Search by email`}
                          onSearch={(value) => this.handleSearch(value)}
                          style={{ width: "100%", marginRight: "5px" }}
                          //onKeyUp={(e) => this.handleSearch(e.target.value)}
                        />
                        <Form.Item>
                          {getFieldDecorator(`user`, {
                            initialValue: this.state.selectedCustomer
                              ? `${
                                  this.state.selectedCustomer?.user
                                    ?.firstName || ""
                                } ${
                                  this.state.selectedCustomer?.user?.lastName ||
                                  ""
                                }`
                              : undefined,

                            rules: [
                              {
                                required: !this.props.isEditMode,
                                message: "Required",
                              },
                            ],
                          })(
                            <>
                              <Select
                                disabled={this.isEditMode()}
                                placeholder="Select customer"
                                showSearch
                                value={
                                  Object.keys(
                                    this.state?.selectedCustomer || {}
                                  ).length > 0
                                    ? JSON.stringify(
                                        this.state.selectedCustomer
                                      )
                                    : ""
                                }
                                onChange={(option) => {
                                  const customerObj = JSON.parse(option);
                                  this.setState({
                                    selectedCustomerId: customerObj._id,
                                    selectedCustomer: customerObj,
                                    phoneNumber: customerObj.phone
                                      ? customerObj.phone
                                      : {},
                                  });
                                }}
                              >
                                {customerList &&
                                  customerList.map((customer) => (
                                    <Option
                                      value={JSON.stringify(customer)}
                                      key={customer.id}
                                    >
                                      {`${customer.firstName || ""} ${
                                        customer.lastName || ""
                                      }`}
                                    </Option>
                                  ))}
                              </Select>
                            </>
                          )}
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <div className="order-info-customer">
                          <Link
                            to={`/customer/edit/${
                              this.props.activeOrder &&
                              this.props.activeOrder.user?.id?.id
                            }`}
                            className="semibold pad-bottom capitalize cust-name"
                          >
                            {" "}
                            {`${activeOrder && activeOrder.user.firstName} ${
                              activeOrder ? activeOrder.user.lastName || "" : ""
                            }`}
                          </Link>
                          {/* <strong
                        style={{ borderBottom: "1px solid #e8e8e8" }}
                        className="semibold pad-bottom capitalize"
                      >
                        {`${activeOrder && activeOrder.user.firstName} ${
                          activeOrder ? activeOrder.user.lastName || "" : ""
                        }`}
                      </strong> */}
                          <div style={{ fontSize: "18px", paddingTop: "8px" }}>
                            Contact Information
                          </div>
                          <strong className="semibold">
                            {activeOrder && activeOrder.user.email}
                          </strong>
                          <div
                            style={{
                              display: `${
                                activeOrder &&
                                activeOrder.paymentEmail &&
                                activeOrder.paymentEmail
                                  ? "flex"
                                  : "none"
                              }`,
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: 18 }}>Partner Email</div>
                            <strong className="semibold">
                              {activeOrder &&
                                activeOrder.paymentEmail &&
                                activeOrder.paymentEmail}
                            </strong>
                          </div>
                          <div
                            style={{
                              display: `${
                                activeOrder?.shippingAddress?.instagramHandel
                                  ? "flex"
                                  : "none"
                              }`,
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: 18 }}>
                              Instagram username
                            </div>
                            <strong className="semibold">
                              {`@${activeOrder?.shippingAddress?.instagramHandel}`}
                            </strong>
                          </div>
                          <div
                            style={{
                              display: `${
                                activeOrder?.shippingAddress?.tiktokHandel
                                  ? "flex"
                                  : "none"
                              }`,
                              flexDirection: "column",
                            }}
                          >
                            <div style={{ fontSize: 18 }}>TikTok username</div>
                            <strong className="semibold">
                              {`@${activeOrder?.shippingAddress?.tiktokHandel}`}
                            </strong>
                          </div>
                        </div>

                        {/* <hr style={{width:'1px solid #e8e8e8',marginBottom:'0',color:'#e8e8e8 !Important'}}></hr> */}
                      </>
                    )}

                    {/* this would be only visible in create mode (div start) */}

                    {/* <h3
                      onClick={() => {
                        this.setState((prevState) => {
                          return {
                            showShippingAddressFormOnCreation:
                              !prevState.showShippingAddressFormOnCreation,
                          };
                        });
                      }}
                      style={{
                        fontSize: "18px",
                        color: "#373873",
                        paddingBottom: "17px",
                      }}
                      className="semibold cursor-pointer underline"
                    >
                      Add new address
                    </h3> */}

                    {true &&
                      this.props.customerAddressList &&
                      this.props.customerAddressList.length > 0 && (
                        <>
                          <div className="flex justify-between mt-10">
                            <div className="semibold text-18 text-black">
                              {this.isEditMode() ? "Update" : ""} Shipping
                              Address
                            </div>
                          </div>
                          <div className="mb-28">
                            <Form.Item>
                              {getFieldDecorator(`shippingAddress`, {
                                initialValue:
                                  this.state.selectedCustomer &&
                                  this.state.selectedCustomer.primaryAddress
                                    ? this.state.selectedCustomer.primaryAddress
                                        .id
                                    : undefined,
                                rules: [
                                  {
                                    required: !this.isEditMode(),
                                    message: "Required",
                                  },
                                ],
                              })(
                                <Select
                                  // disabled={this.isEditMode()}
                                  onChange={(value) => {
                                    if (value != "new") {
                                      this.setPhoneNumber(value);
                                      this.setState({ updateDisabled: false });
                                    } else {
                                      this.setState({
                                        showShippingAddressFormOnCreation: true,
                                      });
                                    }
                                  }}
                                  value={this.state.selectedCustomerAddressId}
                                  placeholder="Select shipping address"
                                >
                                  {customerAddressList &&
                                    customerAddressList.map((address) => (
                                      <Option
                                        value={address.id}
                                        key={address.id}
                                      >
                                        {this.getAddressStr(address)}
                                      </Option>
                                    ))}
                                  <option value={"new"} key={"new"}>
                                    Create New Address
                                  </option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                          <div>
                            {/* {this.props.form.getFieldsValue}hello */}
                          </div>
                        </>
                      )}

                    {/* billing address on creation */}
                    {!this.isEditMode() &&
                      this.props.customerAddressList &&
                      this.props.customerAddressList.length > 0 && (
                        <>
                          <div className="flex justify-between mt-10">
                            <div className="semibold text-18 text-black">
                              Billing Address
                            </div>
                          </div>
                          <div className="mb-28">
                            <Form.Item>
                              {getFieldDecorator(`billingAddress`, {
                                initialValue:
                                  this.state.selectedCustomer &&
                                  this.state.selectedCustomer.primaryAddress
                                    ? this.state.selectedCustomer.primaryAddress
                                        ._id
                                    : undefined,
                                rules: [
                                  {
                                    required: !this.props.isEditMode,
                                    message: "Required",
                                  },
                                ],
                              })(
                                <Select
                                  // disabled={this.isEditMode()}
                                  placeholder="Select shipping address"
                                >
                                  {customerAddressList &&
                                    customerAddressList.map((address) => (
                                      <Option
                                        value={address.id}
                                        key={address.id}
                                      >
                                        {this.getAddressStr(address)}
                                      </Option>
                                    ))}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                          {/* <div className="address-title flex item-center justify-between">
                        <h3
                          style={{
                            fontSize: "18px",
                            color: "#656565",
                            paddingBottom: "0px",
                          }}
                          className="semibold"
                        >
                          Billing Address
                        </h3>
                        <div
                          onClick={() => {
                            this.setState((prevState) => {
                              return {
                                showShippingAddressForm: !prevState.showShippingAddressForm,
                              };
                            });
                          }}
                          style={{
                            color: "#363874",
                            fontSize: "14px",
                            paddingTop: "3px",
                          }}
                          className="regular cursor-pointer"
                        >
                          Edit
                        </div>
                      </div>
                      <div style={{maxWidth:'180px',color:'#656565',paddingBottom:'10px'}} className="address-content-clearfix">
                        Address: 1625 Post St, Hotel Kabuki San Francisco, CA
                        CA, United States
                      </div> */}
                        </>
                      )}
                    {/* billing adddress on creation */}

                    {/* phone number on creation */}
                    {!this.isEditMode() &&
                      this.props.customerAddressList &&
                      this.props.customerAddressList.length && (
                        <>
                          <div className="flex justify-between mt-10">
                            <div className="semibold text-18 text-black">
                              Phone Number
                            </div>
                          </div>
                          <Row gutter={24}>
                            <Col span={10}>
                              <Form.Item>
                                {getFieldDecorator("isdCode", {
                                  initialValue: this.state.phoneNumber
                                    ? this.state.phoneNumber.isdCode
                                    : undefined,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please select your ISD Code",
                                    },
                                  ],
                                })(
                                  <Select
                                    showSearch
                                    placeholder="ISD Code"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {ISDCodes.map((country) => {
                                      return (
                                        <Option
                                          value={country.dial_code}
                                          key={country.dial_code}
                                        >
                                          {country.dial_code +
                                            ` - ` +
                                            country.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>

                            <Col span={14}>
                              <Form.Item label="">
                                {getFieldDecorator("phone", {
                                  initialValue: this.state.phoneNumber
                                    ? this.state.phoneNumber.phone
                                    : undefined,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please enter Phone Number!",
                                    },
                                  ],
                                })(
                                  <Input
                                    placeholder="Enter Phone Number"
                                    value={this.state.phoneNumber?.phone}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      )}
                    {/* phone number on creation */}

                    {true && (
                      <div
                        style={{
                          margin: "13px 0 11px",
                          borderBottom: "1px solid #e8e8e8",
                        }}
                        className="shipping-wrapper-create-mode"
                      >
                        <div className="address-title flex item-center justify-between">
                          {/* <div
                        onClick={() => {
                          this.setState((prevState) => {
                            return {
                              showShippingAddressFormOnCreation: !prevState.showShippingAddressFormOnCreation,
                            };
                          });
                        }}
                        style={{ color: "#363874", fontSize: "14px" }}
                        className="regular cursor-pointer"
                      >
                        Create
                      </div> */}
                          {/* <button>Manage</button> */}
                        </div>
                        {this.state.showShippingAddressFormOnCreation && (
                          <div className="add-address-form">
                            <CreateAddressForm
                              customerId={this.state.selectedCustomerId}
                              onAddressCreation={() => {
                                this.setState({
                                  showShippingAddressFormOnCreation: false,
                                });
                                this.props.fetchCustomerAddressList(
                                  this.state.selectedCustomerId
                                );
                              }}
                              showPrimaryKey={true}
                            />
                          </div>
                        )}
                      </div>
                    )}

                    {/* this would be only visible in create mode (div end) */}

                    {/* this would be only visible in edit mode (start div) */}
                    {this.isEditMode() && (
                      <div className="address-info">
                        <div
                          style={{
                            margin: "13px 0 11px",
                            borderBottom: "1px solid #e8e8e8",
                          }}
                          className="shipping-wrapper"
                        >
                          <div className="address-title flex item-center justify-between">
                            <h3
                              style={{
                                fontSize: "18px",
                                color: "#656565",
                                paddingBottom: "17px",
                              }}
                              className="semibold"
                            >
                              Shipping Address
                            </h3>
                            <div
                              onClick={() => {
                                this.setState((prevState) => {
                                  return {
                                    showShippingAddressForm:
                                      !prevState.showShippingAddressForm,
                                  };
                                });
                              }}
                              style={{ color: "#363874", fontSize: "14px" }}
                              className="regular cursor-pointer"
                            >
                              Edit
                            </div>
                            {/* <button>Manage</button> */}
                          </div>
                          {this.props.activeOrder && (
                            <CustomerAddress
                              showAddressType={true}
                              addressContent={
                                this.props.activeOrder.shippingAddress
                              }
                            />
                          )}

                          {this.state.showShippingAddressForm && (
                            <CreateAddressForm
                              activeAddress={
                                this.props.activeOrder.shippingAddress
                              }
                              customerId={
                                this.props.activeOrder
                                  ? this.props.activeOrder.user?.id?.id
                                  : ""
                              }
                              updateShippingAddress={
                                this.props.updateShippingAddress
                              }
                              activeAddressId={
                                this.props.activeOrder
                                  ? this.props.activeOrder.shippingAddress?._id
                                  : ""
                              }
                              addressType="ShippingAddress"
                              orderId={this.props.activeOrder._id}
                              onAddressCreation={() => {
                                this.setState({
                                  showShippingAddressForm: false,
                                });
                                this.props.getOrder(this.props.activeOrder._id);
                              }}
                              showPrimaryKey={true}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            margin: "13px 0 11px",
                            borderBottom: "1px solid #e8e8e8",
                          }}
                          className="shipping-wrapper"
                        >
                          <div className="address-title flex item-center justify-between">
                            <h3
                              style={{
                                fontSize: "18px",
                                color: "#656565",
                                paddingBottom: "17px",
                              }}
                              className="semibold"
                            >
                              Phone Number
                            </h3>
                            <div
                              onClick={() => {
                                this.setState((prevState) => {
                                  return {
                                    showPhoneNumberForm:
                                      !prevState.showPhoneNumberForm,
                                  };
                                });
                              }}
                              style={{ color: "#363874", fontSize: "14px" }}
                              className="regular cursor-pointer"
                            >
                              Edit
                            </div>
                          </div>
                          <div style={{ marginBottom: "16px" }}>
                            {phoneNumber
                              ? phoneNumber.isdCode + " - " + phoneNumber.phone
                              : "Not Available"}
                          </div>
                          {this.state.showPhoneNumberForm && (
                            <CreatePhoneForm
                              phoneNumber={phoneNumber}
                              customerId={
                                this.props.activeOrder
                                  ? this.props.activeOrder.user?.id?.id
                                  : ""
                              }
                              updatePhone={this.props.updateAddress}
                              activeId={
                                this.props.activeOrder
                                  ? this.props.activeOrder.shippingAddress?._id
                                  : ""
                              }
                              onPhoneCreation={() => {
                                this.setState({
                                  showPhoneNumberForm: false,
                                });
                                if (
                                  this.props.activeOrder.trackingNumber &&
                                  (this.props.activeOrder.fulfillmentStatus ==
                                    0 ||
                                    this.props.activeOrder.fulfillmentStatus ==
                                      10)
                                ) {
                                  this.props.updateWaybill(
                                    this.props.activeOrder._id
                                  );
                                }

                                this.props.getOrder(this.props.activeOrder._id);
                              }}
                            />
                          )}
                        </div>
                        <div className="blling-wrapper">
                          <div className="address-title flex item-center justify-between">
                            <h3
                              style={{
                                fontSize: "18px",
                                color: "#656565",
                                paddingBottom: "17px",
                              }}
                              className="semibold"
                            >
                              Billing Address
                            </h3>
                            <div
                              onClick={() => {
                                this.setState((prevState) => {
                                  return {
                                    showBillingAddressForm:
                                      !prevState.showBillingAddressForm,
                                  };
                                });
                              }}
                              style={{ color: "#363874", fontSize: "14px" }}
                              className="regular cursor-pointer"
                            >
                              Edit
                            </div>
                          </div>

                          {this.props.activeOrder && (
                            <CustomerAddress
                              addressContent={
                                this.props.activeOrder.billingAddress
                              }
                            />
                          )}
                        </div>
                        {this.state.showBillingAddressForm && (
                          <CreateAddressForm
                            activeAddress={
                              this.props.activeOrder.billingAddress
                            }
                            customerId={
                              this.props.activeOrder
                                ? this.props.activeOrder.user.id.id
                                : ""
                            }
                            updateAddress={this.props.updateAddress}
                            activeAddressId={
                              this.props.activeOrder
                                ? this.props.activeOrder.billingAddress._id
                                : ""
                            }
                            onAddressCreation={() => {
                              this.setState({
                                showBillingAddressForm: false,
                              });
                              this.props.getOrder(this.props.activeOrder._id);
                            }}
                            showPrimaryKey={true}
                          />
                        )}
                      </div>
                    )}
                    {/* this would be only visible in edit mode (end div) */}
                  </>
                )}
              </div>

              {hideSensitiveDataForRole() && (
                <div className="desc tags mb-28">
                  <div className="sub-title flex align-item justify-between">
                    <h4 className="semibold">UTM</h4>
                  </div>
                  <div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        Browser:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utmBrowser}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        Device:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utmDevice}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        Location:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utmLocation}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        UTM Source:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utmWebsite}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        UTM Medium:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utm_medium}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        UTM Content:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utm_content}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "100px" }}>
                        UTM campaign:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.utm_campaign}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "60px" }}>
                        User Ip:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.userIp}
                      </div>
                    </div>
                    <div class="flex">
                      <div class="regular" style={{ width: "60px" }}>
                        Country:
                      </div>
                      <div className="regular">
                        {this.props.activeOrder?.ipLocation?.country}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.props.activeOrder &&
                this.props.activeOrder.cardExpiryDate &&
                activeOrder.cardDetails && (
                  <div className="desc tags mb-28">
                    <div className="sub-title flex align-item justify-between">
                      <h4 className="semibold">Card Details</h4>
                    </div>
                    <div>
                      <div class="flex">
                        <div class="regular" style={{ width: "160px" }}>
                          Brand:
                        </div>
                        <div className="regular">
                          {this.props.activeOrder?.cardDetails.brand}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="regular" style={{ width: "160px" }}>
                          Expiry Month:
                        </div>
                        <div className="regular">
                          {this.props.activeOrder?.cardDetails.exp_month}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="regular" style={{ width: "160px" }}>
                          Expiry Year:
                        </div>
                        <div className="regular">
                          {this.props.activeOrder?.cardDetails.exp_year}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="regular" style={{ width: "160px" }}>
                          Last4Digits:
                        </div>
                        <div className="regular">
                          {this.props.activeOrder?.cardDetails.last4digit}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {this.isEditMode() &&
                this.props.tags &&
                hideSensitiveDataForRole() && (
                  <div className="desc tags mb-28">
                    <div className="sub-title flex align-item justify-between">
                      <h4 className="semibold">Tag</h4>
                      {/* <button>Edit</button> */}
                    </div>
                    <Form.Item label="">
                      {getFieldDecorator("tags", {
                        initialValue:
                          activeOrder && activeOrder.tags
                            ? activeOrder.tags.map((tag) => tag._id)
                            : undefined,
                        rules: [
                          {
                            required: false,
                            type: "array",
                          },
                        ],
                      })(
                        <Select
                          placeholder="Select a tag"
                          style={{ width: "100%" }}
                          mode="multiple"
                          onChange={() => {
                            this.setState({ updateDisabled: false });
                          }}
                          showSearch
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.props.tags &&
                            this.props.tags.map((tag) => (
                              <Option value={tag._id} key={tag._id}>
                                {tag.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  </div>
                )}

              {this.isEditMode() && (
                <div className="desc tags mb-28">
                  <div className="sub-title flex align-item justify-between">
                    <h4 className="semibold">Order Notes</h4>
                    {this.props?.activeOrder?.notes && (
                      <Button
                        type="ghost"
                        onClick={async () => {
                          await this.setState({
                            orderNotesEdit: !this.state.orderNotesEdit,
                          });
                          this.setState({
                            orderNote: this.props?.activeOrder?.notes,
                          });
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <div>
                    {this.props?.activeOrder?.notes &&
                    !this.state.orderNotesEdit
                      ? this.props.activeOrder?.notes
                      : ""}
                  </div>
                  {this.state.orderNotesEdit && (
                    <>
                      <Form>
                        <Form.Item className="mb-10">
                          <Input
                            placeholder="Add note"
                            value={this.state.orderNote}
                            onChange={(e) => {
                              this.setState({
                                orderNote: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Form>
                      <Button
                        type="primary"
                        onClick={() => {
                          this.props
                            .updateOrder(this.state.activeOrderId, {
                              notes: this.state.orderNote,
                            })
                            .then((res) => {
                              if (res && res.error) {
                                message.error(res.error.message);
                              } else {
                                message.success(
                                  `Order Notes Successfully Updated`
                                );
                                if (this.state.orderNote.length) {
                                  this.setState({ orderNotesEdit: false });
                                } else {
                                  this.setState({ orderNotesEdit: true });
                                }
                                this.props.getOrder(
                                  this.props?.match?.params?.id
                                );
                              }
                            });
                        }}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              )}

              <div className="credit-wrap notes-wrap products mb-28">
                <div className="sub-title flex align-item justify-between">
                  <h4 className="semibold">Customer Notes</h4>
                  {this.props?.activeOrder?.user?.id?.notes && (
                    <Button
                      type="ghost"
                      onClick={async () => {
                        await this.setState({
                          customerNotesEdit: !this.state.customerNotesEdit,
                        });
                        this.setState({
                          note: this.props?.activeOrder?.user?.id?.notes,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
                <div>
                  {this.props?.activeOrder?.user?.id?.notes &&
                  !this.state.customerNotesEdit
                    ? this.props.activeOrder.user.id.notes
                    : ""}
                </div>
                {this.state.customerNotesEdit && (
                  <>
                    <Form>
                      <Form.Item className="mb-10">
                        <Input
                          placeholder="Add note"
                          value={this.state.note}
                          onChange={(e) => {
                            this.setState({
                              note: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Form>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({ prevNote: this.state.note });
                        const user = {};
                        user.notes = this.state.note;
                        this.props
                          .updateCustomer(
                            this.state.selectedCustomerId ||
                              (this.props.activeOrder &&
                                this.props.activeOrder.user.id.id),
                            {
                              notes: this.state.note,
                            }
                          )
                          .then((res) => {
                            if (res && res.error) {
                              message.error(res.error.message);
                            } else {
                              message.success(
                                `Notes Successfully Updated for ${this.state.selectedCustomerId}.`
                              );
                              if (this.state.note.length) {
                                this.setState({ customerNotesEdit: false });
                              } else {
                                this.setState({ customerNotesEdit: true });
                              }
                              this.props.getOrder(
                                this.props?.match?.params?.id
                              );
                            }
                          });
                      }}
                    >
                      Save
                    </Button>
                  </>
                )}
              </div>

              {this.props.activeOrder?.gift?.message && (
                <div className="credit-wrap notes-wrap products mb-28">
                  <div className="sub-title flex align-item justify-between">
                    <h4 className="semibold">Gift Message</h4>
                  </div>
                  <div className="regular">
                    {this.props.activeOrder && this.props.activeOrder.gift
                      ? `Sender: ${this.props.activeOrder?.gift?.from}`
                      : ""}
                  </div>
                  <div className="regular">
                    {this.props.activeOrder && this.props.activeOrder.gift
                      ? `Receiver: ${this.props.activeOrder?.gift?.to}`
                      : ""}
                  </div>
                  <div className="regular">
                    {this.props.activeOrder && this.props.activeOrder.gift
                      ? `Message: ${this.props.activeOrder?.gift?.message}`
                      : ""}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>

        {/* <hr />

        <div
          className={`flex justify-${
            activeOrder && activeOrder.type !== "default" ? "between" : "end"
          } pb-40`}
        >
          {activeOrder && activeOrder.type !== "default" && (
            <Button type="danger" onClick={this.handleDeleteOrder}>
              Delete
            </Button>
          )}

        </div> */}
        <div className="mb-20 contentLeft history">
          {this.props.activeOrder && (
            <>
              <div>
                <h3>
                  {" "}
                  Confirmation Email send :{" "}
                  <span>
                    {" "}
                    {this.props.activeOrder?.orderConfirmationEmailSent
                      ? "Success"
                      : "Fail"}{" "}
                  </span>
                </h3>{" "}
              </div>
              <div>
                <h3>
                  {" "}
                  Confirmation SMS send :
                  <span>
                    {" "}
                    {this.props.activeOrder?.confirmationSMSSent
                      ? "Success"
                      : "Fail"}
                  </span>
                </h3>{" "}
              </div>
            </>
          )}
          <HistoryBlock results={this.state.orderHistoryLogs} />
        </div>
        <AddProductsModal
          showModal={this.state.showAddProductModal}
          closeModal={() => this.setState({ showAddProductModal: false })}
          orderId={this.props?.activeOrder?._id}
          onClickOk={this.addNewProducts}
          orderItems={this.props?.activeOrder?.items.map(
            (prod) => prod.product.id
          )}
          allProducts={allProducts.filter(
            (prod) =>
              prod.category.name.toLowerCase() == "essentials" ||
              prod.category.name.toLowerCase() == "merch"
          )}
        ></AddProductsModal>
        <SerumTypeModal
          showSerumTypeModal={this.state.showSerumTypeModal}
          index={this.state.activeItemIndex}
          onClickOk={this.updateTrackingDetail}
          closeModal={this.closeSerumTypeModal}
        />
        <TrackingModal
          showExportModal={this.state.trackingModal}
          onClickOk={this.updateTrackingDetail}
          closeModal={this.closeTrackingModal}
          trackingNumber={
            this.props.activeOrder ? this.props.activeOrder.trackingNumber : ""
          }
          courierService={
            this.props.shipmentInfo
              ? this.props.shipmentInfo.courierService
              : ""
          }
        />
        <FlaggedModal
          orderId={this?.props?.activeOrder?._id}
          showFlaggedModal={this.state.showFlaggedModal}
          flaggedStatus={this.state.flaggedStatus}
          updateFlaggedStatus={this.props.updateFlaggedStatus}
          onClickOk={this.updateFlaggedStatus}
          closeModal={() =>
            this.setState({
              showFlaggedModal: false,
            })
          }
        />
      </div>
    );
  }
}

const AddOrderForm = Form.create({ name: "add_ingredients_form" })(AddOrder);

function mapStateToProps(state) {
  const {
    Customer,
    Products,
    Orders,
    Tags,
    Fulfillment,
    Assessment,
    latestAssessmentResult,
  } = state;
  return {
    allProducts: Products.products,
    customerList: Customer.customerList,
    customerAddressList: Customer.customerAddressList,
    activeOrder: Orders.activeOrder,
    tags: Tags.tags,
    refund: Orders.refund,
    shipmentInfo: Fulfillment.shipmentInfo,
    assessment: Assessment.activeCompleteAssessment,
    latestAssessmentResult: latestAssessmentResult,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    fetchCustomerList: bindActionCreators(
      customerActions.getCustomerList,
      dispatch
    ),
    fetchCustomerAddressList: bindActionCreators(
      customerActions.getCustomerAddressList,
      dispatch
    ),
    fetchCustomer: bindActionCreators(customerActions.getCustomer, dispatch),
    updateCustomer: bindActionCreators(
      customerActions.updateCustomer,
      dispatch
    ),
    addAddress: bindActionCreators(customerActions.createNewAddress, dispatch),
    updateAddress: bindActionCreators(customerActions.updateAddress, dispatch),
    updateShippingAddress: bindActionCreators(
      actions.updateShippingAddress,
      dispatch
    ),
    createOrder: bindActionCreators(actions.createOrder, dispatch),
    updateOrder: bindActionCreators(actions.updateOrder, dispatch),
    changeProductsInOrder: bindActionCreators(
      actions.changeProductsInOrder,
      dispatch
    ),

    removeProductsFromOrder: bindActionCreators(
      actions.removeProductsFromOrder,
      dispatch
    ),
    getOrder: bindActionCreators(actions.getOrder, dispatch),
    resetActiveOrder: bindActionCreators(actions.resetActiveOrder, dispatch),
    deleteOrder: bindActionCreators(actions.deleteOrder, dispatch),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
    getRefundForOrder: bindActionCreators(actions.getRefundForOrder, dispatch),
    getShipmentByOrderId: bindActionCreators(
      fulfillmentActions.getShipmentByOrderId,
      dispatch
    ),
    updateTrackingDetail: bindActionCreators(
      fulfillmentActions.updateTrackingDetail,
      dispatch
    ),
    addTrackingDetail: bindActionCreators(
      fulfillmentActions.addTrackingDetail,
      dispatch
    ),
    getCompletedAssessment: bindActionCreators(
      assessmentActions.getCompletedAssessmentById,
      dispatch
    ),
    getLatestResultForUser: bindActionCreators(
      assessmentActions.getLatestResultForUser,
      dispatch
    ),
    updateFlaggedStatus: bindActionCreators(
      actions.updateFlaggedStatus,
      dispatch
    ),
    addProductsInOrder: bindActionCreators(
      actions.addProductsInOrder,
      dispatch
    ),
    updateWaybill: bindActionCreators(actions.updateWaybill, dispatch),
    updateShipmentLabel: bindActionCreators(
      fulfillmentActions.updateShipmentLabel,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrderForm);
