
const initialState = {
  discountList: [],
  discountBulkList:[],
  discountCodesBulkList:[],
  activeDiscount: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_DISCOUNT":
      return setActiveDiscount(state, action);
    case "LIST_DISCOUNT":
      return listDiscounts(state, action);
    case "LIST_BULK_DISCOUNT":
      return listBulkDiscounts(state, action);
    case "LIST_BULK_DISCOUNT_CODES":
      return listBulkDiscounts(state, action);
    case "ADD_DISCOUNT":
      return addDiscount(state, action);
    case "UPDATE_DISCOUNT_LIST":
      return updateDiscountList(state, action);
    default:
      return state;
  }
}

function setActiveDiscount(state, action) {
  return {
    ...state,
    activeDiscount: action.payload ? { ...action.payload } : null
  }
}

function listDiscounts(state, action) {
  return {
    ...state, 
    discountList: { ...action.payload }
  };
}

function listBulkDiscounts(state, action) {
  return {
    ...state,
    discountBulkList: { ...action.payload },
  };
}

function listBulkDiscountsCodes(state, action) {
  return {
    ...state,
    discountCodesBulkList: { ...action.payload },
  };
}

function addDiscount(state, action) {
  return {
    ...state,
    discountList: [ { ...action.payload }, ...state.discountList ]
  };
}

function updateDiscountList(state, action) {
  const updatedDiscountList = [...state.discountList].map( discount => discount.id === action.payload.id ? {...action.payload} : discount);
  return {
    ...state,
    discountList: updatedDiscountList
  };
}
