import React, { Component } from "react";
import "./listProducts.scss";
import {
  Table,
  Input,
  Button,
  Icon,
  Modal,
  InputNumber,
  Badge
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/products";
import * as tagsActions from "actions/tags";
import { debounce } from "utils/helpers";
import SortIcon from "static/img/icon/sortIcon.svg";
import { CSVLink } from "react-csv";
const { Search } = Input;

class ListProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      sortOrder: 1,
      showExportModal: false,
      exportModalLoading: false,
      numberOfRows: 10
    };
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    this.props.fetchListProducts().then(() => this.props.fetchAllTags());
  }
  onChange = e => {
    console.log(e);
  };
  handleMenuClick = e => {
    console.log(e);
  };
  handleSearch = debounce(async value => {
    this.setState(
      {
        searchKey: value
      },
      () => {
        this.props.fetchListProducts(undefined, value);
      }
    );
  }, 300);
  handleSort = value => {
    this.setState(
      prevState => {
        return {
          sortOrder: prevState.sortOrder === "1" ? "-1" : "1"
        };
      },
      () =>
        this.props.fetchListProducts(
          undefined,
          undefined,
          undefined,
          this.state.sortOrder
        )
    );
  };
  handleFilter = value => {
    this.setState(
      {
        activeFilter: value
      },
      () => {
        this.props.fetchListProducts(value);
      }
    );
  };
  handleTagSearch = value => {
    this.props.fetchListProducts(undefined, undefined, value);
  };
  onChangeInput = value => {
    this.setState({ numberOfRows: value })
  }
  render() {
    const columns = [
      // {
      //   title: () => <Checkbox onChange={this.onChange}></Checkbox>,
      //   dataIndex: "Icon",
      //   key: "Icon",
      //   render: () => <Checkbox onChange={this.onChange}></Checkbox>
      // },
      {
        title: "Photo",
        dataIndex: "Photo",
        key: "Photo",
        render: photo =>
          photo && (
            <img
              src={photo.mediaUrl}
              style={{ maxHeight: "60px" }}
              alt="product"
            />
          )
      },
      {
        title: "Products",
        dataIndex: "Products",
        key: "Products"
      },
      {
        title: "Price in USD",
        dataIndex: "price",
        key: "price"
      },
      {
        title: "Inventory",
        dataIndex: "Inventory",
        key: "Inventory"
      },
      {
        title: "Category",
        dataIndex: "Category",
        key: "Category"
      },
      // {
      //   title: "Tags",
      //   key: "tags",
      //   dataIndex: "tags",
      //   render: tags => (
      //     <span>
      //       {tags && tags.map(tag => {
      //         return (
      //           <Tag className="mb-4" key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </span>
      //   )
      // },
      {
        title: "Units Sold",
        key: "sold",
        dataIndex: "sold"
      },
      {
        title: "Is Live",
        key: "type",
        dataIndex: "type",
        render: state => {
          console.log("Is Live----", state);

          return state === 'active' ? (
            <Badge status="success" />
          ) : (
              <Badge status="default" />
            );
        }
      },
      {
        title: "Recommended",
        key: "isRecommended",
        dataIndex: "isRecommended",
        
      },

    ];
    let headers = columns.map(item => {
      return {
        label: item.title,
        key: item.key
      }
    })
    const mappedProductList =
      this.props.productList.length > 0 ?
        this.props.productList.map(product => {
          return {
            id: product.id,
            Icon: "",
            Photo: product.mainImage.withoutHover,
            Products: product.name,
            price:
              product.prices && product.prices.length > 0
                ? `${product.prices.find(item => item.currency.code === "usd") ? product.prices.find(item => item.currency.code === "usd").price : ""}`
                : "",
            Inventory: product && product.inventory && product.inventory ? product.inventory : '-',
            Category: product.category.name,
            // tags: product.tags,
            type: product.type,
            sold: product && product.itemsSold && product.itemsSold ? product.itemsSold : '-',
            isRecommended: product &&product.isRecommended==true?"Yes":"No"
          };
        }) : [];
    let csvData = mappedProductList.map(
      (item) => {
        return { ...item, Photo: item.Photo ? item.Photo.mediaUrl : "-" }
      }
    ).slice(0, this.state.numberOfRows)

    return (
      <div className="products-list-page" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div className="theme-color semibold text-30 mr-14">Product List</div>
          <div className="flex items-center cursor-pointer"
            onClick={() => {
              this.setState({ showExportModal: true })
            }}>
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>

        <div className="flex nav-container justify-between">
          <div className="sub-text-top-left theme-color">All</div>
          <Button
            type="primary"
            onClick={() => {
              const win = window.open(`/products/edit`, "_blank");
              win.focus();
              //this.props.history.push("/products/edit")
            }
            }
            style={{ marginTop: "-14px" }}
          >
            <Icon type="plus"></Icon>
            Add product
          </Button>
        </div>

        <div>
          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            <Search
              placeholder="Search product name"
              onSearch={value => this.handleSearch(value)}
              style={{ width: "100%", marginRight: "5px" }}
              onKeyUp={e => this.handleSearch(e.target.value)}
            />
            {/* <Dropdown 
              overlay={menu} 
              style={{ width: "17.32%", marginRight: "5px" }}>
              <Button>
                Advanced Search <Icon type="down" />
              </Button>
            </Dropdown> */}
            {/* {advSearch} */}
            {/* <Dropdown overlay={menu}>
              <Button style={{ flex: 1, marginRight: "5px" }}>
                Filters <Icon type="down" />
              </Button>
            </Dropdown> */}
            <Button
              style={{ display: "flex", alignItems: "center" }}
              onClick={this.handleSort}
            >
              <span className="mr-4">Sort</span>
              <img src={SortIcon} style={{ maxHeight: "15px" }} alt="sort" />
            </Button>
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedProductList}
              bordered
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    const win = window.open(`/products/edit/${record.id}`, "_blank");
                    win.focus();
                    //this.props.history.push(`/products/edit/${record.id}`);
                  }
                };
              }}
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={() => { this.setState({ showExportModal: false }) }}
          onOk={async () => {
            this.setState({ exportModalLoading: true })
            this.setState({ exportModalLoading: false, showExportModal: false })
            this.csvRef.current && this.csvRef.current.link.click()
          }}
        >
          <InputNumber min={1} defaultValue={this.state.numberOfRows} onChange={this.onChangeInput} />
        </Modal>
        <CSVLink data={csvData} headers={headers} ref={this.csvRef} style={{ display: "none" }}
          filename='products.csv' />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Products, Tags } = state;
  return {
    productList: Products.products,
    tags: Tags.tags
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListProducts: bindActionCreators(
      actions.getSearchedProducts,
      dispatch
    ),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProducts);
