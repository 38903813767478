import React, { memo } from "react";
import { Modal, InputNumber, Radio } from "antd";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const ExportModal = ({
  showExportModal,
  exportModalLoading,
  toggleExportModal,
  onClickOk,
  numberOfRows,
  onChangeInput,
  exportMode,
  setExportMode,
  selectedOrderIds = [],
  totalCount,
  closeModal,
}) => {
  const onChange = (e) => {
    setExportMode(e.target.value);
  };

  return (
    <Modal
      title="Export order"
      visible={showExportModal}
      okText="Export"
      confirmLoading={exportModalLoading}
      onCancel={closeModal}
      onOk={onClickOk}
    >
      <Radio.Group defaultValue={0} onChange={onChange}>
        <Radio style={radioStyle} value={0} checked={exportMode === 0}>
          Number of rows {totalCount && `(Total: ${totalCount})`}
          <InputNumber
            min={1}
            value={numberOfRows}
            onChange={onChangeInput}
            style={{ marginLeft: 20 }}
            max={totalCount}
          />
        </Radio>
        <Radio
          disabled={selectedOrderIds.length === 0}
          style={radioStyle}
          value={1}
          checked={exportMode === 1}
        >
          Selected rows
          {selectedOrderIds.length === 0 && ` (Please select rows)`}
        </Radio>
      </Radio.Group>
    </Modal>
  );
};

export default memo(ExportModal);
