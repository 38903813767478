import React, { Component } from "react";
import "./addQuestion.scss";
import { Form ,Icon} from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/assessment";
import * as tagsActions from "actions/tags";
import QuestionStepTwo from "./questionStepTwo";
import QuestionStepOne from "./questionStepOne";
import QuestionStepThree from "./questionStepThree";
import { Link } from "react-router-dom";

class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionResponse: null,
      optionResponse: null,

      optionType: "single",
      lookup: "question",
      optionHaveIcons: false,
      noOfOptions: 4,

      stepOneEnabled: true,
      stepTwoEnabled: false,
      stepThreeEnabled: false,

      // when updating a question
      mode: "create",
      questionId: null,
      assessmentId: null
    };
    this.questionFormRef = React.createRef();
    this.optionFormRef = React.createRef();
  }
  componentDidMount() {
    this.fetchQuestion()
    this.props.fetchGroupList();
    this.props.fetchAssessmentList();
    this.props.fetchQuestionList();
    this.props.fetchAllTags();
    this.props.fetchParameterList();
  }
  componentWillUnmount() {
    if(this.isEditMode()) {
      this.props.resetActiveQuestion();
    }
  }
  fetchQuestion=()=>{
    const {
      match: {
        params: { id: assessmentId, questionId }
      }
    } = this.props;
    if( assessmentId && questionId) {
      this.setState({
        mode: "edit",
        questionId,
        assessmentId,
        stepTwoEnabled: true,
        stepThreeEnabled: true
      });
      this.props
        .getQuestion(questionId)
        .then( res => {
          if(res) {
            this.setState({
              noOfOptions: res.options.length,
              optionType: this.getOptionTypeFromResponse(res),
              lookup: res.nextQuestionLookup,
              optionHaveIcons: res.optionsHaveIcons
            });
          }
        });
      this.props.getParameterQuestionMappingList(questionId,assessmentId);
    }
  }
  getOptionTypeFromResponse = (response) => {
    if(response.optionsAreRange) {
      return "range";
    }
    else if(response.isMultiSelect) {
      return "multi";
    }
    else {
      return "single";
    }
  }
  isEditMode = () => {
    return this.state.mode === "edit";
  }
  onChange = value => {
    console.log(`selected ${value}`);
  };
  setQuestionResponse = value => {
    this.setState({
      questionResponse: value
    });
  };
  setOptionResponse = value => {
    this.setState({
      optionResponse: value
    });
  };
  setOptionType = value => {
    this.setState({
      optionType: value
    });
  };
  setLookup = value => {
    this.setState({
      lookup: value
    });
  };
  toggleOptionHaveIcons = value => {
    this.setState({
      optionHaveIcons: value
    });
  };
  setNoOfOptions = value => {
    const { activeQuestion } = this.props;
    if(activeQuestion) {
      if(value >= activeQuestion.options.length && value <= 20) {
        this.setState({
          noOfOptions: value
        });
      }
    }
    else if(value >= 1 && value <= 20) {
      this.setState({
        noOfOptions: value
      });
    }
  };
  setEnabledStepOne = value => {
    this.setState({
      stepOneEnabled: value
    });
  };
  setEnabledStepTwo = value => {
    this.setState({
      stepTwoEnabled: value
    });
  };
  setEnabledStepThree = value => {
    this.setState({
      stepThreeEnabled: value
    });
  };
  render() {
    const {
      groupList,
      assessmentList,
      questionList,
      tagList,
      parameterList,
      parameterQuestionMappingList,
      activeQuestion,
      createQuestion,
      createOption,
      updateOption,
      createParameterQuestionMapping,
      updateParameterQuestionMapping,
      updateQuestion
    } = this.props;
    const {
      lookup,
      optionType,
      optionHaveIcons,
      noOfOptions,
      stepOneEnabled,
      stepTwoEnabled,
      stepThreeEnabled,
      questionResponse,
      optionResponse,
      questionId,
      assessmentId
    } = this.state;
    return (
      <div className="addQuestions-page" style={{ marginLeft: "200px" }}>
          <Link to={`/assessment/${assessmentId}/questions`}>
              <div className="flex justify-start items-center cursor-pointer">
                <div>
                  <Icon className="mr-4" type="left" />
                </div>
                <div className="text-14 pb-2">Back</div>
              </div>
            </Link>
        <div className="theme-color semibold text-30 mb-12">
          {/* {this.isEditMode() && this.props.activeProduct
            ? this.props.activeProduct.name
            : ""} */}
        </div>
        <h1 className="assessment-header text-xl bold theme-color">
          {this.isEditMode() ? "Update" : "Create"} Question
        </h1>
        <Form onSubmit={this.handleSubmit}>
          <div className="flex">
            <div className="contentLeft">
              <QuestionStepOne
                enabled={stepOneEnabled}
                setEnabled={this.setEnabledStepOne}
                setNextStepEnabled={this.setEnabledStepTwo}
                questionList={questionList}
                groupList={groupList}
                assessmentList={assessmentList}
                optionType={optionType}
                lookup={lookup}
                optionHaveIcons={optionHaveIcons}
                createQuestion={createQuestion}
                setOptionType={this.setOptionType}
                setQuestionResponse={this.setQuestionResponse}
                setLookup={this.setLookup}
                toggleOptionHaveIcons={this.toggleOptionHaveIcons}
                ref={this.questionFormRef}
                activeQuestion={activeQuestion}
                isEditMode={this.isEditMode()}
                updateQuestion={updateQuestion}
                questionId={questionId}
                assessmentId={assessmentId}
              />

              <QuestionStepTwo
                enabled={stepTwoEnabled}
                setEnabled={this.setEnabledStepTwo}
                setNextStepEnabled={this.setEnabledStepThree}
                questionList={questionList}
                tagList={tagList}
                questionResponse={questionResponse}
                optionType={optionType}
                lookup={lookup}
                optionHaveIcons={optionHaveIcons}
                noOfOptions={noOfOptions ? noOfOptions : 1}
                createOption={createOption}
                updateOption={updateOption}
                updateQuestion={updateQuestion}
                setOptionResponse={this.setOptionResponse}
                setNoOfOptions={this.setNoOfOptions}
                ref={this.optionFormRef}
                activeQuestion={activeQuestion}
                isEditMode={this.isEditMode()}
                questionId={questionId}
                assessmentId={assessmentId}
                parameterQuestionMappingList={parameterQuestionMappingList}
                fetchQuestion={this.fetchQuestion}
              />
            </div>
            <div className="contentRight flex flex-col">
              <QuestionStepThree
                enabled={stepThreeEnabled}
                setEnabled={this.setEnabledStepThree}
                parameterList={parameterList}
                parameterQuestionMappingList={parameterQuestionMappingList}
                noOfOptions={noOfOptions ? noOfOptions : 1}
                questionResponse={questionResponse}
                optionResponse={optionResponse}
                questionId={questionId}
                assessmentId={assessmentId}
                activeQuestion={activeQuestion}
                isEditMode={this.isEditMode()}
                createParameterQuestionMapping={createParameterQuestionMapping}
                updateParameterQuestionMapping={updateParameterQuestionMapping}
                fetchQuestion={this.fetchQuestion}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Assessment, Tags } = state;
  return {
    groupList: Assessment.groupList,
    assessmentList: Assessment.assessmentList,
    questionList: Assessment.questionList,
    parameterList: Assessment.parameterList,
    parameterQuestionMappingList: Assessment.parameterQuestionMappingList,
    tagList: Tags.tags,
    activeQuestion: Assessment.activeQuestion
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGroupList: bindActionCreators(actions.getGroupList, dispatch),
    fetchAssessmentList: bindActionCreators(
      actions.getSearchedAssessment,
      dispatch
    ),
    fetchQuestionList: bindActionCreators(
      actions.getSearchedQuestion,
      dispatch
    ),
    fetchParameterList: bindActionCreators(actions.searchParameter, dispatch),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
    createQuestion: bindActionCreators(actions.createQuestion, dispatch),
    createOption: bindActionCreators(actions.createOption, dispatch),
    createParameterQuestionMapping: bindActionCreators(
      actions.createParameterQuestionMapping,
      dispatch
    ),
    getQuestion: bindActionCreators(actions.getQuestionById, dispatch),
    updateQuestion: bindActionCreators(actions.updateQuestionById, dispatch),
    updateOption: bindActionCreators(actions.updateOptionById, dispatch),
    resetActiveQuestion: bindActionCreators(actions.resetActiveQuestionAction, dispatch),
    getParameterQuestionMappingList: bindActionCreators(actions.getParameterQuestionMappingList, dispatch),
    updateParameterQuestionMapping: bindActionCreators(actions.updateParameterQuestionMapping, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
