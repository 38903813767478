import React, { Fragment, useEffect, useState } from "react";
import "./invoice.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/orders";
import { useLocation } from "react-router-dom";
import InvoiceSheet from "./invoiceSheet";
import { message } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Invoice = (props) => {
  let query = useQuery();
  const orderIds = query.get("orders");
  const [ordersInvoice, setOrderInvoice] = useState([]);
  useEffect(() => {
    props.getOrdersInvoice(orderIds).then((res) => {
      if (res) {
        setOrderInvoice(res);
      } else {
        message.error("Oops! Something went wrong");
      }
    });
  }, []);

  return ordersInvoice.length > 0 ? (
    <div className="view-invoice">
      <body className="A4">
        {/* <!-- Each sheet element should have the class "sheet" --> */}
        {/* <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 --> */}
        {ordersInvoice.map((invoice, index) => {
          const currencyCode = invoice.currency.code;
          const currency = getSymbolFromCurrency(currencyCode);
          return (
            <Fragment key={index}>
              <InvoiceSheet
                data={invoice}
                currency={currency ? currency : ""}
              />
            </Fragment>
          );
        })}
      </body>
    </div>
  ) : (
    ""
  );
};

function mapStateToProps(state) {
  const { Orders } = state;
  return {
    ordersList: Orders.ordersList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrdersInvoice: bindActionCreators(actions.getOrdersInvoice, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
