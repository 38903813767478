import ProductsApi from "services/products";

function handleError(err) {
  console.error(err);
}


function listProducts(result) {
  return {
    type: "LIST_ALL_PRODUCTS",
    payload: result
  };
}


export const getActiveProducts = (params) => async dispatch => {
   try {
    let res = await ProductsApi.getActiveProductsList(params);
    //console.log("Response", res)
    dispatch(listProducts(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateStock = (id,params,callBack) => async dispatch => {
  try {
    let res = await ProductsApi.updateProductStock(id,params);
    callBack (res)
  } catch (err) {
    handleError(err);
  }
}

