const initialState = {
  products: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LIST_ALL_PRODUCTS":
      return listProducts(state, action);
    case "SET_PRODUCT_STOCK_VALUE":
      return updateStockOfProduct(state, action);
    default:
      return state;
  }
}

function listProducts(state, action) {
  return {
    ...state,
    products: [...action.payload],
  };
}

function updateStockOfProduct(state, action) {
  let products = [...state.products];
  let productIndex = products.findIndex(
    (element) => element.id == action.payload.id
  );
  let propertyToChange = action.payload.property;

  products[productIndex][propertyToChange] = action.payload.value;
  return {
    ...state,
    products,
  };
}
