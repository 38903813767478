import React, { Component } from "react";
import "./customerProfile.scss";
import {
  Table,
  Icon,
  Form,
  Input,
  Button,
  Select,
  message,
  Modal,
  DatePicker,
  Menu,
  Dropdown,
  Tooltip,
} from "antd";
import Switch from "react-switch";
import pauseImage from "./noun_pause_1948186-3.svg";
import textIconImage from "./textIcon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import * as actions from "actions/tags";
import * as actions from "actions/customer";
//import { debounce } from "utils/helpers";
import { CustomerAddress } from "../customerAddress/customerAddress";
import CreatePhoneForm from "forms/createPhoneForm";
import UpdateEmailForm from "forms/updateEmailForm";
import { Link } from "react-router-dom";
import * as ordersActions from "actions/orders";
import { getAssessmentAttemptList, getSelfieList } from "actions/assessment";
import moment from "moment";
import Comments from "components/customers/Comments";
import ErrorBoundary from "components/errorBoundary";
import UserSocialInfo from "components/user-social-info";
import {
  orderFullfillmentStatusListing,
  paymentStatusMapping,
  customerTypeMapping,
} from "utils/constants";
import * as tagsActions from "actions/tags";
import {
  skipSubscription,
  cancelSubscription,
  editSubscription,
} from "./../../../services/orders";
import Lightbox from "react-image-lightbox";
const { TextArea } = Input;

const { Option } = Select;
const { confirm } = Modal;
class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rescheduleSubscriptionDate: null,
      currentSubscriptionDate: null,
      currentSubscriptionId: null,
      currentSubscriptionPeriodValue: null,
      newSubscriptionPeriodValue: null,
      subscriptionPauseStatus: null,
      currentUserId: null,
      showAddressForm: false,
      appartment: "",
      street: "",
      city: "",
      state: "",
      country: "",
      note: "",
      tags: [],
      prevNote: "",
      prevTags: [],
      tagsEdit: false,
      notesEdit: false,
      showAddressFormEditMode: false,
      showPhoneFormEditMode: false,
      showEmailFormEditMode: false,
      isModalVisible: false,
      cancelModalVisible: false,
      editModalVisible: false,
      cancelSubscriptionText: "",
      userType: null,
      receiveEmailUpdate: false,
      receiveSMSUpdate: false,
      showCancelReasonTextBox: false,
      selectedSelfie: "",
    };
    this.commentRef = React.createRef();
    this.handleCancelTextInput = this.handleCancelTextInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const customerId = params.id;
    const userType = localStorage.getItem("userType");
    this.setState({
      userType,
    });
    if (customerId) {
      await this.props.fetchAllTags();
      this.props.fetchCustomer(customerId).then((res) => {
        if (res) {
          this.setState({
            note: res.notes,
            tags: res.tags && res.tags.map((tag) => tag.id),
            prevNote: res.notes,
            prevTags: res.tags && res.tags.map((tag) => tag.id),
            receiveEmailUpdate: res.receiveEmailUpdate,
            receiveSMSUpdate: res.receiveSMSUpdate,
          });
        }
      });
      this.props.fetchCustomerAddressList(customerId, `isPrimary=false`);
      this.props.getCustomerOrders(
        `user.id=${customerId}&status=2&status=4&status=3`
      );
      this.props.getSubscriptions(customerId);
      this.props.getAssessmentAttemptList(`user=${customerId}`);
      this.props.getSelfieList(customerId);
    } else {
      message.error("Invalid route");
      this.props.history.push("/customers");
    }
  }

  //----Show address form---
  handleShowAddressForm = () => {
    this.setState({
      showAddressForm: true,
    });
  };

  //----handle Add address ---
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {
          match: { params },
        } = this.props;
        const customerId = params.id;
        if (this.state.showAddressFormEditMode) {
          const primaryAddressId = this.props.customerAddressList[0]._id;
          values["user"] = customerId;
          this.props.updateAddress(primaryAddressId, values).then((res) => {
            if (res) {
              this.setState({ showAddressFormEditMode: false });
              this.props.fetchCustomerAddressList(customerId, `isPrimary=true`);
            }
          });
        } else {
          values["user"] = customerId;
          this.props.addAddress(values, true).then((res) => {
            if (res) {
              this.setState({ showAddressForm: false });
              this.props.fetchCustomerAddressList(customerId, `isPrimary=true`);
            }
          });
        }
      }
    });
  };
  handleDeactivateCustomer = (customerId) => {
    let payload = {
      isActive: false,
    };
    confirm({
      title: `Are you sure you want to deactivate this account?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.updateCustomer(customerId, payload).then((res) => {
          this.props.history.push("/customers");
          if (res) {
            message.success("Account blocked successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  handleUpdateAddress = () => {
    this.props.updateAddress();
  };
  handleActivateCustomer = (customerId) => {
    let payload = {
      isActive: true,
    };
    confirm({
      title: `This account would be activated again?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.updateCustomer(customerId, payload).then((res) => {
          this.props.history.push("/customers");
          if (res) {
            message.success("Account activated successfully");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  showModal = () => {
    this.setState({ isModalVisible: true });
  };
  handleEditChange(value) {
    console.log(`selected ${value}`);
    this.setState({ newSubscriptionPeriodValue: value });
  }

  handleSkipOk = async () => {
    const payload = {
      productsToChange: {},
      skipSubscriptionPeriod: {
        date: this.state.rescheduleSubscriptionDate,
      },
    };
    const result = await skipSubscription(
      this.state.currentSubscriptionId,
      payload
    );
    this.props.getSubscriptions(`${this.props.activeCustomer._id}`);
    if (result) {
      this.setState({
        isModalVisible: false,
        rescheduleSubscriptionDate: null,
      });
    }
  };

  handlePause = async () => {
    let payload = {};
    if (this.state.subscriptionPauseStatus == "Activate") {
      payload.isPaused = false;
    } else {
      payload.isPaused = true;
    }
    const result = await skipSubscription(
      this.state.currentSubscriptionId,
      payload
    );
    this.props.getSubscriptions(`${this.props.activeCustomer._id}`);
    if (result) {
      this.setState({
        isModalVisible: false,
      });
    }
  };

  handleCancelOk = async () => {
    const payload = {
      isActive: false,
      cancelSubReason: this.state.cancelSubscriptionText,
    };
    if (
      this.state.cancelSubscriptionText &&
      this.state.cancelSubscriptionText != "Others:"
    ) {
      const result = await cancelSubscription(
        this.state.currentSubscriptionId,
        payload
      );
      this.props.getSubscriptions(`${this.props.activeCustomer._id}`);
      if (result) {
        this.setState({
          cancelModalVisible: false,
          cancelSubscriptionText: "",
        });
      }
    } else {
      alert("Please, enter a valid reason");
    }
    this.setState({
      showCancelReasonTextBox: false,
    });
  };
  handleEditOk = async () => {
    const payload = {
      subscriptionPeriod: {
        value: parseInt(this.state.newSubscriptionPeriodValue),
        frequency: "days",
      },
    };
    const result = await editSubscription(
      this.state.currentSubscriptionId,
      payload
    );
    this.props.getSubscriptions(`${this.props.activeCustomer._id}`);
    if (result) {
      this.setState({
        editModalVisible: false,
        newSubscriptionPeriodValue: null,
      });
    }
  };
  handleEditCancel = () => {
    this.setState({ editModalVisible: false });
  };
  handleCancelTextInput(e) {
    this.setState({
      cancelSubscriptionText: e.target.value,
    });
  }
  handleChange = (value) => {
    console.log(`selected ${value}`);
    this.setState({
      cancelSubscriptionText: value,
    });
    if (value == "Others:") {
      this.setState({
        showCancelReasonTextBox: true,
      });
    } else {
      this.setState({
        showCancelReasonTextBox: false,
      });
    }
    //console.log("This value", this.state.showCancelReasonTextBox)
  };
  handleSkipCancel = () => {
    this.setState({ isModalVisible: false });
  };
  handleCancel = () => {
    this.setState({
      cancelModalVisible: false,
      cancelSubscriptionText: " ",
      showCancelReasonTextBox: false,
    });
  };
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };
  SelectDates = (val) => {
    if (val) {
      this.setState({
        rescheduleSubscriptionDate: val,
      });
    }
  };

  getTotalSpendValue = () => {
    const { ordersList } = this.props;
    let totalSpendValue = 0;
    ordersList &&
      ordersList.map((order) => {
        if (order && order.chargedPrice) {
          totalSpendValue += order.chargedPrice;
        }
      });
    return totalSpendValue;
  };

  getAvgOrderValue = () => {
    const { ordersList } = this.props;
    let data = 0;
    let noOfRecord = 0;
    ordersList &&
      ordersList.map((order) => {
        if (order && order.chargedPrice) {
          data += order.chargedPrice;
          noOfRecord++;
        }
      });
    if (data > 0) {
      data = data / noOfRecord;
    }
    return data;
  };

  render() {
    const menu = (subscriptionId) => (
      <Menu>
        <Menu.Item>
          <Link
            to={`/customer/${this.props.match.params.id}/subscription/${subscriptionId.id}`}
          >
            Edit
          </Link>
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            this.setState({
              isModalVisible: true,
            })
          }
        >
          Reschedule
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            this.setState({
              editModalVisible: true,
            })
          }
        >
          Edit Frequency
        </Menu.Item>
        <Menu.Item onClick={this.handlePause}>
          {this.state.subscriptionPauseStatus}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            this.setState({
              cancelModalVisible: true,
            })
          }
        >
          Cancel
        </Menu.Item>
      </Menu>
    );

    const orderColumns = [
      {
        title: "Order ID",
        dataIndex: "order",
        key: "order",
        render: (order) => (
          <div
            className="cursor-pointer"
            style={{ color: "#373873" }}
            onClick={(e) => {
              e.stopPropagation();
              let a = order.slice(1, order.length);
              console.log(a, "value");
              this.props.history.push(`/orders/edit/${a}`);
            }}
          >
            {order}
          </div>
        ),
      },
      {
        title: "Date / Time",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Payment",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Fullfillment",
        dataIndex: "fulfillmentStatus",
        key: "fulfillmentStatus",
      },
    ];
    const upcomingOrderColumns = [
      {
        title: "Product",
        dataIndex: "product",
        key: "product",
        render: (product) =>
          product.isActive
            ? product.items.map((x) => {
                return <div>{x?.product?.name}</div>;
              })
            : product.items.map((x) => {
                return (
                  <div>
                    <s>{x.product.name}</s>
                  </div>
                );
              }),
      },
      {
        title: "Date / Time",
        dataIndex: "date",
        key: "date",
        render: (date) =>
          date.isActive ? (
            <div>
              {moment(parseInt(date.nextFulfilment)).format(
                "MMM DD, YYYY [at] hh:mm a"
              )}
            </div>
          ) : (
            <div>
              <s>
                {moment(parseInt(date.nextFulfilment)).format(
                  "MMM DD, YYYY [at] hh:mm a"
                )}
              </s>
            </div>
          ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) =>
          amount.isActive ? (
            <div>
              {amount && amount.currency && amount.currency.toUpperCase()}
              &nbsp;&nbsp;{Math.round(amount.chargedPrice)}
            </div>
          ) : (
            <div>
              <s>
                {amount && amount.currency && amount.currency.toUpperCase()}
                &nbsp;&nbsp;{Math.round(amount.chargedPrice)}
              </s>
            </div>
          ),
      },
      {
        title: "Frequency",
        dataIndex: "frequency",
        key: "frequency",
        render: (frequency) =>
          frequency.isActive ? (
            <div>
              {frequency.period.value}&nbsp;&nbsp;{frequency.period.frequency}
            </div>
          ) : (
            <div>
              <s>
                {frequency.period.value}&nbsp;&nbsp;{frequency.period.frequency}
              </s>
            </div>
          ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          const subscriptionStatus = status.isActive
            ? status.isPaused
              ? "Paused"
              : "Active"
            : "Cancelled";
          if (subscriptionStatus === "Paused") {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>{subscriptionStatus} </div>
                <img
                  src={pauseImage}
                  style={{
                    width: 15,
                    height: 15,
                    marginLeft: "3px",
                    marginTop: "3px",
                  }}
                ></img>
              </div>
            );
          } else if (subscriptionStatus === "Cancelled") {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>{subscriptionStatus} </div>
                <Tooltip title={status.cancelSubReason}>
                  <img
                    src={textIconImage}
                    style={{
                      width: 15,
                      height: 15,
                      marginLeft: "3px",
                      marginTop: "3px",
                    }}
                  ></img>
                </Tooltip>
              </div>
            );
          } else {
            return <div>{subscriptionStatus}</div>;
          }
        },
      },
      {
        dataIndex: "subscriptionId",
        key: "subscriptionId",
        render: (subscriptionId) =>
          subscriptionId.isActive &&
          (this.state.userType === "admin" ||
            this.state.userType === "basic") ? (
            <Dropdown overlay={menu(subscriptionId)} trigger={["click"]}>
              <Button
                onClick={() =>
                  this.setState({
                    currentSubscriptionId: subscriptionId.id,
                    currentSubscriptionDate: moment(
                      parseInt(subscriptionId.nextFulfilment)
                    ).format("MMM DD, YYYY"),
                    currentSubscriptionPeriodValue: subscriptionId.period.value,
                    subscriptionPauseStatus: subscriptionId.isPaused
                      ? "Activate"
                      : "Pause",
                  })
                }
              >
                Actions
              </Button>
            </Dropdown>
          ) : (
            <Button disabled>Actions</Button>
          ),
      },
    ];
    const uniqueSubscriptions = [];
    const upcomingOrderData =
      this.props.subscriptionList && this.props.subscriptionList.length > 0
        ? this.props.subscriptionList
            .map((subscription) => {
              let upcomingOrder = null;
              if (
                subscription._id &&
                !uniqueSubscriptions.includes(subscription._id)
              ) {
                upcomingOrder = {
                  subscriptionId: subscription,
                  product: subscription,
                  date: subscription,
                  frequency: subscription,
                  status: subscription,
                  amount: subscription,
                };
                uniqueSubscriptions.push(subscription._id);
              }
              return upcomingOrder;
            })
            .filter((upcomingOrder) => upcomingOrder !== null)
        : [];
    const orderData =
      this.props.ordersList && this.props.ordersList.length > 0
        ? this.props.ordersList.map((order) => {
            return {
              key: order.id,
              order:
                `#${order._id}` +
                `${order && order.shortId ? " / " + order.shortId : "-"}`,
              date: moment(order.createdAt).format("MMM DD, YYYY [at] hh:mm a"),
              amount: `${
                order && order.currency && order.currency.code.toUpperCase()
              } ${order.chargedPrice}`,
              status: paymentStatusMapping[order.status],
              fulfillmentStatus:
                orderFullfillmentStatusListing[order.fulfillmentStatus],
            };
          })
        : [];
    const assessmentsColumns = [
      {
        title: "Submission ID",
        dataIndex: "assessment",
        key: "assessment",
        render: (assessment) => (
          <div
            className="cursor-pointer"
            style={{ color: "#373873" }}
            onClick={(e) => {
              this.props.history.push(`/assessment-detail/${assessment}`);
            }}
          >
            {assessment}
          </div>
        ),
      },
      {
        title: "Date / Time",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      // {
      //   title: "Selfie URL",
      //   dataIndex: "selfie",
      //   key: "selfie",
      //   render: (text) => <button className="selfie-url">{text}</button>,
      // },
    ];

    const selfieLibraryColumns = [
      {
        title: "Selfie URL",
        dataIndex: "assessment",
        key: "assessment",
        render: (assessment, row) => {
          return (
            <div
              className="cursor-pointer"
              style={{ color: "#373873" }}
              onClick={() =>
                this.setState({
                  selectedSelfie: row.image,
                })
              }
            >
              {assessment}
            </div>
          );
        },
      },
      {
        title: "Upload Via",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Date / Time",
        dataIndex: "date",
        key: "date",
      },
    ];

    const assessmentAttemptData = this.props.assessmentAttemptList.map(
      (assessment) => {
        return {
          key: assessment.id,
          assessment: `${assessment.id}`,
          date: moment(assessment.createdAt).format(
            "MMM DD, YYYY [at] hh:mm a"
          ),
          status: assessment.status,
          selfie: "",
        };
      }
    );

    const selfieList = this.props.selfieList.map((assessment) => {
      return {
        assessment: assessment.id,
        image: assessment.image,
        date: moment(assessment.createdAt).format("MMM DD, YYYY [at] hh:mm a"),
        type: assessment.type === 'assessment' ? 'Assessment' : 'My Account',
      };
    });
    const { getFieldDecorator } = this.props.form;
    const { activeCustomer, ordersList, location } = this.props;
    return (
      <div className="customer-page-wrap">
        <div className="flex justify-between customer-title-wrap">
          <div className="customer-title">
            <Link
              className="back-link"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <Icon type="left" />
              <span>Back</span>
            </Link>
            <div
              className="theme-color semibold text-30 mr-14"
              style={{ marginBottom: "15px" }}
            >
              {activeCustomer ? activeCustomer.firstName : ""}{" "}
              {activeCustomer ? activeCustomer.lastName : ""}
            </div>

            <div className="feature-wrap flex items-center">
              {activeCustomer && activeCustomer.isActive ? (
                <div
                  onClick={() =>
                    this.handleDeactivateCustomer(activeCustomer.id)
                  }
                  className="flex cursor-pointer items-center mr-20"
                >
                  <Icon type="stop" className="mr-4" />
                  <div>Disable account</div>
                </div>
              ) : (
                <div
                  onClick={() => this.handleActivateCustomer(activeCustomer.id)}
                  className="flex cursor-pointer items-center mr-20"
                >
                  <Icon type="redo" className="mr-4" />
                  <div>Enable account</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="customer-page-content flex justify-between">
          <div className="left">
            <div style={{ padding: "25px" }} className="customer-order-wrap">
              <div style={{ paddingBottom: "8px" }} className="bold text-base">
                {`${activeCustomer && activeCustomer.firstName} ${
                  activeCustomer && activeCustomer.lastName
                }`}
              </div>
              {this.props.customerAddressList &&
                this.props.customerAddressList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="semibold"
                      style={{ color: "#656565", fontSize: "14px" }}
                    >
                      {item.city ? item.city + ", " : ""}
                      {item.state ? item.state + ", " : ""}
                      {item.country ? item.country + "" : ""}
                    </div>
                  );
                })}
              <div style={{ paddingTop: "21px" }} className="">
                <div className="flex">
                  <h4 className="bold" style={{ color: "#8c8c8c" }}>
                    User Type:
                  </h4>
                  <h4
                    style={{
                      paddingLeft: "3px",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    {activeCustomer &&
                      customerTypeMapping[activeCustomer.customerType]}
                  </h4>
                </div>
              </div>
              <div
                style={{ paddingTop: "15px" }}
                className="flex justify-between"
              >
                <div
                  style={{ width: "150px" }}
                  className="center-card flex flex-col items-center"
                >
                  <div
                    style={{ color: "#363874", fontSize: "14px" }}
                    className=""
                  >
                    Last order
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      paddingTop: "12px",
                      fontWeight: "600",
                    }}
                    className="text-black"
                  >
                    {activeCustomer && activeCustomer.lastOrderDate
                      ? moment(activeCustomer.lastOrderDate).format(
                          "DD MMMM YYYY"
                        )
                      : "-"}
                  </div>
                </div>
                <div
                  style={{ width: "150px" }}
                  className="center-card flex flex-col items-center"
                >
                  <div
                    style={{ color: "#656565", fontSize: "14px" }}
                    className=""
                  >
                    Total spend to date
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      paddingTop: "12px",
                      fontWeight: "600",
                    }}
                    className="text-black"
                  >
                    {activeCustomer &&
                      activeCustomer.totalSpent &&
                      activeCustomer.totalSpent.currency.toUpperCase()}
                    {activeCustomer &&
                      activeCustomer.totalSpent &&
                      " " + activeCustomer.totalSpent.value}
                  </div>
                  <div
                    style={{
                      fontSizcurrencye: "14px",
                      color: "#656565",
                      paddingTop: "12px",
                    }}
                    className=""
                  >
                    {activeCustomer && activeCustomer.totalCompletedOrders
                      ? activeCustomer.totalCompletedOrders + " order(s)"
                      : "-"}
                  </div>
                </div>
                <div
                  style={{ width: "150px" }}
                  className="center-card flex flex-col items-center"
                >
                  <div
                    style={{ color: "#656565", fontSize: "14px" }}
                    className=""
                  >
                    Average order value
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      paddingTop: "12px",
                      fontWeight: "600",
                    }}
                    className="text-black"
                  >
                    {activeCustomer &&
                      activeCustomer.totalSpent &&
                      activeCustomer.totalSpent.currency.toUpperCase()}
                    {activeCustomer &&
                    activeCustomer.averageOrderValue &&
                    activeCustomer.averageOrderValue
                      ? " " + activeCustomer.averageOrderValue
                      : " 0"}
                  </div>
                </div>
              </div>
            </div>
            <div className="customer-order-wrap">
              <div className="customer-order-title">
                <h2 className="semibold">Upcoming Orders</h2>
                <span>
                  {activeCustomer && upcomingOrderData
                    ? `${upcomingOrderData.length} order${
                        upcomingOrderData.length > 0 ? "s" : ""
                      }`
                    : ""}
                </span>
              </div>
              <Table
                columns={upcomingOrderColumns}
                dataSource={upcomingOrderData}
              />
              {/* <div className="view-all-assessment">View all Orders</div> */}
            </div>
            <div className="customer-order-wrap">
              <div className="customer-order-title">
                <h2 className="semibold">Orders</h2>
                <span>
                  {activeCustomer && ordersList
                    ? `${ordersList.length} order${
                        ordersList.length > 0 ? "s" : ""
                      }`
                    : ""}
                </span>
              </div>
              <Table columns={orderColumns} dataSource={orderData} />
              {/* <div className="view-all-assessment">View all Orders</div> */}
            </div>
            <div className="customer-order-wrap">
              <div className="customer-order-title">
                <h2 className="semibold">Assessments</h2>
                <span>{`${assessmentAttemptData.length} Assessment${
                  assessmentAttemptData.length > 1 ? "s" : ""
                } Taken`}</span>
              </div>
              <Table
                columns={assessmentsColumns}
                dataSource={assessmentAttemptData}
              />
            </div>
            <div className="customer-order-wrap">
              <div className="customer-order-title">
                <h2 className="semibold">Selfie Library</h2>
              </div>
              <Table columns={selfieLibraryColumns} dataSource={selfieList} />
            </div>
            <div className="customer-order-wrap">
              {activeCustomer && (
                <div className="desc comments">
                  <p className="semibold text-18 text-black mb-10">Comments</p>
                  <ErrorBoundary>
                    <Comments
                      ref={this.commentRef}
                      customerId={activeCustomer?.id}
                    />
                  </ErrorBoundary>
                </div>
              )}
            </div>
          </div>

          <div className="right">
            <div className="customer-overview-wrap">
              <h2 className="semibold">Customer Overview</h2>
              <div className="order-info">
                <strong className="semibold">
                  {`${activeCustomer && activeCustomer.firstName} ${
                    activeCustomer && activeCustomer.lastName
                  }`}
                </strong>
                <span>
                  {ordersList.length} order
                  {`${ordersList.length > 1 ? "s" : ""}`}
                </span>
              </div>
              <div className="contact-info">
                <div className="contact-title flex item-center justify-between">
                  <h3 className="semibold">Contact Information</h3>
                  <div
                    onClick={() =>
                      this.setState((prevState) => {
                        return {
                          showEmailFormEditMode:
                            !prevState.showEmailFormEditMode,
                        };
                      })
                    }
                    className="cursor-pointer"
                  >
                    Edit
                  </div>
                </div>
                <div className="user-email-with-logo">
                  <strong className="semibold">
                    {activeCustomer && activeCustomer.email}
                  </strong>
                  {activeCustomer && (
                    <UserSocialInfo identities={activeCustomer.identities} />
                  )}
                </div>
                {this.state?.showEmailFormEditMode &&
                  this.props?.match?.params?.id && (
                    <UpdateEmailForm
                      email={
                        activeCustomer && activeCustomer.email
                          ? activeCustomer.email
                          : ""
                      }
                      customerId={this.props.match.params.id}
                      prevEmail={activeCustomer?.email}
                      updateEmail={this.props.updateCustomer}
                      activeId={this.props.match.params.id}
                      onEmailUpdate={() => {
                        this.setState({
                          showEmailFormEditMode: false,
                        });
                        this.props.fetchCustomer(this.props.match.params.id);
                      }}
                    />
                  )}
              </div>
              {activeCustomer?.instagramHandel && (
                <div className="contact-info">
                  <div className="contact-title flex item-center justify-between">
                    <h3 className="semibold">Instagram Username </h3>
                  </div>
                  <div className="user-email-with-logo">
                    <strong className="semibold">
                      {activeCustomer && activeCustomer.instagramHandel}
                    </strong>
                  </div>
                </div>
              )}
              {activeCustomer?.tiktokHandel && (
                <div className="contact-info">
                  <div className="contact-title flex item-center justify-between">
                    <h3 className="semibold">Tiktok Username</h3>
                  </div>
                  <div className="user-email-with-logo">
                    <strong className="semibold">
                      {activeCustomer && activeCustomer.tiktokHandel}
                    </strong>
                  </div>
                </div>
              )}

              <div className="address-info">
                <div className="address-title flex item-center justify-between">
                  <h3 className="semibold">Primary Address</h3>
                  {this.props.customerAddressList &&
                    this.props.customerAddressList.length > 0 && (
                      <div
                        onClick={() =>
                          this.setState((prevState) => {
                            return {
                              showAddressFormEditMode:
                                !prevState.showAddressFormEditMode,
                            };
                          })
                        }
                        className="cursor-pointer"
                      >
                        Edit
                      </div>
                    )}
                </div>
                {this.props.customerAddressList &&
                  this.props.customerAddressList.map((info, index) => (
                    <CustomerAddress addressContent={info} key={index} />
                  ))}
                {!this.state.showAddressForm && (
                  <button
                    className="add-new-address"
                    onClick={this.handleShowAddressForm}
                  >
                    Add new Address
                  </button>
                )}
                {this.state.showAddressFormEditMode && (
                  <div className="add-address-form">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Item label="Address Type">
                        {getFieldDecorator("title", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].title
                              ? this.props.customerAddressList[0].title
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please select your address type",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Please Select Address Type"
                            onChange={this.handleSelectChange}
                          >
                            <Option value="home">Home</Option>
                            <Option value="work">Work</Option>
                            <Option value="others">other</Option>
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item label="Apartment / Block">
                        {getFieldDecorator("apartment", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].apartment
                              ? this.props.customerAddressList[0].apartment
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Apartment / Block!",
                            },
                          ],
                        })(<Input placeholder="Enter Apartment / Block" />)}
                      </Form.Item>
                      <Form.Item label="Street / Area">
                        {getFieldDecorator("street", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].street
                              ? this.props.customerAddressList[0].street
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please enter Apartment / Block!",
                            },
                          ],
                        })(<Input placeholder="Enter Street / Area" />)}
                      </Form.Item>
                      <Form.Item label="City">
                        {getFieldDecorator("city", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].city
                              ? this.props.customerAddressList[0].city
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter City",
                            },
                          ],
                        })(<Input placeholder="Enter City" />)}
                      </Form.Item>
                      <Form.Item label="Postal Code">
                        {getFieldDecorator("zipCode", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].zipCode
                              ? this.props.customerAddressList[0].zipCode
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Postal Code",
                            },
                          ],
                        })(<Input placeholder="Enter Postal Code" />)}
                      </Form.Item>
                      <Form.Item label="State">
                        {getFieldDecorator("state", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].state
                              ? this.props.customerAddressList[0].state
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter State",
                            },
                          ],
                        })(<Input placeholder="Enter State" />)}
                      </Form.Item>
                      <Form.Item label="Country">
                        {getFieldDecorator("country", {
                          initialValue:
                            this.props.customerAddressList &&
                            this.props.customerAddressList[0].country
                              ? this.props.customerAddressList[0].country
                              : undefined,
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Country",
                            },
                          ],
                        })(<Input placeholder="Enter Country" />)}
                      </Form.Item>
                      <Form.Item label="Is this is your primary address">
                        {getFieldDecorator("isPrimary", {
                          valuePropName: "checked",
                          initialValue: true,
                          rules: [
                            {
                              required: true,
                              message: "This is required",
                            },
                          ],
                        })(<Switch />)}
                      </Form.Item>

                      <Form.Item>
                        <Button
                          onClick={() => this.handleUpdateAddress}
                          type="primary"
                          htmlType="submit"
                        >
                          Update
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                )}
                {/* this address form would only be visible in edit mode (div end)*/}
                {this.state.showAddressForm && (
                  <div className="add-address-form">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Item label="Address Type">
                        {getFieldDecorator("title", {
                          rules: [
                            {
                              required: true,
                              message: "Please select your address type",
                            },
                          ],
                        })(
                          <Select
                            placeholder="Please Select Address Type"
                            onChange={this.handleSelectChange}
                          >
                            <Option value="home">Home</Option>
                            <Option value="work">Work</Option>
                            <Option value="others">other</Option>
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item label="Apartment / Block">
                        {getFieldDecorator("apartment", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Apartment / Block!",
                            },
                          ],
                        })(<Input placeholder="Enter Apartment / Block" />)}
                      </Form.Item>
                      <Form.Item label="Street / Area">
                        {getFieldDecorator("street", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Apartment / Block!",
                            },
                          ],
                        })(<Input placeholder="Enter Street / Area" />)}
                      </Form.Item>
                      <Form.Item label="City">
                        {getFieldDecorator("city", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter City",
                            },
                          ],
                        })(<Input placeholder="Enter City" />)}
                      </Form.Item>
                      <Form.Item label="Postal Code">
                        {getFieldDecorator("zipCode", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Postal Code",
                            },
                          ],
                        })(<Input placeholder="Enter Postal Code" />)}
                      </Form.Item>
                      <Form.Item label="State">
                        {getFieldDecorator("state", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter State",
                            },
                          ],
                        })(<Input placeholder="Enter State" />)}
                      </Form.Item>
                      <Form.Item label="Country">
                        {getFieldDecorator("country", {
                          rules: [
                            {
                              required: true,
                              message: "Please Enter Country",
                            },
                          ],
                        })(<Input placeholder="Enter Country" />)}
                      </Form.Item>
                      <Form.Item label="Is this is your primary address">
                        {getFieldDecorator("isPrimary", {
                          rules: [
                            {
                              required: true,
                              message: "This is required",
                            },
                          ],
                        })(<Switch onChange={this.onChange} />)}
                      </Form.Item>

                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                )}
              </div>
              <div className="phone-info">
                <div className="phone-title flex item-center justify-between">
                  <h3 className="semibold">Phone Number</h3>
                  <div
                    onClick={() =>
                      this.setState((prevState) => {
                        return {
                          showPhoneFormEditMode:
                            !prevState.showPhoneFormEditMode,
                        };
                      })
                    }
                    className="cursor-pointer"
                  >
                    Edit
                  </div>
                </div>
                <span>
                  {activeCustomer && activeCustomer.phone
                    ? activeCustomer.phone.isdCode + " "
                    : this.props.customerAddressList &&
                      this.props.customerAddressList[0] &&
                      this.props.customerAddressList[0].phone &&
                      this.props.customerAddressList[0].phone.isdCode
                    ? this.props.customerAddressList[0].phone.isdCode
                    : ""}
                  {activeCustomer && activeCustomer.phone
                    ? activeCustomer.phone.phone
                    : this.props.customerAddressList &&
                      this.props.customerAddressList[0] &&
                      this.props.customerAddressList[0].phone &&
                      this.props.customerAddressList[0].phone.phone
                    ? this.props.customerAddressList[0].phone.phone
                    : "Not Available"}

                  {/* {this.props.customerAddressList &&
                  this.props.customerAddressList[0] &&
                  this.props.customerAddressList[0].phone &&
                  this.props.customerAddressList[0].phone.isdCode
                    ? this.props.customerAddressList[0].phone.isdCode
                    : ""}
                  {this.props.customerAddressList &&
                  this.props.customerAddressList[0] &&
                  this.props.customerAddressList[0].phone &&
                  this.props.customerAddressList[0].phone.phone
                    ? this.props.customerAddressList[0].phone.phone
                    : "Not Available"} */}
                </span>
              </div>
              {this.props.match &&
                this.props.match.params &&
                this.props.match.params.id &&
                this.state.showPhoneFormEditMode && (
                  <CreatePhoneForm
                    phoneNumber={
                      activeCustomer && activeCustomer.phone
                        ? activeCustomer.phone
                        : {}
                    }
                    customerId={this.props.match.params.id}
                    updatePhone={this.props.updateCustomer}
                    activeId={this.props.match.params.id}
                    onPhoneCreation={() => {
                      this.setState({
                        showPhoneFormEditMode: false,
                      });
                      this.props.fetchCustomer(this.props.match.params.id);
                    }}
                  />
                )}
            </div>
            {/* <div className="credit-wrap">
              <div className="sub-title flex align-item justify-between">
                <h4 className="semibold">Credit</h4>
                <button>Manage</button>
              </div>
              <span className="semibold">STORE CREDIT</span>
              <strong className="semibold">$80.05</strong>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item label="Add Credit">
                  <Input placeholder="$00.00" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
              </Form>
            </div> */}
            <div className="credit-wrap notes-wrap">
              <div className="sub-title flex align-item justify-between">
                <h4 className="semibold">Notes</h4>
                <button
                  onClick={async () => {
                    await this.setState({ notesEdit: !this.state.notesEdit });
                    if (!this.state.notesEdit) {
                      this.setState({ note: this.state.prevNote });
                    }
                  }}
                >
                  Edit
                </button>
              </div>
              <div>
                {this.state.prevNote ? this.state.prevNote : "Not Available"}
              </div>
              {this.state.notesEdit && (
                <>
                  <Form>
                    <Form.Item label="Customer Note">
                      <Input
                        placeholder="Add note"
                        value={this.state.note}
                        onChange={(e) => {
                          this.setState({
                            note: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Form>
                  <button
                    className="primary"
                    onClick={() => {
                      this.setState({ prevNote: this.state.note });
                      this.props
                        .updateCustomer(this.props.activeCustomer.id, {
                          notes: this.state.note,
                        })
                        .then((res) => {
                          if (res && res.error) {
                            message.error(res.error.message);
                          } else {
                            message.success(
                              `Notes Successfully Updated for ${this.props.activeCustomer.firstName}.`
                            );
                          }
                        });
                      this.setState({ notesEdit: false });
                    }}
                  >
                    Add
                  </button>
                </>
              )}
            </div>

            <div className="credit-wrap mb-28">
              <div className="sub-title flex align-item justify-between">
                <h4 className="semibold">Tags</h4>
                <button
                  onClick={async () => {
                    await this.setState({ tagsEdit: !this.state.tagsEdit });
                    if (!this.state.tagsEdit) {
                      this.setState({ tags: this.state.prevTags });
                    }
                  }}
                >
                  Edit
                </button>
              </div>
              <Select
                placeholder="Select a tag"
                style={{ width: "100%" }}
                mode="multiple"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.tags}
                onChange={(value) => {
                  this.setState({ tags: value });
                }}
                disabled={!this.state.tagsEdit}
              >
                {this.props.tags &&
                  this.props.tags.map((tag) => (
                    <Option value={tag._id} key={tag._id}>
                      {tag.name}
                    </Option>
                  ))}
              </Select>
              {this.state.tagsEdit && (
                <button
                  className="primary"
                  onClick={() => {
                    if (!this.state.tags || this.state.tags.length < 1) {
                      return;
                    }
                    this.setState({ prevTags: this.state.tags });
                    this.props
                      .updateCustomer(
                        this.props.activeCustomer &&
                          this.props.activeCustomer.id,
                        {
                          tags: this.state.tags,
                        }
                      )
                      .then((res) => {
                        if (res && res.error) {
                          message.error(res.error.message);
                        } else {
                          message.success(
                            `Tags Successfully Updated for ${this.props.activeCustomer.firstName}.`
                          );
                        }
                      });
                    this.setState({ tagsEdit: false });
                  }}
                >
                  Add
                </button>
              )}
            </div>

            <div className="credit-wrap mb-28">
              <div className="sub-title flex align-item justify-between">
                <h4 className="semibold">Communication</h4>
              </div>
              <div className="flex justify-between">
                <label className="pt-10">Email</label>
                <Switch
                  checked={this.state.receiveEmailUpdate}
                  onChange={() =>
                    this.setState(
                      {
                        receiveEmailUpdate: !this.state.receiveEmailUpdate,
                      },
                      () => {
                        this.props
                          .updateCustomer(
                            this.props.activeCustomer &&
                              this.props.activeCustomer.id,
                            {
                              receiveEmailUpdate: this.state.receiveEmailUpdate,
                            }
                          )
                          .then((res) => {
                            if (res && res.error) {
                              message.error(res.error.message);
                            } else {
                              if (this.state.receiveEmailUpdate) {
                                message.success(
                                  `Email Alert enabled for ${this.props.activeCustomer.firstName}.`
                                );
                              } else {
                                message.success(
                                  `Email Alert disabled for ${this.props.activeCustomer.firstName}.`
                                );
                              }
                            }
                          });
                      }
                    )
                  }
                />
              </div>
              <div className="flex justify-between pt-10">
                <label className="pt-10">SMS</label>
                <Switch
                  checked={this.state.receiveSMSUpdate}
                  onChange={() =>
                    this.setState(
                      {
                        receiveSMSUpdate: !this.state.receiveSMSUpdate,
                      },
                      () => {
                        this.props
                          .updateCustomer(
                            this.props.activeCustomer &&
                              this.props.activeCustomer.id,
                            {
                              receiveSMSUpdate: this.state.receiveSMSUpdate,
                            }
                          )
                          .then((res) => {
                            if (res && res.error) {
                              message.error(res.error.message);
                            } else {
                              if (this.state.receiveSMSUpdate) {
                                message.success(
                                  `Sms Alert enabled for ${this.props.activeCustomer.firstName}.`
                                );
                              } else {
                                message.success(
                                  `Sms Alert disabled for ${this.props.activeCustomer.firstName}.`
                                );
                              }
                            }
                          });
                      }
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Subscription Reschedule"
          visible={this.state.isModalVisible}
          onOk={this.handleSkipOk}
          onCancel={this.handleSkipCancel}
        >
          <div className=" nav-container justify-between">
            <div className="flex"></div>
            Next Date:&nbsp;&nbsp; {this.state.currentSubscriptionDate}{" "}
            &nbsp;&nbsp;
            <DatePicker
              onChange={(val, dateString) => {
                //val is the momemt object
                this.SelectDates(dateString);
              }}
              disabledDate={this.disabledDate}
            />
            <div className="flex"></div>
            <div style={{ marginTop: "15px" }}></div>
          </div>
        </Modal>
        <Modal
          title="Cancel Subscription"
          visible={this.state.cancelModalVisible}
          onOk={this.handleCancelOk}
          onCancel={this.handleCancel}
        >
          <div className=" nav-container justify-between">
            <div style={{ marginBottom: "10px" }}>
              Please, help us process your request by telling us why you are
              cancelling (mandatory)
            </div>

            <Select
              defaultValue="Please select one of the option"
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="Financial reasons">Financial reasons</Option>
              <Option value="Backlog but doesn’t want to pause">
                Backlog but doesn’t want to pause
              </Option>
              <Option value="Duplicate">Duplicate</Option>
              <Option value="Wants to buy at own pace">
                Wants to buy at own pace
              </Option>
              <Option value="Dermat advised to stop">
                Dermat advised to stop
              </Option>
              <Option value="Resub">Resub</Option>
              <Option value="Reaction but doesn't want reform">
                Reaction but doesn't want reform
              </Option>
              <Option value="No longer need it">No longer need it</Option>
              <Option value="Others:">Others ( Enter the reason)</Option>
            </Select>
            {this.state.showCancelReasonTextBox ? (
              <TextArea
                rows={2}
                defaultValue=""
                value={this.state.cancelSubscriptionText}
                onChange={this.handleCancelTextInput}
                style={{ marginTop: "10px" }}
              />
            ) : (
              ""
            )}
          </div>
        </Modal>
        <Modal
          title="Edit Subscription"
          visible={this.state.editModalVisible}
          onOk={this.handleEditOk}
          onCancel={this.handleEditCancel}
        >
          <div className=" nav-container flex flex-row">
            Frequency:&nbsp;&nbsp; {this.state.currentSubscriptionPeriodValue}
            &nbsp;days&nbsp;&nbsp;
            <div
              style={{
                width: "40% !improtant",
                margin: "-6px",
                marginRight: "2px",
                marginLeft: "2px",
              }}
            >
              <Select
                defaultValue={this.state.currentSubscriptionPeriodValue}
                onChange={this.handleEditChange.bind(this)}
              >
                <Option value="30">30</Option>
                <Option value="60">60</Option>
                <Option value="90">90</Option>
              </Select>
            </div>
            &nbsp;days
          </div>
        </Modal>
        {this.state.selectedSelfie.length !== 0 && (
          <Lightbox
            mainSrc={this.state.selectedSelfie}
            onCloseRequest={() =>
              this.setState({
                selectedSelfie: "",
              })
            }
          />
        )}
      </div>
    );
  }
}

const CustomerProfilePage = Form.create({ name: "customer_profile_page" })(
  CustomerProfile
);

function mapStateToProps(state) {
  const { Customer, Orders, Assessment, Tags } = state;
  return {
    customerAddressList: Customer.customerAddressList,
    activeCustomer: Customer.activeCustomer,
    ordersList: Orders.ordersList,
    subscriptionList: Orders.subscriptionList,
    assessmentAttemptList: Assessment.assessmentAttemptList,
    tags: Tags.tags,
    selfieList: Assessment.selfieList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomerAddressList: bindActionCreators(
      actions.getCustomerAddressList,
      dispatch
    ),
    addAddress: bindActionCreators(actions.createNewAddress, dispatch),
    fetchCustomer: bindActionCreators(actions.getCustomer, dispatch),
    getCustomerOrders: bindActionCreators(ordersActions.getOrders, dispatch),
    getSubscriptions: bindActionCreators(
      ordersActions.getSubscriptions,
      dispatch
    ),
    getAssessmentAttemptList: bindActionCreators(
      getAssessmentAttemptList,
      dispatch
    ),
    getSelfieList: bindActionCreators(getSelfieList, dispatch),
    updateCustomer: bindActionCreators(actions.updateCustomer, dispatch),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
    updateAddress: bindActionCreators(actions.updateAddress, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProfilePage);
