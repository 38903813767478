import BenefitsApi from "services/benefits";

function handleError(err) {
  console.error(err);
}

export const ADD_BENEFIT = "ADD_BENEFIT";

export const SET_ACTIVE_BENEFIT = "SET_ACTIVE_BENEFIT";
export function setActiveBenefit(payload) {
  return {
    type: SET_ACTIVE_BENEFIT,
    payload,
  };
}

export const LIST_BENEFITS = "LIST_BENEFITS";
export function listBenefits(result) {
  return {
    type: LIST_BENEFITS,
    payload: result,
  };
}

export const getBenefit = (id) => async (dispatch) => {
  try {
    let res = await BenefitsApi.getBenefitById(id);
    dispatch(setActiveBenefit(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getBenefitsList = () => async (dispatch) => {
  try {
    let res = await BenefitsApi.getBenefitsList();
    dispatch(listBenefits(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSearchedBenefits = (type, name, tags, sort) => async (
  dispatch
) => {
  try {
    let res = await BenefitsApi.getSearchedBenefits(type, name, tags, sort);
    dispatch(listBenefits(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
};

export const createBenefit = (payload) => async (dispatch) => {
  try {
    let res = await BenefitsApi.createBenefit(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateBenefit = (id, payload) => async (dispatch) => {
  try {
    let res = await BenefitsApi.updateBenefitById(id, payload);
    dispatch(setActiveBenefit(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const deleteBenefit = (id) => async (dispatch) => {
  try {
    let res = await BenefitsApi.deleteBenefitById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
};
