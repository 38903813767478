import React, { Component } from "react";
import "./referral.scss";
import {
  Layout,
  Row,
  Col,
  Table,
  Form,
  Card,
  Dropdown,
  Menu,
  Button,
  Badge,
  Icon,
  Input,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import momentTimezone from "moment-timezone";

import * as actions from "actions/referral";
import { Link } from "react-router-dom";
import { referralUserType } from "utils/constants";
import { debounce } from "utils/helpers";

const { Search } = Input;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (data, row, i) => {
      return row?.referralUser ? (
        <Link target="_blank" to={`customer/edit/${row?.referralUser?._id}`}>
          {row?.referralUser?.firstName + row?.referralUser?.lastName}
        </Link>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (data, row, i) => {
      return row.referralEmail;
    },
  },
  {
    title: "Referred By",
    dataIndex: "referredBy",
    key: "referredBy",
    render: (data, row, i) => {
      return (
        <Link target="_blank" to={`customer/edit/${row?.user?._id}`}>
          {row.user.firstName + row.user.lastName}
        </Link>
      );
    },
  },
  {
    title: "Submission Date",
    dataIndex: "createdAt",
    key: "date",
    render: (data, row, i) => {
      return momentTimezone(row.createdAt)
      .tz("Asia/Singapore")
      .format("DD/MM/YYYY hh:mm a")
    }
  },
];

class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referrals: [],
      pageNo: 0,
      recordsPerpage: 20,
      isRegistered: undefined,
      search: undefined,
    };
  }

  componentDidMount() {
    const { pageNo } = this.state;
    this.getReferrals(pageNo);
  }

  getReferrals = (page) => {
    const { isRegistered, search } = this.state;
    this.props.fetchAllReferral({
      page,
      isRegistered,
      search,
    });
  };

  handleSearch = debounce(async (value) => {
    this.setState(
      {
        search: value ? value : undefined,
      },
      () => {
        this.getReferrals(this.state.pageNo);
      }
    );
  }, 300);

  clearFilter = () => {
    this.setState({
      pageNo: 0,
      isRegistered: undefined,
      search: undefined,
    }, () => {
      this.getReferrals(this.state.pageNo);
    });
  }

  render() {
    const { recordsPerpage, pageNo, isRegistered } = this.state;
    const { referrals } = this.props;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const selectedOrderIds = selectedRows.map((row) => row.id);
        this.setState({
          selectedOrderIds,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };

    const menu = (
      <Menu
        onClick={(e) => {
          if (this.state.isRegistered === e.key) {
            this.setState({ isRegistered: undefined, pageNo: 0 }, () => {
              this.getReferrals(0);
            });
          } else {
            this.setState({ isRegistered: e.key, pageNo: 0 }, () => {
              this.getReferrals(0);
            });
          }
        }}
        selectedKeys={isRegistered}
        selectable
      >
        {Object.keys(referralUserType).map((key) => {
          return <Menu.Item key={key}>{referralUserType[key]}</Menu.Item>;
        })}
      </Menu>
    );
    return (
      <Layout className="referral-page">
        <Row>
          <Col span={12}>
            <div className="flex mb-20 mt-20">
              <div className="theme-color semibold text-30 mr-10">Referral</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <div className="mb-20 flex">
                <Search
                  placeholder="Search by email"
                  onSearch={(value) => this.handleSearch(value)}
                  style={{ width: "50%", marginRight: "5px" }}
                  onKeyUp={(e) => this.handleSearch(e.target.value)}
                />
                <Dropdown overlay={menu}>
                  <Button
                    className={`dropdown-btn ${
                      isRegistered !== undefined && "filter-selected"
                    }`}
                    style={{ flex: 1, marginRight: "5px" }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        right: "7px",
                        top: "0px",
                      }}
                    >
                      <Badge count={isRegistered !== undefined ? "!" : 0}>
                        <a href="#" className="head-example" />
                      </Badge>{" "}
                    </span>
                    User Status <Icon type="down" />
                  </Button>
                </Dropdown>
                <Button
                  onClick={this.clearFilter}
                  className="dropdown-btn"
                  style={{ width: "140px", marginRight: "5px" }}
                >
                  Clear All Filters
                </Button>
              </div>
              <Table
                columns={columns}
                dataSource={referrals?.result}
                bordered
                size="small"
                // rowSelection={rowSelection}
                pagination={{
                  current: pageNo,
                  total: referrals.totalCount || 0,
                  pageSize: recordsPerpage,
                  onChange: (pageNo) => {
                    console.log(pageNo);
                    this.setState({ pageNo: pageNo });
                    this.getReferrals(pageNo - 1);
                  },
                }}
              />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { Referral } = state;
  console.log("Referral", Referral);
  return {
    referrals: Referral.referrals,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllReferral: bindActionCreators(actions.getReferrals, dispatch),
  };
}
const ReferralForm = Form.create({ name: "referral_form" })(Referral);

export default connect(mapStateToProps, mapDispatchToProps)(ReferralForm);
