
const initialState = {
    loginDetails:window.localStorage.getItem("userDetails")?JSON.parse(window.localStorage.getItem("userDetails")):null
};


export default function(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_DETAILS":
      return loginRequest(state, action);
    default:
      return state;
  }
}

function loginRequest(state, action) {
  return {
    ...state,
    loginDetails: action.payload
  };
}
