import { Api } from "../config/request";
import querystring from "query-string";

export const getBenefitById = (id) => {
  let url = `catalogue/benefit/${id}`;
  return Api.getRequest(url);
};

export const getBenefitsList = (query) => {
  let url = `catalogue/benefit/list`;
  return Api.getListRequest(url);
};

export const getSearchedBenefits = (type, name, tags, sort) => {
  let query = querystring.stringify({
    type,
    search: name,
    tags: tags ? tags : undefined,
    orderBy: sort ? `createdAt:${sort}` : '',
  });
  let url = `catalogue/benefit/search?${query}`;
  return Api.getListRequest(url);
};

export const createBenefit = (payload) => {
  let url = `catalogue/benefit/`;
  return Api.postRequest(url, payload);
};

export const updateBenefitById = (id, payload) => {
  let url = `catalogue/benefit/${id}`;
  return Api.patchRequest(url, payload);
};

export const deleteBenefitById = (id) => {
  let url = `catalogue/benefit/${id}`;
  return Api.deleteRequest(url);
};

const Admins = {
  getBenefitById,
  getSearchedBenefits,
  createBenefit,
  getBenefitsList,
  updateBenefitById,
  deleteBenefitById,
};

export default Admins;
