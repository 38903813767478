import { Api } from "../config/request";

export const getTaxList = (query) => {
  let url = `orders/tax/search${query ? "?" + query : ""}`;
  return Api.getListRequest(url);
};

export const createTax = payload => {
  let url = `orders/tax`;
  return Api.postRequest(url, payload);
};

export const updateTaxById = (id, payload) => {
  let url = `orders/tax/${id}`;
  return Api.patchRequest(url, payload);
};

const Admins = {
    getTaxList,
    createTax,
    updateTaxById
};

export default Admins;
