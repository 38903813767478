import React from "react";
import "./tagsForm.scss";
import { Modal, Button, Icon, Form, Input, Switch } from "antd";

const TagsModal = props => {
  const {
    title,
    visible,
    formInputFields,
    confirmLoading,
    handleOk,
    handleCancel,
    handleCreateTag,
    activeTag
  } = props;
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <Button
        type="primary"
        onClick={handleCreateTag}
      >
        <Icon type="plus"></Icon>
        Add Tags
      </Button>
      <Modal
        title={title}
        visible={visible}
        okText="Submit"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {formInputFields &&
          formInputFields.map((obj, index) => {
            return (
              <div className="mb-8" key={index}>
                {/* render input fields */}
                <div className="text-black regular text-14 mb-4">
                  {obj.fieldName}
                </div>
                <Form.Item>
                  {getFieldDecorator(`${obj.key}`, {
                    initialValue: activeTag ? activeTag[obj.key] : undefined,
                    rules: [{ required: true, message: "Required" }]
                  })(<Input />)}
                </Form.Item>

                {/* active switch */}
                <div className="text-black regular text-14 mb-4">Active</div>
                <Form.Item>
                  {getFieldDecorator("isActive", {
                    initialValue: activeTag ? activeTag.active : true,
                    valuePropName: "checked"
                  })(
                    <Switch />
                  )}
                </Form.Item>
              </div>
            );
          })}
      </Modal>
    </div>
  );
};

const TagsForm = Form.create({ name: "tags_form" })(TagsModal);
export default TagsForm;
