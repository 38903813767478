import { setCustomer } from "actions/customer";

const initialState = {
  ordersList: [],
  subscriptionList:[],
  activeOrder: null,
  ordersPageDetails: null,
  refund:[],
  ordersList_Abandoned:[],
  ordersList_Incomplete:[],
  whatsAppMessageList:[],
  customerOrderList:[],
  productWiseCount:[],
  comments:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LIST_ORDERS":
      return listOrders(state, action);
    case "LIST_SUBSCRIPTIONS":
      return listSubscriptions(state,action);
    case "LIST_ORDERS_ABANDONED":
      return listOrders_Abandoned(state, action);
    case "LIST_ORDERS_INCOMPLETE":
      return listOrders_Incomplete(state, action);
    case "SET_ACTIVE_ORDER":
      return setActiveOrder(state, action);
    case "SET_REFUND_FOR_ACTIVE_ORDER":
      return setRefundForActiveOrder(state, action);
    case "SET_PAGE_DETAILS":
      return setPageDetails(state, action);
    case "SET_WHATSAPP_MESSAGE_LIST":
      return setWhatsAppMessageList(state, action);
    case "SET_PRODUCT_WISE_COUNT"  :
      return setProductWiseCount(state, action);
    case "SET_CUSTOMER_ORDER_LIST"  :
      return setCustomerOrderList(state, action);
    case "SET_ORDER_COMMENTS":
      return setOrderCommentList(state, action);
    case "REMOVE_ORDER_FROM_ORDERS":
      return {
        ...state,
        ordersList: state.ordersList.filter(
          (order) => order.id !== action.payload.id
        ),
      };
    case "SET_ACTIVE_ORDER_ITEM_RECOMMENDATION":
        return updateActiveOrderItemRecommendationType(state, action);
    default:
      return state;
  }
}

function setPageDetails(state, action) {
  return {
    ...state,
    ordersPageDetails: action.payload,
  };
}

function listOrders(state, action) {
  return {
    ...state,
     ordersList: [...action.payload],
  };
}

function listSubscriptions(state, action) {
  return {
    ...state,
     subscriptionList: [...action.payload],
  };
}

function listOrders_Abandoned(state, action) {
  return {
    ...state,
    ordersList_Abandoned:action.payload,
  };
}

function listOrders_Incomplete(state, action) {
  return {
    ...state,
    ordersList_Incomplete:action.payload,
  };
}


function setActiveOrder(state, action) {
  return {
    ...state,
    activeOrder: action.payload ? { ...action.payload } : null,
  };
}
function setRefundForActiveOrder(state, action) {
  return {
    ...state,
    refund: action.payload ? [ ...action.payload ] : [],
  };
}

function setWhatsAppMessageList(state,action){
  return {
    ...state,
    whatsAppMessageList: action.payload ? [ action.payload ] :[]
  };
}

function setProductWiseCount(state, action ){
  //console.log("ACTION", action);
  return {
    ...state,
    productWiseCount: action.payload ? [action.payload] : [],
  };
}

function setCustomerOrderList(state,action){
  //console.log("setCustomerOrderList====" , action);
  return {
    ...state,
    customerOrderList: action.payload ? [action.payload] : [],
  };
}

function setOrderCommentList(state,action){
  return {
    ...state,
    comments: action.payload ? [ ...action.payload ] : [],
  };
}

function updateActiveOrderItemRecommendationType(state,action){
  let items = [...state.activeOrder.items];
  items[action.payload.index].recommendationType = action.payload.value;
  return {
    ...state,
    activeOrder: {
      ...state.activeOrder,
      items
    },
  };
}