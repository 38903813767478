import BannerApi from "services/banner";

const handleError = (err) => {
    console.error(err);
}

export const setBannerDataDispatch = (payload) => {
    return {
        type: "SET_BANNER_DATA",
        payload
    }
};

export const setBannerData = payload => async dispatch => {
    try {
        let res = await BannerApi.addingBannerData(payload);
        dispatch(setBannerDataDispatch(res));
        return res;
    } catch (err) {
        handleError(err);
    }
}

export const getBannerData = () => async dispatch => {
    try {
        let res = await BannerApi.gettingBannerData();
        dispatch(setBannerDataDispatch(res));
        return res;
    } catch (err) {
        handleError(err);
    }
}
