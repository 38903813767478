import React from "react";
import "./App.scss";
import "react-image-lightbox/style.css";
import "static/scss/main.scss";
import Header from "./components/header";
import AppRouter from "./router/appRouter";
import { withRouter } from "react-router-dom";
import Sidebar from "components/sidebar";
import Invoice from "views/invoice";

const App = (props) => {
  let currentPath = props.location.pathname;
  const name = localStorage.getItem("name") ? localStorage.getItem("name") : "";

  return currentPath !== "/invoice" ? (
    <div className="App">
      {currentPath !== "/login" && currentPath !== "/auth/google" && currentPath !== "/manual-login" && (
        <div className="root-container">
          <Header />
          <div className="content-container">
            <Sidebar name={name} />
          </div>
        </div>
      )}
      <AppRouter />
    </div>
  ) : (
    <Invoice />
  );
};

export default withRouter(App);
