import React, { useEffect } from "react";
import {
  Modal,
  Button,
  Icon,
  Form,
  Switch,
  InputNumber,
  Select,
  Input,
  Row,
  Col,
} from "antd";
import { RESTRICTED_COUNTRIES_CHECKOUT } from "utils/countryMap";
const { Option } = Select;

const TaxModal = (props) => {
  const {
    title,
    visible,
    confirmLoading,
    handleOk,
    handleCancel,
    handleCreateTax,
    activeTax,
  } = props;
  const { getFieldDecorator, getFieldValue, resetFields, setFieldsValue } =
    props.form;

  useEffect(() => {
    setFieldsValue({
      stateRate: activeTax?.stateRate,
    });
  }, [activeTax]);

  const checkStateField = () => {
    let country = RESTRICTED_COUNTRIES_CHECKOUT.find(
      (x) =>
        x.name?.trim()?.toLowerCase() ===
        getFieldValue("country")?.trim()?.toLowerCase()
    );
    if (country?.states) {
      return false;
    }
    return true;
  };

  const getStateList = () => {
    let country = RESTRICTED_COUNTRIES_CHECKOUT.find(
      (x) =>
        x.name?.trim()?.toLowerCase() ===
        getFieldValue("country")?.trim()?.toLowerCase()
    );
    if (country?.states) {
      return country.states;
    }
    return [];
  };

  const getCombinedRate = () => {
    let stateRate = getFieldValue("stateRate");
    let countyRate = getFieldValue("countyRate");
    let cityRate = getFieldValue("cityRate");
    let specialRate = getFieldValue("specialRate");
    let total = 0;
    if (stateRate) {
      total += stateRate;
    }
    if (countyRate) {
      total += countyRate;
    }
    if (cityRate) {
      total += cityRate;
    }
    if (specialRate) {
      total += specialRate;
    }
    return total;
  };

  return (
    <div>
      <Button type="primary" onClick={handleCreateTax}>
        <Icon type="plus"></Icon>
        Add Tax
      </Button>
      <Modal
        title={title}
        visible={visible}
        okText="Submit"
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={() => {
          handleCancel();
          resetFields();
        }}
      >
        <div className="mb-8">
          {/* render input fields */}
          <div className="text-black regular text-14 mb-4">Country</div>
          <Form.Item>
            {getFieldDecorator(`country`, {
              initialValue: activeTax
                ? activeTax["country"].toLowerCase()
                : undefined,
              rules: [{ required: true, message: "Required" }],
            })(
              <Select placeholder="Select">
                {RESTRICTED_COUNTRIES_CHECKOUT.map((value) => (
                  <Option value={value.name.toLowerCase()} key={value.code}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <div className="text-black regular text-14 mb-4">State</div>
          <Form.Item>
            {getFieldDecorator(`state`, {
              initialValue: activeTax
                ? activeTax["state"].toLowerCase()
                : undefined,
              rules: [{ required: !checkStateField(), message: "Required" }],
            })(
              <Select placeholder="Select" disabled={checkStateField()}>
                {getStateList().map((value) => (
                  <Option value={value.name.toLowerCase()} key={value.code}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Row>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">
                Tax Percentage (combined rate)
              </div>
              <Form.Item>
                {getFieldDecorator(`value`, {
                  initialValue: getCombinedRate(),
                  rules: [{ required: false, message: "Required" }],
                  defaultValue: getCombinedRate(),
                })(
                  <InputNumber
                    disabled
                    min={0}
                    max={20}
                    placeholder="Percentage"
                    className="tax-percentage-value"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">ZipCode</div>
              <Form.Item>
                {getFieldDecorator(`zipCode`, {
                  initialValue: activeTax ? activeTax["zipCode"] : undefined,
                  rules: [{ required: false, message: "Required" }],
                })(
                  <Input placeholder="Enter" className="tax-percentage-value" />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">State Rate</div>
              <Form.Item>
                {getFieldDecorator(`stateRate`, {
                  initialValue: activeTax ? activeTax["stateRate"] : 0,
                  rules: [{ required: true, message: "Required" }],
                })(
                  <InputNumber
                    min={0}
                    max={20}
                    placeholder="Percentage"
                    className="tax-percentage-value"
                    onBlur={getCombinedRate}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">County Rate</div>
              <Form.Item>
                {getFieldDecorator(`countyRate`, {
                  initialValue: activeTax ? activeTax["countyRate"] : 0,
                  rules: [{ required: true, message: "Required" }],
                })(
                  <InputNumber
                    min={0}
                    max={20}
                    placeholder="Percentage"
                    className="tax-percentage-value"
                    onBlur={getCombinedRate}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">City Rate</div>
              <Form.Item>
                {getFieldDecorator(`cityRate`, {
                  initialValue: activeTax ? activeTax["cityRate"] : 0,
                  rules: [{ required: true, message: "Required" }],
                })(
                  <InputNumber
                    min={0}
                    max={20}
                    placeholder="Percentage"
                    className="tax-percentage-value"
                    onBlur={getCombinedRate}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">
                Special Rate
              </div>
              <Form.Item>
                {getFieldDecorator(`specialRate`, {
                  initialValue: activeTax ? activeTax["specialRate"] : 0,
                  rules: [{ required: true, message: "Required" }],
                })(
                  <InputNumber
                    min={0}
                    max={20}
                    placeholder="Percentage"
                    className="tax-percentage-value"
                    onBlur={getCombinedRate}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* shipping switch */}
              <div className="text-black regular text-14 mb-4">
                Is Shipping Taxable?
              </div>
              <Form.Item>
                {getFieldDecorator("shipping", {
                  initialValue: activeTax ? activeTax["shipping"] : true,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* active switch */}
              <div className="text-black regular text-14 mb-4">Active</div>
              <Form.Item>
                {getFieldDecorator("isActive", {
                  initialValue: activeTax ? activeTax["active"] : true,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="text-black regular text-14 mb-4">
                Fallback State
              </div>
              <Form.Item>
                {getFieldDecorator("fallbackState", {
                  initialValue: activeTax ? activeTax["fallbackState"] : false,
                  valuePropName: "checked",
                })(<Switch />)}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

const TaxForm = Form.create({ name: "tax_form" })(TaxModal);
export default TaxForm;
