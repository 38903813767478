import React, { useMemo } from "react";
import { Modal, Select, Input, Form, Button } from "antd";
const { Option } = Select;
const TrackingModal = ({
  showExportModal,
  onClickOk,
  closeModal,
  trackingNumber = "",
  courierService = "",
  form,
}) => {
  const { getFieldDecorator } = form;
  const courierOptions = useMemo(() => ["DHL", "easy-ship", "FedEx"], []);
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onClickOk(values);
      }
    });
  };
  return (
    <Modal
      title="Tracking Detail"
      visible={showExportModal}
      okText="Add"
      onCancel={closeModal}
      onOk={onClickOk}
      footer={[
        <Button key="button" htmlType="button" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          form="order_tracking_form"
          className="ant-btn ant-btn-primary"
          key="submit"
          htmlType="submit"
        >
          Submit
        </Button>,
      ]}
    >
      <Form id="order_tracking_form" onSubmit={handleSubmit}>
        <Form.Item label="Courier Service">
          {getFieldDecorator("courier", {
            initialValue: courierService,
            rules: [
              {
                required: true,
                message: "Please select courier service",
              },
            ],
          })(
            <Select placeholder="Select">
              {courierOptions.map((product) => (
                <Option value={product} key={product}>
                  {product}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Tracking Number">
          {getFieldDecorator("awbNumber", {
            initialValue: trackingNumber,
            rules: [
              {
                required: true,
                message: "Please enter tracking number",
              },
            ],
          })(<Input placeholder="Enter Tracking Number" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const trackingModal = Form.create({ name: "order_tracking_form" })(
  TrackingModal
);

export default trackingModal;
