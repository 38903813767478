import CurrencyApi from "services/currency";

function handleError(err) {
  console.error(err);
}

export function setActiveCurrency(payload) {
  return {
    type: "SET_ACTIVE_CURRENCY",
    payload
  }
};

export function listCurrency(result) {
  return {
    type: "LIST_CURRENCY",
    payload: result
  };
};

export const getCurrency = id => async dispatch => {
  try {
    let res = await CurrencyApi.getCurrencyById(id);
    dispatch(setActiveCurrency(res));
    return res;
  } catch (err) {
    handleError(err);
    return err;
  }
}

export const getCurrencyList = () => async dispatch => {
   try {
    let res = await CurrencyApi.getCurrencyList();
    dispatch(listCurrency(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
    return err;
  }
};

export const getSearchedCurrency = (type, name, tags, sort) => async dispatch => {
  try {
    let res = await CurrencyApi.getSearchedCurrency(type, name, tags, sort);
    dispatch(listCurrency(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
}

export const createCurrency = (payload) => async dispatch => {
  try {
    let res = await CurrencyApi.createCurrency(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateCurrency = (id, payload) => async dispatch => {
  try {
    let res = await CurrencyApi.updateCurrencyById(id, payload);
    dispatch(setActiveCurrency(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const deleteCurrency = id => async dispatch => {
  try {
    let res = await CurrencyApi.deleteCurrencyById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
}

