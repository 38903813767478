import React, { Component, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import NotFoundPage from "views/notFoundPage";
import HomePage from "views/homePage";
import LoginPage from "views/loginPage";
import ManualLoginPage from "views/loginPage/manualLoginPage";
import GoogleLogin from "views/loginPage/googleLogin";
import ListIngredientsPage from "views/ingredientsPage/listIngredients";
import EditIngredientsPage from "views/ingredientsPage/addIngredients";
import ListProductsPage from "views/productsPage/listProducts";
import EditProductsPage from "views/productsPage/addProducts";
import ListCategoryPage from "views/categoryPage/listCategory";
import EditCategoryPage from "views/categoryPage/addCategory";
import TagsPage from "views/tagsPage";
import TaxPage from "views/taxPage";
import ListAssessmentPage from "views/assessmentPage/assessmentList";
//import EditAssessmentPage from "views/assessmentPage/addAssessment";
import CustomerPage from "views/customerPage";
import InventoryPage from "views/InventoryPage";
import CustomerProfile from "views/customerPage/customerProfile";
import CustomerSubscription from "views/customerPage/customerSubscription";
import isAuthenticated from "./isAuthenticated";
import ListAssessmentQuestionsPage from "views/assessmentPage/questionsList";
import AddQuestionPage from "views/assessmentPage/addQuestion";
import ListOrdersPage from "views/ordersPage/listOrders";
import CompletedOrdersPage from "views/ordersPage/completedOrders";
import FulfillmentOrdersPage from "views/ordersPage/fulfillmentOrders";
import IncompleteOrdersPage from "views/ordersPage/incompleteOrders";
import AbandonedCartPage from "views/ordersPage/abandonedCart";
import CreateCart from "views/ordersPage/createCart";

import AddOrderPage from "views/ordersPage/addOrder";
import RefundOrderPage from "views/ordersPage/refundOrder";
import AssessmentAttemptPage from "views/assessmentAttemptPage/";
import Invoice from "views/invoice";
import ListDiscountPage from "views/discountPage/listDiscount";
import ListBulkDiscountPage from "views/discountPage/listBulkDiscount";
import ListBulkDiscountCodePage from "views/discountPage/listBulkDiscountCodes";
import AddDiscount from "views/discountPage/addDiscount";
import ListAdminPage from "views/adminPage/listAdmin";
import AddAdmin from "views/adminPage/addAdmin";
import AssessmentDetailPage from "views/assessmentDetailPage/assessmentDetailPage";
import recommendationsPage from "views/recommendationsPage/recommendationsPage";

import BenefitAddPage from "views/benefitPage/addBenefit";
import BenefitEditPage from "views/benefitPage/editBenefit";
import BenefitListPage from "views/benefitPage/listBenefit";
import ReferralPage from "views/referralPage";
import BannerPage from "views/bannerPage";
import ReviewAddPage from "views/reviewsPage/addReview";
import ReviewListPage from "views/reviewsPage/listReviews";
import UrlShortener from "views/crmPage/shortUrl";
import SelfiePage from "views/selfies";

// import isAuthenticated from "./isAuthenticated";
// dynamic import: (for route based code splitting)
// const HomePage = lazy(() => import(/* webpackChunkName: "homepage" */ "views/homePage"));
// const LoginPage = lazy(() => import(/* webpackChunkName: "loginpage" */ "views/loginPage"));

const routes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
    type: "private",
  },
  {
    path: "/login",
    component: LoginPage,
    type: "public",
  },
  {
    path: "/manual-login",
    component: ManualLoginPage,
    type: "public",
  },
  {
    path: "/auth/google",
    component: GoogleLogin,
    type: "public",
  },
  {
    path: "/ingredients/edit/:id",
    component: EditIngredientsPage,
    type: "private",
  },
  {
    path: "/ingredients/edit",
    component: EditIngredientsPage,
    type: "private",
  },
  {
    path: "/ingredients",
    component: ListIngredientsPage,
    type: "private",
  },
  {
    path: "/orders/edit/:id",
    component: AddOrderPage,
    type: "private",
  },
  {
    path: "/orders/refund/:id",
    component: RefundOrderPage,
    type: "private",
  },
  {
    path: "/orders/edit/",
    component: AddOrderPage,
    type: "private",
  },
  {
    path: "/ready-to-pack",
    component: FulfillmentOrdersPage,
    type: "private",
  },
  {
    path: "/orders",
    component: CompletedOrdersPage,
    type: "private",
  },
  {
    path: "/incompleteorders",
    component: IncompleteOrdersPage,
    type: "private",
  },
  {
    path: "/abandonedcarts",
    component: AbandonedCartPage,
    type: "private",
  },
  {
    path: "/cart/:cartId",
    component: CreateCart,
    type: "private",
  },
  {
    path: "/cart",
    component: CreateCart,
    type: "private",
  },
  {
    path: "/recommendations",
    component: recommendationsPage,
    type: "private",
  },
  {
    path: "/products/edit/:id",
    component: EditProductsPage,
    type: "private",
  },
  {
    path: "/products/edit",
    component: EditProductsPage,
    type: "private",
  },
  {
    path: "/products",
    component: ListProductsPage,
    type: "private",
  },
  {
    path: "/category/edit/:id",
    component: EditCategoryPage,
    type: "private",
  },
  {
    path: "/category/edit",
    component: EditCategoryPage,
    type: "private",
  },
  {
    path: "/category",
    component: ListCategoryPage,
    type: "private",
  },
  {
    path: "/tags",
    component: TagsPage,
    type: "private",
  },
  {
    path: "/tax",
    component: TaxPage,
    type: "private",
  },
  {
    path: "/assessment/:id/questions/edit/:questionId",
    component: AddQuestionPage,
  },
  {
    path: "/assessment/:id/questions/edit",
    component: AddQuestionPage,
  },
  {
    path: "/assessment/:id/questions/",
    component: ListAssessmentQuestionsPage,
  },
  {
    path: "/assessment",
    component: ListAssessmentPage,
  },
  {
    path: "/customers",
    component: CustomerPage,
    type: "private",
  },
  {
    path: "/customer/edit/:id",
    component: CustomerProfile,
    type: "private",
  },
  {
    path: "/customer/:cusId/subscription/:subId",
    component: CustomerSubscription,
    type: "private",
  },
  {
    path: "/assessment-attempt",
    component: AssessmentAttemptPage,
    type: "private",
  },
  {
    path: "/invoice",
    component: Invoice,
    type: "private",
  },

  {
    path: "/bulk-discounts/codes/:id/:title",
    component: ListBulkDiscountCodePage,
    type: "private",
  },
  {
    path: "/bulk-discounts",
    component: ListBulkDiscountPage,
    type: "private",
  },
  {
    path: "/discounts",
    component: ListDiscountPage,
    type: "private",
  },

  {
    path: "/add-discount/add/:type",
    component: AddDiscount,
    type: "private",
  },
  {
    path: "/add-discount/edit/:type/:id",
    component: AddDiscount,
    type: "private",
  },
  {
    path: "/add-discount/:id",
    component: AddDiscount,
    type: "private",
  },
  {
    path: "/add-discount/",
    component: AddDiscount,
    type: "private",
  },
  {
    path: "/admin/",
    component: ListAdminPage,
    type: "private",
  },
  {
    path: "/add-admin/:id",
    component: AddAdmin,
    type: "private",
  },
  {
    path: "/add-admin/",
    component: AddAdmin,
    type: "private",
  },
  {
    path: "/assessment-detail/:id",
    component: AssessmentDetailPage,
    type: "private",
  },
  {
    path: "/assessment-detail/",
    component: AssessmentDetailPage,
    type: "private",
  },
  {
    path: "/benefits/add",
    component: BenefitAddPage,
    type: "private",
  },
  {
    path: "/benefits/edit/:id",
    component: BenefitEditPage,
    type: "private",
  },
  {
    path: "/benefits",
    component: BenefitListPage,
    type: "private",
  },
  {
    path: "/referral",
    component: ReferralPage,
    type: "private",
  },
  {
    path: "/banner",
    component: BannerPage,
    type: "private",
  },
  {
    path: "/reviews/edit",
    component: ReviewAddPage,
    type: "private",
  },
  {
    path: "/reviews/edit?id=:id",
    component: ReviewAddPage,
    type: "private",
  },
  {
    path: "/reviews",
    component: ReviewListPage,
    type: "private",
  },
  {
    path: "/progress-selfies",
    component: SelfiePage,
    type: "private",
  },
  {
    path: "/inventory",
    component: InventoryPage,
    type: "private",
  },
  {
    path: "/url-shortener",
    component: UrlShortener,
    type: "private",
  },
  {
    path: "*",
    component: NotFoundPage,
    type: "public",
  },
];

export default class AppRouter extends Component {
  render() {
    let homeRoute = "/login";
    if (isAuthenticated()) {
      if (localStorage.getItem("userType") == "packer") {
        homeRoute = "/ready-to-pack";
      } else {
        homeRoute = "/orders";
      }
    }

    const routeComponents = routes.map(
      ({ path, component, exact, type }, key) => {
        if (type === "public") {
          return exact ? (
            <PublicRoute exact path={path} component={component} key={key} />
          ) : (
            <PublicRoute path={path} component={component} key={key} />
          );
        } else {
          return exact ? (
            <PrivateRoute exact path={path} component={component} key={key} />
          ) : (
            <PrivateRoute path={path} component={component} key={key} />
          );
        }
      }
    );
    return (
      <>
        <Suspense fallback={<div className="loader-div"></div>}>
          <Switch>
            <Redirect exact from="/" to={homeRoute} />
            {routeComponents}
          </Switch>
        </Suspense>
      </>
    );
  }
}
