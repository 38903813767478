
const initialState = {
  currency: [],
  activeCurrency: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_CURRENCY": 
      return setActiveCurrency(state, action);
    case "LIST_CURRENCY":
      return listCurrency(state, action);
    case "ADD_CURRENCY":
      return addCurrency(state, action);
    default:
      return state
  }
}

function setActiveCurrency(state, action) {
  return {
    ...state,
    activeCurrency: { ...action.payload }
  }
}

function listCurrency(state, action) {
  return {
    ...state, 
    currency: [ ...action.payload ]
  };
}

function addCurrency(state, action) {
  return {
    ...state,
    activeCurrency: { ...action.payload }
  };
}