import React, { Component } from "react";
import { Form, Input, Select, Button, Checkbox, Radio, message } from "antd";
import { getSafely } from "utils/helpers";
import "./questionStepOne.scss";
const { Option } = Select;
//
class QuestionStepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionHaveUserInput: false,
      typeOfAnswer: [
        {
          fieldName: "Single select",
          key: "single",
        },
        {
          fieldName: "Multi select",
          key: "multi",
        },
        {
          fieldName: "Range",
          key: "range",
        },
      ],
      inputQuestionFields: [
        {
          fieldName: "Assessment",
          key: "assessment",
        },
        {
          fieldName: "Text",
          key: "text",
        },
        {
          fieldName: "Help Text",
          key: "helpText",
        },
        {
          fieldName: "Question Tag",
          key: "questionTag",
        },
      ],
      groupFields: [
        {
          fieldName: "Question Type",
          key: "question",
        },
        {
          fieldName: "Primary color",
          key: "primaryColor",
        },
        {
          fieldName: "Secondary color",
          key: "secondaryColor",
        },
      ],
    };
  }
  renderCreateQuestionInputFields = arr => {
    const { getFieldDecorator } = this.props.form;
    const { assessmentList, activeQuestion } = this.props;
    return arr.map((obj, index) => {
      return (
        <div className="mb-8" key={index}>
          <div className="text-black regular text-14 mb-4">{obj.fieldName}</div>
          {obj.key === "assessment" ? (
            <Form.Item>
              {getFieldDecorator(`assessment`, {
                initialValue: (!this.props.isEditMode) ? undefined : activeQuestion?.assessment,
                rules: [{ 
                  required: !this.props.isEditMode, 
                  message: "Required" 
                }]
              })(
                <Select
                  placeholder="Select a assessment"
                  disabled={!this.props.enabled || this.props.isEditMode}
                >
                  {assessmentList &&
                    assessmentList.map(assessment => (
                      <Option value={assessment.id} key={assessment.id}>{assessment.id}</Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          ) : (
            <Form.Item>
              {getFieldDecorator(`${obj.key}`, {
                initialValue: activeQuestion && activeQuestion[obj.key],
                rules: [{ 
                  required: obj.key === "helpText" ? false : !this.props.isEditMode, 
                  message: "Required" 
                }]
              })(<Input disabled={!this.props.enabled}/>)}
            </Form.Item>
          )}
        </div>
      );
    });
  };
  renderInputFields = arr => {
    const { getFieldDecorator } = this.props.form;
    const { activeGroup } = this.state;
    const { groupList, activeQuestion} = this.props;
    return arr.map((obj, index) => {
      return (
        <div className="mb-8 input-item" key={index}>
          <div className="text-black regular text-14 mb-4">{obj.fieldName}</div>
          {obj.key === "question" ? (
            <Form.Item>
              {getFieldDecorator(`group`, {
                initialValue: (!this.props.isEditMode) ? undefined : activeQuestion?.group,
                rules: [{ 
                  required: !this.props.isEditMode,
                  message: "Required" 
                }]
              })(
                <Select
                  onChange={value =>
                    this.setState({ activeGroup: JSON.parse(value) })
                  }
                  placeholder="Select a group"
                  style={{ minWidth: "170px" }}
                  disabled={!this.props.enabled || this.props.isEditMode}
                >
                  {groupList &&
                    groupList.map(group => (
                      <Option value={JSON.stringify(group)} key={group.id}>
                        {group.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          ) : (
            <Form.Item>
              <Input
                disabled={true}
                value={activeGroup && activeGroup[obj.key]}
              />
            </Form.Item>
          )}
        </div>
      );
    });
  };
  renderAnswerTypeCheckbox = arr => {
    return (
      <Radio.Group 
        onChange={(e) => this.props.setOptionType(e.target.value)} 
        value={this.props.optionType}
        disabled={!this.props.enabled || this.props.isEditMode}
      >
        {arr.map((obj, index) => {
          return <Radio value={obj.key} key={index}>{obj.fieldName}</Radio>
        })}
      </Radio.Group>
    );
  }
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        if(this.props.isEditMode) {
          this.props
            .updateQuestion(this.props.questionId, values)
            .then(res => {
              if(res)
                message.success("Question updated!");
              else 
                message.error("Oops, Error");
            });
        }
        else {
          values.group = JSON.parse(values.group).id;
          values["isMultiSelect"] = this.props.optionType === "multi";
          values["optionsAreRange"] = this.props.optionType === "range";
          values["optionsAllowUserInput"] = this.state.optionHaveUserInput;
          values["optionsHaveIcons"] = this.props.optionHaveIcons;
          this.props
            .createQuestion(values)
            .then(res => {
              if(res) {
                this.props.setQuestionResponse(res);
                this.props.setEnabled(false);
                this.props.setNextStepEnabled(true);
                message.success("Question created!");
              }
              else {
                message.error("Oops, Error");
              }
            });

        }
      }
    });
  };
  render() {
    const { questionList, optionType, activeQuestion } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className="assessment-step-one--wrapper-clearfix">
          <div className="group-category-container-clearfix">
            <h4 className="text-black text-base semibold">Group / Category</h4>
            <div className="sub-category-container flex mt-20">
              <div className="flex sub-items-wrapper">
                {this.renderInputFields(this.state.groupFields)}
              </div>
            </div>
          </div>
        </div>
        <div className="desc">
          <h4 className="text-black text-base semibold">Assessment Question</h4>
          <div className="input-item">
            {this.renderCreateQuestionInputFields(
              this.state.inputQuestionFields
            )}
          </div>
          <div
            style={{ marginLeft: "10px" }}
            className="bottom-question-fields-container"
          >
            <h4 className="bottom-field-header text-black text-14 mb-10">
              Next Button/ Move to
            </h4>
            <Form.Item>
              {getFieldDecorator("nextQuestionLookup", {
                initialValue: activeQuestion && activeQuestion.nextQuestionLookup,
                rules: [
                  {
                    required: true,//!this.props.isEditMode,
                    message: "Required"
                  }
                ]
              })(
                <Select
                  style={{ width: 200 }}
                  onChange={value => this.props.setLookup(value)}
                  //disabled={!this.props.enabled || this.props.isEditMode}
                >
                  <Option value="last question">Last Question</Option>
                  <Option value="question">Question</Option>
                  <Option value="option">Option</Option>
                </Select>
              )}
            </Form.Item>
          </div>

          { (
            <div
              style={{ marginTop: "10px" }}
              className="flex assessment-one-bottom mb-20"
            >
              {this.props.lookup == "question" && this.props.activeQuestion && <div
                style={{ marginLeft: "10px" }}
                className="bottom-question-fields-container"
              >
                <h4 className="bottom-field-header text-black text-14 mb-10">
                  Next Question
                </h4>
                <Form.Item label="">
                  {getFieldDecorator("nextQuestion", {
                    initialValue: activeQuestion && activeQuestion.nextQuestion,
                    rules: [
                      {
                        required: !this.props.isEditMode,
                        message: "Required"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select a question"
                      style={{ width: 200 }}
                      showSearch
                      disabled={!this.props.enabled}
                    >
                      {questionList &&
                        questionList.map(question => (
                          <Option value={question.id} key={question.id}>
                            {question.text}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>}
              <div
                style={{ marginLeft: "10px" }}
                className="bottom-question-fields-container"
              >
                <h4 className="bottom-field-header text-black text-14 mb-10">
                  Previous Question
                </h4>
                <Form.Item label="">
                  {getFieldDecorator("prevQuestion", {
                    initialValue: activeQuestion && activeQuestion.prevQuestion,
                    rules: [
                      {
                        required: !this.props.isEditMode,
                        message: "Required"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select a question"
                      style={{ width: 200 }}
                      showSearch
                      disabled={!this.props.enabled}
                    >
                      {questionList &&
                        questionList.map(question => (
                          <Option value={question.id} key={question.id}>
                            {question.text}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>
          )}
          <div style={{ marginLeft: "10px" }}>
            <h4 className="bottom-field-header text-black text-14 mb-10">
              Type Of Answer
            </h4>
            <div className="flex mb-20">
              {this.renderAnswerTypeCheckbox(this.state.typeOfAnswer)}
              {optionType === "range" && <div>
                <div className="headings flex">Range type</div>
                <div className="values flex">
                  <Form.Item>
                    {getFieldDecorator(`rangeLabel`, {
                      initialValue: getSafely(() => activeQuestion.rangeLabel, undefined),
                      rules: [{ required: true, message: "Required" }]
                    })(<Input disabled={!this.props.enabled || this.props.isEditMode}/>)}
                  </Form.Item>
                </div>
              </div>}
            </div>
            <h4 className="bottom-field-header text-black text-14 mb-10">
              Customization
            </h4>
            <div className="flex">
              <Checkbox
                onChange={e =>
                  this.setState({ optionHaveUserInput: e.target.checked })
                }
                checked={this.state.optionHaveUserInput}
                disabled={!this.props.enabled || this.props.isEditMode}
              >
                Options have user input
              </Checkbox>
              <Checkbox
                onChange={e =>
                  this.props.toggleOptionHaveIcons(e.target.checked)
                }
                checked={this.props.optionHaveIcons}
                disabled={!this.props.enabled || this.props.isEditMode}
              >
                Options have icons
              </Checkbox>
            </div>
          </div>
          <div className="w-full flex justify-end mt-20">
            <Button 
              type="primary"
              disabled={!this.props.enabled}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const QuestionStepOneForm = Form.create({ name: "question_step_one_form" })(
  QuestionStepOne
);

export default QuestionStepOneForm;
