import React, { Component } from "react";
import "./loginPage.scss";
import { Input, Form, message, Divider, Icon } from "antd";
import { Button } from "antd";
import Logo from "static/img/global/lovefromyours-logo.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loginPageActions from "actions/login";
import { Switch, Redirect } from "react-router-dom";

class loginPage extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.fetchLoginDetails(values).then((res) => {
          if (res) {
            if (res.role == "packer") {
              // this.props.history.push("/ready-to-pack");
              this.props.history.replace({
                pathname: "ready-to-pack",
                state: { isActive: true },
              });
            } else {
              this.props.history.push("/");
            }
          } else {
            message.error("Incorrect email/ password");
          }
        });
      }
    });
  };

  googleLogin = () => {
    loginPageActions.googleLoginRequest();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="view-login-page flex justify-center items-center h-100">
        <div className="form-wrapper flex flex-col justify-center items-center">
          <img src={Logo} alt="Get Yours" className="mb-24" />
          <p className="mw bold text-32 theme-color mb-24">Hey There!</p>
          <Button type="primary" htmlType="button" onClick={this.googleLogin}>
            <Icon type="google" /> Google Login
          </Button>
          <Divider>Or</Divider>
          <p className="text-14 regular theme-color mb-22">
            Sign in with your Email:
          </p>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ],
              })(<Input placeholder="Email" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please Enter your Password!" },
                ],
              })(<Input type="password" placeholder="Password" />)}
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(loginPage);

function mapStateToProps(state) {
  const { logindetail } = state;
  return {
    login_details: logindetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLoginDetails: bindActionCreators(
      loginPageActions.loginRequest,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm);
