import React, { Component } from "react";
import "./questionStepThree.scss";
import { getSafely } from "utils/helpers";
import {
  Form,
  InputNumber,
  Divider,
  Select,
  Button,
  message
} from "antd";
import { withRouter } from 'react-router';
const { Option } = Select;

class QuestionStepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfParameters: 1,
      parameterQuestionMappingObj: null
    };
  }
  componentDidMount() {
    const { parameterQuestionMappingList, isEditMode } = this.props;
    const { parameterQuestionMappingObj } = this.state;
    if((parameterQuestionMappingList.length) && 
      !parameterQuestionMappingObj && 
      isEditMode) {
        this.setState({
          parameterQuestionMappingObj: this.createMappedObjFromParameterQuestionMappingList(parameterQuestionMappingList),
          noOfParameters: 0
        });
    }
  }
  
  componentDidUpdate(prevProps) {
    const { parameterQuestionMappingList, isEditMode } = this.props;
    const { parameterQuestionMappingObj } = this.state;
    if((prevProps.parameterQuestionMappingList.length !== parameterQuestionMappingList.length) && 
      !parameterQuestionMappingObj && 
      isEditMode) {
        this.setState({
          parameterQuestionMappingObj: this.createMappedObjFromParameterQuestionMappingList(parameterQuestionMappingList),
          noOfParameters: 0
        });
    }
  }
  createMappedObjFromParameterQuestionMappingList = (arr) => {
    let result = {};
    arr.forEach( mapping => {
      if (mapping.parameter in result) {
        result[mapping.parameter].push({...mapping});
      } else {
        result[mapping.parameter] = [{...mapping}];
      }
    });
     Object.keys(result) && Object.keys(result).forEach((val)=>{
        result[val].reverse()
    })
    return result;
  }
  addParameter = () => {
    this.setState(state => {
      return {
        noOfParameters: state.noOfParameters + 1
      };
    });
  };
  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values,"values")
        const {
          questionResponse,
          optionResponse,
          createParameterQuestionMapping,
          updateParameterQuestionMapping,
          setEnabled,
          isEditMode,
          questionId,
          assessmentId,
          activeQuestion
        } = this.props;
        let promiseArr = [];
        if(isEditMode) {
          const { parameterQuestionMappingObj } = this.state;
          var updateLength
          if(parameterQuestionMappingObj){
            updateLength = Object.keys(parameterQuestionMappingObj).length - 1;
          }
          else{
            updateLength = -1;
          }
          let i = 0;
          // iterate over parameters
          let size= Object.keys(values) ? Object.keys(values).length : 0
          for(let parameterKey= 0; parameterKey <size; parameterKey++ ){
            let parameterObj = JSON.parse(values[parameterKey].parameter);
            delete values[parameterKey].parameter;
            // iterate o""ver parameter options
            Object.keys(values[parameterKey]) && Object.keys(values[parameterKey]).forEach(optionKey => {
              // create payload
              let payload = {
                ...values[parameterKey][optionKey],
                parameterType: parameterObj.parameterType,
                parameter: parameterObj.parameter,
                option: activeQuestion.options[optionKey],
                question: questionId,
                assessment: assessmentId
              };
              if(i<=updateLength) {
                const parameterQuestionMappingId = parameterQuestionMappingObj[parameterObj.parameter] && parameterQuestionMappingObj[parameterObj.parameter][optionKey] && parameterQuestionMappingObj[parameterObj.parameter][optionKey]._id;
                if(parameterQuestionMappingId){
                  promiseArr.push(updateParameterQuestionMapping(parameterQuestionMappingId, payload));
                }
                else{
                  promiseArr.push(createParameterQuestionMapping(payload));
                }
              }
              else {
                promiseArr.push(createParameterQuestionMapping(payload));
              }
            });
            i++;
          }
        }
        else {
           // iterate over parameters
           Object.keys(values).forEach(parameterKey => {
            let parameterObj = JSON.parse(values[parameterKey].parameter);
            delete values[parameterKey].parameter;
            // iterate over parameter options
            Object.keys(values[parameterKey]).forEach(optionKey => {
              // create payload
              let payload = {
                ...values[parameterKey][optionKey],
                parameterType: parameterObj.parameterType,
                parameter: parameterObj.parameter,
                option: optionResponse[optionKey],
                question: questionResponse._id,
                assessment: questionResponse.assessment
              };
              promiseArr.push(createParameterQuestionMapping(payload));
            });
          });
        }
        Promise.all(promiseArr)
          .then(res => {
            if (res && res[0]) {
              if(isEditMode) {
                this.props.fetchQuestion()
                message.success("All parameters updated successfully!");
              }
              else {
                setEnabled(false);
                message.success("All parameters created successfully!");
                setTimeout(() => {
                  message.success("Question Creation Successful!");
                  this.props.history.goBack();
                }, 1000);
              }
            } else {
              message.error("Oops! Error");
            }
          })
          .catch(err => {
            console.log(err);
            message.error("Oops! Error");
          });
      }
    });
  };
  render() {
    const { noOfParameters, parameterQuestionMappingObj } = this.state;
    const { parameterList, noOfOptions, isEditMode, activeQuestion } = this.props;
    const { getFieldDecorator } = this.props.form;

    let parametersArr = new Array(noOfParameters).fill(true);     

    if(isEditMode && activeQuestion && parameterQuestionMappingObj) {
      const parameterQuestionArr = Object.keys(parameterQuestionMappingObj)
      parametersArr = [...parameterQuestionArr, ...parametersArr];
    }
    return (
      <div className="component-question-step-three-clearfix">
        <div className="flex items-center justify-between">
          <h3 className="text-base text-black">Parameter</h3>
          <h3
            className="text-14 text-black cursor-pointer"
            onClick={this.addParameter}
          >
            Add Parameter
          </h3>
        </div>
        {parametersArr.map((elem, index) => {
          return (
          <div key={index}>
            <div className="flex justify-between">
              <div style={{ width: "60%" }}>
                <h4 className="text-black">Name</h4>
                <Form.Item>
                  {getFieldDecorator(`${index}.parameter`, {
                    initialValue: getSafely(() => JSON.stringify({
                      parameterType: parameterQuestionMappingObj[elem][0].parameterType,
                      parameter: elem
                    }), undefined),
                    rules: [
                      {
                        required: true,
                        message: "Required"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select a parameter"
                      style={{ width: 200 }}
                      showSearch
                      disabled={!this.props.enabled}
                    >
                      {parameterList &&
                        parameterList.map(parameter => (
                          <Option
                            value={JSON.stringify({
                              parameterType: parameter.parameterType,
                              parameter: parameter._id
                            })}
                            key={parameter.id}
                          >
                            {parameter.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
              {/* <div style={{ width: "calc(40% - 20px)" }}>
                <h4 className="text-black">Total Weight</h4>
                <Input disabled={!this.props.enabled} />
              </div> */}
            </div>
            <div
              style={{ marginTop: "20px" }}
              className="answer-weight-container"
            >
              <h4 style={{ color: "#8c8c8c" }} className="text-14">
                Answer weight
              </h4>
              <div className="flex flex-wrap">
                {(!parameterQuestionMappingObj || !parameterQuestionMappingObj[elem]) &&
                new Array(noOfOptions).fill(true).map((elem, optionIndex) => {
                  return (
                    <div
                      className="main-weights-wrapper"
                      key={optionIndex}
                      style={{ marginRight: "10px" }}
                    >
                      <h1 className="text-black text-14">
                        Option {optionIndex + 1}
                      </h1>
                      <Form.Item>
                        {getFieldDecorator(`${index}.${optionIndex}.weight`, {
                          initialValue: undefined,
                          rules: [{ required: true, message: "Required" }]
                        })(<InputNumber 
                          style={{width: "100%"}} 
                          disabled={!this.props.enabled} 
                          min={0}
                          max={1000000}
                        />)}
                      </Form.Item>
                    </div>
                  );
                })}
                {parameterQuestionMappingObj && parameterQuestionMappingObj[elem] &&
                 new Array(noOfOptions).fill(true).map((elem1, optionIndex) => {
                  return (
                    <div
                      className="main-weights-wrapper"
                      key={optionIndex}
                      style={{ marginRight: "10px" }}
                    >
                      <h1 className="text-black text-14">
                        Option {optionIndex + 1}
                      </h1>
                      <Form.Item>
                        {getFieldDecorator(`${index}.${optionIndex}.weight`, {
                          initialValue: getSafely(() => parameterQuestionMappingObj[elem][optionIndex].weight, undefined),
                          rules: [{ required: true, message: "Required" }]
                        })(<InputNumber 
                          style={{width: "100%"}} 
                          disabled={!this.props.enabled}
                          min={0}
                          max={1000000}/>
                        )}
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider />
          </div>
        )})}
        <div className="w-full flex justify-end mt-20">
          <Button
            type="primary"
            disabled={!this.props.enabled}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const QuestionStepThreeForm = Form.create({
  name: "question_step_three_form"
})(QuestionStepThree);

export default withRouter(QuestionStepThreeForm);
