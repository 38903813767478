import React, { Component } from "react";
import "./recommendationsPage.scss";
import RecommendationsForm from "forms/recommendationForm";
import { Input, Table, Badge, message,Icon,InputNumber,Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as productActions from "actions/products";
import * as actions from "actions/recommendations";
import { CSVLink } from "react-csv";
const { Search } = Input;
const { confirm } = Modal;

class RecommendationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: "Content of the modal",
      visible: false,
      loading: false,
      mode: "create",
      activeTag: null,
      formTitle: "Create Recommendation",
      formInputFields: [
        {
          fieldName: "Product",
          key: "product",
          type: ""
        }
      ],
      showExportModal:false,
      exportModalLoading:false,
      numberOfRows:10
    };
    this.formRef = React.createRef();
    this.csvRef = React.createRef();
  }
  async componentDidMount() {
    await this.props.fetchAllRecommendations();
    this.props.fetchListProducts();
  }
  onChangeInput = value => {
    this.setState({numberOfRows:value})
  }
  isEditMode() {
    return this.state.mode === "edit";
  }
  handleCreateRecommendation = () => {
    const { resetFields } = this.formRef.current;
    resetFields();
    this.setState({
      activeTag: null,
      visible: true,
      mode: "create"
    });
  };

  handleFormSubmit = () => {
    const { validateFields, resetFields } = this.formRef.current;
    validateFields((err, values) => {
      if (err) {
        return;
      }
      console.log(values)
      this.setState({ loading: true });
      
      if (this.isEditMode()) {
        this.props.updateTag(this.state.activeTag.id, values).then(res => {
          this.setState({ loading: false });
          if (res) {
            resetFields();
            this.setState({ visible: false });
            message.success(`Updated Successfully`);
          } else {
            message.error("Oops, Error");
          }
        });
      } else {
        this.props.createRecommendation(values).then(async res => {
          if (res) {
            await this.props.fetchAllRecommendations()
            this.setState({ loading: false });
            resetFields();
            this.setState({ visible: false });
            message.success(`Created Successfully`);
          } else {
            this.setState({ loading: false });
            message.error("Oops, Error");
          }
        });
      }
    });
  };

  handleFormCancel = () => {
    console.log("Clicked cancel button");
    this.setState({
      visible: false
    });
  };
  handleDeleteRecommendation = (recommendation) => {
    // let payload ={
    //   isDeleted:true
    // }
    confirm({
      title: `Are you sure you want to delete this Recommendation?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        return this.props.deleteRecommendation(recommendation.id).then((res) => {
          this.props.fetchAllRecommendations()
          if (res) {
            message.success("Recommendation deleted successfully.");
          } else {
            message.error("Oops! Something went wrong");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  render() {
    const columns = [
      {
        title: "Show On Results Page",
        dataIndex: "showInResultsPage",
        key: "showInResultsPage",
        render: state => {
          return state ? (
            <Badge status="success" />
          ) : (
            <Badge status="default" />
          );
        },
        width:"30%"
      },
      {
        title: "Product",
        dataIndex: "product",
        key: "product"
      },
      {
        title: "Action",
        key: "action",
        fixed: "right",
        width: 100,
        
        render: (recommendation) => {
          return (
            <div className="flex">
            
                
                <div className="delete-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleDeleteRecommendation(recommendation);
                  }}
                >
                  Delete
                </div>
              
            </div>
          );
        },
      },
    ];
    let headers= columns.map(item=>{
      return {label:item.title,
         key:item.key}
     })
    const mappedRecommendationList =
      this.props.recommendationsList.length > 0 ?
      this.props.recommendationsList.map(recommendation => {
        return {
          id: recommendation.id,
          showInResultsPage: recommendation.showInResultsPage,
          product: recommendation.product && recommendation.product.name
        };
      }) : [];
      const csvData= mappedRecommendationList.map(
        (item)=>{
            return {showInResultsPage:item.showInResultsPage ? 'True' : 'False' , product: item.product}
          } 
        ).slice(0,this.state.numberOfRows)
    return (
      <div className="recommendations-page" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div
            className="theme-color semibold text-30 mr-14"
            style={{ marginBottom: "0px" }}
          >
            Recommendations
          </div>
          <div className="flex items-center cursor-pointer" 
            onClick={() => {
            this.setState({showExportModal:true})
            }}>
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>
        <div className="flex nav-container justify-between">
          <p className="sub-text-top-left theme-color">All</p>
          <RecommendationsForm
            title={this.state.formTitle}
            visible={this.state.visible}
            formInputFields={this.state.formInputFields}
            loading={this.state.loading}
            handleOk={this.handleFormSubmit}
            handleCancel={this.handleFormCancel}
            handleCreateRecommendation={this.handleCreateRecommendation}
            activeTag={this.state.activeTag}
            ref={this.formRef}
            productList={this.props.productList}
          />
        </div>

        <div>
          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            {/* <Search
              placeholder="Search recommendation name"
              onSearch={value => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              // onKeyUp={e => this.handleSearch(e.target.value)}
            /> */}
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedRecommendationList}
              bordered
              size="small"
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={()=>{this.setState({showExportModal:false})}}
          onOk={async ()=>{
            this.setState({exportModalLoading:true})
            this.setState({exportModalLoading:false,showExportModal:false})
            this.csvRef.current && this.csvRef.current.link.click()
          }}
        >
          <InputNumber min={1} defaultValue={this.state.numberOfRows}  onChange={this.onChangeInput}/>
        </Modal>
        <CSVLink data={csvData} headers={headers} ref={this.csvRef} style={{display:"none"}}
          filename='recommendations.csv'/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Products, Recommendations } = state;
  return {
    productList: Products.products,
    recommendationsList: Recommendations.recommendations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListProducts: bindActionCreators(productActions.getProductsList, dispatch),
    fetchAllRecommendations: bindActionCreators(actions.getRecommendationsList, dispatch),
    createRecommendation: bindActionCreators(actions.createRecommendation, dispatch),
    deleteRecommendation:bindActionCreators(actions.deleteRecommendation,dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsPage);
