import React, { Component } from "react";
import "./tagsPage.scss";
import TagsForm from "forms/tagsForm";
import { Input, Table, Badge, message,Icon,InputNumber,Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/tags";
import { CSVLink } from "react-csv";
import { debounce } from "utils/helpers";
const { Search } = Input;

class TagsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: "Content of the modal",
      visible: false,
      loading: false,
      mode: "create",
      activeTag: null,
      formTitle: "Create Tag",
      formInputFields: [
        {
          fieldName: "Name",
          key: "name",
          type: ""
        }
      ],
      showExportModal:false,
      exportModalLoading:false,
      numberOfRows:10
    };
    this.formRef = React.createRef();
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    this.props.fetchAllTags();
  }
  onChangeInput = value => {
    this.setState({numberOfRows:value})
  }
  isEditMode() {
    return this.state.mode === "edit";
  }
  handleSearch = debounce(async value => {
    this.setState(
      {
        searchKey: value
      },
      () => {
        this.props.fetchAllTags(value);
      }
    );
  }, 300);
  handleCreateTag = () => {
    this.setState({
      activeTag: null,
      visible: true,
      mode: "create"
    });
  };
  handleEditTag = tag => {
    this.setState({
      activeTag: tag,
      visible: true,
      mode: "edit"
    });
  };
  handleFormSubmit = () => {
    const { validateFields, resetFields } = this.formRef.current;
    validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });
      if (this.isEditMode()) {
        this.props.updateTag(this.state.activeTag.id, values).then(res => {
          this.setState({ loading: false });
          if (res) {
            resetFields();
            this.setState({ visible: false });
            message.success(`Updated Successfully`);
          } else {
            message.error("Oops, Error");
          }
        });
      } else {
        this.props.createTag(values).then(res => {
          this.setState({ loading: false });
          if (res) {
            resetFields();
            this.setState({ visible: false });
            message.success(`Created Successfully`);
          } else {
            message.error("Oops, Error");
          }
        });
      }
    });
  };

  handleFormCancel = () => {
    console.log("Clicked cancel button");
    this.setState({
      visible: false
    });
  };
  render() {
    const columns = [
      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        render: state => {
          return state ? (
            <Badge status="success" />
          ) : (
            <Badge status="default" />
          );
        }
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      }
    ];
    let headers= columns.map(item=>{
      return {label:item.title,
         key:item.key}
     })
    const mappedTagList =
      this.props.tagList.length > 0 ?
      this.props.tagList.map(tag => {
        return {
          id: tag.id,
          active: tag.isActive,
          name: tag.name
        };
      }) : [];
      const csvData= mappedTagList.map(
        (item)=>{
            return {active:item.active ? 'True' : 'False' , name: item.name}
          } 
        ).slice(0,this.state.numberOfRows)
    return (
      <div className="tags-page" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div
            className="theme-color semibold text-30 mr-14"
            style={{ marginBottom: "0px" }}
          >
            Tags
          </div>
          <div className="flex items-center cursor-pointer" 
            onClick={() => {
            this.setState({showExportModal:true})
            }}>
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>
        <div className="flex nav-container justify-between">
          <p className="sub-text-top-left theme-color">All</p>
          <TagsForm
            title={this.state.formTitle}
            visible={this.state.visible}
            formInputFields={this.state.formInputFields}
            loading={this.state.loading}
            handleOk={this.handleFormSubmit}
            handleCancel={this.handleFormCancel}
            handleCreateTag={this.handleCreateTag}
            handleEditTag={this.handleEditTag}
            activeTag={this.state.activeTag}
            ref={this.formRef}
          />
        </div>

        <div>
          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            <Search
              placeholder="Search tag name"
              onSearch={value => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              onKeyUp={e => this.handleSearch(e.target.value)}
            />
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedTagList}
              bordered
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    this.handleEditTag(record);
                  }
                };
              }}
              size="small"
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={()=>{this.setState({showExportModal:false})}}
          onOk={async ()=>{
            this.setState({exportModalLoading:true})
            this.setState({exportModalLoading:false,showExportModal:false})
            this.csvRef.current && this.csvRef.current.link.click()
          }}
        >
          <InputNumber min={1} defaultValue={this.state.numberOfRows}  onChange={this.onChangeInput}/>
        </Modal>
        <CSVLink data={csvData} headers={headers} ref={this.csvRef} style={{display:"none"}}
          filename='tags.csv'/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Tags } = state;
  return {
    tagList: Tags.tags
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllTags: bindActionCreators(actions.getSearchedTags, dispatch),
    createTag: bindActionCreators(actions.createTag, dispatch),
    updateTag: bindActionCreators(actions.updateTag, dispatch),
    deleteTag: bindActionCreators(actions.deleteTag, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsPage);
