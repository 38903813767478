import React, { Component } from "react";
import "./taxPage.scss";
import TaxForm from "forms/taxForm";
import { Input, Table, Badge, message, Icon, InputNumber, Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/tax";
import { CSVLink } from "react-csv";
import { debounce } from "utils/helpers";
const { Search } = Input;

class TaxPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalText: "Content of the modal",
      visible: false,
      loading: false,
      mode: "create",
      activeTax: null,
      formTitle: "Create Tax",
      formInputFields: [
        {
          fieldName: "Name",
          key: "name",
          type: "",
        },
      ],
      showExportModal: false,
      exportModalLoading: false,
      numberOfRows: 20,
    };
    this.formRef = React.createRef();
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    this.props.fetchAllTax();
  }
  onChangeInput = (value) => {
    this.setState({ numberOfRows: value });
  };
  isEditMode() {
    return this.state.mode === "edit";
  }
  handleSearch = debounce(async (value) => {
    this.setState({
      searchKey: value,
    });
  }, 500);
  handleCreateTax = () => {
    this.setState({
      activeTax: null,
      visible: true,
      mode: "create",
    });
  };
  handleEditTag = (tag) => {
    this.setState({
      activeTax: tag,
      visible: true,
      mode: "edit",
    });
  };
  handleFormSubmit = () => {
    const { validateFields, resetFields } = this.formRef.current;
    validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });
      if (this.isEditMode()) {
        this.props.updateTax(this.state.activeTax.id, values).then((res) => {
          this.setState({ loading: false });
          if (res) {
            resetFields();
            this.setState({ visible: false });
            message.success(`Updated Successfully`);
            this.props.fetchAllTax();
          } else {
            message.error("Oops, Error");
          }
        });
      } else {
        this.props.createTax(values).then((res) => {
          this.setState({ loading: false });
          if (res) {
            resetFields();
            this.setState({ visible: false });
            message.success(`Created Successfully`);
            this.props.fetchAllTax();
          } else {
            message.error("Oops, Error");
          }
        });
      }
    });
  };

  handleFormCancel = () => {
    this.setState({
      visible: false,
    });
  };

  getSearchedResult = () => {
    const { searchKey } = this.state;
    if (searchKey) {
      return this.props.taxList
        .filter((item) => {
          return item.state.toLowerCase().includes(searchKey.toLowerCase());
        })
        .map((tax) => {
          return {
            id: tax.id,
            active: tax.isActive,
            country: tax.country,
            state: tax.state,
            value: tax.value,
            shipping: tax.shipping,
            zipCode: tax.zipCode,
            stateRate: tax.stateRate,
            countyRate: tax.countyRate,
            cityRate: tax.cityRate,
            specialRate: tax.specialRate,
            fallbackState: tax.fallbackState,
          };
        });
    }
    return (this.props.taxList || []).map((tax) => {
      return {
        id: tax.id,
        active: tax.isActive,
        country: tax.country,
        state: tax.state,
        value: tax.value,
        shipping: tax.shipping,
        zipCode: tax.zipCode,
        stateRate: tax.stateRate,
        countyRate: tax.countyRate,
        cityRate: tax.cityRate,
        specialRate: tax.specialRate,
        fallbackState: tax.fallbackState,
      };
    });
  };
  render() {
    const columns = [
      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        render: (state) => {
          return state ? (
            <Badge status="success" />
          ) : (
            <Badge status="default" />
          );
        },
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Country",
        dataIndex: "country",
        key: "name",
      },
      {
        title: "ZipCode",
        dataIndex: "zipCode",
        key: "zipCode",
      },
      {
        title: "Tax Percentage",
        dataIndex: "value",
        key: "value",
      },
      {
        title: "State Rate",
        dataIndex: "stateRate",
        key: "stateRate",
      },
      {
        title: "County Rate",
        dataIndex: "countyRate",
        key: "countyRate",
      },
      {
        title: "City Rate",
        dataIndex: "cityRate",
        key: "cityRate",
      },
      {
        title: "Special Rate",
        dataIndex: "specialRate",
        key: "specialRate",
      },
      {
        title: "Fallback State",
        dataIndex: "fallbackState",
        key: "fallbackState",
        render: (state) => {
          return state ? (
            <Badge status="success" />
          ) : (
            <Badge status="default" />
          );
        },
      },
      {
        title: "Tax on shipping",
        dataIndex: "shipping",
        key: "shipping",
        render: (state) => {
          return state ? (
            <Badge status="success" />
          ) : (
            <Badge status="default" />
          );
        },
      },
    ];
    // let headers = columns.map((item) => {
    //   return { label: item.title, key: item.key };
    // });
    // const csvData = mappedTaxList
    //   .map((item) => {
    //     return { active: item.active ? "True" : "False", name: item.name };
    //   })
    //   .slice(0, this.state.numberOfRows);
    return (
      <div className="tax-page" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div
            className="theme-color semibold text-30 mr-14"
            style={{ marginBottom: "0px" }}
          >
            Tax
          </div>
          {/* <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              this.setState({ showExportModal: true });
            }}
          >
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div> */}
        </div>
        <div className="flex nav-container justify-between">
          <p className="sub-text-top-left theme-color">All</p>
          <TaxForm
            title={this.state.formTitle}
            visible={this.state.visible}
            loading={this.state.loading}
            handleOk={this.handleFormSubmit}
            handleCancel={this.handleFormCancel}
            handleCreateTax={this.handleCreateTax}
            handleEditTag={this.handleEditTag}
            activeTax={this.state.activeTax}
            ref={this.formRef}
          />
        </div>

        <div>
          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            <Search
              placeholder="Search state"
              onSearch={(value) => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              onKeyUp={(e) => this.handleSearch(e.target.value)}
            />
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={this.getSearchedResult()}
              bordered
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.handleEditTag(record);
                  },
                };
              }}
              size="small"
              pagination={{
                pageSize: 20,
              }}
            />
          </div>
        </div>
        {/* <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={() => {
            this.setState({ showExportModal: false });
          }}
          onOk={async () => {
            this.setState({ exportModalLoading: true });
            this.setState({
              exportModalLoading: false,
              showExportModal: false,
            });
            this.csvRef.current && this.csvRef.current.link.click();
          }}
        >
          <InputNumber
            min={1}
            defaultValue={this.state.numberOfRows}
            onChange={this.onChangeInput}
          />
        </Modal> */}
        {/* <CSVLink
          data={csvData}
          headers={headers}
          ref={this.csvRef}
          style={{ display: "none" }}
          filename="tax.csv"
        /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Tax } = state;
  return {
    taxList: Tax.tax,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllTax: bindActionCreators(actions.getSearchedTax, dispatch),
    createTax: bindActionCreators(actions.createTax, dispatch),
    updateTax: bindActionCreators(actions.updateTax, dispatch),
    // deleteTag: bindActionCreators(actions.deleteTag, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxPage);
