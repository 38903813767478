import actionTypes from "actions/actionTypes";

const initialState = {
  reviews: [],
  activeReview: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_REVIEW: 
      return {
        ...state,
        activeReview: { ...action.payload }
      };
    case actionTypes.LIST_REVIEWS:
      return {
        ...state, 
        reviews: [ ...action.payload ]
      };
    case actionTypes.ADD_REVIEW:
      return {
        ...state,
        activeIngredient: { ...action.payload }
      };
    default:
      return state
  }
}

