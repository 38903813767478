const configs = {
  development: {
    API_URL: "https://dev-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://dev-web.lovefromyours.com",
  },
  production: {
    API_URL: "https://beta-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://lovefromyours.com",
  },
  localhost: {
    API_URL: "http://localhost:8080/api/v1/",
    WEB_URL: "http://localhost:3000",
  },
  qa: {
    API_URL: "https://qa-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://qa-web.lovefromyours.com",
  },
  beta: {
    API_URL: "https://beta-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://lovefromyours.com",
  },
  red: {
    API_URL: "https://red-api.yoursinternal.com/api/v1/",
    WEB_URL: "https://red.yoursinternal.com",
  },
  blue: {
    API_URL: "https://dev-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://blue.yoursinternal.com",
  },
  pink: {
    API_URL: "https://dev-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://pink.yoursinternal.com",
  },
  green: {
    API_URL: "https://dev-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://green.yoursinternal.com",
  },
  qa1: {
    API_URL: "https://qa1-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://qa1-admin.lovefromyours.com",
  },
  qa2: {
    API_URL: "https://qa2-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://qa2-admin.lovefromyours.com",
  },
  qa3: {
    API_URL: "https://qa3-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://qa3-admin.lovefromyours.com",
  },
  qa4: {
    API_URL: "https://qa4-api.lovefromyours.com/api/v1/",
    WEB_URL: "https://qa4-admin.lovefromyours.com",
  },
};

const NODE_ENV = process.env.NODE_ENV;
const REACT_APP_ENV = process.env.REACT_APP_ENV;

const ENV =
  (configs[REACT_APP_ENV] !== undefined && REACT_APP_ENV) ||
  (NODE_ENV === "production" ? "production" : "development");

// Do not remove this line
console.log(`mode: ${ENV}`);

export default configs[ENV];
