import { Api } from "../config/request";


export const loginRequest = payload => {
  return Api.postRequest(`auth/login/cms-user`, payload);
};

export const getGoogleLoginPage = () => {
  return `auth/admin/social/google`;
}

export const verifyGoogleLoginRequest = (payload) => {
  return Api.getRequest(`auth/login/admin/social/google/?code=${payload.code}
    &scope=${payload.scope}&authuser=${payload.authuser}
    &hd=${payload.hd}&session_state=${payload.session_state}
    &prompt${payload.prompt}`);
};



const CommonAPI = {
    loginRequest,
    getGoogleLoginPage,
    verifyGoogleLoginRequest
};

export default CommonAPI;
