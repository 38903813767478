import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Icon,
  Tag,
  Select,
  Input,
  Button,
  Upload,
  Form,
  Switch,
  message,
} from "antd";
import { ReactSortable } from "react-sortablejs";
import { Link } from "react-router-dom";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./addIngredients.scss";
import { getBase64, beforeUpload, UploadImage } from "utils/helpers";
import * as actions from "actions/ingredients";
import * as productActions from "actions/products";
import * as tagsActions from "actions/tags";
import { getSafely } from "utils/helpers";

const { Option } = Select;
const { TextArea } = Input;

class AddIngredients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: [
        {
          fieldName: "Ingredient Name",
          key: "name",
        },
        {
          fieldName: "Slug",
          key: "slug",
        },
        {
          fieldName: "Subheading on the ingredient card **",
          key: "subHeadingOnCard",
        },
        {
          fieldName: "Short description on the ingredient card **",
          key: "shortDescOnCard",
        },
        {
          fieldName: "Sub-heading **",
          key: "subHeading",
        },
      ],
      // descEditorState: EditorState.createEmpty(),
      shortdescEditorState: EditorState.createEmpty(),
      validateStatusDescEditor: "",
      errorMsgDescEditor: null,

      longdescEditorState: EditorState.createEmpty(),
      validateStatusLongDescEditor: "",
      errorMsgLongDescEditor: null,

      imageUrl: "",
      imageLoading: false,
      mode: "create",
      activeIngredientId: null,

      infoImageUrl: "",
      // FIXME: delete mediaUrl or added new shema to backend
      infoImageObj: { mediaUrl: null, mediaType: null },

      galleryAwards: [{ mediaUrl: "", order: 1, mediaType: "" }],
      galleryEvidence: [{ mediaUrl: "", order: 1, mediaType: "" }],
      imageLoadingGallery: [],
      imageLoadingGalleryEvidence: [],
      imageLoadingBanner: false,

      numberOfProducts:0,

      ewgRating: '',
      hasPage: false,

      // TODO: this lists can be to write to database
      ewdRatingList: [
        '1 - Safe',
        '2 - Safe',
        '3 - Moderate',
        '4 - Moderate',
      ],

      tagsList: [],
      newTagsList: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      match: { params },
    } = this.props;
    if (params.id !== undefined) {
      this.setState({
        mode: "edit",
        activeIngredientId: params.id,
      });
      this.props.getActiveIngredient(params.id).then((res) => {
        if( !res){
          return;
        }
        if (res.mainImage) {
          this.setState({
            imageUrl: res.mainImage.mediaUrl,
          });
        }
        if (res.infoImage) {
          this.setState({
            infoImageObj: {
              ...this.state.infoImageObj, 
              mediaType: res.infoImage.mediaType,
              mediaUrl: res.infoImage.mediaUrl,
            },
          });
        }
        if (res.galleryAwards) {
          const arr = [...res.galleryAwards];
          if (res.galleryAwards.length < 2) {
            arr.push({ mediaUrl: "", order: 1, mediaType: "" });
          }
          this.setState({ galleryAwards: arr });
        }
        if (res.galleryEvidence) {
          const arr = [...res.galleryEvidence];
          if (res.galleryEvidence.length < 2) {
            arr.push({ mediaUrl: "", order: 1, mediaType: "" });
          }
          this.setState({ galleryEvidence: arr });
        }
        if (res.tags) {
          this.setState({ tagsList: res.tags })
        }
        if (res.hasPage) {
          this.setState({ hasPage: res.hasPage })
        }
        this.setState({
          numberOfProducts: res?.productDescriptions?.length
        })
      });
    } else {
      this.props.setActiveIngredient(null);
    }
    this.props.fetchAllProducts();
    this.props.fetchAllTags();
  }

  isEditMode() {
    return this.state.mode === "edit";
  }

  handleImageChange = (info, imageType, index) => {
    if (info.file.status === "uploading") {
      if (imageType === "main" || imageType === "infoImage") {
        this.setState({ imageLoading: true });
      } else if (imageType === "galleryAwards") {
        const arr = [...this.state.imageLoadingGallery];
        arr[index] = true;
        this.setState({ imageLoadingGallery: arr });
      }
      else if (imageType === "galleryEvidence") {
        const arr = [...this.state.imageLoadingGalleryEvidence];
        arr[index] = true;
        this.setState({ imageLoadingGalleryEvidence: arr });
      }
      return;
    }
    if (info.file.status === "done") {
      if (imageType === "main") {
        this.props.form.setFieldsValue({
          "mainImage.mediaType": info.file.type,
        });
      }
      if (imageType === "infoImage") {
        this.setState({
          infoImageObj: {...this.state.infoImageObj, mediaType:  info.file.type}
        })
        this.props.form.setFieldsValue({
          "infoImage.mediaType": this.state.infoImageObj.mediaType,
        });
      }
      getBase64(info.file.originFileObj, (imageUrl) => {
        if (imageType === "main") {
          this.setState({
            imageUrl,
            imageLoading: false,
          });
          this.props.form.setFieldsValue({
            "mainImage.withoutHover.mediaType": info.file.type,
          });
        } else if (imageType === "infoImage") {
          this.setState({
            infoImageObj: {...this.state.infoImageObj, mediaUrl: imageUrl},
            imageLoading: false,
          });
          this.props.form.setFieldsValue({
            "infoImage.withoutHover.mediaType": info.file.type,
          });
        } else if (imageType === "galleryAwards") {
          const arr = [...this.state.imageLoadingGallery];
          const images = [...this.state.galleryAwards];
          if (images[index].mediaUrl === "" && images.length < 2) {
            arr[index] = false;
            images[index].mediaUrl = imageUrl;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = imageUrl;
          }
          this.setState({
            galleryAwards: images,
            imageLoadingBanner: arr,
          });
          const formArray = [...this.props.form.getFieldValue("galleryAwards")];
          formArray[index] = {
            mediaUrl: formArray[index].mediaUrl,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            galleryAwards: formArray,
          });
        } else if (imageType === "galleryEvidence") {
          const arr = [...this.state.imageLoadingGallery];
          const images = [...this.state.galleryEvidence];
          if (images[index].mediaUrl === "" && images.length < 2) {
            arr[index] = false;
            images[index].mediaUrl = imageUrl;
            images.push({ mediaUrl: "" });
            arr.push(false);
          } else {
            arr[index] = false;
            images[index].mediaUrl = imageUrl;
          }
          this.setState({
            galleryEvidence: images,
            imageLoadingBanner: arr,
          });
          const formArray = [...this.props.form.getFieldValue("galleryEvidence")];
          formArray[index] = {
            mediaUrl: formArray[index].mediaUrl,
            mediaType: info.file.type,
          };
          this.props.form.setFieldsValue({
            galleryEvidence: formArray,
          });
        }
      });
    }
  };

  onDescEditorStateChange = (editorState) => {
    this.setState({
      descEditorState: editorState,
    });
  };
  onShortDescEditorStateChange = (editorState) => {
    this.setState({
      shortdescEditorState: editorState,
    });
  };
  onLongDescEditorStateChange = (editorState) => {
    this.setState({
      longdescEditorState: editorState,
    });
  };

  onToggleHasPage = () => {
    this.setState({
      hasPage: !this.state.hasPage,
    });
  };

  renderInputFields = (arr) => {
    const { getFieldDecorator } = this.props.form;
    return arr.map((obj) => (
      <div className="mb-8" key={obj.key}>
        <p className="text-black regular text-14 mb-4">{obj.fieldName}</p>
        <Form.Item>
          {getFieldDecorator(obj.key, {
            initialValue: this.props.activeIngredient
              ? this.props.activeIngredient[obj.key]
              : undefined,
            rules: [{ 
              required: obj.key === 'name', 
              message: "Required" 
            }],
          })(<Input />)}
        </Form.Item>
      </div>
    ));
  };

  clickDeleteButton = (e) => {
    e.preventDefault()
    const deleteQuestion = window.confirm(`${this.props.activeIngredient.name}'ll be removed`)
    if(deleteQuestion) {
      this.handleDelete(e)
    }
  }

  handleDelete = (e) => {
    e.preventDefault();
    this.props
      .deleteIngredient(this.state.activeIngredientId)
      .then((res) => {
        this.setState({ loading: false });
        if (res) {
          message.success(`Removed Successfully`, 0.5, () => {
            window.location.href = '/ingredients'
          });
        } else {
          message.error("Oops, Error");
        }
      });
  };

  handleDeleteImg = (e, index, category) => {
    e.preventDefault();
    e.stopPropagation()

    const cutGallery = category === 'awards' ? 
    [...this.state.galleryAwards] :
    [...this.state.galleryEvidence]

    cutGallery.splice(index, 1)
    cutGallery.push({ mediaUrl: "", order: 1, mediaType: "" });
    const resultGallery = cutGallery.filter(img => img.mediaUrl !== "")
    const categoryToKey = {
      awards: 'galleryAwards',
      evidence: 'galleryEvidence',
    };
    this.setState({
      [categoryToKey[category]]: resultGallery.length
        ? cutGallery
        : [{ mediaUrl: "", order: 1, mediaType: "" }],
    })
  };

  handleDeleteInfoImg = (e) => {
    e.preventDefault();
    e.stopPropagation()

    this.setState({ infoImageObj: { mediaUrl: null, mediaType: null }  })
    this.props.form.setFieldsValue({
      "infoImage.mediaUrl": '',
      "infoImage.mediaType": 'image/png',
    })
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // validate editors
    const shortDescEditorContent = this.state.shortdescEditorState
      .getCurrentContent()
      .hasText();
    const longDescEditorContent = this.state.longdescEditorState
      .getCurrentContent()
      .hasText();
    if (!shortDescEditorContent) {
      this.setState({
        validateStatusDescEditor: "error",
        errorMsgDescEditor: "Required",
      });
    } else {
      this.setState({
        validateStatusDescEditor: "success",
        errorMsgDescEditor: "",
      });
    }
    if (!longDescEditorContent) {
      this.setState({
        validateStatusLongDescEditor: "error",
        errorMsgLongDescEditor: "Required",
      });
    } else {
      this.setState({
        validateStatusLongDescEditor: "success",
        errorMsgLongDescEditor: "",
      });
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.type = values.type ? "active" : "inactive";
        this.setState({ loading: true });
        const nonEmptyAwards = values.galleryAwards.filter(
          (image) => !!image.mediaUrl
        )
        const nonEmptyEvidence = values.galleryEvidence.filter(
          (image) => !!image.mediaUrl
        )
        values = {
          ...values,
          galleryAwards: nonEmptyAwards,
          galleryEvidence: nonEmptyEvidence
        }
        values["hasPage"] = this.state.hasPage;
        if (this.isEditMode()) {
          this.props
            .updateIngredient(this.state.activeIngredientId, values)
            .then((res) => {
              this.setState({ loading: false });
              if (res) {
                message.success(`Updated Successfully`);
              } else {
                message.error("Oops, Error");
              }
            });
        } else {
          this.props.createIngredient(values).then((res) => {
            this.setState({ loading: false });
            if (res) {
              message.success(`Created Successfully`);
              this.props.history.push("/ingredients");
            } else {
              message.error("Oops, Error");
            }
          });
        }
      }
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.file && e.file.response) {
      return e.file.response;
    }
    return e.file;
  };

  uploadButton = (state) =>(
    <div>
      <Icon type={state ? "loading" : "plus"} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  render() {
    const { getFieldDecorator } = this.props.form;

    const { imageUrl, infoImageObj } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.imageLoading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="ingredients-page" style={{ marginLeft: "200px" }}>
        <div className="flex justify-between w-full items-center">
          <Link to="/ingredients">
            <div
              className="flex justify-start items-center cursor-pointer"
              onClick={this.props.toggleIngredientsState}
            >
              <div>
                <Icon className="mr-4" type="left" />
              </div>
              <div className="text-14 pb-2">Back</div>
            </div>
          </Link>
          <Button
            type="primary"
            onClick={this.handleSubmit}
            loading={this.state.loading}
          >
            Save
          </Button>
          {/* {this.isEditMode() && (
            <div>
              <Icon
                type="arrow-left"
                style={{ fontSize: "30px", color: "#050505" }}
                className="mr-18 cursor-pointer"
              />
              <Icon
                type="arrow-right"
                style={{ fontSize: "30px", color: "#050505" }}
                className="cursor-pointer"
              />
            </div>
          )} */}
        </div>

        <div className="theme-color semibold text-30 mb-12">
          {this.isEditMode() && this.props.activeIngredient
            ? this.props.activeIngredient.name
            : ""}
        </div>

        {/* <div className="flex justify-start mb-12">
          <div className="flex justify-start items-center mr-22">
            <Icon type="copy" className="mr-8"></Icon>
            <div>Duplicate</div>
          </div>
          <div className="flex justify-start items-center">
            <Icon type="eye" className="mr-5"></Icon>
            <div>Views</div>
          </div>
        </div> */}

        <Form onSubmit={this.handleSubmit}>
          <div className="flex">
            <div className="contentLeft">
              <div className="desc">
                <p className="semibold text-18 text-black">Text</p>
                <div style={{marginBottom: '20px'}}>** there is you can set two varints word, <br/>
                for example write *{String.fromCharCode('123')}word_GB, word_US{String.fromCharCode('125')}* in a text
                </div>
                {this.renderInputFields(this.state.inputFields)}

                {/* <div className="mb-14">
                  <p className="text-black regular text-14 mb-4">
                    Result page - Discription
                  </p>
                  <Editor
                    editorState={this.state.descEditorState}
                    editorToolbar="editorToolbar"
                    editorWrapper="editorWrapper"
                    editorClassName="editor"
                    editor="editor"
                    onEditorStateChange={this.onDescEditorStateChange}
                    wrapperStyle={{
                      border: "1px solid #F1F1F1"
                    }}
                  />
                </div> */}

                <div className="mb-14">
                  <p className="text-black regular text-14 mb-4">
                    BENEFITS Description **(Results page)
                  </p>
                  <Form.Item
                  >
                    {getFieldDecorator('shortDescription', {
                      initialValue: this.props.activeIngredient?.shortDescription
                      // FIXME:  required: true
                      // rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea 
                          rows={5} 
                          />)}
                  </Form.Item>
                  {/* <Form.Item
                    label=""
                    validateStatus={this.state.validateStatusDescEditor}
                    help={this.state.errorMsgDescEditor || ""}
                  >
                    <Editor
                      editorState={this.state.shortdescEditorState}
                      editorToolbar="editorToolbar"
                      editorWrapper="editorWrapper"
                      editorClassName="editor"
                      editor="editor"
                      onEditorStateChange={this.onShortDescEditorStateChange}
                      wrapperStyle={{
                        border: "1px solid #F1F1F1",
                      }}
                    />
                  </Form.Item> */}
                </div>

                <div className="">
                  <p className="text-black regular text-14 mb-4">
                    Long Description ** (Do not apply HTML)
                  </p>
                  <Form.Item
                  >
                    {getFieldDecorator('longDescription', {
                      initialValue: this.props.activeIngredient?.longDescription
                      // FIXME:  required: true
                      // rules: [{ required: true, message: "Required" }],
                    })(<Input.TextArea 
                          rows={10} 
                          />)}
                  </Form.Item>
                </div>
              </div>
              <div className="images">
                <div className="flex justify-between mb-12">
                  <p className="semibold text-18 text-black">Images</p>
                </div>
                <div>
                  <div>
                    <Form.Item label="Main">
                      {getFieldDecorator("mainImage.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue: 
                          this.props.activeIngredient?.mainImage?.mediaUrl,
                        rules: [
                          {
                            required: true,
                            message: "Please upload a main image",
                          },
                        ],
                      })(
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={(e) => 
                            this.handleImageChange(e, "main", 0)
                          }
                          customRequest={UploadImage}
                        >
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("mainImage.mediaType", {
                        initialValue:
                          this.props.activeIngredient?.mainImage?.mediaType,
                      })(<span/>)}
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item label="Info Image (page inrgedient)">
                      {getFieldDecorator("infoImage.mediaUrl", {
                        getValueFromEvent: this.normFile,
                        initialValue: 
                          this.props.activeIngredient?.infoImage?.mediaUrl,
                        rules: [
                          {
                            required: false,
                            message: "Please upload a info image",
                          },
                        ],
                      })(
                        <Upload
                          name="info_image"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={(e) => 
                            this.handleImageChange(e, "infoImage", 0)
                          }
                          customRequest={UploadImage}
                        >
                          {infoImageObj.mediaUrl ? (
                            <img
                              src={infoImageObj.mediaUrl}
                              alt="info image"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      )}

                      <Button
                        type="danger"
                        onClick={(e) => {
                          this.handleDeleteInfoImg(e)
                        }}
                        loading={this.state.loading}
                      >
                        Delete
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator('infoImage.mediaType', {
                        initialValue:
                          this.props.activeIngredient?.infoImage?.mediaType,
                      })(<span/>)}
                    </Form.Item>
                  </div>

                </div>
              </div>

              <div className="images">
                <Form.Item
                  label="Awards"
                  className="mb-0"
                />
                <ReactSortable
                  list={this.state.galleryAwards}
                  setList={(val) => this.setState({ galleryAwards: val })}
                  className="flex flex-wrap"
                >
                  {this.state.galleryAwards.map((image, index) => (
                    <div key={image._id || image.order}>
                      <Form.Item className="mb-0">
                        {getFieldDecorator(`galleryAwards[${index}].mediaUrl`, {
                          getValueFromEvent: this.normFile,
                          initialValue: getSafely(
                            () => image.mediaUrl,
                            undefined
                          ),
                          rules: [
                            {
                              required: false,
                              message: "Required",
                            },
                          ],
                        })
                        (
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) =>
                              this.handleImageChange(e, "galleryAwards", index)
                            }
                            customRequest={UploadImage}
                          >
                            {this.state.galleryAwards &&
                            this.state.galleryAwards[index] &&
                            this.state.galleryAwards[index].mediaUrl !== "" ? (
                              <div>
                                <img
                                  src={this.state.galleryAwards[index].mediaUrl}
                                  alt="avatar"
                                  style={{
                                    maxHeight: "88px",
                                    maxWidth: "88px",
                                    margin: "auto",
                                  }}
                                />
                                <Button
                                  type="danger"
                                  onClick={(e) => {
                                    this.handleDeleteImg(e, index, 'awards')
                                  }}
                                >
                                  Delete Img
                                </Button>
                              </div>
                            ) : (
                              this.uploadButton(
                                this.state.imageLoadingGallery[index]
                              )
                            )}
                          </Upload>
                        )}
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {getFieldDecorator(
                          `galleryAwards[${index}].mediaType`,
                          {
                            initialValue: getSafely(
                              () => image.mediaType,
                              undefined
                            ),
                          }
                        )(<span/>)}
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {getFieldDecorator(`galleryAwards[${index}].order`, {
                          initialValue: getSafely(
                            () => index + 1,
                            undefined
                          ),
                        })(<span/>)}
                      </Form.Item>
                    </div>
                  ))}
                </ReactSortable>
              </div>
            
              <div className="images space-bottom">
                <Form.Item
                  label="Evidence"
                  className="mb-0"
                />
                <ReactSortable
                  list={this.state.galleryEvidence}
                  setList={(val) => this.setState({ galleryEvidence: val })}
                  className="flex flex-wrap"
                >
                  {this.state.galleryEvidence.map((image, index) => (
                    <div key={image._id || image.order}>
                      <Form.Item className="mb-0">
                        {getFieldDecorator(`galleryEvidence[${index}].mediaUrl`, {
                          getValueFromEvent: this.normFile,
                          initialValue: getSafely(
                            () => image.mediaUrl,
                            undefined
                          ),
                          rules: [
                            {
                              required: false,
                              message: "Required",
                            },
                          ],
                        })(
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={(e) =>
                              this.handleImageChange(e, "galleryEvidence", index)
                            }
                            customRequest={UploadImage}
                          >
                            {this.state.galleryEvidence &&
                            this.state.galleryEvidence[index] &&
                            this.state.galleryEvidence[index].mediaUrl !== "" ? (
                              <div>
                              <img
                                src={this.state.galleryEvidence[index].mediaUrl}
                                alt="avatar"
                                style={{
                                  maxHeight: "88px",
                                  maxWidth: "88px",
                                  margin: "auto",
                                }}
                              />
                                <Button
                                  type="danger"
                                  onClick={(e) => {
                                    this.handleDeleteImg(e, index, 'evidence')
                                  }}
                                >
                                  Delete Img
                                </Button>
                              </div>
                            ) : (
                              // uploadButton("plus")
                              this.uploadButton(
                                this.state.imageLoadingGallery[index]
                              )
                            )}
                          </Upload>
                        )}
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {getFieldDecorator(
                          `galleryEvidence[${index}].mediaType`,
                          {
                            initialValue: getSafely(
                              () => image.mediaType,
                              undefined
                            ),
                          }
                        )(<span/>)}
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        {getFieldDecorator(`galleryEvidence[${index}].order`, {
                          initialValue: getSafely(
                            () => index + 1,
                            undefined
                          ),
                        })(<span/>)}
                      </Form.Item>
                    </div>
                  ))}
                </ReactSortable>
              </div>

            </div>

            <div className="contentRight flex flex-col">
              {/* tags */}
              <div className="tags mb-28">
                <div className="flex justify-between">
                  <p className="semibold text-18 text-black mb-24">Tags</p>
                  <Link to="/tags">
                    <p className="regular text-14 text-link cursor-pointer">
                      Edit
                    </p>
                  </Link>
                </div>
                <Form.Item label="">
                  {getFieldDecorator("tags", {
                    initialValue:
                      this.props.activeIngredient?.tags?.length
                        ? this.props.activeIngredient.tags.map(
                            (tag) => tag.id
                          )
                        : undefined,
                    rules: [
                      {
                        required: false,
                        message: "Please select atleast one tag",
                        type: "array",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Select a tag"
                      style={{ width: "100%" }}
                      mode="multiple"
                      showSearch
                      optionFilterProp="children"
                    >
                      {this.props.tags &&
                        this.props.tags.map((tag) => (
                          <Option 
                            value={tag._id} 
                            key={tag._id}
                          >
                            {!tag.isActive &&
                              <div style={{color: 'red'}}> {tag.name} (DISABLE)</div>}
                            {tag.isActive && tag.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="select-products-container mb-28">
                <p className="line-22 text-base text-black">Active</p>
                <Form.Item>
                  {getFieldDecorator("type", {
                    initialValue: this.props.activeIngredient
                      ? this.props.activeIngredient.type === "active"
                      : true,
                    valuePropName: "checked",
                  })(<Switch />)}
                </Form.Item>
                <Form.Item label="Has individual page" className="mb-8">
                  <Switch onChange={this.onToggleHasPage} checked={this.state.hasPage} />
                </Form.Item>
              </div>
              <div className="select-products-container">
                <p className="line-22 text-base text-black" style={{marginBottom:28}}>
                  More Information
                </p>

                <div className="text-black regular text-14 mb-4">
                    INCI BREAKDOWN **
                </div>
                <Form.Item>
                  {getFieldDecorator('inciBreakdown', {
                    initialValue: this.props.activeIngredient?.inciBreakdown
                    // FIXME:  required: true
                    // rules: [{ required: true, message: "Required" }],
                  })(<Input.TextArea 
                        rows={5} 
                        />)}
                </Form.Item>

                <div className="text-black regular text-14 mb-4">
                  SOURCE
                </div>
                <Form.Item>
                  {getFieldDecorator('source', {
                    initialValue: this.props.activeIngredient?.source
                    // FIXME:  required: true
                    // rules: [{ required: true, message: "Required" }],
                  })(<Input />)}
                </Form.Item>

                <div className="text-black regular text-14 mb-4">
                  SUPPLIER
                </div>
                <Form.Item>
                  {getFieldDecorator('supplier', {
                    initialValue: this.props.activeIngredient?.supplier
                    // FIXME:  required: true
                    // rules: [{ required: true, message: "Required" }],
                  })(<Input />)}
                </Form.Item>

                <div className="text-black regular text-14 mb-4">
                  LOCATION
                </div>
                <Form.Item>
                  {getFieldDecorator('location', {
                    initialValue: this.props.activeIngredient?.location
                    // FIXME:  required: true
                    // rules: [{ required: true, message: "Required" }],
                  })(<Input />)}
                </Form.Item>
                <div className="h-line"/>
                {/* <div className="text-black regular text-14 mb-4">
                  EWG RATING
                </div>
                <Form.Item>
                  {getFieldDecorator('ewgRating', {
                    initialValue: this.props.activeIngredient?.ewgRating
                    // FIXME:  required: true
                    // rules: [{ required: true, message: "Required" }],
                  })(
                    <Select           
                      onChange={(e) => this.setState({ewgRating: e})}
                    >
                      {this.state.ewdRatingList.map((item) => (
                        <Option key={item} value={item}>{item}</Option>
                      ))}
                    </ Select>
                    
                  )}
                </Form.Item> */}
                <div className="text-black regular text-14 mb-4">
                  WORKS BEST FOR
                </div>
                {/* TODO: these tags can be either a const list or docs oin a database */}
                <Form.Item>
                  {getFieldDecorator('worksBestFor', {
                    initialValue: this.props.activeIngredient?.worksBestFor
                    // FIXME:  required: true
                    // rules: [{ required: true, message: "Required" }],
                  })(<Input.TextArea 
                        rows={5} 
                        />)}
                </Form.Item>
              </div>
              {/* products */}
              {/* <div className="products">
                <div className="flex justify-between">
                  <p className="semibold text-18 text-black mb-14">Products</p>
                  {this.isEditMode() && (
                    <p className="regular text-14 text-link">Edit</p>
                  )}
                </div>
                <p className="regular text-14 text-black mb-6">
                  Select Product
                </p>
                <Form.Item label="">
                  {getFieldDecorator("products", {
                    rules: [
                      {
                        required: true,
                        message: "Please select atleast one product",
                        type: "array"
                      }
                    ]
                  })(
                    <Select
                      // defaultValue="product1"
                      placeholder="Select a product"
                      style={{ width: "100%" }}
                      // onChange={handleChange}
                      mode="multiple"
                      showSearch
                    >
                      {this.props.allProducts &&
                        this.props.allProducts.map(product => (
                          <Option value={product._id} key={product._id}>
                            {product.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div> .. */}
            </div>
          </div>
        </Form>

        {/* <hr />

        <div className="flex justify-between pb-40">
          {this.isEditMode() ? (
            <Button
              type="danger"
              onClick={this.handleDelete}
              loading={this.state.deleteLoader}
            >
              Delete
            </Button>
          ) : (
            <div></div>
          )}
          
        </div> */}
        
        <Button
          type="danger"
          onClick={this.clickDeleteButton}
          loading={this.state.loading}
        >
          Delete
        </Button>
      </div>
    );
  }
}

const AddIngredientsForm = Form.create({ name: "add_ingredients_form" })(
  AddIngredients
);

function mapStateToProps(state) {
  const { Ingredients, Products, Tags } = state;
  return {
    ingredientsList: Ingredients.ingredients,
    allProducts: Products.products,
    tags: Tags.tags,
    activeIngredient: Ingredients.activeIngredient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchListIngredients: bindActionCreators(
      actions.getIngredientsList,
      dispatch
    ),
    fetchAllProducts: bindActionCreators(
      productActions.getSearchedProducts,
      dispatch
    ),
    fetchAllTags: bindActionCreators(tagsActions.getSearchedTags, dispatch),
    createIngredient: bindActionCreators(actions.createIngredient, dispatch),
    updateIngredient: bindActionCreators(actions.updateIngredient, dispatch),
    deleteIngredient: bindActionCreators(actions.deleteIngredient, dispatch),
    getActiveIngredient: bindActionCreators(actions.getIngredient, dispatch),
    setActiveIngredient: bindActionCreators(
      actions.setActiveIngredient,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIngredientsForm);
