import { Api } from "../config/request";


export const getCustomerList = query => {

  return Api.getListRequest(`user/list${query ? "?"+query:""}`);
  //return Api.getListRequest(`user/search${query ? "?"+query:""}`);
};

export const searchCustomerById = id => {
  return Api.getListRequest(`user/list?_id=${id}`);
};

export const getCustomerAddressList = (id,query) => {
  return Api.getListRequest(`user/address-book/list?user=${id}${query ? "&"+query:""}`);
};

export const addNewAddress = (payload) => {
  return Api.postRequest(`user/address-book`,payload);
};

export const updateAddress = (id, payload) => {
  return Api.patchRequest(`user/address-book/${id}`, payload);
}

export const getCustomerById = id => {
  return Api.getRequest(`user/${id}`);
};

export const updateCustomerById = (id, payload) => {
  return Api.patchRequest(`user/${id}`, payload);
};

export const getComments = (customerId) => {
  let url = `user/admin/comments/${customerId}`;
  
  return Api.getRequest(url);
};

export const postComment = (payload) => {
  let url = `user/admin/comments`;
  return Api.postRequest(url, payload);
};

export const postReply = (customerId, payload) => {
  let url = `user/admin/comments/${customerId}`;
  return Api.postRequest(url, payload);
};

export const updateComment = (customerId, payload) => {
  let url = `user/admin/comments/${customerId}`;
  return Api.patchRequest(url, payload);
};

export const updateReply = (customerId, payload) => {
  let url = `user/admin/reply/${customerId}`;
  return Api.patchRequest(url, payload);
};


const CustomerAPI = {
  getCustomerList,
  searchCustomerById,
  getCustomerAddressList,
  addNewAddress,
  updateAddress,
  getCustomerById,
  updateCustomerById,
  getComments,
  postComment,
  postReply,
  updateComment,
  updateReply,
};

export default CustomerAPI;
