import React from "react";
import { Row, Col, Icon, Card, Typography, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { verifyGoogleLoginRequest } from "actions/login";
import { useLocation, useParams } from "react-router-dom";
import * as queryString from "query-string";

const { Title } = Typography;

function useQuery() {
  const { search } = useLocation();
  console.log(search);
  return React.useMemo(() => queryString.parse(search), [search]);
}

export default function GoogleLogin(props) {
  let query = useQuery();

  const dispatch = useDispatch();
  useEffect(() => {
    if (query) {
      dispatch(verifyGoogleLoginRequest(query)).then((res) => {
        if (res) {
          if (res.role === "packer") {
            props.history.replace({
              pathname: "ready-to-pack",
              state: { isActive: true },
            });
          } else {
            props.history.push("/");
          }
        } else {
          message.error("Incorrect email");
          props.history.push("/login");
        }
      });
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-100">
      <Row className="w-100">
        <Col span={12} offset={6}>
          <Card
            bodyStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Icon
              type="google"
              style={{ fontSize: "50px", color: "#373873", marginBottom: "20px" }}
            />
            <Icon
              type="loading"
              style={{ fontSize: "40px", color: "#373873", marginBottom: "20px" }}
            />
            <Title level={2}>Logging In - Please Wait</Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
