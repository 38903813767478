import { Api } from "../config/request";

export const getDiscountById = (id,type) => {
  let url = `orders/promotions/${id}/${type}`;
  return Api.getRequest(url);
};

export const getDiscountsList = query => {
  let url = `orders/promotions/list`;
  return Api.getListRequest(url);
};

export const getSearchedDiscounts = query => {
  let url = `orders/promotions/search${query ? "?"+query : ""}`;
  return Api.getListRequest(url);
};

export const getBulkSearchedDiscounts = (query) => {
  let url = `orders/promotions/bulk/search${query ? "?" + query : ""}`;
  return Api.getListRequest(url);
};

export const getBulkSearchedDiscountsCodes = (query) => {
  let url = `orders/promotions/bulk/codes/search${query ? "?" + query : ""}`;
  return Api.getListRequest(url);
};

export const createDiscount = payload => {
  let url = `orders/promotions/`;
  return Api.postRequest(url, payload);
};

export const updateDiscountById = (id, payload) => {
  let url = `orders/promotions/${id}`;
  if(payload.isBulkPromo){
   url = `orders/promotions/bulk/${id}`;   
  }
  
  return Api.patchRequest(url, payload);
};

export const addMoreDiscountById = (id, payload) => {
  let url = `orders/promotions/bulk/addCodes/${id}`;
  return Api.patchRequest(url, payload);
};

export const deleteDiscountById = id => {
  let url = `orders/promotions/${id}`;
  return Api.deleteRequest(url);
};

export const getDiscountHistory = id => {
  let url = `logshistory/promotions/${id}`;
  return Api.getListRequest(url)

}

const Admins = {
  getDiscountById,
  getDiscountsList,
  getSearchedDiscounts,
  createDiscount,
  updateDiscountById,
  addMoreDiscountById,
  deleteDiscountById,
  getDiscountHistory,
  getBulkSearchedDiscounts,
  getBulkSearchedDiscountsCodes,
};

export default Admins;
