import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Dropdown,
  Button,
  Icon,
  Menu,
  Tag,
  Checkbox,
  Badge,
  Modal,
  InputNumber,
  Pagination,
} from "antd";
import "./inventoryPage.scss";
import { useSelector, useDispatch } from "react-redux";
import { getActiveProducts } from "../../actions/inventory";
import InputStockValueModal from "../../components/inventory/inputValueModal";

const omitCategories = ["Gift Sets", "Freebies", "Bundles"];

const InventoryPage = () => {
  const [allProducts, setProducts] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});
  const [propertyToEdit, setPropertyToEdit] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveProducts(`type=active`));
  }, []);

  let data = useSelector((state) => state.Inventory.products);
  //console.log("product data", data)

  useEffect(() => {
    if (data.length > 0) {
      data = data.filter(
        (element) => !omitCategories.includes(element.category.name)
      );
      setProducts(data);
    }
  }, [data]);

  //console.log("hi there", productList);

  const totalStockCount = (stockData) => {
    return (
      stockData.usFCStock +
      stockData.sgFCStock +
      stockData.sgWHStock +
      stockData.transitStock
    );
  };

  const availableStockCount = (stockData) => {
    return stockData.usFCStock + stockData.sgFCStock;
  };

  const inlevelStock = (stockData) => {
    return totalStockCount(stockData) - stockData.outBoundStock;
  };

  const displayModal = (product, property) => {
    setShowModal(true);
    setProductToEdit(product);
    setPropertyToEdit(property);
  };

  const rowSelection = {
    type: "checkbox",
  };

  // lowStock - If it falls below threshold: (1/5th of projected outbound column)
  const mappedProductList =
    allProducts.length > 0
      ? allProducts.map((productdata, index) => {
          return {
            skuname: productdata.ssu,
            fullname: productdata.name,
            category: "Product",
            totalstock: totalStockCount(productdata),
            availablestock: availableStockCount(productdata),
            usfcStock: (
              <div>
                {productdata.usFCStock}{" "}
                <Icon
                  type="edit"
                  style={{ color: "#373873", paddingLeft: "2px" }}
                  onClick={() => {
                    displayModal(productdata, "usFCStock");
                  }}
                />
              </div>
            ),
            aufcStock: (
              <div>
                {productdata.auFCStock}{" "}
                <Icon
                  type="edit"
                  style={{ color: "#373873", paddingLeft: "2px" }}
                  onClick={() => {
                    displayModal(productdata, "auFCStock");
                  }}
                />
              </div>
            ),
            sgfcStock: (
              <div>
                {productdata.sgFCStock}{" "}
                <Icon
                  type="edit"
                  style={{ color: "#373873", paddingLeft: "2px" }}
                  onClick={() => {
                    displayModal(productdata, "sgFCStock");
                  }}
                />
              </div>
            ),
            sgwarehouseStock: (
              <div>
                {productdata.sgWHStock}{" "}
                <Icon
                  type="edit"
                  style={{ color: "#373873", paddingLeft: "2px" }}
                  onClick={() => {
                    displayModal(productdata, "sgWHStock");
                  }}
                />
              </div>
            ),
            transitStock: (
              <div>
                {productdata.transitStock}{" "}
                <Icon
                  type="edit"
                  style={{ color: "#373873", paddingLeft: "2px" }}
                  onClick={() => {
                    displayModal(productdata, "transitStock");
                  }}
                />
              </div>
            ),
            outboundStock: (
              <div>
                {productdata.outBoundStock}
                <Icon
                  type="edit"
                  style={{ color: "#373873", paddingLeft: "2px" }}
                  onClick={() => {
                    displayModal(productdata, "outBoundStock");
                  }}
                />
              </div>
            ),
            inlevelStock: inlevelStock(productdata),
            lowStock:
              productdata.outBoundStock / 5 > inlevelStock(productdata)
                ? "Yes"
                : "No",
            status:
              productdata.outBoundStock / 5 > inlevelStock(productdata)
                ? "Alert"
                : "Not Applicable",
          };
        })
      : [];

  const columns = [
    {
      title: "SKU Name",
      dataIndex: "skuname",
      key: "skuname",
      width: 100,
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
      key: "fullname",
      width: 200,
    },
    {
      title: "Classification",
      dataIndex: "category",
      key: "category",
      width: 130,
    },
    {
      title: "Total Stock",
      dataIndex: "totalstock",
      key: "totalstock",
      width: 80,
    },
    {
      title: "Available Stock",
      dataIndex: "availablestock",
      key: "availablestock",
      width: 80,
    },
    {
      title: "US FC",
      dataIndex: "usfcStock",
      key: "usfcStock",
      width: 80,
    },
    {
      title: "AU FC",
      dataIndex: "aufcStock",
      key: "aufcStock",
      width: 80,
    },
    {
      title: "SG FC",
      dataIndex: "sgfcStock",
      key: "sgfcStock",
      width: 80,
    },
    {
      title: "SG Warehouse",
      dataIndex: "sgwarehouseStock",
      key: "sgwarehouseStock",
      width: 100,
    },
    {
      title: "Transit Stock",
      dataIndex: "transitStock",
      key: "transitStock",
      width: 80,
    },
    {
      title: "Project Outbound for 10 weeks",
      dataIndex: "outboundStock",
      key: "outboundStock",
      width: 80,
    },
    {
      title: "Project stock level after 10 weeks",
      dataIndex: "inlevelStock",
      key: "inlevelStock",
      width: 80,
    },
    {
      title: "Low Stock",
      dataIndex: "lowStock",
      key: "lowStock",
      width: 70,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 130,
    },
    // {
    //   title: "Action",
    //   key:"action",
    //   fixed: "right",
    //   width: 100,
    //   render: (record) =>{
    //     return(<div className="action-column" onClick={()=>{}}>Adjust</div>)
    //   }
    // }
  ];

  return (
    <div className="inventory-page">
      <div className="list-wrap">
        <div className="flex items-center mb-36">
          <div
            className="theme-color semibold text-30 mr-14"
            style={{ marginBottom: "0px" }}
          >
            Inventory Panel
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              this.setState({ showExportModal: true });
            }}
          >
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>
        <div className="table-content">
          <Table
            rowClassName={(record) =>
              record.lowStock == "Yes" ? "alert-row" : "table-row-light"
            }
            dataSource={mappedProductList}
            columns={columns}
            bordered
            size="small"
            scroll={{ x: 1500 }}
            pagination={{ pageSize: 20 }}
            rowSelection={rowSelection}
          />
        </div>
      </div>
      <InputStockValueModal
        showInputValueModal={showModal}
        product={productToEdit}
        property={propertyToEdit}
        closeModal={() => setShowModal(false)}
      />
    </div>
  );
};

export default InventoryPage;
