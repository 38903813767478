import ReviewsApi from "services/reviews";
import actionTypes from "actions/actionTypes";


export function setActiveReview(payload) {
  return {
    type: actionTypes.SET_ACTIVE_REVIEW,
    payload
  }
};

export const listReviews = (result) => ({
  type: actionTypes.LIST_REVIEWS,
  payload: result
})

export const getReview = (id) => async dispatch => {
  try {
    const res = await ReviewsApi.getReviewById(id);
    dispatch(setActiveReview(res));
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const getReviewsList = () => async dispatch => {
   try {
    const res = await ReviewsApi.getReviewsList();
    dispatch(listReviews(res.result));
    return res.result;
  } catch (err) {
    console.log(err);
  }
};


export const createReview = (payload) => async dispatch => {
  try {
    const res = await ReviewsApi.createReview(payload);
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const updateReview= (id, payload) => async dispatch => {
  try {
    const res = await ReviewsApi.updateReviewById(id, payload);
    dispatch(setActiveReview(res));
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const deleteReview = id => async dispatch => {
  try {
    const res = await ReviewsApi.deleteReviewById(id);
    return res;
  } catch (err) {
    console.log(err);
  }
}

