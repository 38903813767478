import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { 
  Table,
  Input, 
  Button, 
  Icon, 
  Modal,
  InputNumber,
  Badge,
} from "antd";

import "./reviews.scss";
import * as actions from "actions/reviews";


export const ListReviews = (props) => {
  const [ showExportModal, setShowExportModal ] = useState(false)
  const [ exportModalLoading, setExportModalLoading ] = useState(false)
  const [ numberOfRows, setNumberOfRows ] = useState(10)
  const csvRef = React.createRef()

  const dispatch = useDispatch()

  const reviewsList = useSelector(state => state.Reviews.reviews)

  useEffect(() => {
    dispatch(actions.getReviewsList())
  }, [])

  const onChangeInput = value => setNumberOfRows(value)
 

  const columns = [
    {
      title: "Name Author",
      dataIndex: "nameAuthor",
      key: "nameAuthor",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Author Photo",
      dataIndex: "authorPhoto",
      key: "authorPhoto",
      render: photo => (
        <img
          src={photo.mediaUrl}
          style={{ maxHeight: "60px" }}
          alt="Author Photo"
        />
      )
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: image => (
        <img
          src={image.mediaUrl}
          style={{ maxHeight: "60px" }}
          alt="Image"
        />
      )
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating"
    },
    {
      title: "isShowOnHomepage",
      dataIndex: "isShowOnHomepage",
      key: "isShowOnHomepage",
      render: state => {
        return state 
        ? <Badge status="success" />
        : <Badge status="default" />
      }
    },
    {
      title: "isActive",
      dataIndex: "isActive",
      key: "isActive",
      render: state => {
        return state 
        ? <Badge status="success" />
        : <Badge status="default" />
      }
    },
  ];

  const headers= columns.map(item => {
    return {
        label: item.dataIndex,
        key: item.key
      }
    })

  const mappedReviewsList =
    reviewsList.length > 0 ?
    reviewsList.map(review => {
      return {
        id: review.id,
        authorPhoto: review.authorPhoto,
        nameAuthor: review.nameAuthor,
        text: review.text,
        date: review.date,
        verifiedFrom: review.verifiedFrom,
        image: review.image,
        rating: review.rating,
        isShowOnHomepage: review.isShowOnHomepage,
        isActive: review.isActive,
      };
    })
    :[];

    const csvData= mappedReviewsList.map(
      (item) => {
          return {
            ...item,
            authorPhoto: item.authorPhoto.mediaUrl || '-',
            image: item.image.mediaUrl || '-',
          }
        } 
      ).slice(0, numberOfRows)

  return (
    <div className="ingredients-page-list" style={{ marginLeft: "200px" }}>
      <div className="flex items-center mb-36">
        <div
          className="theme-color semibold text-30 mr-14"
          style={{ marginBottom: "0px" }}
        >
          Review List
        </div>
        <div className="flex items-center cursor-pointer" 
          onClick={() => {
            setShowExportModal(true)
          }}>
          <Icon type="export" className="mr-4" />
          <div>Export</div>
        </div>
      </div>

      <div className="flex nav-container justify-between">
        <div className="sub-text-top-left theme-color">All</div>
        <Button
          type="primary"
          onClick={() => {
            const win = window.open(`/reviews/edit`, "_blank");
            win.focus();
          }}
          style={{ marginTop: "-14px" }}
        >
          <Icon type="plus"></Icon>
          Add Review
        </Button>
      </div>

      <div>
        <div style={{ background: "white" }}>
          <Table
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={mappedReviewsList}
            bordered
            rowKey={(record) => record.id}
            onRow={(record) => {
              return {
                onClick: () => {
                  const win = window.open(`/reviews/edit?id=${record.id}`, "_blank");
                  win.focus();
                }
              };
            }}
            size="small"
          />
        </div>
      </div>
      <Modal
        title="How many rows to be exported ?"
        visible={showExportModal}
        okText="Export"
        confirmLoading={exportModalLoading}
        onCancel={()=>{setShowExportModal(false)}}
        onOk={async ()=>{
          setExportModalLoading(true)
          setExportModalLoading(false)
          setShowExportModal(false)
          csvRef.current && csvRef.current.link.click()
        }}
      >
        <InputNumber min={1} defaultValue={numberOfRows}  onChange={onChangeInput}/>
      </Modal>
      <CSVLink data={csvData} headers={headers} ref={csvRef} style={{display:"none"}}
        filename='reviews.csv'/>
    </div>
  );
}

