
const initialState = {
  tags: [],
  activeTag: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "SET_ACTIVE_TAG":
      return setActiveTag(state, action);
    case "LIST_TAGS":
      return listTags(state, action);
    case "ADD_TAG":
      return addTag(state, action);
    case "UPDATE_TAG_LIST":
      return updateTagList(state, action);
    default:
      return state;
  }
}

function setActiveTag(state, action) {
  return {
    ...state,
    activeTag: { ...action.payload }
  }
}

function listTags(state, action) {
  return {
    ...state, 
    tags: [ ...action.payload ]
  };
}

function addTag(state, action) {
  return {
    ...state,
    tags: [ { ...action.payload }, ...state.tags ]
  };
}

function updateTagList(state, action) {
  const updatedTagList = [...state.tags].map( tag => tag.id === action.payload.id ? {...action.payload} : tag);
  return {
    ...state,
    tags: updatedTagList
  };
}
