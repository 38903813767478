import React, { Component } from "react";
import "./questionStepTwo.scss";
import {
  InputNumber,
  Checkbox,
  Divider,
  Input,
  Icon,
  Form,
  Button,
  Select,
  Upload,
  message
} from "antd";
import { getBase64, beforeUpload, UploadImage, getSafely } from "utils/helpers";
const { Option } = Select;

class QuestionStepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionImages: [],
      rangeImage: {
        loading: false,
        mediaUrl: null,
        mediaType: null
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.activeQuestion && this.props.activeQuestion) {
      if (!this.props.activeQuestion.optionsAreRange) {
        const optionImagesCopy = this.props.activeQuestion.options.map(
          option => {
            return {
              loading: false,
              mediaUrl: getSafely(() => option.icon.mediaUrl, "")
            };
          }
        );
        this.setState({
          optionImages: [...optionImagesCopy]
        });
      } else {
        this.setState({
          rangeImage: {
            loading: false,
            mediaUrl: getSafely(
              () => this.props.activeQuestion.options[0].icon.mediaUrl,
              ""
            ),
            mediaType: getSafely(
              () => this.props.activeQuestion.options[0].icon.mediaType,
              ""
            )
          }
        });
      }
    }
  }

  showNextQuestionField = () => {
    return this.props.lookup === "option";
  };

  showNextQuestionFieldForQuestion = () => {
    return this.props.lookup === "option" && this.props.optionType === "multi";
  };

  renderOption = index => {
    const {
      questionList,
      optionHaveIcons,
      activeQuestion,
      optionType
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { optionImages } = this.state;
    return (
      <div key={index}>
        
        <div className="values flex items-center">
          <div className="w-100 mr-12">
            <div>Option {index || ""} Text</div>
            <Form.Item
              style={{ width: "100%", marginTop: "10px", marginRight: "40px" }}
            >
              {getFieldDecorator(`${index}.text`, {
                initialValue: getSafely(
                  () => activeQuestion.options[index - 1].text
                ),
                rules: [{ required: true, message: "Required" }]
              })(<Input disabled={!this.props.enabled} />)}
            </Form.Item>
          </div>

          <div className="w-100 mr-12">
            <div>Tag</div>
            <Form.Item
              style={{ width: "100%", marginTop: "10px", marginRight: "40px" }}
            >
              {getFieldDecorator(`${index}.tag`, {
                initialValue: getSafely(
                  () => activeQuestion.options[index - 1].tag
                ),
                rules: [
                  {
                    required: true,
                    message: "Required"
                  }
                ]
              })(
                <Select
                  placeholder="Select a tag"
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                  disabled={!this.props.enabled}
                >
                  {this.props.tagList &&
                    this.props.tagList.map(tag => (
                      <Option value={tag._id} key={tag._id}>
                        {tag.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </div>

          {optionHaveIcons && optionType !== "range" && (
            <div className="w-100 mr-12">
              <div>Upload Photo</div>
              <div
                className="upload flex"
                style={{ width: "100%" }}
              >
                <Form.Item>
                  <Icon type="edit" style={{ marginRight: "16px" }} />
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator(`${index}.icon`, {
                    getValueFromEvent: this.normFile,
                    initialValue: getSafely(
                      () => activeQuestion.options[index - 1].icon,
                      undefined
                    ),
                    rules: [
                      {
                        required: true,
                        message: "Required"
                      }
                    ]
                  })(
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={e => this.handleImageChange(e, "", index - 1)}
                      customRequest={UploadImage}
                      disabled={!this.props.enabled}
                    >
                      {optionImages[index - 1] &&
                      optionImages[index - 1].mediaUrl ? (
                        <img
                          src={
                            optionImages[index - 1] &&
                            optionImages[index - 1].mediaUrl
                          }
                          alt="avatar"
                          style={{ width: "60px", height: "60px" }}
                        />
                      ) : (
                        <Icon
                          type={
                            optionImages[index - 1] &&
                            optionImages[index - 1].loading
                              ? "loading"
                              : "plus-square"
                          }
                        />
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </div>
            </div>
          )}
          {this.showNextQuestionField() && (
            <div className="w-100 mr-12">
              <div>Next Question</div>

              <Form.Item
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginRight: "40px"
                }}
              >
                {getFieldDecorator(`${index}.nextQuestion`, {
                  initialValue: getSafely(
                    () => activeQuestion.options[index - 1].text
                  ),
                  rules: [
                    {
                      required: true,
                      message: "Required"
                    }
                  ]
                })(
                  <Select
                    placeholder="Select a question"
                    style={{ width: 200 }}
                    showSearch
                    disabled={!this.props.enabled}
                  >
                    {questionList &&
                      questionList.map(question => (
                        <Option value={question.id} key={question.id}>
                          {question.text}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderAnswerTypeCheckbox = arr => {
    return arr.map((obj, index) => {
      return (
        <div className="checkbox-answer-wrapper" key={index}>
          <Checkbox onChange={() => {}}>{obj.fieldName}</Checkbox>
        </div>
      );
    });
  };
  renderRangeOption = index => {
    const { questionList, activeQuestion } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div key={index} className="flex justify-start">
        <div className="left w-full">
          <div className="headings mt-10 flex items-center">
            Option {index || ""} Text
          </div>
          <div className="values flex items-center">
            <Form.Item
              style={{ width: "50%", marginTop: "10px", marginRight: "40px" }}
            >
              {getFieldDecorator(`${index}.text`, {
                initialValue: getSafely(
                  () => activeQuestion.options[index - 1].text
                ),
                rules: [{ required: true, message: "Required" }]
              })(<Input disabled={!this.props.enabled} />)}
            </Form.Item>
          </div>
        </div>
        <div className="w-full">
          <div className="headings mt-10 flex items-center"> Tag </div>
          <div className="values flex items-center">
            <Form.Item
              style={{ width: "50%", marginTop: "10px", marginRight: "40px" }}
            >
              {getFieldDecorator(`${index}.tag`, {
                initialValue: getSafely(
                  () => activeQuestion.options[index - 1].tag
                ),
                rules: [
                  {
                    required: true,
                    message: "Required"
                  }
                ]
              })(
                <Select
                  placeholder="Select a tag"
                  style={{ width: "100%" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!this.props.enabled}
                >
                  {this.props.tagList &&
                    this.props.tagList.map(tag => (
                      <Option value={tag._id} key={tag._id}>
                        {tag.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </div>
        </div>
        {this.showNextQuestionField() && (
          <div className="right w-full">
            <div className="headings mt-10 flex items-center">
              Next Question
            </div>
            <div className="values flex items-center">
              <Form.Item>
                {getFieldDecorator(`${index}.nextQuestion`, {
                  initialValue: undefined,
                  rules: [
                    {
                      required: true,
                      message: "Required"
                    }
                  ]
                })(
                  <Select
                    placeholder="Select a question"
                    style={{ width: 200 }}
                    showSearch
                    disabled={!this.props.enabled}
                  >
                    {questionList &&
                      questionList.map(question => (
                        <Option value={question.id} key={question.id}>
                           {question.text}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>
        )}
      </div>
    );
  };

  handleImageChange = (info, imageType, index) => {
    const { optionImages } = this.state;
    if (info.file.status === "uploading") {
      if (imageType === "range") {
        this.setState((state, props) => {
          return {
            rangeImage: { ...state.rangeImage, loading: true }
          };
        });
      } else {
        const optionImagesCopy = [...optionImages];
        optionImagesCopy[index] = {
          loading: true
        };
        this.setState({
          optionImages: optionImagesCopy
        });
      }
      return;
    }
    if (info.file.status === "done") {
      if (info.file.type) {
        if (imageType === "range") {
          this.setState((state, props) => {
            return {
              rangeImage: { ...state.rangeImage, mediaType: info.file.type }
            };
          });
        } else {
          const optionImagesCopy = [...optionImages];
          optionImagesCopy[index] = {
            mediaType: info.file.type
          };
          this.setState({
            optionImages: optionImagesCopy
          });
        }
      }
      getBase64(info.file.originFileObj, imageUrl => {
        if (imageType === "range") {
          this.setState({
            rangeImage: {
              loading: false,
              mediaUrl: imageUrl
            }
          });
        } else {
          const optionImagesCopy = [...optionImages];
          optionImagesCopy[index] = {
            ...optionImagesCopy[index],
            mediaUrl: imageUrl,
            thumbURL: imageUrl,
          };
          this.setState({
            optionImages: optionImagesCopy
          });
        }
      });
    }
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.file && e.file.response) {
      return {
        mediaUrl: e.file.response,
        mediaType: e.file.type,
        thumbURL: e.file.response,
      };
    }
    return e.file;
  };

  handleSubmit = () => {
    const {
      createOption,
      updateOption,
      updateQuestion,
      questionResponse,
      setOptionResponse,
      setEnabled,
      setNextStepEnabled,
      optionType,
      isEditMode,
      noOfOptions,
      activeQuestion
    } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        if (this.showNextQuestionFieldForQuestion()) {
          const currentQuestionId = isEditMode
            ? activeQuestion.id
            : questionResponse._id;
          updateQuestion(currentQuestionId, {
            nextQuestion: values.questionNextQuestion
          })
            .then(res => {
              if (res) {
                message.success(
                  "Question updated with next question successfully!"
                );
              } else {
                message.error(
                  "Error! Failed to update question with next question"
                );
              }
            })
            .catch(err => {
              console.log(err);
              message.error("Oops! Error");
            });
          delete values["questionNextQuestion"];
        }
        if (Object.keys(values).length === 0) {
          return;
        }
        if (isEditMode) {
          let icon = {};
          if (optionType === "range") {
            icon = { ...values["rangeIcon"] };
            icon["thumbURL"] = icon.mediaUrl;
            delete values["rangeIcon"];
          } else {
            icon["thumbURL"] = icon.mediaUrl;
          }
          let arr = Object.keys(values);
          let promiseArr = [];
          let optionsToUpdate = arr.slice(0, this.props.activeQuestion.options.length );
          let optionsToCreate = arr.slice(this.props.activeQuestion.options.length , arr.length);
          let i = 0;
          optionsToUpdate.forEach(key => {
            let payload = {
              ...values[key],
              question: activeQuestion.id,
              assessment: activeQuestion.assessment
            };
            if (optionType === "range") {
              payload["icon"] = icon;
            }
            promiseArr.push(
              updateOption(activeQuestion.options[i].id, payload)
            );
            i++;
          });
          optionsToCreate.forEach(key => {
            let payload = {
              ...values[key],
              question: activeQuestion.id,
              assessment: activeQuestion.assessment,
              // todo: internal code
              internalCode: Math.random()
            };
            if (optionType === "range") {
              payload["icon"] = icon;
            }
            promiseArr.push(createOption(payload));
          });
          Promise.all(promiseArr)
            .then(res => {
              if (res && res[0]) {
                let optionResponseArr = res.map(obj => obj._id);
                setOptionResponse(optionResponseArr);
                message.success("Options updated successfully!");
                this.props.fetchQuestion()
              } else {
                message.error("Oops! Error");
              }
            })
            .catch(err => {
              console.log(err);
              message.error("Oops! Error");
            });
        } else {
          let promiseArr = [];
          // case 1: when question option is of range type
          let icon = "";
          if (optionType === "range") {
            icon = { ...values["rangeIcon"] };
            icon["thumbURL"] = icon.mediaUrl;
            delete values["rangeIcon"];
          }
          // todo
          // if(optionType === "multi" && lookup === "option") {
          //   updateQuestion(questionResponse._id, {
          //     nextQuestion: values[0]
          //   });
          // }
          // create payload
          Object.keys(values).forEach(key => {
            let payload = {
              ...values[key],
              question: questionResponse._id,
              assessment: questionResponse.assessment,
              // todo:
              internalCode: Math.random()
            };
            // (for case 1) insert range icon in each option
            if (optionType === "range") {
              payload["icon"] = icon;
            }
            promiseArr.push(createOption(payload));
          });
          Promise.all(promiseArr)
            .then(res => {
              if (res && res[0]) {
                let arr = res.map(obj => obj._id);
                setOptionResponse(arr);
                setEnabled(false);
                setNextStepEnabled(true);
                message.success("All options created successfully!");
              } else {
                message.error("Oops! Error");
              }
            })
            .catch(err => {
              console.log(err);
              message.error("Oops! Error");
            });
        }
      }
    });
  };

  render() {
    const {
      optionType,
      activeQuestion,
      noOfOptions,
      questionList
    } = this.props;
    const { getFieldDecorator } = this.props.form;

    let optionsArr = [];
    if (activeQuestion && activeQuestion.options) {
      let optionsArrTemp = [...activeQuestion.options];
      if (noOfOptions > activeQuestion.options.length) {
        for (let i = 0; i < noOfOptions - activeQuestion.options.length; i++) {
          optionsArrTemp.push(true);
        }
      }
      optionsArr = optionsArrTemp.map((e, index) =>
        this.renderOption(index + 1)
      );
    } else {
      optionsArr = new Array(this.props.noOfOptions)
        .fill(true)
        .map((e, index) => this.renderOption(index + 1));
    }

    const select =
      optionType === "single" || optionType === "multi" ? (
        <div>
          {optionsArr}
          {this.showNextQuestionFieldForQuestion() && (
            <Form.Item label="This is the question which would come after all follow ups have been answered">
              {getFieldDecorator(`questionNextQuestion`, {
                initialValue: getSafely(
                  () => activeQuestion.nextQuestion,
                  undefined
                ),
                rules: [
                  {
                    required: true,
                    message: "Required"
                  }
                ]
              })(
                <Select
                  placeholder="Select a question"
                  style={{ width: 200 }}
                  showSearch
                  disabled={!this.props.enabled}
                >
                  {questionList &&
                    questionList.map(question => (
                      <Option value={question.id} key={question.id}>
                        {question.text}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          )}
        </div>
      ) : (
        ""
      );

    const range =
      optionType === "range" ? (
        <div className="range">
          {/* range type row */}
          <div className="flex justify-start">
            <div className="left w-full"></div>
            <div className="right w-full">
              <div className="headings mt-10 flex items-center">
                Upload Range Icon Photo
              </div>
              <div
                className="upload flex"
                style={{ width: "30%", marginRight: "40px" }}
              >
                <Form.Item>
                  <Icon type="edit" style={{ marginRight: "16px" }} />
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("rangeIcon", {
                    getValueFromEvent: this.normFile,
                    initialValue: getSafely(
                      () => activeQuestion.options[0].icon,
                      undefined
                    ),
                    rules: [
                      {
                        required: true,
                        message: "Required"
                      }
                    ]
                  })(
                    <Upload
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={e => this.handleImageChange(e, "range")}
                      customRequest={UploadImage}
                      disabled={!this.props.enabled}
                    >
                      {this.state.rangeImage.mediaUrl ? (
                        <img
                          src={this.state.rangeImage.mediaUrl}
                          alt="avatar"
                          style={{ width: "60px", height: "60px" }}
                        />
                      ) : (
                        <Icon
                          type={
                            this.state.rangeImage.loading
                              ? "loading"
                              : "plus-square"
                          }
                        />
                      )}
                    </Upload>
                  )}
                </Form.Item>
              </div>
            </div>
          </div>

          {/* range options row */}
          {new Array(this.props.noOfOptions)
            .fill(true)
            .map((e, index) => this.renderRangeOption(index + 1))}
        </div>
      ) : (
        ""
      );

    return (
      <div
        style={{ paddingTop: "20px" }}
        className="component-question-step-two desc"
      >
        <div style={{ padding: "10px 0" }} className="">
          <h3 className="main-header text-black text-base">
            Assessment Option
          </h3>
        </div>
        <div className="mb-4 mt-10">No of Answer Options</div>
        <div>
          <InputNumber
            min={1}
            max={10}
            value={this.props.noOfOptions}
            onChange={this.props.setNoOfOptions}
            style={{ marginRight: "40px" }}
            disabled={!this.props.enabled}
          />
          <Checkbox onChange={() => {}}>Add Submit Button</Checkbox>
          <Divider />
        </div>

        {(optionType === "single" || optionType === "multi") && select}
        {optionType === "range" && range}

        <div className="w-full flex justify-end mt-20">
          <Button
            type="primary"
            disabled={!this.props.enabled}
            onClick={this.handleSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

const QuestionStepTwoForm = Form.create({ name: "question_step_two_form" })(
  QuestionStepTwo
);

export default QuestionStepTwoForm;
