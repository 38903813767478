import React, { memo } from "react";

import UserSocialInfo from "components/user-social-info";

const EmailWithSocial = ({ email = "-", identities }) => {
  console.log("identities is", identities);
  return (
    <>
      <span style={{ float: 'left' }}>
        {identities && <UserSocialInfo identities={identities} />}
      </span>
      
      <span style={{marginLeft:2}}>{email}</span>
    </>
  );
};

export default memo(EmailWithSocial);
