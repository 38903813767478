import React from "react";
import { Button, Form, Input, Select, Row, Col, message, Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as customerActions from "actions/customer";
const { confirm } = Modal;

const UpdateEmailForm = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.customerId) {
          let payload = {
            email: values.email,
            user: props.customerId,
          };
          confirm({
            title: `Are you sure you want to change email from ${props.prevEmail} to ${values.email}`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              return props.updateEmail(props.activeId, payload).then((res) => {
                props.onEmailUpdate(res);
                if (res) {
                  message.success("Email updated successfully.");
                } else {
                  message.error("Oops! Something went wrong");
                }
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
          
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const { email } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item label="Update Email">
            {getFieldDecorator("email", {
              initialValue: email ? email : undefined,
              rules: [
                {
                  required: true,
                  message: "Please select valid Email",
                },
              ],
            })(<Input placeholder="Enter email" />)}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const updateEmailForm = Form.create({ name: "update_email_form" })(
  UpdateEmailForm
);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    addAddress: bindActionCreators(customerActions.createNewAddress, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(updateEmailForm);
