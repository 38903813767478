import IngredientsApi from "services/ingredients";

function handleError(err) {
  console.error(err);
}

export function setActiveIngredient(payload) {
  return {
    type: "SET_ACTIVE_INGREDIENT",
    payload
  }
};

export function listIngredients(result) {
  return {
    type: "LIST_INGREDIENTS",
    payload: result
  };
};

export const getIngredient = id => async dispatch => {
  try {
    let res = await IngredientsApi.getIngredientById(id);
    dispatch(setActiveIngredient(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const getIngredientsList = () => async dispatch => {
   try {
    let res = await IngredientsApi.getIngredientsList();
    dispatch(listIngredients(res.result.result));
    return res.result.result;
  } catch (err) {
    handleError(err);
  }
};

export const getSearchedIngredients = (type, name, tags, sort) => async dispatch => {
  try {
    let res = await IngredientsApi.getSearchedIngredients(type, name, tags, sort);
    dispatch(listIngredients(res.result));
    return res.result;
  } catch (err) {
    handleError(err);
  }
}

export const createIngredient = (payload) => async dispatch => {
  try {
    let res = await IngredientsApi.createIngredient(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const updateIngredient = (id, payload) => async dispatch => {
  try {
    let res = await IngredientsApi.updateIngredientById(id, payload);
    dispatch(setActiveIngredient(res));
    return res;
  } catch (err) {
    handleError(err);
  }
}

export const deleteIngredient = id => async dispatch => {
  try {
    let res = await IngredientsApi.deleteIngredientById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
}

