import React, { Component } from "react";
import "./customerPage.scss";
import {
  Table,
  Input,
  Dropdown,
  Button,
  Icon,
  Menu,
  Tag,
  Checkbox,
  Badge,
  Modal,
  InputNumber,
  Pagination,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/customer";
import moment from "moment";
import { CSVLink } from "react-csv";
import { debounce } from "utils/helpers";
import EmailWithSocial from "components/customers/EmailWithSocial";

const { Search } = Input;
const CUSTOMER_ADVANCED_SEARCH_OPTIONS = [
  {
    key: "search",
    label: "Name",
  },
  {
    key: "_id",
    label: "User ID",
  },
  {
    key: "search",
    label: "Email",
  },
  {
    key: "tag",
    label: "Tags",
  },
  {
    label: "Country",
    key: "location.country",
  },
];
const CUSTOMER_TYPE_FILTER = [
  {
    key: "created",
    label: "Created",
  },
  {
    key: "new",
    label: "New",
  },
  {
    key: "returning",
    label: "Returning",
  },
  {
    key: "subscriber",
    label: "Subscriber",
  },
];

const customerTypeKeys = {
  created: "Created",
  //new: "New",
  returning: "Returning",
  subscriber: "Subscriber",
  "single-order": "Single-Order",
  //banned: "Banned",
};

const subscriptionTypeKeys = {
  subscribed: "Subscribed",
  "non-subscribed": "Non-Subscribed",
  canceled: "Canceled",
};

const basicAdminColumnsFilters = [
  "all",
  "name",
  "email",
  "totalSubmissions",
  "totalCompletedOrders",
  "totalSpent",
  "orderDate",
  "customerType",
  "tags",
  "notes",
  "location",
];

const adminColumnsFilter = [
  "all",
  "date",
  "id",
  "name",
  "number",
  "email",
  "totalSubmissions",
  "orderId",
  "totalCompletedOrders",
  "abandonedCheckouts",
  "totalSpent",
  "orderDate",
  "customerType",
  "tags",
  "notes",
  "emailSubscriber",
  "location",
  "isActive",
  "stripeCustomerId",
  "subscriptionType",
];

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      sortOrder: 1,
      activeAdvSearch: CUSTOMER_ADVANCED_SEARCH_OPTIONS[2],
      shownColumns: this.getFilters(),
      checked: true,
      activeStatusFilters: [],
      showExportModal: false,
      exportModalLoading: false,
      numberOfRows: 10,
      pageNo: 0,
      csvData: [],
    };
    this.csvRef = React.createRef();
  }
  componentDidMount() {
    const filterQueryStatus =
      this.props.customerList &&
      this.props.customerList.activeStatusFilters &&
      this.props.customerList.activeStatusFilters.length > 0 &&
      this.props.customerList.activeStatusFilters
        .map((value, index) => {
          if (index === 0) {
            return `customerType=${value}`;
          } else {
            return `&customerType=${value}`;
          }
        })
        .join("");
    this.props.fetchCustomerList(filterQueryStatus);
  }
  onChange = (e) => {
    console.log(e);
  };
  onChangeInput = (value) => {
    this.setState({ numberOfRows: value });
  };
  handleMenuClick = (e) => {
    console.log(e);
  };
  handleAdvSearchChange = (value) => {
    this.setState({
      activeAdvSearch: JSON.parse(value),
    });
  };

  getCustomerTypeFilterQuery = () => {
    let queryString = "";
    const { activeStatusFilters } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `customerType=${x}`;
        } else {
          queryString += `&customerType=${x}`;
        }
      });
    }
    return queryString;
  };

  getAdvanceSearchQuery = () => {
    let queryString = "";
    const { searchKey, activeAdvSearch } = this.state;
    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}`;
    }
    return queryString;
  };

  handleSearch = debounce(async (value) => {
    this.setState(
      {
        searchKey: value,
      },
      () => {
        if (this.state.searchKey) {
          this.props.fetchCustomerList(
            `${this.state.activeAdvSearch.key}=${value}${
              this.getCustomerTypeFilterQuery()
                ? "&" + this.getCustomerTypeFilterQuery()
                : ""
            }`
          );
        } else {
          this.props.fetchCustomerList();
        }
      }
    );
  }, 300);
  handleTagSearch = (value) => {
    console.log(value);
    this.props.fetchListIngredients(undefined, undefined, value);
  };
  handleStatusFilter = (value) => {
    this.setState(
      {
        activeFilter: value,
      },
      () => {
        this.props.fetchCustomerList(`customerType=${value}`, 0);
      }
    );
  };
  handleStatusFilter = (filterKey, value) => {
    this.setState(
      (state, props) => {
        let newFilterList = [];
        if (state.activeStatusFilters.indexOf(value) > -1) {
          newFilterList = state.activeStatusFilters.filter(
            (elem) => elem !== value
          );
        } else {
          newFilterList = [...state.activeStatusFilters, value];
        }
        return {
          activeStatusFilters: newFilterList,
        };
      },
      () => {
        const filterQueryStatus = this.state.activeStatusFilters
          .map((value, index) => {
            if (index === 0) {
              return `${filterKey}=${value}`;
            } else {
              return `&${filterKey}=${value}`;
            }
          })
          .join("");

        this.props.fetchCustomerList(
          filterQueryStatus +
            `${
              this.getAdvanceSearchQuery() ? this.getAdvanceSearchQuery() : ""
            }`
        );
      }
    );
  };

  onColumnFilterChange = (e, item) => {
    if (item === "all") {
      if (e.target.checked) {
        this.setState({
          shownColumns: [...this.getFilters()],
        });
      } else {
        this.setState({
          shownColumns: [],
        });
      }
      return;
    } else {
      this.setState((prevState, props) => {
        return {
          shownColumns: prevState.shownColumns.filter((col) => col !== "all"),
        };
      });
    }
    if (e.target.checked) {
      this.setState((prevState) => {
        let columnsArr = [...prevState.shownColumns, item];
        return {
          shownColumns: columnsArr,
        };
      });
    } else {
      this.setState((prevState) => {
        let columnsArr = prevState.shownColumns.filter((value) => {
          return value !== item;
        });
        return {
          shownColumns: columnsArr,
        };
      });
    }

    this.setState({
      checked: e.target.checked,
    });
  };
  // handleMenuStopClick = e => {
  //   if (e.key === '3') {
  //     this.setState({ visible: false });
  //   }
  // };

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };
  isactiveStatusFiltersApplied = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.customerList &&
        this.props.customerList.activeStatusFilters &&
        this.props.customerList.activeStatusFilters.length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  getactiveStatusFiltersCount = () => {
    const { activeStatusFilters } = this.state;
    if (
      (activeStatusFilters && activeStatusFilters.length > 0) ||
      (this.props.customerList &&
        this.props.customerList.activeStatusFilters &&
        this.props.customerList.activeStatusFilters.length > 0)
    ) {
      if (activeStatusFilters && activeStatusFilters.length > 0) {
        return activeStatusFilters.length;
      } else {
        return this.props.customerList.activeStatusFilters.length;
      }
    } else {
      return 0;
    }
  };
  isClearAllFilterDisabled = () => {
    if (!this.isactiveStatusFiltersApplied()) {
      return true;
    } else {
      return false;
    }
  };

  getFilters = () => {
    if (localStorage.getItem("userType") === "basic") {
      return basicAdminColumnsFilters;
    }
    return adminColumnsFilter;
  };

  getSelectedQueryStringFilter = () => {
    let queryString = "";
    const {
      activeFulfillMentFilters,
      activeStatusFilters,
      searchKey,
      activeAdvSearch,
    } = this.state;
    if (activeStatusFilters && activeStatusFilters.length > 0) {
      activeStatusFilters.forEach((x, index) => {
        if (index === 0) {
          queryString = `customerType=${x}`;
        } else {
          queryString += `&customerType=${x}`;
        }
      });
    }

    if (
      searchKey &&
      searchKey.length > 0 &&
      activeAdvSearch &&
      activeAdvSearch.key
    ) {
      queryString = `${queryString}&${activeAdvSearch.key}=${searchKey}`;
    }
    return queryString;
  };

  render() {
    const columns = [
      {
        title: "Account creation date",
        dataIndex: "date",
        key: "date",
        width: 160,
      },
      {
        title: "User ID",
        dataIndex: "id",
        key: "id",
        width: 150,
        render: (id) => <div style={{ color: "#363864" }}>{id}</div>,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 130,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        render: (text) => <button>{text}</button>,
      },
      {
        title: "Phone Number",
        dataIndex: "number",
        key: "number",
        width: 130,
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        width: 160,
      },
      {
        title: "Total Submissions",
        dataIndex: "totalSubmissions",
        key: "totalSubmissions",
        width: 130,
      },
      {
        title: "Total Completed Orders",
        dataIndex: "totalCompletedOrders",
        key: "totalCompletedOrders",
        width: 170,
      },
      // {
      //   title: "FullFilment Status",
      //   dataIndex: "number",
      //   key: "number"
      // },
      {
        title: "Total Abandoned Checkouts",
        dataIndex: "abandonedCheckouts",
        key: "abandonedCheckouts",
        width: 130,
      },
      {
        title: "Lifetime Value",
        dataIndex: "totalSpent",
        key: "totalSpent",
        width: 130,
      },
      {
        title: "Last Order date",
        dataIndex: "orderDate",
        key: "orderDate",
        width: 130,
      },
      {
        title: "Last Order ID",
        dataIndex: "orderId",
        key: "orderId",
        width: 130,
      },
      {
        title: "Customer Type",
        dataIndex: "customerType",
        key: "customerType",
        width: 130,
      },
      {
        title: "Subscription Type",
        dataIndex: "subscriptionType",
        key: "subscriptionType",
        width: 130,
      },

      {
        title: "Tags",
        key: "tags",
        dataIndex: "tags",
        width: 130,

        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag className="mb-4" key={tag}>
                  {tag === Object(tag) ? tag.name : ""}
                </Tag>
              );
            })}
          </span>
        ),
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        width: 130,
      },
      {
        title: "Email Subscriber",
        dataIndex: "emailSubscriber",
        key: "emailSubscriber",
        width: 130,
      },
      {
        title: "City/Country",
        dataIndex: "location",
        key: "location",
        width: 130,
      },
      {
        title: "Account Status",
        dataIndex: "isActive",
        key: "isActive",
        width: 130,
      },
      // {
      //   title: "Average Order Value",
      //   dataIndex: "averageOrderValue",
      //   key: "averageOrderValue",
      //   width: 170,
      // },
      {
        title: "Stripe Id",
        dataIndex: "stripeCustomerId",
        key: "stripeCustomerId",
        width: 160,
      },
    ].filter((column) =>
      this.state.shownColumns.find(
        (hiddenColKey) => hiddenColKey === column.key
      )
    );

    let headers = columns.map((item) => {
      return { label: item.title, key: item.key };
    });

    const mappedCustomerList =
      this.props.customerList.customerList.length > 0
        ? this.props.customerList.customerList.map((customerdata) => {
            return {
              date: moment(customerdata.createdAt).format("DD/MM/YY"),
              orderDate: customerdata.lastOrderDate
                ? moment(customerdata.lastOrderDate).format("DD/MM/YY")
                : "-",
              orderId: `${
                customerdata && customerdata.lastOrder
                  ? customerdata.lastOrder
                  : "-"
              }`,
              id: customerdata.id,
              name:
                `${customerdata.firstName ? customerdata.firstName : ""}` +
                `${customerdata.lastName ? " " + customerdata.lastName : ""}`,
              email: (
                <EmailWithSocial
                  email={customerdata && customerdata.email}
                  identities={customerdata && customerdata.identities}
                />
              ),
              isActive: `${
                customerdata.isActive === true ? "Active" : "Blocked"
              }`,
              emailSubscriber: `${
                customerdata.emailSubscriber === false ? "No" : "Yes"
              }`,
              number: `${customerdata.phone ? customerdata.phone.phone : "-"}`,
              address:
                `${
                  customerdata &&
                  customerdata.primaryAddress &&
                  customerdata.primaryAddress.apartment
                    ? customerdata.primaryAddress.apartment + " , "
                    : ""
                }` +
                `${
                  customerdata &&
                  customerdata.primaryAddress &&
                  customerdata.primaryAddress.street
                    ? customerdata.primaryAddress.street + " ,"
                    : ""
                }` +
                `${
                  customerdata &&
                  customerdata.primaryAddress &&
                  customerdata.primaryAddress.city
                    ? customerdata.primaryAddress.city + " , "
                    : ""
                }` +
                `${
                  customerdata &&
                  customerdata.primaryAddress &&
                  customerdata.primaryAddress.state
                    ? customerdata.primaryAddress.state + " , "
                    : ""
                }` +
                `${
                  customerdata &&
                  customerdata.primaryAddress &&
                  customerdata.primaryAddress.country
                    ? customerdata.primaryAddress.country
                    : ""
                }`,
              tags: customerdata.tags,
              customerType: `${
                customerdata.customerType
                  ? customerTypeKeys[customerdata.customerType]
                  : "-"
              }`,
              subscriptionType: `${
                customerdata.subscriptionType
                  ? subscriptionTypeKeys[customerdata.subscriptionType]
                  : "-"
              }`,
              totalSpent: `${
                customerdata.totalSpent && customerdata.totalSpent.currency
                  ? customerdata.totalSpent.currency.toUpperCase()
                  : "-"
              }${
                customerdata.totalSpent && customerdata.totalSpent.currency
                  ? " " + customerdata.totalSpent.value
                  : "-"
              }`,
              stripeCustomerId: `${
                customerdata.stripeCustomerId
                  ? customerdata.stripeCustomerId
                  : "-"
              }`,
              // tags:`${customerdata && customerdata.tags[0] ? customerdata.tags[0] : '-'}`,
              notes: `${
                customerdata && customerdata.notes ? customerdata.notes : "-"
              }`,
              totalCompletedOrders: `${
                customerdata && customerdata.totalCompletedOrders
                  ? customerdata.totalCompletedOrders
                  : "-"
              }`,
              averageOrderValue: `${
                customerdata && customerdata.averageOrderValue
                  ? customerdata.averageOrderValue
                  : "-"
              }`,
              totalSubmissions: `${
                customerdata && customerdata.totalSubmissions
                  ? customerdata.totalSubmissions
                  : "-"
              }`,
              location:
                `${
                  customerdata.location && customerdata.location.city
                    ? customerdata.location.city
                    : "-"
                }` +
                `${
                  customerdata.location && customerdata.location.country
                    ? " / " + customerdata.location.country
                    : "-"
                }`,
              abandonedCheckouts: `${
                customerdata && customerdata.abandonedCheckouts
                  ? customerdata.abandonedCheckouts
                  : 0
              }`,
            };
          })
        : [];
    // const csvData = mappedCustomerList
    //   .map((item) => {
    //     return {
    //       ...item,
    //       tags: item.tags
    //         .map((tag) => {
    //           return tag === Object(tag) ? tag.name : "";
    //         })
    //         .join(","),
    //     };
    //   })
    //   .slice(0, this.state.numberOfRows);

    const advSearch = (
      <Menu
        onClick={(e) => this.handleAdvSearchChange(e.key)}
        selectedKeys={[JSON.stringify(this.state.activeAdvSearch)]}
      >
        {CUSTOMER_ADVANCED_SEARCH_OPTIONS.map((option) => {
          return (
            <Menu.Item
              key={JSON.stringify({ key: option.key, label: option.label })}
            >
              {option.label}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    const menu = (
      <Menu
        onClick={(e) => this.handleStatusFilter("customerType", e.key)}
        selectedKeys={this.state.activeStatusFilters}
        multiple
        selectable
      >
        {Object.keys(customerTypeKeys).map((key) => {
          return <Menu.Item key={key}>{customerTypeKeys[key]}</Menu.Item>;
        })}
      </Menu>
    );
    const columnFilterMenu = (
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
        className="flex flex-col column-checkbox-wrapper"
      >
        {this.getFilters().map((item, index) => {
          return (
            <Checkbox
              key={index}
              checked={this.state.shownColumns.find((value) => {
                return value === item;
              })}
              onChange={(e) => this.onColumnFilterChange(e, item)}
            >
              {item
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
            </Checkbox>
          );
        })}
      </div>
    );

    return (
      <div className="customer-page">
        <div className="list-wrap">
          <div className="flex items-center mb-36">
            <div
              className="theme-color semibold text-30 mr-14"
              style={{ marginBottom: "0px" }}
            >
              User Panel
            </div>
            {localStorage.getItem("userType") === "admin" && (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  this.setState({ showExportModal: true });
                }}
              >
                <Icon type="export" className="mr-4" />
                <div>Export</div>
              </div>
            )}
          </div>

          <div className="flex nav-container">
            {/* <div style={{width:'79px'}} className="sub-text-left-m cursor-pointer theme-color">All</div> */}
            {/* <div style={{marginLeft:'4px'}} className="sub-text-left-m cursor-pointer theme-color">Subscriber</div>

          <div style={{marginLeft:'4px'}} className="sub-text-left-m cursor-pointer theme-color">Abandoned Checkouts</div> */}

            {/* <Button
            type="primary"
            onClick={() => this.props.history.push("/ingredients/edit")}
            style={{ marginTop: "-14px" }}
          >
            <Icon type="plus"></Icon>
              Add Customer
          </Button> */}
          </div>

          <div className="table-wrap">
            <div className="table-controls flex">
              <Search
                placeholder={`Search by ${this.state.activeAdvSearch.label}`}
                onSearch={(value) => this.handleSearch(value)}
                onKeyUp={(e) => this.handleSearch(e.target.value)}
              />
              <Dropdown className="filter-search" overlay={advSearch}>
                <Button>
                  Advanced Search <Icon type="down" />
                </Button>
              </Dropdown>
              <Dropdown
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
                overlay={columnFilterMenu}
              >
                <Button
                  onClick={(e) => e.preventDefault()}
                  className="dropdown-btn"
                  style={{ flex: 1, marginRight: "5px" }}
                >
                  Column Filters <Icon type="down" />
                </Button>
              </Dropdown>
              <Dropdown overlay={menu}>
                <Button
                  className={`dropdown-btn ${
                    this.isactiveStatusFiltersApplied() && "filter-selected"
                  }`}
                  style={{ marginRight: "5px" }}
                >
                  <span
                    style={{ position: "absolute", right: "7px", top: "0px" }}
                  >
                    <Badge count={this.getactiveStatusFiltersCount()}>
                      <a href="#" className="head-example" />
                    </Badge>{" "}
                  </span>
                  Customer Type <Icon type="down" />
                </Button>
              </Dropdown>
              <Button
                className="dropdown-btn"
                onClick={() => {
                  this.setState({
                    activeStatusFilters: [],
                  });
                  this.props.fetchCustomerList();
                }}
                className="dropdown-btn"
                style={{ marginRight: "5px", width: "140px" }}
              >
                Clear All Filters
              </Button>
            </div>
            <div className="table-content">
              <Table
                rowClassName="cursor-pointer"
                columns={columns}
                dataSource={mappedCustomerList}
                bordered
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      const win = window.open(
                        `/customer/edit/${record.id}`,
                        "_blank"
                      );
                      win.focus();
                      // this.props.history.push(`/customer/edit/${record.id}`, {
                      //   state: record.id,
                      // });
                    },
                  };
                }}
                size="small"
                scroll={{ x: 1500 }}
                // pagination={{
                //   current:
                //     Number(this.props.customerList&&this.props.customerList.customerPageNo || 0),
                //     onChange: (pageNo) => {
                //       this.props.setCustomerPageDetail(pageNo);
                //     },
                //   }}

                pagination={{
                  current: Number(this.state.pageNo) || 0,
                  total:
                    (this.props &&
                      this.props.customerList &&
                      this.props.customerList.customerPageDetails &&
                      this.props.customerList.customerPageDetails.totalCount) ||
                    0,
                  pageSize: 20,
                  onChange: (pageNo) => {
                    this.setState({ pageNo: pageNo });
                    this.props.fetchCustomerList(
                      `${this.getSelectedQueryStringFilter()}&page=${
                        pageNo - 1
                      }` // this.getSelectedQueryStringFilter(),
                      // pageNo,
                      // undefined,
                      // this.state.startDate,
                      // this.state.endDate,
                      // this.state.activeStatusFilters,
                      // this.state.activeFulfillMentFilters
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={() => {
            this.setState({ showExportModal: false });
          }}
          onOk={async () => {
            this.props.fetchCustomerExportList(
              `${this.getSelectedQueryStringFilter()}&pageSize=${
                this.state.numberOfRows
              }`,
              (result) => {
                const csvData = result.map((item) => {
                  return {
                    ...item,
                    tags: item.tags
                      .map((tag) => {
                        return tag === Object(tag) ? tag.name : "";
                      })
                      .join(","),
                  };
                });
                this.setState({ exportModalLoading: true, csvData });
                this.setState({
                  exportModalLoading: false,
                  showExportModal: false,
                });
                setTimeout(() => {
                  this.csvRef.current && this.csvRef.current.link.click();
                }, 3000);
              }
            );
          }}
        >
          <InputNumber
            min={1}
            defaultValue={this.state.numberOfRows}
            onChange={this.onChangeInput}
          />
        </Modal>
        <CSVLink
          data={this.state.csvData}
          headers={headers}
          ref={this.csvRef}
          style={{ display: "none" }}
          filename="users.csv"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Customer, customerPageDetails } = state;
  return {
    customerList: Customer,
    pageDetails: customerPageDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomerList: bindActionCreators(actions.getCustomerList, dispatch),
    fetchCustomerExportList: bindActionCreators(
      actions.getCustomerExportList,
      dispatch
    ),
    searchCustomerById: bindActionCreators(
      actions.searchCustomerById,
      dispatch
    ),
    setCustomerPageDetail: bindActionCreators(
      actions.saveCustomerPageDetail,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
