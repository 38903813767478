import { Api } from "../config/request";

export const getTagById = id => {
  let url = `tags/${id}`;
  return Api.getRequest(url);
};

export const getTagsList = query => {
  let url = `tags/list`;
  return Api.getListRequest(url);
};

export const getSearchedTags = name => {
  let url = `tags/search?${name ? "name=" + name + "&" : ""}`;
  return Api.getListRequest(url);
};

export const createTag = payload => {
  let url = `tags/`;
  return Api.postRequest(url, payload);
};

export const updateTagById = (id, payload) => {
  let url = `tags/${id}`;
  return Api.patchRequest(url, payload);
};

export const deleteTagById = id => {
  let url = `tags/${id}`;
  return Api.deleteRequest(url);
};

const Admins = {
  getTagById,
  getTagsList,
  getSearchedTags,
  createTag,
  updateTagById,
  deleteTagById
};

export default Admins;
