const initialState = {
    headlineText: "",
    subHeadingText: "",
    buttonText: "",
    announcementText: "",
    announcementTextMob: "",
    announcementBarColour: "",
    announcementTextColour: "",
    loggedInHeadlineText: '',
    loggedInSubHeadingText: '',
    colorLogos: "",
    images: {
        des: "",
        mob: "",
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_BANNER_DATA":
            return setBannerData(state, action);
        default:
            return state;
    }
}

const setBannerData = (state, action) => {
    return { ...state, ...action.payload };
}
