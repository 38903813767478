const initialState = {
  customerList: [],
  customerAddressList: [],
  activeCustomer: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "CUSTOMER_LIST":
      return customerList(state, action);
      case "SAVECUSTOMER_TYPE":
      return saveCustomerType(state, action);
      case "SAVECUSTOMER_PAGE":
      return saveCustomerPage(state, action);
    case "CUSTOMER_ADDRESS_LIST":
      return customerAddressList(state, action);
    case "ADD_NEW_ADDRESS":
      return addNewAddress(state, action);
    case "SET_CUSTOMER":
      return setCustomer(state, action);
    default:
      return state;
  }
}

function setCustomer(state, action) {
  return {
    ...state,
    activeCustomer: action.payload ? { ...action.payload } : null,
  };
}

function customerList(state, action) {
  return {
    ...state,
    customerList: [...action.payload.flat()],
  };
}

function saveCustomerType(state, action) {
  return {
    ...state,
    activeStatusFilters: [...action.payload.flat()],
  };
}
function saveCustomerPage(state, action) {
  return {
    ...state,
    customerPageNo: action.payload,
    customerPageDetails:action.payload
  };
}

function customerAddressList(state, action) {
  return {
    ...state,
    customerAddressList: [...action.payload.result],
  };
}
function addNewAddress(state, action) {
  return {
    ...state,
    customerAddressList: [{ ...action.payload }, ...state.customerAddressList],
  };
}
