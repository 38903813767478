export const ORDER_TYPES = [
  "gift",
  "reformulation",
  "influencer",
  "appeasement",
  "default",
];
export const ORDER_TYPES_MAPPER = {
  gift: "Gift",
  reformulation: "Reformulation",
  influencer: "Influencer",
  appeasement: "Appeasement",
  default: "Paid",
  wholesale: "Paid > Wholesale",
  ecommerce: "Paid Direct - Ecommerce",
};

export const ORDER_TYPES_ADMIN = [
  "gift",
  "reformulation",
  "influencer",
  "appeasement",
];

export const paymentStatusMapping = {
  "-2": "Payment Failed",
  "-1": "Failed",
  0: "Created",
  1: "Payment Pending",
  2: "Payment Completed",
  3: "Partially Refunded",
  4: "Refunded",
  5: "Non-Revenue",
};

export const orderStatusMapping = {
  0: "Ready to Pack",
  1: "Packed",
  2: "On Hold",
  3: "Canceled",
  4: "Fulfilled",
};

export const orderStatusFilterMapping = {
  "-2": "Payment Failed",
  "-1": "Failed",
  0: "Created",
  1: "Payment Pending",
  2: "Payment Completed",
};

export const orderPaymentCompleteStatusFilterMapping = {
  2: "Payment Completed",
  3: "Partially Refunded",
  4: "Refunded",
};

export const paymentStatusMappingArr = [
  {
    label: "Created",
    value: "0",
  },
  {
    label: "Payment Pending",
    value: "1",
  },
  {
    label: "Payment Completed",
    value: "2",
  },
  {
    label: "Ready to ship",
    value: "3",
  },
  {
    label: "Scheduled for Shipment",
    value: "4",
  },
  {
    label: "Shipped",
    value: "5",
  },
  {
    label: "Delivered",
    value: "6",
  },
  {
    label: "Cancelled",
    value: "7",
  },
  {
    label: "Returned",
    value: "8",
  },
  {
    label: "Partial Return",
    value: "9",
  },
  {
    label: "Payment Failed",
    value: "-2",
  },
  {
    label: "Failed",
    value: "-1",
  },
];

export const countryFilterMapping = {
  Australia: "Australia",
  Singapore: "Singapore",
  "United States": "United States",
  ROW: "ROW",
  SG_FC: "SG + ROW",
};

export const countryCodeToCountryName = {
  // 'Australia': 'Australia',
  SG: "Singapore",
  US: "United States",
  AU: "Australia",
  ROW: "ROW",
  SG_FC: "SG + ROW",
};

export const orderFullfillmentStatusListing = {
  //6: "Ready to Pack",
  //7: "Packed",
  //8: "On Hold",
  //10: "Ready to Pack",
  //1: "Waybill Generated",
  //2: "Label Generated",
  3: "Packed",
  4: "Shipped",
  5: "Delivered",
  11: "On Hold",
  12: "Cancelled",
  14: "Returned",
  "-1": "WayBill Failed",
  13: "Packing",
  15: "Disposed",
};
export const orderFullfillmentStatusMapping = {
  "Ready to Pack": 10,
  //"Waybill generated": 1,
  //"Label generated": 2,
  Packed: 3,
  Shipped: 4,
  Delivered: 5,
  "WayBill Failed": -1,
  Cancelled: 12,
  "On Hold": 11,
  Returned: 14,
  New: 0,
  Packing: 13,
  Disposed: 15,
};

export const orderFullfillmentStatusEmoji = {
  0: "✨",
  10: "👍",
  1: "📝",
  2: "📑",
  3: "🎁",
  4: "🚛",
  5: "📦",
  11: "✋",
  12: "❎",
  14: "📥",
  "-1": "⚠️",
  13: "👷‍♀️",
  15: "",
};

export const orderFlaggedStatus = [
  {
    status: 1,
    label: "Flagged 🚩",
    text: "Flagged",
    modelTitle: "🚩 Flag this order",
  },
  {
    status: 2,
    label: "Resolved ✅",
    text: "Resolved",
    modelTitle: "Mark Resolved ✅ ",
  },
];

export const orderFulfillmentStatusLabel = {
  //6: "Ready to Pack",
  //7: "Packed",
  0: "New",
  10: "Ready to Pack",
  //1: "Waybill Generated",
  //2: "Label Generated",
  3: "Packed",
  4: "Shipped",
  5: "Delivered",
  11: "On Hold",
  12: "Cancelled",
  14: "Returned",
  "-1": "WayBill Failed",
  13: "Packing",
  15: "Disposed",
};

export const customerTypeMapping = {
  created: "Created",
  new: "New",
  returning: "Returning",
  subscriber: "Subscriber",
  "single-order": "Single-Order",
  banned: "Banned",
};

export const assessmentStatusMapping = {
  completed: "Completed",
  pending: "Pending",
  cancelled: "Cancelled",
};
export const oderTypeMapper = {
  default: "Paid",
  reformulation: "Reformulation",
  appeasement: "Appeasement",
  gift: "Gift",
  influencer: "Influencer",
};

export const PromoCategory = [
  { key: "1", label: "ADS" },
  { key: "2", label: "APPEASEMENT" },
  { key: "3", label: "INFLUENCER SEEDING" },
  { key: "4", label: "CRM" },
  { key: "5", label: "GIFT" },
  { key: "6", label: "PARTNERSHIPS" },
  { key: "7", label: "AFFILIATE" },
  { key: "8", label: "OTHER" },
  { key: "9", label: "INFLUENCER (Customer ACQUISITION)" },
  // { key : "2", label : "REFERRALS"},
];

export const orderTypeFilter = [
  { key: "influencer", label: "Influencer" },
  { key: "gift", label: "Gift" },
  { key: "appeasement", label: "Appeasement" },
  { key: "reformulation", label: "Reformulation" },
  { key: "default", label: "Paid" },
  { key: "ecommerce", label: "Paid Direct - Ecommerce" },
];

export const trackingDetailsCarriers = [
  { label: "DHL", value: "DHL" },
  { label: "Easy-Ship", value: "easy-ship" },
];

export const whatsAppMessageStatus = {
  no_msg_send: 0,
  incomplete_order_send: 1,
  completed_order_send: 2,
};

export const categories = [
  "day serum",
  "moisturiser",
  "night serum",
  "night cream",
  "day cream",
];

export const PERSONALIZED_CATEGORIES = [
  "face serum",
  "moisturiser",
  "night serum",
  "night cream",
  "day cream",
];

export const referralUserType = {
  true: "Registered",
  false: "Not Registered",
};

export const FULFILLMENT_ERROR = {
  ALL_ERROR: 9,
  INVALID_ACTION: 10,
  INVALID_ADDRESS: 11,
  INVALID_PAYMENT_STATUS: 12,
  SHIPPING_API_ERROR: 13,
  NO_SHIPPING_FOR_THIS_COUNTRY: 14,
};

export const FULFILLMENT_ERROR_STATUS = {
  10: "INVALID_ACTION",
  11: "INVALID_ADDRESS",
  12: "INVALID_PAYMENT_STATUS",
  13: "SHIPPING_API_ERROR",
  14: "NO_SHIPPING_FOR_THIS_COUNTRY",
};

export const sideBarLinks = [
  {
    name: "Orders",
    key: "order",
    icon: "download",
    path: "",
    links: [
      {
        name: "Completed Orders",
        key: "order.completed_list",
        path: "/orders",
      },
      {
        name: "Incomplete Orders",
        key: "order.incomplete_list",
        path: "/incompleteorders",
      },
      {
        name: "Abandoned Carts",
        key: "order.abandoned_carts_list",
        path: "/abandonedcarts",
      },
      {
        name: "Ready to Pack",
        key: "order.ready_to_pack_list",
        path: "/ready-to-pack",
      },
    ],
  },
  {
    name: "Products",
    key: "products",
    icon: "tag",
    path: "",
    links: [
      {
        name: "Yours Product List",
        key: "product.product_list",
        path: "/products",
      },
      {
        name: "Ingredients List",
        key: "products.ingredients_list",
        path: "/ingredients",
      },
      {
        name: "Category List",
        key: "products.ingredients_list",
        path: "/category",
      },
      {
        name: "Benefits List",
        key: "products.benefits_list",
        path: "/benefits",
      },
      {
        name: "Tags",
        key: "products.tags_list",
        path: "/tags",
      },
      {
        name: "Tax",
        key: "products.tax_list",
        path: "/tax",
      },
      {
        name: "Reviews",
        key: "products.reviews_list",
        path: "/reviews",
      },
      {
        name: "Other Recommendations",
        key: "products.recommendations_list",
        path: "/recommendations",
      },
    ],
  },
  {
    name: "Inventory",
    key: "inventory",
    icon: "shopping-cart",
    path: "/inventory",
  },
  {
    name: "Customers",
    key: "customers_list",
    icon: "user",
    path: "/customers",
  },
  {
    name: "Generate short url",
    key: "url_shortener",
    icon: "container",
    path: "/url-shortener",
  },
  // {
  //   name: "Reviews",
  //   key: "reviews_list",
  //   icon: "container",
  //   path: "/reviews",
  // },
  {
    name: "Reviews",
    key: "reviews",
    icon: "container",
    path: "",
    links: [
      {
        name: "Reviews List",
        key: "review.review_list",
        path: "/reviews",
      },
      {
        name: "Selfie List",
        key: "review.selfie_list",
        path: "/progress-selfies",
      },
    ],
  },
  {
    name: "Skin Assessment Panel",
    key: "skin_assessment",
    icon: "file-text",
    path: "",
    links: [
      {
        name: "Assessment Config",
        key: "assessment.config",
        path: "/assessment",
      },
      {
        name: "Assessments",
        key: "assessment.attempt_list",
        path: "/assessment-attempt",
      },
    ],
  },
  {
    name: "Discounts",
    key: "discounts_list",
    icon: "dollar",
    path: "/discounts",
    links: [
      {
        name: "Discounts",
        key: "Discounts.discount_list",
        path: "/discounts",
      },
      {
        name: "Bulk Discounts",
        key: "Discounts.bulk_discount_list",
        path: "/bulk-discounts",
      },
    ],
  },
  {
    name: "Admin",
    key: "admin_list",
    icon: "usergroup-add",
    path: "/admin",
  },
  {
    name: "Analytics",
    key: "analytics",
    icon: "line-chart",
    path: "",
    links: [
      {
        name: "Referral",
        key: "analytics.referral_list",
        path: "/referral",
      },
    ],
  },
  {
    name: "Homepage banner",
    key: "banner",
    icon: "home",
    path: "/banner",
  },
];

export const role_permission = {
  admin: {
    allAllowed: true,
  },
  basic: {
    allAllowed: false,
    order: true,
    "order.completed_list": true,
    "order.incomplete_list": true,
    "order.abandoned_carts_list": true,
    "order.ready_to_pack_list": true,
    customers_list: true,
    skin_assessment: true,
    "assessment.attempt_list": true,
    discounts_list: true,
    "Discounts.discount_list": true,
    "Discounts.bulk_discount_list": true,
    products: true,
    "products.tags_list": true,
  },
  packer: {
    allAllowed: false,
    order: true,
    "order.ready_to_pack_list": true,
  },
  influencer: {
    allAllowed: false,
    order: true,
    "order.ready_to_pack_list": true,
  },
};

export const isPermitted = (usertype, permission) => {
  if (usertype && usertype.length) {
    if (usertype === "admin") {
      return role_permission[usertype].allAllowed;
    }
    return role_permission[usertype][permission];
  } else {
    return false;
  }
};

export const checkAllowedCountries = () => {
  if (localStorage.allowedCountry) {
    return (
      Object.entries(JSON.parse(localStorage.allowedCountry)).filter(
        (x) => x[1] === true
      ).length >= 3
    );
  } else {
    return true;
  }
};

export const getCountryFilterMapping = () => {
  if (localStorage.allowedCountry) {
    let allowedCountry = JSON.parse(localStorage.allowedCountry);
    if (Object.values(allowedCountry).filter((x) => x === true).length > 1) {
      const country_map = Object.entries(allowedCountry).reduce((acc, x) => {
        if (x[1]) {
          if (countryCodeToCountryName[x[0]] === "ROW") {
            acc[countryCodeToCountryName[x[0]]] = "ROW";
          } else {
            acc[countryCodeToCountryName[x[0]]] =
              countryCodeToCountryName[x[0]];
          }
        }
        return acc;
      }, {});

      if (
        Object.keys(allowedCountry).includes("ROW") &&
        Object.keys(allowedCountry).includes("SG")
      ) {
        country_map["SG_FC"] = "SG + ROW";
      }
      return country_map;
    } else {
      return undefined;
    }
  } else {
    return countryFilterMapping;
  }
};

export const getDefaultCountryFilter = () => {
  try {
    if (localStorage.allowedCountry) {
      let allowedCountry = JSON.parse(localStorage.allowedCountry);
      let validAllowedCountry = Object.entries(allowedCountry).filter(
        (x) => x[1] === true
      );
      if (validAllowedCountry.length >= 3) {
        return [];
      } else {
        if (!validAllowedCountry.length) {
          return [];
        } else {
          let country = validAllowedCountry[0][0];
          return [country];
        }
      }
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const hideSensitiveDataForRole = () => {
  if (localStorage.userType === "packer") {
    return false;
  }
  return true;
};

export const SERUM_IDS = [
  "5df88f702b08ab622a06a1df",
  "5df88ee52b08ab622a06a1cd",
  "5df88f1d2b08ab622a06a1d3",
  "5df88fa82b08ab622a06a1e5",
  "5df88f4b2b08ab622a06a1d9",
];

export const PRODUCT_TYPE = ["product", "bundle", "merch"];
