
const initialState = {
  groupList: [],
  assessmentList: [],
  questionList: [],
  activeQuestion: null,
  parameterList: [],
  parameterQuestionMappingList: [],
  assessmentAttemptList: [],
  assessmentAttemptPageDetails: null,
  activeAssessment:null,
  activeCompleteAssessment: null,
  latestAssessmentResult:null,
  selfieList: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "LIST_GROUP":
      return listGroup(state, action);
    case "LIST_ASSESSMENT":
      return listAssessment(state, action);
    case "LIST_QUESTION":
      return listQuestion(state, action);
    case "LIST_PARAMETER":
      return listParameter(state, action);
    case "SET_ACTIVE_QUESTION":
      return setActiveQuestion(state, action);
    case "RESET_ACTIVE_QUESTION":
      return resetActiveQuestion(state, action);
    case "LIST_PARAMETER_QUESTION_MAPPING":
      return listParameterQuestionMapping(state, action);
    case "LIST_ASSESSMENT_ATTEMPT":
      return setAssessmentAttemptList(state, action);
    case "LIST_SELFIES":
      return setSelfieList(state, action);
    case "SET_PAGE_DETAILS":
      return setPageDetails(state, action);
    case "SET_ACTIVE_ASSESSMENT":
      return setActiveAssessment(state, action);
    case "SET_LATEST_ASSESSMENT_RESULT":
      return setLatestResult(state, action);
    case "SET_COMPLETED_ASSESSMENT":
      return setActiveCompletedAssessment(state, action);
    default:
      return state;
  }
}

function setPageDetails(state, action) {
  return {
    ...state,
    assessmentAttemptPageDetails: action.payload
  }
}

function listGroup(state, action) {
  return {
    ...state, 
    groupList: [ ...action.payload ]
  };
}

function listAssessment(state, action) {
  return {
    ...state, 
    assessmentList: [ ...action.payload ]
  };
}

function setAssessmentAttemptList(state, action) {
  return {
    ...state,
    assessmentAttemptList: [ ...action.payload]
  };
}

function setSelfieList(state, action) {
  return {
    ...state,
    selfieList: [ ...action.payload]
  };
}


function listQuestion(state, action) {
  return {
    ...state, 
    questionList: [ ...action.payload ]
  };
}

function listParameter(state, action) {
  return {
    ...state, 
    parameterList: [ ...action.payload]
  };
}

function setActiveQuestion(state, action) {
  return {
    ...state,
    activeQuestion: {...action.payload}
  };
}

function setActiveAssessment(state, action) {
  return {
    ...state,
    activeAssessment: {...action.payload}
  };
}

function setLatestResult(state, action) {
  return {
    ...state,
    activeAssessment: { ...action.payload },
  };
}

function setActiveCompletedAssessment(state, action) {
  return {
    ...state,
    activeCompleteAssessment: {...action.payload}
  };
}

function resetActiveQuestion(state, action) {
  return {
    ...state,
    activeQuestion: null
  };
}

function listParameterQuestionMapping(state, action) {
  return {
    ...state,
    parameterQuestionMappingList: [ ...action.payload ]
  }
}