import React from "react";
import { Button, Form, Input, Select, Row, Col } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as customerActions from "actions/customer";
import { ISDCodes } from "./isdCodes";
const { Option } = Select;

const CreatePhoneForm = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.customerId) {
          let payload = {
            phone: { phone: values.phone, isdCode: values.isdCode },
            user: props.customerId,
          };
          props.updatePhone(props.activeId, payload).then((res) => {
            props.onPhoneCreation(res);
          });
        }
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const { phoneNumber } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item label="Phone Number">
            {getFieldDecorator("isdCode", {
              initialValue: phoneNumber ? phoneNumber.isdCode : undefined,
              rules: [
                {
                  required: true,
                  message: "Please select your ISD Code",
                },
              ],
            })(
              <Select
                showSearch
                placeholder="ISD Code"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {ISDCodes.map((country) => {
                  return (
                    <Option value={country.dial_code} key={country.dial_code}>
                      {country.dial_code + ` - ` + country.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={14} style={{ marginTop: "39px" }}>
          <Form.Item label="">
            {getFieldDecorator("phone", {
              initialValue: phoneNumber ? phoneNumber.phone : undefined,
              rules: [
                {
                  required: true,
                  message: "Please enter Phone Number!",
                },
              ],
            })(<Input placeholder="Enter Phone Number" />)}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

const createPhoneForm = Form.create({ name: "create_address_form" })(
  CreatePhoneForm
);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    addAddress: bindActionCreators(customerActions.createNewAddress, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(createPhoneForm);
