import React, { Component } from "react";
import "./listCategory.scss";
import { Table, Tag, Input, Button, Icon,Modal,InputNumber } from "antd";
// import SortIcon from "static/img/icon/sortIcon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "actions/category";
import { CSVLink } from "react-csv";
import { debounce } from "utils/helpers";
const { Search } = Input;

class listCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExportModal:false,
      exportModalLoading:false,
      numberOfRows:10
    };
    this.csvRef = React.createRef();
  }
  onChangeInput = value => {
    this.setState({numberOfRows:value})
  }
  componentDidMount() {
    this.props.fetchlistCategory();
  }
  onChange = e => {
    console.log(e);
  };
  handleMenuClick = e => {
    console.log(e);
  };
  handleSearch = debounce(async value => {
    this.setState(
      {
        searchKey: value
      },
      () => {
        this.props.fetchlistCategory(value);
      }
    );
  }, 300);
  // handleFilter = value => {
  //   console.log(value);
  //   this.setState(
  //     {
  //       activeFilter: value
  //     },
  //     () => {
  //       this.props.fetchlistCategory(value);
  //     }
  //   );
  // };
  // handleTagSearch = value => {
  //   console.log(value);
  //   this.props.fetchlistCategory(undefined, undefined, value);
  // };
  render() {
    const columns = [
      {
        title: "Photo",
        dataIndex: "photo",
        key: "photo",
        render: photo => {
          return (
            <img
              src={photo.mediaUrl}
              style={{ maxHeight: "60px" }}
              alt="ingredient"
            />
          );
        }
      },
      {
        title: "Category Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: desc => {
          return (
            <div dangerouslySetInnerHTML={{__html: desc}} />
          )
        }
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity"
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: priceArr => {
          return (
            <span>
              {priceArr.map((price, i) => {
                return <Tag key={i}>
                  {`${price.price}${price.currency.symbol}`}
                </Tag>
              })}
            </span>
          );
        }
      }
    ];
    let headers= columns.map(item=>{
      return {label:item.title,
         key:item.key}
     })
    const mappedCategoryList =
      this.props.categoryList.length > 0 ?
      this.props.categoryList.map(category => {
        return {
          id: category.id,
          name: category.name,
          description: category.description,
          photo: category.image || {},
          quantity: category.quantity ? 
            `${category.quantity.value|| ""} ${category.quantity.unit || ""}` :
            "",
          price: category.prices || []
        };
      }):[];


    const csvData= mappedCategoryList.map(
        (item)=>{
            return {...item, photo:item.photo ? item.photo.mediaUrl : "-", price:item.price.map((price) => {
                return `${price.price}${price.currency.symbol}`
            }).join(",")}
          } 
        ).slice(0,this.state.numberOfRows)
    
    return (
      <div className="category-page-list" style={{ marginLeft: "200px" }}>
        <div className="flex items-center mb-36">
          <div
            className="theme-color semibold text-30 mr-14"
            style={{ marginBottom: "0px" }}
          >
            Category List
          </div>
          <div className="flex items-center cursor-pointer" 
            onClick={() => {
            this.setState({showExportModal:true})
            }}>
            <Icon type="export" className="mr-4" />
            <div>Export</div>
          </div>
        </div>

        <div className="flex nav-container justify-between">
          <div className="sub-text-top-left theme-color">All</div>
          <Button
            type="primary"
            onClick={() => {
              const win = window.open(`/category/edit`, "_blank");
              win.focus();
            }}
            style={{marginTop: "-14px"}}
          >
            <Icon type="plus"></Icon>
            Add category
          </Button>
        </div>

        <div>
          <div className="table-controls w-100 pl-16 pt-14 pr-16 pb-20 flex justify-between">
            <Search
              placeholder="Search category name"
              onSearch={value => this.handleSearch(value)}
              style={{ width: "50%", marginRight: "5px" }}
              onKeyUp={e => this.handleSearch(e.target.value)}
            />
          </div>
          <div style={{ background: "white" }}>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={mappedCategoryList}
              bordered
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    const win = window.open(`/category/edit/${record.id}`, "_blank");
                    win.focus();
                    //this.props.history.push(`/category/edit/${record.id}`);
                  }
                };
              }}
              size="small"
            />
          </div>
        </div>
        <Modal
          title="How many rows to be exported ?"
          visible={this.state.showExportModal}
          okText="Export"
          confirmLoading={this.state.exportModalLoading}
          onCancel={()=>{this.setState({showExportModal:false})}}
          onOk={async ()=>{
            this.setState({exportModalLoading:true})
            this.setState({exportModalLoading:false,showExportModal:false})
            this.csvRef.current && this.csvRef.current.link.click()
          }}
        >
          <InputNumber min={1} defaultValue={this.state.numberOfRows}  onChange={this.onChangeInput}/>
        </Modal>
        <CSVLink data={csvData} headers={headers} ref={this.csvRef} style={{display:"none"}}
          filename='categories.csv'/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { Category } = state;
  return {
    categoryList: Category.category
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchlistCategory: bindActionCreators(
      actions.getSearchedCategory,
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(listCategory);
