import React, { memo } from "react";

import { isEmpty } from "utils/helpers";
import FacebookIcon from "./FacebookIcon";
import GoogleIcon from "./GoogleIcon";

const UserSocialInfo = ({ identities, width = 20, height = 20 }) => {
  const socialObj = identities?.find((iden) => iden.isSocial);

  if (!socialObj || isEmpty(socialObj)) {
    return null;
  }

  const SocialIcon =
    socialObj.connection === "google-oauth" ? GoogleIcon : FacebookIcon;

  return <SocialIcon width={width} height={height} />;
};

export default memo(UserSocialInfo);
