import { Api } from "../config/request";

export const getCurrencyById = id => {
  let url = `payment/currency/${id}`;
  return Api.getRequest(url);
};

export const getCurrencyList = query => {
  let url = `payment/currency/list`;
  return Api.getListRequest(url);
};

export const getSearchedCurrency = query => {
  let url = `payment/currency/search`;
  return Api.getListRequest(url);
}

export const createCurrency = (payload) => {
  let url = `payment/currency/`;
  return Api.postRequest(url, payload)
}

export const updateCurrencyById = (id, payload) => {
  let url = `payment/currency/${id}`;
  return Api.putRequest(url, payload);
}

const Currency = {
    getCurrencyById,
    getCurrencyList,
    getSearchedCurrency,
    createCurrency,
    updateCurrencyById
};

export default Currency;
