import OrderApi from "services/orders";
import abandonedCarts from "views/ordersPage/abandonedCart";

function handleError(err) {
  console.error(err);
}

export function listOrders(result) {
  return {
    type: "LIST_ORDERS",
    payload: result,
  };
}

export function listCustomerSubscription(result) {
  return {
    type: "LIST_SUBSCRIPTIONS",
    payload: result,
  };
}

export function listOrders_Abandoned(result) {
  return {
    type: "LIST_ORDERS_ABANDONED",
    payload: result,
  };
}
export function listOrders_Incomplete(result) {
  return {
    type: "LIST_ORDERS_INCOMPLETE",
    payload: result,
  };
}

export function setActiveOrder(payload) {
  return {
    type: "SET_ACTIVE_ORDER",
    payload,
  };
}
export function setRefundForActiveOrder(payload) {
  return {
    type: "SET_REFUND_FOR_ACTIVE_ORDER",
    payload,
  };
}

export function setPageDetails(payload) {
  return {
    type: "SET_PAGE_DETAILS",
    payload,
  };
}

export function removeOrderFromOrders(payload) {
  return {
    type: "REMOVE_ORDER_FROM_ORDERS",
    payload,
  };
}

export function setWhatsAppMessageForOrders(payload) {
  return {
    type: "SET_WHATSAPP_MESSAGE_LIST",
    payload,
  };
}

export function setProductWiseCount( payload){
  return {
    type: "SET_PRODUCT_WISE_COUNT",
    payload,
  };
}

export function updateCustomerOrderList(payload){
  return {
    type: "SET_CUSTOMER_ORDER_LIST",
    payload,
  };
}

export function setOrderComments(payload){
  return {
    type: "SET_ORDER_COMMENTS",
    payload,
  };
}

export const exportOrders =
  (
    query,
    pageNo,
    pageSize,
    startDate,
    endDate,
    callback,
    activeStatusFilters = null,
    activeFulfillMentFilters = null
  ) =>
  async (dispatch) => {
    try {
      let res = await OrderApi.getOrders(
        query,
        pageNo,
        pageSize,
        startDate,
        endDate
      );
      if (res) {
        // dispatch(listOrders(res.result ? res.result.result : []));
        // dispatch(
        //   setPageDetails({
        //     currentPage: pageNo,
        //     ...res.result,
        //     activeStatusFilters,
        //     activeFulfillMentFilters,
        //   })
        // );
        // return res.result;
        callback(res.result ? res.result.result : []);
      }
    } catch (err) {
      handleError(err);
      return err;
    }
  };

export const getOrders =
  (
    query,
    pageNo,
    pageSize,
    startDate,
    endDate,
    activeStatusFilters = null,
    activeFulfillMentFilters = null
  ) =>
  async (dispatch) => {
    try {
      let res = await OrderApi.getOrders(
        query,
        pageNo,
        pageSize,
        startDate,
        endDate
      );
      if (res) {
        dispatch(listOrders(res.result ? res.result.result : []));
        dispatch(
          setPageDetails({
            currentPage: pageNo,
            ...res.result,
            activeStatusFilters,
            activeFulfillMentFilters,
          })
        );
        return res.result;
      }
    } catch (err) {
      handleError(err);
      return err;
    }
  };

export const getIncompleteOrders =
  (
    query,
    pageNo,
    pageSize,
    startDate,
    endDate,
    activeStatusFilters = null,
    activeFulfillMentFilters = null
  ) =>
  async (dispatch) => {
    try {
      let res = await OrderApi.getOrders(
        query,
        pageNo,
        pageSize,
        startDate,
        endDate
      );
      if (res) {
        dispatch(listOrders_Incomplete(res.result ? res.result.result : []));
        dispatch(
          setPageDetails({
            currentPage: pageNo,
            ...res.result,
            activeStatusFilters,
            activeFulfillMentFilters,
          })
        );
        return res.result;
      }
    } catch (err) {
      handleError(err);
      return err;
    }
  };

export const getAbandonedCartOrders =
  (query, pageNo, pageSize, startDate, endDate) => async (dispatch) => {
    try {
      let res = await OrderApi.getAbandonedCartOrder(
        query,
        pageNo,
        pageSize,
        startDate,
        endDate
      );
      if (res) {
        // dispatch(listOrders(res.result ? res.result.result : []));
        dispatch(listOrders_Abandoned(res.result ? res.result.result : []));
        dispatch(
          setPageDetails({
            currentPage: pageNo,
            ...res.result,
          })
        );
        return res.result;
      }
    } catch (err) {
      handleError(err);
      return err;
    }
  };

export const getCart = (id) => async () => {
  try {
    let res = await OrderApi.getCart(id);
    if (res) {
      return res;
    }
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const addToCart = (payload) => async () => {
  try {
    let res = await OrderApi.addToCart(payload);
    if (res) {
      return res;
    }
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const updateCart = (payload) => async () => {
  try {
    let res = await OrderApi.updateCart(payload);
    if (res) {
      return res;
    }
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const applyPromoCode = (payload) => async () => {
  try {
    let res = await OrderApi.applyPromoToCart(payload);
    if (res) {
      return res;
    }
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const removePromoCode = (payload) => async () => {
  try {
    let res = await OrderApi.removePromo(payload);
    if (res) {
      return res;
    }
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const updateCartType = (payload) => async () => {
  try {
    let res = await OrderApi.updateCartType(payload);
    if (res) {
      return res;
    }
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const createOrder = (payload) => async (dispatch) => {
  try {
    let res = await OrderApi.createOrder(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getOrder = (id) => async (dispatch) => {
  try {
    let res = await OrderApi.getOrder(id);
    dispatch(setActiveOrder(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateWaybill = (id) => async (dispatch) => {
  try {
    let res = await OrderApi.updateWaybill(id);
   // dispatch(setActiveOrder(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};
export const updateOrder = (id, payload) => async (dispatch) => {
  try {
    let res = await OrderApi.updateOrder(id, payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const changeProductsInOrder = (payload) => async (dispatch) => {
  try {
    let res = await OrderApi.changeProductsInOrder(payload);
    //console.log("res is", res);
    return res;
  } catch (err) {
    console.log("err is", err);
    handleError(err);
  }
};

export const addProductsInOrder = (payload) => async (dispatch) => {
  try {
    let res = await OrderApi.addProductsInOrder(payload);
    //console.log("res is", res);
    return res;
  } catch (err) {
    console.log("err is", err);
    handleError(err);
  }
};

export const removeProductsFromOrder = (payload) => async (dispatch) => {
  try {
    let res = await OrderApi.removeProductsFromOrder(payload);
    //console.log("res is", res);
    if(res && !res.error ){
      dispatch(setActiveOrder(res));
    }
    return res;
  } catch (err) {
    console.log("err is", err);
    handleError(err);
  }
};

export const getOrdersInvoice = (ids) => async (dispatch) => {
  try {
    let res = await OrderApi.getOrdersInvoice(ids);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const resetActiveOrder = () => async (dispatch) => {
  dispatch(setActiveOrder(null));
};

export const deleteOrder = (id) => async (dispatch) => {
  try {
    let res = await OrderApi.deleteOrder(id);
    if (res) {
      dispatch(removeOrderFromOrders({ id }));
    }
    return res;
  } catch (err) {
    handleError(err);
  }
};
export const createRefund = (payload) => async (dispatch) => {
  try {
    let res = await OrderApi.createRefund(payload);
    return res;
  } catch (err) {
    handleError(err);
  }
};
export const getRefundForOrder = (id) => async (dispatch) => {
  try {
    let res = await OrderApi.getRefund(id);
    dispatch(setRefundForActiveOrder(res.result));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateWhatsAppMsgStatus =
  (orderId, messageStatus) => async (dispatch) => {
    try {
      let res = await OrderApi.updateWhatsAppMsgStatus(orderId, messageStatus);
      return res;
    } catch (err) {
      handleError(err);
    }
  };

export const updateWhatsAppMsgStatusInCart = (cartId) => async (dispatch) => {
  try {
    let res = await OrderApi.updateWhatsAppMsgStatusInCart(cartId);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getWhatsAppMessage = (messageType) => async (dispatch) => {
  try {
    let res = await OrderApi.getWhatsAppMessage(messageType);
    dispatch(setWhatsAppMessageForOrders(res));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateWhatsAppMessage =
  (messageType, message) => async (dispatch) => {
    try {
      let res = await OrderApi.updateWhatsAppMessage(messageType, message);
      return res;
    } catch (err) {
      handleError(err);
    }
  };

export const updateMultiCartWhatsAppMsgStatus =
  (cartIds, messageStatus) => async (dispatch) => {
    try {
      let res = await OrderApi.updateMultiCartWhatsAppMsgStatus(
        cartIds,
        messageStatus
      );
      return res;
    } catch (err) {
      handleError(err);
    }
  };

export const updateMultiOrdersWhatsAppMsgStatus =
  (orderIds, messageStatus) => async (dispatch) => {
    try {
      let res = await OrderApi.updateMultiOrdersWhatsAppMsgStatus(
        orderIds,
        messageStatus
      );
      return res;
    } catch (err) {
      handleError(err);
    }
  };

export const getSubscription = (id) => async () => {
  try {
    let res = await OrderApi.getSubscription(id);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getSubscriptions = (id) => async (dispatch) => {
  try {
    let res = await OrderApi.getSubscriptions(id);
    dispatch(listCustomerSubscription(Array.isArray(res) ? res : []));
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const downloadMultipleInvoice = ( orderIds ) =>async (dispatch) =>{
  try{
    let res = await OrderApi.downloadMultipleInvoice(orderIds);
     return res;
  }catch(err){
     handleError(err);
  }
};  

export const downloadMultipleLabels = (orderIds) => async (dispatch) => {
  try {
    let res = await OrderApi.downloadMultipleLabels(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};  

export const downloadMultipleIngredients = (orderIds) => async (dispatch) => {
  try {
    let res = await OrderApi.downloadMultipleIngredients(orderIds);
    return res;
  } catch (err) {
    handleError(err);
  }
};  

export const getLastOrdersForCustomer =( customerId ) => async (dispatch) =>{
  try{
    let res = await OrderApi.getLastOrdersForCustomer(customerId);
    console.log("res getLastOrdersForCustomer", res);
    dispatch(updateCustomerOrderList(res));
    return res;
  }catch( err){
     handleError(err);
  }
}

export const updateShippingAddress = ( orderId,  AddressId, Address) => async ( dispatch) =>{
  try{

    const res = await OrderApi.updateShippingAddress(orderId, AddressId, Address);
     return res;
  }catch(err){
     handleError(err);
  }
}

export const getOrderComments = (orderId) => async (dispatch) => {
  try {
    const res = await OrderApi.getOrderComments(orderId);
    // dispatch(setOrderComments(res));
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const postOrderComment = (payload) => async (dispatch) => {
  try {
    const res = await OrderApi.postOrderComment(payload);
    // dispatch(getOrderComments(payload.orderId));
      return res;
  } catch (error) {
    handleError(error);
  }
}

export const postOrderReply = (orderId, payload) => async (dispatch) => {
  try {
    const res = await OrderApi.postOrderReply(orderId, payload);
    return res;
    // dispatch(setOrderComments(res));
  } catch (error) {
    handleError(error);
  }
}

export const updateComment = (orderId, commentId, comment) => async (dispatch) => {
  try {
    const res = await OrderApi.updateComment(orderId, {commentId, comment});
    return res;
    // dispatch(setOrderComments(res));
  } catch (error) {
    handleError(error);
  }
}

export const updateReply = (orderId, commentId, replyId, comment) => async (dispatch) => {
  try {
    const res = await OrderApi.updateReply(orderId, {commentId, comment, replyId});
    return res;
    // dispatch(setOrderComments(res));
  } catch (error) {
    handleError(error);
  }
}

export const updateFlaggedStatus = (payload) => async (dispatch) => {
  try {
    const res = await OrderApi.updateFlaggedStatus(payload);
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const updateFlaggedstatuses = (payload) => async (dispatch) => {
  try {
    const res = await OrderApi.updateFlaggedstatuses(payload);
    return res;
  } catch (error) {
    handleError(error);
  }
}

export const getProductWiseCount = ( query) => async (dispatch) =>{
  try {
   //console.log("querry",query);
    const res = await OrderApi.getProductWiseCount(query);
    dispatch(setProductWiseCount(res));
    return res;
  } catch (error) {
    handleError(error);
  }
  
}