import { Api } from "../config/request";

const BASE_ROUTE = "skin-assessment";

export const getGroupList = payload => {
  return Api.getListRequest(`${BASE_ROUTE}/group/list/`, payload);
};

export const getAssessmentList = (isPublished, startDate, endDate) => {
  let url = `${BASE_ROUTE}/assessment/search${isPublished ? 'isPublished='+isPublished+'&' : ""}${startDate ? 'startDate='+startDate+'&' : ""}${endDate ? 'endDate='+endDate+'&' : ""}`;
  return Api.getListRequest(url);
};

export const getAssessmentAttemptList = (query, pageNo,pageSize) => {
  let url = `${BASE_ROUTE}/attempt/list?${query ? query : ""}${pageNo ? "page="+pageNo : ""}${pageSize ? "&&pageSize="+pageSize:''}`;
  return Api.getListRequest(url);
};

export const getAssessmentById = (id) => {
  let url = `${BASE_ROUTE}/attempt/${id ? id : ""}`;
  return Api.getListRequest(url);
};

export const getCompletedAssessmentById = (id) => {
  let url = `${BASE_ROUTE}/attempt/completed/${id}`;
  return Api.getListRequest(url);
};

export const getLatestResultForUser = (id) => {
  let url = `results/latest/result/${id}`;
  return Api.getListRequest(url);
};


export const searchQuestionList = (assessmentId) => {
  return Api.getListRequest(`${BASE_ROUTE}/question/search/${assessmentId ? '?assessment='+assessmentId : ""}`);
};

export const searchParameter = () => {
  return Api.getListRequest(`parameter/search`);
};

export const createQuestion = (payload) => {
  let url = `${BASE_ROUTE}/question`;
  return Api.postRequest(url, payload)
}

export const getQuestionById = (questionId) => {
  let url = `${BASE_ROUTE}/question/${questionId}`;
  return Api.getRequest(url);
}

export const updateQuestionById = (questionId, payload) => {
  let url = `${BASE_ROUTE}/question/${questionId}`;
  return Api.patchRequest(url, payload);
}

export const createOption = (payload) => {
  let url = `${BASE_ROUTE}/option`;
  return Api.postRequest(url, payload);
}

export const updateOptionById = (optionId, payload) => {
  let url = `${BASE_ROUTE}/option/${optionId}`;
  return Api.patchRequest(url, payload);
}

export const createParameterQuestionMapping = (payload) => {
  let url = `${BASE_ROUTE}/parameter-question/`;
  return Api.postRequest(url, payload);
}

export const updateParameterQuestionMappingById = (id, payload) => {
  let url = `${BASE_ROUTE}/parameter-question/${id}`;
  return Api.patchRequest(url, payload);
}

export const getLatestAssessmentByUserId =( userId) =>{
   let url = `${BASE_ROUTE}/attempt/get-completed/${userId}`;
   return  Api.getListRequest(url);
};
export const getParameterQuestionMappingList = (questionId,assessmentId) => {
  return Api.getListRequest(`${BASE_ROUTE}/parameter-question/search?${questionId ? 'question='+questionId : ""}${assessmentId ? '&assessment='+assessmentId:""}`);
}

export const getSelfieListById =( userId) =>{
  let url = `image-analysis/selfies/?userId=${userId}`;
  return  Api.getListRequest(url);
};

const AssessmentAPI = {
  getGroupList,
  getAssessmentList,
  searchQuestionList,
  searchParameter,
  createQuestion,
  createOption,
  createParameterQuestionMapping,
  updateParameterQuestionMappingById,
  getQuestionById,
  updateQuestionById,
  updateOptionById,
  getParameterQuestionMappingList,
  getAssessmentAttemptList,
  getAssessmentById,
  getCompletedAssessmentById,
  getLatestAssessmentByUserId,
  getLatestResultForUser,
  getSelfieListById
};

export default AssessmentAPI;
